import React, { useState, useEffect } from "react";
import { ModalAddForm } from "../../../components";
import { useNavigate } from "react-router-dom";
import { makeConfig } from "./config";
import { useSelector } from "react-redux";
import { fetchData } from "../../../../../utils/helpers/data.helper";

const Page = (props) => {
  const navigate = useNavigate();
  const auth = useSelector((state) => state?.auth);
  const config = makeConfig({ navigate, auth });
  const [values, setValues] = useState({});
  const contentTypes = [
   
  ];

  useEffect(() => {
    contentTypes?.forEach((contentType) => {
      fetchData(contentType);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log('props:', props);

  return (
    <ModalAddForm
      config={config}
      values={values}
      data={props.data}
      onSubmit={props?.onSubmit}
      onCancel={props?.onCancel}
    />
  );
};

export default Page;
