import React from "react";
import { Route } from "react-router-dom";
import { WorkUnitList, WorkUnitAdd, WorkUnitEdit } from "../pages/WorkUnits";

export const register = (props) => {
  const basePath = "work-units";

  return (
    <>
      <Route path={basePath} element={<WorkUnitList />} exact={true} />
      <Route path={`${basePath}/add`} element={<WorkUnitAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<WorkUnitEdit />} />
    </>
  );
};
