import config from "../config";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  id: `${config?.slugs?.plural}-list`,
  pageTitle: config?.entityNames?.plural,
  breadcrumbs: [
    {
      path: "/quick-stocks/dashboard",
      title: "Home",
    },
    {
      path: "",
      title: config?.entityNames?.plural,
    },
  ],
  columns: [
    {
      title: "Name",
      dataIndex: "name",
      selectable: true,
      sorter: (a, b) => a?.name?.localeCompare(b?.name),
    },
    {
      title: "Work Group",
      dataIndex: "work_group",
      selectable: true,
      references: {
        fieldName: "name",
      },
    },
  ],
  ...config,
};
