import React from "react";
import { Route } from "react-router-dom";
import {
  MaterialItemList,
  MaterialItemAdd,
  MaterialItemEdit,
} from "../pages/MaterialItems";

export const register = (props) => {
  const basePath = "material-items";

  return (
    <>
      <Route path={basePath} element={<MaterialItemList />} exact={true} />
      <Route path={`${basePath}/add`} element={<MaterialItemAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<MaterialItemEdit />} />
    </>
  );
};
