import React from "react";
import { Route } from "react-router-dom";
import {
  VendorQuotationList,
  VendorQuotationAdd,
  VendorQuotationEdit,
  VendorQuotationView,
  VendorQuotationReport,
} from "../pages/VendorQuotations";

export const register = (props) => {
  const basePath = "vendor-quotations";

  return (
    <>
      <Route path={basePath} element={<VendorQuotationList />} exact={true} />
      <Route path={`${basePath}/add`} element={<VendorQuotationAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<VendorQuotationEdit />} />
      <Route path={`${basePath}/view/:id`} element={<VendorQuotationView />} />
      <Route path={`${basePath}-report`} element={<VendorQuotationReport />} />
    </>
  );
};
