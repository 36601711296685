// eslint-disable-next-line import/no-anonymous-default-export
export default {
  slugs: {
    singular: "sub-contractor-credit-note",
    plural: "sub-contractor-credit-notes",
  },
  entityNames: {
    singular: "Sub Contractor Credit Note",
    plural: "Sub Contractor Credit Notes",
  },
};
