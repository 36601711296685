import React, { useEffect, useState } from 'react'
import { axios } from '../../../../../App';
import { CHART_SCHEMAS, CHART_SLUGS } from '../constants';
import { fetchData } from '../../../../../utils/helpers/data.helper';
import { ReportDataTable } from '../../../components';
import { Box } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, DatePicker, Input, Row, Select, Space, Table } from 'antd';
import ChartGenerator from '../../../../../components/ChartGenerator';
import moment from "moment";


const DashboardReport = () => {
    const navigate = useNavigate();

    const [data, setData] = useState([]);
    const [projects, setProjects] = useState([])
    const [searchValues, setSearchValues] = useState({});
    const [allProjects, setAllProjects] = useState([])
    const [selectedProject, setSelectedProject] = useState(null);

    const DATE_TIME_FORMAT = "YYYY-MM-DDTHH:mm:ss[Z]";

    const fetchProjects = async () => {
        try {
            const response = await axios.get(`projects`);

            if (response?.data) {
                let options = [
                    {
                        value: "",
                        label: "All Projects",
                    },
                ];

                response?.data?.data?.forEach((record) => {
                    options?.push({
                        value: record?.id,
                        label: record?.attributes?.name,
                    });
                });

                setAllProjects(options);
            }
        } catch (error) {
            console.log(error);
        }
    };


    const fetchProjectsData = (params = null) => {
        fetchData({
            slug: "projects",
            query: {
                populate: "*",
                sort: ["start_date:desc"],
                filters: {
                    status: "Active",
                },
                // Merge the params into the query object if they exist
                ...params,
            },
            transformer: (item) => ({
                ...item,
                value: item?.id,
                label: item?.name,
            }),
            onSuccess: (data) => setProjects(data),
        });
    };


    const fetchChartData = async (inputProjectId, inputPeriodId) => {
        let requests = [];
        let newData = {};
        let slug = "";

        for (let slug of Object.values(CHART_SLUGS)) {
            requests.push(
                axios.get(
                    `/quick-projects-dashboard/${slug}`
                )
            );
        }

        const response = await Promise.all(requests);

        for (let i = 0; i < Object.values(CHART_SLUGS)?.length; i++) {
            slug = Object.values(CHART_SLUGS)?.[i];

            newData[slug] = response[i]?.data;
        }

        setData(newData);
    };

    const onSearch = () => {
        // Initialize the filters object with an empty $or array
        const filters = {
            $or: [
                {
                    name: {
                        $containsi: searchValues?.keyword?.toLowerCase(),
                    },
                },
                {
                    project_type: {
                        name: {
                            $containsi: searchValues?.keyword?.toLowerCase(),
                        },
                    }
                }
            ]
        };

        // Conditionally add date filter if start_date exists
        if (searchValues?.start_date) {
            filters.$or.push({
                createdAt: {
                    $gte: moment(searchValues?.start_date).format(DATE_TIME_FORMAT),
                    $lte: searchValues?.end_date
                        ? moment(searchValues?.end_date).format(DATE_TIME_FORMAT)
                        : undefined, // Only include end_date if it exists
                },
            });
        }

        // Conditionally add selected project filter if selectedProject is not empty
        if (selectedProject) {
            filters.$or.push({
                id: selectedProject,
            });
        }

        // Only send filters if any conditions were added
        const params = filters.$or.length > 0 ? { filters } : {};

        // Call fetchProjectsData with the constructed params
        fetchProjectsData(params);
    };



    const onReset = () => {
        setSearchValues({});
        fetchData(null);
        fetchProjectsData()
    };

    const refreshData = () => {
        fetchProjectsData()
        fetchChartData();
    };

    useEffect(() => {
        fetchProjectsData()
        fetchProjects()
        fetchChartData();
    }, []);

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Start Date',
            dataIndex: 'start_date',

        },
        {
            title: 'End Date',
            dataIndex: 'end_date',

        },
        {
            title: 'Project type',
            dataIndex: 'project_type',

        },
        {
            title: 'Status',
            dataIndex: 'status',

        },
    ];

    const table_data =
        projects?.map((project) => ({
            name: project?.name,
            start_date: project?.start_date,
            end_date: project?.end_date,
            project_type: project?.project_type?.data?.attributes?.name,
            status: project?.status
        }))

    console.log('dashboard loaded', data, projects, table_data)

    return (
        <Box>
            {/* <div id="barGraph">
          <Bar {...barConfig} />
        </div> */}

            <ReportDataTable
                data={table_data}
                config={{
                    slugs: {
                        plural: 'dashboard-report'
                    },
                    pageTitle: 'Dashboard Report',
                    pie_title: '',
                    columns: [...columns]
                }}
                actions={{
                    onRefresh: refreshData,
                }}
                Toolbar={
                    <div className="toolbar">
                        <Row gutter={16}>
                            <Col span={10}>
                                <Input
                                    value={searchValues?.keyword}
                                    placeholder="Search for keyword..."
                                    onChange={(e) =>
                                        setSearchValues((prev) => ({
                                            ...prev,
                                            keyword: e.target.value,
                                        }))
                                    }
                                />
                            </Col>

                            <Col span={7}>
                                <DatePicker
                                    onChange={(value) =>
                                        setSearchValues((prev) => ({
                                            ...prev,
                                            start_date: value,
                                        }))
                                    }
                                    value={searchValues?.start_date}
                                    placeholder="Select start date"
                                    style={styles.datePicker}
                                />
                            </Col>

                            <Col span={7}>
                                <DatePicker
                                    onChange={(value) =>
                                        setSearchValues((prev) => ({
                                            ...prev,
                                            end_date: value,
                                        }))
                                    }
                                    value={searchValues?.endDate}
                                    placeholder="Select end date"
                                    style={styles.datePicker}
                                />
                            </Col>

                            <Col span={7}>
                                <Select
                                    value={selectedProject}
                                    onChange={setSelectedProject}
                                    options={allProjects}
                                    style={{ width: "100%", marginTop: '20px' }}
                                    placeholder="Select Project"
                                />
                            </Col>
                        </Row>

                        <div className="toolbar-buttons">
                            <p className="toolbar-results"></p>

                            <Space>
                                <Button onClick={onReset}>Reset</Button>
                                <Button type="primary" onClick={onSearch}>
                                    Search
                                </Button>
                            </Space>
                        </div>
                    </div>
                }

                onBack={() => navigate(`/quick-projects/reports`)}
            />

            <Table
                columns={columns}
                dataSource={table_data}
                rowKey={(record) => record.date} // Use a unique key for each row, assuming date is unique
            />

            <Col span={24} id='barGraph'>
                <ChartGenerator
                    type="grouped-columns"
                    schema={CHART_SCHEMAS.TIMELINE_AND_RESOURCES}
                    data={data?.[CHART_SLUGS.TIMELINE_AND_RESOURCES]}
                />
            </Col>

            <Col span={24} id='pieChart'>
                {data?.[CHART_SLUGS.PHASE_COMPLETION] && (
                    <ChartGenerator
                        type="donus-group"
                        schema={CHART_SCHEMAS.PHASE_COMPLETION}
                        data={data?.[CHART_SLUGS.PHASE_COMPLETION]}
                    />
                )}
            </Col>

            <div style={{ display: 'flex', gap: '20px' }} id='pieChart2'>
                <div style={{ flex: '1' }}>
                    {data?.[CHART_SLUGS.COST_PERFORMANCE] && (
                        <ChartGenerator
                            type="pie"
                            schema={CHART_SCHEMAS.COST_PERFORMANCE}
                            data={data?.[CHART_SLUGS.COST_PERFORMANCE]}
                        />
                    )}
                </div>

                <div style={{ flex: '1' }}>
                    {data?.[CHART_SLUGS.PERFORMANCE_REVIEWS] && (
                        <ChartGenerator
                            type="pie"
                            schema={CHART_SCHEMAS.PERFORMANCE_REVIEWS}
                            data={data?.[CHART_SLUGS.PERFORMANCE_REVIEWS]}
                        />
                    )}
                </div>
            </div>

        </Box>
    )
}

const styles = {
    breadcrumbs: {},
    pageHeader: {
        padding: 0,
        paddingBottom: 12,
    },
    datePicker: {
        width: "100%",
    },
};

export default DashboardReport