import React from "react";
import { Route } from "react-router-dom";
import {
  SubContractorList,
  SubContractorAdd,
  SubContractorEdit,
  SubContractorReport,
} from "../pages/SubContractors";
import SubContractorLedger from "../pages/SubcontractorLedger/Report";
import SubcontractorBoqList from "../pages/SubContractorBoq/List";
import SubcontractorBoqAdd from "../pages/SubContractorBoq/Add";
import SubContractorBoqEdit from "../pages/SubContractorBoq/Edit";
import SubContractorBoqView from "../pages/SubContractorBoq/View";

export const register = (props) => {
  const basePath = "sub-contractors";

  return (
    <>
      <Route path={basePath} element={<SubContractorList />} exact={true} />
      <Route path={`${basePath}/add`} element={<SubContractorAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<SubContractorEdit />} />
      <Route path={`${basePath}-report`} element={<SubContractorReport />} />
      <Route path={`${basePath}-ledger-report`} element={<SubContractorLedger />} />
      <Route path={`${basePath}-boq`} element={<SubcontractorBoqList />} />
      <Route path={`${basePath}-boq/add`} element={<SubcontractorBoqAdd />} />
      <Route path={`${basePath}-boq/edit/:id`} element={<SubContractorBoqEdit />} />
      <Route path={`${basePath}-boq/view/:id`} element={<SubContractorBoqView />} />
    </>
  );
};
