import React, { useEffect, useState } from "react";
import { ViewForm } from "../../../components";
import { useNavigate, useParams } from "react-router-dom";
import { makeConfig } from "./config";
import { axios } from "../../../../../App";
import { fetchData } from "../../../../../utils/helpers/data.helper";
import qs from "qs";
import moment from "moment";

const Page = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({});
  const config = makeConfig({ navigate });
  const contentTypes = [
    {
      slug: "tasks",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          task: data,
        })),
    },
  ];

  useEffect(() => {
    contentTypes?.forEach((contentType) => {
      fetchData(contentType);
    });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const queryObject = {
          populate: "*",
        };

        const response = await axios.get(
          `/${config?.slugs?.plural}/${id}?${qs.stringify(queryObject)}`
        );

        if (response?.data) {
          setData({
            ...response?.data?.data?.attributes,
            id: response?.data?.data?.id,
            task: response?.data?.data?.attributes?.task?.data?.id,
            date: moment(response?.data?.data?.attributes?.date),
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  return (
    <ViewForm config={config} data={data} loading={loading} values={values} />
  );
};

export default Page;
