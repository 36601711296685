import React, { useState, useEffect } from "react";
import { AddForm } from "../../../components";
import { useNavigate } from "react-router-dom";
import { makeConfig } from "./config";
import { useSelector } from "react-redux";
import { fetchData } from "../../../../../utils/helpers/data.helper";

const Page = (props) => {
  const navigate = useNavigate();
  const auth = useSelector((state) => state?.auth);
  const config = makeConfig({ navigate, auth });
  const [values, setValues] = useState({
    urgency_level: ["Low", "Medium", "High"]?.map((item) => ({
      value: item,
      label: item,
    })),
  });
  const contentTypes = [
    {
      slug: "material-groups",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          material_group: data,
        })),
    },
    {
      slug: "vendor-material-requisitions",
      transformer: (item) => ({
        value: item?.id,
        label: item?.heading,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          vendor_material_requisition: data,
        })),
    },
    {
      slug: "vendors",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          vendor: data,
        })),
    },
    {
      slug: "projects",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          project: data,
        })),
    },
    {
      slug: "vendor-terms",
      transformer: (item) => ({
        value: item?.id,
        label: item?.heading,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          vendor_term: data,
        })),
    },
    {
      slug: "vendor-payment-schedules",
      transformer: (item) => ({
        value: item?.id,
        label: item?.heading,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          vendor_payment_schedule: data,
        })),
    },
    {
      slug: "locations",
      query: {
        populate: "*",
      },
      transformer: (item) => ({
        value: item?.id,
        label: `${item?.name} (${item?.location_type?.data?.attributes?.name})`,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          delivery_address: data,
        })),
    },
    {
      slug: "employees",
      transformer: (item) => ({
        value: item?.id,
        label: `${item?.first_name} ${item?.last_name}`,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
        //  approved_by: data,
        })),
    },
     {
      slug: "employees/approvers",
      transformer: (item) => ({
        value: item?.id,
        label: `${item?.first_name} ${item?.last_name}`,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
         approved_by: data,
        })),
    },
  ];

  useEffect(() => {
    contentTypes?.forEach((contentType) => {
      fetchData(contentType);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <AddForm config={config} values={values} />;
};

export default Page;
