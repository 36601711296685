import React from "react";
import { Route } from "react-router-dom";
import {
  AttendanceList,
  AttendanceAdd,
  AttendanceEdit,
  AttendanceReport,
} from "../pages/Attendances";

export const register = (props) => {
  const basePath = "attendances";

  return (
    <>
      <Route path={basePath} element={<AttendanceList />} exact={true} />
      <Route path={`${basePath}/add`} element={<AttendanceAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<AttendanceEdit />} />
      <Route path={`${basePath}-report`} element={<AttendanceReport />} />
    </>
  );
};
