export default {
  slugs: {
    singular: "work-item",
    plural: "work-items",
  },
  entityNames: {
    singular: "Work Item",
    plural: "Work Items",
  },
};
