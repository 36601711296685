import React, { useState, useEffect } from "react";
import { DataTable } from "../../../components";
import { useNavigate, useLocation } from "react-router-dom";
import { Space, Button, Tooltip, notification, Input } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import qs from "qs";
import config from "./config";
import { axios } from "../../../../../App";
import _ from "lodash";
import { useSelector } from "react-redux";
import { BsCheckCircle } from "react-icons/bs";

const Page = (props) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    pageSize: 10,
  });
  const [total, setTotal] = useState(0);
  const [searchValues, setSearchValues] = useState({});
  const [searched, setSearched] = useState(false);
  const [taskId, setTaskId] = useState("");
  const location = useLocation();
  const { user } = useSelector((state) => state?.auth);

  const fetchData = async (params = null) => {
    try {
      setLoading(true);

      const queryObject = {
        populate: "*",
        sort: ["createdAt:desc"],
      };

      if (!_.isEmpty(params)) {
        queryObject["filters"] = params;
      }

      const response = await axios.get(
        `task-works?${qs.stringify(queryObject)}`
      );

      if (response?.data) {
        setData(
          response?.data?.data?.map((item) => ({
            id: item?.id,
            ...item?.attributes,
          }))
        );

        setTotal(response?.data?.meta?.pagination?.total);

        setPagination({
          ...response?.data?.meta?.pagination,
          pageSize: 10,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const refreshData = () => {
    onSearch();
  };

  const getColumnFilterProps = ({ dataIndex, references = {} }) => {
    if (typeof references?.fieldName === "function") {
      const transformer = references?.fieldName;

      return {
        render: (data) => transformer(data?.data?.attributes) || "N/A",
      };
    } else {
      return {
        render: (data) =>
          data?.data?.attributes?.[references?.fieldName] || "N/A",
      };
    }
  };

  const getColumnProps = (column) => {
    if (column?.references) {
      return getColumnFilterProps(column);
    }

    return column;
  };

  const onSearch = () => {
    setSearched(true);

    let orConditions = {
      $or: [
        {
          description: {
            $containsi: searchValues?.keyword?.toLowerCase(),
          },
        },
      ],
    };

    let searchParams = {
      $and: [
        // {
        //   status: "Requested",
        // },
        orConditions,
      ],
    };

    if (taskId !== "") {
      searchParams = {
        $and: [
          {
            task: parseInt(taskId),
          },
          // {
          //   status: "Requested",
          // },
          getFiltersForRoles(),
          orConditions,
        ],
      };
    }

    fetchData(searchParams);
  };

  const onReset = () => {
    setSearchValues({});
    fetchData(getFiltersForRoles());
    setSearched(false);
  };

  const getResultsMessage = () => {
    if (!_.isEmpty(searchValues) && !loading && searched) {
      return total > 0 ? (
        <span>
          <strong>{total}</strong> matched results
        </span>
      ) : (
        <span>No matched results</span>
      );
    }

    return (
      <span>
        <strong>{total}</strong> total records
      </span>
    );
  };

  const getFiltersForRoles = () => {
    // const userType = user?.user_type?.toLowerCase()?.replace(/\s/i, "_");

    // if (user?.is_admin) {
    //   return {};
    // }

    // return {
    //   project: {
    //     [Pluralize(userType)]: {
    //       uid: user?.uid,
    //     },
    //   },
    // };

    if (user?.is_admin) {
      return {
        // status: "Requested",
      };
    }

    return {
      $and: [
        // {
        //   status: "Requested",
        // },
        {
          task: {
            assignees: {
              uid: user?.uid,
            },
          },
        },
      ],
    };
  };

  useEffect(() => {
    const queryString = location?.search?.substring(1);

    if (queryString !== "") {
      const queryObject = qs.parse(queryString);

      if (queryObject?.taskId) {
        setTaskId(`${queryObject?.taskId}`);
        fetchData({
          $and: [
            {
              task: parseInt(queryObject?.taskId),
            },
            // {
            //   status: "Requested",
            // },
            getFiltersForRoles(),
          ],
        });
      } else {
        fetchData(getFiltersForRoles());
      }
    } else {
      fetchData(getFiltersForRoles());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.search]);

  return (
    <DataTable
      data={data}
      config={{
        ...config,
        columns: config?.columns
          ?.map((column) => ({
            ...column,
            ...getColumnProps(column),
          }))
          ?.concat({
            title: "Actions",
            fixed: "right",
            width: 100,
            selectable: false,
            render: (item) => {
              return (
                <Tooltip
                  placement="bottom"
                  title={`Select ${config?.entityNames?.singular}`}
                >
                  <Button
                    icon={
                      <BsCheckCircle
                        style={{ margin: "0 auto" }}
                        onClick={() => props?.onSubmit(item)}
                      />
                    }
                  />
                </Tooltip>
              );
            },
          }),
      }}
      loading={loading}
      pagination={pagination}
      actions={{
        onAdd: () => navigate(`/quick-stocks/${config?.slugs?.plural}/add`),
        onRefresh: refreshData,
      }}
      Toolbar={
        <div className="toolbar">
          <Input
            value={searchValues?.keyword}
            placeholder="Search for keyword..."
            onChange={(e) =>
              setSearchValues((prev) => ({
                ...prev,
                keyword: e.target.value,
              }))
            }
          />

          <div className="toolbar-buttons">
            <p className="toolbar-results">{getResultsMessage()}</p>

            <Space>
              <Button onClick={onReset}>Reset</Button>
              <Button type="primary" onClick={onSearch}>
                Search
              </Button>
            </Space>
          </div>
        </div>
      }
      buttons={[
        <Tooltip placement="top" title="Refresh">
          <Button
            icon={<ReloadOutlined />}
            onClick={refreshData}
            loading={loading}
          />
        </Tooltip>,
      ]}
      showsBreadcrumbs={false}
    />
  );
};

export default Page;
