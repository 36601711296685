import React from "react";
import { Route } from "react-router-dom";
import {
  MeetingList,
  MeetingAdd,
  MeetingEdit,
  MeetingReport,
  MeetingView,
  MeetingReschedule,
} from "../pages/Meetings";

export const register = (props) => {
  const basePath = "meetings";

  return (
    <>
      <Route path={basePath} element={<MeetingList />} exact={true} />
      <Route path={`${basePath}/add`} element={<MeetingAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<MeetingEdit />} />
      <Route path={`${basePath}/view/:id`} element={<MeetingView />} />
      <Route
        path={`${basePath}/reschedule/:id`}
        element={<MeetingReschedule />}
      />
      <Route path={`${basePath}-report`} element={<MeetingReport />} />
    </>
  );
};
