export const makeConfig = () => ({
  id: `employee-group-chats`,
  title: `Employee Group Chats`,
  pageTitle: `Employee Group Chats`,
  breadcrumbs: [
    {
      path: "/quick-talks/dashboard",
      title: "Home",
    },
    {
      path: "",
      title: "Employee Group Chats",
    },
  ],
  conversationType: "employee-group",
});
