export default {
  slugs: {
    singular: "partner",
    plural: "partners",
  },
  entityNames: {
    singular: "Partner",
    plural: "Partners",
  },
};
