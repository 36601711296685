import config from "../config";
import { Tag } from "antd";

export default {
  id: `${config?.slugs?.plural}-list`,
  pageTitle: config?.entityNames?.plural,
  breadcrumbs: [
    {
      path: "/quick-setup",
      title: "Home",
    },
    {
      path: "",
      title: config?.entityNames?.plural,
    },
  ],
  columns: [
    {
      title: "Username",
      dataIndex: "username",
      selectable: true,
      sorter: (a, b) => a?.username?.localeCompare(b?.username),
    },
    {
      title: "User Type",
      dataIndex: "user_type",
      selectable: true,
      filterable: true,
    },
    // {
    //   title: "Role",
    //   dataIndex: "app_role",
    //   selectable: true,
    //   filterable: true,
    //   references: {
    //     fieldName: "name",
    //   },
    // },
    {
      title: "Status",
      dataIndex: "status",
      selectable: true,
      render: (text) => (
        <Tag color={text === "Active" ? "green" : ""}>{text}</Tag>
      ),
    },
  ],
  ...config,
};
