import React, { useRef, useEffect, useState } from "react";
import { PageHeader, Breadcrumb, Col, DatePicker, Row, Input, Tooltip, Button, Space, Select } from "antd";
import config from "./config";
import { useNavigate, Link } from "react-router-dom";
import { Box } from "@chakra-ui/react";
import { ReportDataTable } from "../../../components";
import { axios } from "../../../../../App";
import { MAX_PAGINATION_LIMIT } from "../../../../../constants";
import qs from "qs";
import _ from "lodash";
import moment from "moment";
import { useSelector } from "react-redux";
import pluralize from "pluralize";
import { ReloadOutlined, DownloadOutlined } from "@ant-design/icons";
import { Pie } from "@ant-design/plots";

const DATE_TIME_FORMAT = "YYYY-MM-DDTHH:mm:ss[Z]";

const Page = (props) => {
  const navigate = useNavigate();
  const iframeRef = useRef(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    pageSize: 10,
  });
  const [total, setTotal] = useState(0);
  const [searchValues, setSearchValues] = useState({});
  const [searched, setSearched] = useState(false);
  const [tasks, setTasks] = useState([])
  const [selectedTask, setselectedTask] = useState("");
  const [chartData, setChartData] = useState([])
  const { user } = useSelector((state) => state?.auth);

  const onBack = () => navigate(`/quick-projects/reports`);

  const setIframeHeight = () => {
    const iframe = iframeRef.current;
    if (iframe) {
      iframe.style.height = window.innerWidth <= 600 ? '450px' : '690px';
    }
  };

  const fetchData = async (params = null) => {
    try {
      setLoading(true);

      const queryObject = {
        populate: "*",
        sort: ["createdAt:desc"],
        pagination: {
          limit: MAX_PAGINATION_LIMIT,
        },
      };

      if (!_.isEmpty(params)) {
        queryObject["filters"] = params;
      }

      const response = await axios.get(
        `${config?.slugs?.plural}?${qs.stringify(queryObject)}`
      );

      if (response?.data) {
        let options = [
          {
            value: "",
            label: "All Task Materials",
          },
        ];

        const fetchedData = response?.data?.data?.map((item) => ({
          id: item?.id,
          ...item?.attributes,
        }));

        setData(fetchedData);

        const aggregatedChartData = fetchedData?.reduce((acc, record) => {
          const materialName = record?.material_item?.data?.attributes?.name;
          const quantity = record?.quantity;
          if (materialName) {
            const existing = acc.find(item => item.type === materialName);
            if (existing) {
              existing.value += quantity;
            } else {
              acc.push({
                type: materialName,
                value: quantity,
              });
            }
          }
          return acc;
        }, []);

        response?.data?.data?.forEach((record) => {
          options.push({
            value: record?.attributes?.material_item?.data?.attributes?.name,
            label: record?.attributes?.material_item?.data?.attributes?.name,
          });
        });

        setChartData(aggregatedChartData);

        response?.data?.data?.forEach((record) => {
          options?.push({
            value: record?.attributes?.material_item?.data?.attributes?.name,
            label: record?.attributes?.material_item?.data?.attributes?.name,
          });
        });

        setTasks(options);

        setTotal(response?.data?.meta?.pagination?.total);

        setPagination({
          ...response?.data?.meta?.pagination,
          pageSize: 10,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const downloadReport = () => {
    try {
      const data = {
        code: config?.code,
        params: {
          start_date: searchValues?.start_date
            ? searchValues?.start_date?.format("YYYY-MM-DD")
            : moment().subtract(1000, "years").format("YYYY-MM-DD"),
          end_date: searchValues?.end_date
            ? searchValues?.end_date?.format("YYYY-MM-DD")
            : moment().add(1000, "years").format("YYYY-MM-DD"),
        },
      };

      const link = document.createElement("a");
      link.href = `${process.env.REACT_APP_BASE_API_URL
        }/reporter?${qs.stringify(data)}`;

      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.log(error);
    }
  };

  const getColumnFilterProps = ({ dataIndex, references = {} }) => {
    if (typeof references?.fieldName === "function") {
      const transformer = references?.fieldName;

      return {
        render: (data) => transformer(data?.data?.attributes) || "N/A",
      };
    } else {
      return {
        render: (data) =>
          data?.data?.attributes?.[references?.fieldName] || "N/A",
      };
    }
  };

  const getColumnProps = (column) => {
    if (column?.references) {
      return getColumnFilterProps(column);
    }

    return column;
  };

  const refreshData = () => {
    onSearch();
  };

  const onReset = () => {
    setSearchValues({});
    fetchData(getFiltersForRoles());
    setSearched(false);
  };

  const getFiltersForRoles = () => {
    const userType = user?.user_type?.toLowerCase()?.replace(/\s/i, "_");

    if (user?.is_admin) {
      return {};
    }

    if (userType === "employee") {
      return {
        $or: [
          {
            project_managers: {
              uid: user?.uid,
            },
          },
          {
            members: {
              uid: user?.uid,
            },
          },
        ],
      };
    }

    return {
      [pluralize(userType)]: {
        uid: user?.uid,
      },
    };
  };

  useEffect(() => {
    fetchData(getFiltersForRoles());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getResultsMessage = () => {
    if (!_.isEmpty(searchValues) && !loading && searched) {
      return total > 0 ? (
        <span>
          <strong>{total}</strong> matched results
        </span>
      ) : (
        <span>No matched results</span>
      );
    }

    return (
      <span>
        <strong>{total}</strong> total records
      </span>
    );
  };

  const renderHeaderButtons = () => {
    let buttons = [
      <Tooltip placement="top" title="Refresh">
        <Button
          icon={<ReloadOutlined />}
          onClick={refreshData}
          loading={loading}
        />
      </Tooltip>,
    ];

    // if (data?.length > 0) {
    //   buttons.push(
    //     <Tooltip placement="top" title="Download Report">
    //       <Button icon={<DownloadOutlined />} onClick={downloadReport} />
    //     </Tooltip>
    //   );
    // }

    return buttons;
  };

  const onSearch = () => {
    setSearched(true);

    fetchData({
      $and: [
        {
          $or: [
            {
              name: {
                $containsi: searchValues?.keyword?.toLowerCase(),
              },
            },
            {
              pin_code: {
                $containsi: searchValues?.keyword?.toLowerCase(),
              },
            },
            {
              rera_no: {
                $containsi: searchValues?.keyword?.toLowerCase(),
              },
            },
            {
              address_line_1: {
                $containsi: searchValues?.keyword?.toLowerCase(),
              },
            },
            {
              address_line_2: {
                $containsi: searchValues?.keyword?.toLowerCase(),
              },
            },
            {
              description: {
                $containsi: searchValues?.keyword?.toLowerCase(),
              },
            },
            {
              material_item: {
                name: {
                  $containsi: selectedTask,
                }
              }
            },
            {
              createdAt: {
                $gte: moment(searchValues?.start_date).format(DATE_TIME_FORMAT),
                $lte: moment(searchValues?.end_date).format(DATE_TIME_FORMAT),
              },
            },
          ],
        },
        getFiltersForRoles(),
      ],
    });
  };

  useEffect(() => {
    setIframeHeight();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isMobile]);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 600);
  };

  const pieConfig = {
    appendPadding: 10,
    data: chartData,
    angleField: 'value',
    colorField: 'type',
    radius: 1,
    label: {
      type: 'inner',
      offset: '-30%',
      content: '{value}',
      style: {
        fontSize: 14,
        textAlign: 'center',
      },
    },
    interactions: [{ type: 'element-active' }],
  };


  return (
    <>
      {/* <Breadcrumb style={styles.breadcrumbs}>
      {config?.breadcrumbs?.map((breadcrumb, index) => (
        <Breadcrumb.Item key={index}>
          <Link to={breadcrumb?.path}>{breadcrumb?.title}</Link>
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>

    <PageHeader
      title={config?.pageTitle}
      style={styles.pageHeader}
      onBack={onBack}
    /> */}

      {/* <Box w="100%" pt={4}>
      <iframe
        ref={iframeRef}
        id="embeddedReport"
        title="Task Material Report"
        src="https://lookerstudio.google.com/embed/reporting/0896ca74-aa87-48b4-91b6-ba926a93f867/page/p9njD"
         src="https://lookerstudio.google.com/embed/reporting/e7475e4a-c4a0-4303-868b-8851ea26bdde/page/sxhjD"
        width="100%"
        height="890px"
        allowFullScreen
        sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
        style={{ display: isMobile ? 'none' : 'block', boxShadow: "0 0 20px 0 rgb(29 31 89 / 10%)", borderRadius: 12 }}
      />
      {isMobile && (
        <iframe
          width="100%"
          height="900px"
          src="https://lookerstudio.google.com/embed/reporting/5b92f6fe-3dd0-455d-9b6d-6bfbff9e6f66/page/p9njD"
          frameborder="0"
          style={{ boxShadow: "0 0 20px 0 rgb(29 31 89 / 10%)", borderRadius: 12 }}
          allowfullscreen
          sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
        ></iframe>
      )}
    </Box> */}

      <ReportDataTable
        data={data}
        config={{
          ...config,
          columns: config?.columns?.map((column) => ({
            ...column,
            ...getColumnProps(column),
          })),
        }}
        loading={loading}
        pagination={pagination}
        actions={{
          onAdd: () => navigate(`/quick-projects/${config?.slugs?.plural}/add`),
          onRefresh: refreshData,
        }}
        Toolbar={
          <div className="toolbar">
            <Row gutter={16}>
              <Col span={12}>
                <Input
                  value={searchValues?.keyword}
                  placeholder="Search for keyword..."
                  onChange={(e) =>
                    setSearchValues((prev) => ({
                      ...prev,
                      keyword: e.target.value,
                    }))
                  }
                />
              </Col>

              <Col span={6}>
                <DatePicker
                  onChange={(value) =>
                    setSearchValues((prev) => ({
                      ...prev,
                      start_date: value,
                    }))
                  }
                  value={searchValues?.start_date}
                  placeholder="Select start date"
                  style={styles.datePicker}
                />
              </Col>

              <Col span={6}>
                <DatePicker
                  onChange={(value) =>
                    setSearchValues((prev) => ({
                      ...prev,
                      end_date: value,
                    }))
                  }
                  value={searchValues?.endDate}
                  placeholder="Select end date"
                  style={styles.datePicker}
                />
              </Col>

              <Col span={7}>
                <Select
                  value={selectedTask}
                  onChange={setselectedTask}
                  options={tasks}
                  style={{ width: "100%", marginTop: '20px' }}
                  placeholder="Select Project"
                />
              </Col>
            </Row>

            <div className="toolbar-buttons">
              <p className="toolbar-results">{getResultsMessage()}</p>

              <Space>
                <Button onClick={onReset}>Reset</Button>
                <Button type="primary" onClick={onSearch}>
                  Search
                </Button>
              </Space>
            </div>
          </div>
        }
        buttons={renderHeaderButtons()}
        onBack={() => navigate(`/quick-projects/reports`)}
      />

      <div id="pieChart">
        <Pie {...pieConfig} />
      </div>
    </>
  );
};

const styles = {
  breadcrumbs: {},
  pageHeader: {
    padding: 0,
    paddingBottom: 12,
  },
};

export default Page;
