import { axios } from "../../../../../App";
import { v4 } from "uuid";
import config from "../config";

export const makeConfig = ({ navigate, auth }) => ({
  id: `${config?.slugs?.plural}-add`,
  title: `Add New ${config?.entityNames?.singular}`,
  pageTitle: `Add New ${config?.entityNames?.singular}`,
  breadcrumbs: [
    {
      path: "/quick-projects/dashboard",
      title: "Home",
    },
    {
      path: `/quick-projects/${config?.slugs?.plural}`,
      title: config?.entityNames?.plural,
    },
    {
      path: "",
      title: `Add New ${config?.entityNames?.singular}`,
    },
  ],
  components: [
    {
      span: 24,
      component: "input",
      label: "Subject",
      name: "subject",
      required: true,
      placeholder: "Enter subject",
      rules: [
        {
          required: true,
          message: "Subject is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Project",
      name: "project",
      placeholder: "Select project",
      required: true,
      rules: [
        {
          required: true,
          message: "Project is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Cost Code",
      name: "cost_code",
      placeholder: "Select cost code",
      // required: true,
      // rules: [
      //   {
      //     required: true,
      //     message: "Cost code is required!",
      //   },
      // ],
    },
    {
      span: 12,
      component: "date",
      label: "Due Date",
      name: "due_date",
      required: true,
      placeholder: "Select due date",
      rules: [
        {
          required: true,
          message: "Due date is required!",
        },
      ],
    },
    {
      span: 12,
      component: "date",
      label: "Replied Date",
      name: "replied_date",
      // required: true,
      placeholder: "Select replied date",
      // rules: [
      //   {
      //     required: true,
      //     message: "Replied date is required!",
      //   },
      // ],
    },
    {
      span: 12,
      component: "input",
      label: "Discipline",
      name: "discipline",
      // required: true,
      placeholder: "Enter discipline",
      // rules: [
      //   {
      //     required: true,
      //     message: "Discipline is required!",
      //   },
      // ],
    },
    {
      span: 12,
      component: "input",
      label: "Drawing Reference",
      name: "drawing_reference",
      // required: true,
      placeholder: "Enter drawing reference",
      // rules: [
      //   {
      //     required: true,
      //     message: "Drawing reference is required!",
      //   },
      // ],
    },
    {
      span: 12,
      component: "uploader",
      label: "Sketch Reference",
      name: "sketch_reference",
      required: true,
      placeholder: "Enter sketch reference",
      rules: [
        {
          required: true,
          message: "Sketch reference is required!",
        },
      ],
    },
    {
      span: 12,
      component: "input",
      label: "Specification Section Reference",
      name: "specification_section_reference",
      // required: true,
      placeholder: "Enter specification section reference",
      // rules: [
      //   {
      //     required: true,
      //     message: "Specification section reference is required!",
      //   },
      // ],
    },

    {
      span: 12,
      component: "select",
      label: "Assigned To",
      name: "assigned_to",
      placeholder: "Select employee",
      required: true,
      rules: [
        {
          required: true,
          message: "Assigned to is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Verified By",
      name: "verified_by",
      placeholder: "Select employee",
      // required: true,
      // rules: [
      //   {
      //     required: true,
      //     message: "Verified by is required!",
      //   },
      // ],
    },
    {
      span: 12,
      component: "select",
      label: "Sub Contractor",
      name: "sub_contractor",
      placeholder: "Select sub contractor",
      // required: true,
      // rules: [
      //   {
      //     required: true,
      //     message: "Sub contractor is required!",
      //   },
      // ],
    },
    {
      span: 12,
      component: "select",
      label: "Distribution List",
      name: "distribution_list",
      placeholder: "Select distribution list",
      // required: true,
      // rules: [
      //   {
      //     required: true,
      //     message: "Distribution list is required!",
      //   },
      // ],
    },
    {
      span: 12,
      component: "select",
      label: "Once Answered",
      name: "once_answered",
      placeholder: "Select once answered",
      // required: true,
      // rules: [
      //   {
      //     required: true,
      //     message: "Once answered is required!",
      //   },
      // ],
    },
    {
      span: 12,
      component: "uploader",
      label: "Drawing Portion Snapshot",
      name: "drawing_portion",
      placeholder: "Upload snapshot",
    },
    {
      span: 12,
      component: "uploader",
      label: "RFI Hyperlinked Snapshot",
      name: "drawing_rfi",
      placeholder: "Upload snapshot",
    },
    {
      span: 24,
      component: "editor",
      label: "Question",
      name: "question",
      placeholder: "Enter question",
      rows: 5,
    },
    {
      span: 24,
      component: "editor",
      label: "Answer",
      name: "answer",
      placeholder: "Enter answer",
      rows: 5,
    },
    {
      span: 24,
      component: "editor",
      label: "Suggestion",
      name: "suggestion",
      placeholder: "Enter suggestion",
      rows: 5,
    },
    {
      span: 24,
      component: "input",
      type: "hidden",
      name: "drawing_portion",
    },
    {
      span: 24,
      component: "input",
      type: "hidden",
      name: "drawing_rfi",
    },
  ],
  actions: {
    onBack: () => navigate(`/quick-projects/${config?.slugs?.plural}`),
    onFinish: async (values) => {
      try {
        const response = await axios.post(`/${config?.slugs?.plural}`, {
          data: {
            ...values,
            uid: v4(),
            due_date: values?.due_date || null,
            replied_date: values?.replied_date || null,
            drawing_portion: values?.drawing_portion
              ? values?.drawing_portion
              : null,
            drawing_rfi: values?.drawing_rfi ? values?.drawing_rfi : null,
            // creator: auth?.user?.id,
          },
        });

        if (response?.data) {
          return {
            success: true,
            message: `Add new ${config?.entityNames?.singular?.toLowerCase()} successfully!`,
          };
        }

        throw new Error();
      } catch (error) {
        return {
          success: false,
          message: `Add new ${config?.entityNames?.singular?.toLowerCase()} failed!`,
        };
      }
    },
  },
});
