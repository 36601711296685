export default {
  slugs: {
    singular: "holiday",
    plural: "holidays",
  },
  entityNames: {
    singular: "Holiday",
    plural: "Holidays",
  },
};
