import React from "react";
import { Route } from "react-router-dom";
import {
  LeaveTypeList,
  LeaveTypeAdd,
  LeaveTypeEdit,
} from "../pages/LeaveTypes";

export const register = (props) => {
  const basePath = "leave-types";

  return (
    <>
      <Route path={basePath} element={<LeaveTypeList />} exact={true} />
      <Route path={`${basePath}/add`} element={<LeaveTypeAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<LeaveTypeEdit />} />
    </>
  );
};
