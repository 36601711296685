import React from "react";
import { Column } from "@ant-design/plots";

const Chart = ({ data = [], xField, yField }) => {
  const config = {
    data,
    xField,
    yField,
    columnWidthRatio: 0.8,
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
  };
  return <Column {...config} />;
};

export default Chart;
