import React from "react";
import { Route } from "react-router-dom";
import {
  ReimburseList,
  ReimburseAdd,
  ReimburseEdit,
  ReimburseReport,
} from "../pages/Reimburses";

export const register = (props) => {
  const basePath = "reimburses";

  return (
    <>
      <Route path={basePath} element={<ReimburseList />} exact={true} />
      <Route path={`${basePath}/add`} element={<ReimburseAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<ReimburseEdit />} />
      <Route path={`${basePath}-report`} element={<ReimburseReport />} />
    </>
  );
};
