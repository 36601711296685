export default {
  slugs: {
    singular: "location",
    plural: "locations",
  },
  entityNames: {
    singular: "Location",
    plural: "Locations",
  },
};
