import React, { useState, useEffect } from "react";
import { ModalAddForm } from "../../../components";
import { useNavigate } from "react-router-dom";
import { makeConfig } from "./config";
import { useSelector } from "react-redux";
import { fetchData } from "../../../../../utils/helpers/data.helper";
import { STRAPI_URL } from "../../../../../constants";

const EditInvoice = (props) => {
  const navigate = useNavigate();
  const auth = useSelector((state) => state?.auth);
  const config = makeConfig({ navigate, auth });
  const [values, setValues] = useState({});
  const [selectedMaterialItem, setSelectedMaterialItem] = useState(null)
  const [materialImage, setmaterialImage] = useState(null)
  const contentTypes = [
    {
      slug: "gst-types",
      transformer: (item) => ({
        value: item?.rate,
        label: `${item?.name} (${item?.rate}%)`,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          gst: data,
        })),
    },
    {
      slug: "material-groups",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          material_group: data,
        })),
    },
    {
      slug: "material-items",
      query: {
        populate: "*",
      },
      // transformer: (item) => ({
      //   value: item?.id,
      //   label: item?.name,
      // }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          all_material_item: data,
        })),
    },
  ];

  useEffect(() => {
    contentTypes?.forEach((contentType) => {
      fetchData(contentType);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const image = values?.material_item?.find((mat) => mat.value === selectedMaterialItem)

    if (image) {
      setmaterialImage(image?.material_image?.data?.attributes)
    }

  }, [selectedMaterialItem])

  console.log('props:', props);

  return (
    <>
      {materialImage && (
        <div style={{ marginBottom: '20px' }}>
          <img src={`${STRAPI_URL}${materialImage?.url}`} alt="" style={{ width: '80px', height: '80px', objectFit: 'contain' }} />
        </div>
      )}

      <ModalAddForm
        config={config}
        values={values}
        data={props.data}
        onSubmit={props?.onSubmit}
        onCancel={props?.onCancel}
        onFieldChange={{
          material_group: (antForm) => {
            const materialGroupId = antForm.getFieldValue("material_group");

            antForm.setFieldValue("material_item", undefined);

            if (!materialGroupId) {
              setValues((prev) => ({
                ...prev,
                material_item: [],
              }));
            } else {
              setValues((prev) => ({
                ...prev,
                material_item: values?.all_material_item
                  ?.filter(
                    (item) => item?.material_group?.data?.id === materialGroupId
                  )
                  ?.map((item) => ({
                    value: item?.id,
                    label: item?.name,
                    ...item
                  })),
              }));
            }
          },

          material_item: (antForm) => {
            const materialItemId = antForm.getFieldValue("material_item");

            setSelectedMaterialItem(materialItemId)
          }
        }}
      />
    </>
  );
};

export default EditInvoice;
