import React from "react";
import { Route } from "react-router-dom";
import {
  HandToolList,
  HandToolAdd,
  HandToolEdit,
  HandToolReport,
} from "../pages/HandTools";

export const register = (props) => {
  const basePath = "hand-tools";

  return (
    <>
      <Route path={basePath} element={<HandToolList />} exact={true} />
      <Route path={`${basePath}/add`} element={<HandToolAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<HandToolEdit />} />
      <Route path={`${basePath}-report`} element={<HandToolReport />} />
    </>
  );
};
