import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { ChakraProvider } from "@chakra-ui/react";

import "react-awesome-lightbox/build/style.css";
import "./index.css";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";

// import './i18n'; 
// import { I18nextProvider } from 'react-i18next';

// Apollo client for GraphQl API
const client = new ApolloClient({
  uri: `${process.env.REACT_APP_BASE_API_URL}/graphql`,
  // uri: `http://192.168.0.114/graphql`,
  cache: new InMemoryCache(),
});

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <ChakraProvider>
      {/* <I18nextProvider> */}
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
      {/* </I18nextProvider>   */}
    </ChakraProvider>
  </React.StrictMode>
);

serviceWorkerRegistration.register();