import React, { useRef, useEffect, useState } from "react";
import { PageHeader, Breadcrumb } from "antd";
import config from "./config";
import { useNavigate, Link } from "react-router-dom";
import { Box } from "@chakra-ui/react";

const Page = (props) => {
  const navigate = useNavigate();
  const iframeRef = useRef(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

  const onBack = () => navigate(`/quick-projects/reports`);

  const setIframeHeight = () => {
    const iframe = iframeRef.current;
    if (iframe) {
      iframe.style.height = isMobile ? '450px' : '690px';
    }
  };

  useEffect(() => {
    setIframeHeight();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isMobile]);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 600);
  };

  return (
    <Box>
      <Breadcrumb style={styles.breadcrumbs}>
        {config?.breadcrumbs?.map((breadcrumb, index) => (
          <Breadcrumb.Item key={index}>
            <Link to={breadcrumb?.path}>{breadcrumb?.title}</Link>
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>

      <PageHeader
        title={config?.pageTitle}
        style={styles.pageHeader}
        onBack={onBack}
      />

      <Box w="100%" pt={4} overflow="hidden">
        <iframe
          ref={iframeRef}
          id="embeddedReport"
          title="Project Material Report"
          src="https://lookerstudio.google.com/embed/reporting/4dfbb91e-804b-49b5-a26e-4718e729b982/page/7ZrxD"
          width="100%"
          height="690px"
          allowFullScreen
          sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
          style={{ display: isMobile ? 'none' : 'block', boxShadow: "0 0 20px 0 rgb(29 31 89 / 10%)", borderRadius: 12 }}
        />
        {isMobile && (
          <iframe
            width="100%"
            height="1030px"
            src="https://lookerstudio.google.com/embed/reporting/5e8b4a91-d3e8-4a62-a36d-ead6becec876/page/7ZrxD"
            frameborder="0"
            style={{ boxShadow: "0 0 20px 0 rgb(29 31 89 / 10%)", borderRadius: 12 }}
            allowfullscreen
            sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
          ></iframe>
        )}
      </Box>
    </Box>
  );
};

const styles = {
  breadcrumbs: {},
  pageHeader: {
    padding: 0,
    paddingBottom: 12,
  },
};

export default Page;
