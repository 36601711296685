import React from "react";
import { Route } from "react-router-dom";
import {
  ContactGroupList,
  ContactGroupAdd,
  ContactGroupEdit,
} from "../pages/ContactGroups";

export const register = (props) => {
  const basePath = "contact-groups";

  return (
    <>
      <Route path={basePath} element={<ContactGroupList />} exact={true} />
      <Route path={`${basePath}/add`} element={<ContactGroupAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<ContactGroupEdit />} />
    </>
  );
};
