import React from "react";
import { Route } from "react-router-dom";
import {
  MeetingNoteList,
  MeetingNoteAdd,
  MeetingNoteEdit,
  MeetingNoteReport,
} from "../pages/MeetingNotes";

export const register = (props) => {
  const basePath = "meeting-notes";

  return (
    <>
      <Route path={basePath} element={<MeetingNoteList />} exact={true} />
      <Route path={`${basePath}/add`} element={<MeetingNoteAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<MeetingNoteEdit />} />
      <Route path={`${basePath}-report`} element={<MeetingNoteReport />} />
    </>
  );
};
