import React, { useState, useEffect } from "react";
import { ModalAddForm } from "../../../components";
import { useNavigate } from "react-router-dom";
import { makeConfig } from "./config";
import { useSelector } from "react-redux";
import { fetchData } from "../../../../../utils/helpers/data.helper";

const Page = (props) => {
  const navigate = useNavigate();
  const auth = useSelector((state) => state?.auth);
  const config = makeConfig({ navigate, auth });
  const [values, setValues] = useState({
    material_item: [],
    all_material_item: [],
  });
  const contentTypes = [
    {
      slug: "material-groups",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          material_group: data,
        })),
    },
    {
      slug: "material-items",
      query: {
        populate: "*",
      },
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          all_material_item: data,
        })),
    },
    {
      slug: "material-units",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          material_unit: data,
        })),
    },
    {
      slug: "vendors",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          vendor: data,
        })),
    },
  ];

  useEffect(() => {
    contentTypes?.forEach((contentType) => {
      fetchData(contentType);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ModalAddForm
      config={config}
      values={values}
      onSubmit={props?.onSubmit}
      onCancel={props?.onCancel}
      onFieldChange={{
        material_group: (antForm) => {
          const materialGroupId = antForm.getFieldValue("material_group");

          antForm.setFieldValue("material_item", undefined);

          if (!materialGroupId) {
            setValues((prev) => ({
              ...prev,
              material_item: [],
            }));
          } else {
            setValues((prev) => ({
              ...prev,
              material_item: values?.all_material_item
                ?.filter(
                  (item) => item?.material_group?.data?.id === materialGroupId
                )
                ?.map((item) => ({
                  value: item?.id,
                  label: item?.name,
                })),
            }));
          }
        },
      }}
    />
  );
};

export default Page;
