import config from "../config";
import moment from "moment";

const DATE_TIME_FORMAT = "YYYY-MM-DDTHH:mm:ss[Z]";

const commify = (x) => {
  return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export default {
  id: `${config?.slugs?.plural}-list`,
  pageTitle: `${config?.entityNames?.plural} Report`,
  code: "TASK_MATERIALS",
  breadcrumbs: [
    {
      path: "/quick-stocks/dashboard",
      title: "Home",
    },
    {
      path: `/quick-stocks/reports`,
      title: "Reports",
    },
    {
      path: "",
      title: `${config?.entityNames?.plural} Report`,
    },
  ],
  columns: [
    {
      title: "Project Name",
      dataIndex: "task_project",
      selectable: true,
      references: {
        fieldName: "name",
      },
    },
    {
      title: "Task",
      dataIndex: "task",
      selectable: true,
      references: {
        fieldName: "name",
      },
    },
    // {
    //   title: "Material Group",
    //   dataIndex: "material_group",
    //   selectable: true,
    //   references: {
    //     fieldName: "name",
    //   },
    // },
    {
      title: "Material Item",
      dataIndex: "material_item",
      selectable: true,
      references: {
        fieldName: "name",
      },
    },
    // {
    //   title: "Material Unit",
    //   dataIndex: "material_unit",
    //   selectable: true,
    //   references: {
    //     fieldName: "name",
    //   },
    // },
    {
      title: "Budget Estimated",
      dataIndex: "amount",
      selectable: true,
      render: (text) => commify(text || 0),
      sorter: (a, b) => a?.amount - b?.amount,
    },

    {
      title: "Budget Actual",
      dataIndex: "approved_at",
      selectable: true,
      render: (text) => '-',
    },
    // {
    //   title: "Required Date",
    //   dataIndex: "createdAt",
    //   selectable: true,
    //   sorter: (a, b) =>
    //     moment(a.createdAt, DATE_TIME_FORMAT).unix() -
    //     moment(b.createdAt, DATE_TIME_FORMAT).unix(),
    //   render: (text) => (
    //     <span>
    //       {moment(text, DATE_TIME_FORMAT).format("DD-MM-YYYY, HH:mm:ss")}
    //     </span>
    //   ),
    //   width: 250,
    // },
  ],
  ...config,
};
