export default {
  slugs: {
    singular: "quick-project",
    plural: "quick-projects",
  },
  entityNames: {
    singular: "My Drive",
    plural: "My Drive",
  },
};
