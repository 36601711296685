import React from "react";
import { Route } from "react-router-dom";
import {
  UserList,
  UserAdd,
  UserEdit,
  UserChangePassword,
  UserReport,
} from "../pages/Users";

export const register = (props) => {
  const basePath = "app-users";

  return (
    <>
      <Route path={basePath} element={<UserList />} exact={true} />
      <Route path={`${basePath}/add`} element={<UserAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<UserEdit />} />
      <Route
        path={`${basePath}/change-password/:id`}
        element={<UserChangePassword />}
      />
      <Route path={`${basePath}-report`} element={<UserReport />} />
    </>
  );
};
