import React from "react";
import { Route } from "react-router-dom";
import {
  ManpowerLogList,
  ManpowerLogAdd,
  ManpowerLogEdit,
  ManpowerLogVerify,
  ManpowerLogView,
  ManpowerLogViewVerification,
  ManpowerLogReport,
} from "../pages/ManpowerLogs";

export const register = (props) => {
  const basePath = "manpower-logs";

  return (
    <>
      <Route path={basePath} element={<ManpowerLogList />} exact={true} />
      <Route path={`${basePath}/add`} element={<ManpowerLogAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<ManpowerLogEdit />} />
      <Route path={`${basePath}/verify/:id`} element={<ManpowerLogVerify />} />
      <Route path={`${basePath}/view/:id`} element={<ManpowerLogView />} />
      <Route
        path={`${basePath}/view-verification/:id`}
        element={<ManpowerLogViewVerification />}
      />
      <Route path={`${basePath}-report`} element={<ManpowerLogReport />} />
    </>
  );
};
