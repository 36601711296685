import React from "react";
import { Route } from "react-router-dom";
import {
  AttendanceTypeList,
  AttendanceTypeAdd,
  AttendanceTypeEdit,
} from "../pages/AttendanceTypes";

export const register = (props) => {
  const basePath = "attendance-types";

  return (
    <>
      <Route path={basePath} element={<AttendanceTypeList />} exact={true} />
      <Route path={`${basePath}/add`} element={<AttendanceTypeAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<AttendanceTypeEdit />} />
    </>
  );
};
