import config from "../config";
import moment from "moment";

export default {
  id: `${config?.slugs?.plural}-list`,
  pageTitle: config?.entityNames?.plural,
  breadcrumbs: [
    {
      path: "/quick-talks/dashboard",
      title: "Home",
    },
    {
      path: "",
      title: config?.entityNames?.plural,
    },
  ],
  columns: [
    {
      title: "Name",
      dataIndex: "name",
      selectable: true,
      sorter: (a, b) => a?.name?.localeCompare(b?.name),
    },
    {
      title: "Frequency",
      dataIndex: "frequency",
      selectable: true,
      width: 250,
    },
    // {
    //   title: "Date",
    //   dataIndex: "date",
    //   selectable: true,
    //   sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
    //   render: (text) => <span>{moment(text).format("DD-MM-YYYY")}</span>,
    //   width: 250,
    // },
    // {
    //   title: "Time",
    //   dataIndex: "time",
    //   selectable: true,
    //   sorter: (a, b) =>
    //     moment(a.time, "HH:mm:ss.SSS").unix() -
    //     moment(b.time, "HH:mm:ss.SSS").unix(),
    //   render: (text) => <span>{text?.split(".")?.[0]}</span>,
    //   width: 250,
    // },
  ],
  ...config,
};
