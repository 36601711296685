import { axios } from "../../../../../App";
import config from "../config";

export const makeConfig = ({ navigate }) => ({
  id: `${config?.plural}-edit`,
  title: `Edit ${config?.entityNames?.singular}`,
  pageTitle: `Edit ${config?.entityNames?.singular}`,
  breadcrumbs: [
    {
      path: "/quick-setup",
      title: "Home",
    },
    {
      path: `/quick-setup/${config?.slugs?.plural}`,
      title: config?.entityNames?.plural,
    },
    {
      path: "",
      title: `Edit ${config?.entityNames?.singular}`,
    },
  ],
  components: [
    {
      span: 12,
      component: "input",
      label: "Name",
      name: "name",
      required: true,
      placeholder: "Enter name",
      rules: [
        {
          required: true,
          message: "Name is required!",
        },
      ],
    },
    {
      span: 12,
      component: 'uploader',
      label: 'Image File',
      name: 'image_file',
      placeholder: 'Upload image file',
      required: true,
      accept: 'image/*',
      rules: [
        {
          required: true,
          message: 'Image file is required!',
        },
      ],
    },
    {
      span: 24,
      component: "editor",
      label: "Description",
      name: "description",
      placeholder: "Enter description",
      rows: 5,
    },
    {
      span: 24,
      component: "input",
      type: "hidden",
      name: "image_file",
    },
  ],
  actions: {
    onBack: () => navigate(`/quick-setup/${config?.slugs?.plural}`),
    onFinish: async (values, data) => {
      try {
        const response = await axios.put(
          `/${config?.slugs?.plural}/${data?.id}`,
          {
            data: {
              ...values,
              date: values?.date?.add(1, "days"),
            },
          }
        );

        if (response?.data) {
          return {
            success: true,
            message: `Edit ${config?.entityNames?.singular?.toLowerCase()} successfully!`,
          };
        }

        throw new Error();
      } catch (error) {
        return {
          success: false,
          message: error?.response?.data?.error?.message,
        };
      }
    },
  },
  ...config,
});
