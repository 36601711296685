/* eslint-disable default-case */
import { DatePicker, Input, InputNumber, Select, Form, TimePicker } from "antd";
import ReactQuill from "react-quill";
import moment from "moment";
import Uploader from "../Uploader";
import RepeatableComponent from "../RepeatableComponent";

const { Option } = Select;

const renderComponent = (props, value) => {
  switch (props?.component) {
    case "input": {
      const { required, ...fieldProps } = props;

      return (
        <Form.Item
          label={props?.label}
          name={props?.name}
          rules={props?.rules}
          initialValue=""
          style={{ display: props?.type === "hidden" ? "none" : "block" }}
        >
          <Input
            {...fieldProps}
            autoComplete={`${moment().valueOf()}-${props?.name}`}
          />
        </Form.Item>
      );
    }

    case "textarea": {
      const { required, ...fieldProps } = props;

      return (
        <Form.Item
          label={props?.label}
          name={props?.name}
          rules={props?.rules}
          initialValue=""
        >
          <Input.TextArea
            {...fieldProps}
            autoComplete={`${moment().valueOf()}-${props?.name}`}
          />
        </Form.Item>
      );
    }

    case "date": {
      const { required, ...fieldProps } = props;

      return (
        <Form.Item
          label={props?.label}
          name={props?.name}
          rules={props?.rules}
          initialValue=""
        >
          <DatePicker
            {...fieldProps}
            style={{ width: "100%" }}
            autoComplete={`${moment().valueOf()}-${props?.name}`}
          />
        </Form.Item>
      );
    }

    case "datetime": {
      const { required, ...fieldProps } = props;

      return (
        <Form.Item
          label={props?.label}
          name={props?.name}
          rules={props?.rules}
          initialValue=""
        >
          <DatePicker
            {...fieldProps}
            showTime={{ defaultValue: moment("00:00:00", "HH:mm:ss") }}
            style={{ width: "100%" }}
            autoComplete={`${moment().valueOf()}-${props?.name}`}
          />
        </Form.Item>
      );
    }

    case "time": {
      const { required, ...fieldProps } = props;

      return (
        <Form.Item
          label={props?.label}
          name={props?.name}
          rules={props?.rules}
          initialValue=""
        >
          <TimePicker
            {...fieldProps}
            style={{ width: "100%" }}
            autoComplete={`${moment().valueOf()}-${props?.name}`}
          />
        </Form.Item>
      );
    }

    case "select": {
      const { required, placeholder, mode, ...fieldProps } = props;

      return (
        <Form.Item label={props?.label} name={props?.name} rules={props?.rules}>
          <Select
            placeholder={placeholder}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.children).toLowerCase().includes(input.toLowerCase())
            }
            mode={mode}
          >
            {props?.values?.[fieldProps?.name]?.map((option) => (
              <Option value={option?.value}>{option?.label}</Option>
            ))}
          </Select>
        </Form.Item>
      );
    }

    case "gap":
      return <div></div>;

    case "uploader":
      return <Uploader {...props} />;

    case "editor":
      return (
        <Form.Item
          label={props?.label}
          name={props?.name}
          rules={props?.rules}
          initialValue=""
        >
          <ReactQuill theme="snow" preserveWhitespace={true} {...props} />
        </Form.Item>
      );

    case "number":
      const { required, ...fieldProps } = props;

      return (
        <Form.Item
          label={props?.label}
          name={props?.name}
          rules={props?.rules}
          initialValue={0}
        >
          <InputNumber style={{ width: "100%", height: 38 }} {...fieldProps} />
        </Form.Item>
      );

    case "title":
      return <h2>{props?.label}</h2>;

    case "repeatable": {
      return <RepeatableComponent {...props} />;
    }
  }
};

const FormElement = (props) => {
  const value = Form.useWatch(props?.name, props?.form);
  let isVisible = true;

  if (props?.visibility && props?.visibility?.[props?.name]) {
    isVisible = props?.visibility?.[props?.name](props?.formInternalData);
  }

  return isVisible ? renderComponent(props, value) : null;
};

export default FormElement;
