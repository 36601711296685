import React from "react";
import { Route } from "react-router-dom";
import {
  EmployeeDocumentList,
  EmployeeDocumentAdd,
  EmployeeDocumentEdit,
} from "../pages/EmployeeDocuments";

export const register = (props) => {
  const basePath = "employee-documents";

  return (
    <>
      <Route path={basePath} element={<EmployeeDocumentList />} exact={true} />
      <Route path={`${basePath}/add`} element={<EmployeeDocumentAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<EmployeeDocumentEdit />} />
    </>
  );
};
