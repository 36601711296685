import React from "react";
import SharedLayout from "../../components/SharedLayout";
import {makeNavbar} from "./nav";
import { Outlet } from "react-router-dom";

const QuickTeams = () => {
  return (
    <SharedLayout menuItem={{ title: "Quick Teams", path: "/quick-teams" }} makeNavbar = {makeNavbar}>
      <Outlet />
    </SharedLayout>
  );
};

export default QuickTeams;
