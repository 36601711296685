export default {
  slugs: {
    singular: "vendor-quotation",
    plural: "vendor-quotations",
  },
  entityNames: {
    singular: "Vendor Purchase Order",
    plural: "Vendor Purchase Orders",
  },
};
