import React from "react";
import { Route } from "react-router-dom";
import {
  HolidayList,
  HolidayAdd,
  HolidayEdit,
} from "../pages/Holidays";

export const register = (props) => {
  const basePath = "holidays";

  return (
    <>
      <Route path={basePath} element={<HolidayList />} exact={true} />
      <Route path={`${basePath}/add`} element={<HolidayAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<HolidayEdit />} />
    </>
  );
};
