export default {
  slugs: {
    singular: "sub-contractor-quotations-comparison-list",
    plural: "sub-contractor-quotations-comparison-lists",
  },
  entityNames: {
    singular: "Sub Contractor Quotation Comparison List",
    plural: "Sub Contractor Quotation Comparison Lists",
  },
};
