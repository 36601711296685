import React from "react";
import { Route, Navigate } from "react-router-dom";
import NotFound from "../../../pages/NotFound";
import QuickTeams from "../index";
import HomePage from "../pages";

import * as QuickProjects from "./quick-projects.route";
import * as Dashboard from "./dashboard.route";

export const register = (props) => {
  const basePath = "/quick-drive";

  return (
    <Route path={basePath} element={<QuickTeams />}>
      {QuickProjects.register()}
      {Dashboard.register()}

      {/* <Route path={basePath} element={<HomePage />} /> */}
      <Route
        path={basePath}
        element={<Navigate to={`${basePath}/dashboard`} replace />}
        // element={<HomePage />}
      />
      <Route path="*" element={<NotFound returnPath={basePath} />} />
    </Route>
  );
};
