import React, { useState, useEffect } from "react";
import { DataTable, ReportDataTable } from "../../../components";
import { useNavigate } from "react-router-dom";
import { Space, Button, Row, Col, Input, Tooltip, DatePicker, Table, Select } from "antd";
import qs from "qs";
import config from "./config";
import { axios } from "../../../../../App";
import _ from "lodash";
import { ReloadOutlined, DownloadOutlined } from "@ant-design/icons";
import moment from "moment";
import { Pie } from "@ant-design/plots";

const DATE_TIME_FORMAT = "YYYY-MM-DDTHH:mm:ss[Z]";

const ReplaceMaterialReport = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    pageSize: 10,
  });
  const [total, setTotal] = useState(0);
  const [searchValues, setSearchValues] = useState({});

  const fetchData = async (params = null) => {
    try {
      setLoading(true);

      const queryObject = {
        populate: "*",
        filters: {
          quantity_type: "replace",
        },
      };

      const materialQueryObject = {
        populate: "*",
      };

      if (!_.isEmpty(params)) {
        queryObject["filters"] = params;
      }

      const response = await axios.get(
        `${config?.slugs?.plural}?${qs.stringify(queryObject)}`
      );

      if (response?.data) {
        setData(
          response?.data?.data?.map((item) => {
            const totalQuantity =
              item?.attributes?.purchase_order?.data?.attributes?.material_items?.find(
                (subItem) =>
                  subItem?.raw_data?.material_item ===
                  item?.attributes?.material_item?.id
              )?.quantity;
            const totalReceivedQuantity =
              item?.attributes?.received_quantity -
              item?.attributes?.return_replace_quantity;

            return {
              id: item?.id,
              ...item?.attributes,
              material_group: item?.attributes?.material_item?.material_group,
              total_quantity: totalQuantity,
              received_quantity: totalReceivedQuantity,
              remaining_quantity: totalQuantity - totalReceivedQuantity,
            };
          })
        );

        setTotal(response?.data?.meta?.pagination?.total);

        setPagination({
          ...response?.data?.meta?.pagination,
          pageSize: 10,
        });

      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const refreshData = () => {
    onSearch();
  };

  const downloadReport = () => {
    try {
      const data = {
        code: config?.code,
        params: {
          start_date: searchValues?.start_date
            ? searchValues?.start_date?.format("YYYY-MM-DD")
            : moment().subtract(1000, "years").format("YYYY-MM-DD"),
          end_date: searchValues?.end_date
            ? searchValues?.end_date?.format("YYYY-MM-DD")
            : moment().add(1000, "years").format("YYYY-MM-DD"),
        },
      };

      const link = document.createElement("a");
      link.href = `${process.env.REACT_APP_BASE_API_URL
        }/reporter?${qs.stringify(data)}`;

      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.log(error);
    }
  };

  const getColumnFilterProps = ({ dataIndex, references = {} }) => {
    if (typeof references?.fieldName === "function") {
      const transformer = references?.fieldName;

      return {
        render: (data) => transformer(data?.data?.attributes) || "N/A",
      };
    } else {
      return {
        render: (data) =>
          data?.data?.attributes?.[references?.fieldName] || "N/A",
      };
    }
  };

  const getColumnProps = (column) => {
    if (column?.references) {
      return getColumnFilterProps(column);
    }

    return column;
  };

  const onSearch = () => {
    let filters = [
      {
        $or: [
          {
            vendor: {
              name: {
                $containsi: searchValues?.keyword?.toLowerCase(),
              },
            }
          },
          {
            material_item: {
              name: {
                $containsi: searchValues?.keyword?.toLowerCase(),
              },
            }
          },
        ],
      },
      // getFiltersForRoles(),
    ];

    // Conditionally add date filter if start_date exists
    if (searchValues?.start_date) {
      filters[0].$or.push({
        createdAt: {
          $gte: moment(searchValues?.start_date).format(DATE_TIME_FORMAT),
          $lte: searchValues?.end_date
            ? moment(searchValues?.end_date).format(DATE_TIME_FORMAT)
            : undefined, // If end_date exists, format it, otherwise it's ignored
        },
      });
    }

    fetchData({
      $and: filters,
    });
  };

  const onReset = () => {
    setSearchValues({});
    fetchData(null);
  };

  const getResultsMessage = () => {
    if (!_.isEmpty(searchValues)) {
      return total > 0 ? (
        <span>
          <strong>{total}</strong> matched results
        </span>
      ) : (
        <span>No matched results</span>
      );
    }

    return (
      <span>
        <strong>{total}</strong> total records
      </span>
    );
  };

  useEffect(() => {
    fetchData(filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const projects_data = data?.map((s, index) => (
    {
      key: index,
      po_number: s.purchase_order?.data?.id,
      material_item: s.material_item?.name ?? "N/A",
      replaced_material: s.return_replace_quantity ?? "N/A",
      total_quantity: s.total_quantity,
      received_quantity: s.received_quantity,
    }
  ));

  const project_column = [
    {
      title: "Purchase Order Number",
      dataIndex: "po_number",
      selectable: true,
    },
    {
      title: "Material Item",
      dataIndex: "material_item",
      selectable: true,
      key: 'material_item'
    },
    {
      title: "Replaced Material Quantity",
      dataIndex: "replaced_material",
      selectable: true,
      key: 'replaced_material'
    },
    {
      title: "Total Quantity",
      dataIndex: "total_quantity",
      selectable: true,
      key: 'total_quantity'

    },
    {
      title: "Recieved Quantity",
      dataIndex: "received_quantity",
      selectable: true,

    },
  ];


  console.log('material received', data)

  return (
    <>
      <ReportDataTable
        data={projects_data}
        config={{
          ...config,
          columns: [...project_column],
          project_title: '',
          pie_title: '',
          // columns: project_column,
        }}
        loading={loading}
        pagination={pagination}
        actions={{
          onAdd: () => navigate(`/quick-stocks/${config?.slugs?.plural}/add`),
          onRefresh: refreshData,
        }}
        Toolbar={
          <div className="toolbar">
            <Row gutter={16}>
              <Col span={10}>
                <Input
                  value={searchValues?.keyword}
                  placeholder="Search for keyword..."
                  onChange={(e) =>
                    setSearchValues((prev) => ({
                      ...prev,
                      keyword: e.target.value,
                    }))
                  }
                />
              </Col>

              <Col span={7}>
                <DatePicker
                  onChange={(value) =>
                    setSearchValues((prev) => ({
                      ...prev,
                      start_date: value,
                    }))
                  }
                  value={searchValues?.start_date}
                  placeholder="Select start date"
                  style={styles.datePicker}
                />
              </Col>

              <Col span={7}>
                <DatePicker
                  onChange={(value) =>
                    setSearchValues((prev) => ({
                      ...prev,
                      end_date: value,
                    }))
                  }
                  value={searchValues?.endDate}
                  placeholder="Select end date"
                  style={styles.datePicker}
                />
              </Col>
            </Row>

            <div className="toolbar-buttons">
              <p className="toolbar-results">{getResultsMessage()}</p>

              <Space>
                <Button onClick={onReset}>Reset</Button>
                <Button type="primary" onClick={onSearch}>
                  Search
                </Button>
              </Space>
            </div>
          </div>
        }
        buttons={[
          <Tooltip placement="top" title="Refresh">
            <Button
              icon={<ReloadOutlined />}
              onClick={refreshData}
              loading={loading}
            />
          </Tooltip>,
          // <Tooltip placement="top" title="Download Report">
          //   <Button icon={<DownloadOutlined />} onClick={downloadReport} />
          // </Tooltip>,
        ]}
        onBack={() => navigate(`/quick-stocks/reports`)}
      />

    </>
  );
};

const styles = {
  datePicker: {
    width: "100%",
  },
};

export default ReplaceMaterialReport;
