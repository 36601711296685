import config from "../config";
import moment from "moment";
import { Tag } from "antd";

const DATE_TIME_FORMAT = "YYYY-MM-DDTHH:mm:ss[Z]";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  id: `${config?.slugs?.plural}-list`,
  pageTitle: config?.entityNames?.plural,
  breadcrumbs: [
    {
      path: "/",
      title: "Home",
    },
    {
      path: "",
      title: config?.entityNames?.plural,
    },
  ],
  columns: [
    {
      title: "Title",
      dataIndex: "title",
      selectable: true,
      sorter: (a, b) => a?.title?.localeCompare(b?.title),
    },
    {
      title: "Status",
      dataIndex: "status",
      selectable: true,
      render: (text) => (
        <Tag color={text === "UNREAD" ? "blue" : "default"}>{text}</Tag>
      ),
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      selectable: true,
      sorter: (a, b) =>
        moment(a.createdAt, DATE_TIME_FORMAT).unix() -
        moment(b.createdAt, DATE_TIME_FORMAT).unix(),
      render: (text) => (
        <span>
          {moment(text, DATE_TIME_FORMAT).format("DD-MM-YYYY, HH:mm:ss")}
        </span>
      ),
      width: 250,
    },
  ],
  ...config,
};
