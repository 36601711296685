import { axios } from "../../../../../App";
import { v4 } from "uuid";
import config from "../config";
import { Modal, notification } from "antd";
import moment from "moment";
import qs from "qs";

export const makeConfig = ({ navigate, auth }) => ({
  id: `${config?.slugs?.plural}-add`,
  title: `Add New ${config?.entityNames?.singular}`,
  pageTitle: `Add New ${config?.entityNames?.singular}`,
  breadcrumbs: [
    {
      path: "/quick-projects/dashboard",
      title: "Home",
    },
    {
      path: `/quick-projects/${config?.slugs?.plural}`,
      title: config?.entityNames?.plural,
    },
    {
      path: "",
      title: `Add New ${config?.entityNames?.singular}`,
    },
  ],
  components: [
    {
      span: 24,
      component: "select",
      label: "Task",
      name: "task",
      placeholder: "Select task",
      required: true,
      rules: [
        {
          required: true,
          message: "Task is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Material Group",
      name: "material_group",
      placeholder: "Select material group",
      required: true,
      rules: [
        {
          required: true,
          message: "Material group is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Material Item",
      name: "material_item",
      placeholder: "Select material item",
      required: true,
      rules: [
        {
          required: true,
          message: "Material item is required!",
        },
      ],
    },
    // {
    //   span: 12,
    //   component: "select",
    //   label: "Material Unit",
    //   name: "material_unit",
    //   placeholder: "Select material unit",
    //   required: true,
    //   rules: [
    //     {
    //       required: true,
    //       message: "Material unit is required!",
    //     },
    //   ],
    // },
    {
      span: 12,
      component: "number",
      label: "Quantity",
      name: "quantity",
      required: true,
      placeholder: "Enter quantity",
      rules: [
        {
          required: true,
          message: "Quantity is required!",
        },
      ],
      min: 0,
    },
    {
      span: 12,
      component: "number",
      label: "Estimated Amount",
      name: "amount",
      required: true,
      placeholder: "Enter amount",
      rules: [
        {
          required: true,
          message: "Amount is required!",
        },
      ],
      min: 0,
    },
    {
      span: 12,
      component: "select",
      label: "Approved By",
      name: "approved_by",
      placeholder: "Select employee",
      required: true,
      rules: [
        {
          required: true,
          message: "Employee is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Employee",
      name: "employee",
      placeholder: "Select employee",
      required: true,
      rules: [
        {
          required: true,
          message: "Employee is required!",
        },
      ],
      // disabled: true,
    },
    {
      span: 12,
      component: "select",
      label: "Status",
      name: "status",
      placeholder: "Select status",
      required: true,
      rules: [
        {
          required: true,
          message: "Status is required!",
        },
      ],
      disabled: true,
    },
    {
      span: 24,
      component: "editor",
      label: "Description",
      name: "description",
      placeholder: "Enter description",
      rows: 5,
    },
  ],
  actions: {
    onBack: () => navigate(`/quick-projects/${config?.slugs?.plural}`),
    onFinish: async (values) => {
      try {
        const queryObject = {
          filters: {
            task: values?.task,
            material_group: values?.material_group,
            material_item: values?.material_item,
            material_unit: values?.material_unit,
            status: "Pending",
          },
        };

        const existingResp = await axios.get(
          `/${config?.slugs?.plural}?${qs.stringify(queryObject)}`
        );

        if (existingResp?.status === 200) {
          const total = existingResp?.data?.meta?.pagination?.total;

          if (total > 0) {
            return {
              success: false,
              message: `There is already an existing item with a similar task material.`,
            };
          } else {
            const response = await axios.post(`/${config?.slugs?.plural}`, {
              data: {
                ...values,
                status: "Pending",
                uid: v4(),
                // creator: auth?.user?.id,
              },
            });

            if (response?.data) {
              return {
                success: true,
                message: `Add new ${config?.entityNames?.singular?.toLowerCase()} successfully!`,
              };
            } else {
              throw new Error();
            }
          }
        } else {
          throw new Error();
        }
      } catch (error) {
        return {
          success: false,
          message: `Add new ${config?.entityNames?.singular?.toLowerCase()} failed!`,
        };
      }
    },
    // onFinish: async (values) => {
    //   try {
    //     const taskResp = await axios.get(`/tasks/${values?.task}`);
    //     const isTaskStarted =
    //       moment(taskResp?.data?.attributes?.start_date)?.valueOf() <
    //       moment()?.valueOf();

    //     if (!isTaskStarted) {
    //       const response = await axios.post(`/${config?.slugs?.plural}`, {
    //         data: {
    //           ...values,
    //           uid: v4(),
    //           // creator: auth?.user?.id,
    //         },
    //       });

    //       if (response?.data) {
    //         return {
    //           success: true,
    //           message: `Add new ${config?.entityNames?.singular?.toLowerCase()} successfully!`,
    //         };
    //       } else {
    //         throw new Error();
    //       }
    //     } else {
    //       const checkResponse = await axios.get(
    //         `/stock-ledgers/${values?.material_item}`
    //       );
    //       const onMakeRequest = async () => {
    //         try {
    //           const response = await axios.post(`/${config?.slugs?.plural}`, {
    //             data: {
    //               ...values,
    //               status: "Requested",
    //               uid: v4(),
    //               // creator: auth?.user?.id,
    //             },
    //           });

    //           if (response?.data) {
    //             notification["success"]({
    //               message: "Task Material Request",
    //               description: "Your request have been created!",
    //             });
    //           } else {
    //             throw new Error();
    //           }
    //         } catch (error) {
    //           notification["error"]({
    //             message: "Task Material Request",
    //             description: "Failed to create request for this material!",
    //           });
    //         }
    //       };
    //       if (checkResponse?.data) {
    //         const stockAmount = checkResponse?.data?.data?.attributes?.quantity;

    //         if (values?.amount > stockAmount) {
    //           Modal.confirm({
    //             title: `Add New ${config?.entityNames?.plural}`,
    //             content: `There are not enough items in stock (Items left: ${stockAmount}). Do you want to make a request for it?`,
    //             onOk() {
    //               onMakeRequest();
    //               navigate(`/quick-projects/${config?.slugs?.plural}`);
    //             },
    //             onCancel() {},
    //           });
    //         } else {
    //           const response = await axios.post(`/${config?.slugs?.plural}`, {
    //             data: {
    //               ...values,
    //               uid: v4(),
    //               // creator: auth?.user?.id,
    //             },
    //           });

    //           if (response?.data) {
    //             await axios.put(`/stock-ledgers/${values?.material_item}`, {
    //               data: {
    //                 quantity: stockAmount - values?.amount,
    //               },
    //             });
    //             return {
    //               success: true,
    //               message: `Add new ${config?.entityNames?.singular?.toLowerCase()} successfully!`,
    //             };
    //           } else {
    //             throw new Error();
    //           }
    //         }
    //       } else {
    //         throw new Error();
    //       }
    //     }
    //   } catch (error) {
    //     return {
    //       success: false,
    //       message: `Add new ${config?.entityNames?.singular?.toLowerCase()} failed!`,
    //     };
    //   }
    // },
  },
});
