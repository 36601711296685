import React from "react";
import { Route } from "react-router-dom";
import {
  DocumentList,
  DocumentAdd,
  DocumentEdit,
} from "../pages/Documents";

export const register = (props) => {
  const basePath = "documents";

  return (
    <>
      <Route path={basePath} element={<DocumentList />} exact={true} />
      <Route path={`${basePath}/add`} element={<DocumentAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<DocumentEdit />} />
    </>
  );
};
