import { useState, useEffect } from "react";
import { Button } from "antd";
import { STRAPI_URL } from "../../../../constants";
import Lightbox from "react-awesome-lightbox";
import { TfiClose } from "react-icons/tfi";
import { DownloadOutlined } from "@ant-design/icons";
import Papa from "papaparse";
import { axios } from "../../../../App";
import { v4 } from "uuid";
import { useSelector } from "react-redux";

const PdfViewer = ({ url, height }) => {
  return (
    // eslint-disable-next-line jsx-a11y/iframe-has-title
    <iframe
      src={`https://docs.google.com/viewer?url=${url}&embedded=true`}
      frameborder="0"
      width="100%"
      height={height}
    ></iframe>
  );
};

const OfficeViewer = ({ url, height }) => {
  return (
    // eslint-disable-next-line jsx-a11y/iframe-has-title
    <iframe
      src={`https://view.officeapps.live.com/op/embed.aspx?src=${url}`}
      width="100%"
      height={height}
      frameborder="0"
    ></iframe>
  );
};

const CsvFileViewer = ({ url, height }) => {
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);

  const fetchFileContent = () => {
    Papa.parse(url, {
      download: true,
      header: true,
      dynamicTyping: true,
      complete: handleDataChange,
    });
  };

  const handleDataChange = (file) => {
    setData(file.data);
    setColumns(file?.meta?.fields);
  };

  useEffect(() => {
    fetchFileContent();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ height, overflowY: "auto" }}>
      <table className="csv-file-viewer">
        <thead>
          <tr>
            {columns?.map((column, columnIndex) => (
              <th key={columnIndex}>{column}</th>
            ))}
          </tr>
        </thead>

        <tbody>
          {data
            ?.filter((column) => Object.keys(column)?.length > 0)
            ?.map((column, columnIndex) => {
              return (
                <tr key={columnIndex}>
                  {Object.keys(column)?.map((key, keyIndex) => (
                    <td key={keyIndex}>{column?.[key]}</td>
                  ))}
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}

const getFileType = (mimeType) => {
  if (mimeType?.startsWith("image/")) {
    return "image";
  }

  if (mimeType === "application/pdf") {
    return "pdf";
  }

  if (mimeType === "text/csv") {
    return "csv";
  }

  if (
    mimeType?.includes("msword") ||
    mimeType?.includes(
      "openxmlformats-officedocument.wordprocessingml.document"
    ) ||
    mimeType?.includes("ms-excel") ||
    mimeType?.includes("openxmlformats-officedocument.spreadsheetml.sheet") ||
    mimeType?.includes("ms-powerpoint")
  ) {
    return "office";
  }

  return "unknown";
};

const ViewFileModal = ({
  visible,
  onClose,
  data = {},
  onDownload = () => {},
}) => {
  const auth = useSelector((state) => state?.auth);
  const size = useWindowSize();
  const title = `${data?.name}${data?.attachment?.ext}`;
  const url = `${STRAPI_URL}${data?.attachment?.url}`;
  const fileType = getFileType(data?.attachment?.mime);

  const writeLog = async () => {
    try {
      await axios.post(`/file-item-logs`, {
        data: {
          file_item: data?.id,
          uid: v4(),
          actor_uid: auth?.user?.uid,
          event_name: "VIEW",
          event_data: {
            username: auth?.user?.username,
          },
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (visible) {
      writeLog();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  if (visible) {
    if (fileType === "image") {
      return <Lightbox image={url} title={title} onClose={onClose} />;
    }

    return (
      <div style={styles.container}>
        <div style={styles.header}>
          <h3 style={styles.title}>{title}</h3>

          <div style={styles.buttons}>
            <div className="file-viewer-button" onClick={onDownload}>
              <DownloadOutlined style={styles.buttonIcon} />
            </div>

            <div className="file-viewer-button" onClick={onClose}>
              <TfiClose style={styles.buttonIcon} />
            </div>
          </div>
        </div>

        <div style={styles.body}>
          {fileType === "pdf" && (
            <PdfViewer url={url} height={size?.height - 50} />
          )}

          {fileType === "office" && (
            <OfficeViewer url={url} height={size?.height - 50} />
          )}

          {fileType === "csv" && (
            <CsvFileViewer url={url} height={size?.height - 50} />
          )}

          {fileType === "unknown" && (
            <div style={styles.blank}>
              <p style={styles.blankMessage}>
                The preview for this file is not available. Please download it
                to your computer.
              </p>

              <Button type="primary">Download</Button>
            </div>
          )}
        </div>
      </div>
    );
  }

  return null;
};

const styles = {
  container: {
    width: "100%",
    height: "100%",
    position: "fixed",
    top: 0,
    left: 0,
    background: "rgba(0,0,0,0.8)",
    zIndex: 999,
  },
  header: {
    height: 50,
    color: "white",
    background: "rgba(0,0,0,0.9)",
    display: "flex",
    alignItems: "center",
    paddingLeft: "0.8em",
    justifyContent: "space-between",
    boxShadow: "0px 1px 5px 0px #111",
  },
  body: {},
  title: {
    color: "white",
    fontSize: "1.1em",
  },
  buttons: {
    display: "flex",
    alignItems: "center",
  },
  buttonIcon: {
    margin: "0 auto",
    fontSize: 18,
  },
  blank: {
    color: "white",
    textAlign: "center",
    padding: 32,
  },
  blankMessage: {
    marginBottom: 12,
  },
};

export default ViewFileModal;
