import { axios } from "../../../../../App";
import { v4 } from "uuid";
import config from "../config";

export const makeConfig = ({ navigate, auth }) => ({
  id: `${config?.slugs?.plural}-add`,
  title: `Add New ${config?.entityNames?.singular}`,
  pageTitle: `Add New ${config?.entityNames?.singular}`,
  breadcrumbs: [
    {
      path: "/quick-stocks/dashboard",
      title: "Home",
    },
    {
      path: `/quick-stocks/${config?.slugs?.plural}`,
      title: config?.entityNames?.plural,
    },
    {
      path: "",
      title: `Add New ${config?.entityNames?.singular}`,
    },
  ],
  components: [
    {
      span: 24,
      component: "input",
      label: "Heading",
      name: "heading",
      required: true,
      placeholder: "Enter heading",
      rules: [
        {
          required: true,
          message: "Heading is required!",
        },
      ],
    },
    // {
    //   span: 12,
    //   component: "select",
    //   label: "Sub Contractor",
    //   name: "sub_contractor",
    //   placeholder: "Select sub contractor",
    //   required: true,
    //   rules: [
    //     {
    //       required: true,
    //       message: "Sub contractor is required!",
    //     },
    //   ],
    // },
    {
      span: 12,
      component: "select",
      label: "Work Group",
      name: "work_group",
      placeholder: "Select work group",
      required: true,
      rules: [
        {
          required: true,
          message: "Work group is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Work Item",
      name: "work_item",
      placeholder: "Select work item",
      // required: true,
      // rules: [
      //   {
      //     required: true,
      //     message: "Material item is required!",
      //   },
      // ],
    },
    // {
    //   span: 12,
    //   component: "select",
    //   label: "Work Unit",
    //   name: "work_unit",
    //   placeholder: "Select work unit",
    //   // required: true,
    //   // rules: [
    //   //   {
    //   //     required: true,
    //   //     message: "Material unit is required!",
    //   //   },
    //   // ],
    // },
    {
      span: 12,
      component: "select",
      label: "Approved By",
      name: "approved_by",
      placeholder: "Select employee",
      required: true,
      rules: [
        {
          required: true,
          message: "Approved by is required!",
        },
      ],
    },
    // {
    //   span: 24,
    //   component: "editor",
    //   label: "Description",
    //   name: "description",
    //   placeholder: "Enter description",
    //   rows: 5,
    // },
    // {
    //   span: 24,
    //   component: "editor",
    //   label: "Approval Remarks",
    //   name: "approval_remarks",
    //   placeholder: "Enter approval remarks",
    //   rows: 5,
    // },
  ],
  actions: {
    onBack: () => navigate(`/quick-stocks/${config?.slugs?.plural}`),
    onFinish: async (values, data) => {

      try {
        const response = await axios.post(`/${config?.slugs?.plural}`, {
          data: {
            ...values,
            term_and_condition: data,
            uid: v4(),
            // creator: auth?.user?.id,
          },
        });

        if (response?.data) {
          return {
            success: true,
            message: `Add new ${config?.entityNames?.singular?.toLowerCase()} successfully!`,
          };
        }

        throw new Error();
      } catch (error) {
        return {
          success: false,
          message: `Add new ${config?.entityNames?.singular?.toLowerCase()} failed!`,
        };
      }
    },
  },
});
