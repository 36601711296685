export default {
  ROLE: {
    title: "Add Allowed Role",
    entityNames: {
      singular: "Allowed Role",
      plural: "Allowed Roles",
    },
    components: [
      {
        span: 12,
        component: "select",
        label: "Role",
        name: "app_role",
        placeholder: "Select role",
        required: true,
        rules: [
          {
            required: true,
            message: "Role is required!",
          },
        ],
      },
      {
        span: 12,
        component: "select",
        label: "File Role",
        name: "file_role",
        placeholder: "Select file role",
        required: true,
        rules: [
          {
            required: true,
            message: "File role is required!",
          },
        ],
      },
    ],
  },
  DEPARTMENT: {
    title: "Add Allowed Department",
    entityNames: {
      singular: "Allowed Department",
      plural: "Allowed Departments",
    },
    components: [
      {
        span: 12,
        component: "select",
        label: "Department",
        name: "department",
        placeholder: "Select department",
        required: true,
        rules: [
          {
            required: true,
            message: "Department is required!",
          },
        ],
      },
      {
        span: 12,
        component: "select",
        label: "File Role",
        name: "file_role",
        placeholder: "Select file role",
        required: true,
        rules: [
          {
            required: true,
            message: "File role is required!",
          },
        ],
      },
    ],
  },
  EMPLOYEE: {
    title: "Add Allowed Employee",
    entityNames: {
      singular: "Allowed Employee",
      plural: "Allowed Employees",
    },
    components: [
      {
        span: 12,
        component: "select",
        label: "Employee",
        name: "employee",
        placeholder: "Select employee",
        required: true,
        rules: [
          {
            required: true,
            message: "Employee is required!",
          },
        ],
      },
      {
        span: 12,
        component: "select",
        label: "File Role",
        name: "file_role",
        placeholder: "Select file role",
        required: true,
        rules: [
          {
            required: true,
            message: "File role is required!",
          },
        ],
      },
    ],
  },
};
