export default {
  slugs: {
    singular: "sub-contractor-debit-note",
    plural: "sub-contractor-debit-notes",
  },
  entityNames: {
    singular: "Sub Contractor Debit Note",
    plural: "Sub Contractor Debit Notes",
  },
};
