export default {
  slugs: {
    singular: "reimburse",
    plural: "reimburses",
  },
  entityNames: {
    singular: "Reimburse",
    plural: "Reimburses",
  },
};
