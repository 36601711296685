import React from "react";
import { Route } from "react-router-dom";
import {
  AgencyQuotationList,
  AgencyQuotationAdd,
  AgencyQuotationEdit,
  AgencyQuotationView,
  AgencyQuotationReport,
} from "../pages/AgencyQuotations";

export const register = (props) => {
  const basePath = "agency-quotations";

  return (
    <>
      <Route path={basePath} element={<AgencyQuotationList />} exact={true} />
      <Route path={`${basePath}/add`} element={<AgencyQuotationAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<AgencyQuotationEdit />} />
      <Route path={`${basePath}/view/:id`} element={<AgencyQuotationView />} />
      <Route path={`${basePath}-report`} element={<AgencyQuotationReport />} />
    </>
  );
};
