import React, { useState, useEffect } from "react";
import { DataTable } from "../../../components";
import { useNavigate } from "react-router-dom";
import { Space, Button, Popconfirm, Tooltip, notification, Input } from "antd";
import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import qs from "qs";
import config from "./config";
import { axios } from "../../../../../App";
import _ from "lodash";
import { useSelector } from "react-redux";

const Page = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    pageSize: 10,
  });
  const [total, setTotal] = useState(0);
  const [searchValues, setSearchValues] = useState({});
  const auth = useSelector((state) => state?.auth);

  const fetchData = async (params = null) => {
    try {
      setLoading(true);

      const queryObject = {
        populate: "*",
        sort: ["grand_total:desc"],
      };

      if (!_.isEmpty(params)) {
        queryObject["filters"] = params;
      }

      const response = await axios.get(
        `${config?.slugs?.plural}?${qs.stringify(queryObject)}`
      );

      if (response?.data) {
        setData(
          response?.data?.data?.map((item) => ({
            id: item?.id,
            ...item?.attributes,
          }))
        );

        setTotal(response?.data?.meta?.pagination?.total);

        setPagination({
          ...response?.data?.meta?.pagination,
          pageSize: 10,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const refreshData = () => {
    onSearch();
  };

  const deleteItem = async (id) => {
    try {
      const response = await axios.delete(`${config?.slugs?.plural}/${id}`);

      if (response?.data) {
        notification["success"]({
          message: `Remove ${config?.entityNames?.singular}`,
          description: `Remove ${config?.entityNames?.singular} successfully!`,
        });

        refreshData();
      } else {
        throw new Error();
      }
    } catch (error) {
      notification["error"]({
        message: `Remove ${config?.entityNames?.singular}`,
        description: `Remove ${config?.entityNames?.singular} failed!`,
      });
    }
  };

  const getColumnFilterProps = ({ dataIndex, references = {} }) => {
    if (typeof references?.fieldName === "function") {
      const transformer = references?.fieldName;

      return {
        render: (data) => transformer(data?.data?.attributes) || "N/A",
      };
    } else {
      return {
        render: (data) =>
          data?.data?.attributes?.[references?.fieldName] || "N/A",
      };
    }
  };

  const getColumnProps = (column) => {
    if (column?.references) {
      return getColumnFilterProps(column);
    }

    return column;
  };

  const onSearch = () => {
    fetchData({
      $or: [
        {
          heading: {
              $containsi: searchValues?.keyword?.toLowerCase(),
          },
        },
        {
          remarks: {
            $containsi: searchValues?.remarks?.toLowerCase(),
          },
        },
      ],
    });
  };

  const onReset = () => {
    setSearchValues({});
    fetchData(null);
  };

  const getResultsMessage = () => {
    if (!_.isEmpty(searchValues)) {
      return total > 0 ? (
        <span>
          <strong>{total}</strong> matched results
        </span>
      ) : (
        <span>No matched results</span>
      );
    }

    return (
      <span>
        <strong>{total}</strong> total records
      </span>
    );
  };

  useEffect(() => {
    fetchData(filters);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DataTable
      data={data}
      config={{
        ...config,
        columns: config?.columns
          ?.map((column) => ({
            ...column,
            ...getColumnProps(column),
          }))
          ?.concat({
            title: "Actions",
            fixed: "right",
            width: 100,
            selectable: false,
            render: ({ id, employee, approved_by, status, createdAt }) => {
              const isCreator =
                auth?.user?.uid === employee?.data?.attributes?.uid;
              const isApprover =
                auth?.user?.uid === approved_by?.data?.attributes?.uid;
              const isAdmin = auth?.user?.is_admin;

              const actualStartDate = new Date(createdAt);
              const currentDate = new Date();
              const currentDateOnly = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());

              const showEditButton = actualStartDate >= currentDateOnly;

              return (
                <Space>
                  <Tooltip
                    placement="bottom"
                    title={`View ${config?.entityNames?.singular}`}
                  >
                    <Button
                      icon={<EyeOutlined />}
                      onClick={() => {
                        navigate(
                          `/quick-stocks/${config?.slugs?.plural}/view/${id}`
                        );
                      }}
                    />
                  </Tooltip>

                  {(isCreator || isApprover || isAdmin) &&
                    status !== "Approved" && showEditButton && (
                      <Tooltip
                        placement="bottom"
                        title={`Edit ${config?.entityNames?.singular}`}
                      >
                        <Button
                          icon={<EditOutlined />}
                          onClick={() => {
                             if (isApprover || isAdmin) {
                              navigate(
                                `/quick-stocks/${config?.slugs?.plural}/approver/edit/${id}`
                              );
                            }

                             if (isCreator || isAdmin) {
                              navigate(
                                `/quick-stocks/${config?.slugs?.plural}/creator/edit/${id}`
                              );
                            }
                          }}
                        />
                      </Tooltip>
                    )}

                  {(isCreator || isAdmin) && (
                    <Popconfirm
                      title={`Are you sure to remove this ${config?.entityNames?.singular}?`}
                      okText="Yes"
                      cancelText="No"
                      onConfirm={() => deleteItem(id)}
                    >
                      <Tooltip
                        placement="bottom"
                        title={`Remove ${config?.entityNames?.singular}`}
                      >
                        <Button type="danger" icon={<DeleteOutlined />} />
                      </Tooltip>
                    </Popconfirm>
                  )}
                </Space>
              );
            },
          }),
      }}
      loading={loading}
      pagination={pagination}
      actions={{
        onAdd: () => navigate(`/quick-stocks/${config?.slugs?.plural}/add`),
        onRefresh: refreshData,
      }}
      Toolbar={
        <div className="toolbar">
          <Input
            value={searchValues?.keyword}
            placeholder="Search for keyword..."
            onChange={(e) =>
              setSearchValues((prev) => ({
                ...prev,
                keyword: e.target.value,
              }))
            }
          />

          <div className="toolbar-buttons">
            <p className="toolbar-results">{getResultsMessage()}</p>

            <Space>
              <Button onClick={onReset}>Reset</Button>
              <Button type="primary" onClick={onSearch}>
                Search
              </Button>
            </Space>
          </div>
        </div>
      }
    />
  );
};

export default Page;
