export default {
    slugs: {
        singular: "calendar",
        plural: "calendars",
    },
    entityNames: {
        singular: "Calendar",
        plural: "Calendar",
    },
};
