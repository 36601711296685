import React, { useEffect, useState } from "react";
import { AddForm } from "../../../components";
import { useNavigate, useParams } from "react-router-dom";
import { makeConfig } from "./config";
import { axios } from "../../../../../App";
import { fetchData } from "../../../../../utils/helpers/data.helper";
import qs from "qs";
import moment from "moment";

const Page = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    project: [],
    purchase_order: [],
    all_purchase_order: [],
    all_material_item: [],
    material_item: [],
    quantity_type: [
      {
        value: "return",
        label: "Return",
      },
      {
        value: "replace",
        label: "Replace",
      },
    ],
  });
  const config = makeConfig({ navigate });
  const contentTypes = [
    {
      slug: "vendors",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          vendor: data,
        })),
    },
    {
      slug: "employees",
      transformer: (item) => ({
        value: item?.id,
        label: `${item?.first_name} ${item?.last_name}`,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          employee: data,
        })),
    },
    {
      slug: "locations",
      query: {
        populate: "*",
      },
      transformer: (item) => ({
        value: item?.id,
        label: `${item?.name} (${item?.location_type?.data?.attributes?.name})`,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          location: data,
        })),
    },
    {
      slug: "projects",
      query: {
        populate: "*",
      },
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
        vendors: item?.vendors?.data?.map((item) => ({
          // others: item,
          value: item?.id,
          label: item?.attributes?.name,
        })),
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          all_project: data,
        })),
    },
    {
      slug: "vendor-quotations",
      query: {
        filters: {
          status: "Approved",
        },
        populate: "*",
      },
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          all_purchase_order: data,
        })),
    },
    {
      slug: "material-items",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          all_material_item: data,
        })),
    },
    {
      slug: "material-units",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          material_unit: data,
        })),
    },
  ];

  const getPurchaseOrderMaterials = (purchaseOrderId) => {
    const purchaseOrder = values?.all_purchase_order?.find(
      (item) => item?.id === purchaseOrderId
    );

    const materialItems = purchaseOrder?.material_items?.map((item) => ({
      value: item?.raw_data?.material_item,
      label: item?.material_item,
    }));

    setValues((prev) => ({
      ...prev,
      material_item: materialItems,
    }));
  };

  // const fetchProjectMaterials = (projectId) => {
  //   fetchData({
  //     slug: "task-materials",
  //     query: {
  //       filters: {
  //         task: {
  //           project: projectId,
  //         },
  //       },
  //       populate: "*",
  //     },
  //     onSuccess: (data) => {
  //       const materialItemIds = data?.map(
  //         (record) => record?.material_item?.data?.id
  //       );

  //       setValues((prev) => ({
  //         ...prev,
  //         material_item: values?.all_material_item?.filter((item) =>
  //           materialItemIds?.includes(item?.value)
  //         ),
  //       }));
  //     },
  //   });
  // };

  useEffect(() => {
    contentTypes?.forEach((contentType) => {
      fetchData(contentType);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const queryObject = {
          populate: "*",
        };

        const response = await axios.get(
          `/${config?.slugs?.plural}/${id}?${qs.stringify(queryObject)}`
        );

        if (response?.data) {
          const vendorId = response?.data?.data?.attributes?.vendor?.data?.id;
          const projectId = response?.data?.data?.attributes?.project?.data?.id;
          const purchaseOrderId =
            response?.data?.data?.attributes?.purchase_order?.data?.id;

          if (vendorId) {
            let filteredProjects = [];
            let vendorIds = [];

            if (values?.all_project?.length > 0) {
              values?.all_project?.forEach((project) => {
                vendorIds = project?.vendors?.map((item) =>
                  Number(item?.value)
                );

                if (vendorIds?.some((id) => Number(id) === Number(vendorId))) {
                  filteredProjects.push(project);
                }
              });
            }

            setValues((prev) => ({
              ...prev,
              project: filteredProjects,
            }));
          } else {
            setValues((prev) => ({
              ...prev,
              project: [],
              purchase_order: [],
              material_item: [],
            }));
          }

          if (projectId) {
            // fetchProjectMaterials(projectId);

            getPurchaseOrderMaterials(purchaseOrderId);

            setValues((prev) => ({
              ...prev,
              purchase_order: values?.all_purchase_order
                ?.filter(
                  (item) =>
                    item?.project?.data?.id === Number(projectId) &&
                    item?.vendor?.data?.id === Number(vendorId)
                )
                ?.map((item) => ({
                  value: item?.id,
                  label: item?.heading,
                })),
            }));
          }

          const responseData = response?.data?.data?.attributes;

          const receivedQuantity = responseData?.received_quantity || 0;
          const returnQuantity = responseData?.return_replace_quantity || 0;

          let newData = {
            ...responseData,
            id: response?.data?.data?.id,
            material_item: responseData?.material_item?.data?.id,
            material_unit: responseData?.material_unit?.data?.id,
            employee: responseData?.employee?.data?.id,
            location: responseData?.location?.data?.id,
            order_date: responseData?.order_date
              ? moment(responseData?.order_date)
              : null,
            received_date: responseData?.received_date
              ? moment(responseData?.received_date)
              : null,
            project: projectId,
            vendor: vendorId,
            purchase_order: purchaseOrderId,
            balance_quantity: Math.max(receivedQuantity - returnQuantity, 0),
          };

          setData(newData);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    id,
    values?.all_material_item,
    values?.all_purchase_order,
    values?.all_project,
  ]);

  return (
    <AddForm
      config={config}
      data={data}
      loading={loading}
      values={values}
      onFieldChange={{
        vendor: (antForm) => {
          const vendorId = antForm.getFieldValue("vendor");

          if (vendorId) {
            let filteredProjects = [];
            let vendorIds = [];

            if (values?.all_project?.length > 0) {
              values?.all_project?.forEach((project) => {
                vendorIds = project?.vendors?.map((item) =>
                  Number(item?.value)
                );

                if (vendorIds?.some((id) => Number(id) === Number(vendorId))) {
                  filteredProjects.push(project);
                }
              });
            }

            antForm.setFieldValue("project", undefined);
            antForm.setFieldValue("purchase_order", undefined);
            antForm.setFieldValue("material_item", undefined);

            setValues((prev) => ({
              ...prev,
              project: filteredProjects,
            }));
          } else {
            setValues((prev) => ({
              ...prev,
              project: [],
              purchase_order: [],
            }));
          }
        },
        project: (antForm) => {
          const projectId = antForm.getFieldValue("project");
          const vendorId = antForm.getFieldValue("vendor");

          if (projectId) {
            antForm.setFieldValue("purchase_order", undefined);
            antForm.setFieldValue("material_item", undefined);

            // fetchProjectMaterials(projectId);

            setValues((prev) => ({
              ...prev,
              purchase_order: values?.all_purchase_order
                ?.filter(
                  (item) =>
                    item?.project?.data?.id === Number(projectId) &&
                    item?.vendor?.data?.id === Number(vendorId)
                )
                ?.map((item) => ({
                  value: item?.id,
                  label: item?.heading,
                })),
            }));
          }
        },
        purchase_order: (antForm) => {
          const purchaseOrderId = antForm.getFieldValue("purchase_order");

          getPurchaseOrderMaterials(purchaseOrderId);
        },
        received_quantity: (antForm) => {
          const receivedQuantity =
            antForm.getFieldValue("received_quantity") || 0;
          const returnQuantity =
            antForm.getFieldValue("return_replace_quantity") || 0;

          const balanceQuantity = Math.max(
            receivedQuantity - returnQuantity,
            0
          );

          antForm.setFieldValue("balance_quantity", balanceQuantity);
        },
        return_replace_quantity: (antForm) => {
          const receivedQuantity =
            antForm.getFieldValue("received_quantity") || 0;
          const returnQuantity =
            antForm.getFieldValue("return_replace_quantity") || 0;

          const balanceQuantity = Math.max(
            receivedQuantity - returnQuantity,
            0
          );

          antForm.setFieldValue("balance_quantity", balanceQuantity);
        },
      }}
    />
  );
};

export default Page;
