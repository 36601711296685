import React from "react";
import { Route } from "react-router-dom";
import {
  ManufacturerList,
  ManufacturerAdd,
  ManufacturerEdit,
} from "../pages/Manufacturers";

export const register = (props) => {
  const basePath = "manufacturers";

  return (
    <>
      <Route path={basePath} element={<ManufacturerList />} exact={true} />
      <Route path={`${basePath}/add`} element={<ManufacturerAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<ManufacturerEdit />} />
    </>
  );
};
