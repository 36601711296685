export default {
  slugs: {
    singular: "received-invoice",
    plural: "received-invoices",
  },
  entityNames: {
    singular: "Received Invoice",
    plural: "Received Invoices",
  },
};
