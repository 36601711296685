import React, { useEffect, useState } from "react";
import { AddForm } from "../../../components";
import { useNavigate, useParams } from "react-router-dom";
import { makeConfig } from "./config";
import { axios } from "../../../../../App";
import { fetchData } from "../../../../../utils/helpers/data.helper";
import qs from "qs";
import moment from "moment";
import { isEmpty } from "lodash";

const Page = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({});
  const config = makeConfig({ navigate });
  const contentTypes = [
    {
      slug: "contact-groups",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          contact_group: data,
        })),
    },
    {
      slug: "designations",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          designation: data,
        })),
    },
    {
      slug: "locations",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          location: data,
        })),
    },
    {
      slug: "job-titles",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          job_title: data,
        })),
    },
    {
      slug: "initials",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          initial: data,
        })),
    },
    {
      slug: "states",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          state: data,
        })),
    },
    {
      slug: "cities",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          city: data,
        })),
    },
    {
      slug: "employees",
      transformer: (item) => ({
        value: item?.id,
        label: `${item?.first_name} ${item?.last_name}`,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          meeting_with: data,
        })),
    },
    {
      slug: "visit-purposes",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          meeting_purpose: data,
        })),
    },
  ];

  useEffect(() => {
    contentTypes?.forEach((contentType) => {
      fetchData(contentType);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const queryObject = {
          populate: "*",
        };

        const response = await axios.get(
          `/${config?.slugs?.plural}/${id}?${qs.stringify(queryObject)}`
        );

        if (response?.data) {
          let newData = {
            ...response?.data?.data?.attributes,
            id: response?.data?.data?.id,
            initial: response?.data?.data?.attributes?.initial?.data?.id,
            state: response?.data?.data?.attributes?.state?.data?.id,
            city: response?.data?.data?.attributes?.city?.data?.id,
            mobile_number_2: response?.data?.data?.attributes?.mobile_number_2,
            phone_number_1: response?.data?.data?.attributes?.phone_number_1,
            phone_number_2: response?.data?.data?.attributes?.phone_number_2,
            location: response?.data?.data?.attributes?.location?.data?.id,
            company: response?.data?.data?.attributes?.company,
            job_title: response?.data?.data?.attributes?.job_title?.data?.id,
            designation:
              response?.data?.data?.attributes?.designation?.data?.id,
            meeting_with:
              response?.data?.data?.attributes?.meeting_with?.data?.id,
            meeting_purpose:
              response?.data?.data?.attributes?.meeting_purpose?.data?.id,
            date: moment(response?.data?.data?.attributes?.date),
            in_time: moment(
              response?.data?.data?.attributes?.in_time,
              "HH:mm:ss.SSS"
            ),
            out_time: moment(
              response?.data?.data?.attributes?.out_time,
              "HH:mm:ss.SSS"
            ),
          };

          if (isEmpty(newData?.photo) || isEmpty(newData?.photo?.data)) {
            newData["photo"] = null;
          }

          setData(newData);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <AddForm config={config} data={data} loading={loading} values={values} />
  );
};

export default Page;
