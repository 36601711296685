// eslint-disable-next-line import/no-anonymous-default-export
export default {
  slugs: {
    singular: "vendor-credit-note",
    plural: "vendor-credit-notes",
  },
  entityNames: {
    singular: "Vendor Credit Note",
    plural: "Vendor Credit Notes",
  },
};
