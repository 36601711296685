export default {
  slugs: {
    singular: "machine",
    plural: "machines",
  },
  entityNames: {
    singular: "Machinery",
    plural: "Machinery",
  },
};
