export default {
  slugs: {
    singular: "employee",
    plural: "employees",
  },
  entityNames: {
    singular: "Payroll",
    plural: "Payrolls",
  },
};
