export const makeConfig = ({ navigate }) => ({
  id: `gantt-chart`,
  title: `Gantt Chart`,
  pageTitle: `Gantt Chart`,
  breadcrumbs: [
    {
      path: "/quick-projects/dashboard",
      title: "Home",
    },
    {
      path: "",
      title: `Gantt Chart`,
    },
  ],
  actions: {
    onBack: () => navigate(`/quick-projects/projects`),
  },
});
