import React from "react";
import { Route } from "react-router-dom";
import {
  VendorQuotationComparisonList,
  VendorQuotationComparisonView,
} from "../pages/VendorQuotationComparisonLists";

export const register = (props) => {
  const basePath = "vendor-quotations-comparison-lists";

  return (
    <>
      <Route
        path={basePath}
        element={<VendorQuotationComparisonList />}
        exact={true}
      />
      <Route
        path={`${basePath}/view/:id`}
        element={<VendorQuotationComparisonView />}
      />
    </>
  );
};
