export default {
  slugs: {
    singular: "vendor-group",
    plural: "vendor-groups",
  },
  entityNames: {
    singular: "Vendor Group",
    plural: "Vendor Groups",
  },
};
