import React from "react";
import { Route } from "react-router-dom";
import {
  RequiredTaskMaterialList,
  RequiredTaskMaterialView,
  RequiredTaskMaterialProcess,
  RequiredTaskMaterialReport,
} from "../pages/RequiredTaskMaterials";

export const register = (props) => {
  const basePath = "required-task-materials";

  return (
    <>
      <Route
        path={basePath}
        element={<RequiredTaskMaterialList />}
        exact={true}
      />
      <Route
        path={`${basePath}/view/:id`}
        element={<RequiredTaskMaterialView />}
      />
      <Route
        path={`${basePath}/process/:id`}
        element={<RequiredTaskMaterialProcess />}
      />
      <Route
        path={`${basePath}-report`}
        element={<RequiredTaskMaterialReport />}
      />
    </>
  );
};
