import { axios } from "../../../../../App";
import config from "../config";

export const makeConfig = ({ navigate }) => ({
  id: `${config?.plural}-edit`,
  title: `Edit ${config?.entityNames?.singular}`,
  pageTitle: `Edit ${config?.entityNames?.singular}`,
  breadcrumbs: [
    {
      path: "/quick-talks/dashboard",
      title: "Home",
    },
    {
      path: `/quick-talks/${config?.slugs?.plural}`,
      title: config?.entityNames?.plural,
    },
    {
      path: "",
      title: `Edit ${config?.entityNames?.singular}`,
    },
  ],
  components: [
    {
      span: 12,
      component: "input",
      label: "Name",
      name: "name",
      required: true,
      placeholder: "Enter name",
      rules: [
        {
          required: true,
          message: "Name is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Meeting Type",
      name: "meeting_type",
      placeholder: "Select meeting type",
      required: true,
      rules: [
        {
          required: true,
          message: "Meeting type is required!",
        },
      ],
    },
    {
      span: 12,
      component: "datetime",
      label: "Time",
      name: "started_at",
      required: true,
      placeholder: "Select date & time",
      rules: [
        {
          required: true,
          message: "Time is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Project",
      name: "project",
      placeholder: "Select project",
      required: true,
      rules: [
        {
          required: true,
          message: "Project is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Host",
      name: "host",
      placeholder: "Select employee",
      required: true,
      rules: [
        {
          required: true,
          message: "Host is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Notes Prepared By",
      name: "notes_prepared_by",
      placeholder: "Select employee",
      required: true,
      rules: [
        {
          required: true,
          message: "Notes prepared by is required!",
        },
      ],
    },
    {
      span: 24,
      component: "select",
      label: "Tasks",
      name: "tasks",
      placeholder: "Select tasks",
      required: true,
      rules: [
        {
          required: true,
          message: "Tasks is required!",
        },
      ],
      mode: "multiple",
    },
    {
      span: 24,
      component: "select",
      label: "Members",
      name: "members",
      placeholder: "Select employees",
      required: true,
      rules: [
        {
          required: true,
          message: "Members is required!",
        },
      ],
      mode: "multiple",
    },
    {
      span: 12,
      component: "select",
      label: "Employee Group",
      name: "employee_group",
      placeholder: "Select employee group",
      required: true,
      rules: [
        {
          required: true,
          message: "Employee group is required!",
        },
      ],
    },
    {
      span: 24,
      component: "editor",
      label: "Agenda",
      name: "agenda",
      placeholder: "Enter agenda",
      rows: 5,
    },
    {
      span: 12,
      component: "editor",
      label: "Requirements",
      name: "requirements",
      placeholder: "Enter requirements",
      rows: 5,
    },
    {
      span: 12,
      component: "editor",
      label: "Target",
      name: "target",
      placeholder: "Enter target",
      rows: 5,
    },
  ],
  actions: {
    onBack: () => navigate(`/quick-talks/${config?.slugs?.plural}`),
    onFinish: async (values, data) => {
      try {
        const response = await axios.put(
          `/${config?.slugs?.plural}/${data?.id}`,
          {
            data: values,
          }
        );

        if (response?.data) {
          return {
            success: true,
            message: `Edit ${config?.entityNames?.singular?.toLowerCase()} successfully!`,
          };
        }

        throw new Error();
      } catch (error) {
        return {
          success: false,
          message: error?.response?.data?.error?.message,
        };
      }
    },
  },
  ...config,
});
