import { axios } from "../../../../../App";
import config from "../config";

export const makeConfig = ({ navigate }) => ({
  id: `${config?.plural}-edit`,
  title: `Edit ${config?.entityNames?.singular}`,
  pageTitle: `Edit ${config?.entityNames?.singular}`,
  breadcrumbs: [
    {
      path: "/quick-stocks/dashboard",
      title: "Home",
    },
    {
      path: `/quick-stocks/${config?.slugs?.plural}`,
      title: config?.entityNames?.plural,
    },
    {
      path: "",
      title: `Edit ${config?.entityNames?.singular}`,
    },
  ],
  components: [
    {
      span: 12,
      component: "select",
      label: "Machine",
      name: "machine",
      placeholder: "Select machine",
      required: true,
      rules: [
        {
          required: true,
          message: "Machine is required!",
        },
      ],
    },
    {
      span: 12,
      component: "input",
      label: "Label",
      name: "label",
      required: true,
      placeholder: "Enter label",
      rules: [
        {
          required: true,
          message: "Label is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Manufacturer",
      name: "manufacturer",
      placeholder: "Select manufacturer",
      required: true,
      rules: [
        {
          required: true,
          message: "Manufacturer is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Contact",
      name: "contact",
      placeholder: "Select contact",
      required: true,
      rules: [
        {
          required: true,
          message: "Contact is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Allotted To",
      name: "allotted_to",
      placeholder: "Select employee",
      required: true,
      rules: [
        {
          required: true,
          message: "Allotted to is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Performed By",
      name: "performed_by",
      placeholder: "Select employee",
      required: true,
      rules: [
        {
          required: true,
          message: "Performed by is required!",
        },
      ],
    },
    {
      span: 12,
      component: "date",
      label: "Date",
      name: "date",
      required: true,
      placeholder: "Select date",
      rules: [
        {
          required: true,
          message: "Date is required!",
        },
      ],
    },
    {
      span: 12,
      component: "date",
      label: "Purchased Date",
      name: "purchased_date",
      required: true,
      placeholder: "Select purchased date",
      rules: [
        {
          required: true,
          message: "Purchased date is required!",
        },
      ],
    },
    {
      span: 12,
      component: "date",
      label: "Last Service Date",
      name: "last_service_date",
      required: true,
      placeholder: "Select last service date",
      rules: [
        {
          required: true,
          message: "Last service date is required!",
        },
      ],
    },
    {
      span: 12,
      component: "date",
      label: "Validation Date",
      name: "validation_date",
      required: true,
      placeholder: "Select validation date",
      rules: [
        {
          required: true,
          message: "Validation date is required!",
        },
      ],
    },
    {
      span: 12,
      component: "date",
      label: "Due Date",
      name: "due_date",
      required: true,
      placeholder: "Select due date",
      rules: [
        {
          required: true,
          message: "Due date is required!",
        },
      ],
    },
    {
      span: 24,
      component: "editor",
      label: "Remarks",
      name: "remarks",
      placeholder: "Enter remarks",
      rows: 5,
    },
    {
      span: 24,
      component: "editor",
      label: "Description",
      name: "description",
      placeholder: "Enter description",
      rows: 5,
    },
  ],
  actions: {
    onBack: () => navigate(`/quick-stocks/${config?.slugs?.plural}`),
    onFinish: async (values, data) => {
      try {
        const response = await axios.put(
          `/${config?.slugs?.plural}/${data?.id}`,
          {
            data: {
              ...values,
              date: values?.date?.add(1, "days"),
              purchased_date: values?.purchased_date?.add(1, "days"),
              last_service_date: values?.last_service_date?.add(1, "days"),
              validation_date: values?.validation_date?.add(1, "days"),
              due_date: values?.due_date?.add(1, "days"),
            },
          }
        );

        if (response?.data) {
          return {
            success: true,
            message: `Edit ${config?.entityNames?.singular?.toLowerCase()} successfully!`,
          };
        }

        throw new Error();
      } catch (error) {
        return {
          success: false,
          message: error?.response?.data?.error?.message,
        };
      }
    },
  },
  ...config,
});
