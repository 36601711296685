import React from "react";
import { Route } from "react-router-dom";
import {
  VisitorList,
  VisitorAdd,
  VisitorEdit,
  VisitorReport,
} from "../pages/Visitors";

export const register = (props) => {
  const basePath = "visitors";

  return (
    <>
      <Route path={basePath} element={<VisitorList />} exact={true} />
      <Route path={`${basePath}/add`} element={<VisitorAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<VisitorEdit />} />
      <Route path={`${basePath}-report`} element={<VisitorReport />} />
    </>
  );
};
