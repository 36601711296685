import config from "../config";
import moment from "moment";

const DATE_TIME_FORMAT = "YYYY-MM-DDTHH:mm:ss[Z]";

export default {
  id: `${config?.slugs?.plural}-list`,
  pageTitle: `${config?.entityNames?.plural} Report`,
  code: "TASK_COMMENTS",
  breadcrumbs: [
    {
      path: "/quick-projects/dashboard",
      title: "Home",
    },
    {
      path: `/quick-projects/reports`,
      title: "Reports",
    },
    {
      path: "",
      title: `${config?.entityNames?.plural} Report`,
    },
  ],
  columns: [
    {
      title: "Task",
      dataIndex: "task",
      selectable: true,
      references: {
        fieldName: "name",
      },
    },
    {
      title: "Employee",
      dataIndex: "employee",
      selectable: true,
      filterable: true,
      references: {
        fieldName: (record) => `${record?.first_name} ${record?.last_name}`,
      },
    },
    {
      title: "Comment",
      dataIndex: "comment",
      selectable: true,
      sorter: (a, b) => a?.comment?.localeCompare(b?.comment),
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      selectable: true,
      sorter: (a, b) =>
        moment(a.createdAt, DATE_TIME_FORMAT).unix() -
        moment(b.createdAt, DATE_TIME_FORMAT).unix(),
      render: (text) => (
        <span>
          {moment(text, DATE_TIME_FORMAT).format("DD-MM-YYYY, HH:mm:ss")}
        </span>
      ),
      width: 250,
    },
  ],
  ...config,
};
