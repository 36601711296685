import React, { useRef, useEffect, useState } from "react";
import { PageHeader, Breadcrumb, Button, Tooltip, Space, DatePicker, Col, Input, Row, Table, Select } from "antd";
import { useNavigate, Link } from "react-router-dom";
import { Box } from "@chakra-ui/react";
import pluralize from "pluralize";
import { ReloadOutlined, DownloadOutlined } from "@ant-design/icons";
import _ from "lodash";
import qs from "qs";
import moment from "moment";
import { useSelector } from "react-redux";
import { MAX_PAGINATION_LIMIT } from "../../../../../constants";
import { axios } from "../../../../../App";
import { ReportDataTable } from "../../../components";
import { Bar, Column, Pie } from "@ant-design/plots";

const DATE_TIME_FORMAT = "YYYY-MM-DDTHH:mm:ss[Z]";

const TimeLineVariance = (props) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    pageSize: 10,
  });
  const [total, setTotal] = useState(0);
  const [searchValues, setSearchValues] = useState({});
  const [searched, setSearched] = useState(false);
  const { user } = useSelector((state) => state?.auth);
  const [chartData, setchartData] = useState([]);
  const [secondChartData, setSecondChartData] = useState([])
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);

  const fetchProjects = async () => {
    try {
      const response = await axios.get(`projects`);

      if (response?.data) {
        let options = [
          {
            value: "",
            label: "All Projects",
          },
        ];

        response?.data?.data?.forEach((record) => {
          options?.push({
            value: record?.id,
            label: record?.attributes?.name,
          });
        });

        setProjects(options);
      }
    } catch (error) {
      console.log(error);
    }
  };


  const fetchData = async (params = null) => {
    try {
      setLoading(true);

      const queryObject = {
        populate: "*",
        pagination: {
          limit: 9999,
        },
      };

      if (!_.isEmpty(params)) {
        queryObject["filters"] = params;
      }

      const response = await axios.get(
        `daily-reports?${qs.stringify(queryObject)}`
      );

      const taskResponse = await axios.get(
        `tasks?${qs.stringify(queryObject)}`
      );

      if (response?.data && taskResponse?.data) {
        const tasks = taskResponse.data.data;


        const allIssues = response.data.data.reduce((acc, item) => {
          if (item?.attributes?.issues) {
            const issuesWithTasks = item.attributes.issues.map((issue) => {
              const task = tasks.filter((task) => task.id == issue.task);
              return { ...issue, task, all_tasks: item.attributes.tasks };
            });
            return acc.concat(issuesWithTasks);
          }
          return acc;
        }, []);

        console.log('all issues', allIssues);

        const filtered_by_project = selectedProject ? allIssues?.filter((issue) => issue?.task?.[0]?.attributes?.project?.data?.id == selectedProject) : allIssues

        setData(filtered_by_project);
      }

    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const refreshData = () => {
    onSearch();
  };


  const getColumnFilterProps = ({ dataIndex, references = {} }) => {
    if (typeof references?.fieldName === "function") {
      const transformer = references?.fieldName;

      return {
        render: (data) => transformer(data?.data?.attributes) || "N/A",
      };
    } else {
      return {
        render: (data) =>
          data?.data?.attributes?.[references?.fieldName] || "N/A",
      };
    }
  };

  const getColumnProps = (column) => {
    if (column?.references) {
      return getColumnFilterProps(column);
    }

    return column;
  };

  const onSearch = () => {
    setSearched(true);

    let filters = [
      {
        $or: [
          {
            employee: {
              first_name: {
                $containsi: searchValues?.keyword?.toLowerCase(),
              },
            }
          },
        ],
      },

      getFiltersForRoles(),
    ]

    if (searchValues?.start_date) {
      filters[0].$or.push({
        createdAt: {
          $gte: moment(searchValues?.start_date).format(DATE_TIME_FORMAT),
          $lte: searchValues?.end_date
            ? moment(searchValues?.end_date).format(DATE_TIME_FORMAT)
            : undefined, // If end_date exists, format it, otherwise it's ignored
        },
      });
    }

    fetchData({
      $and: filters,
    });
  };

  const onReset = async () => {
    setSelectedProject("")
    setSearchValues({});
    onSearch()
    setSearched(false);
  };

  const getResultsMessage = () => {
    if (!_.isEmpty(searchValues) && !loading && searched) {
      return total > 0 ? (
        <span>
          <strong>{total}</strong> matched results
        </span>
      ) : (
        <span>No matched results</span>
      );
    }

    return (
      <span>
        <strong>{total}</strong> total records
      </span>
    );
  };

  const renderHeaderButtons = () => {
    let buttons = [
      <Tooltip placement="top" title="Refresh">
        <Button
          icon={<ReloadOutlined />}
          onClick={refreshData}
          loading={loading}
        />
      </Tooltip>,
    ];

    return buttons;
  };

  const getFiltersForRoles = () => {
    const userType = user?.user_type?.toLowerCase()?.replace(/\s/i, "_");

    if (user?.is_admin) {
      return {};
    }

    if (userType === "employee") {
      return {
        $or: [
          {
            project_managers: {
              uid: user?.uid,
            },
          },
          {
            members: {
              uid: user?.uid,
            },
          },
        ],
      };
    }

    return {
      [pluralize(userType)]: {
        uid: user?.uid,
      },
    };
  };

  useEffect(() => {
    fetchData(getFiltersForRoles());
    fetchProjects()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const timeline_column = [
    {
      title: 'Project Name',
      dataIndex: 'project',
      key: 'project',
    },
    {
      title: 'List of tasks',
      dataIndex: 'task',
      key: 'task',
    },
    {
      title: 'Estimated Date',
      dataIndex: 'estimated_date',
      key: 'estimated_date',
    },

    {
      title: 'Actual Date',
      dataIndex: 'actual_date',
      key: 'actual_date',
    },
    {
      title: 'Description of Risk',
      dataIndex: 'risk',
      key: 'risk',
    },
    {
      title: 'Description of Mitigation',
      dataIndex: 'mitigation',
      key: 'mitigation',
    },
  ];

  const timeline_data = data?.map((issue) => ({
    project: issue?.task?.[0]?.attributes?.project?.data?.attributes?.name,
    task: issue?.task?.[0]?.attributes?.name,
    estimated_date: moment(issue?.task?.[0]?.attributes?.actual_end_date).format('YYYY-MM-DD'),
    actual_date: moment(issue?.task?.[0]?.attributes?.end_date).format('YYYY-MM-DD'),
    risk: issue?.risk,
    mitigation: issue?.mitigation,
  }))

  const preprocessData = (data) => {
    let totalTasks = 0;
    let mitigatedTasks = 0;
    let taskStatusCounts = {
      Pending: 0,
      Active: 0,
      Completed: 0
    };

    data?.forEach(project => {
      totalTasks += project.all_tasks.length;
      mitigatedTasks += project.all_tasks.filter(task => task.completed === "No").length;

      project.all_tasks.forEach(task => {
        if (task.completed === "No") {
          taskStatusCounts.Pending++;
        } else if (task.completed === "Yes") {
          taskStatusCounts.Completed++;
        } else {
          taskStatusCounts.Active++;
        }
      });
    });

    return {
      totalTasks,
      mitigatedTasks,
      taskStatusCounts
    };
  };

  const { totalTasks, mitigatedTasks, taskStatusCounts } = preprocessData(data);

  const totalTasksData = [
    { type: 'Task Mitigated', value: mitigatedTasks },
    { type: 'Remaining Task', value: totalTasks - mitigatedTasks }
  ];

  const taskStatusData = [
    { type: 'Pending', value: taskStatusCounts.Pending },
    { type: 'Active', value: taskStatusCounts.Active },
    { type: 'Completed', value: taskStatusCounts.Completed }
  ];

  const configTotalTasks = {
    appendPadding: 10,
    data: totalTasksData,
    angleField: 'value',
    colorField: 'type',
    radius: 1,
    label: {
      type: 'inner',
      offset: '-30%',
      content: '{value}',
      style: {
        fontSize: 14,
        textAlign: 'center',
      },
    },
    interactions: [{ type: 'element-active' }],
    color: ['#CF6C58', '#2E943E', '#2E4494', '#4BC0C0'], // Custom colors

  };

  const configTaskStatus = {
    appendPadding: 10,
    data: taskStatusData,
    angleField: 'value',
    colorField: 'type',
    radius: 1,
    label: {
      type: 'inner',
      offset: '-30%',
      content: '{value}',
      style: {
        fontSize: 14,
        textAlign: 'center',
      },
    },
    interactions: [{ type: 'element-active' }],
    color: ['#CF6C58', '#2E943E', '#2E4494', '#4BC0C0'], // Custom colors

  };


  console.log('Time line', data, timeline_data)

  return (
    <Box>

      <ReportDataTable
        data={timeline_data}
        config={{
          project_title: '',
          pie_title: '',
          pageTitle: 'Time line Variance Report',
          columns: timeline_column,
          slugs: {
            plural: 'Time line Variance Report'
          }
        }}
        loading={loading}
        pagination={pagination}

        Toolbar={
          <div className="toolbar">
            <Row gutter={16}>
              <Col span={10}>
                <Input
                  value={searchValues?.keyword}
                  placeholder="Search for keyword..."
                  onChange={(e) =>
                    setSearchValues((prev) => ({
                      ...prev,
                      keyword: e.target.value,
                    }))
                  }
                />
              </Col>

              <Col span={7}>
                <DatePicker
                  onChange={(value) =>
                    setSearchValues((prev) => ({
                      ...prev,
                      start_date: value,
                    }))
                  }
                  value={searchValues?.start_date}
                  placeholder="Select start date"
                  style={styles.datePicker}
                />
              </Col>

              <Col span={7}>
                <DatePicker
                  onChange={(value) =>
                    setSearchValues((prev) => ({
                      ...prev,
                      end_date: value,
                    }))
                  }
                  value={searchValues?.endDate}
                  placeholder="Select end date"
                  style={styles.datePicker}
                />
              </Col>

              <Col span={7}>
                <Select
                  value={selectedProject}
                  onChange={setSelectedProject}
                  options={projects}
                  style={{ width: "100%", marginTop: '20px' }}
                  placeholder="Select Project"
                />
              </Col>
            </Row>

            <div className="toolbar-buttons">
              <p className="toolbar-results">{getResultsMessage()}</p>

              <Space>
                <Button onClick={onReset}>Reset</Button>
                <Button type="primary" onClick={onSearch}>
                  Search
                </Button>
              </Space>
            </div>
          </div>
        }
        buttons={renderHeaderButtons()}
        onBack={() => navigate(`/quick-projects/reports`)}
      />

      <Table dataSource={timeline_data} columns={timeline_column} pagination={false} />


      <div style={{ margin: '5rem 0', fontSize: '20px' }} id="pieChart">
        <p>Total Number of Tasks Mitigated to Total Tasks</p>
        <div style={{ border: '1px solid #E9E9E9', width: '60%', borderRadius: '10px', margin: '20px 0', padding: '10px' }}>
          <Pie {...configTotalTasks} />
        </div>
      </div>


      <div style={{ margin: '5rem 0', fontSize: '20px' }} id="pieChart2">
        <p>Distribution of Task Status: Pending, Active, Completed</p>
        <div style={{ border: '1px solid #E9E9E9', width: '60%', borderRadius: '10px', margin: '20px 0', padding: '10px' }}>
          <Pie {...configTaskStatus} />
        </div>
      </div>

    </Box>
  );
};

const styles = {
  breadcrumbs: {},
  pageHeader: {
    padding: 0,
    paddingBottom: 12,
  },
  datePicker: {
    width: "100%",
  },
};

export default TimeLineVariance;