import React, { useState, useEffect } from "react";
import { AddForm } from "../../../components";
import { useNavigate } from "react-router-dom";
import { makeConfig } from "./config";
import { useSelector } from "react-redux";
import { fetchData } from "../../../../../utils/helpers/data.helper";

const Page = (props) => {
  const navigate = useNavigate();
  const auth = useSelector((state) => state?.auth);
  const config = makeConfig({ navigate, auth });
  const [values, setValues] = useState({
    project: [],
    purchase_order: [],
    all_purchase_order: [],
    all_material_item: [],
    material_item: [],
  });
  const contentTypes = [
    {
      slug: "projects",
      query: {
        populate: "*",
      },
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          all_project: data,
          project: data,
        })),
    },
    {
      slug: "material-items",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          all_material_item: data,
        })),
    },
    {
      slug: "material-units",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          material_unit: data,
        })),
    },
  ];

  const fetchProjectMaterials = (projectId) => {
    fetchData({
      slug: "task-materials",
      query: {
        filters: {
          task: {
            project: projectId,
          },
        },
        populate: "*",
      },
      onSuccess: (data) => {
        const materialItemIds = data?.map(
          (record) => record?.material_item?.data?.id
        );

        setValues((prev) => ({
          ...prev,
          material_item: values?.all_material_item?.filter((item) =>
            materialItemIds?.includes(item?.value)
          ),
        }));
      },
    });
  };

  useEffect(() => {
    contentTypes?.forEach((contentType) => {
      fetchData(contentType);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AddForm
      config={config}
      values={values}
      onFieldChange={{
        project: (antForm) => {
          const projectId = antForm.getFieldValue("project");

          if (projectId) {
            antForm.setFieldValue("purchase_order", undefined);
            antForm.setFieldValue("material_item", undefined);

            fetchProjectMaterials(projectId);

            setValues((prev) => ({
              ...prev,
              purchase_order: values?.all_purchase_order
                ?.filter(
                  (item) => item?.project?.data?.id === Number(projectId)
                )
                ?.map((item) => ({
                  value: item?.id,
                  label: item?.heading,
                })),
            }));
          }
        },
      }}
    />
  );
};

export default Page;