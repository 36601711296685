import React, { useState, useEffect } from "react";
import { DataTable } from "../../../../components";
import { useNavigate } from "react-router-dom";
import { Space, Button, Popconfirm, Tooltip, notification } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import qs from "qs";
import config from "./config";
import { axios } from "../../../../../../App";
import _ from "lodash";

const Page = (props) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    pageSize: 10,
  });
  const [total, setTotal] = useState(0);
  const [searchValues, setSearchValues] = useState({});

  const fetchData = async (params = null) => {
    try {
      setLoading(true);

      const queryObject = {
        populate: "*",
      };

      if (!_.isEmpty(params)) {
        queryObject["filters"] = params;
      }

      const response = await axios.get(
        `${props?.contentTypeId}?${qs.stringify(queryObject)}`
      );

      if (response?.data) {
        setData(
          response?.data?.data?.map((item) => ({
            id: item?.id,
            ...item?.attributes,
          }))
        );

        setTotal(response?.data?.meta?.pagination?.total);

        setPagination({
          ...response?.data?.meta?.pagination,
          pageSize: 10,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const refreshData = () => {
    onSearch();
  };

  const deleteItem = async (id) => {
    try {
      const response = await axios.delete(`${props?.contentTypeId}/${id}`);

      if (response?.data) {
        notification["success"]({
          message: `Remove ${config?.[props?.configId]?.entityNames?.singular}`,
          description: `Remove ${config?.[
            props?.configId
          ]?.entityNames?.singular?.toLowerCase()} successfully!`,
        });

        refreshData();
      } else {
        throw new Error();
      }
    } catch (error) {
      notification["error"]({
        message: `Remove ${config?.[props?.configId]?.entityNames?.singular}`,
        description: `Remove ${config?.[
          props?.configId
        ]?.entityNames?.singular?.toLowerCase()} failed!`,
      });
    }
  };

  const getColumnFilterProps = ({ dataIndex, references = {} }) => {
    if (typeof references?.fieldName === "function") {
      const transformer = references?.fieldName;

      return {
        render: (data) => transformer(data?.data?.attributes) || "N/A",
      };
    } else {
      return {
        render: (data) =>
          data?.data?.attributes?.[references?.fieldName] || "N/A",
      };
    }
  };

  const getColumnProps = (column) => {
    if (column?.references) {
      return getColumnFilterProps(column);
    }

    return column;
  };

  const onSearch = () => {
    fetchData({
      name: {
        $containsi: searchValues?.keyword?.toLowerCase(),
      },
    });
  };

  useEffect(() => {
    fetchData(filters);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.contentTypeId]);

  return (
    <DataTable
      data={data}
      config={{
        pageTitle: config?.[props?.configId]?.title,
        columns: config?.[props?.configId]?.columns
          ?.map((column) => ({
            ...column,
            ...getColumnProps(column),
          }))
          ?.concat({
            title: "Actions",
            fixed: "right",
            width: 100,
            selectable: false,
            render: ({ id }) => {
              return (
                <Space>
                  <Popconfirm
                    title={`Are you sure to remove this ${config?.[
                      props?.configId
                    ]?.entityNames?.singular?.toLowerCase()}?`}
                    okText="Yes"
                    cancelText="No"
                    onConfirm={() => deleteItem(id)}
                  >
                    <Tooltip
                      placement="bottom"
                      title={`Remove ${
                        config?.[props?.configId]?.entityNames?.singular
                      }`}
                    >
                      <Button type="danger" icon={<DeleteOutlined />} />
                    </Tooltip>
                  </Popconfirm>
                </Space>
              );
            },
          }),
      }}
      loading={loading}
      pagination={pagination}
      actions={{
        onAdd: props?.onAdd,
        onRefresh: refreshData,
      }}
      showsBreadcrumbs={false}
    />
  );
};

export default Page;
