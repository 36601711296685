import React from "react";
import { Route } from "react-router-dom";
import { LocationList, LocationAdd, LocationEdit } from "../pages/Locations";

export const register = (props) => {
  const basePath = "locations";

  return (
    <>
      <Route path={basePath} element={<LocationList />} exact={true} />
      <Route path={`${basePath}/add`} element={<LocationAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<LocationEdit />} />
    </>
  );
};
