export default {
    slugs: {
      singular: "quotation",
      plural: "quotations",
    },
    entityNames: {
      singular: "Quotation",
      plural: "Quotations",
    },
  };
  