import { axios } from "../../../../../App";
import config from "../config";

export const makeConfig = ({ navigate }) => ({
  id: `${config?.plural}-edit`,
  title: `Edit ${config?.entityNames?.singular}`,
  pageTitle: `Edit ${config?.entityNames?.singular}`,
  breadcrumbs: [
    {
      path: "/quick-stocks/dashboard",
      title: "Home",
    },
    {
      path: `/quick-stocks/${config?.slugs?.plural}`,
      title: config?.entityNames?.plural,
    },
    {
      path: "",
      title: `Edit ${config?.entityNames?.singular}`,
    },
  ],
  components: [
    {
      span: 24,
      component: "input",
      label: "Heading",
      name: "heading",
      required: true,
      placeholder: "Enter heading",
      rules: [
        {
          required: true,
          message: "Heading is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Agency",
      name: "agency",
      placeholder: "Select agency",
      required: true,
      rules: [
        {
          required: true,
          message: "Agency is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Project",
      name: "project",
      placeholder: "Select project",
      required: true,
      rules: [
        {
          required: true,
          message: "Project is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Delivery Address",
      name: "delivery_address",
      placeholder: "Select delivery address",
      required: true,
      rules: [
        {
          required: true,
          message: "Delivery address is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Work Group",
      name: "work_group",
      placeholder: "Select work group",
      required: true,
      rules: [
        {
          required: true,
          message: "Work group is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Requirement Form",
      name: "agency_requirement_form",
      placeholder: "Select requirement form",
      required: true,
      rules: [
        {
          required: true,
          message: "Requirement form is required!",
        },
      ],
    },
    {
      span: 12,
      component: "number",
      label: "Budget",
      name: "budget",
      required: true,
      placeholder: "Enter budget",
      rules: [
        {
          required: true,
          message: "Budget is required!",
        },
      ],
      min: 0,
    },
    {
      span: 12,
      component: "number",
      label: "Estimated Amount",
      name: "estimated_amount",
      required: true,
      placeholder: "Enter estimated amount",
      rules: [
        {
          required: true,
          message: "Estimated amount is required!",
        },
      ],
      min: 0,
    },
    {
      span: 12,
      component: "select",
      label: "Terms & Conditions",
      name: "agency_term",
      placeholder: "Select terms & conditions",
      required: true,
      rules: [
        {
          required: true,
          message: "Terms & conditions is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Payment Schedule",
      name: "agency_payment_schedule",
      placeholder: "Select payment schedule",
      required: true,
      rules: [
        {
          required: true,
          message: "Payment schedule is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Urgency Level",
      name: "urgency_level",
      placeholder: "Select urgency level",
      required: true,
      rules: [
        {
          required: true,
          message: "Urgency level is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Approved By",
      name: "approved_by",
      placeholder: "Select employee",
      required: true,
      rules: [
        {
          required: true,
          message: "Approved by is required!",
        },
      ],
    },
    {
      span: 24,
      component: "editor",
      label: "Remarks",
      name: "remarks",
      placeholder: "Enter remarks",
      rows: 5,
    },
    {
      span: 24,
      component: "editor",
      label: "Approval Remarks",
      name: "approval_remarks",
      placeholder: "Enter approval remarks",
      rows: 5,
    },
  ],
  actions: {
    onBack: () => navigate(`/quick-stocks/${config?.slugs?.plural}`),
    onFinish: async (values, data) => {
      try {
        const response = await axios.put(
          `/${config?.slugs?.plural}/${data?.id}`,
          {
            data: values,
          }
        );

        if (response?.data) {
          return {
            success: true,
            message: `Edit ${config?.entityNames?.singular?.toLowerCase()} successfully!`,
          };
        }

        throw new Error();
      } catch (error) {
        return {
          success: false,
          message: error?.response?.data?.error?.message,
        };
      }
    },
  },
  ...config,
});
