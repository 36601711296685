export const makeConfig = () => ({
  id: `profile-change`,
  title: `Change Profile`,
  pageTitle: `Change Profile`,
  breadcrumbs: [
    {
      path: "/",
      title: "Home",
    },
    {
      path: "",
      title: `Change profile`,
    },
  ],
});
