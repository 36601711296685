import { axios } from "../../../../../App";
import { v4 } from "uuid";
import config from "../config";

export const makeConfig = ({ navigate, auth }) => ({
  id: `${config?.slugs?.plural}-add`,
  title: `Add New ${config?.entityNames?.singular}`,
  pageTitle: `Add New ${config?.entityNames?.singular}`,
  breadcrumbs: [
    {
      path: "/account/dashboard",
      title: "Home",
    },
    {
      path: `/account/${config?.slugs?.plural}`,
      title: config?.entityNames?.plural,
    },
    {
      path: "",
      title: `Add New ${config?.entityNames?.singular}`,
    },
  ],
  components: [
    {
      span: 12,
      component: "select",
      label: "Document",
      name: "document",
      placeholder: "Select document",
      required: true,
      rules: [
        {
          required: true,
          message: "Document no is required!",
        },
      ],
    },
    {
      span: 12,
      component: "input",
      label: "Document No",
      name: "document_no",
      required: true,
      placeholder: "Enter document no",
      rules: [
        {
          required: true,
          message: "Document no is required!",
        },
      ],
    },
    {
      span: 12,
      component: "uploader",
      label: "Front Side",
      name: "front_side",
      placeholder: "Upload front side",
    },
    {
      span: 12,
      component: "uploader",
      label: "Back Side",
      name: "back_side",
      placeholder: "Upload back side",
    },
    {
      span: 24,
      component: "editor",
      label: "Description",
      name: "description",
      placeholder: "Enter description",
      rows: 5,
    },
    {
      span: 24,
      component: "input",
      type: "hidden",
      name: "front_side",
    },
    {
      span: 24,
      component: "input",
      type: "hidden",
      name: "back_side",
    },
  ],
  actions: {
    onBack: () => navigate(`/account/${config?.slugs?.plural}`),
    onFinish: async (values, data) => {
      try {
        const response = await axios.post(`/${config?.slugs?.plural}`, {
          data: {
            ...values,
            uid: v4(),
            front_side: values?.front_side !== "" ? values?.front_side : null,
            back_side: values?.back_side !== "" ? values?.back_side : null,
            employee: data?.employee,
            // creator: auth?.user?.id,
          },
        });

        if (response?.data) {
          return {
            success: true,
            message: `Add new ${config?.entityNames?.singular?.toLowerCase()} successfully!`,
          };
        }

        throw new Error();
      } catch (error) {
        return {
          success: false,
          message: `Add new ${config?.entityNames?.singular?.toLowerCase()} failed!`,
        };
      }
    },
  },
});
