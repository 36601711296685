import React from "react";
import { Route } from "react-router-dom";
import {
  AgencyGroupList,
  AgencyGroupAdd,
  AgencyGroupEdit,
} from "../pages/AgencyGroups";

export const register = (props) => {
  const basePath = "agency-groups";

  return (
    <>
      <Route path={basePath} element={<AgencyGroupList />} exact={true} />
      <Route path={`${basePath}/add`} element={<AgencyGroupAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<AgencyGroupEdit />} />
    </>
  );
};
