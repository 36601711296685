import React from "react";
import { Route } from "react-router-dom";
import {
  WorkGroupList,
  WorkGroupAdd,
  WorkGroupEdit,
} from "../pages/WorkGroups";

export const register = (props) => {
  const basePath = "work-groups";

  return (
    <>
      <Route path={basePath} element={<WorkGroupList />} exact={true} />
      <Route path={`${basePath}/add`} element={<WorkGroupAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<WorkGroupEdit />} />
    </>
  );
};
