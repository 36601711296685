import { AUTH_LOG_OUT, AUTH_LOGIN } from "./types";

export const loginUser = (data) => ({
  type: AUTH_LOGIN,
  payload: data,
});

export const logOutUser = () => ({
  type: AUTH_LOG_OUT,
});
