export default {
  slugs: {
    singular: "vendor-quotations-comparison-list",
    plural: "vendor-quotations-comparison-lists",
  },
  entityNames: {
    singular: "Vendor Quotation Comparison List",
    plural: "Vendor Quotation Comparison Lists",
  },
};
