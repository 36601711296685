export default {
  slugs: {
    singular: "agency",
    plural: "agencies",
  },
  entityNames: {
    singular: "Agency",
    plural: "Agencies",
  },
};
