import React from "react";
import { Route } from "react-router-dom";
import {
  SubContractorRequirementFormList,
  SubContractorRequirementFormAdd,
  SubContractorRequirementFormEdit,
  SubContractorRequirementFormReport,
  SubContractorRequirementFormQuotationLinkList,
} from "../pages/SubContractorRequirementForms";

export const register = (props) => {
  const basePath = "sub-contractor-requirement-forms";

  return (
    <>
      <Route
        path={basePath}
        element={<SubContractorRequirementFormList />}
        exact={true}
      />
      <Route
        path={`${basePath}/add`}
        element={<SubContractorRequirementFormAdd />}
      />
      <Route
        path={`${basePath}/edit/:id`}
        element={<SubContractorRequirementFormEdit />}
      />
      <Route
        path={`${basePath}/quotations/:id`}
        element={<SubContractorRequirementFormQuotationLinkList />}
      />
      <Route
        path={`${basePath}-report`}
        element={<SubContractorRequirementFormReport />}
      />
    </>
  );
};
