import moment from "moment";

const commify = (x) => {
  return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const config = {
  slugs: {
    singular: "sub-contractor-payment-schedule",
    plural: "sub-contractor-payment-schedules",
  },
  entityNames: {
    singular: "Payment Schedule",
    plural: "Payment Schedules",
  },
};

export default {
  id: `${config?.slugs?.plural}-list`,
  pageTitle: config?.entityNames?.plural,
  breadcrumbs: [
    {
      path: "/quick-stocks/dashboard",
      title: "Home",
    },
    {
      path: "",
      title: config?.entityNames?.plural,
    },
  ],
  columns: [
    {
      title: "Heading",
      dataIndex: "heading",
      selectable: true,
      sorter: (a, b) => a?.heading?.localeCompare(b?.heading),
    },
    {
      title: "Scheduled Date",
      dataIndex: "scheduled_date",
      selectable: true,
      sorter: (a, b) =>
        moment(a.scheduled_date).unix() - moment(b.scheduled_date).unix(),
      render: (text) => <span>{moment(text).format("DD-MM-YYYY")}</span>,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      selectable: true,
      render: (text) => commify(text || 0),
      sorter: (a, b) => a?.amount - b?.amount,
    },
  ],
  ...config,
};
