export default {
  slugs: {
    singular: "cost-code",
    plural: "cost-codes",
  },
  entityNames: {
    singular: "Cost Code",
    plural: "Cost Codes",
  },
};
