export default {
  slugs: {
    singular: "task-machine",
    plural: "task-machines",
  },
  entityNames: {
    singular: "Task Machine",
    plural: "Task Machines",
  },
};
