import config from "../config";
import moment from "moment";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  id: `${config?.slugs?.plural}-list`,
  pageTitle: `${config?.entityNames?.plural} Report`,
  code: "LEAVE_APPLICATIONS",
  breadcrumbs: [
    {
      path: "/quick-teams/dashboard",
      title: "Home",
    },
    {
      path: `/quick-teams/reports`,
      title: "Reports",
    },
    {
      path: "",
      title: `${config?.entityNames?.plural} Report`,
    },
  ],
  columns: [
    {
      title: "Employee",
      dataIndex: "employee",
      selectable: true,
      filterable: true,
      references: {
        fieldName: (record) => `${record?.first_name} ${record?.last_name}`,
      },
    },
    {
      title: "Leave Type",
      dataIndex: "leave_type",
      selectable: true,
      filterable: true,
      references: {
        fieldName: "name",
      },
    },
    {
      title: "From Date",
      dataIndex: "from_date",
      selectable: true,
      sorter: (a, b) => moment(a.from_date).unix() - moment(b.from_date).unix(),
      render: (text) => <span>{moment(text).format("DD-MM-YYYY")}</span>,
      width: 250,
    },
    {
      title: "To Date",
      dataIndex: "to_date",
      selectable: true,
      sorter: (a, b) => moment(a.to_date).unix() - moment(b.to_date).unix(),
      render: (text) => <span>{moment(text).format("DD-MM-YYYY")}</span>,
      width: 250,
    },
  ],
  ...config,
};
