import React from "react";
import { Route } from "react-router-dom";
import {
  ReleasedNoteList,
  ReleasedNoteAdd,
  ReleasedNoteEdit,
  ReleasedNoteView,
  ReleasedNoteReport,
  ReleasedNoteRelease,
  ReleasedNoteHistory,
} from "../pages/ReleasedNotes";

export const register = (props) => {
  const basePath = "released-notes";

  return (
    <>
      <Route path={basePath} element={<ReleasedNoteList />} exact={true} />
      <Route path={`${basePath}/add`} element={<ReleasedNoteAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<ReleasedNoteEdit />} />
      <Route path={`${basePath}/view/:id`} element={<ReleasedNoteView />} />
      <Route
        path={`${basePath}/release/:id`}
        element={<ReleasedNoteRelease />}
      />
      <Route
        path={`${basePath}/release-histories/:id`}
        element={<ReleasedNoteHistory />}
      />
      <Route path={`${basePath}-report`} element={<ReleasedNoteReport />} />
    </>
  );
};
