export default {
  slugs: {
    singular: "event-template",
    plural: "event-templates",
  },
  entityNames: {
    singular: "Event Template",
    plural: "Event Templates",
  },
};
