import React, { useState, useEffect } from "react";
import { Input, Form, Space, Button, Tooltip } from "antd";
import { axios } from "../../../../App";
import { GrDownload } from "react-icons/gr";
import { DeleteOutlined } from "@ant-design/icons";
import { saveAs } from "file-saver";
import { STRAPI_URL } from "../../../../constants";

const Uploader = (props) => {
  const { required, ...fieldProps } = props;
  const [showFile, setShowFile] = useState(false);
  const [fileData, setFileData] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  const onDownload = () => {
    const url = `${STRAPI_URL}${fileData?.url}?updated_at=${fileData?.updatedAt}`;

    saveAs(url, fileData?.name);
  };

  const onRemoveFile = () => {
    setFileData(null);
    setShowFile(false);

    props?.form?.setFieldsValue({
      [props?.name]: null,
    });
  };

  const onChangeFile = async (e) => {
    try {
      setIsUploading(true);

      const file = e.target.files?.[0];

      let fileData = new FormData();
      fileData.append("files", file);

      const response = await axios.post("/upload", fileData);

      if (response?.data) {
        props?.form?.setFieldsValue({
          [props?.name]: response?.data?.[0]?.id,
        });

        setIsUploading(false);
      }
    } catch (error) {
      console.log(error);
      setIsUploading(false);
    }
  };

  useEffect(() => {
    if (props?.data?.[fieldProps?.name]) {
      setFileData(props?.data?.[fieldProps?.name]?.data?.attributes || null);
      setShowFile(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.data]);

  return (
    <Form.Item
      label={isUploading ? `${props?.label} (Uploading...)` : props?.label}
      name={props?.name}
      rules={props?.rules}
      initialValue=""
    >
      {(!fileData || !showFile) && (
        <Input
          {...fieldProps}
          type="file"
          onChange={onChangeFile}
          disabled={props?.disabled || isUploading}
        />
      )}

      {fileData && showFile && (
        <span style={styles.attachment}>
          <img
            src="https://img.icons8.com/external-inipagistudio-mixed-inipagistudio/344/external-document-computer-and-internet-inipagistudio-mixed-inipagistudio.png"
            alt=""
            style={styles.attachmentIcon}
          />

          <span style={styles.attachmentText}>
            <span style={styles.attachmentName}>{fileData?.name}</span>
            <span style={styles.attachmentSize}>{fileData?.size} KB</span>
          </span>

          <Space>
            <Tooltip placement="top" title="Download File">
              <Button
                icon={<GrDownload style={styles.buttonIcon} />}
                onClick={onDownload}
              />
            </Tooltip>

            <Tooltip placement="top" title="Remove File">
              <Button
                type="danger"
                icon={<DeleteOutlined />}
                onClick={onRemoveFile}
              />
            </Tooltip>
          </Space>
        </span>
      )}
    </Form.Item>
  );
};

const styles = {
  attachment: {
    padding: 12,
    borderRadius: 6,
    display: "flex",
    alignItems: "center",
    border: "2px solid #eff2f3",
    background: "#eff2f3",
  },
  attachmentText: {
    marginLeft: 12,
    flex: 1,
  },
  attachmentName: {
    fontWeight: "bold",
    display: "block",
  },
  attachmentSize: {
    color: "#888",
    display: "block",
    fontSize: 12,
  },
  attachmentIcon: {
    width: 42,
    height: 42,
  },
  buttonIcon: {
    margin: "0 auto",
  },
};

export default Uploader;
