import { Modal, Form, Input } from "antd";
import React, { useState } from "react";
import { axios } from "../../../../App";

const AddFileModal = ({
  visible,
  onClose,
  onFinish,
  currentFileItemId = null,
  moduleName,
}) => {
  const [form] = Form.useForm();
  const [attachment, setAttachment] = useState(null);
  const [uploading, setUploading] = useState(false);

  const onChangeFile = async (e) => {
    try {
      const file = e.target.files?.[0];

      setUploading(true);

      let fileData = new FormData();
      fileData.append("files", file);

      const response = await axios.post("/upload", fileData);

      if (response?.data) {
        setAttachment(response?.data?.[0]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setUploading(false);
    }
  };

  const onSubmit = async (values) => {
    try {
      const requestData = {
        name: values?.name,
        // module: moduleName,
        attachment,
        item_type: "File",
        is_deleted: false,
      };

      if (currentFileItemId) {
        requestData["parent"] = currentFileItemId;
      }

      const response = await axios.post(`/file-items`, {
        data: requestData,
      });

      if (response?.data) {
        form.resetFields();

        onFinish();
        onClose();
      }

      throw new Error();
    } catch (error) {}
  };

  return (
    <Modal
      title="Add New File"
      visible={visible}
      onOk={form.submit}
      onCancel={onClose}
      destroyOnClose
    >
      <Form
        form={form}
        name="addFileForm"
        onFinish={onSubmit}
        autoComplete="off"
        layout="vertical"
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: "Name is required!" }]}
        >
          <Input placeholder="Enter name" />
        </Form.Item>

        <Form.Item
          label="Attachment"
          name="attachment"
          rules={[{ required: true, message: "Attachment is required!" }]}
        >
          <Input type="file" onChange={onChangeFile} disabled={uploading} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddFileModal;
