import React, { useState, useEffect } from "react";
import { DataTable, ReportDataTable } from "../../../components";
import { useNavigate } from "react-router-dom";
import { Space, Button, Row, Col, Input, Tooltip, DatePicker, Table } from "antd";
import qs from "qs";
import config from "./config";
import { axios } from "../../../../../App";
import _ from "lodash";
import { ReloadOutlined, DownloadOutlined } from "@ant-design/icons";
import moment from "moment";
import { Pie } from "@ant-design/plots";

const DATE_TIME_FORMAT = "YYYY-MM-DDTHH:mm:ss[Z]";

const Page = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    pageSize: 10,
  });
  const [total, setTotal] = useState(0);
  const [searchValues, setSearchValues] = useState({});
  const [budgetData, setBudgetData] = useState([]);

  const fetchData = async (params = null) => {
    try {
      setLoading(true);

      const queryObject = {
        populate: "*",
      };

      if (!_.isEmpty(params)) {
        queryObject["filters"] = params;
      }

      const response = await axios.get(
        `sub-contractors?${qs.stringify(queryObject)}`
      );

      if (response?.data) {
        const subContractors = response?.data?.data?.map((item) => ({
          id: item?.id,
          ...item?.attributes,
        }));

        setData(subContractors);

        // Fetch work orders for each sub-contractor
        const workOrdersPromises = subContractors.map(async (subContractor) => {
          const extraObject = {
            populate: "*",
            filters: {
              sub_contractor: {
                id: subContractor.id,
              }
            }
          };

          const extraWorkResponse = await axios.get(
            `${config?.slugs?.plural}?${qs.stringify(extraObject)}`
          );

          return extraWorkResponse?.data?.data?.map((item) => ({
            ...item?.attributes,
          }));
        });

        const paymentSchedulePromises = subContractors.map(async (subContractor) => {
          const extraObject = {
            populate: "*",
            filters: {
              sub_contractor: {
                id: subContractor.id,
              }
            }
          };

          const paymentResponse = await axios.get(
            `sub-contractor-payment-schedules?${qs.stringify(extraObject)}`
          );

          return paymentResponse?.data?.data?.map((item) => ({
            ...item?.attributes,
          }));
        });

        const workOrdersResults = await Promise.all(workOrdersPromises);

        const paymentResults = await Promise.all(paymentSchedulePromises);

        const updatedData = subContractors?.map((subContractor, index) => {
          let paymentScheduleTotal = paymentResults[index]?.reduce((sum, item) => sum + item.amount, 0) || 0;
          let workOrdersTotal = workOrdersResults[index]?.reduce((sum, item) => sum + item.grand_total, 0) || 0;
          return {
            ...subContractor,
            workOrders: workOrdersResults[index] || [],
            workOrdersTotal,
            paymentSchedules: paymentResults[index] || [],
            paymentScheduleTotal, // add the totalAmount to the updated data
          };
        });


        const total_cost = updatedData?.reduce((sum, item) => sum + item.workOrdersTotal, 0) || 0;
        const advance_given = updatedData?.reduce((sum, item) => sum + item.paymentScheduleTotal, 0) || 0;

        setBudgetData([
          { type: 'Total Cost', value: total_cost },
          { type: 'Advance Given Out', value: advance_given }
        ]);

        setData(updatedData);
        setTotal(response?.data?.meta?.pagination?.total);
        setPagination({
          ...response?.data?.meta?.pagination,
          pageSize: 10,
        });
      }

    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const refreshData = () => {
    onSearch();
  };

  const getColumnFilterProps = ({ dataIndex, references = {} }) => {
    if (typeof references?.fieldName === "function") {
      const transformer = references?.fieldName;

      return {
        render: (data) => transformer(data?.data?.attributes) || "N/A",
      };
    } else {
      return {
        render: (data) =>
          data?.data?.attributes?.[references?.fieldName] || "N/A",
      };
    }
  };

  const getColumnProps = (column) => {
    if (column?.references) {
      return getColumnFilterProps(column);
    }

    return column;
  };

  const onSearch = () => {
    fetchData({
      $or: [
        {
          name: {
            $containsi: searchValues?.keyword?.toLowerCase(),
          },
        },
        {
          createdAt: {
            $gte: moment(searchValues?.start_date).format(DATE_TIME_FORMAT),
            $lte: moment(searchValues?.end_date).format(DATE_TIME_FORMAT),
          },
        },
      ],
    });
  };

  const onReset = () => {
    setSearchValues({});
    fetchData(null);
  };

  const getResultsMessage = () => {
    if (!_.isEmpty(searchValues)) {
      return total > 0 ? (
        <span>
          <strong>{total}</strong> matched results
        </span>
      ) : (
        <span>No matched results</span>
      );
    }

    return (
      <span>
        <strong>{total}</strong> total records
      </span>
    );
  };

  useEffect(() => {
    fetchData(filters);
  }, []);

  const BudgetPieChart = () => {
    const config = {
      appendPadding: 10,
      data: budgetData,
      angleField: 'value',
      colorField: 'type',
      radius: 1,
      label: {
        type: 'inner',
        offset: '-30%',
        content: '{value}',
        style: {
          fontSize: 14,
          textAlign: 'center',
        },
      },
      interactions: [{ type: 'element-active' }],
    };

    return <Pie {...config} />;
  };

  const payment_columns = [
    {
      title: "Heading",
      dataIndex: "heading",
      selectable: true,

    },
    {
      title: "Sub-Contractor",
      dataIndex: "sub_contractor",
      selectable: true,

    },
    {
      title: 'Scheduled Date',
      dataIndex: 'scheduled_date',
      key: 'scheduled_date',
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: 'amount'
    },

  ];

  let payments_data = [];

  if (data) {
    payments_data = data.flatMap((item) => 
      item?.paymentSchedules?.map((payment) => ({
        heading: payment?.heading,
        sub_contractor: payment?.sub_contractor?.data?.attributes?.name,
        scheduled_date: payment?.scheduled_date,
        amount: payment?.amount
      })) || []
    );
  }
  

  console.log('subbbbbb', data, payments_data)

  return (
    <>
      <ReportDataTable
        data={data}
        config={{
          ...config,
          columns: config?.columns?.map((column) => ({
            ...column,
            ...getColumnProps(column),
          })),
        }}
        loading={loading}
        pagination={pagination}
        actions={{
          onAdd: () => navigate(`/quick-stocks/${config?.slugs?.plural}/add`),
          onRefresh: refreshData,
        }}
        Toolbar={
          <div className="toolbar">
            <Row gutter={16}>
              <Col span={10}>
                <Input
                  value={searchValues?.keyword}
                  placeholder="Search for keyword..."
                  onChange={(e) =>
                    setSearchValues((prev) => ({
                      ...prev,
                      keyword: e.target.value,
                    }))
                  }
                />
              </Col>

              <Col span={7}>
                <DatePicker
                  onChange={(value) =>
                    setSearchValues((prev) => ({
                      ...prev,
                      start_date: value,
                    }))
                  }
                  value={searchValues?.start_date}
                  placeholder="Select start date"
                  style={styles.datePicker}
                />
              </Col>

              <Col span={7}>
                <DatePicker
                  onChange={(value) =>
                    setSearchValues((prev) => ({
                      ...prev,
                      end_date: value,
                    }))
                  }
                  value={searchValues?.endDate}
                  placeholder="Select end date"
                  style={styles.datePicker}
                />
              </Col>
            </Row>

            <div className="toolbar-buttons">
              <p className="toolbar-results">{getResultsMessage()}</p>

              <Space>
                <Button onClick={onReset}>Reset</Button>
                <Button type="primary" onClick={onSearch}>
                  Search
                </Button>
              </Space>
            </div>
          </div>
        }
        buttons={[
          <Tooltip placement="top" title="Refresh">
            <Button
              icon={<ReloadOutlined />}
              onClick={refreshData}
              loading={loading}
            />
          </Tooltip>
        ]}
        onBack={() => navigate(`/quick-stocks/reports`)}
      />

      <div id="taskTable">
        <h1 style={{ fontSize: '24px', marginBottom: '20px'}}>Payment Schedules</h1>
        <Table columns={payment_columns} dataSource={payments_data} pagination={{
          pageSize: 1000,
        }} />
      </div>

      <div id="pieChart">
        <BudgetPieChart />
      </div>

    </>
  );
};

const styles = {
  datePicker: {
    width: "100%",
  },
};

export default Page;
