import React, { useRef, useState } from "react";
import { axios } from "../../../../../App";
import config from "./config";
import qs from "qs";
import { useLocation, useNavigate } from "react-router-dom";
import { DataTable, ReportDataTable } from "../../../components";
import {
  Button,
  Col,
  DatePicker,
  Input,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
  Tooltip,
  notification,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import { ImFilePdf } from "react-icons/im";
import _ from "lodash";
import { useEffect } from "react";
import moment from "moment";
import { RiFileCopyLine } from "react-icons/ri";
import { v4 } from "uuid";
import { BASE_API_URL, STRAPI_URL } from "../../../../../constants";

const { Option } = Select;
const DATE_TIME_FORMAT = "YYYY-MM-DDTHH:mm:ss[Z]";


const QuotationReport = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    pageSize: 10,
  });
  const [total, setTotal] = useState(0);
  const [searchValues, setSearchValues] = useState({});
  const [letterheadFileName, setLetterheadFileName] = useState("");
  const [showsModal, setShowsModal] = useState(false);
  const [letterheads, setLetterheads] = useState([]);
  const [selectedData, setselectedData] = useState(null);
  const [companies, setCompanies] = useState([]);

  const componentRef = useRef();

  const styles = {
    toolbar: {
      marginBottom: 24,
    },
    rightToolbar: {
      textAlign: "right",
    },
    pageHeader: {
      padding: 0,
      paddingBottom: 24,
    },
    label: {
      display: "block",
      marginBottom: 3,
      marginTop: 6,
    },
    previewedImage: {
      width: "100%",
    },
    select: {
      width: "100%",
    },
    datePicker: {
      width: "100%",
    },

  };

  const fetchData = async (params = null) => {
    try {
      setLoading(true);

      const queryObject = {
        populate: "*",
      };

      if (!_.isEmpty(params)) {
        queryObject["filters"] = params;
      }

      const keyword = searchValues?.keyword?.toLowerCase();

      const response = await axios.get(
        `${config?.slugs?.plural}?${qs.stringify(queryObject)}`
      );

      const filteredData = response?.data?.data?.filter((item) => {
        const clientName = item?.attributes?.client?.[0]?.client_name?.toLowerCase();
        return clientName?.includes(keyword);
      });

      console.log('keyword', searchValues, filteredData)

      if (response?.data && searchValues?.keyword) {

        setData(
          filteredData?.map((item) => ({
            id: item?.id,
            name: `${item?.attributes?.client?.[0]?.client_name}`,
            total_taxable_amount: item?.attributes?.item?.reduce((acc, item) => acc + item.taxable_amount, 0),
            total_amount: item?.attributes?.item?.reduce((acc, item) => acc + item.total, 0),
            gst: item?.attributes?.item?.reduce((acc, item) => acc + item.tax_amount, 0),
            date: moment(item?.attributes?.createdAt).format("DD-MM-YYYY"),
            delivery_date: moment(item?.attributes?.client?.[0]?.po_date).format("DD-MM-YYYY"),
            ...item?.attributes,
          }))
        )

        setTotal(response?.data?.meta?.pagination?.total);

        setPagination({
          ...response?.data?.meta?.pagination,
          pageSize: 10,
        });


      } else {
        setData(
          response?.data?.data?.map((item) => ({
            id: item?.id,
            name: `${item?.attributes?.client?.[0]?.client_name}`,
            total_taxable_amount: item?.attributes?.item?.reduce((acc, item) => acc + item.taxable_amount, 0),
            total_amount: item?.attributes?.item?.reduce((acc, item) => acc + item.total, 0),
            gst: item?.attributes?.item?.reduce((acc, item) => acc + item.tax_amount, 0),
            date: moment(item?.attributes?.createdAt).format("DD-MM-YYYY"),
            delivery_date: moment(item?.attributes?.client?.[0]?.po_date).format("DD-MM-YYYY"),
            ...item?.attributes,
          }))
        );

        setTotal(response?.data?.meta?.pagination?.total);

        setPagination({
          ...response?.data?.meta?.pagination,
          pageSize: 10,
        });

      }

    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const onSearch = () => {
    let filters = [];

    // Conditionally add date filter if start_date exists
    if (searchValues?.start_date) {
      filters.push({
        createdAt: {
          $gte: moment(searchValues?.start_date).format(DATE_TIME_FORMAT),
          $lte: searchValues?.end_date
            ? moment(searchValues?.end_date).format(DATE_TIME_FORMAT)
            : undefined,
        },
      });
    }

    fetchData({
      $and: filters,
    });
  };


  const refreshData = () => {
    onSearch();
  };
  const onReset = () => {
    setSearchValues({});
    fetchData(null);
  };

  const getColumnFilterProps = ({ dataIndex, references = {} }) => {
    if (typeof references?.fieldName === "function") {
      const transformer = references?.fieldName;

      return {
        render: (data) => transformer(data?.data?.attributes) || "N/A",
      };
    } else {
      return {
        render: (data) =>
          data?.data?.attributes?.[references?.fieldName] || "N/A",
      };
    }
  };

  const getColumnProps = (column) => {
    if (column?.references) {
      return getColumnFilterProps(column);
    }

    return column;
  };

  const fetchLetterheads = async () => {
    try {
      const resp = await axios.get(`/letterheads?populate=*`);

      if (resp?.data?.data?.length > 0) {
        const results = resp?.data?.data?.map((record, index) => {
          let fileName =
            record?.attributes?.image_file?.data?.attributes?.url?.split(
              "/"
            )[2] || "";

          if (index === 0) {
            setLetterheadFileName(fileName);
          }

          return {
            id: record?.id,
            name: record?.attributes?.name,
            file_name: fileName,
          };
        });

        setLetterheads(results);
      }
    } catch (error) { }
  };


  function handlePrint() {
    setTimeout(() => {
      var element = componentRef?.current?.innerHTML;

      var opt = {
        margin: [0.03, 0, 0.3, 0],
        filename: "quotation_" + selectedData?.id + ".pdf",
        image: { type: "jpeg", quality: 0.5 },
        pagebreak: { avoid: "tr", mode: "css", before: "#nextpage1" },
        html2canvas: {
          scale: 5,
          useCORS: true,
        },
        jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
        compressImage: false,
      };

      import("html2pdf.js").then((html2pdf) => {
        /* html2pdf().set(opt).from(element).save(); */
        html2pdf.default().set(opt).from(element).save();
      });
    }, 10);
  }

  useEffect(() => {
    fetchLetterheads();
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  console.log("selected Data", data, companies);

  return (
    <>
      <ReportDataTable
        data={data}
        config={{
          ...config,
          columns: config?.columns
            ?.map((column) => ({
              ...column,
              ...getColumnProps(column),
            }))

        }}
        loading={loading}
        pagination={pagination}
        actions={{
          onAdd: () => navigate(`/quick-stocks/${config?.slugs?.plural}/add`),
          onRefresh: refreshData,
        }}
        Toolbar={
          <div className="toolbar">
            <Row gutter={16}>
              <Col span={10}>
                <Input
                  value={searchValues?.keyword}
                  placeholder="Search for keyword..."
                  onChange={(e) =>
                    setSearchValues((prev) => ({
                      ...prev,
                      keyword: e.target.value,
                    }))
                  }
                />
              </Col>
              <Col span={7}>
                <DatePicker
                  onChange={(value) =>
                    setSearchValues((prev) => ({
                      ...prev,
                      start_date: value,
                    }))
                  }
                  value={searchValues?.start_date}
                  placeholder="Select start date"
                  style={styles.datePicker}
                />
              </Col>

              <Col span={7}>
                <DatePicker
                  onChange={(value) =>
                    setSearchValues((prev) => ({
                      ...prev,
                      end_date: value,
                    }))
                  }
                  value={searchValues?.endDate}
                  placeholder="Select end date"
                  style={styles.datePicker}
                />
              </Col>
            </Row>

            <div className="toolbar-buttons">
              <p className="toolbar-results">{' '}</p>

              <Space>
                <Button onClick={onReset}>Reset</Button>
                <Button type="primary" onClick={onSearch}>
                  Search
                </Button>
              </Space>
            </div>
          </div>
        }
      />
    </>
  );
};

export default QuotationReport;
