import React, { useEffect } from "react";
import { PageHeader, Breadcrumb, Row, Col, Form, Space, Button } from "antd";
import FormElement from "../FormElement";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const ViewForm = ({
  loading = false,
  config,
  data = {},
  values = {},
  disabled = false,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(data);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <Helmet>
        <title>{config?.pageTitle}</title>
      </Helmet>

      <Breadcrumb style={styles.breadcrumbs}>
        {config?.breadcrumbs?.map((breadcrumb, index) => (
          <Breadcrumb.Item key={index}>
            <Link to={breadcrumb?.path}>{breadcrumb?.title}</Link>
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>

      <PageHeader
        title={config?.title}
        style={styles.pageHeader}
        onBack={config?.actions?.onBack}
      ></PageHeader>

      <Form
        form={form}
        name={config?.id}
        layout="vertical"
        initialValues={data}
        style={styles.form}
        scrollToFirstError
        disabled={disabled ? disabled : loading}
      >
        <Row gutter={24}>
          {config?.components.map((component) => (
            <Col span={component?.span} key={component?.name}>
              <FormElement
                {...component}
                values={values}
                form={form}
                disabled={true}
                data={data}
              />
            </Col>
          ))}
        </Row>
      </Form>

      <Space>
        <Button onClick={config?.actions?.onBack}>Cancel</Button>
      </Space>
    </>
  );
};

const styles = {
  pageHeader: {
    padding: 0,
    paddingBottom: 24,
  },
  form: {
    width: "100%",
  },
};

export default ViewForm;
