export const makeConfig = () => ({
  id: `quick-talks-dashboard`,
  title: `Dashboard`,
  pageTitle: `Quick Talks Dashboard`,
  breadcrumbs: [
    {
      path: "/quick-talks/dashboard",
      title: "Home",
    },
    {
      path: "",
      title: "Dashboard",
    },
  ],
});
