import React from "react";
import { Route, Navigate } from "react-router-dom";
import NotFound from "../../../pages/NotFound";
import AccountManagement from "../index";

import * as ChangePassword from "./change-password.route";
import * as Profile from "./profile.route";
import * as EmployeeDocuments from "./employee-documents.route";
import * as EmployeeQualifications from "./employee-qualifications.route";
import * as Notifications from "./notifications.route";

export const register = (props) => {
  const basePath = "/account";

  return (
    <Route path={basePath} element={<AccountManagement />}>
      {ChangePassword.register()}
      {Profile.register()}
      {EmployeeDocuments.register()}
      {EmployeeQualifications.register()}
      {Notifications.register()}

      <Route
        path={basePath}
        element={<Navigate to={`${basePath}/profile`} replace />}
      />
      <Route path="*" element={<NotFound returnPath={basePath} />} />
    </Route>
  );
};
