import React, { useEffect, useState } from "react";
import { AddForm } from "../../../components";
import { useNavigate, useParams } from "react-router-dom";
import { makeConfig } from "./config";
import { axios } from "../../../../../App";
import { fetchData } from "../../../../../utils/helpers/data.helper";
import qs from "qs";
import { v4 } from "uuid";
import moment from "moment";

const EditQuotations = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    urgency_level: ["Low", "Medium", "High"]?.map((item) => ({
      value: item,
      label: item,
    })),
    approved_by: []
  });
  const config = makeConfig({ navigate });
  const [paymentSchedules, setPaymentSchedules] = useState([]);
  const [terms, setTerms] = useState([]);
  const [item, setitem] = useState([]);
  const [client, setclient] = useState([]);
  const [poAddress, setpoAddress] = useState([]);
  const [shippings, setShippings] = useState([]);


  const contentTypes = [
    {
      slug: "material-groups",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          material_group: data,
        })),
    },
    {
      slug: "material-items",
      query: {
        populate: "*"
      },
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
        material_image: item?.material_image
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          material_item: data,
        })),
    },
    {
      slug: "material-units",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          material_unit: data,
        })),
    },
    {
      slug: "vendors",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          vendor: data,
          approved_by: data,
        })),
    },

    {
      slug: "companies",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          po_from: data,
        })),
    },
    {
      slug: "banks",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          bank: data,
        })),
    },
  ];

  // MATERIAL ITEMS

  const transformMaterialItem = (rawData) => {
    return {
      ...rawData,
      material_group: values?.material_group?.find(
        (item) => item?.value === rawData?.material_group
      )?.label,
      material_item: values?.material_item?.find(
        (item) => item?.value === rawData?.material_item
      )?.label,
      material_unit: values?.material_unit?.find(
        (item) => item?.value === rawData?.material_unit
      )?.label,
      raw_data: rawData,
    };
  };

  // PAYMENT SCHEDULES

  const transformPaymentSchedule = (rawData) => {
    return {
      ...rawData,
      material_item: values?.material_item?.find(
        (item) => item?.value === rawData?.material_item
      )?.label,
      material_image: values?.material_item?.find(
        (item) => item?.value === rawData?.material_item
      )?.material_image,
      raw_data: rawData,
    };
  };

  const onAddInvoice = (data) => {
    const newData = {
      id: v4(),
      ...data,
      ...transformPaymentSchedule(data),
    };

    setitem((prev) => prev?.concat(newData));
  };

  const onEditInvoice = (id, data) => {
    let newitem = item?.map((record) => {
      if (record?.id === id) {
        record = {
          ...data,
          ...transformPaymentSchedule(data),
        };
      }

      return record;
    });

    setitem(newitem);
  };

  const onDeleteInvoice = (id) => {
    setitem((prev) => prev?.filter((item) => item?.id !== id));
  };

  const onAddClient = (data) => {
    if (client?.length > 0) {
      alert('Only one client can be added.')
      return;
    }

    const newData = {
      id: v4(),
      ...data,
      ...transformPaymentSchedule(data),
    };

    setclient((prev) => prev?.concat(newData));
  };

  const onEditClient = (id, data) => {
    let newitem = client?.map((record) => {
      if (record?.id === id) {
        record = {
          ...data,
          ...transformPaymentSchedule(data),
        };
      }

      return record;
    });

    setclient(newitem);
  };

  const onDeleteClient = (id) => {
    setclient((prev) => prev?.filter((item) => item?.id !== id));
  };

  const onAddAddress = (data) => {
    const newData = {
      id: v4(),
      ...data,
      ...transformPaymentSchedule(data),
    };

    setpoAddress((prev) => prev?.concat(newData));
  };

  const onDeleteAddress = (id) => {
    setpoAddress((prev) => prev?.filter((item) => item?.id !== id));
  };

  const onAddPaymentSchedule = (data) => {
    const newData = {
      id: v4(),
      ...data,
      ...transformPaymentSchedule(data),
    };

    setPaymentSchedules((prev) => prev?.concat(newData));
  };

  const onEditPaymentSchedule = (id, data) => {
    let newItems = paymentSchedules?.map((record) => {
      if (record?.id === id) {
        record = {
          ...data,
          ...transformPaymentSchedule(data),
        };
      }

      return record;
    });

    setPaymentSchedules(newItems);
  };

  const onDeletePaymentSchedule = (id) => {
    setPaymentSchedules((prev) => prev?.filter((item) => item?.id !== id));
  };

  // TERMS

  const transformTerm = (rawData) => {
    return {
      ...rawData,
      material_group: values?.material_group?.find(
        (item) => item?.value === rawData?.material_group
      )?.label,
      material_item: values?.material_item?.find(
        (item) => item?.value === rawData?.material_item
      )?.label,
      material_unit: values?.material_unit?.find(
        (item) => item?.value === rawData?.material_unit
      )?.label,
      raw_data: rawData,
    };
  };

  const onAddTerm = (data) => {
    const newData = {
      id: v4(),
      ...data,
      ...transformTerm(data),
    };

    setTerms((prev) => prev?.concat(newData));
  };

  const onEditTerm = (id, data) => {
    let newItems = terms?.map((record) => {
      if (record?.id === id) {
        record = {
          ...data,
          ...transformTerm(data),
        };
      }

      return record;
    });

    setTerms(newItems);
  };

  const onDeleteTerm = (id) => {
    setTerms((prev) => prev?.filter((item) => item?.id !== id));
  };

  const onAddShipping = (data) => {
    if (shippings?.length > 0) {
      alert('Only one Shipping Address can be added.')
      return;
    }
    const newData = {
      id: v4(),
      ...data,
      ...transformPaymentSchedule(data),
    };

    setShippings((prev) => prev?.concat(newData));
  };

  const onEditShipping = (id, data) => {
    let newitem = shippings?.map((record) => {
      if (record?.id === id) {
        record = {
          ...data,
          ...transformPaymentSchedule(data),
        };
      }

      return record;
    });

    setShippings(newitem);
  };

  const onDeleteShipping = (id) => {
    setShippings((prev) => prev?.filter((item) => item?.id !== id));
  };

  useEffect(() => {
    contentTypes?.forEach((contentType) => {
      fetchData(contentType);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const queryObject = {
          populate: "*",
        };

        const response = await axios.get(
          `/quotations/${id}?${qs.stringify(queryObject)}`
        );

        if (response?.data) {

          setitem(response?.data?.data?.attributes?.item || [])

          const updatedClients = response?.data?.data?.attributes?.client.map(client => ({
            ...client,
            po_date: moment(client.po_date), // Update top-level po_date
            raw_data: {
              ...client.raw_data,
              po_date: moment(client.raw_data?.po_date) // Update po_date inside raw_data
            }
          }));

          setclient(updatedClients);

          setShippings(response?.data?.data?.attributes?.shipped_to)

          setpoAddress(response?.data?.data?.attributes?.po_address || [])

          setPaymentSchedules(
            response?.data?.data?.attributes?.payment_schedule || []
          );
          setTerms(response?.data?.data?.attributes?.term || []);

          setData({
            ...response?.data?.data?.attributes,
            id: response?.data?.data?.id,
            po_from: response?.data?.data?.attributes?.po_from?.data?.id,
            payment_date: moment(response?.data?.data?.payment_date),
            bank: response?.data?.data?.attributes?.bank?.data?.id,
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  console.log('confed', config, data)

  return (
    <AddForm
      config={config}
      data={{
        ...data,
        item: item,
        client: client,
        po_address: poAddress,
        payment_schedule: paymentSchedules,
        shipped_to: shippings,
        term: terms,
      }}
      loading={loading}
      values={values}
      modals={{
        item: {
          data: item,
          methods: {
            add: onAddInvoice,
            delete: onDeleteInvoice,
            edit: onEditInvoice,
          },
        },
        client: {
          data: client,
          methods: {
            add: onAddClient,
            delete: onDeleteClient,
            edit: onEditClient,
          },
        },
        po_address: {
          data: poAddress,
          methods: {
            add: onAddAddress,
            delete: onDeleteAddress,
            // edit: onEditInvoice,
          },
        },
        payment_schedule: {
          data: paymentSchedules,
          methods: {
            add: onAddPaymentSchedule,
            edit: onEditPaymentSchedule,
            delete: onDeletePaymentSchedule,
          },
        },
        term: {
          data: terms,
          methods: {
            add: onAddTerm,
            edit: onEditTerm,
            delete: onDeleteTerm,
          },
        },
        shipped_to: {
          data: shippings,
          methods: {
            add: onAddShipping,
            delete: onDeleteShipping,
            edit: onEditShipping,
          },
        },
      }}
    />
  );
};

export default EditQuotations;
