export default {
  slugs: {
    singular: "task-manpower",
    plural: "task-manpowers",
  },
  entityNames: {
    singular: "Task Manpowers",
    plural: "Task Manpowers",
  },
};
