import React from "react";
import { Route } from "react-router-dom";
import { ToolTypeList, ToolTypeAdd, ToolTypeEdit } from "../pages/ToolTypes";

export const register = (props) => {
  const basePath = "tool-types";

  return (
    <>
      <Route path={basePath} element={<ToolTypeList />} exact={true} />
      <Route path={`${basePath}/add`} element={<ToolTypeAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<ToolTypeEdit />} />
    </>
  );
};
