export default {
  slugs: {
    singular: "contact",
    plural: "contacts",
  },
  entityNames: {
    singular: "Contact",
    plural: "Contacts",
  },
};
