import React, { useEffect, useState } from "react";
import { ViewForm } from "../../../components";
import { useNavigate, useParams } from "react-router-dom";
import { makeConfig } from "./config";
import { axios } from "../../../../../App";
import { fetchData } from "../../../../../utils/helpers/data.helper";
import qs from "qs";
import moment from "moment";

const Page = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    status: ["Pending", "Rejected", "Approved"]?.map((item) => ({
      value: item,
      label: item,
    })),
    all_task_material: [],
    all_task: [],
  });
  const config = makeConfig({ navigate });
  const contentTypes = [
    {
      slug: "employees",
      transformer: (item) => ({
        value: item?.id,
        label: `${item?.first_name} ${item?.last_name}`,
        uid: item?.uid,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          employee: data,
        //  approved_by: data,
        })),
    },
     {
      slug: "employees/approvers",
      transformer: (item) => ({
        value: item?.id,
        label: `${item?.first_name} ${item?.last_name}`,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
         approved_by: data,
        })),
    },
    {
      slug: "projects",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          project: data,
        })),
    },
    {
      slug: "tasks",
      query: {
        populate: "*",
      },
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          all_task: data,
        })),
    },
    {
      slug: "task-materials",
      query: {
        populate: "*",
      },
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          all_task_material: data,
        })),
    },
  ];

  useEffect(() => {
    contentTypes?.forEach((contentType) => {
      fetchData(contentType);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const queryObject = {
          populate: "*",
        };

        const response = await axios.get(
          `/${config?.slugs?.plural}/${id}?${qs.stringify(queryObject)}`
        );

        if (response?.data) {
          const projectId = Number(response?.data?.data?.attributes?.project);
          const taskId = Number(response?.data?.data?.attributes?.task);

          if (projectId) {
            setValues((prev) => ({
              ...prev,
              task: values?.all_task
                ?.filter(
                  (item) => item?.project?.data?.id === Number(projectId)
                )
                ?.map((item) => ({
                  value: item?.id,
                  label: item?.name,
                })),
            }));
          }

          if (taskId) {
            setValues((prev) => ({
              ...prev,

              task_material: values?.all_task_material
                ?.filter((item) => item?.task?.data?.id === Number(taskId))
                ?.map((item) => ({
                  value: item?.id,
                  label: item?.material_item?.data?.attributes?.name,
                })),
            }));
          }

          setData({
            ...response?.data?.data?.attributes,
            id: response?.data?.data?.id,
            date: moment(response?.data?.data?.attributes?.createdAt)?.format(
              "DD-MM-YYYY"
            ),
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, values?.all_task, values?.all_task_material]);

  return (
    <ViewForm config={config} data={data} loading={loading} values={values} />
  );
};

export default Page;
