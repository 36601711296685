import React from "react";
import { Route } from "react-router-dom";
import {
  EventTemplateList,
  EventTemplateAdd,
  EventTemplateEdit,
} from "../pages/EventTemplates";

export const register = (props) => {
  const basePath = "event-templates";

  return (
    <>
      <Route path={basePath} element={<EventTemplateList />} exact={true} />
      <Route path={`${basePath}/add`} element={<EventTemplateAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<EventTemplateEdit />} />
    </>
  );
};
