import React from "react";
import { Route } from "react-router-dom";
import {
  ReceivedInvoiceList,
  ReceivedInvoiceAdd,
  ReceivedInvoiceEdit,
  ReceivedInvoiceReport,
} from "../pages/ReceivedInvoices";

export const register = (props) => {
  const basePath = "received-invoices";

  return (
    <>
      <Route path={basePath} element={<ReceivedInvoiceList />} exact={true} />
      <Route path={`${basePath}/add`} element={<ReceivedInvoiceAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<ReceivedInvoiceEdit />} />
      <Route path={`${basePath}-report`} element={<ReceivedInvoiceReport />} />
    </>
  );
};
