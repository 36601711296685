import React, { useState, useEffect } from "react";
import { DataTable } from "../../../components";
import { useNavigate } from "react-router-dom";
import { Space, Button, Popconfirm, Tooltip, notification, Input } from "antd";
import {
  PlusOutlined,
  DeleteOutlined,
  EditOutlined,
  ReloadOutlined,
  LockOutlined,
  UserOutlined,
} from "@ant-design/icons";
import qs from "qs";
import config from "./config";
import { axios } from "../../../../../App";
import _ from "lodash";
import md5 from "md5";
import { useSelector } from "react-redux";
import { MAX_USERS } from "../../../../../constants";

const Page = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state?.auth);
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    pageSize: 10,
  });
  const [total, setTotal] = useState(0);
  const [searchValues, setSearchValues] = useState({});
  const [showAddButton, setShowAddButton] = useState(true);

  // const fetchSettings = async () => {
  //   try {
  //     const response = await originalAxios.get(
  //       `/app-setting`,
  //     );
  //     if (response?.data) {
  //       console.log("response", response?.data);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const fetchData = async (params = {}) => {
    try {
      setLoading(true);

      const queryObject = {
        populate: "*",
        sort: ["username:asc"],
      };

      if (!_.isEmpty(params)) {
        queryObject["filters"] = params;
      }

      const response = await axios.get(
        `${config?.slugs?.plural}?${qs.stringify(queryObject)}`
      );

      if (response?.data) {
        setData(
          response?.data?.data?.map((item) => ({
            id: item?.id,
            ...item?.attributes,
          }))
        );

        if (response?.data?.meta?.pagination?.total >= MAX_USERS) {
          setShowAddButton(false);
        }

        setTotal(response?.data?.meta?.pagination?.total);

        setPagination({
          ...response?.data?.meta?.pagination,
          pageSize: 10,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const refreshData = () => {
    onSearch();
  };

  const resetPassword = async (id) => {
    try {
      const response = await axios.put(`/${config?.slugs?.plural}/${id}`, {
        data: {
          password: md5("quickso@123456"),
        },
      });

      if (response?.data) {
        notification["success"]({
          message: `Reset Default Password`,
          description: `Reset default password for ${config?.entityNames?.singular?.toLowerCase()} successfully!`,
        });

        refreshData();
      } else {
        throw new Error();
      }
    } catch (error) {
      notification["error"]({
        message: `Reset Default Password`,
        description: `Reset default password for ${config?.entityNames?.singular} failed!`,
      });
    }
  };

  const deleteItem = async (id) => {
    try {
      const response = await axios.delete(`${config?.slugs?.plural}/${id}`);

      if (response?.data) {
        notification["success"]({
          message: `Remove ${config?.entityNames?.singular}`,
          description: `Remove ${config?.entityNames?.singular} successfully!`,
        });

        refreshData();
      } else {
        throw new Error();
      }
    } catch (error) {
      notification["error"]({
        message: `Remove ${config?.entityNames?.singular}`,
        description: `Remove ${config?.entityNames?.singular} failed!`,
      });
    }
  };

  const getColumnFilterProps = ({ dataIndex, references = {} }) => {
    if (typeof references?.fieldName === "function") {
      const transformer = references?.fieldName;

      return {
        render: (data) => transformer(data?.data?.attributes) || "N/A",
      };
    } else {
      return {
        render: (data) =>
          data?.data?.attributes?.[references?.fieldName] || "N/A",
      };
    }
  };

  const getColumnProps = (column) => {
    if (column?.references) {
      return getColumnFilterProps(column);
    }

    return column;
  };

  const onSearch = () => {
    fetchData({
      $and: [
        {
          $or: [
            {
              username: {
                $containsi: searchValues?.keyword?.toLowerCase(),
              },
            },
          ],
        },
        {
          id: {
            $notIn: [user?.id],
          },
        },
      ],
    });
  };

  const onReset = () => {
    setSearchValues({});
    fetchData({
      id: {
        $notIn: [user?.id],
      },
    });
  };

  const getResultsMessage = () => {
    if (!_.isEmpty(searchValues)) {
      return total > 0 ? (
        <span>
          <strong>{total}</strong> matched results
        </span>
      ) : (
        <span>No matched results</span>
      );
    }

    return (
      <span>
        <strong>{total}</strong> total records
      </span>
    );
  };

  const onEditProfile = ({ id, userType }) => {
    let prefix = "";
    let slug = "";

    // eslint-disable-next-line default-case
    switch (userType) {
      case "Employee":
        prefix = "quick-setup";
        slug = "employees";
        break;

      case "Partner":
        prefix = "quick-setup";
        slug = "partners";
        break;

      case "Agency":
        prefix = "quick-stocks";
        slug = "agencies";
        break;

      case "Sub-contractor":
        prefix = "quick-stocks";
        slug = "sub-contractors";
        break;

      case "Vendor":
        prefix = "quick-stocks";
        slug = "vendors";
        break;
    }

    navigate(`/${prefix}/${slug}/edit/${id}`);
  };

  const onAdd = () => navigate(`/quick-setup/${config?.slugs?.plural}/add`);

  const renderButtons = () => {
    let buttons = [];

    if (showAddButton) {
      buttons.push(
        <Tooltip placement="top" title="Add new item">
          <Button icon={<PlusOutlined />} onClick={onAdd} />
        </Tooltip>
      );
    }

    buttons.push(
      <Tooltip placement="top" title="Refresh">
        <Button
          icon={<ReloadOutlined />}
          onClick={refreshData}
          loading={loading}
        />
      </Tooltip>
    );

    return buttons;
  };

  useEffect(() => {
    fetchData({
      id: {
        $notIn: [user?.id],
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log(MAX_USERS)

  return (
    <DataTable
      data={data}
      config={{
        ...config,
        columns: config?.columns
          ?.map((column) => ({
            ...column,
            ...getColumnProps(column),
          }))
          ?.concat({
            title: "Actions",
            width: 100,
            selectable: false,
            render: ({ id, user_type }) => {
              return (
                <Space>
                  <Tooltip placement="bottom" title={`Edit Profile`}>
                    <Button
                      icon={<UserOutlined />}
                      onClick={() => onEditProfile({ id, userType: user_type })}
                    />
                  </Tooltip>

                  <Tooltip
                    placement="bottom"
                    title={`Edit ${config?.entityNames?.singular}`}
                  >
                    <Button
                      icon={<EditOutlined />}
                      onClick={() =>
                        navigate(
                          `/quick-setup/${config?.slugs?.plural}/edit/${id}`
                        )
                      }
                    />
                  </Tooltip>

                  {/* <Tooltip placement="bottom" title={`Change Password`}>
                    <Button
                      icon={<LockOutlined />}
                      onClick={() =>
                        navigate(
                          `/quick-setup/${config?.slugs?.plural}/change-password/${id}`
                        )
                      }
                    />
                  </Tooltip> */}

                  {/* <Popconfirm
                    title={`Are you sure to reset default password for this ${config?.entityNames?.singular?.toLowerCase()}?`}
                    okText="Yes"
                    cancelText="No"
                    onConfirm={() => resetPassword(id)}
                  >
                    <Tooltip
                      placement="bottom"
                      title={`Reset Default Password`}
                    >
                      <Button type="danger" icon={<ReloadOutlined />} />
                    </Tooltip>
                  </Popconfirm> */}

                  <Popconfirm
                    title={`Are you sure to remove this ${config?.entityNames?.singular?.toLowerCase()}?`}
                    okText="Yes"
                    cancelText="No"
                    onConfirm={() => deleteItem(id)}
                  >
                    <Tooltip
                      placement="bottom"
                      title={`Remove ${config?.entityNames?.singular}`}
                    >
                      <Button type="danger" icon={<DeleteOutlined />} />
                    </Tooltip>
                  </Popconfirm>
                </Space>
              );
            },
          }),
      }}
      loading={loading}
      pagination={pagination}
      actions={{
        onAdd: () => navigate(`/quick-setup/${config?.slugs?.plural}/add`),
        onRefresh: refreshData,
      }}
      Toolbar={
        <div className="toolbar">
          <Input
            value={searchValues?.keyword}
            placeholder="Search for keyword..."
            onChange={(e) =>
              setSearchValues((prev) => ({
                ...prev,
                keyword: e.target.value,
              }))
            }
          />

          <div className="toolbar-buttons">
            <p className="toolbar-results">{getResultsMessage()}</p>

            <Space>
              <Button onClick={onReset}>Reset</Button>
              <Button type="primary" onClick={onSearch}>
                Search
              </Button>
            </Space>
          </div>
        </div>
      }
      buttons={renderButtons()}
    />
  );
};

export default Page;
