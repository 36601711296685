import React from "react";
import { Route } from "react-router-dom";
import {
  StockLedgerList,
  StockLedgerAdd,
  StockLedgerEdit,
  StockLedgerIncrease,
  StockLedgerReport,
  StockLedgerView,
} from "../pages/StockLedgers";

export const register = (props) => {
  const basePath = "stock-ledgers";

  return (
    <>
      <Route path={basePath} element={<StockLedgerList />} exact={true} />
      <Route path={`${basePath}/add`} element={<StockLedgerAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<StockLedgerEdit />} />
      <Route path={`${basePath}/view/:id`} element={<StockLedgerView />} />
      {/* <Route
        path={`${basePath}/increase/:id`}
        element={<StockLedgerIncrease />}
      /> */}
      <Route path={`${basePath}-report`} element={<StockLedgerReport />} />
    </>
  );
};
