import config from "../config";
import moment from "moment";
import { Tag } from "antd";

export default {
  id: `${config?.slugs?.plural}-list`,
  pageTitle: config?.entityNames?.plural,
  breadcrumbs: [
    {
      path: "/quick-projects/dashboard",
      title: "Home",
    },
    {
      path: "",
      title: config?.entityNames?.plural,
    },
  ],
  columns: [
    // {
    //   title: "Manpower",
    //   dataIndex: "manpower",
    //   selectable: true,
    //   filterable: true,
    //   references: {
    //     fieldName: (record) =>
    //       `${record?.employee?.data?.attributes?.first_name} ${record?.employee?.data?.attributes?.last_name}`,
    //   },
    // },
    {
      title: "Manpower",
      dataIndex: "manpower",
      selectable: true,
      references: {
        fieldName: "labour",
      },
    },
    {
      title: "Date",
      dataIndex: "date",
      selectable: true,
      sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
      render: (text) => <span>{moment(text).format("DD-MM-YYYY")}</span>,
    },
    {
      title: "Status",
      dataIndex: "status",
      selectable: true,
      render: (text) => <Tag>{text}</Tag>,
    },
  ],
  ...config,
};
