export default {
  slugs: {
    singular: "vendor-payment-schedule",
    plural: "vendor-payment-schedules",
  },
  entityNames: {
    singular: "Vendor Payment Schedule",
    plural: "Vendor Payment Schedules",
  },
};
