import React from "react";
import { Route } from "react-router-dom";
import { InitialList, InitialAdd, InitialEdit } from "../pages/Initials";

export const register = (props) => {
  const basePath = "initials";

  return (
    <>
      <Route path={basePath} element={<InitialList />} exact={true} />
      <Route path={`${basePath}/add`} element={<InitialAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<InitialEdit />} />
    </>
  );
};
