import React from "react";
import { Route } from "react-router-dom";
import QuotationList from "../pages/Quotations/List";
import AddQuotation from "../pages/Quotations/Add";
import EditQuotations from "../pages/Quotations/Edit";
import QuotationReport from "../pages/Quotations/Report";


export const register = (props) => {
  const basePath = "quotations";

  return (
    <>
      <Route path={basePath} element={<QuotationList />} exact={true} />
      <Route path={`${basePath}/add`} element={<AddQuotation />} exact={true} />
      <Route path={`${basePath}/edit/:id`} element={<EditQuotations />} exact={true} />
      <Route path={`${basePath}-report`} element={<QuotationReport />} exact={true} />
    </>
  );
};
