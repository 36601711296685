import { axios } from "../../../../../App";
import config from "../config";
import qs from "qs";

export const makeConfig = ({ navigate }) => ({
  id: `${config?.plural}-edit`,
  title: `Edit ${config?.entityNames?.singular} (For Creator)`,
  pageTitle: `Edit ${config?.entityNames?.singular} (For Creator)`,
  breadcrumbs: [
    {
      path: "/quick-projects/dashboard",
      title: "Home",
    },
    {
      path: `/quick-projects/${config?.slugs?.plural}`,
      title: config?.entityNames?.plural,
    },
    {
      path: "",
      title: `Edit ${config?.entityNames?.singular}`,
    },
  ],
  components: [
    {
      span: 24,
      component: "select",
      label: "Task",
      name: "task",
      placeholder: "Select task",
      required: true,
      rules: [
        {
          required: true,
          message: "Task is required!",
        },
      ],
      // disabled: true,
    },
    {
      span: 12,
      component: "select",
      label: "Work Group",
      name: "work_group",
      placeholder: "Select work group",
      required: true,
      rules: [
        {
          required: true,
          message: "Work group is required!",
        },
      ],
      // disabled: true,
    },
    {
      span: 12,
      component: "select",
      label: "Work Item",
      name: "work_item",
      placeholder: "Select work item",
      required: true,
      rules: [
        {
          required: true,
          message: "Work item is required!",
        },
      ],
      // disabled: true,
    },
    // {
    //   span: 12,
    //   component: "select",
    //   label: "Work Unit",
    //   name: "work_unit",
    //   placeholder: "Select work unit",
    //   required: true,
    //   rules: [
    //     {
    //       required: true,
    //       message: "Work unit is required!",
    //     },
    //   ],
    //   // disabled: true,
    // },
    {
      span: 12,
      component: "number",
      label: "Quantity",
      name: "quantity",
      required: true,
      placeholder: "Enter quantity",
      rules: [
        {
          required: true,
          message: "Quantity is required!",
        },
      ],
      min: 0,
      // disabled: true,
    },
    {
      span: 12,
      component: "number",
      label: "Amount",
      name: "amount",
      required: true,
      placeholder: "Enter amount",
      rules: [
        {
          required: true,
          message: "Amount is required!",
        },
      ],
      min: 0,
      // disabled: true,
    },
    {
      span: 12,
      component: "select",
      label: "Approved By",
      name: "approved_by",
      placeholder: "Select employee",
      required: true,
      rules: [
        {
          required: true,
          message: "Employee is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Employee",
      name: "employee",
      placeholder: "Select employee",
      required: true,
      rules: [
        {
          required: true,
          message: "Employee is required!",
        },
      ],
      disabled: true,
    },
    {
      span: 12,
      component: "select",
      label: "Status",
      name: "status",
      placeholder: "Select status",
      required: true,
      rules: [
        {
          required: true,
          message: "Status is required!",
        },
      ],
      disabled: true,
    },
    {
      span: 24,
      component: "editor",
      label: "Description",
      name: "description",
      placeholder: "Enter description",
      rows: 5,
      // disabled: true,
    },
  ],
  actions: {
    onBack: () => navigate(`/quick-projects/${config?.slugs?.plural}`),
    onFinish: async (values, data) => {
      try {
        const updateEntry = async () => {
          const response = await axios.put(
            `/${config?.slugs?.plural}/${data?.id}`,
            {
              data: values,
            }
          );

          if (response?.data) {
            return {
              success: true,
              message: `Edit ${config?.entityNames?.singular?.toLowerCase()} successfully!`,
            };
          } else {
            throw new Error();
          }
        };

        const queryObject = {
          filters: {
            task: values?.task,
            work_group: values?.work_group,
            work_item: values?.work_item,
            work_unit: values?.work_unit,
            status: "Pending",
          },
        };

        const existingResp = await axios.get(
          `/${config?.slugs?.plural}?${qs.stringify(queryObject)}`
        );

        if (existingResp?.status === 200) {
          const total = existingResp?.data?.meta?.pagination?.total;

          if (total > 0) {
            const entryId = existingResp?.data?.data?.[0]?.id;

            if (entryId === data?.id) {
              return updateEntry();
            } else {
              return {
                success: false,
                message: `There is already an existing item with a similar task work.`,
              };
            }
          } else {
            return updateEntry();
          }
        } else {
          throw new Error();
        }
      } catch (error) {
        return {
          success: false,
          message: `Edit ${config?.entityNames?.singular?.toLowerCase()} failed!`,
        };
      }
    },
  },
  ...config,
});
