export default {
  slugs: {
    singular: "firm-type",
    plural: "firm-types",
  },
  entityNames: {
    singular: "Company Type",
    plural: "Company Types",
  },
};
