import React from "react";
import { Route } from "react-router-dom";
import EmployeeChats from "../pages/EmployeeChats";

export const register = (props) => {
  const basePath = "employee-chats";

  return (
    <>
      <Route path={basePath} element={<EmployeeChats />} exact={true} />
    </>
  );
};
