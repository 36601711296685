export default {
  slugs: {
    singular: "sub-contractor-term",
    plural: "sub-contractor-terms",
  },
  entityNames: {
    singular: "Sub Contractor Term & Condition",
    plural: "Sub Contractor Terms & Conditions",
  },
};
