import React from "react";
import { Table } from "antd";

const DataTable = ({ data, columns, title }) => {
  return (
    <div>
      <div style={styles.header}>
        <p style={styles.headerTitle}>{title}</p>
      </div>

      <div style={styles.body}>
        <Table style={{overflow:"auto"}} columns={columns} dataSource={data} pagination={false} />
      </div>
    </div>
  );
};

const styles = {
  header: {
    padding: " 10px 15px ",
    borderBottom: " 1px solid #cf6c58",
    background: "white",
  },
  headerTitle: {
    color: " #cf6c58 ",
    fontSize: " 1.2rem",
    margin: "0rem",
    fontWeight: "500",
  },
  body: {
    padding: "auto",
    background: "white",
    overflow: "auto !important"
  },
};

export default DataTable;
