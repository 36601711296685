import React, { useState, useEffect } from "react";
import { Row, Col, Form, Space, Button } from "antd";
import FormElement from "../FormElement";

const ModalAddForm = ({
  loading = false,
  config,
  data = {},
  values = {},
  disabled = false,
  onSubmit = () => {},
  onCancel = () => {},
  modals,
}) => {
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);

  const handleFinish = (values) => {
    setSubmitting(true);
    onSubmit(values);
    setSubmitting(false);
  };

  useEffect(() => {
    form.setFieldsValue(data);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Form
      form={form}
      name={config?.id}
      layout="vertical"
      onFinish={handleFinish}
      initialValues={data}
      style={styles.form}
      scrollToFirstError
      disabled={disabled ? disabled : loading}
    >
      <Row gutter={24}>
        {config?.components.map((component) => (
          <Col span={component?.span} key={component?.name}>
            <FormElement
              {...component}
              values={values}
              form={form}
              modals={modals}
            />
          </Col>
        ))}
      </Row>

      <Space style={styles.footer}>
        <Button onClick={onCancel}>Cancel</Button>

        <Button type="primary" htmlType="submit" loading={submitting}>
          Save
        </Button>
      </Space>
    </Form>
  );
};

const styles = {
  pageHeader: {
    padding: 0,
    paddingBottom: 24,
  },
  form: {
    width: "100%",
  },
  footer: {
    marginTop: 12,
  },
};

export default ModalAddForm;
