import React from "react";
import { Route } from "react-router-dom";
import EGChats from "../pages/EGChats";

export const register = (props) => {
  const basePath = "employee-group-chats";

  return (
    <>
      <Route path={basePath} element={<EGChats />} exact={true} />
    </>
  );
};
