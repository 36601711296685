export default {
  slugs: {
    singular: "agency-payment-schedule",
    plural: "agency-payment-schedules",
  },
  entityNames: {
    singular: "Agency Payment Schedule",
    plural: "Agency Payment Schedules",
  },
};
