import React, { useState, useEffect, useRef } from "react";
import {
  Col,
  Row,
  Divider,
  Button,
  Input,
  Avatar,
  Breadcrumb,
  Spin,
  Badge,
  Empty,
} from "antd";
import { axios } from "../../../../App";
import qs from "qs";
import moment from "moment";
import { SendOutlined, SmileOutlined } from "@ant-design/icons";
import _ from "lodash";
import io from "socket.io-client";
import $ from "jquery";
import { makeConfig } from "./config";
import { Helmet } from "react-helmet";
import { Link, useLocation } from "react-router-dom";
import Picker from "emoji-picker-react";
import { ImAttachment } from "react-icons/im";
import { BsFileEarmarkMedical } from "react-icons/bs";
import { GrDownload } from "react-icons/gr";
import { saveAs } from "file-saver";
import { Stack } from "@chakra-ui/react";

const { Search } = Input;

const Chats = (props) => {
  const config = makeConfig();
  const [conversations, setConversations] = useState([]);
  const [activeConversation, setActiveConversation] = useState({});
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingConversations, setLoadingConversations] = useState(false);
  const [showEmojis, setShowEmojis] = useState(false);
  const [hasMessages, setHasMessages] = useState(false);
  const [hasConversations, setHasConversations] = useState(false);
  const [isFocusing, setIsFocusing] = useState(false);
  const location = useLocation();

  const emojisRef = useRef();
  const fileInputRef = useRef();
  const socketRef = useRef();

  const fetchData = async (filters = null) => {
    try {
      setLoadingConversations(true);
      setHasConversations(false);

      const queryObject = {
        populate: ["members", "last_message"],
      };

      if (filters !== null) {
        queryObject["filters"] = filters;
      }

      const response = await axios.get(
        `/conversations?${qs.stringify(queryObject)}`
      );

      if (response?.data) {
        const newConversations = response?.data?.data?.map((item) => ({
          id: item?.id,
          ...item?.attributes,
          last_message: item?.attributes?.last_message?.data?.attributes,
        }));

        setConversations(newConversations);

        const queryString = location?.search?.substring(1);

        if (queryString !== "") {
          const queryObject = qs.parse(queryString);

          if (queryObject?.conversationId) {
            const conversation = newConversations?.find(
              (item) => item?.id === +queryObject?.conversationId
            );

            if (conversation) {
              setActiveConversation(conversation);
            } else {
              setHasConversations(false);
              return;
            }
          }
        } else {
          setActiveConversation(newConversations?.[0]);
        }

        if (newConversations?.length > 0) {
          setHasConversations(true);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingConversations(false);
    }
  };

  const onSubmit = () => {
    if (socketRef) {
      socketRef.current?.emit("send-message", {
        roomId: `${config?.conversationType}-${activeConversation?.id}`,
        message: {
          conversation: activeConversation?.id,
          content: message,
          type: "text",
        },
      });

      onNewMessage();
    }
  };

  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      onSubmit();
    }
  };

  const onSearch = () => {
    fetchData({
      $and: [
        {
          name: {
            $containsi: keyword,
          },
        },
        {
          type: config?.conversationType,
        },
      ],
    });
  };

  const onNewMessage = () => {
    scrollToBottom();
    onReset();
  };

  const onReset = () => {
    setMessage("");
  };

  const scrollToBottom = () => {
    $(".messages-container").animate({
      scrollTop: $(".messages-container")[0].scrollHeight,
    });
  };

  const onSelectEmoji = (e, emojiObject) => {
    setMessage((prev) => (prev += emojiObject.emoji));
  };

  const toggleEmojis = () => setShowEmojis((prev) => !prev);

  const onChangeFile = async (e) => {
    try {
      const file = e.target.files?.[0];

      let fileData = new FormData();
      fileData.append("files", file);

      const response = await axios.post("/upload", fileData);

      if (response?.data) {
        if (socketRef) {
          socketRef.current?.emit("send-message", {
            roomId: `${config?.conversationType}-${activeConversation?.id}`,
            message: {
              conversation: activeConversation?.id,
              content: `${process.env.REACT_APP_BASE_API_URL}${response?.data?.[0]?.url}`,
              type: "attachment",
              attachment: response?.data?.[0],
            },
          });

          fileInputRef.current.value = "";

          onNewMessage();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onDownloadAttachment = (data) => {
    const url = `${process.env.REACT_APP_BASE_API_URL}${data?.url}?updated_at=${data?.updatedAt}`;

    saveAs(url, data?.name);
  };

  useEffect(() => {
    socketRef.current = io(process.env.REACT_APP_BASE_API_URL);

    fetchData({
      type: config?.conversationType,
    });

    return () => {
      if (socketRef) {
        socketRef.current?.disconnect();
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (emojisRef.current && !emojisRef.current.contains(event.target)) {
        setShowEmojis(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [emojisRef]);

  useEffect(() => {
    if (socketRef) {
      socketRef.current?.on("socket-status", (status) =>
        console.log("Socket status", status)
      );

      socketRef.current?.on("new-message", (data) => {
        if (isFocusing) {
          socketRef.current?.emit("read", data?.message);
        }

        setMessages((prev) => prev.concat(data?.message));
      });
    }

    return () => {
      if (socketRef) {
        socketRef.current?.off("socket-status");
        socketRef.current?.off("new-message");
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketRef]);

  useEffect(() => {
    if (socketRef && activeConversation) {
      socketRef.current?.emit(
        "join",
        `${config?.conversationType}-${activeConversation?.id}`
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketRef, activeConversation]);

  useEffect(() => {
    const fetchMessages = async (conversationId) => {
      try {
        setLoading(true);
        setHasMessages(false);

        const queryObject = {
          populate: "*",
          filters: {
            conversation: conversationId,
          },
          sort: ["createdAt:desc"],
        };

        const response = await axios.get(
          `/chat-messages?${qs.stringify(queryObject)}`
        );

        if (response?.data) {
          const newMessages = response?.data?.data?.map((item) => ({
            id: item?.id,
            ...item?.attributes,
            sender: `${item?.data?.attributes?.first_name} ${item?.data?.attributes.last_name}`,
            attachment:
              item?.attributes?.attachment !== null
                ? item?.attributes?.attachment?.data?.attributes
                : null,
          }));

          setMessages(newMessages?.reverse());

          if (newMessages?.length > 0) {
            setHasMessages(true);
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    if (!_.isEmpty(activeConversation)) {
      fetchMessages(activeConversation?.id);

      let timer = setTimeout(() => {
        scrollToBottom();
        clearTimeout(timer);
      }, 1000);
    }
  }, [activeConversation]);

  return (
    <div style={styles.pageContainer}>
      <Helmet>
        <title>{config?.pageTitle}</title>
      </Helmet>

      <Breadcrumb style={styles.breadcrumbs}>
        {config?.breadcrumbs?.map((breadcrumb, index) => (
          <Breadcrumb.Item key={index}>
            <Link to={breadcrumb?.path}>{breadcrumb?.title}</Link>
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>

      {!loadingConversations && hasConversations && (
        <div style={styles.container}>
          <Stack direction={{ base: "column", md: "row" }} spacing={0}>
            <Col>
              <div style={styles.searchBoxContainer}>
                <div className="quick-talks-search" style={styles.searchBox}>
                  <Search
                    placeholder="Search"
                    allowClear
                    enterButton
                    value={keyword}
                    onChange={(e) => setKeyword(e.target.value)}
                    onSearch={onSearch}
                  />
                </div>
              </div>

              <div style={styles.conversationsContainer}>
                {loadingConversations && (
                  <div style={styles.loaderContainer}>
                    <Spin />
                  </div>
                )}

                {!loadingConversations &&
                  conversations?.length > 0 &&
                  conversations?.map((item) => (
                    <div
                      style={{
                        ...styles.conversation,
                        background:
                          item?.id === activeConversation?.id
                            ? "#1164A3"
                            : "transparent",
                      }}
                      onClick={() => setActiveConversation(item)}
                      key={item?.id}
                    >
                      <div
                        style={{
                          ...styles.conversationTitle,
                          fontWeight:
                            item?.id === activeConversation?.id
                              ? "bold"
                              : "normal",
                        }}
                      >
                        # {item?.name}
                      </div>
                    </div>
                  ))}
              </div>
            </Col>

            <Col style={{ width: "100%" }}>
              {!_.isEmpty(activeConversation) && (
                <>
                  <div style={styles.header}>
                    <div style={styles.headerText}>
                      <h3 style={styles.headerTitle}>
                        # {activeConversation?.name}
                      </h3>
                      <Badge
                        color="green"
                        text={`${
                          activeConversation?.members?.data?.length || 0
                        } members`}
                        style={styles.headerSubtitle}
                      />
                    </div>
                  </div>

                  <div className="messages-container" style={styles.messages}>
                    {loading && (
                      <div style={styles.loaderContainer}>
                        <Spin />
                      </div>
                    )}

                    {!loading && !hasMessages && <Empty style={styles.empty} />}

                    {!loading &&
                      hasMessages &&
                      messages?.map((item) => (
                        <div style={styles.message} key={item?.id}>
                          <Avatar
                            src={
                              item?.sender?.photo?.formats?.small?.url
                                ? `${process.env.REACT_APP_BASE_API_URL}${item?.sender?.photo?.formats?.small?.url}`
                                : "/images/no-avatar.png"
                            }
                            style={styles.avatar}
                          />

                          <div style={styles.messageText}>
                            <div style={styles.title}>
                              <span>
                                {item?.sender?.first_name}{" "}
                                {item?.sender?.last_name}
                              </span>

                              <span> • </span>

                              <span style={styles.subtitle}>
                                {moment(item?.createdAt).fromNow()}
                              </span>
                            </div>

                            {item?.type === "text" && (
                              <div style={styles.subtitle}>{item?.content}</div>
                            )}

                            {item?.type === "attachment" && (
                              <span style={styles.attachment}>
                                <BsFileEarmarkMedical
                                  style={styles.attachmentIcon}
                                />

                                <span style={styles.attachmentText}>
                                  <span style={styles.attachmentName}>
                                    {item?.attachment?.name}
                                  </span>

                                  <span style={styles.attachmentSize}>
                                    {item?.attachment?.size} KB
                                  </span>
                                </span>

                                <Button
                                  style={styles.button}
                                  icon={<GrDownload />}
                                  onClick={() =>
                                    onDownloadAttachment(item?.attachment)
                                  }
                                />
                              </span>
                            )}
                          </div>
                        </div>
                      ))}
                  </div>

                  <div>
                    <div style={styles.inputBoxContainer}>
                      {showEmojis && (
                        <div style={styles.emojiContainer} ref={emojisRef}>
                          <Picker onEmojiClick={onSelectEmoji} />
                        </div>
                      )}

                      <input
                        placeholder="Enter your message..."
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        onKeyDown={onKeyDown}
                        style={styles.input}
                        onFocus={() => setIsFocusing(true)}
                        onBlur={() => setIsFocusing(false)}
                      ></input>

                      <input
                        type="file"
                        style={styles.fileInput}
                        onChange={onChangeFile}
                        ref={fileInputRef}
                      />

                      <div style={styles.buttons}>
                        <div>
                          <Button
                            style={styles.button}
                            icon={<SmileOutlined />}
                            onClick={toggleEmojis}
                          />
                          <Divider type="vertical" style={styles.divider} />
                          <Button
                            style={styles.button}
                            icon={<ImAttachment />}
                            onClick={() => fileInputRef?.current?.click()}
                          />
                        </div>

                        <Button
                          icon={<SendOutlined />}
                          style={styles.button}
                          onClick={onSubmit}
                          disabled={message === ""}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </Col>
          </Stack>
        </div>
      )}

      {!loadingConversations && !hasConversations && (
        <Empty style={styles.empty} />
      )}
    </div>
  );
};

const styles = {
  container: {
    overflow: "hidden",
    marginTop: 16,
    borderRadius: 12,
    background: "#eee",
  },
  title: {
    fontWeight: "bold",
  },
  subtitle: {
    color: "#888",
  },
  input: {
    border: "none",
    width: "100%",
    outline: "none",
    padding: 16,
  },
  buttons: {
    background: "#F8F8F8",
    borderTop: "1px solid #eee",
    padding: "6px 16px",
    display: "flex",
    justifyContent: "space-between",
  },
  button: {
    border: "none",
    background: "transparent",
  },
  divider: {
    background: "#eee",
  },
  messages: {
    maxHeight: 500,
    overflowY: "auto",
    border: "1px solid #eee",
    padding: 24,
    paddingBottom: 12,
    minHeight: "calc(100vh - 120px)",
    background: "white",
  },
  searchBoxContainer: {
    background: "white",
    width: "100%",
    minHeight: 60,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "#2C092A",
    padding: 16,
    borderRight: "1px solid rgba(255, 255, 255, 0.3)",
  },
  searchBox: {},
  inputBoxContainer: {
    border: "1px solid #eee",
  },
  message: {
    marginBottom: 12,
    display: "flex",
  },
  header: {
    minHeight: 64,
    display: "flex",
    alignItems: "center",
    background: "#2C092A",
  },
  headerSubtitle: {
    color: "#eee",
    margin: 0,
    padding: 0,
  },
  headerText: {
    padding: "0 24px",
  },
  headerTitle: {
    margin: 0,
    color: "white",
  },
  conversationsContainer: {
    background: "#3E0F3F",
    height: "100%",
    minHeight: 600,
  },
  conversation: {
    padding: "12px 24px",
    cursor: "pointer",
  },
  conversationTitle: {
    color: "white",
  },
  avatar: {
    borderRadius: 6,
    width: 42,
    height: 42,
    border: "1px solid #eee",
    marginRight: 8,
  },
  loaderContainer: {
    paddingTop: 24,
    textAlign: "center",
  },
  emojiContainer: {
    position: "absolute",
    left: 0,
    bottom: 102,
  },
  fileInput: {
    display: "none",
  },
  attachment: {
    padding: 12,
    borderRadius: 6,
    display: "inline-block",
    background: "#eee",
    marginTop: 6,
    maxWidth: 320,
    display: "flex",
    alignItems: "center",
  },
  messageText: {
    flex: 1,
  },
  attachmentText: {
    marginLeft: 8,
    flex: 1,
  },
  attachmentName: {
    fontWeight: "bold",
    display: "block",
  },
  attachmentSize: {
    color: "#888",
    display: "block",
    fontSize: 12,
  },
  attachmentIcon: {
    fontSize: 32,
    color: "#E13130",
  },
  empty: {
    padding: 24,
  },
};

export default Chats;
