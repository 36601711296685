import React from "react";
import { Route } from "react-router-dom";
import {
  LetterheadList,
  LetterheadAdd,
  LetterheadEdit,
} from "../pages/Letterheads";

export const register = (props) => {
  const basePath = "letterheads";

  return (
    <>
      <Route path={basePath} element={<LetterheadList />} exact={true} />
      <Route path={`${basePath}/add`} element={<LetterheadAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<LetterheadEdit />} />
    </>
  );
};
