import React, { useState } from "react";
import {
  Empty,
  Tooltip,
  Button,
  Popconfirm,
  Space,
  Modal,
  notification,
} from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useBreakpointValue, Stack } from "@chakra-ui/react";

const RepeatableComponent = (props) => {
  const { list, label, modals, name, modalComponents } = props;
  const [isOpenAdd, setIsOpenAdd] = useState(false);
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [isOpenList, setIsOpenList] = useState(false);
  const [activeItem, setActiveItem] = useState(null);

  const onShowAdd = () => setIsOpenAdd(true);

  const onCloseAdd = () => setIsOpenAdd(false);

  const onShowList = () => setIsOpenList(true);

  const onCloseList = () => setIsOpenList(false);

  const onShowEdit = (id) => {
    setActiveItem(modals?.[name]?.data?.find((item) => item?.id === id));
    setIsOpenEdit(true);
  };

  const onCloseEdit = () => setIsOpenEdit(false);

  const deleteItemByIndex = (id) => {
    modals?.[name]?.methods?.delete(id);

    notification["success"]({
      message: `Remove ${label}`,
      description: `Remove ${label} successfully!`,
    });
  };

  const renderCell = (record, listItem) => {
    if (listItem?.render) {
      return listItem?.render(record?.[listItem?.dataIndex]);
    }

    return record?.[listItem?.dataIndex]?.toString() || "N/A";
  };

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <h3 style={styles.title}>{label}</h3>

        <Stack direction={useBreakpointValue({ base: "column", md: "row" })} spacing={2}>
          {modalComponents?.add && (
            <Button type="primary" onClick={onShowAdd}>
              New Item
            </Button>
          )}

          {modalComponents?.list && (
            <Button onClick={onShowList}>Add Existing Item</Button>
          )}
        </Stack>
      </div>

      <div style={styles.body}>
        {(!modals?.[name]?.data || modals?.[name]?.data?.length) === 0 && (
          <div style={styles.loaderContainer}>
            <Empty />
          </div>
        )}

        {modals && modals?.[name]?.data?.length > 0 && (
          <>
            <table className="public-form-table">
              <tr>
                <th>No.</th>
                {list?.map((listItem, index) => (
                  <th key={index}>{listItem?.name}</th>
                ))}
                {!props?.hide_actions && (
                  <th>Actions</th>
                )}
              </tr>

              <tbody>
                {modals?.[name]?.data?.map((record, index) => (
                  <tr key={index}>
                    <td className="text-center text-bold">{index + 1}</td>
                    {list?.map((listItem, listItemIndex) => (
                      <td key={listItemIndex}>
                        {renderCell(record, listItem)}
                      </td>
                    ))}

                    {!props?.hide_actions && (
                      <td className="text-center">
                        <Space>
                          <Tooltip placement="bottom" title={`Edit Item`}>
                            <Button
                              icon={<EditOutlined />}
                              onClick={() => onShowEdit(record?.id)}
                            />
                          </Tooltip>

                          <Popconfirm
                            title={`Are you sure to remove this item?`}
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => deleteItemByIndex(record?.id)}
                          >
                            <Tooltip placement="bottom" title={`Remove Item`}>
                              <Button type="danger" icon={<DeleteOutlined />} />
                            </Tooltip>
                          </Popconfirm>
                        </Space>
                      </td>

                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
      </div>

      {modalComponents?.add && (
        <Modal
          title={`Add New ${label}`}
          footer={null}
          open={isOpenAdd}
          onCancel={onCloseAdd}
          destroyOnClose
        >
          {modalComponents?.add({
            onSubmit: (data) => {
              modals?.[name]?.methods?.add(data);
              onCloseAdd();
            },
            onCancel: onCloseAdd,
          })}
        </Modal>
      )}

      <Modal
        title={`Edit ${label}`}
        footer={null}
        open={isOpenEdit}
        onCancel={onCloseEdit}
        destroyOnClose
      >
        {modalComponents?.edit({
          data: activeItem?.raw_data,
          onSubmit: (data) => {
            modals?.[name]?.methods?.edit(activeItem?.id, data);
            onCloseEdit();
          },
          onCancel: onCloseEdit,
        })}
      </Modal>

      {modalComponents?.list && (
        <Modal
          title={`Select ${label}`}
          footer={null}
          open={isOpenList}
          onCancel={onCloseList}
          width={1000}
          destroyOnClose
        >
          {modalComponents?.list({
            form: props?.form,
            onSubmit: (data) => {
              modals?.[name]?.methods?.addExisting(data);

              onCloseList();
            },
            onCancel: onCloseList,
          })}
        </Modal>
      )}
    </div>
  );
};

const styles = {
  container: {},
  header: {
    marginBottom: 12,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
  },
  loaderContainer: {
    padding: 24,
  },
};

export default RepeatableComponent;
