import config from "../config";

export default {
  id: `${config?.slugs?.plural}-list`,
  pageTitle: `${config?.entityNames?.plural} Report`,
  code: "HAND_TOOLS",
  breadcrumbs: [
    {
      path: "/quick-stocks/dashboard",
      title: "Home",
    },
    {
      path: `/quick-stocks/reports`,
      title: "Reports",
    },
    {
      path: "",
      title: `${config?.entityNames?.plural} Report`,
    },
  ],
  columns: [
    // {
    //   title: "Tool Type",
    //   dataIndex: "tool_type",
    //   selectable: true,
    //   references: {
    //     fieldName: "name",
    //   },
    // },
    {
      title: "Name",
      dataIndex: "name",
      selectable: true,
      sorter: (a, b) => a?.name?.localeCompare(b?.name),
    },
    {
      title: "Project",
      dataIndex: "project",
      selectable: true,
      references: {
        fieldName: "name",
      },
    },
    {
      title: "Model",
      dataIndex: "model",
      selectable: true,
      sorter: (a, b) => a?.model?.localeCompare(b?.model),
    },
    // {
    //   title: "Condition",
    //   dataIndex: "condition",
    //   selectable: true,
    //   sorter: (a, b) => a?.condition?.localeCompare(b?.condition),
    // },
    {
      title: "Location",
      dataIndex: "location",
      selectable: true,
      references: {
        fieldName: "name",
      },
    },
  ],
  ...config,
};
