import config from "../config";
import { Tag } from "antd";

const commify = (x) => {
  return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export default {
  id: `${config?.slugs?.plural}-list`,
  pageTitle: config?.entityNames?.plural,
  breadcrumbs: [
    {
      path: "/quick-stocks/dashboard",
      title: "Home",
    },
    {
      path: "",
      title: config?.entityNames?.plural,
    },
  ],
  columns: [
    {
      title: "Heading",
      dataIndex: "heading",
      selectable: true,
      sorter: (a, b) => a?.heading?.localeCompare(b?.heading),
      width: 200,
    },
    {
      title: "Project",
      dataIndex: "project",
      selectable: true,
      references: {
        fieldName: "name",
      },
    },
    {
      title: "Budget",
      dataIndex: "budget",
      selectable: true,
      render: (text) => commify(text || 0),
      sorter: (a, b) => a?.budget - b?.budget,
    },
    {
      title: "Delivery Address",
      dataIndex: "delivery_address",
      selectable: true,
      filterable: true,
      references: {
        fieldName: (record) =>
          `${record?.name} (${record?.location_type?.data?.attributes?.name})`,
      },
    },
    {
      title: "Urgency Level",
      dataIndex: "urgency_level",
      selectable: true,
      render: (text) => <Tag>{text}</Tag>,
    },
  ],
  ...config,
};
