import React from "react";
import { Route } from "react-router-dom";
import InventorySettings from "../pages/InventorySettings";

export const register = (props) => {
  const basePath = "inventory-settings";

  return (
    <>
      <Route path={basePath} element={<InventorySettings />} exact={true} />
    </>
  );
};
