import React, { useEffect, useState } from "react";
import { AddForm } from "../../../components";
import { useNavigate, useParams } from "react-router-dom";
import { makeConfig } from "./config";
import { axios } from "../../../../../App";
import { fetchData } from "../../../../../utils/helpers/data.helper";
import qs from "qs";
import moment from "moment";

const Page = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({});
  const config = makeConfig({ navigate });
  const contentTypes = [
    {
      slug: "material-groups",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          material_group: data,
        })),
    },
    // {
    //   slug: "material-items",
    //   transformer: (item) => ({
    //     value: item?.id,
    //     label: item?.name,
    //   }),
    //   onSuccess: (data) =>
    //     setValues((prev) => ({
    //       ...prev,
    //       material_item: data,
    //     })),
    // },
    // {
    //   slug: "material-units",
    //   transformer: (item) => ({
    //     value: item?.id,
    //     label: item?.name,
    //   }),
    //   onSuccess: (data) =>
    //     setValues((prev) => ({
    //       ...prev,
    //       material_unit: data,
    //     })),
    // },
    {
      slug: "sub-contractors",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          sub_contractor: data,
        })),
    },
    {
      slug: "employees",
      transformer: (item) => ({
        value: item?.id,
        label: `${item?.first_name} ${item?.last_name}`,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
        //  approved_by: data,
        })),
    },
     {
      slug: "employees/approvers",
      transformer: (item) => ({
        value: item?.id,
        label: `${item?.first_name} ${item?.last_name}`,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
         approved_by: data,
        })),
    },
  ];

  useEffect(() => {
    contentTypes?.forEach((contentType) => {
      fetchData(contentType);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const queryObject = {
          populate: "*",
        };

        const response = await axios.get(
          `/${config?.slugs?.plural}/${id}?${qs.stringify(queryObject)}`
        );

        if (response?.data) {
          setData({
            ...response?.data?.data?.attributes,
            id: response?.data?.data?.id,
            material_group:
              response?.data?.data?.attributes?.material_group?.data?.id,
            material_item:
              response?.data?.data?.attributes?.material_item?.data?.id,
            material_unit:
              response?.data?.data?.attributes?.material_unit?.data?.id,
            sub_contractor:
              response?.data?.data?.attributes?.sub_contractor?.data?.id,
            scheduled_date: moment(
              response?.data?.data?.attributes?.scheduled_date
            ),
            approved_by:
              response?.data?.data?.attributes?.approved_by?.data?.id,
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <AddForm config={config} data={data} loading={loading} values={values} />
  );
};

export default Page;
