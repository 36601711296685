import React, { useState, useEffect } from "react";
import { AddForm } from "../../../components";
import { useNavigate } from "react-router-dom";
import { makeConfig } from "./config";
import { useSelector } from "react-redux";
import { fetchData } from "../../../../../utils/helpers/data.helper";
import { find } from "lodash";

const Page = (props) => {
  const navigate = useNavigate();
  const auth = useSelector((state) => state?.auth);
  const config = makeConfig({ navigate, auth });
  const [values, setValues] = useState({
    status: ["Pending", "Approved"]?.map((item) => ({
      value: item,
      label: item,
    })),
    project: [],
    work_order: [],
    all_work_order: [],
  });
  const contentTypes = [
    {
      slug: "sub-contractors",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          sub_contractor: data,
        })),
    },
    {
      slug: "employees",
      transformer: (item) => ({
        value: item?.id,
        label: `${item?.first_name} ${item?.last_name}`,
        uid: item?.uid,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          employee: data,
        //  approved_by: data,
        })),
    },
     {
      slug: "employees/approvers",
      transformer: (item) => ({
        value: item?.id,
        label: `${item?.first_name} ${item?.last_name}`,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
         approved_by: data,
        })),
    },
    {
      slug: "projects",
      query: {
        populate: "*",
      },
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
        sub_contractors: item?.sub_contractors?.data?.map((item) => ({
          value: item?.id,
          label: item?.attributes?.name,
        })),
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          all_project: data,
        })),
    },
    {
      slug: "sub-contractor-quotations",
      query: {
        filters: {
          status: "Approved",
        },
        populate: "*",
      },
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          all_work_order: data,
        })),
    },
  ];

  useEffect(() => {
    contentTypes?.forEach((contentType) => {
      fetchData(contentType);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AddForm
      config={config}
      values={values}
      data={{
        status: "Pending",
        employee: find(values?.employee, {
          uid: auth?.user?.uid,
        })?.value,
      }}
      onFieldChange={{
        // Reference: https://4x.ant.design/components/form/#FormInstance

        sub_contractor: (antForm) => {
          const subContractorId = antForm.getFieldValue("sub_contractor");

          if (subContractorId) {
            let filteredProjects = [];
            let subContractorIds = [];

            if (values?.all_project?.length > 0) {
              values?.all_project?.forEach((project) => {
                subContractorIds = project?.sub_contractors?.map((item) =>
                  Number(item?.value)
                );

                if (
                  subContractorIds?.some(
                    (id) => Number(id) === Number(subContractorId)
                  )
                ) {
                  filteredProjects.push(project);
                }
              });
            }

            antForm.setFieldValue("project", undefined);
            antForm.setFieldValue("work_order", undefined);

            setValues((prev) => ({
              ...prev,
              project: filteredProjects,
            }));
          } else {
            setValues((prev) => ({
              ...prev,
              project: [],
              work_order: [],
            }));
          }
        },
        project: (antForm) => {
          const projectId = antForm.getFieldValue("project");
          const subContractorId = antForm.getFieldValue("sub_contractor");

          if (projectId) {
            antForm.setFieldValue("work_order", undefined);

            setValues((prev) => ({
              ...prev,
              work_order: values?.all_work_order
                ?.filter(
                  (item) =>
                    item?.project?.data?.id === Number(projectId) &&
                    item?.sub_contractor?.data?.id === Number(subContractorId)
                )
                ?.map((item) => ({
                  value: item?.id,
                  label: item?.heading,
                })),
            }));
          }
        },
      }}
    />
  );
};

export default Page;
