import React from "react";
import { Route } from "react-router-dom";
import {
  EmployeeQualificationList,
  EmployeeQualificationAdd,
  EmployeeQualificationEdit,
} from "../pages/EmployeeQualifications";

export const register = (props) => {
  const basePath = "employee-qualifications";

  return (
    <>
      <Route
        path={basePath}
        element={<EmployeeQualificationList />}
        exact={true}
      />
      <Route path={`${basePath}/add`} element={<EmployeeQualificationAdd />} />
      <Route
        path={`${basePath}/edit/:id`}
        element={<EmployeeQualificationEdit />}
      />
    </>
  );
};
