export default {
  slugs: {
    singular: "task-material",
    plural: "task-materials",
  },
  entityNames: {
    singular: "Task Material",
    plural: "Task Materials",
  },
};
