import { axios } from "../../../../../App";
import config from "../config";
import qs from "qs";

export const makeConfig = ({ navigate }) => ({
  id: `${config?.plural}-edit`,
  title: `Receive Remaining Quantity`,
  pageTitle: `Receive Remaining Quantity`,
  breadcrumbs: [
    {
      path: "/quick-stocks/dashboard",
      title: "Home",
    },
    {
      path: `/quick-stocks/${config?.slugs?.plural}`,
      title: config?.entityNames?.plural,
    },
    {
      path: "",
      title: `Receive Remaining Quantity`,
    },
  ],
  components: [
    {
      span: 12,
      component: "number",
      label: "Remaining Quantity",
      name: "remaining_quantity",
      disabled: true,
    },
    {
      span: 12,
      component: "number",
      label: "Newly Received Quantity",
      name: "newly_received_quantity",
      required: true,
      placeholder: "Enter newly received quantity",
      rules: [
        {
          required: true,
          message: "Newly received quantity is required!",
        },
      ],
      min: 0,
    },
  ],
  actions: {
    onBack: () => navigate(`/quick-stocks/${config?.slugs?.plural}`),
    onFinish: async (values, data) => {
      try {
        if (values?.newly_received_quantity > values?.remaining_quantity) {
          return {
            success: false,
            message: `The number of newly received material items must not be greater than the remaining quantity!`,
          };
        } else {
          const queryObject = {
            filters: {
              project: data?.project?.data?.id,
              material_item: data?.material_item?.data?.id,
              material_unit: data?.material_unit?.data?.id,
            },
          };

          const stockResp = await axios.get(
            `/stock-ledgers?${qs.stringify(queryObject)}`
          );

          if (stockResp?.status === 200) {
            const items = stockResp?.data?.data;

            if (items?.length > 0) {
              const item = items?.[0];

              const updateStockResp = await axios.put(
                `/stock-ledgers/${item?.id}`,
                {
                  data: {
                    ...values,
                    quantity:
                      item?.attributes?.quantity +
                      values?.newly_received_quantity,
                  },
                }
              );

              if (updateStockResp?.status === 200) {
                const updateResp = await axios.put(
                  `/${config?.slugs?.plural}/${data?.id}`,
                  {
                    data: {
                      received_quantity:
                        data?.received_quantity +
                        values?.newly_received_quantity,
                      return_replace_quantity: Math.max(
                        data?.return_replace_quantity -
                          values?.newly_received_quantity,
                        0
                      ),
                    },
                  }
                );

                if (updateResp?.status === 200) {
                  return {
                    success: true,
                    message: `Receive new materials successfully!`,
                  };
                }
              }
            }
          }

          throw new Error();
        }
      } catch (error) {
        return {
          success: false,
          message: error?.response?.data?.error?.message,
        };
      }
    },
  },
  ...config,
});
