import { Breadcrumb } from "antd";
import React from "react";
import { Link } from "react-router-dom";

const Page = (props) => {
  return (
    <div>
      <Breadcrumb style={styles.breadcrumbs}>
        <Breadcrumb.Item>
          <Link to="/">Home</Link>
        </Breadcrumb.Item>

        <Breadcrumb.Item>User Guide</Breadcrumb.Item>
      </Breadcrumb>

      <h1>User Guide</h1>
    </div>
  );
};

const styles = {
  breadcrumbs: {
    marginBottom: 24,
  },
};

export default Page;
