export default {
  slugs: {
    singular: "manpower-log",
    plural: "manpower-logs",
  },
  entityNames: {
    singular: "Manpower Log",
    plural: "Manpower Logs",
  },
};
