import React, { useState, useEffect } from "react";
import { AddForm } from "../../../components";
import { useNavigate } from "react-router-dom";
import { makeConfig } from "./config";
import { useSelector } from "react-redux";
import { fetchData } from "../../../../../utils/helpers/data.helper";
import { v4 } from "uuid";

const Page = (props) => {
  const navigate = useNavigate();
  const auth = useSelector((state) => state?.auth);
  const config = makeConfig({ navigate, auth });
  const [values, setValues] = useState({});
  const [coordinates, setCoordinates] = useState([]);
  const contentTypes = [
    {
      slug: "location-types",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          location_type: data,
        })),
    },
    {
      slug: "states",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          state: data,
        })),
    },
    {
      slug: "cities",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          city: data,
        })),
    },
  ];

  // CO-ORDINATES

  const onAddCoordinate = (data) => {
    const newData = {
      id: v4(),
      ...data,
    };

    setCoordinates((prev) => prev?.concat(newData));
  };

  const onEditCoordinate = (id, data) => {
    let newItems = coordinates?.map((record) => {
      if (record?.id === id) {
        record = {
          ...data,
        };
      }

      return record;
    });

    setCoordinates(newItems);
  };

  const onDeleteCoordinate = (id) => {
    setCoordinates((prev) => prev?.filter((item) => item?.id !== id));
  };

  useEffect(() => {
    contentTypes?.forEach((contentType) => {
      fetchData(contentType);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AddForm
      config={config}
      values={values}
      modals={{
        coordinates: {
          data: coordinates,
          methods: {
            add: onAddCoordinate,
            edit: onEditCoordinate,
            delete: onDeleteCoordinate,
          },
        },
      }}
      data={{
        coordinates,
      }}
    />
  );
};

export default Page;
