import React from "react";
import { Route, Navigate } from "react-router-dom";
import NotFound from "../../../pages/NotFound";
import QuickTalks from "../index";
import HomePage from "../pages";

import * as Meetings from "./meetings.route";
import * as MeetingComments from "./meeting-comments.route";
import * as MeetingNotes from "./meeting-notes.route";
import * as MeetingTypes from "./meeting-types.route";
import * as Reminders from "./reminders.route";
import * as Events from "./events.route";
import * as ToDos from "./to-dos.route";
import * as EGChats from "./eg-chats.route";
import * as ProjectChats from "./project-chats.route";
import * as EmployeeChats from "./employee-chats.route";
import * as EventTemplates from "./event-templates.route";
import * as ReminderTemplates from "./reminder-templates.route";
import * as Dashboard from "./dashboard.route";
import * as Emails from "./emails.route";
import * as Calendar from "./calendar.route";
import * as Reports from "./reports.route";

export const register = (props) => {
  const basePath = "/quick-talks";

  return (
    <Route path={basePath} element={<QuickTalks />}>
      {Meetings.register()}
      {MeetingComments.register()}
      {MeetingNotes.register()}
      {MeetingTypes.register()}
      {Reminders.register()}
      {Events.register()}
      {ToDos.register()}
      {EGChats.register()}
      {ProjectChats.register()}
      {EmployeeChats.register()}
      {EventTemplates.register()}
      {ReminderTemplates.register()}
      {Dashboard.register()}
      {Emails.register()}
      {Calendar.register()}
      {Reports.register()}

      <Route
        path={basePath}
        element={<Navigate to={`${basePath}/dashboard`} replace />}
        // element={<HomePage />}
      />
      <Route path="*" element={<NotFound returnPath={basePath} />} />
    </Route>
  );
};
