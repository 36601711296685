import React, { useState, useEffect } from "react";
import { DataTable } from "../../../components";
import { useNavigate, useLocation } from "react-router-dom";
import { Space, Button, Popconfirm, Tooltip, notification, Input } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  ReloadOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import qs from "qs";
import config from "./config";
import { axios } from "../../../../../App";
import _ from "lodash";
import { useSelector } from "react-redux";
import moment from "moment";
import { MAX_PAGINATION_LIMIT } from "../../../../../constants";

const Page = (props) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    pageSize: 10,
  });
  const [total, setTotal] = useState(0);
  const [searchValues, setSearchValues] = useState({});
  const [searched, setSearched] = useState(false);
  const [taskId, setTaskId] = useState("");
  const location = useLocation();
  const auth = useSelector((state) => state?.auth);

  const fetchData = async (params = null) => {
    try {
      setLoading(true);

      const queryObject = {
        populate: "*",
        sort: ["createdAt:desc"],
        pagination: {
          limit: MAX_PAGINATION_LIMIT,
        },
      };

      if (!_.isEmpty(params)) {
        queryObject["filters"] = params;
      }

      const response = await axios.get(
        `${config?.slugs?.plural}?${qs.stringify(queryObject)}`
      );

      if (response?.data) {
        setData(
          response?.data?.data?.map((item) => ({
            id: item?.id,
            ...item?.attributes,
          }))
        );

        setTotal(response?.data?.meta?.pagination?.total);

        setPagination({
          ...response?.data?.meta?.pagination,
          pageSize: 10,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const refreshData = () => {
    onSearch();
  };

  const deleteItem = async ({ id, status, amount, work_item, task }) => {
    try {
      const taskResp = await axios.get(`/tasks/${task?.data?.id}`);
      const isTaskStarted =
        moment(taskResp?.data?.attributes?.start_date)?.valueOf() <
        moment()?.valueOf();

      if (isTaskStarted && status === "Pending") {
        const checkResponse = await axios.get(
          `/stock-ledgers/${work_item?.data?.id}`
        );

        if (checkResponse?.data) {
          const stockAmount = checkResponse?.data?.data?.attributes?.quantity;

          const returnResponse = await axios.put(
            `/stock-ledgers/${work_item?.data?.id}`,
            {
              data: {
                quantity: stockAmount + amount,
              },
            }
          );

          const deleteResponse = await axios.delete(
            `${config?.slugs?.plural}/${id}`
          );

          if (returnResponse?.data && deleteResponse?.data) {
            notification["success"]({
              message: `Remove ${config?.entityNames?.singular}`,
              description: `Return items to stock successfully!`,
            });

            notification["success"]({
              message: `Remove ${config?.entityNames?.singular}`,
              description: `Remove ${config?.entityNames?.singular} successfully!`,
            });

            refreshData();
          }
        } else {
          throw new Error();
        }
      } else {
        const response = await axios.delete(`${config?.slugs?.plural}/${id}`);

        if (response?.data) {
          notification["success"]({
            message: `Remove ${config?.entityNames?.singular}`,
            description: `Remove ${config?.entityNames?.singular} successfully!`,
          });

          refreshData();
        } else {
          throw new Error();
        }
      }
    } catch (error) {
      console.log("error", error);
      notification["error"]({
        message: `Remove ${config?.entityNames?.singular}`,
        description: `Remove ${config?.entityNames?.singular} failed!`,
      });
    }
  };

  const getColumnFilterProps = ({ dataIndex, references = {} }) => {
    if (typeof references?.fieldName === "function") {
      const transformer = references?.fieldName;

      return {
        render: (data) => transformer(data?.data?.attributes) || "N/A",
      };
    } else {
      return {
        render: (data) =>
          data?.data?.attributes?.[references?.fieldName] || "N/A",
      };
    }
  };

  const getColumnProps = (column) => {
    if (column?.references) {
      return getColumnFilterProps(column);
    }

    return column;
  };

  const onSearch = () => {
    setSearched(true);

    let orConditions = {
      $or: [
        {
          task: {
            name: {
              $containsi: searchValues?.keyword,
            },
          },
        },
        {
          work_group: {
            name: {
              $containsi: searchValues?.keyword,
            },
          },
        },
      ],
    };

    let searchParams = orConditions;

    if (taskId !== "") {
      searchParams = {
        $and: [
          {
            task: parseInt(taskId),
          },
          getFiltersForRoles(),
          orConditions,
        ],
      };
    }

    fetchData(searchParams);
  };

  const onReset = () => {
    setSearchValues({});
    fetchData(getFiltersForRoles());
    setSearched(false);
  };

  const getResultsMessage = () => {
    if (!_.isEmpty(searchValues) && !loading && searched) {
      return total > 0 ? (
        <span>
          <strong>{total}</strong> matched results
        </span>
      ) : (
        <span>No matched results</span>
      );
    }

    return (
      <span>
        <strong>{total}</strong> total records
      </span>
    );
  };

  const getFiltersForRoles = () => {
    // const userType = user?.user_type?.toLowerCase()?.replace(/\s/i, "_");

    // if (user?.is_admin) {
    //   return {};
    // }

    // return {
    //   project: {
    //     [Pluralize(userType)]: {
    //       uid: user?.uid,
    //     },
    //   },
    // };

    if (auth?.user?.is_admin) {
      return {};
    }

    return {
      task: {
        assignees: {
          uid: auth?.user?.uid,
        },
      },
    };
  };

  const onAdd = () => {
    navigate(`/quick-projects/${config?.slugs?.plural}/add`);
  };

  useEffect(() => {
    if (props?.mode !== "embed") {
      const queryString = location?.search?.substring(1);

      if (queryString !== "") {
        const queryObject = qs.parse(queryString);

        if (queryObject?.taskId) {
          setTaskId(`${queryObject?.taskId}`);
          fetchData({
            $and: [
              {
                task: parseInt(queryObject?.taskId),
              },
              getFiltersForRoles(),
            ],
          });
        } else {
          fetchData(getFiltersForRoles());
        }
      } else {
        fetchData(getFiltersForRoles());
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.mode, location?.search]);

  useEffect(() => {
    if (props?.mode === "embed") {
      if (props?.query?.taskId) {
        setTaskId(`${props?.query?.taskId}`);
        fetchData({
          $and: [
            {
              task: parseInt(props?.query?.taskId),
            },
            getFiltersForRoles(),
          ],
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.mode, props?.query]);

  return (
    <DataTable
      data={data}
      config={{
        ...config,
        columns: config?.columns
          ?.map((column) => ({
            ...column,
            ...getColumnProps(column),
          }))
          ?.concat({
            title: "Actions",
            width: 100,
            selectable: false,
            render: ({
              id,
              status,
              amount,
              work_item,
              task,
              employee,
              approved_by,
            }) => {
              const isCreator =
                auth?.user?.uid === employee?.data?.attributes?.uid;
              const isApprover =
                auth?.user?.uid === approved_by?.data?.attributes?.uid;
              const isAdmin = auth?.user?.is_admin;

              return (
                <Space style={styles.buttons}>
                  <Tooltip
                    placement="bottom"
                    title={`View ${config?.entityNames?.singular}`}
                  >
                    <Button
                      icon={<EyeOutlined />}
                      onClick={() =>
                        props?.mode === "embed"
                          ? props?.actions?.onView({ id })
                          : navigate(
                              `/quick-projects/${config?.slugs?.plural}/view/${id}`
                            )
                      }
                    />
                  </Tooltip>

                  {(isCreator || isApprover || isAdmin) &&
                    status !== "Approved" &&
                    status !== "Done" && (
                      <Tooltip
                        placement="bottom"
                        title={`Edit ${config?.entityNames?.singular}`}
                      >
                        <Button
                          icon={<EditOutlined />}
                          onClick={() => {
                            if (isApprover || isAdmin) {
                              navigate(
                                `/quick-projects/${config?.slugs?.plural}/approver/edit/${id}`
                              );

                              return;
                            }

                            if (isCreator || isAdmin) {
                              navigate(
                                `/quick-projects/${config?.slugs?.plural}/creator/edit/${id}`
                              );

                              return;
                            }
                          }}
                        />
                      </Tooltip>
                    )}

                  {(isCreator || isAdmin) &&
                    status !== "Approved" &&
                    status !== "Done" && (
                      <Popconfirm
                        title={`Are you sure to remove this ${config?.entityNames?.singular}?`}
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() =>
                          deleteItem({
                            id,
                            status,
                            amount,
                            work_item,
                            task,
                          })
                        }
                      >
                        <Tooltip
                          placement="bottom"
                          title={`Remove ${config?.entityNames?.singular}`}
                        >
                          <Button type="danger" icon={<DeleteOutlined />} />
                        </Tooltip>
                      </Popconfirm>
                    )}
                </Space>
              );
            },
          }),
      }}
      loading={loading}
      pagination={pagination}
      actions={{
        onAdd,
        onRefresh: refreshData,
      }}
      Toolbar={
        <div className="toolbar">
          <Input
            value={searchValues?.keyword}
            placeholder="Search for keyword..."
            onChange={(e) =>
              setSearchValues((prev) => ({
                ...prev,
                keyword: e.target.value,
              }))
            }
          />

          <div className="toolbar-buttons">
            <p className="toolbar-results">{getResultsMessage()}</p>

            <Space>
              <Button onClick={onReset}>Reset</Button>
              <Button type="primary" onClick={onSearch}>
                Search
              </Button>
            </Space>
          </div>
        </div>
      }
      buttons={[
        <Tooltip placement="top" title="Add new item">
          <Button
            icon={<PlusOutlined />}
            onClick={props?.mode === "embed" ? props?.actions?.onAdd : onAdd}
          />
        </Tooltip>,
        <Tooltip placement="top" title="Refresh">
          <Button
            icon={<ReloadOutlined />}
            onClick={refreshData}
            loading={loading}
          />
        </Tooltip>,
      ]}
      showsBreadcrumbs={props?.showsBreadcrumbs}
    />
  );
};

const styles = {
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
};

export default Page;
