import { axios } from "../../../../App";

const config = {
  slugs: {
    singular: "inventory-setting",
    plural: "inventory-settings",
  },
  entityNames: {
    singular: "Inventory Settings",
    plural: "Inventory Settings",
  },
};

export const makeConfig = ({ navigate }) => ({
  id: `${config?.plural}-edit`,
  title: `Edit ${config?.entityNames?.singular}`,
  pageTitle: `Edit ${config?.entityNames?.singular}`,
  breadcrumbs: [
    {
      path: "/quick-setup",
      title: "Home",
    },
    {
      path: "",
      title: `Inventory Settings`,
    },
  ],
  components: [
    {
      span: 12,
      component: "number",
      label: "Service Cost",
      name: "service_cost",
      required: true,
      placeholder: "Enter service cost",
      rules: [
        {
          required: true,
          message: "Service cost is required!",
        },
      ],
      min: 0,
    },
    {
      span: 12,
      component: "number",
      label: "Risk Cost",
      name: "risk_cost",
      required: true,
      placeholder: "Enter risk cost",
      rules: [
        {
          required: true,
          message: "Risk cost is required!",
        },
      ],
      min: 0,
    },
    {
      span: 12,
      component: "number",
      label: "Capital Cost",
      name: "capital_cost",
      required: true,
      placeholder: "Enter capital cost",
      rules: [
        {
          required: true,
          message: "Capital cost is required!",
        },
      ],
      min: 0,
    },
    {
      span: 12,
      component: "number",
      label: "Storage Cost",
      name: "storage_cost",
      required: true,
      placeholder: "Enter storage cost",
      rules: [
        {
          required: true,
          message: "Storage cost is required!",
        },
      ],
      min: 0,
    },
  ],
  actions: {
    onFinish: async (values, data) => {
      try {
        const response = await axios.put(`/${config?.slugs?.singular}`, {
          data: values,
        });

        if (response?.data) {
          return {
            success: true,
            message: `Edit ${config?.entityNames?.singular?.toLowerCase()} successfully!`,
          };
        }

        throw new Error();
      } catch (error) {
        return {
          success: false,
          message: error?.response?.data?.error?.message,
        };
      }
    },
  },
  ...config,
});
