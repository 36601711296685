import { axios } from "../../../../../App";
import config from "../config";

export const makeConfig = ({ navigate }) => ({
  id: `${config?.plural}-view`,
  title: `View ${config?.entityNames?.singular}`,
  pageTitle: `View ${config?.entityNames?.singular}`,
  breadcrumbs: [
    {
      path: "/quick-stocks/dashboard",
      title: "Home",
    },
    {
      path: `/quick-stocks/${config?.slugs?.plural}`,
      title: config?.entityNames?.plural,
    },
    {
      path: "",
      title: `Edit ${config?.entityNames?.singular}`,
    },
  ],
  components: [
    {
      span: 24,
      component: "input",
      label: "Heading",
      name: "heading",
      required: true,
      placeholder: "Enter heading",
      rules: [
        {
          required: true,
          message: "Heading is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Sub Contractor",
      name: "sub_contractor",
      placeholder: "Select sub contractor",
      required: true,
      rules: [
        {
          required: true,
          message: "Sub contractor is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Project",
      name: "project",
      placeholder: "Select project",
      required: true,
      rules: [
        {
          required: true,
          message: "Project is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Work Order",
      name: "work_order",
      placeholder: "Select work order",
      required: true,
      rules: [
        {
          required: true,
          message: "Work order is required!",
        },
      ],
    },
    {
      span: 12,
      component: "number",
      label: "Grand Total",
      name: "grand_total",
      required: true,
      placeholder: "Enter grand total",
      rules: [
        {
          required: true,
          message: "Grand total is required!",
        },
      ],
      min: 0,
    },
    {
      span: 12,
      component: "uploader",
      label: "Attachment",
      name: "attachment",
      required: true,
      placeholder: "Upload attachment",
      rules: [
        {
          required: true,
          message: "Attachment is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Approved By",
      name: "approved_by",
      placeholder: "Select employee",
      required: true,
      rules: [
        {
          required: true,
          message: "Employee is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Employee",
      name: "employee",
      placeholder: "Select employee",
      required: true,
      rules: [
        {
          required: true,
          message: "Employee is required!",
        },
      ],
      disabled: true,
    },
    {
      span: 12,
      component: "select",
      label: "Status",
      name: "status",
      placeholder: "Select status",
      required: true,
      rules: [
        {
          required: true,
          message: "Status is required!",
        },
      ],
      disabled: true,
    },
    {
      span: 24,
      component: "editor",
      label: "Description",
      name: "description",
      placeholder: "Enter description",
      rows: 5,
      disabled: true,
    },
    {
      span: 24,
      component: "input",
      type: "hidden",
      name: "attachment",
    },
  ],
  actions: {
    onBack: () => navigate(`/quick-stocks/${config?.slugs?.plural}`),
    onFinish: async (values, data) => {
      try {
        const response = await axios.put(
          `/${config?.slugs?.plural}/${data?.id}`,
          {
            data: values,
          }
        );

        if (response?.data) {
          return {
            success: true,
            message: `Edit ${config?.entityNames?.singular?.toLowerCase()} successfully!`,
          };
        }

        throw new Error();
      } catch (error) {
        return {
          success: false,
          message: error?.response?.data?.error?.message,
        };
      }
    },
  },
  ...config,
});
