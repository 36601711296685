import React, { useEffect, useState } from "react";
import { AddForm } from "../../../components";
import { useNavigate } from "react-router-dom";
import { makeConfig } from "./config";
import { useSelector } from "react-redux";

const Page = (props) => {
  const navigate = useNavigate();
  const auth = useSelector((state) => state?.auth);
  const config = makeConfig({ navigate, auth });
  const [values, setValues] = useState({
    type: ['Skilled', 'Unskilled', 'Semi-skilled']?.map((item) => ({
      value: item,
      label: item,
    }))
  });


  return <AddForm config={config} values={values} />;
};

export default Page;
