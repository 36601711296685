import React from "react";
import { Line } from "@ant-design/plots";

const Chart = ({ data = [], xField, yField, seriesField }) => {
  const config = {
    data,
    // xField: "year",
    // yField: "value",
    // seriesField: "category",
    xField,
    yField,
    seriesField,
    yAxis: {
      label: {
        formatter: (v) =>
          `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
      },
    },
    color: ["#1979C9", "#D62A0D", "#FAA219"],
    ...data,
  };

  return <Line {...config} />;
};

export default Chart;
