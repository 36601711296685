import { axios } from "../../../../../App";
import { v4 } from "uuid";
import config from "../config";
import moment from "moment";

export const makeConfig = ({ navigate, auth }) => ({
  id: `${config?.slugs?.plural}-add`,
  title: `Add New ${config?.entityNames?.singular}`,
  pageTitle: `Add New ${config?.entityNames?.singular}`,
  breadcrumbs: [
    {
      path: "/quick-teams/dashboard",
      title: "Home",
    },
    {
      path: `/quick-teams/${config?.slugs?.plural}`,
      title: config?.entityNames?.plural,
    },
    {
      path: "",
      title: `Add New ${config?.entityNames?.singular}`,
    },
  ],
  components: [
    {
      span: 12,
      component: "select",
      label: "Attendance Type",
      name: "attendance_type",
      placeholder: "Select attendance type",
      required: true,
      rules: [
        {
          required: true,
          message: "Attendance type is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Location",
      name: "location",
      placeholder: "Select location",
      required: true,
      rules: [
        {
          required: true,
          message: "Location is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Shift",
      name: "shift",
      placeholder: "Select shift",
      required: true,
      rules: [
        {
          required: true,
          message: "Shift is required!",
        },
      ],
    },
    {
      span: 12,
      component: "date",
      label: "Date",
      name: "date",
      required: true,
      placeholder: "Select date",
      rules: [
        {
          required: true,
          message: "Date is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Employee",
      name: "employee",
      placeholder: "Select employee",
      required: true,
      rules: [
        {
          required: true,
          message: "Employee is required!",
        },
      ],
    },
    {
      span: 12,
      component: "time",
      label: "In Time",
      name: "in_time",
      required: true,
      placeholder: "Select time",
      rules: [
        {
          required: true,
          message: "In time is required!",
        },
      ],
    },
    {
      span: 12,
      component: "time",
      label: "Out Time",
      name: "out_time",
      required: true,
      placeholder: "Select time",
      rules: [
        {
          required: true,
          message: "Out time is required!",
        },
      ],
    },
    {
      span: 24,
      component: "editor",
      label: "Description",
      name: "description",
      placeholder: "Enter description",
      rows: 5,
    },
  ],
  actions: {
    onBack: () => navigate(`/quick-teams/${config?.slugs?.plural}`),
    onFinish: async (values) => {
      try {
        const response = await axios.post(`/${config?.slugs?.plural}`, {
          data: {
            ...values,
            uid: v4(),
            // creator: auth?.user?.id,
            in_time: moment(values?.in_time).format("HH:mm:ss.SSS"),
            out_time: moment(values?.out_time).format("HH:mm:ss.SSS"),
          },
        });

        if (response?.data) {
          return {
            success: true,
            message: `Add new ${config?.entityNames?.singular?.toLowerCase()} successfully!`,
          };
        }

        throw new Error();
      } catch (error) {
        return {
          success: false,
          message: `Add new ${config?.entityNames?.singular?.toLowerCase()} failed!`,
        };
      }
    },
  },
});
