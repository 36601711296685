import React, { useState, useEffect } from "react";
import { AddForm } from "../../../components";
import { useNavigate } from "react-router-dom";
import { makeConfig } from "./config";
import { useSelector } from "react-redux";
import { fetchData } from "../../../../../utils/helpers/data.helper";

const Page = (props) => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state?.auth);
  const config = makeConfig({ navigate, user });
  const [values, setValues] = useState({
    passing: Array(100)
      ?.fill(1)
      ?.map((item, index) => ({
        value: new Date().getFullYear() - index,
        label: new Date().getFullYear() - index,
      })),
  });
  const [data, setData] = useState({});
  const contentTypes = [
    {
      slug: "employees",
      query: {
        filters: {
          uid: user?.uid,
        },
      },
      onSuccess: (data) => {
        setData({
          employee: data?.[0]?.id,
        });
      },
    },
  ];

  useEffect(() => {
    contentTypes?.forEach((contentType) => {
      fetchData(contentType);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <AddForm config={config} values={values} data={data} />;
};

export default Page;
