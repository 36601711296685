import React, { useEffect, useState } from "react";
import { AddForm } from "../../../components";
import { useNavigate, useParams } from "react-router-dom";
import { makeConfig } from "./config";
import { axios } from "../../../../../App";
import { fetchData } from "../../../../../utils/helpers/data.helper";
import qs from "qs";

const Page = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    work_hour: [{
      label: 'Full Day',
      value: 8
    }, {
      label: 'Half Day',
      value: 4
    }]?.map((item) => ({
      value: item?.value,
      label: item?.label,
    })),
  });
  const config = makeConfig({ navigate });
  const contentTypes = [
    {
      slug: "tasks",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          task: data,
        })),
    },
    {
      slug: "manpower-types",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          manpower_type: data,
        })),
    },
  ];

  useEffect(() => {
    contentTypes?.forEach((contentType) => {
      fetchData(contentType);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const queryObject = {
          populate: "*",
        };

        const finalId = props?.mode === "embed" ? props?.query?.entryId : id;

        const response = await axios.get(
          `/${config?.slugs?.plural}/${finalId}?${qs.stringify(queryObject)}`
        );

        if (response?.data) {
          setData({
            ...response?.data?.data?.attributes,
            id: response?.data?.data?.id,
            task: response?.data?.data?.attributes?.task?.data?.id,
            manpower_type:
              response?.data?.data?.attributes?.manpower_type?.data?.id,
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, props?.query?.entryId]);

  return (
    <AddForm
      config={config}
      data={data}
      loading={loading}
      values={values}
      mode={props?.mode}
      actions={props?.actions}
      disabledItems={props?.disabledItems}
    />
  );
};

export default Page;
