import React from "react";
import SharedLayout from "../../components/SharedLayout";
import {makeNavbar} from "./nav";
import { Outlet } from "react-router-dom";

const QuickSetup = () => {
  return (
    <SharedLayout menuItem={{ title: "Quick Setup", path: "/quick-setup" }} makeNavbar = {makeNavbar}>
      <Outlet />
    </SharedLayout>
  );
};

export default QuickSetup;
