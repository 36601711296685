import React from "react";
import { Route } from "react-router-dom";
import { ReportList } from "../pages/Reports";

export const register = (props) => {
  const basePath = "reports";

  return (
    <>
      <Route path={basePath} element={<ReportList />} exact={true} />
    </>
  );
};
