import React, { useState, useEffect } from "react";
import { Row, Col, Form, Space, Button } from "antd";
import FormElement from "../FormElement";

const ModalAddForm = ({
  loading = false,
  config,
  data = {},
  values = {},
  disabled = false,
  onSubmit = () => { },
  onCancel = () => { },
  modals,
  onFieldChange = {},
  addTerms = false,
  children = null,
}) => {
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);

  const handleFinish = (values) => {
    setSubmitting(true);
    if (addTerms) {
      onSubmit({
        ...values,
        term_and_condition: data?.term_and_condition
      })
    } else {
      onSubmit(values);
    }
    setSubmitting(false);
  };

  // Function to calculate the total amount including SGST, CGST, and IGST
  const calculateTotal = (quantity, rate, sgst, cgst, igst) => {
    const totalTaxMultiplier = 1 + (sgst + cgst + igst) / 100;
    return quantity * rate * totalTaxMultiplier;
  };

  // Function to calculate the total tax amount (SGST + CGST + IGST)
  const calculateTotalTaxAmount = (quantity, rate, sgst, cgst, igst) => {
    const sgstAmount = (quantity * rate * sgst) / 100;
    const cgstAmount = (quantity * rate * cgst) / 100;
    const igstAmount = (quantity * rate * igst) / 100;
    return sgstAmount + cgstAmount + igstAmount;
  };

  // Function to calculate individual tax amounts (SGST, CGST, and IGST)
  const calculateIndividualTaxAmounts = (quantity, rate, sgst, cgst, igst) => {
    const sgstAmount = (quantity * rate * sgst) / 100;
    const cgstAmount = (quantity * rate * cgst) / 100;
    const igstAmount = (quantity * rate * igst) / 100;
    return { sgstAmount, cgstAmount, igstAmount };
  };

  useEffect(() => {
    form.setFieldsValue(data);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleValuesChange = (changedValues, allValues) => {
    const { quantity, rate, sgst, cgst, igst } = allValues;

    // Check if all necessary fields are available
    if (quantity && rate && (sgst || sgst === 0) && (cgst || cgst === 0) && (igst || igst === 0)) {
      const total = calculateTotal(quantity, rate, sgst, cgst, igst);
      const tax_amount = calculateTotalTaxAmount(quantity, rate, sgst, cgst, igst);
      const taxable_amount = total - tax_amount;

      // Get individual tax amounts
      const { sgstAmount, cgstAmount, igstAmount } = calculateIndividualTaxAmounts(quantity, rate, sgst, cgst, igst);

      // Set the form fields with the calculated values
      form.setFieldsValue({
        total,
        taxable_amount,
        tax_amount,
        sgst_amount: sgstAmount,
        cgst_amount: cgstAmount,
        igst_amount: igstAmount
      });
    }
  };

  console.log('Modal Add Form', data)

  return (
    <Form
      form={form}
      name={config?.id}
      layout="vertical"
      onFinish={handleFinish}
      onValuesChange={handleValuesChange}
      initialValues={data}
      style={styles.form}
      scrollToFirstError
      disabled={disabled ? disabled : loading}
    >
      <Row gutter={24}>
        {config?.components.map((component) => (
          <Col span={component?.span} key={component?.name}>
            <FormElement
              {...component}
              values={values}
              form={form}
              modals={modals}
              onFieldChange={onFieldChange?.[component?.name]}
            />
          </Col>
        ))}

        {children}
      </Row>

      <Space style={styles.footer}>
        <Button onClick={onCancel}>Cancel</Button>

        <Button type="primary" htmlType="submit" loading={submitting}>
          Save
        </Button>
      </Space>
    </Form>
  );
};

const styles = {
  pageHeader: {
    padding: 0,
    paddingBottom: 24,
  },
  form: {
    width: "100%",
  },
  footer: {
    marginTop: 12,
  },
};

export default ModalAddForm;
