export default {
  slugs: {
    singular: "app-email",
    plural: "app-emails",
  },
  entityNames: {
    singular: "Email",
    plural: "Emails",
  },
};
