import React from "react";
import { Route } from "react-router-dom";
import {
  AgencyList,
  AgencyAdd,
  AgencyEdit,
  AgencyReport,
} from "../pages/Agencies";
import AgencyView from "../pages/Agencies/View";
import AgencyLedger from "../pages/AgenciesLedger/Report";
import AgencyBoqList from "../pages/AgencyBoq/List";
import AgencyBoqAdd from "../pages/AgencyBoq/Add";
import AgencyBoqEdit from "../pages/AgencyBoq/Edit";
import AgencyBoqView from "../pages/AgencyBoq/View";

export const register = (props) => {
  const basePath = "agencies";

  return (
    <>
      <Route path={basePath} element={<AgencyList />} exact={true} />
      <Route path={`${basePath}/add`} element={<AgencyAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<AgencyEdit />} />
      <Route path={`${basePath}/view/:id`} element={<AgencyView />} />
      <Route path={`${basePath}-report`} element={<AgencyReport />} />
      <Route path={`${basePath}-ledger-report`} element={<AgencyLedger />} />
      <Route path={`${basePath}-boq`} element={<AgencyBoqList />} />
      <Route path={`${basePath}-boq/add`} element={<AgencyBoqAdd />} />
      <Route path={`${basePath}-boq/edit/:id`} element={<AgencyBoqEdit />} />
      <Route path={`${basePath}-boq/view/:id`} element={<AgencyBoqView />} />
    </>
  );
};
