import React from "react";
import { Route } from "react-router-dom";
import {
  AgencyQuotationComparisonList,
  AgencyQuotationComparisonView,
} from "../pages/AgencyQuotationComparisonLists";

export const register = (props) => {
  const basePath = "agency-quotations-comparison-lists";

  return (
    <>
      <Route
        path={basePath}
        element={<AgencyQuotationComparisonList />}
        exact={true}
      />
      <Route
        path={`${basePath}/view/:id`}
        element={<AgencyQuotationComparisonView />}
      />
    </>
  );
};
