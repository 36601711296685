import config from "../config";

export default {
  id: `${config?.slugs?.plural}-list`,
  pageTitle: config?.entityNames?.plural,
  breadcrumbs: [
    {
      path: "/quick-talks/dashboard",
      title: "Home",
    },
    {
      path: "",
      title: config?.entityNames?.plural,
    },
  ],
  columns: [
    {
      title: "Meeting",
      dataIndex: "meeting",
      selectable: true,
      references: {
        fieldName: "name",
      },
    },
    {
      title: "Employee",
      dataIndex: "employee",
      selectable: true,
      filterable: true,
      references: {
        fieldName: (record) => `${record?.first_name} ${record?.last_name}`,
      },
    },
  ],
  ...config,
};
