import { axios } from "../../../../../App";
import config from "../config";

export const makeConfig = ({ navigate }) => ({
  id: `${config?.plural}-edit`,
  title: `Edit ${config?.entityNames?.singular}`,
  pageTitle: `Edit ${config?.entityNames?.singular}`,
  breadcrumbs: [
    {
      path: "/quick-teams/dashboard",
      title: "Home",
    },
    {
      path: `/quick-teams/${config?.slugs?.plural}`,
      title: config?.entityNames?.plural,
    },
    {
      path: "",
      title: `Edit ${config?.entityNames?.singular}`,
    },
  ],
  components: [
    {
      span: 12,
      component: "select",
      label: "Employee",
      name: "employee",
      required: true,
      placeholder: "Select employee",
      rules: [
        {
          required: true,
          message: "Employee is required!",
        },
      ],
    },
    {
      span: 12,
      component: "date",
      label: "Start Date",
      name: "start_date",
      required: true,
      placeholder: "Select start date",
      rules: [
        {
          required: true,
          message: "Start date is required!",
        },
      ],
    },
    {
      span: 12,
      component: "input",
      type: "number",
      label: "Amount",
      name: "amount",
      required: true,
      placeholder: "Enter amount",
      rules: [
        {
          required: true,
          message: "Amount is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Months",
      name: "months",
      required: true,
      placeholder: "Select months",
      rules: [
        {
          required: true,
          message: "Months is required!",
        },
      ],
    },
    {
      span: 12,
      component: "uploader",
      label: "Attachment",
      name: "attachment",
      required: true,
      placeholder: "Upload attachment",
      rules: [
        {
          required: true,
          message: "Attachment is required!",
        },
      ],
    },
    {
      span: 24,
      component: "editor",
      label: "Reason",
      name: "reason",
      placeholder: "Enter reason",
      rows: 5,
    },
    {
      span: 24,
      component: "input",
      type: "hidden",
      name: "attachment",
    },
  ],
  actions: {
    onBack: () => navigate(`/quick-teams/${config?.slugs?.plural}`),
    onFinish: async (values, data) => {
      try {
        let dataToSend = {
          ...values,
          start_date: values?.start_date?.add(1, "days"),
        };

        Object.keys(dataToSend).forEach((key) => {
          if (dataToSend?.[key] === "") {
            delete dataToSend[key];
          }

          if (dataToSend?.[key]?.data?.id) {
            dataToSend[key] = dataToSend?.[key]?.data?.id;
          }
        });

        const response = await axios.put(
          `/${config?.slugs?.plural}/${data?.id}`,
          {
            data: dataToSend,
          }
        );

        if (response?.data) {
          return {
            success: true,
            message: `Edit ${config?.entityNames?.singular?.toLowerCase()} successfully!`,
          };
        }

        throw new Error();
      } catch (error) {
        return {
          success: false,
          message: error?.response?.data?.error?.message,
        };
      }
    },
  },
  ...config,
});
