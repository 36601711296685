import config from "../config";

export const makeConfig = ({ navigate }) => ({
  id: `${config?.plural}-view`,
  title: `View ${config?.entityNames?.singular}`,
  pageTitle: `View ${config?.entityNames?.singular}`,
  breadcrumbs: [
    {
      path: "/account/dashboard",
      title: "Home",
    },
    {
      path: `/account/${config?.slugs?.plural}`,
      title: config?.entityNames?.plural,
    },
    {
      path: "",
      title: `View ${config?.entityNames?.singular}`,
    },
  ],
  components: [
    {
      span: 24,
      component: "input",
      label: "Title",
      name: "title",
      placeholder: "Enter title",
    },
    {
      span: 24,
      component: "editor",
      label: "Content",
      name: "content",
      placeholder: "Enter content",
      rows: 5,
    },
  ],
  actions: {
    onBack: () => navigate(`/account/${config?.slugs?.plural}`),
  },
  ...config,
});
