import React, { useState, useEffect } from "react";
import {
  PageHeader,
  Breadcrumb,
  Row,
  Col,
  Form,
  Space,
  Button,
  notification,
  Select,
  Input,
  DatePicker,
  InputNumber,
  Empty,
  Tooltip,
  Popconfirm,
  Alert,
} from "antd";
import FormElement from "../../../components/FormElement";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { makeConfig } from "./config";
import { fetchData } from "../../../../../utils/helpers/data.helper";
import { axios } from "../../../../../App";
import qs from "qs";
import moment from "moment";
import { DeleteOutlined } from "@ant-design/icons";
import { v4 } from "uuid";

const Errors = ({ errors }) => {
  if (errors?.length > 0) {
    return (
      <Col span={24}>
        <div style={styles.errors}>
          <h4 style={styles.errorsTitle}>Errors ({errors?.length})</h4>

          {errors?.map((message, index) => (
            <Alert
              key={index}
              message={message}
              type="error"
              style={styles.alert}
              showIcon
            />
          ))}
        </div>
      </Col>
    );
  }
};

const Page = (props) => {
  const navigate = useNavigate();
  const auth = useSelector((state) => state?.auth);
  const config = makeConfig({ navigate, auth });
  const [submitting, setSubmitting] = useState(false);
  const [form] = Form.useForm();
  const [allValues, setAllValues] = useState({});
  const [selectorValues, setSelectorValues] = useState({});
  const [tasks, setTasks] = useState([]);
  const [actualTasks, setActualTasks] = useState([]);
  const [issues, setIssues] = useState([]);
  const [errors, setErrors] = useState({
    tasks: [],
    issues: [],
  });
  const contentTypes = [
    {
      slug: "employees",
      transformer: (item) => ({
        value: item?.id,
        label: `${item?.first_name} ${item?.last_name}`,
      }),
      onSuccess: (data) =>
        setSelectorValues((prev) => ({
          ...prev,
          employee: data,
        })),
    },
  ];

  const handleValuesChange = (_, inputValues) => {
    setAllValues((prev) => ({
      ...prev,
      ...inputValues,
    }));
  };

  const handleFinish = async (values) => {
    setSubmitting(true);

    validateTasks();
    validateIssues();

    if (errors?.tasks?.length > 0 || errors?.issues?.length > 0) {
      setSubmitting(false);

      notification["error"]({
        message: config?.title,
        description: `Add new ${config?.entityNames?.singular?.toLowerCase()} failed!`,
      });
    } else {
      try {
        const response = await axios.post(`/${config?.slugs?.plural}`, {
          data: {
            ...values,
            uid: v4(),
            tasks: actualTasks,
            issues,
            // creator: auth?.user?.id,
          },
        });

        if (response?.data) {
          notification["success"]({
            message: config?.title,
            description: `Add new ${config?.entityNames?.singular?.toLowerCase()} successfully!`,
          });

          config?.actions?.onBack();
        } else {
          throw new Error();
        }
      } catch (error) {
        notification["error"]({
          message: config?.title,
          description: `Add new ${config?.entityNames?.singular?.toLowerCase()} failed!`,
        });
      } finally {
        setSubmitting(false);
      }
    }
  };

  const handleCancel = () => {};

  const fetchTasksByEmployeeId = async (id) => {
    try {
      const queryObject = {
        filters: {
          assignees: [id],
        },
      };
      const resp = await axios.get(`/tasks?${qs.stringify(queryObject)}`);

      if (resp?.data) {
        let newTasks = [];
        let newActualTasks = [];

        resp?.data?.data?.forEach((item) => {
          newTasks.push({
            ...item?.attributes,
            id: item?.id,
          });

          newActualTasks.push({
            id: item?.id,
            finished_date: moment(item?.end_date),
            completion_percentage: 0,
            completed: "No",
            notes: "",
          });
        });

        setTasks(newTasks);
        setActualTasks(newActualTasks);
      }
    } catch (error) {}
  };

  const onEditTask = (id, key, value) => {
    setActualTasks((prev) =>
      prev?.map((item) => {
        if (item?.id === id) {
          item[key] = value;
        }

        return item;
      })
    );
  };

  const onAddIssue = () => {
    setIssues((prev) =>
      prev?.concat({
        id: v4(),
        issue: "",
        risk: "",
        mitigation: "",
        notes: "",
      })
    );
  };

  const onEditIssue = (id, key, value) => {
    setIssues((prev) =>
      prev?.map((item) => {
        if (item?.id === id) {
          item[key] = value;
        }

        return item;
      })
    );
  };

  const onRemoveIssue = (id) => {
    setIssues((prev) => prev?.filter((item) => item?.id !== id));

    notification["success"]({
      message: `Remove Issue`,
      description: `Remove issue successfully!`,
    });
  };

  const validateTasks = () => {
    if (actualTasks?.length > 0) {
      let results = [];

      for (let i = 0; i < actualTasks?.length; i++) {
        if (!actualTasks[i]?.finished_date) {
          results.push(`[TASK-${i + 1}] Finished date is required!`);
        }
      }

      setErrors((prev) => ({
        ...prev,
        tasks: results,
      }));
    }
  };

  const validateIssues = () => {
    if (issues?.length > 0) {
      let results = [];

      for (let i = 0; i < issues?.length; i++) {
        if (issues[i]?.issue === "") {
          results.push(`[Issue No. ${i + 1}] Project issue is required!`);
        }

        if (issues[i].risk === "") {
          results.push(`[Issue No. ${i + 1}] Risk is required!`);
        }

        if (issues[i].mitigation === "") {
          results.push(`[Issue No. ${i + 1}] Mitigation is required!`);
        }
      }

      setErrors((prev) => ({
        ...prev,
        issues: results,
      }));
    }
  };

  useEffect(() => {
    contentTypes?.forEach((contentType) => {
      fetchData(contentType);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (allValues?.employee) {
      fetchTasksByEmployeeId(allValues?.employee);
    }
  }, [allValues?.employee]);

  return (
    <>
      <Helmet>
        <title>{config?.pageTitle}</title>
      </Helmet>

      <Breadcrumb style={styles.breadcrumbs}>
        {config?.breadcrumbs?.map((breadcrumb, index) => (
          <Breadcrumb.Item key={index}>
            <Link to={breadcrumb?.path}>{breadcrumb?.title}</Link>
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>

      <PageHeader
        title={config?.title}
        style={styles.pageHeader}
        onBack={config?.actions?.onBack}
      ></PageHeader>

      <Form
        form={form}
        name={config?.id}
        layout="vertical"
        onFinish={handleFinish}
        // initialValues={data}
        style={styles.form}
        scrollToFirstError
        // disabled={loading}
        onValuesChange={handleValuesChange}
      >
        <Row gutter={24}>
          <Col span={12}>
            <FormElement
              {...config?.components?.find((item) => item?.name === "employee")}
              values={selectorValues}
              form={form}
            />
          </Col>

          <Col span={12}>
            <FormElement
              {...config?.components?.find((item) => item?.name === "date")}
              values={selectorValues}
              form={form}
            />
          </Col>

          <Col span={24}>
            <FormElement
              {...config?.components?.find((item) => item?.name === "details")}
              values={selectorValues}
              form={form}
            />
          </Col>

          <Col span={24}>
            <h3 style={styles.title}>Tasks</h3>

            <table className="public-form-table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th style={{ width: "20%" }}>Task</th>
                  <th style={{ textAlign: "center" }}>Estimated Date</th>
                  <th style={{ textAlign: "center" }}>Finished Date</th>
                  <th style={{ textAlign: "center" }}>
                    Percentage of Completion
                  </th>
                  <th style={{ textAlign: "center" }}>Completed?</th>
                  <th style={{ width: "30%", textAlign: "left" }}>Notes</th>
                </tr>
              </thead>

              <tbody>
                {tasks?.length === 0 && (
                  <tr>
                    <td colSpan={7}>
                      <div style={styles.loaderContainer}>
                        <Empty />
                      </div>
                    </td>
                  </tr>
                )}

                {tasks?.length > 0 &&
                  tasks?.map((task, index) => (
                    <tr key={task?.id}>
                      <td style={{ fontWeight: "bold" }}>TASK-{task?.id}</td>
                      <td>{task?.name}</td>
                      <td style={{ textAlign: "center" }}>
                        {moment(task?.end_date).format("DD-MM-YYYY")}
                      </td>
                      <td>
                        <DatePicker
                          style={{ width: "100%" }}
                          defaultValue={moment(task?.end_date)}
                          value={actualTasks?.[index]?.finished_date}
                          onChange={(value) =>
                            onEditTask(task?.id, "finished_date", value)
                          }
                        />
                      </td>
                      <td>
                        <InputNumber
                          min={0}
                          defaultValue={0}
                          placeholder="Enter percentage"
                          value={actualTasks?.[index]?.completion_percentage}
                          onChange={(value) =>
                            onEditTask(task?.id, "completion_percentage", value)
                          }
                          style={{ width: "100%", padding: 3 }}
                        />
                      </td>
                      <td>
                        <Select
                          defaultValue="No"
                          style={{ width: "100%" }}
                          value={actualTasks?.[index]?.completed}
                          onChange={(value) =>
                            onEditTask(task?.id, "completed", value)
                          }
                          options={[
                            {
                              value: "No",
                              label: "No",
                            },
                            {
                              value: "Yes",
                              label: "Yes",
                            },
                          ]}
                        />
                      </td>
                      <td>
                        <Input
                          placeholder="Enter notes"
                          value={actualTasks?.[index]?.notes}
                          onChange={(e) =>
                            onEditTask(task?.id, "notes", e.target.value)
                          }
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </Col>

          <Errors errors={errors?.tasks} />

          <Col span={24}>
            <div style={styles.header}>
              <h3 style={styles.title}>
                Project Risks, Issues, and Mitigation Plans
              </h3>

              <Space>
                <Button type="primary" onClick={onAddIssue}>
                  New Item
                </Button>
              </Space>
            </div>

            <table className="public-form-table">
              <thead>
                <tr>
                  <th>No.</th>
                  <th>Project Issue</th>
                  <th style={{ width: "20%", textAlign: "left" }}>Risk</th>
                  <th style={{ width: "20%", textAlign: "left" }}>
                    Mitigation
                  </th>
                  <th style={{ width: "20%", textAlign: "left" }}>Notes</th>
                  <th>Actions</th>
                </tr>
              </thead>

              <tbody>
                {issues?.length === 0 && (
                  <tr>
                    <td colSpan={7}>
                      <div style={styles.loaderContainer}>
                        <Empty />
                      </div>
                    </td>
                  </tr>
                )}

                {issues?.length > 0 &&
                  issues?.map((issue, index) => (
                    <tr key={issue?.id}>
                      <td style={{ fontWeight: "bold" }}>{index + 1}</td>
                      <td>
                        <Input
                          placeholder="Enter project issue"
                          value={issue?.issue}
                          onChange={(e) =>
                            onEditIssue(issue?.id, "issue", e.target.value)
                          }
                        />
                      </td>
                      <td>
                        <Input
                          placeholder="Enter risk"
                          value={issue?.risk}
                          onChange={(e) =>
                            onEditIssue(issue?.id, "risk", e.target.value)
                          }
                        />
                      </td>
                      <td>
                        <Input
                          placeholder="Enter mitigation"
                          value={issue?.mitigation}
                          onChange={(e) =>
                            onEditIssue(issue?.id, "mitigation", e.target.value)
                          }
                        />
                      </td>
                      <td>
                        <Input
                          placeholder="Enter notes"
                          value={issue?.notes}
                          onChange={(e) =>
                            onEditIssue(issue?.id, "notes", e.target.value)
                          }
                        />
                      </td>
                      <td>
                        <Popconfirm
                          title={`Are you sure to remove this item?`}
                          okText="Yes"
                          cancelText="No"
                          onConfirm={() => onRemoveIssue(issue?.id)}
                        >
                          <Tooltip placement="bottom" title={`Remove Item`}>
                            <Button type="danger" icon={<DeleteOutlined />} />
                          </Tooltip>
                        </Popconfirm>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </Col>

          <Errors errors={errors?.issues} />
        </Row>

        <Space style={styles.buttons}>
          <Space>
            <Button onClick={handleCancel}>Cancel</Button>

            <Button type="primary" htmlType="submit" loading={submitting}>
              Save
            </Button>
          </Space>
        </Space>
      </Form>
    </>
  );
};

const styles = {
  pageHeader: {
    padding: 0,
    paddingBottom: 24,
  },
  form: {
    width: "100%",
  },
  buttons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 16,
  },
  loaderContainer: {
    textAlign: "center",
    padding: 12,
  },
  header: {
    marginBottom: 12,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  errors: {
    marginBottom: 24,
  },
  errorsTitle: {
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: 6,
  },
  alert: {
    marginBottom: 6,
  },
};

export default Page;
