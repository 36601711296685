import config from "../config";

const commify = (x) => {
  return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export default {
  id: `${config?.slugs?.plural}-list`,
  pageTitle: config?.entityNames?.plural,
  breadcrumbs: [
    {
      path: "/quick-stocks/dashboard",
      title: "Home",
    },
    {
      path: "",
      title: config?.entityNames?.plural,
    },
  ],
  columns: [
    {
      title: "Heading",
      dataIndex: "heading",
      selectable: true,
      sorter: (a, b) => a?.heading?.localeCompare(b?.heading),
      width: 200,
    },
    {
      title: "Project",
      dataIndex: "project",
      selectable: true,
      references: {
        fieldName: "name",
      },
    },
    {
      title: "Vendor",
      dataIndex: "vendor",
      selectable: true,
      references: {
        fieldName: "name",
      },
    },
    {
      title: "Grand Total",
      dataIndex: "grand_total",
      selectable: true,
      render: (text) => commify(text || 0),
      sorter: (a, b) => a?.grand_total - b?.grand_total,
    },
  ],
  ...config,
};
