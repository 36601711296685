import React, { useEffect, useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { makeConfig } from "./config";
import { axios } from "../../../../../App";
import { Helmet } from "react-helmet";
import { PageHeader, Breadcrumb, Empty, Spin } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { updateUnreadCount } from "../../../../../redux/actions/notificationActions";
import classes from "./styles.module.css";

const Page = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [hasData, setHasData] = useState(false);
  const config = makeConfig({ navigate });
  const { user } = useSelector((state) => state?.auth);
  const dispatch = useDispatch();

  const fetchUnreadNotifications = async () => {
    try {
      const response = await axios.post(`/notifications/count/unread`, {
        recipient_uid: user?.uid,
      });

      if (response?.data) {
        dispatch(updateUnreadCount(response?.data?.data?.count));
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const response = await axios.post(
          `/${config?.slugs?.plural}/details/${id}`,
          {
            recipient_uid: user?.uid,
          }
        );

        if (response?.data) {
          setData(response?.data?.data);
          setHasData(true);

          fetchUnreadNotifications();
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  console.log(data)

  return (
    <>
      <Helmet>
        <title>{config?.pageTitle}</title>
      </Helmet>

      <Breadcrumb style={styles.breadcrumbs}>
        {config?.breadcrumbs?.map((breadcrumb, index) => (
          <Breadcrumb.Item key={index}>
            <Link to={breadcrumb?.path}>{breadcrumb?.title}</Link>
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>

      <PageHeader
       title={<div style={styles.title}>{data?.title}</div>}
        style={styles.pageHeader}
        onBack={config?.actions?.onBack}
      ></PageHeader>

      {loading ? (
        <div style={styles.loaderContainer}>
          <Spin />
        </div>
      ) : (
        <>
          {hasData ? (
            <div
              dangerouslySetInnerHTML={{
                __html: data?.content || "N/A",
              }}
              className={classes.htmlContainer}
            ></div>
          ) : (
            <Empty />
          )}
        </>
      )}
    </>
  );
};

const styles = {
  pageHeader: {
    padding: 0,
  },
  title: {
    whiteSpace: 'normal', // Allows text to wrap
    overflow: 'hidden',
    textOverflow: 'ellipsis', // Optional: adds ellipsis for overflowing text
    wordWrap: 'break-word', // Optional: ensures words break if too long
  },
  form: {
    width: "100%",
  },
  loaderContainer: {
    textAlign: "center",
    padding: 24,
  },
};

export default Page;
