import config from "../config";

export const makeConfig = ({ navigate }) => ({
  id: `${config?.plural}-view`,
  title: `View ${config?.entityNames?.singular}`,
  pageTitle: `View ${config?.entityNames?.singular}`,
  breadcrumbs: [
    {
      path: "/quick-talks/dashboard",
      title: "Home",
    },
    {
      path: `/quick-talks/${config?.slugs?.plural}`,
      title: config?.entityNames?.plural,
    },
    {
      path: "",
      title: `View ${config?.entityNames?.singular}`,
    },
  ],
  components: [
    {
      span: 24,
      component: "input",
      label: "Name",
      name: "name",
      required: true,
      placeholder: "Enter name",
      rules: [
        {
          required: true,
          message: "Name is required!",
        },
      ],
    },
    {
      span: 24,
      component: "select",
      label: "Members",
      name: "members",
      placeholder: "Select employees",
      required: true,
      rules: [
        {
          required: true,
          message: "Members is required!",
        },
      ],
      mode: "multiple",
    },
    {
      span: 24,
      component: "input",
      label: "URL",
      name: "url",
      placeholder: "Enter URL",
    },
    {
      span: 12,
      component: "datetime",
      label: "Time",
      name: "time",
      required: true,
      placeholder: "Select date & time",
      rules: [
        {
          required: true,
          message: "Time is required!",
        },
      ],
    },
    {
      span: 24,
      component: "editor",
      label: "Description",
      name: "description",
      placeholder: "Enter description",
      rows: 5,
    },
  ],
  actions: {
    onBack: () => navigate(`/quick-talks/${config?.slugs?.plural}`),
  },
  ...config,
});
