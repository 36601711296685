import React from "react";
import { Route } from "react-router-dom";
import {
  VendorList,
  VendorAdd,
  VendorEdit,
  VendorReport,
} from "../pages/Vendors";
import VendorsLedger from "../pages/VendorsLedger/Report";
import VendorsBoqList from "../pages/vendorsBoq/List";
import VendorBoqAdd from "../pages/vendorsBoq/Add";
import VendorBoqEdit from "../pages/vendorsBoq/Edit";
import VendorsBoqView from "../pages/vendorsBoq/View";

export const register = (props) => {
  const basePath = "vendors";

  return (
    <>
      <Route path={basePath} element={<VendorList />} exact={true} />
      <Route path={`${basePath}/add`} element={<VendorAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<VendorEdit />} />
      <Route path={`${basePath}-report`} element={<VendorReport />} />
      <Route path={`${basePath}-ledger-report`} element={<VendorsLedger />} />
      <Route path={`${basePath}-boq`} element={<VendorsBoqList />} />
      <Route path={`${basePath}-boq/add`} element={<VendorBoqAdd />} />
      <Route path={`${basePath}-boq/edit/:id`} element={<VendorBoqEdit />} />
      <Route path={`${basePath}-boq/view/:id`} element={<VendorsBoqView />} />

    </>
  );
};
