export const makeConfig = ({ navigate }) => ({
  id: `sub-contractor-quotation-form`,
  title: `Sub Contractor Quotation`,
  pageTitle: `Sub Contractor Quotation Form`,
  components: [
    {
      span: 12,
      component: "select",
      label: "Sub Contractor",
      name: "sub_contractor",
      placeholder: "Select sub contractor",
      required: true,
      rules: [
        {
          required: true,
          message: "Sub contractor is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Project",
      name: "project",
      placeholder: "Select project",
      required: true,
      rules: [
        {
          required: true,
          message: "Project is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Delivery Address",
      name: "delivery_address",
      placeholder: "Select delivery address",
      required: true,
      rules: [
        {
          required: true,
          message: "Delivery address is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Requirement Form",
      name: "sc_requirement_form",
      placeholder: "Select requirement form",
      required: true,
      rules: [
        {
          required: true,
          message: "Requirement form is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Urgency Level",
      name: "urgency_level",
      placeholder: "Select urgency level",
      required: true,
      rules: [
        {
          required: true,
          message: "Urgency level is required!",
        },
      ],
    },
    {
      span: 12,
      component: "hidden",
    },
  ],
  slugs: {
    singular: "sub-contractor-quotation",
    plural: "sub-contractor-quotations",
  },
  entityNames: {
    singular: "Sub Contractor Quotation",
    plural: "Sub Contractor Quotations",
  },
});
