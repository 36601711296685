import moment from "moment";
import config from "../config";

export default {
  id: `${config?.slugs?.plural}-list`,
  pageTitle: config?.entityNames?.plural,
  breadcrumbs: [
    {
      path: "/quick-teams/dashboard",
      title: "Home",
    },
    {
      path: "",
      title: config?.entityNames?.plural,
    },
  ],
  columns: [
    {
      title: "Employee",
      dataIndex: "employee",
      selectable: true,
      filterable: true,
      references: {
        fieldName: (record) => `${record?.first_name} ${record?.last_name}`,
      },
    },
    {
      title: "Leave Type",
      dataIndex: "leave_type",
      selectable: true,
      filterable: true,
      references: {
        fieldName: "name",
      },
    },
    {
      title: "From Date",
      dataIndex: "from_date",
      selectable: true,
      sorter: (a, b) => moment(a.from_date).unix() - moment(b.from_date).unix(),
      render: (text) => <span>{moment(text).format("DD-MM-YYYY")}</span>,
      width: 250,
    },
    {
      title: "To Date",
      dataIndex: "to_date",
      selectable: true,
      sorter: (a, b) => moment(a.to_date).unix() - moment(b.to_date).unix(),
      render: (text) => <span>{moment(text).format("DD-MM-YYYY")}</span>,
      width: 250,
    },
    // {
    //   title: "Reason",
    //   dataIndex: "reason",
    //   selectable: true,
    //   sorter: (a, b) => a?.reason?.localeCompare(b?.reason),
    //   render: (text) =>
    //     text !== "" ? (
    //       <span
    //         className="html-cell"
    //         dangerouslySetInnerHTML={{ __html: text }}
    //       ></span>
    //     ) : (
    //       "N/A"
    //     ),
    // },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   selectable: true,
    //   render: (text) => <Tag>{text}</Tag>,
    // },
  ],
  ...config,
};
