import config from "../config";
import moment from "moment";
import { Tag } from "antd";

const commify = (x) => {
  return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export default {
  id: `${config?.slugs?.plural}-list`,
  pageTitle: `${config?.entityNames?.plural} Report`,
  code: "TASKS",
  breadcrumbs: [
    {
      path: "/quick-projects/dashboard",
      title: "Home",
    },
    {
      path: `/quick-projects/reports`,
      title: "Reports",
    },
    {
      path: "",
      title: `${config?.entityNames?.plural} Report`,
    },
  ],
  columns: [
    // {
    //   title: "No",
    //   dataIndex: "no",
    //   selectable: true,
    //   sorter: (a, b) => a?.no?.localeCompare(b?.no),
    //   width: 150,
    // },
    {
      title: "Name",
      dataIndex: "name",
      selectable: true,
      sorter: (a, b) => a?.name?.localeCompare(b?.name),
      width: 250,
    },
    {
      title: "Project",
      dataIndex: "project",
      selectable: true,
      references: {
        fieldName: "name",
      },
    },
    {
      title: "Employees",
      dataIndex: "employees",
      selectable: true,
      width: 250,
    },
    {
      title: "Milestone",
      dataIndex: "milestone",
      selectable: true,
      references: {
        fieldName: "name",
      },
    },
    // {
    //   title: "Start Date",
    //   dataIndex: "start_date",
    //   selectable: true,
    //   sorter: (a, b) =>
    //     moment(a.start_date).unix() - moment(b.start_date).unix(),
    //   render: (text) => <span>{moment(text).format("DD-MM-YYYY")}</span>,
    // },
    // {
    //   title: "Progress",
    //   dataIndex: "progress",
    //   selectable: true,
    //   render: (text) => commify(text || 0),
    //   sorter: (a, b) => a?.progress - b?.progress,
    // },
    // {
    //   title: "Estimated Budget",
    //   dataIndex: "estimated_budget",
    //   selectable: true,
    //   render: (text) => commify(text || 0),
    //   sorter: (a, b) => a?.estimated_budget - b?.estimated_budget,
    // },
    // {
    //   title: "Sanctioned Budget",
    //   dataIndex: "sanctioned_budget",
    //   selectable: true,
    //   render: (text) => commify(text || 0),
    //   sorter: (a, b) => a?.sanctioned_budget - b?.sanctioned_budget,
    // },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   selectable: true,
    //   render: (text) => <Tag>{text}</Tag>,
    // },
  ],
  ...config,
};
