import React, { useEffect, useState } from "react";
import { AddForm } from "../../../components";
import { useNavigate, useParams } from "react-router-dom";
import { makeConfig } from "./config";
import { axios } from "../../../../../App";
import { fetchData } from "../../../../../utils/helpers/data.helper";
import qs from "qs";

const Page = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    work_item: [],
    all_work_item: [],
  });
  const config = makeConfig({ navigate });
  const contentTypes = [
    {
      slug: "work-groups",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          work_group: data,
        })),
    },
    {
      slug: "work-items",
      query: {
        populate: "*",
      },
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          all_work_item: data,
        })),
    },
    {
      slug: "work-units",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          work_unit: data,
        })),
    },
    {
      slug: "agencies",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          agency: data,
        })),
    },
    {
      slug: "employees",
      transformer: (item) => ({
        value: item?.id,
        label: `${item?.first_name} ${item?.last_name}`,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          // approved_by: data,
        })),
    },
    {
      slug: "app-users",
      transformer: (item) => {
        if (item?.attributes?.user_type === "Employee") {
          return {
            value: item?.id,
            label: item?.attributes?.username,
          };
        }
      },
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          approved_by: data,
        })),
    },
  ];

  useEffect(() => {
    contentTypes?.forEach((contentType) => {
      fetchData(contentType);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const queryObject = {
          populate: "*",
        };

        const response = await axios.get(
          `/${config?.slugs?.plural}/${id}?${qs.stringify(queryObject)}`
        );

        if (response?.data) {
          setData({
            ...response?.data?.data?.attributes,
            id: response?.data?.data?.id,
            work_group: response?.data?.data?.attributes?.work_group?.data?.id,
            work_item: response?.data?.data?.attributes?.work_item?.data?.id,
            work_unit: response?.data?.data?.attributes?.work_unit?.data?.id,
            agency: response?.data?.data?.attributes?.agency?.data?.id,
            approved_by:
              response?.data?.data?.attributes?.approved_by?.data?.id,
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (data) {
      setValues((prev) => ({
        ...prev,
        work_item: values?.all_work_item
          ?.filter((item) => item?.work_group?.data?.id === data?.work_group)
          ?.map((item) => ({
            value: item?.id,
            label: item?.name,
          })),
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <AddForm
      config={config}
      data={data}
      loading={loading}
      values={values}
      onFieldChange={{
        work_group: (antForm) => {
          const workGroupId = antForm.getFieldValue("work_group");

          antForm.setFieldValue("work_item", undefined);

          if (!workGroupId) {
            setValues((prev) => ({
              ...prev,
              work_item: [],
            }));
          } else {
            setValues((prev) => ({
              ...prev,
              work_item: values?.all_work_item
                ?.filter((item) => item?.work_group?.data?.id === workGroupId)
                ?.map((item) => ({
                  value: item?.id,
                  label: item?.name,
                })),
            }));
          }
        },
      }}
    />
  );
};

export default Page;
