export default {
  slugs: {
    singular: "sub-contractor-payment-schedule",
    plural: "sub-contractor-payment-schedules",
  },
  entityNames: {
    singular: "Sub Contractor Payment Schedule",
    plural: "Sub Contractor Payment Schedules",
  },
};
