import React, { useState, useEffect } from "react";
import { Checkbox, Empty, Row, Col, Input, Select } from "antd";
import { APP_MODULES } from "./constants";
import { AiFillCaretDown } from "react-icons/ai";

const { Option } = Select;

const Card = ({
  permissions,
  item,
  onChange,
  expanded = false,
  onExpand = () => {},
}) => {
  const DEFAULT_PERMISSIONS = [
    {
      id: "read",
      label: "View",
    },
    {
      id: "update",
      label: "Update",
    },
  ];
  const itemScopes = item?.scopes || DEFAULT_PERMISSIONS;

  const getSwitchKey = (key, item, mode) => {
    return `${key?.toUpperCase()}.${
      item?.id?.toUpperCase() || item?.key?.toUpperCase()
    }.${mode?.toUpperCase()}`;
  };

  return (
    <div style={styles.cardContainer}>
      <div style={styles.cardHeader}>
        <div style={styles.cardLeft}>
          <h3 style={styles.cardTitle}>
            {item?.appModule?.name} - {item?.label}
          </h3>

          <p style={styles.cardDescription}>
            Define all allowed scopes for the <strong>{item?.label}</strong>{" "}
            feature of the <strong>{item?.appModule?.name}</strong> module.
          </p>
        </div>

        <div style={styles.cardRight}>
          <span style={styles.cardToggler} onClick={onExpand}>
            <AiFillCaretDown />
          </span>
        </div>
      </div>

      {expanded && (
        <div style={styles.cardContent}>
          {itemScopes?.map((itemScope, index) => (
            <div style={styles.cell}>
              <Checkbox
                key={index}
                checked={
                  permissions?.indexOf(
                    getSwitchKey(item?.appModule?.id, item, itemScope?.id)
                  ) > -1
                }
                onChange={(value) =>
                  onChange(
                    getSwitchKey(item?.appModule?.id, item, itemScope?.id),
                    value
                  )
                }
              >
                {itemScope?.label}
              </Checkbox>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const PermissionsTable = ({ rawItems, permissions, onChange }) => {
  const [searchValues, setSearchValues] = useState({
    appModule: "",
    keyword: "",
  });
  const [allItems, setAllItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [activeIndex, setActiveIndex] = useState(-1);

  const onExpand = (index) => {
    if (activeIndex === index) {
      setActiveIndex(-1);
    } else {
      setActiveIndex(index);
    }
  };

  useEffect(() => {
    let results = [];

    Object.keys(rawItems)?.map((key) => {
      rawItems?.[key]?.items?.forEach((item) => {
        if (item?.key !== "back-to-home") {
          item["appModule"] = {
            id: rawItems?.[key]?.id,
            name: rawItems?.[key]?.name,
          };

          results.push(item);
        }
      });
    });

    setAllItems(results);
    setFilteredItems(results);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let results = allItems
      ?.filter((item) => {
        if (searchValues?.appModule !== "") {
          return item?.appModule?.id === searchValues?.appModule;
        }

        return true;
      })
      ?.filter((item) => {
        if (searchValues?.keyword !== "") {
          return item?.label
            ?.toLowerCase()
            ?.includes(searchValues?.keyword?.toLowerCase());
        }

        return true;
      });

    setFilteredItems(results);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValues, allItems]);

  console.log('rawwww itemsss', rawItems, permissions, filteredItems)

  return (
    <div>
      <h2 style={styles.title}>Permissions</h2>

      <div className="toolbar">
        <Row gutter={16}>
          <Col span={10}>
            <label style={styles.label}>Module</label>
            <Select
              style={{ width: "100%" }}
              defaultValue=""
              onChange={(value) =>
                setSearchValues((prev) => ({
                  ...prev,
                  appModule: value,
                  keyword: "",
                }))
              }
            >
              <Option value="">All Modules</Option>

              {APP_MODULES?.map((appModule) => (
                <Option value={appModule?.id}>{appModule?.name}</Option>
              ))}
            </Select>
          </Col>

          <Col span={14}>
            <label style={styles.label}>Keyword</label>
            <Input
              value={searchValues?.keyword}
              placeholder="Search for keyword..."
              onChange={(e) =>
                setSearchValues((prev) => ({
                  ...prev,
                  keyword: e.target.value,
                }))
              }
            />
          </Col>
        </Row>
      </div>

      {filteredItems?.length <= 0 && (
        <div style={styles.emptyContainer}>
          <Empty />
        </div>
      )}

      {filteredItems?.length > 0 && (
        <div style={styles.cards}>
          {filteredItems?.map((item, index) => (
            <Card
              key={index}
              item={item}
              permissions={permissions}
              onChange={onChange}
              expanded={activeIndex === index}
              onExpand={() => onExpand(index)}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const styles = {
  emptyContainer: {
    textAlign: "center",
    padding: 24,
  },
  label: {
    display: "block",
  },
  cardContainer: {
    border: "1px solid rgb(246, 246, 249)",
    marginBottom: 24,
    borderRadius: 4,
    overflow: "hidden",
  },
  cardHeader: {
    background: "rgb(246, 246, 249)",
    padding: 24,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  cardTitle: {
    margin: 0,
    padding: 0,
  },
  cardDescription: {
    margin: 0,
    padding: 0,
    color: "#666687",
  },
  cardToggler: {
    // display: "inline-block",
    background: "rgb(220, 220, 228)",
    borderRadius: "50%",
    width: 32,
    height: 32,
    fontSize: 18,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  cardContent: {
    padding: 24,
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)",
  },
  title: {
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: 6,
  },
};

export default PermissionsTable;
