/* eslint-disable import/no-anonymous-default-export */
import config from "../config";
import moment from "moment";

export default {
  id: `${config?.slugs?.plural}-list`,
  pageTitle: `${config?.entityNames?.plural} Report`,
  code: "EMPLOYEES",
  breadcrumbs: [
    {
      path: "/quick-setup",
      title: "Home",
    },
    {
      path: "",
      title: `${config?.entityNames?.plural} Report`,
    },
  ],
  columns: [
    {
      title: "No",
      dataIndex: "no",
      selectable: true,
      sorter: (a, b) => a?.no?.localeCompare(b?.no),
      width: 150,
    },
    {
      title: "First Name",
      dataIndex: "first_name",
      selectable: true,
      filterable: true,
    },
    {
      title: "Middle Name",
      dataIndex: "middle_name",
      selectable: true,
      filterable: true,
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      selectable: true,
      filterable: true,
    },
    {
      title: "Gender",
      dataIndex: "gender",
      selectable: true,
      filterable: true,
    },
    {
      title: "Birthdate",
      dataIndex: "birthdate",
      selectable: true,
      sorter: (a, b) => moment(a.birthdate).unix() - moment(b.birthdate).unix(),
      render: (text) => <span>{moment(text).format("DD-MM-YYYY")}</span>,
    },
    {
      title: "Job Title",
      dataIndex: "job_title",
      selectable: true,
      filterable: true,
      references: {
        fieldName: "name",
      },
    },
    // {
    //   title: "City",
    //   dataIndex: "city",
    //   selectable: true,
    //   filterable: true,
    //   references: {
    //     fieldName: "name",
    //   },
    // },
    // {
    //   title: "State",
    //   dataIndex: "state",
    //   selectable: true,
    //   filterable: true,
    //   references: {
    //     fieldName: "name",
    //   },
    // },
  ],
  ...config,
};
