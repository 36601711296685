export default {
  slugs: {
    singular: "agency-quotation",
    plural: "agency-quotations",
  },
  entityNames: {
    singular: "Agency Work Order",
    plural: "Agency Work Orders",
  },
};
