import React from "react";
import { Route } from "react-router-dom";
import { RfiList, RfiAdd, RfiEdit, RfiReport } from "../pages/Rfis";

export const register = (props) => {
  const basePath = "rfis";

  return (
    <>
      <Route path={basePath} element={<RfiList />} exact={true} />
      <Route path={`${basePath}/add`} element={<RfiAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<RfiEdit />} />
      <Route path={`${basePath}-report`} element={<RfiReport />} />
    </>
  );
};
