import React, { useState, useEffect } from "react";
import { ReportDataTable } from "../../../components";
import { useNavigate } from "react-router-dom";
import { Space, Button, Row, Col, Input, Tooltip, DatePicker, Select } from "antd";
import qs from "qs";
import config from "./config";
import { axios } from "../../../../../App";
import _ from "lodash";
import { ReloadOutlined, DownloadOutlined } from "@ant-design/icons";
import moment from "moment";
import { useSelector } from "react-redux";
import { MAX_PAGINATION_LIMIT } from "../../../../../constants";
import { Bar, Column, Pie } from "@ant-design/plots";

const DATE_TIME_FORMAT = "YYYY-MM-DDTHH:mm:ss[Z]";

const Page = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    pageSize: 10,
  });
  const [total, setTotal] = useState(0);
  const [searchValues, setSearchValues] = useState({});
  const [searched, setSearched] = useState(false);
  const { user } = useSelector((state) => state?.auth);
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState("");

  const fetchProjects = async () => {
    try {
      const response = await axios.get(`projects`);

      if (response?.data) {
        let options = [
          {
            value: "",
            label: "All Projects",
          },
        ];

        response?.data?.data?.forEach((record) => {
          options?.push({
            value: record?.id,
            label: record?.attributes?.name,
          });
        });

        setProjects(options);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const processAnswersData = (data) => {
    let answeredCount = 0;
    let nonAnsweredCount = 0;


    data?.forEach((item) => {
      if (item?.once_answered) {
        answeredCount += 1;
      } else {
        nonAnsweredCount += 1;
      }
    });

    return [
      { type: 'Answered', value: answeredCount },
      { type: 'Non-Answered', value: nonAnsweredCount },
    ];
  };

  const processRepliedData = (data) => {
    return data
      .filter((item) => item.replied_date)
      .map((item, index) => {
        const createdDate = new Date(item.createdAt);
        const repliedDate = new Date(item.replied_date);
        const timeTaken = Math.round((repliedDate - createdDate) / (1000 * 60 * 60 * 24)); // Calculate days taken and round to nearest whole number

        console.log('time taken', timeTaken);

        return {
          name: `RFI ${data.length + index + 1}`,
          timeTaken: timeTaken,
        };
      });
  };


  const fetchData = async (params = null) => {
    try {
      setLoading(true);

      const queryObject = {
        populate: "*",
        sort: ["subject:asc"],
        // pagination: {
        //   limit: MAX_PAGINATION_LIMIT,
        // },
      };

      if (!_.isEmpty(params)) {
        queryObject["filters"] = params;
      }

      const response = await axios.get(
        `${config?.slugs?.plural}?${qs.stringify(queryObject)}`
      );

      if (response?.data) {
        setData(
          response?.data?.data?.map((item) => {
            return {
              id: item?.id,
              ...item?.attributes,
              employee: `${item?.attributes?.assigned_to?.data?.attributes?.first_name} ${item?.attributes?.assigned_to?.data?.attributes?.last_name}`,
              reply_person: `${item?.attributes?.verified_by?.data?.attributes?.first_name} ${item?.attributes?.verified_by?.data?.attributes?.last_name}`,
            };
          })
        );

        setTotal(response?.data?.meta?.pagination?.total);

        setPagination({
          ...response?.data?.meta?.pagination,
          pageSize: 10,
        });

      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const refreshData = () => {
    onSearch();
  };

  const downloadReport = () => {
    try {
      const data = {
        code: config?.code,
        params: {
          start_date: searchValues?.start_date
            ? searchValues?.start_date?.format("YYYY-MM-DD")
            : moment().subtract(1000, "years").format("YYYY-MM-DD"),
          end_date: searchValues?.end_date
            ? searchValues?.end_date?.format("YYYY-MM-DD")
            : moment().add(1000, "years").format("YYYY-MM-DD"),
        },
      };

      const link = document.createElement("a");
      link.href = `${process.env.REACT_APP_BASE_API_URL
        }/reporter?${qs.stringify(data)}`;

      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.log(error);
    }
  };

  const getColumnFilterProps = ({ dataIndex, references = {} }) => {
    if (typeof references?.fieldName === "function") {
      const transformer = references?.fieldName;

      return {
        render: (data) => transformer(data?.data?.attributes) || "N/A",
      };
    } else {
      return {
        render: (data) =>
          data?.data?.attributes?.[references?.fieldName] || "N/A",
      };
    }
  };

  const getColumnProps = (column) => {
    if (column?.references) {
      return getColumnFilterProps(column);
    }

    return column;
  };

  const onSearch = () => {
    setSearched(true);

    let filters = [
      {
        subject: {
          $containsi: searchValues?.keyword?.toLowerCase(),
        },
      },
      {
        question: {
          $containsi: searchValues?.keyword?.toLowerCase(),
        },
      },
      {
        assigned_to: {
          first_name: {
            $containsi: searchValues?.keyword?.toLowerCase(),
          }
        }
      },
      {
        answer: {
          $containsi: searchValues?.keyword?.toLowerCase(),
        },
      },
      {
        suggestion: {
          $containsi: searchValues?.keyword?.toLowerCase(),
        },
      },
    ];

    if (searchValues?.start_date && searchValues?.end_date) {
      filters.push({
        createdAt: {
          $gte: moment(searchValues?.start_date).format(DATE_TIME_FORMAT),
          $lte: moment(searchValues?.end_date).format(DATE_TIME_FORMAT),
        },
      });
    } else {
      if (searchValues?.start_date) {
        filters.push({
          createdAt: {
            $gte: moment(searchValues?.start_date).format(DATE_TIME_FORMAT),
          },
        });
      }

      if (searchValues?.end_date) {
        filters.push({
          createdAt: {
            $lte: moment(searchValues?.end_date).format(DATE_TIME_FORMAT),
          },
        });
      }

      if (selectedProject !== "") {
        filters.push({
          project: selectedProject,
        });
      }
    }

    fetchData({
      $or: filters,
    });
  };

  const onReset = () => {
    setSearchValues({});
    // fetchData(getFiltersForRoles());
    fetchData();
    setSearched(false);
  };

  const getResultsMessage = () => {
    if (!_.isEmpty(searchValues)) {
      return total > 0 ? (
        <span>
          <strong>{total}</strong> matched results
        </span>
      ) : (
        <span>No matched results</span>
      );
    }

    return (
      <span>
        <strong>{total}</strong> total records
      </span>
    );
  };

  const renderHeaderButtons = () => {
    let buttons = [
      <Tooltip placement="top" title="Refresh">
        <Button
          icon={<ReloadOutlined />}
          onClick={refreshData}
          loading={loading}
        />
      </Tooltip>,
    ];

    // if (data?.length > 0) {
    //   buttons.push(
    //     <Tooltip placement="top" title="Download Report">
    //       <Button icon={<DownloadOutlined />} onClick={downloadReport} />
    //     </Tooltip>
    //   );
    // }

    return buttons;
  };

  const getFiltersForRoles = () => {
    // const userType = user?.user_type?.toLowerCase()?.replace(/\s/i, "_");

    // if (user?.is_admin) {
    //   return {};
    // }

    // return {
    //   project: {
    //     [Pluralize(userType)]: {
    //       uid: user?.uid,
    //     },
    //   },
    // };

    return {};

    return {
      $or: [
        {
          assigned_to: {
            uid: user?.uid,
          },
        },
        {
          verified_by: {
            uid: user?.id,
          },
        },
        {
          sub_contractor: {
            uid: user?.uid,
          },
        },
      ],
    };
  };

  const RfiPieChart = () => {
    const chartData = processAnswersData(data);

    const pieConfig = {
      appendPadding: 10,
      data: chartData,
      angleField: 'value',
      colorField: 'type',
      radius: 0.9,
      label: {
        type: 'spider',
        labelHeight: 28,
        content: '{name}\n{percentage}',
      },
      interactions: [{ type: 'element-active' }],
      color: ['#CF6C58', '#2E943E', '#2E4494', '#4BC0C0'], // Custom colors
    };

    return <Pie {...pieConfig} />;
  };

  const RfiBarChart = () => {
    const chartData = processRepliedData(data);

    const barConfig = {
      data: chartData,
      xField: 'name',
      yField: 'timeTaken',
      seriesField: 'name',
      legend: false,
      meta: {
        timeTaken: { alias: 'Days Taken' },
        name: { alias: 'RFI' },
      },
      columnWidthRatio: 0.8,
      label: {
        position: 'middle',
        style: {
          fill: '#FFFFFF',
          opacity: 0.9,
        },
      },
      color: ['#CF6C58', '#2E943E', '#2E4494', '#4BC0C0'], // Custom colors
    };

    return <Column {...barConfig} />;
  };


  useEffect(() => {
    // fetchData(getFiltersForRoles());
    fetchData();
    fetchProjects()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log('RFI Data', data)

  return (
    <>
      <ReportDataTable
        data={data}
        config={{
          ...config,
          project_title: '',
          pie_title: '',
          columns: config?.columns?.map((column) => ({
            ...column,
            ...getColumnProps(column),
          })),
        }}
        loading={loading}
        pagination={pagination}
        actions={{
          onAdd: () => navigate(`/quick-projects/${config?.slugs?.plural}/add`),
          onRefresh: refreshData,
        }}
        Toolbar={
          <div className="toolbar">
            <Row gutter={16}>
              <Col span={10}>
                <Input
                  value={searchValues?.keyword}
                  placeholder="Search for keyword..."
                  onChange={(e) =>
                    setSearchValues((prev) => ({
                      ...prev,
                      keyword: e.target.value,
                    }))
                  }
                />
              </Col>

              <Col span={7}>
                <DatePicker
                  onChange={(value) =>
                    setSearchValues((prev) => ({
                      ...prev,
                      start_date: value,
                    }))
                  }
                  value={searchValues?.start_date}
                  placeholder="Select start date"
                  style={styles.datePicker}
                />
              </Col>

              <Col span={7}>
                <DatePicker
                  onChange={(value) =>
                    setSearchValues((prev) => ({
                      ...prev,
                      end_date: value,
                    }))
                  }
                  value={searchValues?.endDate}
                  placeholder="Select end date"
                  style={styles.datePicker}
                />
              </Col>
              <Col span={7}>
                <Select
                  value={selectedProject}
                  onChange={setSelectedProject}
                  options={projects}
                  style={{ width: "100%", marginTop: '20px' }}
                  placeholder="Select Project"
                />
              </Col>
            </Row>

            <div className="toolbar-buttons">
              <p className="toolbar-results">{getResultsMessage()}</p>

              <Space>
                <Button onClick={onReset}>Reset</Button>
                <Button type="primary" onClick={onSearch}>
                  Search
                </Button>
              </Space>
            </div>
          </div>
        }
        buttons={renderHeaderButtons()}
        onBack={() => navigate(`/quick-projects/reports`)}
      />

      <div id="pieChart">
        <div style={{ margin: '5rem 0', fontSize: '20px' }}>
          <p>Performance Review</p>
          <div style={{ border: '1px solid #E9E9E9', width: '60%', borderRadius: '10px', margin: '20px 0', padding: '10px' }}>
            <RfiPieChart />
          </div>
        </div>
      </div>

      <div id="barGraph">
        <div style={{ margin: '5rem 0', fontSize: '20px' }}>
          <p>Time Taken to Reply to RFIs</p>
          <div style={{ border: '1px solid #E9E9E9', width: '60%', borderRadius: '10px', margin: '20px 0', padding: '10px' }}>
            <RfiBarChart />
          </div>
        </div>
      </div>

    </>
  );
};

const styles = {
  datePicker: {
    width: "100%",
  },
};

export default Page;
