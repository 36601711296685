import config from "../config";

export default {
    id: `${config?.slugs?.plural}-list`,
    pageTitle: `${config?.entityNames?.plural} Report`,
    code: "QUOTATION REPORT",
    breadcrumbs: [
        {
            path: "/quick-stocks/dashboard",
            title: "Home",
        },
        {
            path: "",
            title: config?.entityNames?.plural,
        },
    ],
    columns: [
        {
            title: "Client Name",
            dataIndex: "name",
            selectable: true,
        },
        {
            title: "Quotation Number",
            dataIndex: "id",
            selectable: true,
        },
        {
            title: "Date",
            dataIndex: "date",
            selectable: true,
        },
        {
            title: "Delivery Date",
            dataIndex: "delivery_date",
            selectable: true,
        },
        {
            title: "Taxable Amount",
            dataIndex: "total_taxable_amount",
            selectable: true,
        },
        {
            title: "GST Amount",
            dataIndex: "gst",
            selectable: true,
        },
        {
            title: "total Amount",
            dataIndex: "total_amount",
            selectable: true,
        },
    ],
    ...config,
};
