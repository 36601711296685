export const makeConfig = ({ navigate }) => ({
  id: `agency-quotation-form`,
  title: `Agency Quotation`,
  pageTitle: `Agency Quotation Form`,
  components: [
    {
      span: 12,
      component: "select",
      label: "Agency",
      name: "agency",
      placeholder: "Select agency",
      required: true,
      rules: [
        {
          required: true,
          message: "Agency is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Project",
      name: "project",
      placeholder: "Select project",
      required: true,
      rules: [
        {
          required: true,
          message: "Project is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Delivery Address",
      name: "delivery_address",
      placeholder: "Select delivery address",
      required: true,
      rules: [
        {
          required: true,
          message: "Delivery address is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Requirement Form",
      name: "agency_requirement_form",
      placeholder: "Select requirement form",
      required: true,
      rules: [
        {
          required: true,
          message: "Requirement form is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Urgency Level",
      name: "urgency_level",
      placeholder: "Select urgency level",
      required: true,
      rules: [
        {
          required: true,
          message: "Urgency level is required!",
        },
      ],
    },
    {
      span: 12,
      component: "hidden",
    },
  ],
  slugs: {
    singular: "agency-quotation",
    plural: "agency-quotations",
  },
  entityNames: {
    singular: "Agency Quotation",
    plural: "Agency Quotations",
  },
});
