import React from "react";
import { Route } from "react-router-dom";
import { NotificationList, NotificationView } from "../pages/Notifications";

export const register = (props) => {
  const basePath = "notifications";

  return (
    <>
      <Route path={basePath} element={<NotificationList />} exact={true} />
      <Route path={`${basePath}/view/:id`} element={<NotificationView />} />
    </>
  );
};
