import React from "react";
import { Route } from "react-router-dom";
import {
  VendorTermList,
  VendorTermAdd,
  VendorTermEdit,
  VendorTermReport,
} from "../pages/VendorTerms";

export const register = (props) => {
  const basePath = "vendor-terms";

  return (
    <>
      <Route path={basePath} element={<VendorTermList />} exact={true} />
      <Route path={`${basePath}/add`} element={<VendorTermAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<VendorTermEdit />} />
      <Route path={`${basePath}-report`} element={<VendorTermReport />} />
    </>
  );
};
