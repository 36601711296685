import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import moment from "moment";
import {
  Empty,
  Input,
  Row,
  Col,
  Button,
  notification,
  DatePicker,
  InputNumber,
  Alert,
} from "antd";
import { axios } from "../../../../App";
import _ from "lodash";
import qs from "qs";
import { v4 } from "uuid";

const { TextArea } = Input;

const Errors = ({ errors }) => {
  if (errors?.length > 0) {
    return (
      <Col span={24}>
        <div style={styles.errors}>
          <h4 style={styles.errorsTitle}>Errors ({errors?.length})</h4>

          {errors?.map((message, index) => (
            <Alert
              key={index}
              message={message}
              type="error"
              style={styles.alert}
              showIcon
            />
          ))}
        </div>
      </Col>
    );
  }
};

const MainForm = ({ data = {}, materialRequisitionUid, vendorUid }) => {
  const [loading, setLoading] = useState(false);
  const [materialItemsRemarks, setMaterialItemsRemarks] = useState("");
  const [termsRemarks, setTermsRemarks] = useState("");
  const [paymentSchedulesRemarks, setPaymentSchedulesRemarks] = useState("");
  const [deliveryDate, setDeliveryDate] = useState();
  const [materialItemNotes, setMaterialItemNotes] = useState([]);
  const [grandTotalAmount, setGrandTotalAmount] = useState(0);
  const [errors, setErrors] = useState({
    material_items: [],
  });
  const [company, setcompany] = useState(null)

  const validateMaterialItems = () => {
    let results = [];

    for (let i = 0; i < materialItemNotes?.length; i++) {
      if (!materialItemNotes[i]?.amount) {
        results.push(`[Material Item No. ${i + 1}] Amount is required!`);
      }
    }

    return results;
  };

  const onSubmit = async () => {
    try {
      setLoading(true);

      const validationErrors = validateMaterialItems();

      if (validationErrors?.length > 0) {
        setErrors({
          material_items: validationErrors,
        });
      } else {
        setErrors({ material_items: [] });

        const queryObject = {
          filters: {
            vendor: {
              uid: vendorUid,
            },
            vendor_material_requisition: {
              uid: materialRequisitionUid,
            },
          },
        };

        const entryResp = await axios.get(
          `vendor-quotations?${qs.stringify(queryObject)}`
        );

        const vendor = data?.vendors?.data?.find(
          (item) => item?.attributes?.uid === vendorUid
        )?.id;

        const requestData = {
          uid: v4(),
          heading: data?.heading,
          vendor,
          vendor_material_requisition: data?.id,
          project: data?.project?.data?.id,
          delivery_address: data?.delivery_address?.data?.id,
          urgency_level: data?.urgency_level,
          terms: data?.terms,
          material_items: data?.material_items,
          payment_schedules: data?.payment_schedules,
          expected_delivery_date: data?.expected_delivery_date,
          delivery_date: deliveryDate?.format("YYYY-MM-DD"),
          material_item_notes: materialItemNotes,
          grand_total_amount: TotalAmount,
          material_items_remarks: materialItemsRemarks,
          terms_remarks: termsRemarks,
          payment_schedules_remarks: paymentSchedulesRemarks,
        };

        if (entryResp?.data?.meta?.pagination?.total === 0) {
          const response = await axios.post(`/vendor-quotations`, {
            data: requestData,
          });

          if (response?.data) {
            notification.success({
              message: "Save Quotation",
              description: "Save the quotation successfully!",
            });
          } else {
            throw new Error();
          }
        } else {
          const quotationId = entryResp?.data?.data?.[0]?.id;

          const response = await axios.put(
            `/vendor-quotations/${quotationId}`,
            {
              data: requestData,
            }
          );

          if (response?.data) {
            notification.success({
              message: "Save Quotation",
              description: "Save the quotation successfully!",
            });
          } else {
            throw new Error();
          }
        }
      }
    } catch (error) {
      notification.error({
        message: "Save Quotation",
        description: "Failed to save the quotation!",
      });
    } finally {
      setLoading(false);
    }
  };

  const onEditMaterialItemNote = (index, key, value) => {
    setMaterialItemNotes((prev) =>
      prev?.map((item, itemIndex) => {
        if (itemIndex === index) {
          // Update the specified key with the new value
          item[key] = value;

          // If updating labour or material cost, recalculate the amount
          if (key === "estimated_labour_cost" || key === "estimated_material_cost") {
            const labourCost = item["estimated_labour_cost"] || 0;
            const materialCost = item["estimated_material_cost"] || 0;
            item["amount"] = Number(labourCost) + Number(materialCost); // Set amount as sum of labour and material costs
          }
        }
        return item;
      })
    );
  };

  const getDeliveryAddress = (addressObject) => {
    return `${addressObject?.name} (${addressObject?.address_line_1}, ${addressObject?.address_line_2})`;
  };

  useEffect(() => {
    setDeliveryDate(moment());
  }, []);

  useEffect(() => {
    if (!_.isEmpty(data)) {
      setMaterialItemsRemarks(
        data?.quotation?.attributes?.material_items_remarks
      );
      setTermsRemarks(data?.quotation?.attributes?.terms_remarks);
      setPaymentSchedulesRemarks(
        data?.quotation?.attributes?.payment_schedules_remarks
      );

      if (data?.quotation?.attributes?.delivery_date) {
        setDeliveryDate(moment(data?.quotation?.attributes?.delivery_date));
      }

      if (data?.quotation?.attributes?.material_item_notes) {
        setMaterialItemNotes(data?.quotation?.attributes?.material_item_notes);
      } else {
        let newMaterialItemNotes = [];

        data?.material_items?.forEach((materialItem, index) => {
          newMaterialItemNotes.push({
            ...materialItem,
            amount: Number(materialItem?.estimated_labour_cost) + Number(materialItem?.estimated_material_cost),
            material_group: materialItem?.raw_data?.material_group,
            quantity: materialItem?.quantity
          });
        })

        // for (let i = 0; i < data?.material_items?.length; i++) {
        //   newMaterialItemNotes.push({
        //     amount: 0,
        //     total_amount: 0,
        //     material_group: 
        //   });
        // }

        setMaterialItemNotes(newMaterialItemNotes);
      }
    }
  }, [data]);

  useEffect(() => {
    let total = 0;

    data?.material_items?.forEach((materialItem, index) => {
      total += (materialItem?.quantity * materialItemNotes?.[index]?.amount) + materialItemNotes?.[index]?.estimated_labour_cost + materialItemNotes?.[index]?.estimated_material_cost;
    });

    console.log('total', total)

    setGrandTotalAmount(total);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.material_items, materialItemNotes]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`/projects/${data?.project?.data?.id}?populate=*`);

        if (response?.data) {
          setcompany(response.data);
        }
      } catch (error) {
        console.log(error);
      }
    };

    if (data?.project?.data?.id) {
      fetchData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.project?.data?.id]);

  const calculateAmount = (paymentSchedule, materialItemNotes) => {

    // Find the corresponding materialItemNote based on the material_group
    const materialItemNote = materialItemNotes?.find(
      (item) => item?.material_group === paymentSchedule?.material_group?.data?.id
    );

    console.log('calculating amount', materialItemNote, paymentSchedule)
    // If no corresponding materialItemNote is found, return 0
    if (!materialItemNote) {
      return 0;
    }

    // Calculate the amount based on percentage
    return (Number(paymentSchedule.amount_percentage) / 100) * (materialItemNote.amount * materialItemNote.quantity);
  };

  // You might also want to calculate the grand total including taxes dynamically
  const TotalAmount = data?.materialItems?.reduce((total, materialItem, index) => {
    const estimated_material_cost = Number(materialItemNotes?.[index]?.estimated_material_cost);
    const estimated_labour_cost = Number(materialItemNotes?.[index]?.estimated_labour_cost)
    const taxableAmount = materialItem?.quantity * (estimated_labour_cost + estimated_material_cost) || 0;
    const cqst = (materialItemNotes?.[index]?.cqst || 0) / 100;
    const sqst = (materialItemNotes?.[index]?.sqst || 0) / 100;
    const igst = (materialItemNotes?.[index]?.igst || 0) / 100;

    const totalWithTax =
      taxableAmount + taxableAmount * cqst + taxableAmount * sqst + taxableAmount * igst;

    return total + totalWithTax;
  }, 0);

  console.log('qutation', data, materialItemNotes)

  return (
    <div style={styles.container}>
      <Helmet>
        <title>Vendor Quotation</title>
      </Helmet>

      <div className="public-form-header">
        <table
          cellPadding={0}
          cellSpacing={0}
          style={{
            width: "100%",
            border: "0px solid #ddd",
            marginBottom: "10px",
          }}
        >
          <tr style={{ borderBottom: "1px solid #eee" }}>
            <td
              className="text-center"
              style={{
                background:
                  "url(/images/form-header-left.jpeg) no-repeat top left",
                backgroundSize: "80%",
                width: "30%",
              }}
            >
              <img
                title="QuickSO"
                src="/images/form-header-logo.png"
                alt="QuickSO"
                style={{
                  height: "75px",
                  width: "auto",
                  objectFit: "contain",
                  margin: "0 auto",
                }}
              />
            </td>

            <td
              style={{
                background:
                  "url(/images/form-header-right.jpeg) no-repeat top left",
                backgroundSize: "100%",
              }}
            >
              <table
                cellPadding={0}
                cellSpacing={0}
                style={{
                  width: "100%",
                  border: "0px solid #ddd",
                  marginBottom: "0px",
                }}
              >
                <tbody>
                  <tr>
                    <td colSpan={2} style={{ borderBottom: "2px dashed #ddd" }}>
                      <h1
                        className="text-left"
                        style={{
                          color: "#ffffff",
                          fontSize: "30px",
                          padding: "20px 20px",
                          textTransform: "uppercase",
                          margin: 0,
                        }}
                      >
                        VENDOR QUOTATION
                      </h1>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <h2
                        className="text-left"
                        style={{
                          color: "#ffffff",
                          fontSize: "16px",
                          padding: "10px 20px",
                          fontWeight: 300,
                        }}
                      >
                        Serial Number
                        <br />
                        <b>
                          PVQ-{materialRequisitionUid?.split("-")?.[0]}-
                          {vendorUid?.split("-")?.[0]}
                        </b>
                      </h2>
                    </td>

                    <td>
                      <h2
                        className="text-left"
                        style={{
                          color: "#ffffff",
                          fontSize: "16px",
                          padding: "10px 20px",
                          fontWeight: 300,
                        }}
                      >
                        Date
                        <br />
                        <b>{moment().format("DD-MM-YYYY, HH:mm")}</b>
                      </h2>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </table>
      </div>

      <div className="public-form-body">
        <div className="public-form-section">
          <h2 className="public-form-section-title">General Information</h2>

          <table className="public-form-general-info">
            {/* <tr>
              <td>Vendor</td>
              <td>
                {
                  data?.vendors?.data?.find(
                    (item) => item?.attributes?.uid === vendorUid
                  )?.attributes?.name
                }
              </td>
            </tr> */}

            <tr>
              <td>Company</td>
              <td>{company?.data?.attributes?.company?.data?.attributes?.name}</td>
            </tr>

            <tr>
              <td>Company Address</td>
              <td>{company?.data?.attributes?.company?.data?.attributes?.address_line_1}</td>
            </tr>

            <tr>
              <td>Delivery Address</td>
              <td>
                {getDeliveryAddress(data?.delivery_address?.data?.attributes) ||
                  "N/A"}
              </td>
            </tr>

            <tr>
              <td>Material Requisition</td>
              <td>{data?.heading}</td>
            </tr>

            <tr>
              <td>Urgency Level</td>
              <td>{data?.urgency_level}</td>
            </tr>

            <tr>
              <td>Expected Delivery Date</td>
              <td>
                {data?.expected_delivery_date
                  ? moment(data?.expected_delivery_date)?.format("DD-MM-YYYY")
                  : "N/A"}
              </td>
            </tr>

            <tr>
              <td>Delivery Date</td>
              <td>
                <DatePicker
                  style={{ width: "100%" }}
                  value={deliveryDate}
                  onChange={(date) => setDeliveryDate(date)}
                />
              </td>
            </tr>

            <tr>
              <td>Material Specifications</td>
              <td>
                <span
                  dangerouslySetInnerHTML={{
                    __html: data?.remarks,
                  }}
                />
              </td>
            </tr>
          </table>
        </div>

        {data?.materialItems?.length > 0 && (
          <div className="public-form-section">
            <h2 className="public-form-section-title">Material Items</h2>

            <div style={{ overflowX: "auto" }}>
              <table className="public-form-table">
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Work Group</th>
                    <th>Work Item</th>
                    <th>Quantity</th>
                    <th style={{ width: "10%" }}>Labour Cost(₹)</th>
                    <th style={{ width: "10%" }}>Material Cost(₹)</th>
                    <th style={{ width: "10%" }}>Amount(₹)</th>
                    <th style={{ width: "10%" }}>CGST (%)</th>
                    <th style={{ width: "10%" }}>SGST (%)</th>
                    <th style={{ width: "10%" }}>IGST (%)</th>
                    <th style={{ width: "10%" }}>Taxable Amount(₹)</th>
                    <th style={{ width: "10%" }}>Total with Tax(₹)</th>
                  </tr>
                </thead>

                <tbody>
                  {data?.materialItems?.length > 0 &&
                    data?.materialItems?.map((materialItem, index) => {
                      const taxableAmount =
                        materialItem?.quantity * materialItemNotes?.[index]?.amount || 0;
                      const cqst = (materialItemNotes?.[index]?.cqst || 0) / 100;
                      const sqst = (materialItemNotes?.[index]?.sqst || 0) / 100;
                      const igst = (materialItemNotes?.[index]?.igst || 0) / 100;

                      // Calculate the total with taxes
                      const totalWithTax =
                        taxableAmount +
                        taxableAmount * cqst +
                        taxableAmount * sqst +
                        taxableAmount * igst

                      return (
                        <tr key={materialItem?.id}>
                          <td className="text-center text-bold">{index + 1}</td>
                          <td>{materialItem?.material_group || "N/A"}</td>
                          <td>{materialItem?.material_item || "N/A"}</td>
                          <td>{materialItem?.quantity || 0}</td>
                          <td>
                            <InputNumber
                              min={0}
                              defaultValue={0}
                              placeholder="Enter Labour Cost"
                              value={materialItemNotes?.[index]?.estimated_labour_cost}
                              onChange={(value) =>
                                onEditMaterialItemNote(index, "estimated_labour_cost", value)
                              }
                              style={{ width: "100%", padding: 3 }}
                            />
                          </td>
                          <td>
                            <InputNumber
                              min={0}
                              defaultValue={0}
                              placeholder="Enter Material Cost"
                              value={materialItemNotes?.[index]?.estimated_material_cost}
                              onChange={(value) =>
                                onEditMaterialItemNote(index, "estimated_material_cost", value)
                              }
                              style={{ width: "100%", padding: 3 }}
                            />
                          </td>
                          <td>
                            <InputNumber
                              disabled
                              min={0}
                              defaultValue={0}
                              placeholder="Enter amount"
                              value={materialItemNotes?.[index]?.amount}
                              onChange={(value) =>
                                onEditMaterialItemNote(index, "amount", value)
                              }
                              style={{ width: "100%", padding: 3 }}
                            />
                          </td>
                          <td>
                            <InputNumber
                              min={0}
                              defaultValue={0}
                              placeholder="Enter CGST"
                              value={materialItemNotes?.[index]?.cqst}
                              onChange={(value) =>
                                onEditMaterialItemNote(index, "cqst", value)
                              }
                              style={{ width: "100%", padding: 3 }}
                            />
                          </td>
                          <td>
                            <InputNumber
                              min={0}
                              defaultValue={0}
                              placeholder="Enter SGST"
                              value={materialItemNotes?.[index]?.sqst}
                              onChange={(value) =>
                                onEditMaterialItemNote(index, "sqst", value)
                              }
                              style={{ width: "100%", padding: 3 }}
                            />
                          </td>
                          <td>
                            <InputNumber
                              min={0}
                              defaultValue={0}
                              placeholder="Enter IGST"
                              value={materialItemNotes?.[index]?.igst}
                              onChange={(value) =>
                                onEditMaterialItemNote(index, "igst", value)
                              }
                              style={{ width: "100%", padding: 3 }}
                            />
                          </td>
                          <td>{Number(taxableAmount).toLocaleString()}</td>
                          <td>{Number(totalWithTax).toLocaleString()}</td>
                        </tr>
                      );
                    })}

                  {data?.materialItems?.length === 0 && (
                    <tr>
                      <td colSpan={11} style={{ padding: 24 }}>
                        <Empty />
                      </td>
                    </tr>
                  )}

                  <tr>
                    <td
                      colSpan={11}
                      style={{
                        background: "#fbfbfb",
                        textAlign: "right",
                        fontWeight: "bold",
                      }}
                    >
                      Grand Total Amount (₹)
                    </td>

                    <td style={{ background: "#fbfbfb", fontWeight: "bold" }}>
                      {Number(TotalAmount).toLocaleString()}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <label style={styles.label}>Remarks</label>
            <TextArea
              placeholder="Enter Remarks"
              value={materialItemsRemarks}
              onChange={(e) => setMaterialItemsRemarks(e.target.value)}
              style={{ marginBottom: 24 }}
            />

            <Errors errors={errors?.material_items} />
          </div>
        )}

        {data?.terms?.length > 0 && (
          <div className="public-form-section">
            <h2 className="public-form-section-title">Terms & Conditions</h2>
            <div style={{ overflowX: "auto" }}>
              <table className="public-form-table">
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Heading</th>
                    <th>Material Group</th>
                    <th>Material Item</th>
                    {/* <th>Material Unit</th> */}
                    {/* <th style={{ textAlign: "left", width: "30%" }}>
                      Description
                    </th> */}
                  </tr>
                </thead>

                <tbody>
                  {data?.terms?.length > 0 &&
                    data?.terms?.map((term, index) => (
                      <tr key={term?.id}>
                        <td className="text-center text-bold">{index + 1}</td>
                        <td>{term?.heading || "N/A"}</td>
                        <td>{term?.material_group || "N/A"}</td>
                        <td>{term?.material_item || "N/A"}</td>
                        {/* <td>{term?.material_unit || "N/A"}</td> */}
                        {/* <td style={{ textAlign: "left" }}>
                          {term?.description
                            ?.replace(/<\/?p>/g, "")
                            ?.replace(/amp;/g, "") || "N/A"}
                        </td> */}
                      </tr>
                    ))}

                  {data?.terms?.length === 0 && (
                    <tr>
                      <td colSpan={6} style={{ padding: 24 }}>
                        <Empty />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            <label style={styles.label}>Remarks</label>
            <TextArea
              placeholder="Enter Remarks"
              value={termsRemarks}
              onChange={(e) => setTermsRemarks(e.target.value)}
              style={{ marginBottom: 24 }}
            />
          </div>
        )}

        {data?.paymentSchedules?.length > 0 && (
          <div className="public-form-section">
            <h2 className="public-form-section-title">Payment Schedules</h2>
            <div style={{ overflowX: "auto" }}>
              <table className="public-form-table">
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Name</th>
                    <th>Material Group</th>
                    <th style={{ textAlign: "left", width: "30%" }}>
                      Description
                    </th>
                    <th style={{ width: "20%", textAlign: "left" }}>
                      Amount (%)
                    </th>
                    <th style={{ width: "20%", textAlign: "left" }}>
                      Amount (₹)
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {data?.paymentSchedules?.map((paymentSchedule, index) => (
                    <tr key={paymentSchedule?.id}>
                      <td className="text-center text-bold">{index + 1}</td>
                      <td>{paymentSchedule?.name}</td>
                      <td>{paymentSchedule?.material_group?.data?.attributes?.name}</td>
                      <td style={{ textAlign: "left" }}>
                        {paymentSchedule?.description?.replace(/<\/?p>/g, "") || "N/A"}
                      </td>
                      <td style={{ textAlign: "left" }}>
                        {paymentSchedule?.amount_percentage}%
                      </td>
                      <td style={{ textAlign: "left" }}>
                        {calculateAmount(paymentSchedule, materialItemNotes)}
                      </td>
                    </tr>
                  ))}

                  {data?.paymentSchedules?.length === 0 && (
                    <tr>
                      <td colSpan={6} style={{ padding: 24 }}>
                        <Empty />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            <label style={styles.label}>Remarks</label>
            <TextArea
              placeholder="Enter Remarks"
              value={paymentSchedulesRemarks}
              onChange={(e) => setPaymentSchedulesRemarks(e.target.value)}
              style={{ marginBottom: 24 }}
            />
          </div>
        )}

        <div className="public-form-section" style={{ marginTop: 24 }}>
          <Button type="primary" onClick={onSubmit} loading={loading}>
            Save Quotation
          </Button>
        </div>

        <div className="public-form-section">
          <table style={{ width: "100%" }}>
            <tfoot>
              <tr>
                <td colSpan={2}>
                  <div
                    style={{
                      background:
                        "url(/images/form-line.png) no-repeat center center",
                      backgroundAttachment: "cover",
                      backgroundSize: "100%",
                      height: 42,
                      marginTop: 24,
                      marginBottom: 10,
                    }}
                  />
                </td>
              </tr>

              <tr className="text-center" style={{ fontSize: "14px" }}>
                <td className="text-left" style={{ padding: "5px 0px" }}>
                  <img
                    title="QuickSO"
                    src="/images/form-logo.png"
                    alt="QuickSO"
                    style={{
                      height: "50px",
                      width: "auto",
                    }}
                  />
                </td>
                <td
                  className="text-right"
                  style={{ padding: "5px 0px", fontSize: "16px" }}
                >
                  Copyright © 2022, All Rights Reserved by QuickSO
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    background: "white",
  },
  datePicker: {
    width: "100%",
  },
  errors: {
    marginBottom: 24,
  },
  errorsTitle: {
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: 6,
  },
  alert: {
    marginBottom: 6,
  },
  label: {
    fontWeight: "bold",
    display: "block",
    marginBottom: 6,
  },
};

export default MainForm;
