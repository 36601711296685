import { axios } from "../../../../../App";
import { v4 } from "uuid";
import config from "../config";

export const makeConfig = ({ navigate, auth }) => ({
  id: `${config?.slugs?.plural}-add`,
  title: `Add New ${config?.entityNames?.singular}`,
  pageTitle: `Add New ${config?.entityNames?.singular}`,
  breadcrumbs: [
    {
      path: "/quick-talks/dashboard",
      title: "Home",
    },
    {
      path: `/quick-talks/${config?.slugs?.plural}`,
      title: config?.entityNames?.plural,
    },
    {
      path: "",
      title: `Add New ${config?.entityNames?.singular}`,
    },
  ],
  components: [
    {
      span: 24,
      component: "input",
      label: "Subject",
      name: "subject",
      required: true,
      placeholder: "Enter subject",
      rules: [
        {
          required: true,
          message: "Subject is required!",
        },
      ],
    },
    {
      span: 24,
      component: "select",
      label: "Sender",
      name: "sender",
      required: true,
      placeholder: "Select sender",
      rules: [
        {
          required: true,
          message: "Sender is required!",
        },
      ],
    },
    {
      span: 24,
      component: "select",
      label: "Recipients",
      name: "recipients",
      placeholder: "Select recipients",
      required: true,
      rules: [
        {
          required: true,
          message: "Recipients is required!",
        },
      ],
      mode: "multiple",
    },
    {
      span: 24,
      component: "select",
      label: "CC Recipients",
      name: "cc_recipients",
      placeholder: "Select CC recipients",
      mode: "multiple",
    },
    {
      span: 24,
      component: "select",
      label: "BCC Recipients",
      name: "bcc_recipients",
      placeholder: "Select BCC recipients",
      mode: "multiple",
    },
    {
      span: 24,
      component: "editor",
      label: "Content",
      name: "content",
      placeholder: "Enter content",
      rows: 5,
      required: true,
      rules: [
        {
          required: true,
          message: "Content is required!",
        },
      ],
    },
    {
      span: 24,
      component: "uploader",
      label: "Attachment",
      name: "attachment",
      placeholder: "Upload attachment",
    },
    {
      span: 24,
      component: "input",
      type: "hidden",
      name: "attachment",
    },
    // {
    //   span: 12,
    //   component: "select",
    //   label: "Status",
    //   name: "status",
    //   placeholder: "Select status",
    //   required: true,
    //   rules: [
    //     {
    //       required: true,
    //       message: "Status is required!",
    //     },
    //   ],
    // },
  ],
  actions: {
    onBack: () => navigate(`/quick-talks/${config?.slugs?.plural}`),
    onFinish: async (values) => {
      try {
        let dataToSend = {
          ...values,
          uid: v4(),
          // attachment: values?.attachment !== "" ? values?.attachment : null,
          status: "Sent",
          // creator: auth?.user?.id,
        };

        Object.keys(dataToSend).forEach((key) => {
          if (dataToSend?.[key] === "") {
            delete dataToSend[key];
          }

          if (dataToSend?.[key]?.data?.id) {
            dataToSend[key] = dataToSend?.[key]?.data?.id;
          }
        });

        const response = await axios.post(`/${config?.slugs?.plural}`, {
          data: dataToSend,
        });

        if (response?.data) {
          return {
            success: true,
            message: `Add new ${config?.entityNames?.singular?.toLowerCase()} successfully!`,
          };
        }

        throw new Error();
      } catch (error) {
        return {
          success: false,
          message: `Add new ${config?.entityNames?.singular?.toLowerCase()} failed!`,
        };
      }
    },
    onSaveDraft: async (values) => {
      try {
        let dataToSend = {
          ...values,
          uid: v4(),
          // attachment: values?.attachment !== "" ? values?.attachment : null,
          // creator: auth?.user?.id,
          status: "Draft",
        };

        Object.keys(dataToSend).forEach((key) => {
          if (dataToSend?.[key] === "") {
            delete dataToSend[key];
          }

          if (dataToSend?.[key]?.data?.id) {
            dataToSend[key] = dataToSend?.[key]?.data?.id;
          }
        });

        const response = await axios.post(`/${config?.slugs?.plural}`, {
          data: dataToSend,
        });

        if (response?.data) {
          return {
            success: true,
            message: `Add new ${config?.entityNames?.singular?.toLowerCase()} successfully!`,
          };
        }

        throw new Error();
      } catch (error) {
        return {
          success: false,
          message: `Add new ${config?.entityNames?.singular?.toLowerCase()} failed!`,
        };
      }
    },
  },
});
