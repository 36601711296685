import React, { useState, useEffect } from "react";
import { fetchData } from "../../../../../utils/helpers/data.helper";
import { Col, Select } from "antd";
import ChartGenerator from "../../../../../components/ChartGenerator";
import DataTable from "./DataTable";
import { Stack, Box } from "@chakra-ui/react";
import {
  CHART_SCHEMAS,
  CHART_SLUGS,
  DATE_RANGES,
  PROJECT_COLUMNS,
  REPORTS,
} from "../constants";
import { axios } from "../../../../../App";
import FeaturedReports from "./FeaturedReports";
import styles from "./styles";

const { Option } = Select;

// TODO: Add filters value
// TODO: Add 'Apply' and 'Clear' button
// TODO: Reduce number of requests

const Main = (props) => {
  const [projects, setProjects] = useState([]);
  const [data, setData] = useState({});
  const [projectId, setProjectId] = useState(-1);
  const [periodId, setPeriodId] = useState(DATE_RANGES?.[0]?.value);

  const fetchProjects = () => {
    fetchData({
      slug: "projects",
      query: {
        populate: "*",
        sort: ["start_date:desc"],
        pagination: {
          page: 1,
          pageSize: 10,
        },
        filters: {
          status: "Active",
        },
      },
      onSuccess: (data) => setProjects(data),
    });
  };

  const fetchChartData = async (inputProjectId, inputPeriodId) => {
    let requests = [];
    let newData = {};
    let slug = "";

    for (let slug of Object.values(CHART_SLUGS)) {
      requests.push(
        axios.get(
          `/quick-stock-dashboard/${slug}?project_id=${inputProjectId}&periodId=${inputPeriodId}`
        )
      );
    }

    const response = await Promise.all(requests);

    for (let i = 0; i < Object.values(CHART_SLUGS)?.length; i++) {
      slug = Object.values(CHART_SLUGS)?.[i];

      newData[slug] = response[i]?.data;
    }

    setData(newData);
  };

  useEffect(() => {
    fetchProjects();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchChartData(projectId, periodId);
  }, [projectId, periodId]);

  return (
    <div style={styles.container}>
      {/* <Col span={24}>
        <div style={styles.filters}>
          <Stack direction={{ base: "column", md: "row", lg: "row" }}>
            <Stack
              direction={{ base: "column", md: "row", lg: "row" }}
              align={{ base: "stretch", md: "center", lg: "center" }}
            >
              <label>Project</label>

              <Select
                style={{ minWidth: 180, width: "100%" }}
                placeholder="Select Project"
                value={projectId}
                onChange={setProjectId}
              >
                <Option value={-1}>All Projects</Option>

                {projects?.map((project) => (
                  <Option value={project?.id}>{project?.name}</Option>
                ))}
              </Select>
            </Stack>

            <Stack
              direction={{ base: "column", md: "row", lg: "row" }}
              align={{ base: "stretch", md: "center", lg: "center" }}
            >
              <label style={{ minWidth: 60, width: "100%" }}>Date Range</label>

              <Select
                value={periodId}
                style={{ minWidth: 180, width: "100%" }}
                placeholder="Select Date Range"
                onChange={setPeriodId}
              >
                {DATE_RANGES.map((dateRange) => (
                  <Option value={dateRange?.value}>{dateRange?.label}</Option>
                ))}
              </Select>
            </Stack>
          </Stack>
        </div>
      </Col> */}

      <Col span={24}>
        <Stack direction={{ base: "column", md: "row", lg: "row" }}>
          <Box w={{ base: "auto", md: "100%" }}>
            <Col>
              <FeaturedReports />
            </Col>
          </Box>
        </Stack>
      </Col>

      <Stack direction={{ base: "column", md: "row", lg: "row" }}>
        <Box w={{ base: "auto", md: `${100 / 2}%` }}>
          <Col>
            <ChartGenerator
              type="speedometer"
              schema={CHART_SCHEMAS.MATERIAL_PROCUREMENT}
              data={data?.[CHART_SLUGS.MATERIAL_PROCUREMENT]}
            />
          </Col>
        </Box>

        <Box w={{ base: "auto", md: `${100 / 2}%` }}>
          <Col>
            <ChartGenerator
              type="numbers"
              color="#000"
              schema={CHART_SCHEMAS.STATISTICS}
              data={data?.[CHART_SLUGS.STATISTICS]}
            />
          </Col>
        </Box>

        {/* <Box w={{ base: "auto", md: `${100 / 3}%` }}>
          <Col>
            <ChartGenerator
              type="pie"
              schema={CHART_SCHEMAS.PERFORMANCE_REVIEWS}
              data={data?.[CHART_SLUGS.PERFORMANCE_REVIEWS]}
            />
          </Col>
        </Box> */}
      </Stack>

      <Box w={{ base: "auto", md: "100%" }}>
        <Col>
          <ChartGenerator
            type="columns"
            schema={CHART_SCHEMAS.PERFECT_ORDERS}
            data={data?.[CHART_SLUGS.PERFECT_ORDERS]}
          />
        </Col>
      </Box>

      <Box w={{ base: "auto", md: "100%" }}>
        <Col>
          <ChartGenerator
            type="columns"
            schema={CHART_SCHEMAS.AVERAGE_INVENTORY}
            data={data?.[CHART_SLUGS.AVERAGE_INVENTORY]}
          />
        </Col>
      </Box>

      <Col span={24}>
        <ChartGenerator
          type="line"
          schema={CHART_SCHEMAS.STOCKS_OUT}
          data={data?.[CHART_SLUGS.STOCKS_OUT]}
        />
      </Col>

      {/* <Col span={24}>
        {data?.[CHART_SLUGS.PHASE_COMPLETION] && (
          <ChartGenerator
            type="donus-group"
            schema={CHART_SCHEMAS.PHASE_COMPLETION}
            data={data?.[CHART_SLUGS.PHASE_COMPLETION]}
          />
        )}
      </Col> */}

      {/* <Col span={24} style={styles.dataTable}>
        <DataTable
          style={{ overflow: "auto" }}
          title="Projects"
          columns={PROJECT_COLUMNS}
          data={projects}
        />
      </Col> */}
    </div>
  );
};

export default Main;
