import React from "react";
import { Route } from "react-router-dom";
import {
  AgencyPaymentScheduleList,
  AgencyPaymentScheduleAdd,
  AgencyPaymentScheduleEdit,
  AgencyPaymentScheduleReport,
} from "../pages/AgencyPaymentSchedules";

export const register = (props) => {
  const basePath = "agency-payment-schedules";

  return (
    <>
      <Route
        path={basePath}
        element={<AgencyPaymentScheduleList />}
        exact={true}
      />
      <Route path={`${basePath}/add`} element={<AgencyPaymentScheduleAdd />} />
      <Route
        path={`${basePath}/edit/:id`}
        element={<AgencyPaymentScheduleEdit />}
      />
      <Route
        path={`${basePath}-report`}
        element={<AgencyPaymentScheduleReport />}
      />
    </>
  );
};
