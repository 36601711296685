import React from "react";
import { Route } from "react-router-dom";
import {
  ManpowerTypeList,
  ManpowerTypeAdd,
  ManpowerTypeEdit,
} from "../pages/ManpowerTypes";

export const register = (props) => {
  const basePath = "manpower-types";

  return (
    <>
      <Route path={basePath} element={<ManpowerTypeList />} exact={true} />
      <Route path={`${basePath}/add`} element={<ManpowerTypeAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<ManpowerTypeEdit />} />
    </>
  );
};
