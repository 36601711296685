import React, { useEffect, useState } from "react";
import { AddForm } from "../../../components";
import { useNavigate, useParams } from "react-router-dom";
import { makeConfig } from "./config";
import { axios } from "../../../../../App";
import { fetchData } from "../../../../../utils/helpers/data.helper";
import qs from "qs";
import moment from "moment";

const Page = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const config = makeConfig({ navigate });
  const [values, setValues] = useState({
    status: ["Pending"]?.map((item) => ({
      value: item,
      label: item,
    })),
  });
  const contentTypes = [
    {
      slug: "employees",
      transformer: (item) => ({
        value: item?.id,
        label: `${item?.first_name} ${item?.last_name}`,
      }),
      onSuccess: (data) => {
        setValues((prev) => ({
          ...prev,
          from_employee: data,
          to_employee: data,
        }));
      },
    },
    {
      slug: "shifts",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          from_shift: data,
          to_shift: data,
        })),
    },
  ];

  useEffect(() => {
    contentTypes?.forEach((contentType) => {
      fetchData(contentType);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const queryObject = {
          populate: "*",
        };

        const response = await axios.get(
          `/${config?.slugs?.plural}/${id}?${qs.stringify(queryObject)}`
        );

        if (response?.data) {
          setData({
            ...response?.data?.data?.attributes,
            id: response?.data?.data?.id,
            from_employee:
              response?.data?.data?.attributes?.from_employee?.data?.id,
            to_employee:
              response?.data?.data?.attributes?.to_employee?.data?.id,
            from_shift: response?.data?.data?.attributes?.from_shift?.data?.id,
            to_shift: response?.data?.data?.attributes?.to_shift?.data?.id,
            date: moment(response?.data?.data?.attributes?.date),
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <AddForm
      config={config}
      data={data}
      loading={loading}
      values={{
        ...values,
        status: ["Pending", "Rejected", "Accepted"]?.map((item) => ({
          value: item,
          label: item,
        })),
      }}
    />
  );
};

export default Page;
