export default {
  slugs: {
    singular: "agency-quotations-comparison-list",
    plural: "agency-quotations-comparison-lists",
  },
  entityNames: {
    singular: "Agency Quotation Comparison List",
    plural: "Agency Quotation Comparison Lists",
  },
};
