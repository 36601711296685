export default {
  slugs: {
    singular: "machine-log",
    plural: "machine-logs",
  },
  entityNames: {
    singular: "Machine Log",
    plural: "Machine Logs",
  },
};
