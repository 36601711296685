import React from "react";
import { PageHeader, Breadcrumb } from "antd";
import { makeConfig } from "./config";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Main from "./components/Main";

const Dashboard = (props) => {
  const config = makeConfig();

  return (
    <div style={styles.container}>
      <Helmet>
        <title>{config?.pageTitle}</title>
      </Helmet>

      <Breadcrumb style={styles.breadcrumbs}>
        {config?.breadcrumbs?.map((breadcrumb, index) => (
          <Breadcrumb.Item key={index}>
            <Link to={breadcrumb?.path}>{breadcrumb?.title}</Link>
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>

      <PageHeader title={config?.title} style={styles.pageHeader}></PageHeader>

      <Main />
    </div>
  );
};

const styles = {
  container: {},
  pageHeader: {
    padding: 0,
    paddingBottom: 24,
  },
};

export default Dashboard;
