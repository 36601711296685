import React from "react";
import { Route } from "react-router-dom";
import {
  EmployeeGroupList,
  EmployeeGroupAdd,
  EmployeeGroupEdit,
} from "../pages/EmployeeGroups";

export const register = (props) => {
  const basePath = "employee-groups";

  return (
    <>
      <Route path={basePath} element={<EmployeeGroupList />} exact={true} />
      <Route path={`${basePath}/add`} element={<EmployeeGroupAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<EmployeeGroupEdit />} />
    </>
  );
};
