import { Tag } from "antd";
import { BASE_API_URL, BASE_APP_URL, STRAPI_URL } from "../../../../../constants";
import config from "../config";
import moment from "moment";

export default {
  id: `${config?.slugs?.plural}-list`,
  pageTitle: `${config?.entityNames?.plural} Report`,
  code: "DAILY_REPORTS",
  breadcrumbs: [
    {
      path: "/quick-projects/dashboard",
      title: "Home",
    },
    {
      path: `/quick-projects/reports`,
      title: "Reports",
    },
    {
      path: "",
      title: `${config?.entityNames?.plural} Report`,
    },
  ],
  columns: [
    {
      title: "Employee",
      dataIndex: "employee",
      selectable: true,
      references: {
        fieldName: "first_name",
      },
    },
    {
      title: "Date",
      dataIndex: "date",
      selectable: true,
      sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
      render: (text) => <span>{moment(text).format("DD-MM-YYYY")}</span>,
    },
    {
      title: "Finished Date",
      dataIndex: "finished_date",
      selectable: true,
      sorter: (a, b) => moment(a.finished_date).unix() - moment(b.finished_date).unix(),
      render: (text) => <span>{moment(text).format("DD-MM-YYYY")}</span>,
    },
    {
      title: "Completion Percentage",
      dataIndex: "first_tasks",
      selectable: true,
      filterable: true,
      references: {
        fieldName: "completion_percentage",
      },
      render: (text) => `${text?.completion_percentage}%`,
    },
    {
      title: "Project Name",
      dataIndex: "project",
      selectable: true,
      references: {
        fieldName: "name",
      },
    },
    {
      title: "Task Name",
      dataIndex: "task_names",
      selectable: true,
      width: "300px"
    },

    // {
    //   title: "Attached Images",
    //   dataIndex: "attached_photo",
    //   selectable: true,
    //   references: {
    //     fieldName: (record) => <img src={`${STRAPI_URL}${record?.url}`} alt="attachment" width={50} />,
    //   },
    // },
  ],
  ...config,
};
