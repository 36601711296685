export default {
  slugs: {
    singular: "app-role",
    plural: "app-roles",
  },
  entityNames: {
    singular: "Role",
    plural: "Roles",
  },
};
