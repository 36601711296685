import config from "../config";
import moment from "moment";

export default {
  id: `${config?.slugs?.plural}-list`,
  pageTitle: config?.entityNames?.plural,
  breadcrumbs: [
    {
      path: "/quick-projects/dashboard",
      title: "Home",
    },
    {
      path: "",
      title: config?.entityNames?.plural,
    },
  ],
  columns: [
    {
      title: "Project",
      dataIndex: "project",
      selectable: true,
      references: {
        fieldName: "name",
      },
    },
    {
      title: "Resource Type",
      dataIndex: "resource_type",
      selectable: true,
    },
    {
      title: "Email",
      dataIndex: "email",
      selectable: true,
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      selectable: true,
      sorter: (a, b) =>
        moment(a.start_date).unix() - moment(b.start_date).unix(),
      render: (text) => <span>{moment(text).format("DD-MM-YYYY")}</span>,
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      selectable: true,
      sorter: (a, b) => moment(a.end_date).unix() - moment(b.end_date).unix(),
      render: (text) => <span>{moment(text).format("DD-MM-YYYY")}</span>,
    },
  ],
  ...config,
};
