import { axios } from "../../../../../App";
import { v4 } from "uuid";
import config from "../config";

export const makeConfig = ({ navigate, auth }) => ({
  id: `${config?.slugs?.plural}-add`,
  title: `Add New ${config?.entityNames?.singular}`,
  pageTitle: `Add New ${config?.entityNames?.singular}`,
  breadcrumbs: [
    {
      path: "/quick-stocks/dashboard",
      title: "Home",
    },
    {
      path: `/quick-stocks/${config?.slugs?.plural}`,
      title: config?.entityNames?.plural,
    },
    {
      path: "",
      title: `Add New ${config?.entityNames?.singular}`,
    },
  ],
  components: [
    {
      span: 12,
      component: "input",
      label: "Name",
      name: "name",
      required: true,
      placeholder: "Enter name",
      rules: [
        {
          required: true,
          message: "Name is required!",
        },
      ],
    },
    {
      span: 12,
      component: "input",
      label: "SKU",
      name: "sku",
      required: true,
      placeholder: "Enter SKU",
      rules: [
        {
          required: true,
          message: "SKU is required!",
        },
      ],
    },
    {
      span: 12,
      component: "input",
      label: "HSN Code",
      name: "hsn_code",
      required: true,
      placeholder: "Enter HSN code",
      rules: [
        {
          required: true,
          message: "HSN code is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Material Group",
      name: "material_group",
      placeholder: "Select material group",
      required: true,
      rules: [
        {
          required: true,
          message: "Material group is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Material Unit",
      name: "material_unit",
      placeholder: "Select material unit",
      required: true,
      rules: [
        {
          required: true,
          message: "Material unit is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Material Brand",
      name: "material_brand",
      placeholder: "Select material brand",
      required: true,
      rules: [
        {
          required: true,
          message: "Material brand is required!",
        },
      ],
    },
    // {
    //   span: 12,
    //   component: "select",
    //   label: "Material Unit",
    //   name: "material_unit",
    //   placeholder: "Select material unit",
    //   required: true,
    //   rules: [
    //     {
    //       required: true,
    //       message: "Material unit is required!",
    //     },
    //   ],
    // },
    {
      span: 12,
      component: 'uploader',
      label: 'Material Image',
      name: 'material_image',
      placeholder: 'Upload image file',
      required: true,
      accept: '.png, .jpg, .jpeg',
      rules: [
        {
          required: true,
          message: 'Image is required!',
        },
      ],
    },
    {
      span: 12,
      component: "number",
      label: "Minimum",
      name: "minimum",
      required: true,
      placeholder: "Enter minimum",
      rules: [
        {
          required: true,
          message: "Minimum is required!",
        },
      ],
      min: 0,
    },
    {
      span: 24,
      component: "editor",
      label: "Description",
      name: "description",
      placeholder: "Enter description",
      rows: 5,
    },
  ],
  actions: {
    onBack: () => navigate(`/quick-stocks/${config?.slugs?.plural}`),
    onFinish: async (values) => {
      try {
        const response = await axios.post(`/${config?.slugs?.plural}`, {
          data: {
            ...values,
            uid: v4(),
            // creator: auth?.user?.id,
          },
        });

        if (response?.data) {
          return {
            success: true,
            message: `Add new ${config?.entityNames?.singular?.toLowerCase()} successfully!`,
          };
        }

        throw new Error();
      } catch (error) {
        return {
          success: false,
          message: `Add new ${config?.entityNames?.singular?.toLowerCase()} failed!`,
        };
      }
    },
  },
});
