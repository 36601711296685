import { axios } from "../../../../../App";
import config from "../config";
import moment from "moment";

export const makeConfig = ({ navigate }) => ({
  id: `${config?.plural}-edit`,
  title: `Edit ${config?.entityNames?.singular}`,
  pageTitle: `Edit ${config?.entityNames?.singular}`,
  breadcrumbs: [
    {
      path: "/quick-talks/dashboard",
      title: "Home",
    },
    {
      path: `/quick-talks/${config?.slugs?.plural}`,
      title: config?.entityNames?.plural,
    },
    {
      path: "",
      title: `Edit ${config?.entityNames?.singular}`,
    },
  ],
  components: [
    {
      span: 24,
      component: "input",
      label: "Name",
      name: "name",
      required: true,
      placeholder: "Enter name",
      rules: [
        {
          required: true,
          message: "Name is required!",
        },
      ],
    },
    {
      span: 12,
      component: "date",
      label: "Date",
      name: "date",
      required: true,
      placeholder: "Select date",
      rules: [
        {
          required: true,
          message: "Date is required!",
        },
      ],
    },
    {
      span: 12,
      component: "time",
      label: "Time",
      name: "time",
      required: true,
      placeholder: "Select time",
      rules: [
        {
          required: true,
          message: "Time is required!",
        },
      ],
    },
    {
      span: 24,
      component: "select",
      label: "Employees",
      name: "employees",
      placeholder: "Select employees",
      required: true,
      rules: [
        {
          required: true,
          message: "Employees is required!",
        },
      ],
      mode: "multiple",
    },
    // {
    //   span: 12,
    //   component: "select",
    //   label: "Frequency",
    //   name: "frequency",
    //   placeholder: "Select frequency",
    //   required: true,
    //   rules: [
    //     {
    //       required: true,
    //       message: "Frequency is required!",
    //     },
    //   ],
    // },
    {
      span: 24,
      component: "editor",
      label: "Description",
      name: "description",
      placeholder: "Enter description",
      rows: 5,
    },
  ],
  actions: {
    onBack: () => navigate(`/quick-talks/${config?.slugs?.plural}`),
    onFinish: async (values, data) => {
      try {
        const response = await axios.put(
          `/${config?.slugs?.plural}/${data?.id}`,
          {
            data: {
              ...values,
              date: values?.date?.add(1, "days"),
              time: moment(values?.time).format("HH:mm:ss.SSS"),
            },
          }
        );

        if (response?.data) {
          return {
            success: true,
            message: `Edit ${config?.entityNames?.singular?.toLowerCase()} successfully!`,
          };
        }

        throw new Error();
      } catch (error) {
        return {
          success: false,
          message: error?.response?.data?.error?.message,
        };
      }
    },
  },
  ...config,
});
