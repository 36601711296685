import { axios } from "../../../../../App";
import { v4 } from "uuid";
import config from "../config";

export const makeConfig = ({ navigate, auth }) => ({
  id: `${config?.slugs?.plural}-add`,
  title: `Add New ${config?.entityNames?.singular}`,
  pageTitle: `Add New ${config?.entityNames?.singular}`,
  breadcrumbs: [
    {
      path: "/quick-projects/dashboard",
      title: "Home",
    },
    {
      path: `/quick-projects/${config?.slugs?.plural}`,
      title: config?.entityNames?.plural,
    },
    {
      path: "",
      title: `Add New ${config?.entityNames?.singular}`,
    },
  ],
  components: [
    {
      span: 12,
      component: "input",
      label: "No",
      name: "no",
      required: true,
      placeholder: "Enter no",
      rules: [
        {
          required: true,
          message: "No is required!",
        },
      ],
    },
    {
      span: 12,
      component: "input",
      label: "Name",
      name: "name",
      required: true,
      placeholder: "Enter name",
      rules: [
        {
          required: true,
          message: "Name is required!",
        },
      ],
    },
    // {
    //   span: 3,
    //   component: "input",
    //   label: "Color",
    //   name: "color",
    //   placeholder: "Select color",
    //   type: "color",
    //   required: true,
    //   rules: [
    //     {
    //       required: true,
    //       message: "Color is required!",
    //     },
    //   ],
    // },
    {
      span: 12,
      component: "select",
      label: "Type",
      name: "type",
      placeholder: "Select type",
      required: true,
      rules: [
        {
          required: true,
          message: "Type is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Project",
      name: "project",
      placeholder: "Select project",
      required: true,
      rules: [
        {
          required: true,
          message: "Project is required!",
        },
      ],
    },
    {
      span: 12,
      component: "date",
      label: "Start Date",
      name: "start_date",
      required: true,
      placeholder: "Select start date",
      rules: [
        {
          required: true,
          message: "Start date is required!",
        },
      ],
    },
    {
      span: 12,
      component: "date",
      label: "End Date",
      name: "end_date",
      placeholder: "Select end date",
      required: true,
      rules: [
        {
          required: true,
          message: "Start date is required!",
        },
      ],
    },
    {
      span: 12,
      component: "date",
      label: "Actual Start Date",
      name: "actual_start_date",
      placeholder: "Select actual start date",
    },
    {
      span: 12,
      component: "date",
      label: "Actual End Date",
      name: "actual_end_date",
      placeholder: "Select actual end date",
    },
    // {
    //   span: 12,
    //   component: "number",
    //   label: "Progress",
    //   name: "progress",
    //   placeholder: "Enter progress",
    //   min: 0,
    //   max: 100,
    // },
    {
      span: 12,
      component: "number",
      label: "Estimated Budget",
      name: "estimated_budget",
      placeholder: "Enter estimated budget",
      min: 0,
    },
    {
      span: 12,
      component: "number",
      label: "Sanctioned Budget",
      name: "sanctioned_budget",
      placeholder: "Enter sanctioned budget",
      min: 0,
    },
    {
      span: 12,
      component: "select",
      label: "Status",
      name: "status",
      placeholder: "Select status",
      required: true,
      rules: [
        {
          required: true,
          message: "Status is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Milestone",
      name: "milestone",
      placeholder: "Select milestone",
      required: true,
      rules: [
        {
          required: true,
          message: "Status is required!",
        },
      ],
    },
    {
      span: 24,
      component: "select",
      label: "Dependencies",
      name: "dependencies",
      placeholder: "Select dependencies",
      // required: true,
      // rules: [
      //   {
      //     required: true,
      //     message: "Dependencies is required!",
      //   },
      // ],
      mode: "multiple",
    },
    {
      span: 24,
      component: "select",
      label: "Assignees",
      name: "assignees",
      placeholder: "Select employees",
      required: true,
      rules: [
        {
          required: true,
          message: "Assignees is required!",
        },
      ],
      mode: "multiple",
    },
    // {
    //   span: 24,
    //   component: "select",
    //   label: "Machines",
    //   name: "machines",
    //   placeholder: "Select machines",
    //   mode: "multiple",
    // },
    // {
    //   span: 24,
    //   component: "select",
    //   label: "Hand Tools",
    //   name: "hand_tools",
    //   placeholder: "Select hand tools",
    //   mode: "multiple",
    // },
    {
      span: 24,
      component: "editor",
      label: "Description",
      name: "description",
      placeholder: "Enter description",
      rows: 5,
    },
  ],
  actions: {
    onBack: () => navigate(`/quick-projects/${config?.slugs?.plural}`),
    onFinish: async (values) => {
      try {
        const response = await axios.post(`/${config?.slugs?.plural}`, {
          data: {
            ...values,
            uid: v4(),
            actual_start_date: values?.actual_start_date || null,
            actual_end_date: values?.actual_end_date || null,
            creator_uid: auth?.user?.uid,
            // creator: auth?.user?.id,
          },
        });

        if (response?.data) {
          return {
            success: true,
            message: `Add new ${config?.entityNames?.singular?.toLowerCase()} successfully!`,
          };
        }

        throw new Error();
      } catch (error) {
        return {
          success: false,
          message: `Add new ${config?.entityNames?.singular?.toLowerCase()} failed!`,
        };
      }
    },
  },
});
