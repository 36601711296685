import { axios } from "../../../../../App";

export const makeConfig = ({ auth, dispatch }) => ({
  id: `password-change`,
  title: `Change Password`,
  pageTitle: `Change Password`,
  breadcrumbs: [
    {
      path: "/",
      title: "Home",
    },
    {
      path: "",
      title: `Change password`,
    },
  ],
  components: [
    {
      span: 8,
      component: "input",
      label: "Current Password",
      name: "current_password",
      required: true,
      placeholder: "Enter current password",
      rules: [
        {
          required: true,
          message: "Current password is required!",
        },
        {
          min: 6,
          message: "Current password must have at least 6 characters!",
        },
      ],
      type: "password",
    },
    {
      span: 8,
      component: "input",
      label: "New Password",
      name: "new_password",
      required: true,
      placeholder: "Enter new password",
      rules: [
        {
          required: true,
          message: "New password is required!",
        },
        {
          min: 6,
          message: "New password must have at least 6 characters!",
        },
        ({ getFieldValue }) => ({
          validator(rule, value) {
            if (getFieldValue("current_password") === value) {
              return Promise.reject(
                "New password must be different from current password!"
              );
            }

            return Promise.resolve();
          },
        }),
      ],
      type: "password",
    },
    {
      span: 8,
      component: "input",
      label: "Confirmation Password",
      name: "confirmation_password",
      required: true,
      placeholder: "Enter confirmation password",
      rules: [
        {
          required: true,
          message: "Confirmation password is required!",
        },
        {
          min: 6,
          message: "Confirmation password must have at least 6 characters!",
        },
        ({ getFieldValue }) => ({
          validator(rule, value) {
            if (
              getFieldValue("new_password") !==
              getFieldValue("confirmation_password")
            ) {
              return Promise.reject("Confirmation password does not match!");
            }

            return Promise.resolve();
          },
        }),
      ],
      type: "password",
    },
  ],
  actions: {
    onFinish: async (values, data) => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/auth/change-password`,
          {
            currentPassword: values?.current_password,
            password: values?.new_password,
            passwordConfirmation: values?.confirmation_password,
          },
          {
            headers: {
              Authorization: `Bearer ${auth?.user?.token}`,
            },
          }
        );

        if (response?.data?.jwt) {
          return {
            success: true,
            message: `Change password successfully!`,
          };
        }
      } catch (error) {
        return {
          success: false,
          message:
            error?.response?.data?.error?.message ||
            "Change password unsuccessfully!",
        };
      }
    },
  },
});
