import { CLIENT_UID_SET, CLIENT_UID_UNSET } from "./types";

export const setClientUid = (uid) => ({
  type: CLIENT_UID_SET,
  payload: {
    uid,
  },
});

export const unsetClientUid = () => ({
  type: CLIENT_UID_UNSET,
});
