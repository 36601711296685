import React from "react";
import { Route } from "react-router-dom";
import {
  AgencyExtraWorkList,
  AgencyExtraWorkAdd,
  AgencyExtraWorkReport,
  AgencyExtraWorkCreatorEdit,
  AgencyExtraWorkApproverEdit,
  AgencyExtraWorkView,
} from "../pages/AgencyExtraWorks";

export const register = (props) => {
  const basePath = "agency-extra-works";

  return (
    <>
      <Route path={basePath} element={<AgencyExtraWorkList />} exact={true} />
      <Route path={`${basePath}/add`} element={<AgencyExtraWorkAdd />} />
      <Route
        path={`${basePath}/creator/edit/:id`}
        element={<AgencyExtraWorkCreatorEdit />}
      />
      <Route
        path={`${basePath}/approver/edit/:id`}
        element={<AgencyExtraWorkApproverEdit />}
      />
      <Route path={`${basePath}/view/:id`} element={<AgencyExtraWorkView />} />
      <Route path={`${basePath}-report`} element={<AgencyExtraWorkReport />} />
    </>
  );
};
