import React, { useEffect, useRef, useState } from "react";
import { ReloadOutlined, PlusOutlined, DownloadOutlined } from "@ant-design/icons";
import {
  Table,
  Space,
  Button,
  PageHeader,
  Breadcrumb,
  Tooltip,
  Modal,
  Select,
} from "antd";
import ColumnSelector from "../ColumnSelector";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import _ from "lodash";
import { ImFilePdf } from "react-icons/im";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { axios } from "../../../../App";
import html2canvas from "html2canvas";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { STRAPI_URL } from "../../../../constants";

const { Option } = Select;

const ReportDataTable = ({
  data,
  taskData,
  columns,
  config,
  loading,
  pagination,
  actions,
  buttons = {},
  onBack = null,
  Toolbar = null,
  projects_data = null,
  projects_column = null,
  task_images,
  use_handlePrint = false,
  handleRowClick = () => { }
}) => {
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [letterheadFileName, setLetterheadFileName] = useState("");
  const [showsModal, setShowsModal] = useState(false);
  const [letterheads, setLetterheads] = useState([]);
  const tableRef = useRef(null);
  const componentRef = useRef();
  const componentRef2 = useRef();


  const selectColumn = (dataIndex) => {
    if (selectedColumns?.indexOf(dataIndex) > -1) {
      setSelectedColumns((prev) => prev?.filter((item) => item !== dataIndex));
    } else {
      setSelectedColumns((prev) => prev?.concat(dataIndex));
    }
  };

  const renderButtons = () => {
    if (!_.isEmpty(buttons)) {
      return buttons;
    } else {
      return [
        <Tooltip placement="top" title="Add new item">
          <Button icon={<PlusOutlined />} onClick={actions?.onAdd} />
        </Tooltip>,
        <Tooltip placement="top" title="Refresh">
          <Button icon={<ReloadOutlined />} onClick={actions?.onRefresh} />
        </Tooltip>,
      ];
    }
  };

  const fetchLetterheads = async () => {
    try {
      const resp = await axios.get(`/letterheads?populate=*`);

      if (resp?.data?.data?.length > 0) {
        const results = resp?.data?.data?.map((record, index) => {
          let fileName =
            record?.attributes?.image_file?.data?.attributes?.url?.split(
              "/"
            )[2] || "";

          if (index === 0) {
            setLetterheadFileName(fileName);
          }

          return {
            id: record?.id,
            name: record?.attributes?.name,
            file_name: fileName,
          };
        });

        setLetterheads(results);
      }
    } catch (error) { }
  };

  const downloadPDF = async () => {
    const doc = new jsPDF();

    // Capture the bar graph as an image
    let barImgData = null;
    let pieImgData = null;
    let pieImgData2 = null;
    const barGraph = document.getElementById('barGraph');
    const pieChart = document.getElementById('pieChart');
    const pieChart2 = document.getElementById('pieChart2');

    if (barGraph) {
      const canvas = await html2canvas(barGraph);
      barImgData = canvas.toDataURL('image/png');
    }

    if (pieChart) {
      const canvas = await html2canvas(pieChart);
      pieImgData = canvas.toDataURL('image/png');
    }

    if (pieChart2) {
      const canvas = await html2canvas(pieChart2);
      pieImgData2 = canvas.toDataURL('image/png');
    }

    // Fetch header image
    const headerResp = await axios.get(`/utils/image-to-base64?file_name=${letterheadFileName}`);

    let finalY = 60; // Starting y-coordinate after the header and title

    // Filter out the column with the title "Actions"
    const filteredColumns = config?.columns?.filter((column) => column?.title !== 'Actions');

    autoTable(doc, {
      head: [filteredColumns?.map((column) => column?.title)],
      body: data?.map((record) => {
        let results = [];

        filteredColumns?.forEach((column) => {
          let value = record?.[column?.dataIndex];

          if (column?.references) {
            if (typeof column?.references?.fieldName === 'function') {
              const transformer = column?.references?.fieldName;
              value = transformer(record) || 'N/A';
            } else {
              value = record?.[column?.dataIndex]?.data?.attributes?.[column?.references?.fieldName] || 'N/A';
            }
          }

          results.push(value);
        });

        return results;
      }),
      didDrawPage: function (data) {
        if (headerResp?.data?.success) {
          doc.addImage(
            headerResp?.data?.data,
            'JPEG',
            0,
            0,
            doc.internal.pageSize.width,
            30
          );
        }
        finalY = data.cursor.y + 20; // Adjust finalY based on where autoTable ends
      },
      margin: { top: 40, left: 10, right: 10 },
      headStyles: {
        fillColor: [199, 199, 199], // Set header background to light gray
        textColor: [0, 0, 0], // Set header text color to black
        fontSize: 8, // Adjust the font size of the header text
        halign: 'left' // Align text to the left
      },
      bodyStyles: {
        textColor: [0, 0, 0], // Set body text color to black
        fontSize: 8, // Set the font size of the body text
        minCellHeight: 12, // Simulate padding by setting minimum cell height
        halign: 'left' // Align text to the left
      },
      alternateRowStyles: { fillColor: [255, 255, 255] }, // No alternate row colors
      styles: {
        fontSize: 8,
        fillColor: [255, 255, 255] // Ensure the row background is always white
      }
    });

    // Function to draw image without border in the center of the page
    const drawImageWithoutBorder = (doc, imgData, x, y, maxWidth) => {
      const imgProps = doc.getImageProperties(imgData);
      const imgWidth = imgProps.width;
      const imgHeight = imgProps.height;
      const aspectRatio = imgWidth / imgHeight;

      const finalWidth = maxWidth;
      const finalHeight = finalWidth / aspectRatio;

      // Draw image
      doc.addImage(imgData, 'PNG', x, y, finalWidth, finalHeight);

      return finalHeight; // Return the total height used by the image
    };

    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const margin = 20; // Margin from the sides

    // Calculate the available width for images
    const availableWidth = pageWidth - margin * 2;

    // Ensure there's enough space between autoTable content and charts
    finalY = Math.max(finalY, 60);

    // Helper function to add a new page if the content exceeds the page height
    const addImageWithAutoPageBreak = (imgData, maxWidth, title) => {
      const imgProps = doc.getImageProperties(imgData);
      const aspectRatio = imgProps.width / imgProps.height;
      const imgHeight = maxWidth / aspectRatio;

      if (finalY + imgHeight > pageHeight - 20) {
        doc.addPage();
        finalY = 20; // Reset Y coordinate for the new page
      }

      if (title) {
        doc.setFontSize(12);
        doc.text(title, margin, finalY);
        finalY += 20; // Adjust finalY to add space between the title and the table
      }

      finalY += drawImageWithoutBorder(doc, imgData, margin, finalY, maxWidth);
    };

    // Draw bar graph image
    if (barImgData) {
      addImageWithAutoPageBreak(barImgData, availableWidth, '');
    }

    // Add some space between the charts
    finalY += 40;

    // Draw pie chart image without border
    if (pieImgData) {
      addImageWithAutoPageBreak(pieImgData, availableWidth, config?.pie_title);
    }

    if (pieImgData2) {
      addImageWithAutoPageBreak(pieImgData2, availableWidth, config?.pie_title);
    }

    // Add projects table if there is project data
    if (projects_data && projects_data.length > 0) {
      finalY += 20; // Add some space before the new table

      // Draw the project title
      doc.setFontSize(12);
      doc.text(config?.project_title, margin, finalY);
      finalY += 10; // Adjust finalY to add space between the title and the table

      autoTable(doc, {
        head: [projects_column?.map((column) => column?.title)],
        body: projects_data?.map((record) => {
          let results = [];

          projects_column?.forEach((column) => {
            let value = record?.[column?.dataIndex];

            if (column?.references) {
              if (typeof column?.references?.fieldName === 'function') {
                const transformer = column?.references?.fieldName;
                value = transformer(record) || 'N/A';
              } else {
                value = record?.[column?.dataIndex]?.data?.attributes?.[column?.references?.fieldName] || 'N/A';
              }
            }

            results.push(value);
          });

          return results;
        }),
        startY: finalY,
        margin: {
          top: 10,
        },
        headStyles: {
          fillColor: [199, 199, 199], // Set header background to light gray
          textColor: [0, 0, 0], // Set header text color to black
          fontSize: 8, // Adjust the font size of the header text
          halign: 'left' // Align text to the left
        },
        bodyStyles: {
          textColor: [0, 0, 0], // Set body text color to black
          fontSize: 8, // Set the font size of the body text
          minCellHeight: 12, // Simulate padding by setting minimum cell height
          halign: 'left' // Align text to the left
        },
        alternateRowStyles: { fillColor: [255, 255, 255] }, // No alternate row colors
        styles: {
          fontSize: 8,
          fillColor: [255, 255, 255] // Ensure the row background is always white
        }
      });
    }

    doc.save(`${config?.slugs?.plural}.pdf`);
  };

  async function handlePrint() {

    // Get the element to convert to PDF
    let element = componentRef?.current?.innerHTML;

    // Capture the HTML of the second table (from componentRef2)
    const tableHTML2 = componentRef2?.current?.querySelector('table')?.outerHTML;

    if (tableHTML2) {
      element += `
          <div style="page-break-inside: avoid; page-break-before: always; padding: 40px 0;">
            ${tableHTML2}
          </div>
        `;
    }

    // Get references to the chart elements
    const barGraph = document.getElementById('barGraph');
    const pieChart = document.getElementById('pieChart');
    const pieChart2 = document.getElementById('pieChart2');

    // Initialize variables to store the images (empty by default)
    let barGraphImage = '';
    let pieChartImage = '';
    let pieChartImage2 = '';

    try {
      // Check if barGraph exists before capturing it
      if (barGraph) {
        barGraphImage = await html2canvas(barGraph, { useCORS: true }).then((canvas) => canvas.toDataURL('image/jpeg', 1.0));
      }

      // Check if pieChart exists before capturing it
      if (pieChart) {
        pieChartImage = await html2canvas(pieChart, { useCORS: true }).then((canvas) => canvas.toDataURL('image/jpeg', 1.0));
      }

      if (pieChart2) {
        pieChartImage2 = await html2canvas(pieChart2, { useCORS: true }).then((canvas) => canvas.toDataURL('image/jpeg', 1.0));
      }
    } catch (error) {
      console.error("Error capturing charts: ", error);
    }

    // Prepare a container for the captured charts
    let chartsHTML = '';

    if (barGraphImage) {
      chartsHTML += `
          <div style="padding: 40px 0; text-align: center; page-break-before: always;">
            <img src="${barGraphImage}" alt="Bar Graph" style="page-break-inside: avoid;" />
          </div>
        `;
    }

    if (pieChartImage) {
      chartsHTML += `
          <div style=" text-align: center; page-break-inside: avoid; max-width: 50%">
            <img src="${pieChartImage}" alt="Pie Chart" style="page-break-inside: avoid;" />
          </div>
        `;
    }

    if (pieChartImage2) {
      chartsHTML += `
          <div style="text-align: center; page-break-inside: avoid;">
            <img src="${pieChartImage2}" alt="Pie Chart" style="page-break-inside: avoid;" />
          </div>
        `;
    }

    // Append the chart images into the HTML content
    element += chartsHTML;


    // Options for html2pdf
    var opt = {
      margin: [0.03, 0.3, 0.3, 0.3],
      filename: config?.slugs?.plural + ".pdf",
      image: { type: "jpeg", quality: 0.5 },
      pagebreak: {
        avoid: 'img, tr',    // Avoid page breaks inside images and table rows
        mode: ['avoid-all', 'css', 'legacy'],  // Add 'css' and 'legacy' modes for better compatibility
      },
      html2canvas: {
        scale: 5,
        useCORS: true,
      },
      jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
      compressImage: false,
    };


    // Generate the PDF
    setTimeout(() => {
      // Generate the PDF
      import("html2pdf.js").then((html2pdf) => {
        html2pdf.default().set(opt).from(element).save();
      });
    }, 500);  // 500ms delay

  }



  const onSubmit = () => {
    setShowsModal(false);
    if (use_handlePrint) {
      handlePrint()
    } else {
      downloadPDF();
    }
  };

  useEffect(() => {
    fetchLetterheads();

    setSelectedColumns(
      config?.columns
        ?.filter((column) => column?.selectable)
        ?.map((column) => column?.dataIndex)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log('report data ', letterheadFileName)


  return (
    <>
      <Helmet>
        <title>{config?.pageTitle}</title>
      </Helmet>

      {data && (
        <Breadcrumb style={styles.breadcrumbs}>
          {config?.breadcrumbs?.map((breadcrumb, index) => (
            <Breadcrumb.Item key={index}>
              <Link to={breadcrumb?.path}>{breadcrumb?.title}</Link>
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>
      )}

      <PageHeader
        title={config?.pageTitle}
        extra={[
          <Space>
            {renderButtons()}

            <Tooltip placement="top" title="Download PDF">
              <Button
                icon={<ImFilePdf style={{ margin: "0 auto" }} />}
                onClick={() => setShowsModal(true)}
              />
            </Tooltip>

            <DownloadTableExcel
              filename={`${config?.slugs?.plural} report`}
              sheet="report"
              currentTableRef={tableRef.current}
            >
              <Tooltip placement="top" title="Download Excel">
                <Button icon={<DownloadOutlined />}>
                </Button>
              </Tooltip>
            </DownloadTableExcel>

            <ColumnSelector
              value={selectedColumns}
              columns={config?.columns?.filter((column) => column?.selectable)}
              onSelect={selectColumn}
            />
          </Space>,
        ]}
        onBack={onBack}
        style={styles.pageHeader}
      ></PageHeader>

      {Toolbar}

      {data && selectedColumns?.length > 0 && (
        <Table
          style={{ overflow: "auto" }}
          columns={config?.columns?.filter(
            (column) =>
              column?.title === "Actions" ||
              selectedColumns?.indexOf(column?.dataIndex) > -1
          )}
          rowKey={(record) => record.id}
          dataSource={data}
          pagination={pagination}
          loading={loading}
          onRow={(record) => ({
            onClick: () => handleRowClick(record),
          })}
        />
      )}

      {data?.length > 0 && (
        <div
          ref={componentRef}
          style={{ position: "absolute", left: "-9999px", top: 0 }}
        >
          {(/\.(png|jpe?g)$/i.test(letterheadFileName)) && (
            <img
              src={`${STRAPI_URL}/uploads/${letterheadFileName}`}
              alt="Letterhead"
              style={{
                width: '100%',
                height: '150px',
                zIndex: -1, // Ensure it is behind other content
                marginBottom: '40px',
                objectFit: 'cover'
              }}
            />
          )}
          <Table
            style={{ overflow: "auto" }}
            columns={config?.columns?.filter(
              (column) =>
                column?.title === "Actions" ||
                selectedColumns?.indexOf(column?.dataIndex) > -1
            )}
            rowKey={(record) => record.id}
            dataSource={data}
            pagination={false}
            loading={loading}
            onRow={(record) => ({
              onClick: () => handleRowClick(record),
            })}
          />
        </div>
      )}

      {projects_data?.length > 0 && (
        <div
          ref={componentRef2}
          style={{ position: "absolute", left: "-9999px", top: 0 }}
        >
          <Table
            columns={projects_column}
            rowKey={(record) => record.id}
            dataSource={projects_data}
            pagination={false}
            loading={loading}
          />
        </div>
      )}


      {/* // Table for Download Excel */}
      {selectedColumns?.length > 0 && (
        <div ref={tableRef} style={{ display: 'none' }}>
          <Table
            style={{ overflow: "auto" }}
            columns={config?.columns?.filter(
              (column) =>
                column?.title === "Actions" ||
                selectedColumns?.indexOf(column?.dataIndex) > -1
            )}
            rowKey={(record) => record.id}
            dataSource={data}
            pagination={{
              pageSize: 1000,
            }}
          />
        </div>
      )}

      <Modal
        title="Print PDF"
        visible={showsModal}
        onOk={onSubmit}
        onCancel={() => setShowsModal(false)}
        destroyOnClose
      >
        <label style={{ ...styles.label, marginTop: 0 }}>Letterhead</label>
        <Select
          value={letterheadFileName}
          onChange={setLetterheadFileName}
          style={styles.select}
        >
          {letterheads?.map((letterhead, index) => (
            <Option key={index} value={letterhead?.file_name}>
              {letterhead?.name}
            </Option>
          ))}
        </Select>

        <label style={styles.label}>Preview</label>
        <img
          src={`${process.env.REACT_APP_BASE_API_URL}/uploads/${letterheadFileName}`}
          style={styles.previewedImage}
          alt="Preview"
        />
      </Modal>
    </>
  );
};

const styles = {
  toolbar: {
    marginBottom: 24,
  },
  rightToolbar: {
    textAlign: "right",
  },
  pageHeader: {
    padding: 0,
    paddingBottom: 24,
  },
  label: {
    display: "block",
    marginBottom: 3,
    marginTop: 6,
  },
  previewedImage: {
    width: "100%",
  },
  select: {
    width: "100%",
  },
};

export default ReportDataTable;
