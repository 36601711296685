export default {
  slugs: {
    singular: "contact-group",
    plural: "contact-groups",
  },
  entityNames: {
    singular: "Contact Group",
    plural: "Contact Groups",
  },
};
