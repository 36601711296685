import moment from "moment";
import { SimpleGrid, Box } from "@chakra-ui/react";

export default function Previewer({ data }) {
  const getDeliveryAddress = (addressObject) => {
    return `${addressObject?.name} (${addressObject?.address_line_1}, ${addressObject?.address_line_2})`;
  };

  return (
    <div style={styles.container}>
      <div style={styles.formContainer}>
        <div className="public-form-header">
          <table
            cellPadding={0}
            cellSpacing={0}
            style={{
              width: "100%",
              border: "0px solid #ddd",
              marginBottom: "10px",
            }}
          >
            <tr style={{ borderBottom: "1px solid #eee" }}>
              <td
                className="text-center"
                style={{
                  background:
                    "url(/images/form-header-left.jpeg) no-repeat top left",
                  backgroundSize: "80%",
                  width: "30%",
                }}
              >
                <img
                  title="QuickSO"
                  src="/images/form-header-logo.png"
                  alt="QuickSO"
                  style={{
                    height: "75px",
                    width: "auto",
                    objectFit: "contain",
                    margin: "0 auto",
                  }}
                />
              </td>

              <td
                style={{
                  background:
                    "url(/images/form-header-right.jpeg) no-repeat top left",
                  backgroundSize: "100%",
                }}
              >
                <table
                  cellPadding={0}
                  cellSpacing={0}
                  style={{
                    width: "100%",
                    border: "0px solid #ddd",
                    marginBottom: "0px",
                  }}
                >
                  <tbody>
                    <tr>
                      <td
                        colSpan={2}
                        style={{ borderBottom: "2px dashed #ddd" }}
                      >
                        <h1
                          className="text-left"
                          style={{
                            color: "#ffffff",
                            fontSize: "30px",
                            padding: "20px 20px",
                            textTransform: "uppercase",
                            margin: 0,
                          }}
                        >
                          AGENCY WORK ORDER
                        </h1>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <h2
                          className="text-left"
                          style={{
                            color: "#ffffff",
                            fontSize: "16px",
                            padding: "10px 20px",
                            fontWeight: 300,
                          }}
                        >
                          Serial Number
                          <br />
                          <b>
                            VPO-
                            {
                              data?.agency_requirement_form?.data?.attributes?.uid?.split(
                                "-"
                              )?.[0]
                            }
                            -
                            {
                              data?.agency?.data?.attributes?.uid?.split(
                                "-"
                              )?.[0]
                            }
                          </b>
                        </h2>
                      </td>

                      <td>
                        <h2
                          className="text-left"
                          style={{
                            color: "#ffffff",
                            fontSize: "16px",
                            padding: "10px 20px",
                            fontWeight: 300,
                          }}
                        >
                          Date
                          <br />
                          <b>
                            {moment(data?.createdAt).format(
                              "DD-MM-YYYY, HH:mm"
                            )}
                          </b>
                        </h2>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </table>
        </div>

        <div className="public-form-body">
          <div className="public-form-section">
            {/* <h2 className="public-form-section-title">General Information</h2> */}

            {/* <div className="public-form-row"> */}
              <SimpleGrid columns={[1, 2]} spacing={[2, 5]}>
                <Box p={2}>
                  <table className="public-form-general-info">
                    <tr>
                      <td>Agency</td>
                      <td>{data?.agency?.data?.attributes?.name}</td>
                    </tr>

                    <tr>
                      <td>Project</td>
                      <td>{data?.project?.data?.attributes?.name}</td>
                    </tr>

                    <tr>
                      <td>Delivery Address</td>
                      <td>
                        {getDeliveryAddress(
                          data?.delivery_address?.data?.attributes
                        ) || "N/A"}
                      </td>
                    </tr>

                    <tr>
                      <td>Requirement Form</td>
                      <td>{data?.heading}</td>
                    </tr>
                  </table>
                </Box>
                <Box p={2}>
                  <table className="public-form-general-info">
                    <tr>
                      <td>Urgency Level</td>
                      <td>{data?.urgency_level}</td>
                    </tr>

                    <tr>
                      <td>Expected Delivery Date</td>
                      <td>
                        {data?.expected_delivery_date
                          ? moment(data?.expected_delivery_date)?.format(
                              "DD-MM-YYYY"
                            )
                          : "N/A"}
                      </td>
                    </tr>

                    <tr>
                      <td>Delivery Date</td>
                      <td>
                        {data?.delivery_date
                          ? moment(data?.delivery_date)?.format("DD-MM-YYYY")
                          : "N/A"}
                      </td>
                    </tr>

                    <tr>
                      <td>Work Specifications</td>
                      <td>
                        <span
                          dangerouslySetInnerHTML={{
                            __html:
                              data?.agency_requirement_form?.data?.attributes
                                ?.remarks,
                          }}
                        />
                      </td>
                    </tr>
                  </table>
                </Box>
              </SimpleGrid>
            {/* </div> */}
          </div>

          <div className="public-form-section">
            <table style={{ width: "100%" }}>
              <tfoot>
                <tr>
                  <td colSpan={2}>
                    <div
                      style={{
                        background:
                          "url(/images/form-line.png) no-repeat center center",
                        backgroundAttachment: "cover",
                        backgroundSize: "100%",
                        height: 42,
                        marginTop: 24,
                        marginBottom: 10,
                      }}
                    />
                  </td>
                </tr>

                <tr className="text-center" style={{ fontSize: "14px" }}>
                  <td className="text-left" style={{ padding: "5px 0px" }}>
                    <img
                      title="QuickSO"
                      src="/images/form-logo.png"
                      alt="QuickSO"
                      style={{
                        height: "50px",
                        width: "auto",
                      }}
                    />
                  </td>
                  <td
                    className="text-right"
                    style={{ padding: "5px 0px", fontSize: "16px" }}
                  >
                    Copyright © 2022, All Rights Reserved by QuickSO
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

const styles = {};
