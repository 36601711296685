import React, { useState, useEffect } from "react";
import { DataTable } from "../../../components";
import { useNavigate, useLocation } from "react-router-dom";
import { Space, Button, Popconfirm, Tooltip, notification, Input } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import qs from "qs";
import config from "./config";
import { axios } from "../../../../../App";
import _ from "lodash";
import { MAX_PAGINATION_LIMIT } from "../../../../../constants";

const Page = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    pageSize: 10,
  });
  const [total, setTotal] = useState(0);
  const [searchValues, setSearchValues] = useState({});
  const [searched, setSearched] = useState(false);
  const [projectId, setProjectId] = useState("");
  const location = useLocation();

  const fetchData = async (params = null) => {
    try {
      setLoading(true);

      const queryObject = {
        populate: "*",
        sort: ["createdAt:desc"],
        pagination: {
          limit: MAX_PAGINATION_LIMIT,
        },
      };

      if (!_.isEmpty(params)) {
        queryObject["filters"] = params;
      }

      const response = await axios.get(
        `${config?.slugs?.plural}?${qs.stringify(queryObject)}`
      );

      if (response?.data) {
        setData(
          response?.data?.data?.map((item) => ({
            id: item?.id,
            ...item?.attributes,
          }))
        );

        setTotal(response?.data?.meta?.pagination?.total);

        setPagination({
          ...response?.data?.meta?.pagination,
          pageSize: 10,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const refreshData = () => {
    onSearch();
  };

  const deleteItem = async ({ id, status, amount, material_item }) => {
    try {
      if (status === "Pending") {
        const checkResponse = await axios.get(
          `/stock-ledgers/${material_item?.data?.id}`
        );

        if (checkResponse?.data) {
          const stockAmount = checkResponse?.data?.data?.attributes?.quantity;

          const returnResponse = await axios.put(
            `/stock-ledgers/${material_item?.data?.id}`,
            {
              data: {
                quantity: stockAmount + amount,
              },
            }
          );

          const deleteResponse = await axios.delete(
            `${config?.slugs?.plural}/${id}`
          );

          if (returnResponse?.data && deleteResponse?.data) {
            notification["success"]({
              message: `Remove ${config?.entityNames?.singular}`,
              description: `Return items to stock successfully!`,
            });

            notification["success"]({
              message: `Remove ${config?.entityNames?.singular}`,
              description: `Remove ${config?.entityNames?.singular} successfully!`,
            });

            refreshData();
          }
        } else {
          throw new Error();
        }
      } else {
        const response = await axios.delete(`${config?.slugs?.plural}/${id}`);

        if (response?.data) {
          notification["success"]({
            message: `Remove ${config?.entityNames?.singular}`,
            description: `Remove ${config?.entityNames?.singular} successfully!`,
          });

          refreshData();
        } else {
          throw new Error();
        }
      }
    } catch (error) {
      console.log("error", error);
      notification["error"]({
        message: `Remove ${config?.entityNames?.singular}`,
        description: `Remove ${config?.entityNames?.singular} failed!`,
      });
    }
  };

  // const returnItem = async (id, materialItemId, amount) => {
  //   try {
  //     const checkResponse = await axios.get(`/stock-ledgers/${materialItemId}`);

  //     if (checkResponse?.data) {
  //       const stockAmount = checkResponse?.data?.data?.attributes?.quantity;

  //       const response = await axios.put(`/stock-ledgers/${materialItemId}`, {
  //         data: {
  //           quantity: stockAmount + amount,
  //         },
  //       });

  //       if (response?.data) {
  //         await axios.put(`/${config?.slugs?.plural}/${id}`, {
  //           data: {
  //             amount: 0,
  //           },
  //         });

  //         onReset();

  //         notification["success"]({
  //           message: `Return Items`,
  //           description: `Return items successfully!`,
  //         });
  //       } else {
  //         throw new Error();
  //       }
  //     } else {
  //       throw new Error();
  //     }
  //   } catch (error) {
  //     notification["error"]({
  //       message: `Return Items`,
  //       description: `Return items failed!`,
  //     });
  //   }
  // };

  const getColumnFilterProps = ({ dataIndex, references = {} }) => {
    if (typeof references?.fieldName === "function") {
      const transformer = references?.fieldName;

      return {
        render: (data) => transformer(data?.data?.attributes) || "N/A",
      };
    } else {
      return {
        render: (data) =>
          data?.data?.attributes?.[references?.fieldName] || "N/A",
      };
    }
  };

  const getColumnProps = (column) => {
    if (column?.references) {
      return getColumnFilterProps(column);
    }

    return column;
  };

  const onSearch = () => {
    setSearched(true);

    let orConditions = {
      $or: [
        {
          description: {
            $containsi: searchValues?.keyword?.toLowerCase(),
          },
        },
      ],
    };

    let searchParams = orConditions;

    if (projectId !== "") {
      searchParams = {
        $and: [
          {
            task: parseInt(projectId),
          },
          orConditions,
        ],
      };
    }

    fetchData(searchParams);
  };

  const onReset = () => {
    setSearchValues({});
    fetchData(null);
    setSearched(false);
  };

  const getResultsMessage = () => {
    if (!_.isEmpty(searchValues) && !loading && searched) {
      return total > 0 ? (
        <span>
          <strong>{total}</strong> matched results
        </span>
      ) : (
        <span>No matched results</span>
      );
    }

    return (
      <span>
        <strong>{total}</strong> total records
      </span>
    );
  };

  useEffect(() => {
    const queryString = location?.search?.substring(1);

    if (queryString !== "") {
      const queryObject = qs.parse(queryString);

      if (queryObject?.projectId) {
        setProjectId(`${queryObject?.projectId}`);
        fetchData({
          task: parseInt(queryObject?.projectId),
        });
      } else {
        fetchData(filters);
      }
    } else {
      fetchData(filters);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.search]);

  return (
    <DataTable
      data={data}
      config={{
        ...config,
        columns: config?.columns
          ?.map((column) => ({
            ...column,
            ...getColumnProps(column),
          }))
          ?.concat({
            title: "Actions",
            fixed: "right",
            width: 100,
            selectable: false,
            render: ({ id, material_item, amount, status }) => {
              return (
                <Space>
                  <Tooltip
                    placement="bottom"
                    title={`Edit ${config?.entityNames?.singular}`}
                  >
                    <Button
                      icon={<EditOutlined />}
                      onClick={() =>
                        navigate(
                          `/quick-projects/${config?.slugs?.plural}/edit/${id}`
                        )
                      }
                    />
                  </Tooltip>

                  {/* 
                  {amount > 0 && (
                    <Tooltip placement="bottom" title={`Return Items`}>
                      <Popconfirm
                        title={`Are you sure to return back items for this ${config?.entityNames?.singular}?`}
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() =>
                          returnItem(id, material_item?.data?.id, amount)
                        }
                      >
                        <Button icon={<MdOutlineSettingsBackupRestore />} />
                      </Popconfirm>
                    </Tooltip>
                  )} */}

                  <Tooltip
                    placement="bottom"
                    title={`Remove ${config?.entityNames?.singular}`}
                  >
                    <Popconfirm
                      title={`Are you sure to remove this ${config?.entityNames?.singular}?`}
                      okText="Yes"
                      cancelText="No"
                      onConfirm={() =>
                        deleteItem({ id, status, amount, material_item })
                      }
                    >
                      <Button type="danger" icon={<DeleteOutlined />} />
                    </Popconfirm>
                  </Tooltip>
                </Space>
              );
            },
          }),
      }}
      loading={loading}
      pagination={pagination}
      actions={{
        onAdd: () => navigate(`/quick-projects/${config?.slugs?.plural}/add`),
        onRefresh: refreshData,
      }}
      Toolbar={
        <div className="toolbar">
          <Input
            value={searchValues?.keyword}
            placeholder="Search for keyword..."
            onChange={(e) =>
              setSearchValues((prev) => ({
                ...prev,
                keyword: e.target.value,
              }))
            }
          />

          <div className="toolbar-buttons">
            <p className="toolbar-results">{getResultsMessage()}</p>

            <Space>
              <Button onClick={onReset}>Reset</Button>
              <Button type="primary" onClick={onSearch}>
                Search
              </Button>
            </Space>
          </div>
        </div>
      }
    />
  );
};

export default Page;
