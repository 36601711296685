import { useEffect, useState } from "react";
import { Modal, Form, Button, Tabs } from "antd";
import PermissionsList from "./components/PermissionsList";
import PermissionAdd from "./components/PermissionAdd";
import { fetchData } from "../../../../utils/helpers/data.helper";

const TAB_IDS = {
  ALLOWED_ROLES: "ALLOWED_ROLES",
  ALLOWED_DEPARTMENTS: "ALLOWED_DEPARTMENTS",
  ALLOWED_EMPLOYEES: "ALLOWED_EMPLOYEES",
};

const CONTENT_TYPE_IDS = {
  ALLOWED_ROLES: "file-item-role-permissions",
  ALLOWED_DEPARTMENTS: "file-item-department-permissions",
  ALLOWED_EMPLOYEES: "file-item-employee-permissions",
};

const CONFIG_IDS = {
  ALLOWED_ROLES: "ROLE",
  ALLOWED_DEPARTMENTS: "DEPARTMENT",
  ALLOWED_EMPLOYEES: "EMPLOYEE",
};

const ListPage = ({
  activeTabId,
  contentTypeId,
  configId,
  setActiveTabId = () => {},
  setActivePage = () => {},
  setContentTypeId = () => {},
  setConfigId = () => {},
}) => {
  const onChangeTab = (tabId) => {
    setActiveTabId(tabId);
    setContentTypeId(CONTENT_TYPE_IDS?.[tabId]);
    setConfigId(CONFIG_IDS?.[tabId]);
  };

  const onAdd = () => {
    setActivePage("ADD");
  };

  return (
    <Tabs activeKey={activeTabId} onChange={onChangeTab}>
      <Tabs.TabPane tab="Roles" key={TAB_IDS.ALLOWED_ROLES}>
        {activeTabId === TAB_IDS.ALLOWED_ROLES && (
          <PermissionsList
            title="Allowed Roles"
            contentTypeId={contentTypeId}
            configId={configId}
            onAdd={onAdd}
          />
        )}
      </Tabs.TabPane>

      <Tabs.TabPane tab="Departments" key={TAB_IDS.ALLOWED_DEPARTMENTS}>
        {activeTabId === TAB_IDS.ALLOWED_DEPARTMENTS && (
          <PermissionsList
            title="Allowed Departments"
            contentTypeId={contentTypeId}
            configId={configId}
            onAdd={onAdd}
          />
        )}
      </Tabs.TabPane>

      <Tabs.TabPane tab="Employees" key={TAB_IDS.ALLOWED_EMPLOYEES}>
        {activeTabId === TAB_IDS.ALLOWED_EMPLOYEES && (
          <PermissionsList
            title="Allowed Employees"
            contentTypeId={contentTypeId}
            configId={configId}
            onAdd={onAdd}
          />
        )}
      </Tabs.TabPane>
    </Tabs>
  );
};

const PermissionsModal = ({
  visible,
  onClose,
  onFinish,
  currentFileItemId = null,
  data = {},
}) => {
  const [form] = Form.useForm();
  const [activePage, setActivePage] = useState("LIST");
  const [activeTabId, setActiveTabId] = useState(TAB_IDS.ALLOWED_ROLES);
  const [configId, setConfigId] = useState("");
  const [contentTypeId, setContentTypeId] = useState("");
  const [values, setValues] = useState({
    file_role: ["Viewer", "Editor"]?.map((item) => ({
      value: item,
      label: item,
    })),
  });
  const contentTypes = [
    {
      slug: "employees",
      transformer: (item) => ({
        value: item?.id,
        label: `${item?.first_name} ${item?.last_name}`,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          employee: data,
        })),
    },
    {
      slug: "app-roles",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          app_role: data,
        })),
    },
    {
      slug: "departments",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          department: data,
        })),
    },
  ];

  const handleClose = () => {
    onClose();
    setActivePage("LIST");
    setActiveTabId(TAB_IDS.ALLOWED_ROLES);
  };

  const backToListPage = () => {
    setActivePage("LIST");
  };

  useEffect(() => {
    contentTypes?.forEach((contentType) => {
      fetchData(contentType);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      name: data?.name,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Modal
      title={`Set Permissions for "${data?.name}"`}
      visible={visible}
      onOk={form.submit}
      onCancel={handleClose}
      width={1000}
      footer={[<Button onClick={handleClose}>Cancel</Button>]}
      bodyStyle={{ padding: "12px 24px" }}
      destroyOnClose
    >
      {activePage === "LIST" && (
        <ListPage
          activeTabId={activeTabId}
          contentTypeId={contentTypeId}
          configId={configId}
          setActivePage={setActivePage}
          setConfigId={setConfigId}
          setContentTypeId={setContentTypeId}
          setActiveTabId={setActiveTabId}
        />
      )}

      {activePage === "ADD" && (
        <PermissionAdd
          contentTypeId={contentTypeId}
          configId={configId}
          values={values}
          onBack={backToListPage}
        />
      )}
    </Modal>
  );
};

export default PermissionsModal;
