import config from "../config";

export const makeConfig = ({ navigate }) => ({
  id: `${config?.plural}-view`,
  title: `View ${config?.entityNames?.singular}`,
  pageTitle: `View ${config?.entityNames?.singular}`,
  breadcrumbs: [
    {
      path: "/quick-teams/dashboard",
      title: "Home",
    },
    {
      path: `/quick-teams/${config?.slugs?.plural}`,
      title: config?.entityNames?.plural,
    },
    {
      path: "",
      title: `View ${config?.entityNames?.singular}`,
    },
  ],
  components: [
    {
      span: 24,
      component: "input",
      label: "Subject",
      name: "subject",
      required: true,
      placeholder: "Enter subject",
      rules: [
        {
          required: true,
          message: "Subject is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Sender",
      name: "sender",
      required: true,
      placeholder: "Select sender",
      rules: [
        {
          required: true,
          message: "Sender is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Recipient",
      name: "recipients",
      required: true,
      placeholder: "Select recipients",
      rules: [
        {
          required: true,
          message: "Recipients is required!",
        },
      ],
      mode: "multiple",
    },
    {
      span: 12,
      component: "uploader",
      label: "Attachment",
      name: "attachment",
      placeholder: "Upload attachment",
    },
    {
      span: 12,
      component: "select",
      label: "Priority",
      name: "priority",
      required: true,
      placeholder: "Select priority",
      rules: [
        {
          required: true,
          message: "Priority is required!",
        },
      ],
    },
    {
      span: 24,
      component: "editor",
      label: "Message",
      name: "message",
      placeholder: "Enter message",
      rows: 5,
    },
    {
      span: 24,
      component: "input",
      type: "hidden",
      name: "attachment",
    },
  ],
  actions: {
    onBack: () => navigate(`/quick-teams/${config?.slugs?.plural}`),
  },
  ...config,
});
