import React from "react";
import { Route } from "react-router-dom";
import {
  DebitNoteList,
  DebitNoteAdd,
  DebitNoteEdit,
  DebitNoteReport,
} from "../pages/DebitNotes";

export const register = (props) => {
  const basePath = "debit-notes";

  return (
    <>
      <Route path={basePath} element={<DebitNoteList />} exact={true} />
      <Route path={`${basePath}/add`} element={<DebitNoteAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<DebitNoteEdit />} />
      <Route path={`${basePath}-report`} element={<DebitNoteReport />} />
    </>
  );
};
