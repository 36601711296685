import React from "react";
import { Route } from "react-router-dom";
import {
  LeaveApplicationList,
  LeaveApplicationAdd,
  LeaveApplicationEdit,
  LeaveApplicationReport,
} from "../pages/LeaveApplications";

export const register = (props) => {
  const basePath = "leave-applications";

  return (
    <>
      <Route path={basePath} element={<LeaveApplicationList />} exact={true} />
      <Route path={`${basePath}/add`} element={<LeaveApplicationAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<LeaveApplicationEdit />} />
      <Route path={`${basePath}-report`} element={<LeaveApplicationReport />} />
    </>
  );
};
