import React from "react";
import { Route } from "react-router-dom";
import {
  VendorPaymentScheduleList,
  VendorPaymentScheduleAdd,
  VendorPaymentScheduleEdit,
  VendorPaymentScheduleReport,
} from "../pages/VendorPaymentSchedules";

export const register = (props) => {
  const basePath = "vendor-payment-schedules";

  return (
    <>
      <Route
        path={basePath}
        element={<VendorPaymentScheduleList />}
        exact={true}
      />
      <Route path={`${basePath}/add`} element={<VendorPaymentScheduleAdd />} />
      <Route
        path={`${basePath}/edit/:id`}
        element={<VendorPaymentScheduleEdit />}
      />
      <Route
        path={`${basePath}-report`}
        element={<VendorPaymentScheduleReport />}
      />
    </>
  );
};
