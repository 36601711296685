import config from "../config";

export default {
  id: `${config?.slugs?.plural}-list`,
  pageTitle: config?.entityNames?.plural,
  breadcrumbs: [
    {
      path: "/quick-stocks/dashboard",
      title: "Home",
    },
    {
      path: "",
      title: config?.entityNames?.plural,
    },
  ],
  columns: [
    {
      title: "Code",
      dataIndex: "code",
      selectable: true,
      sorter: (a, b) => a?.code?.localeCompare(b?.code),
    },
    {
      title: "Name",
      dataIndex: "name",
      selectable: true,
      sorter: (a, b) => a?.name?.localeCompare(b?.name),
    },
    {
      title: "SAC Code",
      dataIndex: "sac_code",
      selectable: true,
      sorter: (a, b) => a?.sac_code?.localeCompare(b?.sac_code),
    },
  ],
  ...config,
};
