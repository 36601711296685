export default {
  slugs: {
    singular: "tds-type",
    plural: "tds-types",
  },
  entityNames: {
    singular: "TDS Type",
    plural: "TDS Types",
  },
};
