import React from "react";
import { Route } from "react-router-dom";
import {
  DailyReportList,
  DailyReportAdd,
  DailyReportEdit,
  DailyReportReport,
  DailyReportView,
} from "../pages/DailyReports";
import TimeLineVariance from "../pages/TimelineVariance/Report";
import ConstructionCostReport from "../pages/ConstructionCost/Report";

export const register = (props) => {
  const basePath = "daily-reports";

  return (
    <>
      <Route path={basePath} element={<DailyReportList />} exact={true} />
      <Route path={`${basePath}/add`} element={<DailyReportAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<DailyReportEdit />} />
      <Route path={`${basePath}/view/:id`} element={<DailyReportView />} />
      <Route path={`${basePath}-report`} element={<DailyReportReport />} />
      <Route
        path={`timeline-variance-report`}
        element={<TimeLineVariance />}
      />
      <Route
        path={`construction-cost-report`}
        element={<ConstructionCostReport />}
      />
    </>
  );
};
