import React from "react";
import { Route } from "react-router-dom";
import {
  ToDoList,
  ToDoAdd,
  ToDoEdit,
  ToDoView,
  ToDoReport,
} from "../pages/ToDos";

export const register = (props) => {
  const basePath = "to-dos";

  return (
    <>
      <Route path={basePath} element={<ToDoList />} exact={true} />
      <Route path={`${basePath}/add`} element={<ToDoAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<ToDoEdit />} />
      <Route path={`${basePath}/view/:id`} element={<ToDoView />} />
      <Route path={`${basePath}-report`} element={<ToDoReport />} />
    </>
  );
};
