export default {
  slugs: {
    singular: "to-do",
    plural: "to-dos",
  },
  entityNames: {
    singular: "To Do",
    plural: "To Dos",
  },
};
