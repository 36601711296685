export default {
  slugs: {
    singular: "agency-quotation",
    plural: "agency-quotations",
  },
  entityNames: {
    singular: "Agency Quotation",
    plural: "Agency Quotations",
  },
};
