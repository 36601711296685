import React, { useState, useEffect } from "react";
import { Collapse, Breadcrumb, Space, Button } from "antd";
import { Link } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import axios from "../../utils/axios";
import qs from "qs";

const { Panel } = Collapse;

const Page = (props) => {
  const [activeTab, setActiveTab] = useState("general");
  const [questions, setQuestions] = useState([]);
  const [activeItems, setActiveItems] = useState([]);
  const tabs = [
    {
      id: "general",
      name: "General",
    },
    {
      id: "quick-projects",
      name: "Quick Projects",
    },
    {
      id: "quick-teams",
      name: "Quick Teams",
    },
  ];

  const fetchData = async () => {
    try {
      const queryObject = {
        pagination: {
          page: 1,
          pageSize: 9999,
        },
      };
      const res = await axios.get(`/faqs?${qs.stringify(queryObject)}`);

      if (res?.data) {
        setQuestions(res?.data?.data?.map((item) => item?.attributes));
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setActiveItems([]);
  }, [activeTab]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <Breadcrumb style={styles.breadcrumbs}>
        <Breadcrumb.Item>
          <Link to="/">Home</Link>
        </Breadcrumb.Item>

        <Breadcrumb.Item>FAQs</Breadcrumb.Item>
      </Breadcrumb>

      <h1>Frequently Asked Questions</h1>

      <Space style={styles.tabs}>
        {tabs?.map((item) => (
          <Button
            key={item?.id}
            type={item?.id === activeTab ? "primary" : ""}
            onClick={() => setActiveTab(item?.id)}
          >
            {item?.name}
          </Button>
        ))}
      </Space>

      {questions?.filter((item) => item?.module === activeTab)?.length > 0 && (
        <Collapse activeKey={activeItems} onChange={setActiveItems}>
          {questions
            ?.filter((item) => item?.module === activeTab)
            ?.map((item) => (
              <Panel header={item?.question}>
                <ReactMarkdown>{item?.answer}</ReactMarkdown>
              </Panel>
            ))}
        </Collapse>
      )}
    </div>
  );
};

const styles = {
  breadcrumbs: {
    marginBottom: 24,
  },
  tabs: {
    marginBottom: 24,
  },
};

export default Page;
