import React, { useEffect, useState } from "react";
import { AddForm } from "../../../components";
import { useNavigate, useParams } from "react-router-dom";
import { makeConfig } from "./config";
import { axios } from "../../../../../App";
import moment from "moment";
import qs from "qs";
import { isEmpty } from "lodash";

const Page = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const config = makeConfig({ navigate });

  const [leaveTypes, setLeaveTypes] = useState([]);
  const [employees, setEmployees] = useState([]);

  const fetchLeaveTypes = async () => {
    try {
      const queryString = {};

      const response = await axios.get(
        `/leave-types?${qs.stringify(queryString)}`
      );

      if (response?.data) {
        setLeaveTypes(
          response?.data?.data?.map((item) => ({
            value: item?.id,
            label: item?.attributes?.name,
          }))
        );
      } else {
        throw new Error();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchEmployees = async () => {
    try {
      const response = await axios.get(`/employees`);

      if (response?.data) {
        setEmployees(
          response?.data?.data?.map((item) => ({
            value: item?.id,
            label: `${item?.attributes?.first_name} ${item?.attributes?.last_name}`,
          }))
        );
      } else {
        throw new Error();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchLeaveTypes();
    fetchEmployees();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const queryObject = {
          populate: "*",
        };

        const response = await axios.get(
          `/${config?.slugs?.plural}/${id}?${qs.stringify(queryObject)}`
        );

        if (response?.data) {
          let newData = {
            ...response?.data?.data?.attributes,
            id: response?.data?.data?.id,
            from_date: moment(response?.data?.data?.attributes?.from_date),
            to_date: moment(response?.data?.data?.attributes?.to_date),
            employee: response?.data?.data?.attributes?.employee?.data?.id,
            representative:
              response?.data?.data?.attributes?.representative?.data?.id,
            leave_type: response?.data?.data?.attributes?.leave_type?.data?.id,
          };

          if (
            isEmpty(newData?.attachment) ||
            isEmpty(newData?.attachment?.data)
          ) {
            newData["attachment"] = null;
          }

          setData(newData);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <AddForm
      config={config}
      data={data}
      loading={loading}
      values={{
        employee: employees,
        representative: employees,
        // approved_by: employees,
        leave_type: leaveTypes,
        status: ["Pending", "Rejected", "Approved"]?.map((item) => ({
          value: item,
          label: item,
        })),
      }}
    />
  );
};

export default Page;
