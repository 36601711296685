import React from "react";
import { Route } from "react-router-dom";
import {
  PettyCashList,
  PettyCashAdd,
  PettyCashEdit,
  PettyCashReport,
} from "../pages/PettyCashes";

export const register = (props) => {
  const basePath = "petty-cashes";

  return (
    <>
      <Route path={basePath} element={<PettyCashList />} exact={true} />
      <Route path={`${basePath}/add`} element={<PettyCashAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<PettyCashEdit />} />
      <Route path={`${basePath}-report`} element={<PettyCashReport />} />
    </>
  );
};
