import { useState } from "react";
import LeftPanel from "./components/LeftPanel";
import RightPanel from "./components/RightPanel";
import SplitPane from "split-pane-react";
import { DATE_HEADER_CELL_HEIGHT, DATA_ROW_HEIGHT } from "./constants";

import "split-pane-react/esm/themes/default.css";
import "./index.css";

const HORIZONTAL_SCROLL_BAR_HEIGHT = 10;

const App = ({
  tasks = [],
  miscData,
  onMenuClick = () => {},
  activeTaskId,
  setActiveTaskId,
  toggleExpandedId,
  expandedIds,
  gantt_update_permissions
}) => {
  const [sizes, setSizes] = useState(["50%", "50%"]);
  const [latestTime, setLatestTime] = useState(0); // Use this state to force update!

  return (
    <div
      style={{
        height:
          DATE_HEADER_CELL_HEIGHT +
          DATA_ROW_HEIGHT * tasks?.length +
          HORIZONTAL_SCROLL_BAR_HEIGHT,
      }}
    >
      <SplitPane sizes={sizes} onChange={(sizes) => setSizes(sizes)}>
        <LeftPanel
          tasks={tasks}
          miscData={miscData}
          onMenuClick={onMenuClick}
          setActiveTaskId={setActiveTaskId}
          setLatestTime={setLatestTime}
          toggleExpandedId={toggleExpandedId}
          expandedIds={expandedIds}
          gantt_update_permissions={gantt_update_permissions}
        />

        <RightPanel
          tasks={tasks}
          activeTaskId={activeTaskId}
          latestTime={latestTime}
          miscData={miscData}
          expandedIds={expandedIds}
        />
      </SplitPane>
    </div>
  );
};

export default App;
