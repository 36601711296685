import { useState, useEffect } from "react";
import { Modal } from "antd";

const ContentModal = ({
  title,
  visible,
  width = 1200,
  pages,
  initialPage = "list",
  actions,
  data,
  disabledItems,
  queries = {},
  onCancel = () => {},
}) => {
  const [pageId, setPageId] = useState("list");
  const [query, setQuery] = useState({});

  const handleCancel = () => {
    setPageId(initialPage);
    onCancel();
  };

  const renderPage = (pageId) => {
    const Component = pages?.[pageId];
    const actionsProp = actions({ setPageId, setQuery })?.[pageId];

    return (
      <Component
        mode="embed"
        actions={actionsProp}
        showsBreadcrumbs={false}
        data={data?.[pageId]}
        disabledItems={disabledItems?.[pageId]}
        query={{
          ...queries?.[pageId],
          ...query,
        }}
      />
    );
  };

  useEffect(() => {
    setPageId(initialPage);
  }, [initialPage]);

  return (
    <Modal
      title={title?.[pageId]}
      visible={visible}
      onCancel={handleCancel}
      width={width}
      footer={null}
      destroyOnClose={true}
    >
      {renderPage(pageId)}
    </Modal>
  );
};

export default ContentModal;
