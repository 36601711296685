export default {
  slugs: {
    singular: "vendor-quotation",
    plural: "vendor-quotations",
  },
  entityNames: {
    singular: "Vendor Quotation",
    plural: "Vendor Quotations",
  },
};
