import { axios } from "../../../../../App";
import config from "../config";

export const makeConfig = ({ navigate }) => ({
  id: `${config?.plural}-edit`,
  title: `Edit ${config?.entityNames?.singular}`,
  pageTitle: `Edit ${config?.entityNames?.singular}`,
  breadcrumbs: [
    {
      path: "/quick-projects/dashboard",
      title: "Home",
    },
    {
      path: `/quick-projects/${config?.slugs?.plural}`,
      title: config?.entityNames?.plural,
    },
    {
      path: "",
      title: `Edit ${config?.entityNames?.singular}`,
    },
  ],
  components: [
    // {
    //   span: 12,
    //   component: "select",
    //   label: "Employee",
    //   name: "employee",
    //   placeholder: "Select employee",
    //   required: true,
    //   rules: [
    //     {
    //       required: true,
    //       message: "Employee is required!",
    //     },
    //   ],
    // },
    {
      span: 12,
      component: "input",
      label: "Labour",
      name: "labour",
      required: true,
      placeholder: "Enter labour",
      rules: [
        {
          required: true,
          message: "Labour is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Manpower Type",
      name: "manpower_type",
      placeholder: "Select manpower type",
      required: true,
      rules: [
        {
          required: true,
          message: "Manpower type is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Project Location",
      name: "project_location",
      placeholder: "Select project location",
      required: true,
      rules: [
        {
          required: true,
          message: "Project location is required!",
        },
      ],
    },
    {
      span: 12,
      component: "uploader",
      label: "Attachment",
      name: "attachment",
      // required: true,
      placeholder: "Upload attachment",
      // rules: [
      //   {
      //     required: true,
      //     message: "Attachment is required!",
      //   },
      // ],
    },
    {
      span: 24,
      component: "editor",
      label: "Notes",
      name: "notes",
      placeholder: "Enter notes",
      rows: 5,
    },
    {
      span: 24,
      component: "input",
      type: "hidden",
      name: "attachment",
    },
  ],
  actions: {
    onBack: () => navigate(`/quick-projects/${config?.slugs?.plural}`),
    onFinish: async (values, data) => {
      try {
        const response = await axios.put(
          `/${config?.slugs?.plural}/${data?.id}`,
          {
            data: values,
          }
        );

        if (response?.data) {
          return {
            success: true,
            message: `Edit ${config?.entityNames?.singular?.toLowerCase()} successfully!`,
          };
        }

        throw new Error();
      } catch (error) {
        return {
          success: false,
          message: error?.response?.data?.error?.message,
        };
      }
    },
  },
  ...config,
});
