import { combineReducers } from "redux";
import authReducer from "./authReducer";
import clientUidReducer from "./clientUidReducer";
import notificationReducer from "./notificationReducer";

export default combineReducers({
  auth: authReducer,
  clientUid: clientUidReducer,
  notification: notificationReducer,
});
