export default {
  slugs: {
    singular: "released-note",
    plural: "released-notes",
  },
  entityNames: {
    singular: "Released Forms",
    plural: "Released Forms",
  },
};
