import { axios } from "../../../../../App";
import { v4 } from "uuid";
import config from "../config";
import { Modal, notification } from "antd";
import qs from "qs";

export const makeConfig = ({ navigate, auth }) => ({
  id: `${config?.slugs?.plural}-add`,
  title: `${config?.entityNames?.singular} - Release Materials`,
  pageTitle: `Add New ${config?.entityNames?.singular} - Release Materials`,
  breadcrumbs: [
    {
      path: "/quick-stocks/dashboard",
      title: "Home",
    },
    {
      path: `/quick-stocks/${config?.slugs?.plural}`,
      title: config?.entityNames?.plural,
    },
    {
      path: "",
      title: `${config?.entityNames?.singular} - Release Materials`,
    },
  ],
  components: [
    {
      span: 12,
      component: "select",
      label: "Project",
      name: "project",
      placeholder: "Select project",
      disabled: true,
    },
    {
      span: 12,
      component: "select",
      label: "Task",
      name: "task",
      placeholder: "Select task",
      disabled: true,
    },
    {
      span: 12,
      component: "select",
      label: "Task Material",
      name: "task_material",
      placeholder: "Select task material",
      disabled: true,
    },
    {
      span: 12,
      component: "number",
      label: "Releasable Quantity",
      name: "releasable_quantity",
      min: 0,
      disabled: true,
    },
    {
      span: 12,
      component: "number",
      label: "Released Quantity",
      name: "released_quantity",
      min: 0,
      // min: 1,
      // rules: [
      //   {
      //     min: 1,
      //     message: "Released quantity must be greater than 0!",
      //   },
      // ],
    },
    {
      span: 24,
      component: "editor",
      label: "Remarks",
      name: "remarks",
      placeholder: "Enter remarks",
      rows: 5,
    },
  ],
  actions: {
    onBack: () => navigate(`/quick-stocks/${config?.slugs?.plural}`),
    onFinish: async (values, data) => {
      try {
        const releasableQuantity = data?.releasable_quantity || 0;

        if (values?.released_quantity > releasableQuantity) {
          return {
            success: false,
            message: `The number of released material items must not be greater than the releasable quantity!`,
          };
        } else {
          const response = await axios.post(`/released-form-histories`, {
            data: {
              released_note: data?.id,
              quantity: values?.released_quantity,
              remarks: values?.remarks,
              // uid: v4(),
              // creator: auth?.user?.id,
            },
          });

          if (response?.data) {
            return {
              success: true,
              message: `Release ${config?.entityNames?.singular?.toLowerCase()} successfully!`,
            };
          }

          throw new Error();
        }
      } catch (error) {
        return {
          success: false,
          message: `Add new ${config?.entityNames?.singular?.toLowerCase()} failed!`,
        };
      }
    },
  },
  ...config,
});
