export default {
  slugs: {
    singular: "initial",
    plural: "initials",
  },
  entityNames: {
    singular: "Initial",
    plural: "Initials",
  },
};
