import React, { useState, useEffect } from "react";
import { AddForm } from "../../../components";
import { useNavigate, useParams } from "react-router-dom";
import { makeConfig } from "./config";
import { useSelector } from "react-redux";
import { fetchData } from "../../../../../utils/helpers/data.helper";
import { axios } from "../../../../../App";
import qs from "qs";

const Page = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const auth = useSelector((state) => state?.auth);
  const config = makeConfig({ navigate, auth });
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    urgency_level: ["Low", "Medium", "High"]?.map((item) => ({
      value: item,
      label: item,
    })),
  });
  const contentTypes = [
    {
      slug: "material-groups",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          material_group: data,
        })),
    },
    {
      slug: "vendors",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          vendor: data,
        })),
    },
    {
      slug: "projects",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          project: data,
        })),
    },
    {
      slug: "vendor-terms",
      transformer: (item) => ({
        value: item?.id,
        label: item?.heading,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          vendor_term: data,
        })),
    },
    {
      slug: "locations",
      query: {
        populate: "*",
      },
      transformer: (item) => ({
        value: item?.id,
        label: `${item?.name} (${item?.location_type?.data?.attributes?.name})`,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          delivery_address: data,
        })),
    },
  ];

  useEffect(() => {
    contentTypes?.forEach((contentType) => {
      fetchData(contentType);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const queryObject = {
          populate: ["material_item.material_group", "task.project"],
        };

        const response = await axios.get(
          `/task-materials/${id}?${qs.stringify(queryObject)}`
        );

        if (response?.data) {
          const materialItem =
            response?.data?.data?.attributes?.material_item?.data?.attributes;
          const projectId =
            response?.data?.data?.attributes?.task?.data?.attributes?.project
              ?.data?.id;

          setData({
            heading: `Material Requisition for ${materialItem?.name}`,
            project: projectId,
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return <AddForm config={config} values={values} data={data} />;
};

export default Page;
