import { axios } from "../../../../../App";
import { v4 } from "uuid";
import config from "../config";

export const makeConfig = ({ navigate, auth }) => ({
  id: `${config?.slugs?.plural}-add`,
  title: `Add New ${config?.entityNames?.singular}`,
  pageTitle: `Add New ${config?.entityNames?.singular}`,
  breadcrumbs: [
    {
      path: "/quick-teams/dashboard",
      title: "Home",
    },
    {
      path: `/quick-teams/${config?.slugs?.plural}`,
      title: config?.entityNames?.plural,
    },
    {
      path: "",
      title: `Add New ${config?.entityNames?.singular}`,
    },
  ],
  components: [
    {
      span: 12,
      component: "select",
      label: "Employee",
      name: "employee",
      required: true,
      placeholder: "Select employee",
      rules: [
        {
          required: true,
          message: "Employee is required!",
        },
      ],
    },
    {
      span: 12,
      component: "input",
      label: "Particular",
      name: "particular",
      required: true,
      placeholder: "Enter particular",
      rules: [
        {
          required: true,
          message: "Particular is required!",
        },
      ],
    },
    {
      span: 12,
      component: "date",
      label: "Date",
      name: "date",
      required: true,
      placeholder: "Select date",
      rules: [
        {
          required: true,
          message: "Date is required!",
        },
      ],
    },
    {
      span: 12,
      component: "uploader",
      label: "Attachment",
      name: "attachment",
      required: true,
      placeholder: "Upload attachment",
      rules: [
        {
          required: true,
          message: "Attachment is required!",
        },
      ],
    },
    {
      span: 12,
      component: "input",
      type: "number",
      label: "Amount",
      name: "amount",
      required: true,
      placeholder: "Enter amount",
      rules: [
        {
          required: true,
          message: "Amount is required!",
        },
      ],
    },
    {
      span: 12,
      component: "input",
      type: "number",
      label: "Quantity",
      name: "quantity",
      required: true,
      placeholder: "Enter quantity",
      rules: [
        {
          required: true,
          message: "Quantity is required!",
        },
      ],
    },
    {
      span: 24,
      component: "editor",
      label: "Remarks",
      name: "remarks",
      placeholder: "Enter remarks",
      rows: 5,
    },
    {
      span: 24,
      component: "input",
      type: "hidden",
      name: "attachment",
    },
  ],
  actions: {
    onBack: () => navigate(`/quick-teams/${config?.slugs?.plural}`),
    onFinish: async (values) => {
      try {
        const response = await axios.post(`/${config?.slugs?.plural}`, {
          data: {
            ...values,
            uid: v4(),
            // creator: auth?.user?.id,
          },
        });

        if (response?.data) {
          return {
            success: true,
            message: `Add new ${config?.entityNames?.singular?.toLowerCase()} successfully!`,
          };
        }

        throw new Error();
      } catch (error) {
        return {
          success: false,
          message: `Add new ${config?.entityNames?.singular?.toLowerCase()} failed!`,
        };
      }
    },
  },
});
