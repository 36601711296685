import React, { useState, useEffect } from "react";
import { AddForm } from "../../../components";
import { useNavigate } from "react-router-dom";
import { makeConfig } from "./config";
import { useSelector } from "react-redux";
import { fetchData } from "../../../../../utils/helpers/data.helper";

const Page = (props) => {
  const navigate = useNavigate();
  const auth = useSelector((state) => state?.auth);
  const config = makeConfig({ navigate, auth });
  const [values, setValues] = useState({
    is_loan: [true, false]?.map((item) => ({
      value: item,
      label: item === true ? "Yes" : "No",
    })),
    is_consumable: [true, false]?.map((item) => ({
      value: item,
      label: item === true ? "Yes" : "No",
    })),
  });
  const contentTypes = [
    {
      slug: "employees",
      query: {
        sort: ["first_name:asc"],
        filters: {
          is_deleted: false,
        },
      },
      transformer: (item) => ({
        value: item?.id,
        label: `${item?.first_name} ${item?.last_name}`,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          employee: data,
        })),
    },
    {
      slug: "tool-types",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          tool_type: data,
        })),
    },
    {
      slug: "manufacturers",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          manufacturer: data,
        })),
    },
    {
      slug: "locations",
      query: {
        populate: "*",
      },
      transformer: (item) => ({
        value: item?.id,
        label: `${item?.name} (${item?.location_type?.data?.attributes?.name})`,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          location: data,
        })),
    },
  ];

  useEffect(() => {
    contentTypes?.forEach((contentType) => {
      fetchData(contentType);
    });
  }, []);

  return <AddForm config={config} values={values} />;
};

export default Page;
