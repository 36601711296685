import React, { useState, useEffect } from "react";
import { Layout, Space, Tooltip, Drawer, Divider, List, Avatar } from "antd";
import logo from "../../assets/images/logo.png";
import { logOutUser } from "../../redux/actions/authActions";
import { unsetClientUid } from "../../redux/actions/clientUidActions";
import { useDispatch, useSelector } from "react-redux";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { BiCalendarStar } from "react-icons/bi";
import { IoNotificationsOutline } from "react-icons/io5";
import { BsChatDots } from "react-icons/bs";
import FeatureSearchBox from "../../components/FeatureSeachBox";
// import axios from "../../utils/axios";
import { axios } from "../../App";
import { updateUnreadCount } from "../../redux/actions/notificationActions";

import "antd/dist/antd.css";
import "react-quill/dist/quill.snow.css";
import { Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

const { Header, Content, Footer } = Layout;
const currentYear = new Date().getFullYear();

const Page = ({ breadcrumbs }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, loggedIn } = useSelector((state) => state?.auth);
  const [showAccount, setShowAccount] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);
  const { unread_count } = useSelector((state) => state?.notification);
  const { t } = useTranslation();
  const menuItems = [
    {
      title: "Profile",
      onClick: () => {
        navigate("/account/profile");
        toggleAccount();
      },
    },
    {
      title: "Change Password",
      onClick: () => {
        navigate("/account/change-password");
        toggleAccount();
      },
    },
    {
      title: "Notifications",
      onClick: () => {
        showNotifications();
        toggleAccount();
      },
    },
    {
      title: "Log out",
      onClick: async () => {
        await axios.post(`/app-auth/log-out`, {
          uid: user?.uid,
        });

        dispatch(unsetClientUid());
        dispatch(logOutUser());
      },
    },
  ];

  const toggleAccount = () => {
    setShowAccount(!showAccount);
  };

  const showNotifications = () => {
    navigate("/account/notifications");
  };

  const fetchUnreadNotifications = async () => {
    try {
      const response = await axios.post(`/notifications/count/unread`, {
        recipient_uid: user?.uid,
      });

      if (response?.data) {
        dispatch(updateUnreadCount(response?.data?.data?.count));
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchUnreadNotifications();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      <Header style={styles.header}>
        <Link to="/">
          <img src={logo} alt="Logo" style={styles.logo} />
        </Link>

        {loggedIn && (
          <Space size="middle">
            {/* <AutoComplete
              dropdownMatchSelectWidth={250}
              style={{ width: 250 }}
              options={searchItems}
              onSelect={(data) => navigate(data)}
            >
              <Input.Search size="large" placeholder="input here" />
            </AutoComplete> */}

            <FeatureSearchBox navigate={navigate} />

            <Tooltip placement="center" title="Project Chats">
              <BsChatDots
                style={styles.icon}
                onClick={() => navigate("/quick-talks/project-chats")}
              />
            </Tooltip>

            <Tooltip placement="center" title="Calendar">
              <BiCalendarStar
                style={styles.icon}
                onClick={() => navigate("/quick-talks/calendar")}
              />
            </Tooltip>

            <Tooltip
              placement="bottom"
              title="Notifications"
              onClick={showNotifications}
            >
              <div style={styles.notificationIcon}>
                <IoNotificationsOutline style={styles.icon} />

                {unread_count > 0 && (
                  <>
                    {unread_count >= 100 ? (
                      <span style={styles.notificationCount}>99+</span>
                    ) : (
                      <span style={styles.notificationCount}>
                        {unread_count}
                      </span>
                    )}
                  </>
                )}
              </div>
            </Tooltip>

            <Divider type="vertical" style={styles.menuDivider} />

            <Space style={styles.profile} onClick={toggleAccount}>
              <span style={styles.avatarContainer}>
                <Avatar
                  src={user?.avatar || "/images/no-avatar.png"}
                  style={styles.avatar}
                ></Avatar>

                <span style={styles.onlineDot}></span>
              </span>

              <span>{user?.full_name}</span>
            </Space>
          </Space>
        )}
      </Header>

      <Content style={styles.contentContainer}>
        <Outlet />
      </Content>

      <Footer style={styles.footer}>
        <Text fontSize={{ base: "12px", md: "15px" }}>
          {t('Copyright')} &copy; {currentYear}, {t('QuickSO - All Right Reserved')}
        </Text>

        <Space>
          <Link to="/faqs">FAQ</Link>
          <Divider type="vertical" />
          <a href="https://www.quickso.in/privacy" target="_blank">
            Privacy
          </a>
          {/* <Link to="/user-guide">User Guide</Link> */}
        </Space>
      </Footer>

      <Drawer
        title="Account"
        placement="right"
        onClose={toggleAccount}
        visible={showAccount}
      >
        <List
          itemLayout="horizontal"
          dataSource={menuItems}
          renderItem={(item) => (
            <List.Item style={styles.listItem} onClick={item?.onClick}>
              <List.Item.Meta title={<span>{item.title}</span>} />
            </List.Item>
          )}
        />
      </Drawer>
    </Layout>
  );
};

const styles = {
  header: {
    background: "white",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 24px",
    borderBottom: "1px solid #eee",
  },
  logo: {
    maxHeight: 42,
  },
  contentContainer: {
    padding: 24,
    minHeight: "86.5vh",
  },
  avatar: {
    backgroundColor: "#f56a00",
    cursor: "pointer",
  },
  icon: {
    fontSize: 24,
    cursor: "pointer",
  },
  notificationIcon: {
    position: "relative",
  },
  notificationCount: {
    position: "absolute",
    top: "-12px",
    right: "-12px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "24px",
    height: "24px",
    borderRadius: "50%",
    backgroundColor: "red",
    color: "white",
    fontSize: "10px",
    cursor: "pointer",
    userSelect: "none",
    fontWeight: "bold",
  },
  rightMenu: {
    display: "flex",
    alignItems: "center",
  },
  footer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    background: "white",
    padding: "12px 24px",
    color: "#888",
  },
  listItem: {
    cursor: "pointer",
  },
  avatarContainer: {
    position: "relative",
  },
  onlineDot: {
    height: 10,
    width: 10,
    background: "#52c41a",
    borderRadius: "100%",
    display: "inline-block",
    position: "absolute",
    bottom: -10,
    right: 0,
  },
  menuDivider: {
    background: "#eee",
    height: 32,
  },
  profile: {
    cursor: "pointer",
  },
};

export default Page;
