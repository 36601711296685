import config from "../config";

export default {
  id: `${config?.slugs?.plural}-list`,
  pageTitle: config?.entityNames?.plural,
  breadcrumbs: [
    {
      path: "/quick-stocks/dashboard",
      title: "Home",
    },
    {
      path: "",
      title: config?.entityNames?.plural,
    },
  ],
  columns: [
    {
      title: "Name",
      dataIndex: "name",
      selectable: true,
      sorter: (a, b) => a?.name?.localeCompare(b?.name),
      width: 300,
    },
    {
      title: "Agency Group",
      dataIndex: "agency_group",
      selectable: true,
      references: {
        fieldName: "name",
      },
    },
    {
      title: "Company Type",
      dataIndex: "firm_type",
      selectable: true,
      references: {
        fieldName: "name",
      },
    },
    {
      title: "City",
      dataIndex: "city",
      selectable: true,
      references: {
        fieldName: "name",
      },
    },
    {
      title: "State",
      dataIndex: "state",
      selectable: true,
      references: {
        fieldName: "name",
      },
    },
  ],
  ...config,
};
