/* eslint-disable import/no-anonymous-default-export */
import config from "../config";
import moment from "moment";
import { Tag } from "antd";

export default {
  id: `${config?.slugs?.plural}-list`,
  pageTitle: `${config?.entityNames?.plural} Report`,
  code: "TO_DOS",
  breadcrumbs: [
    {
      path: "/quick-talks/dashboard",
      title: "Home",
    },
    {
      path: `/quick-talks/reports`,
      title: "Reports",
    },
    {
      path: "",
      title: `${config?.entityNames?.plural} Report`,
    },
  ],
  columns: [
    {
      title: "Name",
      dataIndex: "name",
      selectable: true,
      sorter: (a, b) => a?.name?.localeCompare(b?.name),
    },
    {
      title: "Time",
      dataIndex: "time",
      selectable: true,
      sorter: (a, b) => moment(a.time).unix() - moment(b.time).unix(),
      render: (text) => (
        <span>{moment(text).format("DD-MM-YYYY, HH:mm:ss")}</span>
      ),
      width: 250,
    },
    {
      title: "Status",
      dataIndex: "status",
      selectable: true,
      render: (text) => <Tag>{text}</Tag>,
    },
  ],
  ...config,
};
