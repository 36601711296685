export default {
  slugs: {
    singular: "bank",
    plural: "banks",
  },
  entityNames: {
    singular: "Bank",
    plural: "Banks",
  },
};
