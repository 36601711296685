import React, { useEffect, useState } from "react";
import { AddForm } from "../../../components";
import { useNavigate, useParams } from "react-router-dom";
import { makeConfig } from "./config";
import { axios } from "../../../../../App";
import { fetchData } from "../../../../../utils/helpers/data.helper";
import qs from "qs";
import { v4 } from "uuid";
import { Button, Col, Collapse, Input, Row, Space, Table, notification } from "antd";
import ReactQuill from "react-quill";
import { DeleteOutlined } from "@ant-design/icons";


const { Panel } = Collapse;


const Page = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    work_item: [],
    all_work_item: [],
  });
  const [terms, setTerms] = useState([])
  const [tableTerms, setTablTerms] = useState([])

  const config = makeConfig({ navigate });
  const contentTypes = [
    {
      slug: "work-groups",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          work_group: data,
        })),
    },
    {
      slug: "work-items",
      query: {
        populate: "*",
      },
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          all_work_item: data,
        })),
    },
    {
      slug: "work-units",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          work_unit: data,
        })),
    },
    {
      slug: "sub-contractors",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          sub_contractor: data,
        })),
    },
    {
      slug: "employees",
      transformer: (item) => ({
        value: item?.id,
        label: `${item?.first_name} ${item?.last_name}`,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          //  approved_by: data,
        })),
    },
    {
      slug: "employees/approvers",
      transformer: (item) => ({
        value: item?.id,
        label: `${item?.first_name} ${item?.last_name}`,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          approved_by: data,
        })),
    },
  ];

  const onAddTerms = () => {
    setTerms((prev) =>
      prev?.concat({
        id: v4(),
        term_name: '',
        term_details: ''
      })
    );
  };

  // State to hold filtered material items for each boq item
  const onEditTerms = (id, key, value) => {
    setTerms((prev) =>
      prev.map((item) => {
        if (item.id === id) {
          const updatedItem = { ...item, [key]: value };
          return updatedItem;
        }
        return item;
      })
    );
  };

  const onRemoveTerms = (id) => {
    setTerms((prev) => prev?.filter((item) => item?.id !== id));

    notification["success"]({
      message: `Remove Term`,
      description: `Removed Term successfully!`,
    });
  };

  const onAddTableTerms = () => {
    setTablTerms((prev) =>
      prev?.concat({
        id: v4(),
        table_term_name: '',
        table_term_details: ''
      })
    );
  };

  const columns = [
    {
      title: 'Term Name',
      dataIndex: 'table_term_name',
      render: (_, record) => (
        <Input
          value={record.table_term_name}
          onChange={(e) => handleInputChange(e, record.id, 'table_term_name')}
          placeholder="Enter term name"
        />
      )
    },
    {
      title: 'Term Details',
      dataIndex: 'table_term_details',
      render: (_, record) => (
        <Input
          value={record.table_term_details}
          onChange={(e) => handleInputChange(e, record.id, 'table_term_details')}
          placeholder="Enter term details"
          disabled
        />
      )
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (_, record) => (
        <Button type="link" danger onClick={() => onRemoveTableTerms(record.id)}>
          <DeleteOutlined />
        </Button>
      )
    }
  ];

  // State to hold filtered material items for each boq item
  const handleInputChange = (e, id, field) => {
    const { value } = e.target;
    setTablTerms((prevTerms) =>
      prevTerms.map((term) =>
        term.id === id ? { ...term, [field]: value } : term
      )
    );
  };

  const onRemoveTableTerms = (id) => {
    setTablTerms((prevTerms) => prevTerms.filter((term) => term.id !== id));

    notification["success"]({
      message: `Remove Term`,
      description: `Removed Term successfully!`,
    });
  };

  useEffect(() => {
    contentTypes?.forEach((contentType) => {
      fetchData(contentType);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const queryObject = {
          populate: "*",
        };

        const response = await axios.get(
          `/${config?.slugs?.plural}/${id}?${qs.stringify(queryObject)}`
        );

        if (response?.data) {
          console.log('terms editttt', response?.data);

          setData({
            ...response?.data?.data?.attributes,
            id: response?.data?.data?.id,
            work_group: response?.data?.data?.attributes?.work_group?.data?.id,
            work_item: response?.data?.data?.attributes?.work_item?.data?.id,
            work_unit: response?.data?.data?.attributes?.work_unit?.data?.id,
            sub_contractor:
              response?.data?.data?.attributes?.sub_contractor?.data?.id,
            approved_by:
              response?.data?.data?.attributes?.approved_by?.data?.id,
          });

          setTerms(response?.data?.data?.attributes?.term_and_condition?.terms)
          setTablTerms(response?.data?.data?.attributes?.term_and_condition?.table_terms)
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (data) {
      setValues((prev) => ({
        ...prev,
        work_item: values?.all_work_item
          ?.filter((item) => item?.work_group?.data?.id === data?.work_group)
          ?.map((item) => ({
            value: item?.id,
            label: item?.name,
          })),
      }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    setData(prev => ({
      ...prev,
      term_and_condition: { terms, table_terms: tableTerms }
    }))
  }, [terms, tableTerms]);

  console.log('================================', data)

  return (
    <AddForm
      config={config}
      data={data}
      loading={loading}
      values={values}
      onFieldChange={{
        work_group: (antForm) => {
          const workGroupId = antForm.getFieldValue("work_group");

          antForm.setFieldValue("work_item", undefined);

          if (!workGroupId) {
            setValues((prev) => ({
              ...prev,
              work_item: [],
            }));
          } else {
            setValues((prev) => ({
              ...prev,
              work_item: values?.all_work_item
                ?.filter((item) => item?.work_group?.data?.id === workGroupId)
                ?.map((item) => ({
                  value: item?.id,
                  label: item?.name,
                })),
            }));
          }
        },
      }}
      children={
        <div>
          {terms?.map((data, index) => (
            <div key={index} style={{ marginBottom: '20px' }}>
              <Collapse>
                <Panel header={`${data?.term_name ?? `Terms & Condition ${index + 1}`}`} key="uniqueKey">
                  <div style={{ border: '1px solid #e8e8e8', padding: '20px', marginBottom: '10px' }}>
                    <Row gutter={24}>

                      {/* term_name Input */}
                      <Col span={10}>
                        <Input
                          type="text"
                          placeholder="Enter Term Name"
                          value={data?.term_name}
                          onChange={(e) =>
                            onEditTerms(data?.id, "term_name", e.target.value)
                          }
                          style={{ width: "100%" }}
                        />
                      </Col>
                    </Row>

                    <Row gutter={24} style={{ marginTop: '10px' }}>
                      {/* Estimated Labour Cost */}
                      <Col span={24}>

                        <ReactQuill
                          placeholder="Enter Term Description"
                          value={data?.term_details}
                          onChange={(value) =>
                            onEditTerms(data?.id, "term_details", value) // Pass `value` directly
                          }
                          style={{ width: "100%" }}
                        />

                      </Col>
                    </Row>
                  </div>


                  {/* Delete Button */}
                  <Space style={styles.buttons}>
                    <Button type="danger" onClick={() => onRemoveTerms(data?.id)}>
                      Delete
                    </Button>
                  </Space>
                </Panel>
              </Collapse>
            </div>
          ))}

          <Space style={{ width: '100%', marginBottom: '20px' }}>
            <Space style={{ width: '100%' }}>
              <Button type="secondary" onClick={() => onAddTerms()} >
                Add Terms & Conditions
              </Button>
            </Space>
          </Space>

          <div>
            <Table
              columns={columns}
              dataSource={tableTerms}
              rowKey="id"
              pagination={false}
              bordered
            />
            <Button
              type="secondary"
              onClick={onAddTableTerms}
              style={{ margin: '20px 0' }}
            >
              Add Table Term
            </Button>
          </div>
        </div>
      }
    />
  );
};

const styles = {
  pageHeader: {
    padding: 0,
    paddingBottom: 24,
  },
  form: {
    width: "100%",
  },
  buttons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 16,
  },
  loaderContainer: {
    textAlign: "center",
    padding: 12,
  },
  header: {
    marginBottom: 12,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  errors: {
    marginBottom: 24,
  },
  errorsTitle: {
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: 6,
  },
  alert: {
    marginBottom: 6,
  },
};

export default Page;
