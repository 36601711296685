import React from "react";
import { Route } from "react-router-dom";
import {
  SubContractorGroupList,
  SubContractorGroupAdd,
  SubContractorGroupEdit,
} from "../pages/SubContractorGroups";

export const register = (props) => {
  const basePath = "sub-contractor-groups";

  return (
    <>
      <Route
        path={basePath}
        element={<SubContractorGroupList />}
        exact={true}
      />
      <Route path={`${basePath}/add`} element={<SubContractorGroupAdd />} />
      <Route
        path={`${basePath}/edit/:id`}
        element={<SubContractorGroupEdit />}
      />
    </>
  );
};
