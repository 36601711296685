export default {
  slugs: {
    singular: "task-document",
    plural: "task-documents",
  },
  entityNames: {
    singular: "Task Document",
    plural: "Task Documents",
  },
};
