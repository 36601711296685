import {
  MdArrowBack,
  MdEditNote,
  MdMarkEmailUnread,
  MdDeleteSweep,
  MdEventAvailable,
  MdDashboard,
  MdChecklist,
} from "react-icons/md";
import { GiAlarmClock } from "react-icons/gi";
import { SiGooglemeet } from "react-icons/si";
import {
  RiDashboardLine,
  RiMailAddLine,
  RiMailStarFill,
  RiMailCheckFill,
  RiMailForbidFill,
  RiTimerFlashLine,
} from "react-icons/ri";
import { BsPeopleFill, BsChatDots } from "react-icons/bs";
import { IoIosPeople } from "react-icons/io";
import { IoCalendarNumberOutline } from "react-icons/io5";
import { AiOutlineWechat, AiOutlineMail } from "react-icons/ai";
import { TbReportAnalytics } from "react-icons/tb";
import { GoCommentDiscussion } from "react-icons/go";
import { RiPieChart2Fill } from "react-icons/ri";

const BASE_SLUG = "/quick-talks";

export const makeNavbar = ({ navigate, user }) => {
  const makeItem = ({ icon, id, label, scopes }) => {
    return {
      id,
      key: `${BASE_SLUG}/${id}`,
      label,
      icon,
      onClick: () => navigate(`${BASE_SLUG}/${id}`),
      scopes,
    };
  };

  let roles = [];

  user?.app_roles?.forEach((appRole) => {
    appRole?.permissions?.forEach((appPermission) => {
      if (!roles?.includes(appPermission)) {
        roles?.push(appPermission);
      }
    });
  });

  roles = roles?.join(",");

  const menuItems = [
    makeItem({
      id: "dashboard",
      label: "Dashboard",
      icon: <RiPieChart2Fill style={styles.icon} />,
      scopes: [
        {
          id: "view",
          label: "View",
        },
      ],
    }),
    makeItem({
      id: "calendar",
      label: "Calendar",
      icon: <IoCalendarNumberOutline style={styles.icon} />,
    }),
    {
      key: "meetings",
      label: "Meetings",
      icon: <SiGooglemeet style={styles.icon} />,
      children: [
        makeItem({
          id: "meetings",
          label: "Meetings",
          icon: <SiGooglemeet style={styles.icon} />,
        }),
        makeItem({
          id: "meeting-notes",
          label: "Meeting Notes",
          icon: <MdEditNote style={styles.icon} />,
        }),
        makeItem({
          id: "meeting-comments",
          label: "Meeting Comments",
          icon: <GoCommentDiscussion style={styles.icon} />,
        }),
        makeItem({
          id: "meeting-types",
          label: "Meeting Types",
          icon: <RiDashboardLine style={styles.icon} />,
        }),
      ],
    },
    {
      id: "events",
      label: "Events",
      icon: <MdEventAvailable style={styles.icon} />,
      children: [
        makeItem({
          id: "events",
          label: "Events",
          icon: <MdEventAvailable style={styles.icon} />,
        }),
        // makeItem({
        //   id: "event-templates",
        //   label: "Event Templates",
        //   icon: <RiDashboardLine style={styles.icon} />,
        // }),
      ],
    },
    {
      id: "reminders",
      label: "Reminders",
      icon: <RiTimerFlashLine style={styles.icon} />,
      children: [
        makeItem({
          id: "reminders",
          label: "Reminders",
          icon: <RiTimerFlashLine style={styles.icon} />,
        }),
        // makeItem({
        //   id: "reminder-templates",
        //   label: "Reminder Templates",
        //   icon: <RiDashboardLine style={styles.icon} />,
        // }),
      ],
    },
    makeItem({
      id: "to-dos",
      label: "To Dos",
      icon: <MdChecklist style={styles.icon} />,
    }),
    makeItem({
      id: "app-emails",
      label: "Emails",
      icon: <AiOutlineMail style={styles.icon} />,
    }),
    // {
    //   key: "emails",
    //   label: "Emails",
    //   icon: <AiOutlineMail style={styles.icon} />,
    //   children: [
    //     makeItem({
    //       id: "email-compose",
    //       label: "Compose",
    //       icon: <RiMailAddLine style={styles.icon} />,
    //     }),
    //     makeItem({
    //       id: "email-starred",
    //       label: "Starred",
    //       icon: <RiMailStarFill style={styles.icon} />,
    //     }),
    //     makeItem({
    //       id: "email-inbox",
    //       label: "Inbox",
    //       icon: <MdMarkEmailUnread style={styles.icon} />,
    //     }),
    //     makeItem({
    //       id: "email-sent",
    //       label: "Sent",
    //       icon: <RiMailCheckFill style={styles.icon} />,
    //     }),
    //     makeItem({
    //       id: "email-drafts",
    //       label: "Drafts",
    //       icon: <RiMailForbidFill style={styles.icon} />,
    //     }),
    //     makeItem({
    //       id: "email-trash",
    //       label: "Trash",
    //       icon: <MdDeleteSweep style={styles.icon} />,
    //     }),
    //   ],
    // },
    {
      key: "chats",
      label: "Chats",
      icon: <BsChatDots style={styles.icon} />,
      children: [
        // makeItem({
        //   id: "employee-group-chats",
        //   label: "Employee Group Chats",
        //   icon: <IoIosPeople style={styles.icon} />,
        // }),
        makeItem({
          id: "project-chats",
          label: "Project Chats",
          icon: <BsChatDots style={styles.icon} />,
        }),
        // makeItem({
        //   id: "employee-chats",
        //   label: "Employee Chats",
        //   icon: <BsPeopleFill style={styles.icon} />,
        // }),
      ],
    },
    // {
    //   key: "reports",
    //   label: "Reports",
    //   icon: <TbReportAnalytics style={styles.icon} />,
    //   children: [
    //     makeItem({
    //       id: "meetings-report",
    //       label: "Meetings",
    //       icon: <SiGooglemeet style={styles.icon} />,
    //     }),
    //     makeItem({
    //       id: "meeting-notes-report",
    //       label: "Meeting Notes",
    //       icon: <MdEditNote style={styles.icon} />,
    //     }),
    //     makeItem({
    //       id: "reminders-report",
    //       label: "Reminders",
    //       icon: <GiAlarmClock style={styles.icon} />,
    //     }),
    //   ],
    // },
    makeItem({
      id: "reports",
      label: "Reports",
      icon: <TbReportAnalytics style={styles.icon} />,
      scopes: [
        {
          id: "view",
          label: "View",
        },
      ],
    }),
    {
      key: "back-to-home",
      label: "Return Home",
      icon: <MdArrowBack style={styles.icon} />,
      onClick: () => navigate("/"),
    },
  ];

  let filteredItems = [];

  if (user) {
    menuItems?.forEach((item) => {
      const uid = item?.children ? item?.key : item?.id;

      if (item?.key === "back-to-home") {
        filteredItems?.push(item);
      }

      if (item?.children) {
        const newChildren = item?.children?.filter((item) => {
          const subItemUid = item?.id;

          return roles?.includes(`QUICK-TALKS.${subItemUid?.toUpperCase()}`);
        });

        if (newChildren?.length > 0) {
          item["children"] = newChildren;
          filteredItems?.push(item);
        }
      } else {
        if (roles?.includes(`QUICK-TALKS.${uid?.toUpperCase()}`)) {
          filteredItems?.push(item);
        }
      }
    });
  } else {
    menuItems?.forEach((item) => {
      if (item?.children) {
        item?.children?.forEach((child) => {
          filteredItems?.push(child);
        });
      } else {
        filteredItems?.push(item);
      }
    });
  }

  return filteredItems;
};

const styles = {
  icon: {
    fontSize: 16,
    color: "#CF6C57",
  },
};
