import React from "react";
import { Route } from "react-router-dom";
import { ChangePassword } from "../pages/ChangePassword";

export const register = (props) => {
  const basePath = "change-password";

  return (
    <>
      <Route path={basePath} element={<ChangePassword />} exact={true} />
    </>
  );
};
