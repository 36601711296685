import React from "react";
import { Route } from "react-router-dom";
import {
  LocationTypeList,
  LocationTypeAdd,
  LocationTypeEdit,
} from "../pages/LocationTypes";

export const register = (props) => {
  const basePath = "location-types";

  return (
    <>
      <Route path={basePath} element={<LocationTypeList />} exact={true} />
      <Route path={`${basePath}/add`} element={<LocationTypeAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<LocationTypeEdit />} />
    </>
  );
};
