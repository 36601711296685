export const makeConfig = ({ navigate }) => ({
  id: `vendor-quotation-form`,
  title: `Vendor Quotation`,
  pageTitle: `Vendor Quotation Form`,
  components: [
    {
      span: 12,
      component: "select",
      label: "Vendor",
      name: "vendor",
      placeholder: "Select vendor",
      required: true,
      rules: [
        {
          required: true,
          message: "Vendor is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Project",
      name: "project",
      placeholder: "Select project",
      required: true,
      rules: [
        {
          required: true,
          message: "Project is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Delivery Address",
      name: "delivery_address",
      placeholder: "Select delivery address",
      required: true,
      rules: [
        {
          required: true,
          message: "Delivery address is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Material Requisition",
      name: "vendor_material_requisition",
      placeholder: "Select material requisition",
      required: true,
      rules: [
        {
          required: true,
          message: "Material requisition is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Urgency Level",
      name: "urgency_level",
      placeholder: "Select urgency level",
      required: true,
      rules: [
        {
          required: true,
          message: "Urgency level is required!",
        },
      ],
    },
    {
      span: 12,
      component: "hidden",
    },
  ],
  slugs: {
    singular: "vendor-quotation",
    plural: "vendor-quotations",
  },
  entityNames: {
    singular: "Vendor Quotation",
    plural: "Vendor Quotations",
  },
});
