import React from "react";
import { Route } from "react-router-dom";
import {
  CompanyList,
  CompanyAdd,
  CompanyEdit,
  // CompanyReport,
} from "../pages/Companies";

export const register = (props) => {
  const basePath = "companies";

  return (
    <>
      <Route path={basePath} element={<CompanyList />} exact={true} />
      <Route path={`${basePath}/add`} element={<CompanyAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<CompanyEdit />} />
      {/* <Route path={`${basePath}-report`} element={<CompanyReport />} /> */}
    </>
  );
};
