export default {
  slugs: {
    singular: "debit-note",
    plural: "debit-notes",
  },
  entityNames: {
    singular: "Debit Note",
    plural: "Debit Notes",
  },
};
