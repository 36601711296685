import { axios } from "../../../../../App";
import config from "../config";
import moment from "moment";

export const makeConfig = ({ navigate }) => ({
  id: `${config?.plural}-edit`,
  title: `Edit ${config?.entityNames?.singular}`,
  pageTitle: `Edit ${config?.entityNames?.singular}`,
  breadcrumbs: [
    {
      path: "/quick-talks/dashboard",
      title: "Home",
    },
    {
      path: `/quick-talks/${config?.slugs?.plural}`,
      title: config?.entityNames?.plural,
    },
    {
      path: "",
      title: `Edit ${config?.entityNames?.singular}`,
    },
  ],
  components: [
    {
      span: 12,
      component: "datetime",
      label: "Start Time",
      name: "start_time",
      required: true,
      placeholder: "Select date & time",
      rules: [
        {
          required: true,
          message: "Start time is required!",
        },
      ],
    },
    {
      span: 12,
      component: "datetime",
      label: "End Time",
      name: "end_time",
      required: true,
      placeholder: "Select date & time",
      rules: [
        {
          required: true,
          message: "End time is required!",
        },
      ],
    },
    // {
    //   span: 12,
    //   component: "input",
    //   label: "Meeting Minutes",
    //   name: "meeting_minutes",
    //   required: true,
    //   placeholder: "Enter meeting minutes",
    //   rules: [
    //     {
    //       required: true,
    //       message: "Meeting minutes is required!",
    //     },
    //   ],
    // },
    {
      span: 12,
      component: "select",
      label: "Meeting",
      name: "meeting",
      placeholder: "Select meeting",
      required: true,
      rules: [
        {
          required: true,
          message: "Meeting is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Employee",
      name: "employee",
      placeholder: "Select employee",
      required: true,
      rules: [
        {
          required: true,
          message: "Employee is required!",
        },
      ],
    },
    {
      span: 12,
      component: "uploader",
      label: "Photograph",
      name: "photograph_file",
      //required: true,
      placeholder: "Upload photograph",
      // rules: [
      //   {
      //     required: true,
      //     message: "Photograph is required!",
      //   },
      // ],
    },
    {
      span: 12,
      component: "uploader",
      label: "Recording",
      name: "recording_file",
      // required: true,
      placeholder: "Upload recording",
      // rules: [
      //   {
      //     required: true,
      //     message: "Recording is required!",
      //   },
      // ],
    },
    {
      span: 24,
      component: "editor",
      label: "Content",
      name: "content",
      placeholder: "Enter content",
      rows: 5,
    },
    {
      span: 24,
      component: "input",
      type: "hidden",
      name: "photograph_file",
    },
    {
      span: 24,
      component: "input",
      type: "hidden",
      name: "recording_file",
    },
  ],
  actions: {
    onBack: () => navigate(`/quick-talks/${config?.slugs?.plural}`),
    onFinish: async (values, data) => {
      try {
        let dataToSend = {
          ...values,
          start_time: moment(values?.start_time).format(
            "YYYY-MM-DD HH:mm:ss.SSS"
          ),
          end_time: moment(values?.end_time).format("YYYY-MM-DD HH:mm:ss.SSS"),
        };

        Object.keys(dataToSend).forEach((key) => {
          if (dataToSend?.[key] === "") {
            delete dataToSend[key];
          }

          if (dataToSend?.[key]?.data?.id) {
            dataToSend[key] = dataToSend?.[key]?.data?.id;
          }
        });

        const response = await axios.put(
          `/${config?.slugs?.plural}/${data?.id}`,
          {
            data: dataToSend,
          }
        );

        if (response?.data) {
          return {
            success: true,
            message: `Edit ${config?.entityNames?.singular?.toLowerCase()} successfully!`,
          };
        }

        throw new Error();
      } catch (error) {
        return {
          success: false,
          message: error?.response?.data?.error?.message,
        };
      }
    },
  },
  ...config,
});
