import { axios } from "../../../../../App";
import config from "../config";
import { notification } from "antd";
import qs from "qs";

export const makeConfig = ({ navigate }) => ({
  id: `${config?.plural}-edit`,
  title: `Edit ${config?.entityNames?.singular} (For Creator)`,
  pageTitle: `Edit ${config?.entityNames?.singular} (For Creator)`,
  breadcrumbs: [
    {
      path: "/quick-projects/dashboard",
      title: "Home",
    },
    {
      path: `/quick-projects/${config?.slugs?.plural}`,
      title: config?.entityNames?.plural,
    },
    {
      path: "",
      title: `Edit ${config?.entityNames?.singular}`,
    },
  ],
  components: [
    {
      span: 24,
      component: "select",
      label: "Task",
      name: "task",
      placeholder: "Select task",
      required: true,
      rules: [
        {
          required: true,
          message: "Task is required!",
        },
      ],
      // disabled: true,
    },
    {
      span: 12,
      component: "select",
      label: "Material Group",
      name: "material_group",
      placeholder: "Select material group",
      required: true,
      rules: [
        {
          required: true,
          message: "Material group is required!",
        },
      ],
      // disabled: true,
    },
    {
      span: 12,
      component: "select",
      label: "Material Item",
      name: "material_item",
      placeholder: "Select material item",
      required: true,
      rules: [
        {
          required: true,
          message: "Material item is required!",
        },
      ],
      // disabled: true,
    },
    // {
    //   span: 12,
    //   component: "select",
    //   label: "Material Unit",
    //   name: "material_unit",
    //   placeholder: "Select material unit",
    //   required: true,
    //   rules: [
    //     {
    //       required: true,
    //       message: "Material unit is required!",
    //     },
    //   ],
    //   // disabled: true,
    // },
    {
      span: 12,
      component: "number",
      label: "Quantity",
      name: "quantity",
      required: true,
      placeholder: "Enter quantity",
      rules: [
        {
          required: true,
          message: "Quantity is required!",
        },
      ],
      min: 0,
      // disabled: true,
    },
    {
      span: 12,
      component: "number",
      label: "Amount",
      name: "amount",
      required: true,
      placeholder: "Enter amount",
      rules: [
        {
          required: true,
          message: "Amount is required!",
        },
      ],
      min: 0,
      // disabled: true,
    },
    // {
    //   span: 12,
    //   component: "select",
    //   label: "Status",
    //   name: "status",
    //   placeholder: "Select status",
    //   required: true,
    //   rules: [
    //     {
    //       required: true,
    //       message: "Status is required!",
    //     },
    //   ],
    // },
    {
      span: 12,
      component: "select",
      label: "Approved By",
      name: "approved_by",
      placeholder: "Select employee",
      required: true,
      rules: [
        {
          required: true,
          message: "Employee is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Employee",
      name: "employee",
      placeholder: "Select employee",
      // required: true,
      // rules: [
      //   {
      //     required: true,
      //     message: "Employee is required!",
      //   },
      // ],
      disabled: true,
    },
    {
      span: 12,
      component: "select",
      label: "Status",
      name: "status",
      placeholder: "Select status",
      required: true,
      rules: [
        {
          required: true,
          message: "Status is required!",
        },
      ],
      disabled: true,
    },
    {
      span: 24,
      component: "editor",
      label: "Description",
      name: "description",
      placeholder: "Enter description",
      rows: 5,
      // disabled: true,
    },
  ],
  actions: {
    onBack: () => navigate(`/quick-projects/${config?.slugs?.plural}`),
    onFinish: async (values, data) => {
      try {
        const updateEntry = async () => {
          const response = await axios.put(
            `/${config?.slugs?.plural}/${data?.id}`,
            {
              data: values,
            }
          );

          if (response?.data) {
            return {
              success: true,
              message: `Edit ${config?.entityNames?.singular?.toLowerCase()} successfully!`,
            };
          } else {
            throw new Error();
          }
        };

        const queryObject = {
          filters: {
            task: values?.task,
            material_group: values?.material_group,
            material_item: values?.material_item,
            material_unit: values?.material_unit,
            status: "Pending",
          },
        };

        const existingResp = await axios.get(
          `/${config?.slugs?.plural}?${qs.stringify(queryObject)}`
        );

        if (existingResp?.status === 200) {
          const total = existingResp?.data?.meta?.pagination?.total;

          if (total > 0) {
            const entryId = existingResp?.data?.data?.[0]?.id;

            if (entryId === data?.id) {
              return updateEntry();
            } else {
              return {
                success: false,
                message: `There is already an existing item with a similar task material.`,
              };
            }
          } else {
            return updateEntry();
          }
        } else {
          throw new Error();
        }
      } catch (error) {
        return {
          success: false,
          message: `Edit ${config?.entityNames?.singular?.toLowerCase()} failed!`,
        };
      }
    },
    // onFinish: async (values, data) => {
    //   try {
    //     const response = await axios.put(
    //       `/${config?.slugs?.plural}/${data?.id}`,
    //       {
    //         data: values,
    //       }
    //     );

    //     if (response?.data) {
    //       if (values?.status === "Rejected") {
    //         const checkResponse = await axios.get(
    //           `/stock-ledgers/${values?.material_item}`
    //         );

    //         if (checkResponse?.data) {
    //           const stockAmount =
    //             checkResponse?.data?.data?.attributes?.quantity;

    //           const returnResponse = await axios.put(
    //             `/stock-ledgers/${values?.material_item}`,
    //             {
    //               data: {
    //                 quantity: stockAmount + values?.amount,
    //               },
    //             }
    //           );

    //           if (returnResponse?.data) {
    //             notification["success"]({
    //               message: `Edit ${config?.entityNames?.singular}`,
    //               description: `Return items to stock successfully!`,
    //             });
    //           }
    //         }
    //       }

    //       return {
    //         success: true,
    //         message: `Edit ${config?.entityNames?.singular?.toLowerCase()} successfully!`,
    //       };
    //     }
    //   } catch (error) {
    //     return {
    //       success: false,
    //       message: error?.response?.data?.error?.message,
    //     };
    //   }
    // },
  },
  ...config,
});
