export default {
  slugs: {
    singular: "sub-contractor-quotation",
    plural: "sub-contractor-quotations",
  },
  entityNames: {
    singular: "Sub Contractor Quotation",
    plural: "Sub Contractor Quotations",
  },
};
