import React, { useState, useEffect } from "react";
import { DataTable } from "../../../components";
import { useNavigate } from "react-router-dom";
import { Space, Button, Popconfirm, Tooltip, notification, Input } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import qs from "qs";
import config from "./config";
import { axios } from "../../../../../App";
import _ from "lodash";

const Page = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    pageSize: 10,
  });
  const [total, setTotal] = useState(0);
  const [searchValues, setSearchValues] = useState({});

  const fetchData = async (params = null) => {
    try {
      setLoading(true);

      const queryObject = {
        populate: ["employee", "location.location_type"],
        sort: ["createdAt:desc"],
      };

      if (!_.isEmpty(params)) {
        queryObject["filters"] = params;
      }

      const response = await axios.get(
        `${config?.slugs?.plural}?${qs.stringify(queryObject)}`
      );

      if (response?.data) {
        setData(
          response?.data?.data?.map((item) => {
            return {
              id: item?.id,
              ...item?.attributes,
              location: item?.attributes?.location?.data
                ? `${item?.attributes?.location?.data?.attributes?.name} (${item?.attributes?.location?.data?.attributes?.location_type?.data?.attributes?.name})`
                : "Unknown",
            };
          })
        );

        setTotal(response?.data?.meta?.pagination?.total);

        setPagination({
          ...response?.data?.meta?.pagination,
          pageSize: 10,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const refreshData = () => {
    onSearch();
  };

  const deleteItem = async (id) => {
    try {
      const response = await axios.delete(`${config?.slugs?.plural}/${id}`);

      if (response?.data) {
        notification["success"]({
          message: `Remove ${config?.entityNames?.singular}`,
          description: `Remove ${config?.entityNames?.singular} successfully!`,
        });

        refreshData();
      } else {
        throw new Error();
      }
    } catch (error) {
      notification["error"]({
        message: `Remove ${config?.entityNames?.singular}`,
        description: `Remove ${config?.entityNames?.singular} failed!`,
      });
    }
  };

  const getColumnFilterProps = ({ dataIndex, references = {} }) => {
    if (typeof references?.fieldName === "function") {
      const transformer = references?.fieldName;

      return {
        render: (data) => transformer(data?.data?.attributes) || "N/A",
      };
    } else {
      return {
        render: (data) =>
          data?.data?.attributes?.[references?.fieldName] || "N/A",
      };
    }
  };

  const getColumnProps = (column) => {
    if (column?.references) {
      return getColumnFilterProps(column);
    }

    return column;
  };

  const onSearch = () => {
    fetchData({
      reason: {
        $containsi: searchValues?.keyword?.toLowerCase(),
      },
    });
  };

  const onReset = () => {
    setSearchValues({});
    fetchData(null);
  };

  const getResultsMessage = () => {
    if (!_.isEmpty(searchValues)) {
      return total > 0 ? (
        <span>
          <strong>{total}</strong> matched results
        </span>
      ) : (
        <span>No matched results</span>
      );
    }

    return (
      <span>
        <strong>{total}</strong> total records
      </span>
    );
  };

  useEffect(() => {
    fetchData(filters);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DataTable
      data={data}
      config={{
        ...config,
        columns: config?.columns?.map((column) => ({
          ...column,
          ...getColumnProps(column),
        })),
        // ?.concat({
        //   title: "Actions",
        //   width: 100,
        //   selectable: false,
        //   render: ({ id }) => {
        //     return (
        //       <Space>
        //         <Tooltip
        //           placement="bottom"
        //           title={`Edit ${config?.entityNames?.singular}`}
        //         >
        //           <Button
        //             icon={<EditOutlined />}
        //             onClick={() =>
        //               navigate(
        //                 `/quick-teams/${config?.slugs?.plural}/edit/${id}`
        //               )
        //             }
        //           />
        //         </Tooltip>

        //         <Popconfirm
        //           title={`Are you sure to remove this ${config?.entityNames?.singular}?`}
        //           okText="Yes"
        //           cancelText="No"
        //           onConfirm={() => deleteItem(id)}
        //         >
        //           <Tooltip
        //             placement="bottom"
        //             title={`Remove ${config?.entityNames?.singular}`}
        //           >
        //             <Button type="danger" icon={<DeleteOutlined />} />
        //           </Tooltip>
        //         </Popconfirm>
        //       </Space>
        //     );
        //   },
        // }),
      }}
      loading={loading}
      pagination={pagination}
      actions={{
        onAdd: () => navigate(`/quick-teams/${config?.slugs?.plural}/add`),
        onRefresh: refreshData,
      }}
      Toolbar={
        <div className="toolbar">
          <Input
            value={searchValues?.keyword}
            placeholder="Search for keyword..."
            onChange={(e) =>
              setSearchValues((prev) => ({
                ...prev,
                keyword: e.target.value,
              }))
            }
          />

          <div className="toolbar-buttons">
            <p className="toolbar-results">{getResultsMessage()}</p>

            <Space>
              <Button onClick={onReset}>Reset</Button>
              <Button type="primary" onClick={onSearch}>
                Search
              </Button>
            </Space>
          </div>
        </div>
      }
      buttons={[
        <Tooltip placement="top" title="Refresh">
          <Button
            icon={<ReloadOutlined />}
            onClick={refreshData}
            loading={loading}
          />
        </Tooltip>,
      ]}
    />
  );
};

export default Page;
