export default {
  slugs: {
    singular: "work-group",
    plural: "work-groups",
  },
  entityNames: {
    singular: "Work Group",
    plural: "Work Groups",
  },
};
