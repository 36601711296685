export default {
  slugs: {
    singular: "sub-contractor-requirement-form",
    plural: "sub-contractor-requirement-forms",
  },
  entityNames: {
    singular: "Sub Contractor Requirement Form",
    plural: "Sub Contractor Requirement Forms",
  },
};
