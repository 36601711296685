import React from "react";
import { Route } from "react-router-dom";
import { QuickProjectList } from "../pages/QuickProjects";

export const register = (props) => {
  const basePath = "my-drive";

  return (
    <>
      <Route path={basePath} element={<QuickProjectList />} exact={true} />
    </>
  );
};
