export default {
  slugs: {
    singular: "task",
    plural: "tasks",
  },
  entityNames: {
    singular: "Task",
    plural: "Tasks",
  },
};
