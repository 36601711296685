import config from "../config";

const commify = (x) => {
  return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export default {
  id: `${config?.slugs?.plural}-list`,
  pageTitle: config?.entityNames?.plural,
  breadcrumbs: [
    {
      path: "/quick-stocks/dashboard",
      title: "Home",
    },
    {
      path: "",
      title: config?.entityNames?.plural,
    },
  ],
  columns: [
    {
      title: "Name",
      dataIndex: "name",
      selectable: true,
      sorter: (a, b) => a?.name?.localeCompare(b?.name),
    },
    {
      title: "Section No.",
      dataIndex: "section_no",
      selectable: true,
      sorter: (a, b) => a?.section_no?.localeCompare(b?.section_no),
    },
    {
      title: "Rate",
      dataIndex: "rate",
      selectable: true,
      render: (text) => commify(text || 0),
      sorter: (a, b) => a?.rate - b?.rate,
    },
  ],
  ...config,
};
