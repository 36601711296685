export default {
  slugs: {
    singular: "material-unit",
    plural: "material-units",
  },
  entityNames: {
    singular: "Material Unit",
    plural: "Material Units",
  },
};
