import { axios } from "../../App";
import qs from "qs";

export const fetchData = async ({
  slug,
  query = {
    pagination: {
      page: 1,
      pageSize: 9999,
    },
  },
  transformer = null,
  onSuccess = () => { },
  onError = () => { },
  onFinally = () => { },
}) => {
  try {
    const response = await axios.get(
      `/${slug}?${qs.stringify({
        ...query,
        pagination: {
          page: 1,
          pageSize: 9999,
        },
      })}`
    );

    if (response?.data) {
      let data = response?.data?.data?.map((item) => ({
        // id: item?.id,
        ...item,
        ...item?.attributes,
      }));

      if (transformer) {
        data = data?.map((item) => transformer(item));
      }
      if (!data) data = response?.data;
      onSuccess(data, response?.data?.meta);
    } else {
      throw new Error();
    }
  } catch (error) {
    onError(error);
    console.log(error);
  } finally {
    onFinally();
  }
};
