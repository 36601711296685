import React, { useEffect, useState } from "react";
import { AddForm } from "../../../../../components";
import { useNavigate } from "react-router-dom";
import { makeConfig } from "../../../../../../stocks/pages/SubContractors/Edit/config";
import { axios } from "../../../../../../../App";
import { fetchData } from "../../../../../../../utils/helpers/data.helper";
import qs from "qs";
import { useSelector } from "react-redux";
import Pluralize from "pluralize";

const Page = (props) => {
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({});
  const config = makeConfig({ navigate });
  const { user } = useSelector((state) => state?.auth);
  const contentTypes = [
    {
      slug: "sub-contractor-groups",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          sub_contractor_group: data,
        })),
    },
    {
      slug: "firm-types",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          firm_type: data,
        })),
    },
    {
      slug: "states",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          state: data,
        })),
    },
    {
      slug: "cities",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          city: data,
        })),
    },
    {
      slug: "banks",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          bank: data,
        })),
    },
    {
      slug: "gst-types",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          gst_type: data,
        })),
    },
    {
      slug: "tds-types",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          tds_type: data,
        })),
    },
  ];

  const getProfileId = async () => {
    const userType = user?.user_type?.toLowerCase()?.replace(/\s/i, "-");
    const queryObject = {
      filters: {
        uid: user?.uid,
      },
    };

    const response = await axios.get(
      `/${Pluralize(userType)}?${qs.stringify(queryObject)}`
    );

    if (response?.data?.data?.length > 0) {
      return response?.data?.data?.[0]?.id;
    }

    return "";
  };

  useEffect(() => {
    contentTypes?.forEach((contentType) => {
      fetchData(contentType);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const queryObject = {
          populate: "*",
        };

        const profileId = await getProfileId();

        if (profileId === "") {
          throw new Error("");
        }

        const response = await axios.get(
          `/${config?.slugs?.plural}/${profileId}?${qs.stringify(queryObject)}`
        );

        if (response?.data) {
          setData({
            ...response?.data?.data?.attributes,
            id: response?.data?.data?.id,
            city: response?.data?.data?.attributes?.city?.data?.id,
            state: response?.data?.data?.attributes?.state?.data?.id,
            bank: response?.data?.data?.attributes?.bank?.data?.id,
            sub_contractor_group:
              response?.data?.data?.attributes?.sub_contractor_group?.data?.id,
            firm_type: response?.data?.data?.attributes?.firm_type?.data?.id,
            gst_type: response?.data?.data?.attributes?.gst_type?.data?.id,
            tds_type: response?.data?.data?.attributes?.tds_type?.data?.id,
            registration_document: null,
            gsin_document: null,
            pan_document: null,
            ifsc_document: null,
            micr_document: null,
            logo: null,
            header: null,
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.uid]);

  return (
    <AddForm
      config={config}
      data={data}
      loading={loading}
      values={values}
      showsHeader={false}
      showsCancel={false}
    />
  );
};

export default Page;
