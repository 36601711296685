import config from "../config";
import { Tag } from "antd";

export default {
  id: `${config?.slugs?.plural}-list`,
  pageTitle: `${config?.entityNames?.plural} Report`,
  code: "USERS",
  breadcrumbs: [
    {
      path: "/quick-setup",
      title: "Home",
    },
    {
      path: "",
      title: `${config?.entityNames?.plural} Report`,
    },
  ],
  columns: [
    {
      title: "Username",
      dataIndex: "username",
      selectable: true,
      sorter: (a, b) => a?.username?.localeCompare(b?.username),
    },
    // {
    //   title: "Role",
    //   dataIndex: "role",
    //   selectable: true,
    //   sorter: (a, b) => a?.role?.localeCompare(b?.role),
    // },
    {
      title: "Status",
      dataIndex: "status",
      selectable: true,
      render: (text) => <Tag>{text}</Tag>,
    },
  ],
  ...config,
};
