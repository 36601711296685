import React from "react";
import { Link } from "react-router-dom";
import { PageHeader, Breadcrumb } from "antd";
import { makeConfig } from "./config";
import { useSelector } from "react-redux";

import { PartnerEdit } from "../components/Partners";
import { EmployeeEdit } from "../components/Employees";
import { AgencyEdit } from "../components/Agencies";
import { SubContractorEdit } from "../components/SubContractors";
import { VendorEdit } from "../components/Vendors";

const Profile = (props) => {
  const config = makeConfig();
  const { user } = useSelector((state) => state?.auth);

  const renderForm = () => {
    // eslint-disable-next-line default-case
    switch (user?.user_type?.toLowerCase()) {
      case "partner": {
        return <PartnerEdit {...props} />;
      }

      case "employee": {
        return <EmployeeEdit {...props} />;
      }

      case "agency": {
        return <AgencyEdit {...props} />;
      }

      case "sub-contractor": {
        return <SubContractorEdit {...props} />;
      }

      case "vendor": {
        return <VendorEdit {...props} />;
      }
    }
  };

  return (
    <div>
      <Breadcrumb style={styles.breadcrumbs}>
        {config?.breadcrumbs?.map((breadcrumb, index) => (
          <Breadcrumb.Item key={index}>
            <Link to={breadcrumb?.path}>{breadcrumb?.title}</Link>
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>

      <PageHeader
        title={config?.title}
        style={styles.pageHeader}
        onBack={config?.actions?.onBack}
      ></PageHeader>

      {renderForm()}
    </div>
  );
};

const styles = {
  pageHeader: {
    padding: 0,
    paddingBottom: 24,
  },
};

export default Profile;
