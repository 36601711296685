import config from "../config";

export default {
  id: `${config?.slugs?.plural}-list`,
  pageTitle: `${config?.entityNames?.plural} Report`,
  code: "TASK_DOCUMENTS",
  breadcrumbs: [
    {
      path: "/quick-projects/dashboard",
      title: "Home",
    },
    {
      path: `/quick-projects/reports`,
      title: "Reports",
    },
    {
      path: "",
      title: `${config?.entityNames?.plural} Report`,
    },
  ],
  columns: [
    {
      title: "Task",
      dataIndex: "task",
      selectable: true,
      references: {
        fieldName: "name",
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      selectable: true,
      sorter: (a, b) => a?.name?.localeCompare(b?.name),
    },
  ],
  ...config,
};
