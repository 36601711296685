import config from "../config";
import moment from "moment";

const DATE_TIME_FORMAT = "YYYY-MM-DDTHH:mm:ss[Z]";

export default {
  id: `${config?.slugs?.plural}-list`,
  pageTitle: config?.entityNames?.plural,
  breadcrumbs: [
    {
      path: "/quick-teams/dashboard",
      title: "Home",
    },
    {
      path: "",
      title: config?.entityNames?.plural,
    },
  ],
  columns: [
    {
      title: "Employee",
      dataIndex: "employee",
      selectable: true,
      filterable: true,
      references: {
        fieldName: (record) => `${record?.first_name} ${record?.last_name}`,
      },
    },
    {
      title: "Latitude",
      dataIndex: "latitude",
      selectable: true,
      sorter: (a, b) => a > b,
    },
    {
      title: "Longitude",
      dataIndex: "longitude",
      selectable: true,
      sorter: (a, b) => a > b,
    },
    {
      title: "Location",
      dataIndex: "location",
      selectable: true,
      // filterable: true,
      // references: {
      //   fieldName: (record) =>
      //     `${record?.name} (${record?.location_type?.data?.attributes?.name})`,
      // },
    },
    // {
    //   title: "Valid Location",
    //   dataIndex: "is_valid_location",
    //   selectable: true,
    //   render: (text) => (text === true ? "Yes" : "No"),
    // },
    {
      title: "Date",
      dataIndex: "createdAt",
      selectable: true,
      sorter: (a, b) =>
        moment(a.createdAt, DATE_TIME_FORMAT).unix() -
        moment(b.createdAt, DATE_TIME_FORMAT).unix(),
      render: (text) => (
        <span>
          {moment(text, DATE_TIME_FORMAT).format("DD-MM-YYYY, HH:mm:ss")}
        </span>
      ),
      width: 250,
    },
  ],
  ...config,
};
