import React from "react";
import { Route } from "react-router-dom";
import GanttChart from "../pages/GanttChart";

export const register = (props) => {
  const basePath = "gantt-chart";

  return (
    <>
      <Route path={basePath} element={<GanttChart />} exact={true} />
    </>
  );
};
