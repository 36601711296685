export default {
  slugs: {
    singular: "department",
    plural: "departments",
  },
  entityNames: {
    singular: "Department",
    plural: "Departments",
  },
};
