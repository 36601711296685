import React from "react";
import { Row, Col } from "antd";
import Pie from "./Pie";

const PieGroup = ({ data }) => {
  return (
    <Row>
      {data?.map((item) => (
        <Col span={12}>
          <Pie {...item} />
          <h3 style={styles.title}>{item?.title}</h3>
        </Col>
      ))}
    </Row>
  );
};

const styles = {
  title: {
    margin: 0,
    padding: 0,
    color: "gray",
    textAlign: "center",
    marginBottom: 24,
  },
};

export default PieGroup;
