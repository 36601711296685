import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import {
  PageHeader,
  Breadcrumb,
  Button,
  Space,
  Tooltip,
  Spin,
  Empty,
  Drawer,
  Timeline,
} from "antd";
import { Link, useNavigate } from "react-router-dom";
import { makeConfig } from "./config";
import { fetchData } from "../../../../../utils/helpers/data.helper";
import { IoGridOutline } from "react-icons/io5";
import { FaList } from "react-icons/fa";
import { saveAs } from "file-saver";
import { axios } from "../../../../../App";
import {
  ReloadOutlined,
  FolderAddOutlined,
  FileAddOutlined,
} from "@ant-design/icons";
import AddFolderModal from "../../../components/AddFolderModal";
import RenameModal from "../../../components/RenameModal";
import DeleteModal from "../../../components/DeleteModal";
import AddFileModal from "../../../components/AddFileModal";
import ViewFileModal from "../../../components/ViewFileModal";
import PermissionsModal from "../../../components/PermissionsModal";
import {
  ContextMenuTrigger,
  ContextMenu,
  ContextMenuItem,
} from "rctx-contextmenu";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import moment from "moment";
import { useSelector } from "react-redux";

const MODULE_NAME = "My Drive";

// TODOs:
// Add creation date/ last updated at
// Add select for sorting order
// Navigate via breadcrumb

const FileItemRow = ({ item, onClick, isLast = false }) => {
  return (
    <span
      style={{
        ...styles.fileItemRow,
        borderBottom: isLast ? "none" : "1px solid rgb(29 31 89 / 10%)",
      }}
      onClick={onClick}
    >
      {item?.item_type === "Folder" || item?.item_type === "Parent" ? (
        <img
          src="https://img.icons8.com/external-inipagistudio-mixed-inipagistudio/344/external-folder-computer-and-internet-inipagistudio-mixed-inipagistudio.png"
          alt="Total Folders"
          style={styles.cardIcon}
        />
      ) : (
        <img
          src="https://img.icons8.com/external-inipagistudio-mixed-inipagistudio/344/external-documentation-business-process-inipagistudio-mixed-inipagistudio.png"
          alt="Total Files"
          style={styles.cardIcon}
        />
      )}

      <span style={styles.fileItemText}>
        <span style={styles.fileItemName}>
          {item?.name}
          {item?.attachment?.ext}
        </span>

        {(item?.item_type === "Folder" || item?.item_type === "Parent") && (
          <span style={styles.fileItemSize}>
            {item?.item_type === "Parent" ? "Parent Folder" : "Folder"}
          </span>
        )}

        {item?.item_type === "File" && (
          <span style={styles.fileItemSize}>
            {item?.attachment?.ext?.toUpperCase()?.substr(1)} File -{" "}
            {item?.attachment?.size || 0} KB
          </span>
        )}
      </span>
    </span>
  );
};

const FileItemCell = ({ item, onClick }) => {
  return (
    <span style={styles.fileItem} onClick={onClick}>
      {item?.item_type === "Folder" || item?.item_type === "Parent" ? (
        <img
          src="https://img.icons8.com/external-inipagistudio-mixed-inipagistudio/344/external-folder-computer-and-internet-inipagistudio-mixed-inipagistudio.png"
          alt="Total Folders"
          style={styles.cardIcon}
        />
      ) : (
        <img
          src="https://img.icons8.com/external-inipagistudio-mixed-inipagistudio/344/external-documentation-business-process-inipagistudio-mixed-inipagistudio.png"
          alt="Total Files"
          style={styles.cardIcon}
        />
      )}

      <span style={styles.fileItemText}>
        <span style={styles.fileItemName}>
          {item?.name}
          {item?.attachment?.ext}
        </span>

        {(item?.item_type === "Folder" || item?.item_type === "Parent") && (
          <span style={styles.fileItemSize}>
            {item?.item_type === "Parent" ? "Parent Folder" : "Folder"}
          </span>
        )}

        {item?.item_type === "File" && (
          <span style={styles.fileItemSize}>
            {item?.attachment?.ext?.toUpperCase()?.substr(1)} File -{" "}
            {item?.attachment?.size || 0} KB
          </span>
        )}
      </span>
    </span>
  );
};

const FileItem = ({ mode, item, isLast, onClick }) => {
  if (mode === "grid") {
    return <FileItemCell item={item} onClick={onClick} />;
  }

  return <FileItemRow item={item} onClick={onClick} isLast={isLast} />;
};

const Page = (props) => {
  const navigate = useNavigate();
  const config = makeConfig({ navigate });
  const { user } = useSelector((state) => state?.auth);
  const [data, setData] = useState([]);
  const [mode, setMode] = useState("list");
  const [loading, setLoading] = useState(false);
  const [historyStack, setHistoryStack] = useState([]);
  const [stackLabels, setStackLabels] = useState([]);
  const [stats, setStats] = useState({});
  const [showsAddFolder, setShowsAddFolder] = useState(false);
  const [showsRename, setShowsRename] = useState(false);
  const [showsDelete, setShowsDelete] = useState(false);
  const [showsAddFile, setShowsAddFile] = useState(false);
  const [activeItem, setActiveItem] = useState({});
  const [settings, setSettings] = useState({});
  const [showsView, setShowsView] = useState(false);
  const [allFiles, setAllFiles] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [showsHistories, setShowsHistories] = useState(false);
  const [showsPermissions, setShowsPermissions] = useState(false);
  const [logs, setLogs] = useState([]);

  const onDownload = (item) => {
    const url = `${process.env.REACT_APP_BASE_API_URL}${item?.attachment?.url}?updated_at=${item?.attachment?.updatedAt}`;

    saveAs(url, item?.name);
  };

  const fetchSettings = async () => {
    try {
      const response = await axios.get(`/application-setting`);

      if (response?.data) {
        setSettings(response?.data?.data?.attributes);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchStats = async () => {
    try {
      const response = await axios.get(`/quick-drive/module-stats`);

      if (response?.data) {
        const newStats = response?.data?.data;

        setStats(newStats);
      }

      throw new Error();
    } catch (error) {}
  };

  const fetchFolderData = async (fileItemId = null) => {
    try {
      let newData = [];

      if (!fileItemId) {
        newData = allFiles
          ?.filter((item) => item?.parent === null)
          ?.sort((a, b) => (a.name > b.name) - (a.name < b.name));
      } else {
        newData = allFiles?.filter(
          (item) => item?.parent && item?.parent?.id === fileItemId
        );
      }

      setData(newData);
    } catch (error) {
      console.log(error);
    }
  };

  const setGridMode = () => setMode("grid");

  const setListMode = () => setMode("list");

  const onBack = () => {
    let newHistoryStack = [...historyStack];
    let newStackLabels = [...stackLabels];

    let previousId = newHistoryStack.pop();
    newStackLabels.pop();

    if (newHistoryStack.length > 0) {
      fetchFolderData(previousId);
    } else {
      fetchFolderData();
    }

    setHistoryStack(newHistoryStack);
    setStackLabels(newStackLabels);
  };

  const onClickItem = (item) => {
    if (item?.item_type === "Folder") {
      let newHistoryStack = [...historyStack];
      let newStackLabels = [...stackLabels];

      if (!newHistoryStack?.includes(item?.id)) {
        newHistoryStack?.push(item?.id);
        newStackLabels.push(item?.name);
      }

      fetchFolderData(item?.id);

      setHistoryStack(newHistoryStack);
      setStackLabels(newStackLabels);
    } else {
      onViewFile(item);
    }
  };

  const onRefresh = () => {
    fetchAllFiles();
  };

  const onClickCrumb = (index = null) => {
    if (index) {
      fetchFolderData(historyStack[index]);
    } else {
      fetchFolderData();
      setHistoryStack([]);
      setStackLabels([]);
    }
  };

  const onRename = (record) => {
    setActiveItem(record);
    setShowsRename(true);
  };

  const onDelete = (record) => {
    setActiveItem(record);
    setShowsDelete(true);
  };

  const onViewFile = (record) => {
    setActiveItem(record);
    setShowsView(true);
  };

  const onViewHistories = (record) => {
    setActiveItem(record);
    fetchLogs(record?.id);
  };

  const onSetPermissions = (record) => {
    setActiveItem(record);
    setShowsPermissions(true);
  };

  const renderSharedItems = (record) => {
    return (
      <>
        <ContextMenuItem onClick={() => onViewHistories(record)}>
          View Histories
        </ContextMenuItem>

        <ContextMenuItem onClick={() => onSetPermissions(record)}>
          Set Permissions
        </ContextMenuItem>

        <ContextMenuItem onClick={() => onRename(record)}>
          Rename
        </ContextMenuItem>

        <ContextMenuItem onClick={() => onDelete(record)}>
          Delete
        </ContextMenuItem>
      </>
    );
  };

  const KB2GB = (value) => {
    return value / (1024 * 1024);
  };

  const onSelectSearchResult = (item) => {
    const fileData = allFiles?.find((record) => record?.id === item?.id);

    onViewFile(fileData);
  };

  const fetchAllFiles = async () => {
    try {
      setLoading(true);
      const resp = await axios.post(`/file-items-manager/files`, {
        user_uid: user?.uid,
      });

      if (resp?.data?.success) {
        const filteredData = resp?.data?.data?.filter(
          (item) =>
            (item?.item_type === "Folder" || item?.attachment !== null) &&
            item?.is_deleted === false
        );

        setAllFiles(filteredData);
        setSuggestions(
          filteredData?.map((item) => ({
            id: item?.id,
            name: `${item?.item_type} - ${item?.name}`,
          }))
        );
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchLogs = async (fileItemId) => {
    try {
      fetchData({
        slug: "file-item-logs",
        query: {
          populate: "*",
          filters: {
            actor_uid: {
              $ne: null,
            },
            file_item: fileItemId,
          },
        },
        onSuccess: (data) => {
          setLogs(data);
          setShowsHistories(true);
        },
      });
    } catch (error) {
      console.log(error);
      setShowsHistories(true);
    }
  };

  const getActionFromEventName = (eventName) => {
    switch (eventName) {
      case "VIEW":
        return "viewed";

      default:
        return "";
    }
  };

  useEffect(() => {
    fetchSettings();
    fetchStats();
    fetchAllFiles();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (historyStack?.length > 0) {
      fetchFolderData(historyStack?.at(-1));
    } else {
      fetchFolderData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allFiles, historyStack]);

  return (
    <div style={styles.pageContainer}>
      <Helmet>
        <title>{config?.pageTitle}</title>
      </Helmet>
      <Breadcrumb style={styles.breadcrumbs}>
        {config?.breadcrumbs?.map((breadcrumb, index) => (
          <Breadcrumb.Item key={index}>
            <Link to={breadcrumb?.path}>{breadcrumb?.title}</Link>
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>

      <PageHeader title={config?.title} style={styles.pageHeader}></PageHeader>

      <div style={styles.sectionHeader}>
        <div>
          <Breadcrumb style={styles.breadcrumbs}>
            <Breadcrumb.Item style={styles.folderCrumb}>
              <span>{MODULE_NAME}</span>
            </Breadcrumb.Item>

            {stackLabels?.map((stackLabel, index) => (
              <Breadcrumb.Item style={styles.folderCrumb}>
                <span>{stackLabel}</span>
              </Breadcrumb.Item>
            ))}
          </Breadcrumb>
        </div>

        <Space>
          {KB2GB(stats["used_space"]) <=
            settings?.monthly_maximum_storage_in_gb && (
            <>
              <Tooltip placement="bottom" title="Add New Folder">
                <Button
                  icon={<FolderAddOutlined />}
                  onClick={() => setShowsAddFolder(true)}
                />
              </Tooltip>

              <Tooltip placement="bottom" title="Add New File">
                <Button
                  icon={<FileAddOutlined />}
                  onClick={() => setShowsAddFile(true)}
                />
              </Tooltip>
            </>
          )}

          <Tooltip placement="bottom" title="Refresh">
            <Button icon={<ReloadOutlined />} onClick={onRefresh} />
          </Tooltip>

          <Tooltip placement="bottom" title="Grid View">
            <Button
              icon={<IoGridOutline style={{ margin: "0 auto" }} />}
              onClick={setGridMode}
              type={mode === "grid" ? "primary" : ""}
            />
          </Tooltip>

          <Tooltip placement="bottom" title="List View">
            <Button
              icon={<FaList style={{ margin: "0 auto" }} />}
              onClick={setListMode}
              type={mode === "list" ? "primary" : ""}
            />
          </Tooltip>
        </Space>
      </div>
      <div className="my-drive-search-box">
        <ReactSearchAutocomplete
          items={suggestions}
          onSelect={onSelectSearchResult}
          autoFocus
          maxResults={5}
          placeholder="Search for files or folders"
        />
      </div>
      {loading && (
        <div style={styles.loaderContainer}>
          <Spin />
        </div>
      )}
      <>
        <div
          style={{
            ...styles.container,
            gridTemplateColumns: mode === "grid" ? "repeat(3, 1fr)" : "1fr",
            gridGap: mode === "grid" ? 24 : 0,
          }}
        >
          {!loading && (
            <>
              {historyStack?.length > 0 && (
                <FileItem
                  mode={mode}
                  item={{ name: "...", item_type: "Parent" }}
                  onClick={onBack}
                  isLast={data?.length === 0}
                />
              )}

              {data?.length > 0 &&
                data?.map((record, index) => (
                  <>
                    <ContextMenuTrigger id={`rightMenu-${record?.id}`}>
                      <FileItem
                        mode={mode}
                        key={index}
                        item={record}
                        onClick={() => onClickItem(record)}
                        isLast={index === data?.length - 1}
                      />
                    </ContextMenuTrigger>

                    {record?.item_type === "File" && (
                      <ContextMenu id={`rightMenu-${record?.id}`}>
                        <ContextMenuItem onClick={() => onViewFile(record)}>
                          View File
                        </ContextMenuItem>

                        <ContextMenuItem onClick={() => onDownload(record)}>
                          Download File
                        </ContextMenuItem>

                        {renderSharedItems(record)}
                      </ContextMenu>
                    )}

                    {record?.item_type === "Folder" && (
                      <ContextMenu id={`rightMenu-${record?.id}`}>
                        {renderSharedItems(record)}
                      </ContextMenu>
                    )}
                  </>
                ))}

              {data?.length === 0 && (
                <div style={styles.loaderContainer}>
                  <Empty />
                </div>
              )}
            </>
          )}
        </div>
      </>
      <AddFolderModal
        visible={showsAddFolder}
        currentFileItemId={
          historyStack?.length > 0 ? historyStack.at(-1) : null
        }
        onClose={() => setShowsAddFolder(false)}
        onFinish={onRefresh}
        moduleName={MODULE_NAME}
      />
      <AddFileModal
        visible={showsAddFile}
        currentFileItemId={
          historyStack?.length > 0 ? historyStack.at(-1) : null
        }
        onClose={() => setShowsAddFile(false)}
        onFinish={onRefresh}
        moduleName={MODULE_NAME}
      />
      <RenameModal
        visible={showsRename}
        currentFileItemId={activeItem?.id}
        data={activeItem}
        onClose={() => setShowsRename(false)}
        onFinish={onRefresh}
      />
      <PermissionsModal
        visible={showsPermissions}
        currentFileItemId={activeItem?.id}
        data={activeItem}
        onClose={() => setShowsPermissions(false)}
        onFinish={onRefresh}
      />
      <DeleteModal
        visible={showsDelete}
        currentFileItemId={activeItem?.id}
        data={activeItem}
        onClose={() => setShowsDelete(false)}
        onFinish={onRefresh}
      />
      <ViewFileModal
        visible={showsView}
        data={activeItem}
        onClose={() => setShowsView(false)}
        onFinish={onRefresh}
        onDownload={() => onDownload(activeItem)}
        width={1000}
      />
      <Drawer
        title={`${activeItem?.attributes?.name} Histories`}
        placement="right"
        onClose={() => setShowsHistories(false)}
        open={showsHistories}
        width={500}
      >
        <Timeline>
          <Timeline.Item>
            [{moment(activeItem?.createdAt)?.format("DD-MM-YYYY, HH:mm")}]{" "}
            {activeItem?.item_type}{" "}
            <strong>
              {activeItem?.name}
              {activeItem?.attachment?.ext}
            </strong>{" "}
            was created.
          </Timeline.Item>

          {logs?.length > 0 &&
            logs?.map((log) => (
              <Timeline.Item key={log?.id}>
                [{moment(log?.createdAt)?.format("DD-MM-YYYY, HH:mm")}] User{" "}
                <strong>{log?.event_data?.username}</strong>{" "}
                {getActionFromEventName(log?.event_name)} the file{" "}
                <strong>
                  {activeItem?.name}
                  {activeItem?.attachment?.ext}
                </strong>
              </Timeline.Item>
            ))}
        </Timeline>
      </Drawer>
    </div>
  );
};

const styles = {
  pageContainer: {},
  pageHeader: {
    padding: 0,
    // marginBottom: -16,
  },
  container: {
    overflow: "auto",
    display: "grid",
    padding: 12,
  },
  fileItem: {
    padding: 12,
    borderRadius: 6,
    boxShadow: "0 0 20px 0 rgb(29 31 89 / 10%)",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  fileItemRow: {
    padding: 12,
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  fileItemText: {
    marginLeft: 12,
    flex: 1,
  },
  fileItemName: {
    fontWeight: "bold",
    display: "block",
  },
  fileItemSize: {
    color: "#888",
    display: "block",
    fontSize: 12,
  },
  fileItemIcon: {
    fontSize: 32,
    color: "#E13130",
  },
  cards: {
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)",
    gridGap: 16,
  },
  card: {
    boxShadow: "0 2px 10px 0 rgb(0 0 0 / 10%)",
    padding: "12px 16px",
    borderRadius: 6,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  cardIcon: {
    width: 32,
    height: 32,
  },
  cardTitle: {
    margin: 0,
    padding: 0,
    fontSize: 15,
    color: "#888",
    fontWeight: "normal",
  },
  cardValue: {
    margin: 0,
    padding: 0,
    fontWeight: "bold",
  },
  sectionHeader: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 16,
  },
  sectionTitle: {
    marginBottom: 0,
    fontSize: 17,
    fontWeight: "bold",
  },
  loaderContainer: {
    textAlign: "center",
    padding: 24,
  },
  folderCrumb: {
    cursor: "pointer",
  },
};

export default Page;
