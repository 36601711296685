import config from "../config";
import moment from "moment";

const commify = (x) => {
  return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  id: `${config?.slugs?.plural}-list`,
  pageTitle: `${config?.entityNames?.plural} Report`,
  code: "RELEASED_NOTES",
  breadcrumbs: [
    {
      path: "/quick-stocks/dashboard",
      title: "Home",
    },
    {
      path: `/quick-stocks/reports`,
      title: "Reports",
    },
    {
      path: "",
      title: `${config?.entityNames?.plural} Report`,
    },
  ],
  columns: [
    {
      title: "Date",
      dataIndex: "date",
      selectable: true,
    },
    {
      title: "Project",
      dataIndex: "project",
      selectable: true,
      sorter: (a, b) => a?.project?.localeCompare(b?.project),
    },
    {
      title: "Task",
      dataIndex: "task",
      selectable: true,
      sorter: (a, b) => a?.task?.localeCompare(b?.task),
    },
    {
      title: "Task Material",
      dataIndex: "task_material",
      selectable: true,
      sorter: (a, b) => a?.task_material?.localeCompare(b?.task_material),
    },
    {
      title: "Required Quantity",
      dataIndex: "required_quantity",
      selectable: true,
      render: (text) => commify(text || 0),
      sorter: (a, b) => a?.required_quantity - b?.required_quantity,
    },
    {
      title: "Released Quantity",
      dataIndex: "released_quantity",
      selectable: true,
      render: (text) => commify(text || 0),
      sorter: (a, b) => a?.released_quantity - b?.released_quantity,
    },
    // {
    //   title: "Material Item",
    //   dataIndex: "material_item",
    //   selectable: true,
    //   filterable: true,
    //   references: {
    //     fieldName: "name",
    //   },
    // },
    // {
    //   title: "Quantity",
    //   dataIndex: "quantity",
    //   selectable: true,
    //   render: (text) => commify(text || 0),
    //   sorter: (a, b) => a?.quantity - b?.quantity,
    // },
    // {
    //   title: "Material Unit",
    //   dataIndex: "material_unit",
    //   selectable: true,
    //   filterable: true,
    //   references: {
    //     fieldName: "name",
    //   },
    // },
    // {
    //   title: "Location",
    //   dataIndex: "location",
    //   selectable: true,
    //   filterable: true,
    //   references: {
    //     fieldName: (record) =>
    //       `${record?.name} (${record?.location_type?.data?.attributes?.name})`,
    //   },
    // },
    // {
    //   title: "Approved By",
    //   dataIndex: "approved_by",
    //   selectable: true,
    //   filterable: true,
    //   references: {
    //     fieldName: (record) => `${record?.first_name} ${record?.last_name}`,
    //   },
    // },
  ],
  ...config,
};
