export default {
  slugs: {
    singular: "stock-ledger",
    plural: "stock-ledgers",
  },
  entityNames: {
    singular: "Stock Ledger",
    plural: "Stock Ledgers",
  },
};
