export default {
  slugs: {
    singular: "manpower-type",
    plural: "manpower-types",
  },
  entityNames: {
    singular: "Manpower Type",
    plural: "Manpower Types",
  },
};
