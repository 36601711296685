import config from "../config";

export const makeConfig = ({ navigate }) => ({
  id: `${config?.plural}-view`,
  title: `View ${config?.entityNames?.singular}`,
  pageTitle: `View ${config?.entityNames?.singular}`,
  breadcrumbs: [
    {
      path: "/quick-stocks/dashboard",
      title: "Home",
    },
    {
      path: `/quick-stocks/${config?.slugs?.plural}`,
      title: config?.entityNames?.plural,
    },
    {
      path: "",
      title: `View ${config?.entityNames?.singular}`,
    },
  ],
  components: [
    {
      span: 12,
      component: "input",
      label: "Date",
      name: "date",
      placeholder: "",
      disabled: true,
    },
    {
      span: 12,
      component: "select",
      label: "Project",
      name: "project",
      placeholder: "Select project",
      required: true,
      rules: [
        {
          required: true,
          message: "Project is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Task",
      name: "task",
      placeholder: "Select task",
      required: true,
      rules: [
        {
          required: true,
          message: "Task is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Task Material",
      name: "task_material",
      placeholder: "Select task material",
      required: true,
      rules: [
        {
          required: true,
          message: "Task material is required!",
        },
      ],
    },
    {
      span: 12,
      component: "number",
      label: "Required Quantity",
      name: "required_quantity",
      min: 0,
      disabled: true,
    },
    // {
    //   span: 12,
    //   component: "number",
    //   label: "In Stock Quantity",
    //   name: "in_stock_quantity",
    //   min: 0,
    //   disabled: true,
    // },
    {
      span: 12,
      component: "number",
      label: "Released Quantity",
      name: "released_quantity",
      min: 0,
      disabled: true,
    },
    {
      span: 24,
      component: "editor",
      label: "Remarks",
      name: "remarks",
      placeholder: "Enter remarks",
      rows: 5,
      disabled: true,
    },
  ],
  actions: {
    onBack: () => navigate(`/quick-stocks/${config?.slugs?.plural}`),
  },
  ...config,
});
