import config from "../config";
import moment from "moment";
import { Tag } from "antd";

export default {
  id: `${config?.slugs?.plural}-list`,
  pageTitle: `${config?.entityNames?.plural} Report`,
  code: "PAYROLL",
  breadcrumbs: [
    {
      path: "/quick-talks/dashboard",
      title: "Home",
    },
    {
      path: `/quick-talks/reports`,
      title: "Reports",
    },
    {
      path: "",
      title: `${config?.entityNames?.plural} Report`,
    },
  ],
  columns: [
    {
      title: "Employee Name",
      dataIndex: "name",
      selectable: true,
      sorter: (a, b) => a?.name?.localeCompare(b?.name),
    },
    {
      title: "Total Salary",
      dataIndex: "total_salary",
      selectable: true,
      sorter: (a, b) => a?.total_salary?.localeCompare(b?.total_salary),
    },
    {
      title: "Reimbursements",
      dataIndex: "reimbursement",
      selectable: true,
    },
    {
      title: "Advance Loan",
      dataIndex: "advanced_loan",
      selectable: true,
    },
    {
      title: "Salary Payable",
      dataIndex: "salary_payable",
      selectable: true,
    },
  ],
  ...config,
};
