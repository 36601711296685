import React from "react";
import { Route } from "react-router-dom";
import {
  MeetingTypeList,
  MeetingTypeAdd,
  MeetingTypeEdit,
} from "../pages/MeetingTypes";

export const register = (props) => {
  const basePath = "meeting-types";

  return (
    <>
      <Route path={basePath} element={<MeetingTypeList />} exact={true} />
      <Route path={`${basePath}/add`} element={<MeetingTypeAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<MeetingTypeEdit />} />
    </>
  );
};
