import React from "react";
import { Route } from "react-router-dom";
import {
  EventList,
  EventAdd,
  EventEdit,
  EventReport,
  EventReschedule,
  EventView,
} from "../pages/Events";

export const register = (props) => {
  const basePath = "events";

  return (
    <>
      <Route path={basePath} element={<EventList />} exact={true} />
      <Route path={`${basePath}/add`} element={<EventAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<EventEdit />} />
      <Route path={`${basePath}/view/:id`} element={<EventView />} />
      <Route
        path={`${basePath}/reschedule/:id`}
        element={<EventReschedule />}
      />
      <Route path={`${basePath}-report`} element={<EventReport />} />
    </>
  );
};
