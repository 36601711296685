import {
  MdArrowBack,
  MdDashboard,
  MdOutlineAppRegistration,
  MdOutlineInventory2,
  MdSchedule,
} from "react-icons/md";
import { BiCategoryAlt } from "react-icons/bi";
import { TbReportAnalytics, TbReport } from "react-icons/tb";
import {
  FaRegBuilding,
  FaLayerGroup,
  FaTools,
  FaFileInvoiceDollar,
} from "react-icons/fa";
import { FiSettings } from "react-icons/fi";
import { BsCartCheck } from "react-icons/bs";
import { CgSandClock } from "react-icons/cg";
import { GrDocumentConfig } from "react-icons/gr";
import { HiOutlineDocumentText } from "react-icons/hi";
import { IoDocumentTextOutline } from "react-icons/io5";
import _ from "lodash";
import { RiPieChart2Fill } from "react-icons/ri";

const BASE_SLUG = "/quick-stocks";

export const makeNavbar = ({ navigate, user }) => {
  const makeItem = ({ icon, id, label, scopes, children }) => {
    return {
      id,
      key: `${BASE_SLUG}/${id}`,
      label,
      icon,
      onClick: ({ keyPath }) => {
        navigate(keyPath?.[0]);
      },
      scopes,
      children,
    };
  };

  let roles = [];

  user?.app_roles?.forEach((appRole) => {
    appRole?.permissions?.forEach((appPermission) => {
      if (!roles?.includes(appPermission)) {
        roles?.push(appPermission);
      }
    });
  });

  roles = roles?.join(",");

  const menuItems = [
    makeItem({
      id: "dashboard",
      label: "Dashboard",
      icon: <RiPieChart2Fill style={styles.icon} />,
      scopes: [
        {
          id: "view",
          label: "View",
        },
      ],
    }),
    {
      key: "registration",
      label: "Registration",
      icon: <MdOutlineAppRegistration style={styles.icon} />,
      children: [
        makeItem({
          id: "agencies",
          label: "Agencies",
          icon: <FaRegBuilding style={styles.icon} />,
        }),
        makeItem({
          id: "sub-contractors",
          label: "Sub Contractors",
          icon: <FaRegBuilding style={styles.icon} />,
        }),
        makeItem({
          id: "vendors",
          label: "Vendors",
          icon: <FaRegBuilding style={styles.icon} />,
        }),
      ],
    },
    {
      key: "procurement",
      label: "Procurement",
      icon: <BsCartCheck style={styles.icon} />,
      children: [
        makeItem({
          id: "agencies",
          label: "Agencies",
          icon: <FaRegBuilding style={styles.icon} />,
          children: [
            makeItem({
              id: "agency-terms",
              label: "Terms & Conditions",
              icon: <IoDocumentTextOutline style={styles.icon} />,
            }),
            makeItem({
              id: "agency-payment-schedules",
              label: "Payment Schedules",
              icon: <IoDocumentTextOutline style={styles.icon} />,
            }),
            makeItem({
              id: "agencies-boq",
              label: "BOQ",
              icon: <IoDocumentTextOutline style={styles.icon} />,
            }),
            makeItem({
              id: "agency-requirement-forms",
              label: "Requirement Forms",
              icon: <IoDocumentTextOutline style={styles.icon} />,
            }),
            makeItem({
              id: "agency-quotations",
              label: "Quotations",
              icon: <IoDocumentTextOutline style={styles.icon} />,
            }),
            makeItem({
              id: "agency-quotations-comparison-lists",
              label: "Quotation Comparison Lists",
              icon: <IoDocumentTextOutline style={styles.icon} />,
            }),
            makeItem({
              id: "agency-work-orders",
              label: "Work Orders",
              icon: <IoDocumentTextOutline style={styles.icon} />,
            }),
          ],
        }),
        makeItem({
          id: "sub-contractors",
          label: "Sub Contractors",
          icon: <FaRegBuilding style={styles.icon} />,
          children: [
            makeItem({
              id: "sub-contractor-terms",
              label: "Terms & Conditions",
              icon: <IoDocumentTextOutline style={styles.icon} />,
            }),
            makeItem({
              id: "sub-contractor-payment-schedules",
              label: "Payment Schedules",
              icon: <IoDocumentTextOutline style={styles.icon} />,
            }),
            makeItem({
              id: "sub-contractors-boq",
              label: "BOQ",
              icon: <IoDocumentTextOutline style={styles.icon} />,
            }),
            makeItem({
              id: "sub-contractor-requirement-forms",
              label: "Requirement Forms",
              icon: <IoDocumentTextOutline style={styles.icon} />,
            }),
            makeItem({
              id: "sub-contractor-quotations",
              label: "Quotations",
              icon: <IoDocumentTextOutline style={styles.icon} />,
            }),
            makeItem({
              id: "sub-contractor-quotations-comparison-lists",
              label: "Quotation Comparison Lists",
              icon: <IoDocumentTextOutline style={styles.icon} />,
            }),
            makeItem({
              id: "sub-contractor-work-orders",
              label: "Work Orders",
              icon: <IoDocumentTextOutline style={styles.icon} />,
            }),
          ],
        }),
        makeItem({
          id: "vendors",
          label: "Vendors",
          icon: <FaRegBuilding style={styles.icon} />,
          children: [
            makeItem({
              id: "vendor-terms",
              label: "Terms & Conditions",
              icon: <IoDocumentTextOutline style={styles.icon} />,
            }),
            makeItem({
              id: "vendor-payment-schedules",
              label: "Payment Schedules",
              icon: <IoDocumentTextOutline style={styles.icon} />,
            }),
            makeItem({
              id: "required-task-materials",
              label: "Required Task Materials",
              icon: <IoDocumentTextOutline style={styles.icon} />,
            }),
            makeItem({
              id: "vendors-boq",
              label: "BOQ",
              icon: <IoDocumentTextOutline style={styles.icon} />,
            }),
            makeItem({
              id: "vendor-material-requisitions",
              label: "Material Requisitions",
              icon: <IoDocumentTextOutline style={styles.icon} />,
            }),
            makeItem({
              id: "vendor-quotations",
              label: "Quotations",
              icon: <IoDocumentTextOutline style={styles.icon} />,
            }),
            makeItem({
              id: "vendor-quotations-comparison-lists",
              label: "Quotation Comparison Lists",
              icon: <IoDocumentTextOutline style={styles.icon} />,
            }),
            makeItem({
              id: "vendor-purchase-orders",
              label: "Purchase Orders",
              icon: <IoDocumentTextOutline style={styles.icon} />,
            }),
          ],
        }),
      ],
    },
    {
      key: "inventory",
      label: "Inventory",
      icon: <MdOutlineInventory2 style={styles.icon} />,
      children: [
        {
          id: "debit-notes",
          label: "Debit Notes",
          icon: <IoDocumentTextOutline style={styles.icon} />,
          children: [
            makeItem({
              id: "agency-debit-notes",
              label: "Agencies",
              icon: <IoDocumentTextOutline style={styles.icon} />,
            }),
            makeItem({
              id: "sub-contractor-debit-notes",
              label: "Sub Contractors",
              icon: <IoDocumentTextOutline style={styles.icon} />,
            }),
            makeItem({
              id: "vendor-debit-notes",
              label: "Vendors",
              icon: <IoDocumentTextOutline style={styles.icon} />,
            }),
          ],
        },
        {
          id: "credit-notes",
          label: "Credit Notes",
          icon: <IoDocumentTextOutline style={styles.icon} />,
          children: [
            makeItem({
              id: "agency-credit-notes",
              label: "Agencies",
              icon: <IoDocumentTextOutline style={styles.icon} />,
            }),
            makeItem({
              id: "sub-contractor-credit-notes",
              label: "Sub Contractors",
              icon: <IoDocumentTextOutline style={styles.icon} />,
            }),
            makeItem({
              id: "vendor-credit-notes",
              label: "Vendors",
              icon: <IoDocumentTextOutline style={styles.icon} />,
            }),
          ],
        },
        {
          id: "extra-works",
          label: "Extra Works",
          icon: <IoDocumentTextOutline style={styles.icon} />,
          children: [
            makeItem({
              id: "agency-extra-works",
              label: "Agencies",
              icon: <IoDocumentTextOutline style={styles.icon} />,
            }),
            makeItem({
              id: "sub-contractor-extra-works",
              label: "Sub Contractors",
              icon: <IoDocumentTextOutline style={styles.icon} />,
            }),
            makeItem({
              id: "vendor-extra-works",
              label: "Vendors",
              icon: <IoDocumentTextOutline style={styles.icon} />,
            }),
          ],
        },
        makeItem({
          id: "vendor-material-receivables",
          label: "Material Receivables",
          icon: <IoDocumentTextOutline style={styles.icon} />,
        }),
        makeItem({
          id: "stock-ledgers",
          label: "Stock Ledgers",
          icon: <IoDocumentTextOutline style={styles.icon} />,
        }),
        makeItem({
          id: "released-notes",
          label: "Released Forms",
          icon: <IoDocumentTextOutline style={styles.icon} />,
        }),
        makeItem({
          id: "received-invoices",
          label: "Received Invoices",
          icon: <IoDocumentTextOutline style={styles.icon} />,
        }),
        // makeItem({
        //   id: "created-invoices",
        //   label: "Created Invoices",
        //   icon: <IoDocumentTextOutline style={styles.icon} />,
        // }),
      ],
    },
    {
      key: "billing",
      label: "Billing",
      icon: <IoDocumentTextOutline style={styles.icon} />,
      children: [
        makeItem({
          id: "quotations",
          label: "Create Quotation",
          icon: <IoDocumentTextOutline style={styles.icon} />,
        }),
        makeItem({
          id: "created-invoices",
          label: "Created Invoices",
          icon: <IoDocumentTextOutline style={styles.icon} />,
        }),
      ],
    },
    // {
    //   key: "billing",
    //   label: "Billing",
    //   icon: <IoDocumentTextOutline style={styles.icon} />,
    //   children: [
    //     makeItem({
    //       id: "quotations",
    //       label: "Create Quotation",
    //       icon: <IoDocumentTextOutline style={styles.icon} />,
    //     }),
    //     makeItem({
    //       id: "created-invoices",
    //       label: "Created Invoices",
    //       icon: <IoDocumentTextOutline style={styles.icon} />,
    //     }),
    //   ],
    // },
    {
      key: "machinery",
      label: "Machinery",
      icon: <FiSettings style={styles.icon} />,
      children: [
        makeItem({
          id: "machines",
          label: "Machines",
          icon: <FiSettings style={styles.icon} />,
        }),
        makeItem({
          id: "machine-logs",
          label: "Machinery Maintenance Logs",
          icon: <TbReport style={styles.icon} />,
        }),
        makeItem({
          id: "hand-tools",
          label: "Hand Tools",
          icon: <FaTools style={styles.icon} />,
        }),
        makeItem({
          id: "hand-tool-logs",
          label: "Hand Tool Logs",
          icon: <TbReport style={styles.icon} />,
        }),
        makeItem({
          id: "consumables",
          label: "Consumables",
          icon: <CgSandClock style={styles.icon} />,
        }),
      ],
    },
    makeItem({
      id: "reports",
      label: "Reports",
      icon: <TbReportAnalytics style={styles.icon} />,
      scopes: [
        {
          id: "view",
          label: "View",
        },
      ],
    }),
    {
      id: "settings",
      label: "Settings",
      icon: <FiSettings style={styles.icon} />,
      children: [
        {
          id: "agencies",
          label: "Agencies",
          icon: <FaRegBuilding style={styles.icon} />,
          children: [
            makeItem({
              id: "agency-groups",
              label: "Groups",
              icon: <BiCategoryAlt style={styles.icon} />,
            }),
          ],
        },
        {
          id: "sub-contractors",
          label: "Sub Contractors",
          icon: <FaRegBuilding style={styles.icon} />,
          children: [
            makeItem({
              id: "sub-contractor-groups",
              label: "Groups",
              icon: <BiCategoryAlt style={styles.icon} />,
            }),
          ],
        },
        {
          id: "vendors",
          label: "Vendors",
          icon: <FaRegBuilding style={styles.icon} />,
          children: [
            makeItem({
              id: "vendor-groups",
              label: "Groups",
              icon: <BiCategoryAlt style={styles.icon} />,
            }),
          ],
        },
        {
          id: "materials",
          label: "Materials",
          icon: <FaLayerGroup style={styles.icon} />,
          children: [
            makeItem({
              id: "material-groups",
              label: "Groups",
              icon: <BiCategoryAlt style={styles.icon} />,
            }),
            makeItem({
              id: "material-units",
              label: "Units",
              icon: <BiCategoryAlt style={styles.icon} />,
            }),
            makeItem({
              id: "material-brands",
              label: "Brands",
              icon: <BiCategoryAlt style={styles.icon} />,
            }),
            makeItem({
              id: "material-items",
              label: "Items",
              icon: <BiCategoryAlt style={styles.icon} />,
            }),
          ],
        },
        makeItem({
          id: "machinery",
          label: "Machinery",
          icon: <FiSettings style={styles.icon} />,
          children: [
            makeItem({
              id: "tool-types",
              label: "Machinery Types",
              icon: <BiCategoryAlt style={styles.icon} />,
            }),
            makeItem({
              id: "manufacturers",
              label: "Manufacturers",
              icon: <BiCategoryAlt style={styles.icon} />,
            }),
            // makeItem({
            //   id: "firm-types",
            //   label: "Company Types",
            //   icon: <BiCategoryAlt style={styles.icon} />,
            // }),
          ],
        }),
        makeItem({
          id: "work-orders",
          label: "Work Orders",
          icon: <IoDocumentTextOutline style={styles.icon} />,
          children: [
            makeItem({
              id: "work-groups",
              label: "Work Groups",
              icon: <BiCategoryAlt style={styles.icon} />,
            }),
            makeItem({
              id: "work-items",
              label: "Work Items",
              icon: <BiCategoryAlt style={styles.icon} />,
            }),
            makeItem({
              id: "work-units",
              label: "Work Units",
              icon: <BiCategoryAlt style={styles.icon} />,
            }),
          ],
        }),
        makeItem({
          id: "tds-types",
          label: "TDS Types",
          icon: <BiCategoryAlt style={styles.icon} />,
        }),
        makeItem({
          id: "gst-types",
          label: "GST Types",
          icon: <BiCategoryAlt style={styles.icon} />,
        }),
        makeItem({
          id: "inventory-settings",
          label: "Inventory",
          icon: <MdOutlineInventory2 style={styles.icon} />,
        }),
      ],
    },
    {
      key: "back-to-home",
      label: "Return Home",
      icon: <MdArrowBack style={styles.icon} />,
      onClick: () => navigate("/"),
    },
  ];

  if (user) {
    return menuItems?.filter((firstLevelItem) => {
      if (firstLevelItem?.key === "back-to-home") {
        return true;
      }

      if (firstLevelItem?.children) {
        firstLevelItem["children"] = firstLevelItem.children?.filter(
          (secondLevelItem) => {
            if (secondLevelItem?.children) {
              secondLevelItem["children"] = secondLevelItem.children?.filter(
                (thirdLevelItem) => {
                  return roles?.includes(
                    `QUICK-STOCKS.${thirdLevelItem?.id?.toUpperCase()}`
                  );
                }
              );
            } else {
              return roles?.includes(
                `QUICK-STOCKS.${secondLevelItem?.id?.toUpperCase()}`
              );
            }

            return secondLevelItem?.children?.length > 0;
          }
        );

        return firstLevelItem?.children?.length > 0;
      }

      return roles?.includes(
        `QUICK-STOCKS.${firstLevelItem?.id?.toUpperCase()}`
      );
    });
  } else {
    let filteredItems = [];

    menuItems?.forEach((firstLevelItem) => {
      if (firstLevelItem?.key !== "back-to-home") {
        if (firstLevelItem?.children) {
          firstLevelItem?.children?.forEach((secondLevelItem) => {
            if (secondLevelItem?.children) {
              secondLevelItem?.children?.forEach((thirdLevelItem) => {
                filteredItems?.push({
                  ...thirdLevelItem,
                  label: `${secondLevelItem?.label} - ${thirdLevelItem?.label}`,
                });
              });
            } else {
              filteredItems?.push(secondLevelItem);
            }
          });
        } else {
          filteredItems?.push(firstLevelItem);
        }
      }
    });

    return filteredItems;
  }
};

const styles = {
  icon: {
    fontSize: 16,
    color: "#CF6C57",
  },
};
