import React from "react";
import { Route } from "react-router-dom";
import { FirmTypeList, FirmTypeAdd, FirmTypeEdit } from "../pages/FirmTypes";

export const register = (props) => {
  const basePath = "firm-types";

  return (
    <>
      <Route path={basePath} element={<FirmTypeList />} exact={true} />
      <Route path={`${basePath}/add`} element={<FirmTypeAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<FirmTypeEdit />} />
    </>
  );
};
