import config from "../config";
import moment from "moment";

export default {
  id: `${config?.slugs?.plural}-list`,
  pageTitle: config?.entityNames?.plural,
  breadcrumbs: [
    {
      path: "/quick-talks/dashboard",
      title: "Home",
    },
    {
      path: "",
      title: config?.entityNames?.plural,
    },
  ],
  columns: [
    {
      title: "Meeting",
      dataIndex: "meeting",
      selectable: true,
      references: {
        fieldName: "name",
      },
    },
    {
      title: "Start Time",
      dataIndex: "start_time",
      selectable: true,
      selectable: true,
      sorter: (a, b) =>
        moment(a.start_time, "DD-MM-YYYY, HH:mm:ss.SSS").unix() -
        moment(b.start_time, "DD-MM-YYYY, HH:mm:ss.SSS").unix(),
      render: (text) => <span>{text?.split(".")?.[0]}</span>,
      width: 250,
    },
    {
      title: "End Time",
      dataIndex: "end_time",
      selectable: true,
      sorter: (a, b) =>
        moment(a.out_time, "DD-MM-YYYY, HH:mm:ss.SSS").unix() -
        moment(b.out_time, "DD-MM-YYYY, HH:mm:ss.SSS").unix(),
      render: (text) => <span>{text?.split(".")?.[0]}</span>,
      width: 250,
    },
    {
      title: "Employee",
      dataIndex: "employee",
      selectable: true,
      filterable: true,
      references: {
        fieldName: (record) => `${record?.first_name} ${record?.last_name}`,
      },
    },
  ],
  ...config,
};
