import React from "react";
import { Bar } from "@ant-design/plots";

const Chart = ({ data = [], xField, yField, seriesField }) => {
  const config = {
    data,
    isGroup: true,
    marginRatio: 0,
    label: {
      position: "right",
      offset: 4,
    },
    barStyle: {
      radius: [2, 2, 0, 0],
    },
    xField,
    yField,
    seriesField,
  };

  return <Bar {...config} />;
};

export default Chart;
