export default {
  slugs: {
    singular: "reminder",
    plural: "reminders",
  },
  entityNames: {
    singular: "Reminder",
    plural: "Reminders",
  },
};
