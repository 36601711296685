export default {
  slugs: {
    singular: "sub-contractor",
    plural: "sub-contractors",
  },
  entityNames: {
    singular: "Sub Contractor",
    plural: "Sub Contractors",
  },
};
