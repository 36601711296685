

import React, { useRef, useEffect, useState } from "react";
import { PageHeader, Breadcrumb, Space, Button, DatePicker, Col, Input, Row, Tooltip } from "antd";
import config from "./config";
import { useNavigate, Link } from "react-router-dom";
import { Box } from "@chakra-ui/react";
import pluralize from "pluralize";
import { ReloadOutlined, DownloadOutlined } from "@ant-design/icons";
import _ from "lodash";
import qs from "qs";
import moment from "moment";
import { useSelector } from "react-redux";
import { MAX_PAGINATION_LIMIT } from "../../../../../constants";
import { axios } from "../../../../../App";
import { ReportDataTable } from "../../../components";

const DATE_TIME_FORMAT = "YYYY-MM-DDTHH:mm:ss[Z]";

const Page = (props) => {
  const navigate = useNavigate();
  
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    pageSize: 10,
  });
  const [total, setTotal] = useState(0);
  const [searchValues, setSearchValues] = useState({});
  const [searched, setSearched] = useState(false);
  const { user } = useSelector((state) => state?.auth);
  const [chartData, setchartData] = useState([])

  const onBack = () => navigate(`/quick-projects/reports`);

  const fetchData = async (params = null) => {
    try {
      setLoading(true);

      const queryObject = {
        populate: "*",
        sort: ["createdAt:desc"],
        pagination: {
          limit: MAX_PAGINATION_LIMIT,
        },
      };

      if (!_.isEmpty(params)) {
        queryObject["filters"] = params;
      }

      const response = await axios.get(
        `${config?.slugs?.plural}?${qs.stringify(queryObject)}`
      );


      if (response?.data) {

        setData(
          response?.data?.data?.map((item) => ({
            id: item?.id,
            ...item?.attributes,
          }))
        );

        setchartData(
          response?.data?.data?.map((item) => ({
            type: item?.attributes?.no,
            value: item?.attributes?.progress ?? 0
          }))
        )

        setTotal(response?.data?.meta?.pagination?.total);

        setPagination({
          ...response?.data?.meta?.pagination,
          pageSize: 10,
        });
      }

    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const refreshData = () => {
    onSearch();
  };

  const downloadReport = () => {
    try {
      const data = {
        code: config?.code,
        params: {
          start_date: searchValues?.start_date
            ? searchValues?.start_date?.format("YYYY-MM-DD")
            : moment().subtract(1000, "years").format("YYYY-MM-DD"),
          end_date: searchValues?.end_date
            ? searchValues?.end_date?.format("YYYY-MM-DD")
            : moment().add(1000, "years").format("YYYY-MM-DD"),
        },
      };

      const link = document.createElement("a");
      link.href = `${process.env.REACT_APP_BASE_API_URL
        }/reporter?${qs.stringify(data)}`;

      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.log(error);
    }
  };

  const getColumnFilterProps = ({ dataIndex, references = {} }) => {
    if (typeof references?.fieldName === "function") {
      const transformer = references?.fieldName;

      return {
        render: (data) => transformer(data?.data?.attributes) || "N/A",
      };
    } else {
      return {
        render: (data) =>
          data?.data?.attributes?.[references?.fieldName] || "N/A",
      };
    }
  };

  const getColumnProps = (column) => {
    if (column?.references) {
      return getColumnFilterProps(column);
    }

    return column;
  };

  const onSearch = () => {
    setSearched(true);

    fetchData({
      $and: [
        {
          $or: [
            {
              name: {
                $containsi: searchValues?.keyword?.toLowerCase(),
              },
            },
            {
              pin_code: {
                $containsi: searchValues?.keyword?.toLowerCase(),
              },
            },
            {
              rera_no: {
                $containsi: searchValues?.keyword?.toLowerCase(),
              },
            },
            {
              address_line_1: {
                $containsi: searchValues?.keyword?.toLowerCase(),
              },
            },
            {
              address_line_2: {
                $containsi: searchValues?.keyword?.toLowerCase(),
              },
            },
            {
              description: {
                $containsi: searchValues?.keyword?.toLowerCase(),
              },
            },
            {
              createdAt: {
                $gte: moment(searchValues?.start_date).format(DATE_TIME_FORMAT),
                $lte: moment(searchValues?.end_date).format(DATE_TIME_FORMAT),
              },
            },
          ],
        },

        getFiltersForRoles(),
      ],
    });
  };

  const onReset = () => {
    setSearchValues({});
    fetchData(getFiltersForRoles());
    setSearched(false);
  };

  const getResultsMessage = () => {
    if (!_.isEmpty(searchValues) && !loading && searched) {
      return total > 0 ? (
        <span>
          <strong>{total}</strong> matched results
        </span>
      ) : (
        <span>No matched results</span>
      );
    }

    return (
      <span>
        <strong>{total}</strong> total records
      </span>
    );
  };

  const renderHeaderButtons = () => {
    let buttons = [
      <Tooltip placement="top" title="Refresh">
        <Button
          icon={<ReloadOutlined />}
          onClick={refreshData}
          loading={loading}
        />
      </Tooltip>,
    ];

    return buttons;
  };

  const getFiltersForRoles = () => {
    const userType = user?.user_type?.toLowerCase()?.replace(/\s/i, "_");

    if (user?.is_admin) {
      return {};
    }

    if (userType === "employee") {
      return {
        $or: [
          {
            project_managers: {
              uid: user?.uid,
            },
          },
          {
            members: {
              uid: user?.uid,
            },
          },
        ],
      };
    }

    return {
      [pluralize(userType)]: {
        uid: user?.uid,
      },
    };
  };

  useEffect(() => {
    fetchData(getFiltersForRoles());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const barConfig = {
    data: chartData,
    xField: 'value',
    yField: 'type',
    seriesField: 'type',
    colorField: 'type',
    tooltip: {
      fields: ['type', 'value'],
      showTitle: false,
    },
    barWidthRatio: 0.8,
    height: 400,
    xAxis: {
      label: {
        formatter: (v) => `${v}%`,
      },
    },
    label: {
      position: 'middle',
      layout: [
        {
          type: 'interval-adjust-position',
        },
        {
          type: 'interval-hide-overlap',
        },
        {
          type: 'adjust-color',
        },
      ],
    },
    interactions: [{ type: 'active-region' }],
  };

  return (
    <Box>
    {/* <div id="barGraph">
      <Bar {...barConfig} />
    </div> */}

    <ReportDataTable
      data={data}
      config={{
        ...config,
        columns: config?.columns?.map((column) => ({
          ...column,
          ...getColumnProps(column),
        })),
      }}
      loading={loading}
      pagination={pagination}
      actions={{
        onAdd: () => navigate(`/quick-projects/${config?.slugs?.plural}/add`),
        onRefresh: refreshData,
      }}
      Toolbar={
        <div className="toolbar">
          <Row gutter={16}>
            <Col span={10}>
              <Input
                value={searchValues?.keyword}
                placeholder="Search for keyword..."
                onChange={(e) =>
                  setSearchValues((prev) => ({
                    ...prev,
                    keyword: e.target.value,
                  }))
                }
              />
            </Col>

            <Col span={7}>
              <DatePicker
                onChange={(value) =>
                  setSearchValues((prev) => ({
                    ...prev,
                    start_date: value,
                  }))
                }
                value={searchValues?.start_date}
                placeholder="Select start date"
                style={styles.datePicker}
              />
            </Col>

            <Col span={7}>
              <DatePicker
                onChange={(value) =>
                  setSearchValues((prev) => ({
                    ...prev,
                    end_date: value,
                  }))
                }
                value={searchValues?.endDate}
                placeholder="Select end date"
                style={styles.datePicker}
              />
            </Col>
          </Row>

          <div className="toolbar-buttons">
            <p className="toolbar-results">{getResultsMessage()}</p>

            <Space>
              <Button onClick={onReset}>Reset</Button>
              <Button type="primary" onClick={onSearch}>
                Search
              </Button>
            </Space>
          </div>
        </div>
      }
      buttons={renderHeaderButtons()}
      onBack={() => navigate(`/quick-projects/reports`)}
    />
  </Box>
  );
};

const styles = {
  breadcrumbs: {},
  pageHeader: {
    padding: 0,
    paddingBottom: 12,
  },
};

export default Page;



// import React, { useState, useEffect } from "react";
// import { DataTable } from "../../../components";
// import { useNavigate } from "react-router-dom";
// import { Space, Button, Row, Col, Input, Tooltip, DatePicker } from "antd";
// import qs from "qs";
// import config from "./config";
// import { axios } from "../../../../../App";
// import _ from "lodash";
// import { ReloadOutlined, DownloadOutlined } from "@ant-design/icons";
// import moment from "moment";

// const DATE_TIME_FORMAT = "YYYY-MM-DDTHH:mm:ss[Z]";

// const Page = () => {
//   const navigate = useNavigate();
//   const [data, setData] = useState([]);
//   const [filters, setFilters] = useState({});
//   const [loading, setLoading] = useState(false);
//   const [pagination, setPagination] = useState({
//     pageSize: 10,
//   });
//   const [total, setTotal] = useState(0);
//   const [searchValues, setSearchValues] = useState({});

//   const fetchData = async (params = null) => {
//     try {
//       setLoading(true);

//       const queryObject = {
//         populate: "*",
//         sort: ["date:desc"],
//       };

//       if (!_.isEmpty(params)) {
//         queryObject["filters"] = params;
//       }

//       const response = await axios.get(
//         `${config?.slugs?.plural}?${qs.stringify(queryObject)}`
//       );

//       if (response?.data) {
//         setData(
//           response?.data?.data?.map((item) => ({
//             id: item?.id,
//             ...item?.attributes,
//           }))
//         );

//         setTotal(response?.data?.meta?.pagination?.total);

//         setPagination({
//           ...response?.data?.meta?.pagination,
//           pageSize: 10,
//         });
//       }
//     } catch (error) {
//       console.log(error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const refreshData = () => {
//     onSearch();
//   };

//   const downloadReport = () => {
//     try {
//       const data = {
//         code: config?.code,
//         params: {
//           start_date: searchValues?.start_date
//             ? searchValues?.start_date?.format("YYYY-MM-DD")
//             : moment().subtract(1000, "years").format("YYYY-MM-DD"),
//           end_date: searchValues?.end_date
//             ? searchValues?.end_date?.format("YYYY-MM-DD")
//             : moment().add(1000, "years").format("YYYY-MM-DD"),
//         },
//       };

//       const link = document.createElement("a");
//       link.href = `${
//         process.env.REACT_APP_BASE_API_URL
//       }/reporter?${qs.stringify(data)}`;

//       document.body.appendChild(link);
//       link.click();
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   const getColumnFilterProps = ({ dataIndex, references = {} }) => {
//     if (typeof references?.fieldName === "function") {
//       const transformer = references?.fieldName;

//       return {
//         render: (data) => transformer(data?.data?.attributes) || "N/A",
//       };
//     } else {
//       return {
//         render: (data) =>
//           data?.data?.attributes?.[references?.fieldName] || "N/A",
//       };
//     }
//   };

//   const getColumnProps = (column) => {
//     if (column?.references) {
//       return getColumnFilterProps(column);
//     }

//     return column;
//   };

//   const onSearch = () => {
//     fetchData({
//       $or: [
//         {
//           remarks: {
//             $containsi: searchValues?.keyword?.toLowerCase(),
//           },
//         },
//         {
//           description: {
//             $containsi: searchValues?.keyword?.toLowerCase(),
//           },
//         },
//         {
//           createdAt: {
//             $gte: moment(searchValues?.start_date).format(DATE_TIME_FORMAT),
//             $lte: moment(searchValues?.end_date).format(DATE_TIME_FORMAT),
//           },
//         },
//       ],
//     });
//   };

//   const onReset = () => {
//     setSearchValues({});
//     fetchData(null);
//   };

//   const getResultsMessage = () => {
//     if (!_.isEmpty(searchValues)) {
//       return total > 0 ? (
//         <span>
//           <strong>{total}</strong> matched results
//         </span>
//       ) : (
//         <span>No matched results</span>
//       );
//     }

//     return (
//       <span>
//         <strong>{total}</strong> total records
//       </span>
//     );
//   };

//   useEffect(() => {
//     fetchData(filters);
//   }, []);

//   return (
//     <DataTable
//       data={data}
//       config={{
//         ...config,
//         columns: config?.columns?.map((column) => ({
//           ...column,
//           ...getColumnProps(column),
//         })),
//       }}
//       loading={loading}
//       pagination={pagination}
//       actions={{
//         onAdd: () => navigate(`/quick-stocks/${config?.slugs?.plural}/add`),
//         onRefresh: refreshData,
//       }}
//       Toolbar={
//         <div className="toolbar">
//           <Row gutter={16}>
//             <Col span={10}>
//               <Input
//                 value={searchValues?.keyword}
//                 placeholder="Search for keyword..."
//                 onChange={(e) =>
//                   setSearchValues((prev) => ({
//                     ...prev,
//                     keyword: e.target.value,
//                   }))
//                 }
//               />
//             </Col>

//             <Col span={7}>
//               <DatePicker
//                 onChange={(value) =>
//                   setSearchValues((prev) => ({
//                     ...prev,
//                     start_date: value,
//                   }))
//                 }
//                 value={searchValues?.start_date}
//                 placeholder="Select start date"
//                 style={styles.datePicker}
//               />
//             </Col>

//             <Col span={7}>
//               <DatePicker
//                 onChange={(value) =>
//                   setSearchValues((prev) => ({
//                     ...prev,
//                     end_date: value,
//                   }))
//                 }
//                 value={searchValues?.endDate}
//                 placeholder="Select end date"
//                 style={styles.datePicker}
//               />
//             </Col>
//           </Row>

//           <div className="toolbar-buttons">
//             <p className="toolbar-results">{getResultsMessage()}</p>

//             <Space>
//               <Button onClick={onReset}>Reset</Button>
//               <Button type="primary" onClick={onSearch}>
//                 Search
//               </Button>
//             </Space>
//           </div>
//         </div>
//       }
//       buttons={[
//         <Tooltip placement="top" title="Refresh">
//           <Button
//             icon={<ReloadOutlined />}
//             onClick={refreshData}
//             loading={loading}
//           />
//         </Tooltip>,
//         <Tooltip placement="top" title="Download Report">
//           <Button icon={<DownloadOutlined />} onClick={downloadReport} />
//         </Tooltip>,
//       ]}
//       onBack={() => navigate(`/quick-stocks/reports`)}
//     />
//   );
// };

// const styles = {
//   datePicker: {
//     width: "100%",
//   },
// };

// export default Page;
