import React from "react";
import { Route } from "react-router-dom";
import {
  SubContractorExtraWorkList,
  SubContractorExtraWorkAdd,
  SubContractorExtraWorkReport,
  SubContractorExtraWorkCreatorEdit,
  SubContractorExtraWorkApproverEdit,
  SubContractorExtraWorkView,
} from "../pages/SubContractorExtraWorks";

export const register = (props) => {
  const basePath = "sub-contractor-extra-works";

  return (
    <>
      <Route
        path={basePath}
        element={<SubContractorExtraWorkList />}
        exact={true}
      />
      <Route path={`${basePath}/add`} element={<SubContractorExtraWorkAdd />} />
      <Route
        path={`${basePath}/creator/edit/:id`}
        element={<SubContractorExtraWorkCreatorEdit />}
      />
      <Route
        path={`${basePath}/approver/edit/:id`}
        element={<SubContractorExtraWorkApproverEdit />}
      />
      <Route
        path={`${basePath}/view/:id`}
        element={<SubContractorExtraWorkView />}
      />
      <Route
        path={`${basePath}-report`}
        element={<SubContractorExtraWorkReport />}
      />
    </>
  );
};
