/* eslint-disable import/no-anonymous-default-export */
import config from "../config";
import moment from "moment";

export default {
  id: `${config?.slugs?.plural}-list`,
  pageTitle: config?.entityNames?.plural,
  breadcrumbs: [
    {
      path: "/quick-teams/dashboard",
      title: "Home",
    },
    {
      path: "",
      title: config?.entityNames?.plural,
    },
  ],
  columns: [
    {
      title: "From Employee",
      dataIndex: "from_employee",
      selectable: true,
      filterable: true,
      references: {
        fieldName: (record) => `${record?.first_name} ${record?.last_name}`,
      },
    },
    {
      title: "To Employee",
      dataIndex: "to_employee",
      selectable: true,
      filterable: true,
      references: {
        fieldName: (record) => `${record?.first_name} ${record?.last_name}`,
      },
    },
    {
      title: "From Shift",
      dataIndex: "from_shift",
      selectable: true,
      filterable: true,
      references: {
        fieldName: "name",
      },
    },
    {
      title: "To Shift",
      dataIndex: "to_shift",
      selectable: true,
      filterable: true,
      references: {
        fieldName: "name",
      },
    },
    {
      title: "Date",
      dataIndex: "date",
      selectable: true,
      sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
      render: (text) => <span>{moment(text).format("DD-MM-YYYY")}</span>,
      width: 250,
    },
  ],
  ...config,
};
