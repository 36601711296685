import React from "react";
import { Route } from "react-router-dom";
import {
  TaskReportList,
  TaskReportAdd,
  TaskReportEdit,
  TaskReportVerify,
  TaskReportView,
  TaskReportViewVerification,
  TaskReportReport,
} from "../pages/TaskReports";

export const register = (props) => {
  const basePath = "task-reports";

  return (
    <>
      <Route path={basePath} element={<TaskReportList />} exact={true} />
      <Route path={`${basePath}/add`} element={<TaskReportAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<TaskReportEdit />} />
      <Route path={`${basePath}/verify/:id`} element={<TaskReportVerify />} />
      <Route path={`${basePath}/view/:id`} element={<TaskReportView />} />
      <Route
        path={`${basePath}/view-verification/:id`}
        element={<TaskReportViewVerification />}
      />
      <Route path={`${basePath}-report`} element={<TaskReportReport />} />
    </>
  );
};
