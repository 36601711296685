import React from "react";
import { Route } from "react-router-dom";
import { Profile } from "../pages/Profile";

export const register = (props) => {
  const basePath = "profile";

  return (
    <>
      <Route path={basePath} element={<Profile />} exact={true} />
    </>
  );
};
