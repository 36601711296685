import React from "react";
import { Route } from "react-router-dom";

import HomeContainer from "../pages/HomeContainer";
import Main from "../pages/Main";
import Faqs from "../pages/Faqs";
import UserGuide from "../pages/UserGuide";
import NotFound from "../pages/NotFound";

export const register = (props) => {
  return (
    <Route path="/" element={<HomeContainer />}>
      <Route path="/" element={<Main />} exact={true} />
      <Route path="/faqs" element={<Faqs />} />
      <Route path="/user-guide" element={<UserGuide />} />
      <Route path="*" element={<NotFound returnPath="/" />} />
    </Route>
  );
};
