import React from "react";
import { Route } from "react-router-dom";
import {
  LocationCheckInList,
  LocationCheckInReport,
} from "../pages/LocationCheckIns";

export const register = (props) => {
  const basePath = "location-check-ins";

  return (
    <>
      <Route path={basePath} element={<LocationCheckInList />} exact={true} />
      <Route path={`${basePath}-report`} element={<LocationCheckInReport />} />
    </>
  );
};
