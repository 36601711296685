import React, { useRef, useState } from "react";
import { axios } from "../../../../../App";
import config from "./config";
import qs from "qs";
import { useLocation, useNavigate } from "react-router-dom";
import {
    Button,
    Col,
    DatePicker,
    Input,
    Modal,
    Popconfirm,
    Row,
    Select,
    Space,
    Tooltip,
    notification,
} from "antd";

import _ from "lodash";
import { useEffect } from "react";
import moment from "moment";

import { ReloadOutlined } from "@ant-design/icons";
import { ReportDataTable } from "../../../../projects/components";
import { fetchData } from "../../../../../utils/helpers/data.helper";
import { useSelector } from "react-redux";

const { Option } = Select;
const DATE_TIME_FORMAT = "YYYY-MM-DDTHH:mm:ss[Z]";

const initialStartDate = moment().startOf('month').toISOString();
const initialEndDate = moment().endOf('month').toISOString();


const CalendarReport = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { user } = useSelector((state) => state?.auth);
    const [data, setData] = useState([]);
    const [filters, setFilters] = useState({});
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({
        pageSize: 10,
    });
    const [total, setTotal] = useState(0);
    const [searchValues, setSearchValues] = useState({
        from: initialStartDate,
        to: initialEndDate,
    })
    const [employeeId, setemployeeId] = useState(-1)
    const [employees, setemployees] = useState([])

    const styles = {
        toolbar: {
            marginBottom: 24,
        },
        rightToolbar: {
            textAlign: "right",
        },
        pageHeader: {
            padding: 0,
            paddingBottom: 24,
        },
        label: {
            display: "block",
            marginBottom: 3,
            marginTop: 6,
        },
        previewedImage: {
            width: "100%",
        },
        select: {
            width: "100%",
        },
        datePicker: {
            width: "100%",
        },

    };

    const fetchEmployees = async () => {
        try {
            const response = await axios.get(`employees`);

            if (response?.data) {
                let options = [
                    {
                        value: "",
                        label: "All Employees",
                    },
                ];

                response?.data?.data?.forEach((record) => {
                    options?.push({
                        value: record?.id,
                        label: `${record?.attributes?.first_name} ${record?.attributes?.last_name}`,
                    });
                });

                setemployees(options);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const fetchCalendar = () => {
        setLoading(true);

        fetchData({
            slug: "calendar",
            query: {
                employeeId,
                from: moment(searchValues?.from).format('YYYY-MM-DD'),
                to: moment(searchValues?.to).format('YYYY-MM-DD'),
            },
            onSuccess: (data) => {
                setLoading(false);
                setData(data);
            },
        });

    };

    const onSearch = () => {
        let filters = [];

        // Conditionally add date filter if start_date exists
        if (searchValues?.start_date) {
            filters.push({
                createdAt: {
                    $gte: moment(searchValues?.start_date).format(DATE_TIME_FORMAT),
                    $lte: searchValues?.end_date
                        ? moment(searchValues?.end_date).format(DATE_TIME_FORMAT)
                        : undefined,
                },
            });
        }

        fetchCalendar({
            $and: filters,
        });
    };


    const refreshData = () => {
        onSearch();
    };
    const onReset = () => {
        setSearchValues({});
        fetchCalendar(null);
    };

    const getColumnFilterProps = ({ dataIndex, references = {} }) => {
        if (typeof references?.fieldName === "function") {
            const transformer = references?.fieldName;

            return {
                render: (data) => transformer(data?.data?.attributes) || "N/A",
            };
        } else {
            return {
                render: (data) =>
                    data?.data?.attributes?.[references?.fieldName] || "N/A",
            };
        }
    };

    const getColumnProps = (column) => {
        if (column?.references) {
            return getColumnFilterProps(column);
        }

        return column;
    };


    useEffect(() => {
        fetchCalendar();
        fetchEmployees()
    }, []);

    console.log("Calendar Data", data);

    return (
        <>
            <ReportDataTable
                data={data}
                config={{
                    ...config,
                    columns: config?.columns
                        ?.map((column) => ({
                            ...column,
                            ...getColumnProps(column),
                        }))

                }}
                loading={loading}
                pagination={pagination}
                actions={{
                    onAdd: () => navigate(`/quick-stocks/${config?.slugs?.plural}/add`),
                    onRefresh: refreshData,
                }}
                Toolbar={
                    <div className="toolbar">
                        <Row gutter={16}>
                            <Col span={7}>
                                <Select
                                    style={{ minWidth: 180, width: "100%" }}
                                    placeholder="Select Employee"
                                    value={employeeId}
                                    onChange={setemployeeId}
                                >
                                    <Option value={-1}>All Employees</Option>

                                    {employees?.map((project) => (
                                        <Option value={project?.value}>{project?.label}</Option>
                                    ))}
                                </Select>
                            </Col>

                            <Col span={7}>
                                <DatePicker
                                    onChange={(value) =>
                                        setSearchValues((prev) => ({
                                            ...prev,
                                            from: value ? value.toISOString() : null,
                                        }))
                                    }
                                    value={searchValues?.from ? moment(searchValues.from) : null}
                                    placeholder="Select start date"
                                    style={styles.datePicker}
                                />
                            </Col>

                            <Col span={7}>
                                <DatePicker
                                    onChange={(value) =>
                                        setSearchValues((prev) => ({
                                            ...prev,
                                            to: value ? value.toISOString() : null,
                                        }))
                                    }
                                    value={searchValues?.to ? moment(searchValues.to) : null}
                                    placeholder="Select end date"
                                    style={styles.datePicker}
                                />
                            </Col>
                        </Row>

                        <div className="toolbar-buttons">
                            <p className="toolbar-results">{' '}</p>

                            <Space>
                                <Button onClick={onReset}>Reset</Button>
                                <Button type="primary" onClick={fetchCalendar}>
                                    Search
                                </Button>
                            </Space>
                        </div>
                    </div>
                }
                buttons={[
                    <Tooltip placement="top" title="Refresh">
                        <Button
                            icon={<ReloadOutlined />}
                            onClick={refreshData}
                            loading={loading}
                        />
                    </Tooltip>,
                    // <Tooltip placement="top" title="Download Report">
                    //   <Button icon={<DownloadOutlined />} onClick={downloadReport} />
                    // </Tooltip>,
                ]}
            />
        </>
    );
};

export default CalendarReport;
