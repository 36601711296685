import { useEffect } from "react";
import { notification } from "antd";
import io from "socket.io-client";
import { useSelector, useDispatch } from "react-redux";
import AppRoutes from "./routes";
import { useNavigate } from "react-router-dom";
import { updateUnreadCount } from "./redux/actions/notificationActions";
import { axios } from "./App";

export const Main = (props) => {
  const { user } = useSelector((state) => state?.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fetchUnreadNotifications = async () => {
    try {
      const response = await axios.post(`/notifications/count/unread`, {
        recipient_id: +user?.id,
        recipient_type: user?.user_type?.toUpperCase(),
      });

      if (response?.data) {
        dispatch(updateUnreadCount(response?.data?.data?.total));
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // Initial fetch
    fetchUnreadNotifications();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const socket = io(process.env.REACT_APP_BASE_API_URL);

    socket.on("notification", (data) => {
      if (
        data?.result?.recipient_type === user?.user_type?.toUpperCase() &&
        +data?.result?.recipient_id === +user?.id
      ) {
        fetchUnreadNotifications();

        notification.open({
          message: data?.result?.title,
          description: data.result?.content,
          placement: "bottomRight",
          onClick: () => {
            navigate(`/account/notifications/view/${data?.result?.id}`);
          },
        });
      }
    });

    return () => {
      // Disconnect the socket when the component unmounts
      socket.disconnect();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <AppRoutes />
    </>
  );
};
