import React from "react";
import { Route } from "react-router-dom";
import CalendarList from "../pages/Calendar/List";
import CalendarReport from "../pages/Calendar/Report";

export const register = (props) => {
  const basePath = "calendar";

  return (
    <>
      <Route path={basePath} element={<CalendarList />} exact={true} />
      <Route path={`${basePath}-report`} element={<CalendarReport />} exact={true} />

    </>
  );
};
