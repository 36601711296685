export default {
  slugs: {
    singular: "required-task-material",
    plural: "required-task-materials",
  },
  entityNames: {
    singular: "Required Task Material",
    plural: "Required Task Materials",
  },
};
