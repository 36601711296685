import React, { useEffect, useState } from "react";
import { ModalAddForm } from "../../../components";
import { useNavigate } from "react-router-dom";
import { makeConfig } from "./config";
import { fetchData } from "../../../../../utils/helpers/data.helper";

const Page = (props) => {
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({});
  const config = makeConfig({ navigate });
  const contentTypes = [
    {
      slug: "tasks",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          task: data,
        })),
    },
  ];

  useEffect(() => {
    contentTypes?.forEach((contentType) => {
      fetchData(contentType);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ModalAddForm
      config={config}
      data={props?.data}
      loading={loading}
      values={values}
      onSubmit={props?.onSubmit}
      onCancel={props?.onCancel}
    />
  );
};

export default Page;
