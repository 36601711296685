import React, { useEffect, useState } from "react";
import { ViewForm } from "../../../components";
import { useNavigate, useParams } from "react-router-dom";
import { makeConfig } from "./config";
import { axios } from "../../../../../App";
import { fetchData } from "../../../../../utils/helpers/data.helper";
import qs from "qs";

const Page = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    project: [],
    purchase_order: [],
    all_purchase_order: [],
    all_material_item: [],
    material_item: [],
  });
  const config = makeConfig({ navigate });
  const contentTypes = [
    {
      slug: "projects",
      query: {
        populate: "*",
      },
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          all_project: data,
          project: data,
        })),
    },
    {
      slug: "material-items",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          material_item: data,
        })),
    },
    {
      slug: "material-units",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          material_unit: data,
        })),
    },
  ];

  const fetchProjectMaterials = (projectId) => {
    fetchData({
      slug: "task-materials",
      query: {
        filters: {
          task: {
            project: projectId,
          },
        },
        populate: "*",
      },
      onSuccess: (data) => {
        const materialItemIds = data?.map(
          (record) => record?.material_item?.data?.id
        );

        setValues((prev) => ({
          ...prev,
          material_item: values?.all_material_item?.filter((item) =>
            materialItemIds?.includes(item?.value)
          ),
        }));
      },
    });
  };

  useEffect(() => {
    contentTypes?.forEach((contentType) => {
      fetchData(contentType);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const queryObject = {
          populate: "*",
        };

        const response = await axios.get(
          `/${config?.slugs?.plural}/${id}?${qs.stringify(queryObject)}`
        );

        if (response?.data) {
          const projectId = response?.data?.data?.attributes?.project?.data?.id;

          if (projectId) {
            fetchProjectMaterials(projectId);

            setValues((prev) => ({
              ...prev,
              purchase_order: values?.all_purchase_order
                ?.filter(
                  (item) => item?.project?.data?.id === Number(projectId)
                )
                ?.map((item) => ({
                  value: item?.id,
                  label: item?.heading,
                })),
            }));
          }

          let newData = {
            ...response?.data?.data?.attributes,
            id: response?.data?.data?.id,
            material_item:
              response?.data?.data?.attributes?.material_item?.data?.id,
            material_unit:
              response?.data?.data?.attributes?.material_unit?.data?.id,
            location: response?.data?.data?.attributes?.location?.data?.id,
            project: projectId,
          };

          setData(newData);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    config?.slugs?.plural,
    id,
    values?.all_project,
    values?.all_material_item,
    values?.all_purchase_order,
  ]);

  return (
    <ViewForm
      config={config}
      data={data}
      loading={loading}
      values={values}
      onFieldChange={{
        project: (antForm) => {
          const projectId = antForm.getFieldValue("project");

          if (projectId) {
            antForm.setFieldValue("purchase_order", undefined);
            antForm.setFieldValue("material_item", undefined);

            fetchProjectMaterials(projectId);

            setValues((prev) => ({
              ...prev,
              purchase_order: values?.all_purchase_order
                ?.filter(
                  (item) => item?.project?.data?.id === Number(projectId)
                )
                ?.map((item) => ({
                  value: item?.id,
                  label: item?.heading,
                })),
            }));
          }
        },
      }}
    />
  );
};

export default Page;
