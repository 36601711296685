import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { makeNavbar } from "../nav";
import { Space, Avatar } from "antd";
import { useSelector } from "react-redux";
import moment from "moment";
import { SimpleGrid, Image, Box, Center, Text } from "@chakra-ui/react";

const MODULE_ID = "quick-setup";

const HomePage = (props) => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state?.auth);
  const items = makeNavbar({ navigate, user });
  const [features, setFeatures] = useState([]);
  const [recentlyUsed, setRecentlyUsed] = useState([]);

  const loadRecentlyUsed = () => {
    let entries =
      JSON.parse(localStorage.getItem("QUICKSO_RECENTLY_USED")) || [];
    let filteredEntries = entries?.filter(
      (item) => item?.module === MODULE_ID?.toUpperCase()
    );

    if (filteredEntries?.length > 0) {
      setRecentlyUsed(
        filteredEntries?.sort((a, b) => b?.lastUsedAt - a?.lastUsedAt)
      );
    }
  };

  const onOpenFeature = (feature) => {
    // let entries =
    //   JSON.parse(localStorage.getItem("QUICKSO_RECENTLY_USED")) || [];

    // const newEntry = {
    //   id: `${MODULE_ID}-${feature?.id}`,
    //   module: MODULE_ID?.toUpperCase(),
    //   feature: {
    //     id: feature?.id,
    //     key: feature?.key,
    //     label: feature?.label,
    //   },
    //   lastUsedAt: moment().valueOf(),
    // };

    // const index = entries?.findIndex((item) => item?.id === newEntry?.id);

    // if (index > -1) {
    //   entries[index] = newEntry;
    // } else {
    //   entries.push(newEntry);
    // }

    // localStorage.setItem("QUICKSO_RECENTLY_USED", JSON.stringify(entries));

    navigate(feature?.key);
  };

  useEffect(() => {
    // loadRecentlyUsed();

    if (items?.length > 0) {
      let results = [];

      items?.forEach((item) => {
        if (item?.key !== "back-to-home") {
          if (item?.children?.length > 0) {
            item?.children?.forEach((child) => {
              results?.push(child);
            });
          } else {
            results?.push(item);
          }
        }
      });

      setFeatures(results);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={styles.container}>
      <Box>
        <Center>
          <Image
            src="/images/modules/quick-setup.png"
            alt=""
            style={styles.image}
          />
        </Center>
        <Center>
          <Text style={styles.title}>Quick Setup</Text>
        </Center>
      </Box>

      <section style={styles.main}>
        {recentlyUsed?.length > 0 && (
          <h3 style={styles.sectionTitle}>Recently Used</h3>
        )}

        <div style={styles.features}>
          {recentlyUsed?.length > 0 &&
            recentlyUsed?.map((record) => (
              <Space
                style={styles.feature}
                onClick={() => onOpenFeature(record)}
                key={record?.id}
              >
                <Avatar shape="square" size="large" style={styles.avatar}>
                  {record?.feature?.label?.split(" ")?.[0]?.[0]}
                </Avatar>

                <span>{record?.feature?.label}</span>
              </Space>
            ))}
        </div>

        <h3 style={{ ...styles.sectionTitle, marginTop: 2 }}>Features</h3>

        <SimpleGrid columns={[1, 1, 3, 4]} spacing={3} py={3}>
          {features?.length > 0 &&
            features?.map((feature) => (
              <Space
                style={styles.feature}
                onClick={() => onOpenFeature(feature)}
                key={feature?.id}
              >
                <Avatar shape="square" size="large" style={styles.avatar}>
                  {feature?.label?.split(" ")?.[0]?.[0]}
                </Avatar>

                <span>{feature?.label}</span>
              </Space>
            ))}
        </SimpleGrid>
      </section>
    </div>
  );
};

const styles = {
  container: {},
  header: {
    textAlign: "center",
  },
  image: {
    width: 72,
    height: 72,
  },
  title: {
    marginTop: 6,
    marginBottom: 1,
    fontSize: 18,
  },
  subtitle: {
    color: "#888",
    marginBottom: 0,
  },
  main: {
    padding: 24,
  },
  sectionTitle: {
    fontSize: 24,
    fontWeight: "normal",
    marginTop: 0,
  },
  feature: {
    cursor: "pointer",
  },
  features: {
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)",
    gridRowGap: 12,
  },
  avatar: {
    background: "#DCEDFD",
    color: "#3996FB",
    fontWeight: "bold",
  },
};

export default HomePage;
