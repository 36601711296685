export default {
  slugs: {
    singular: "agency-debit-note",
    plural: "agency-debit-notes",
  },
  entityNames: {
    singular: "Agency Debit Note",
    plural: "Agency Debit Notes",
  },
};
