import React, { useState, useEffect } from "react";
import { AddForm } from "../../../components";
import { useNavigate } from "react-router-dom";
import { makeConfig } from "./config";
import { useSelector } from "react-redux";
import { axios } from "../../../../../App";
import qs from "qs";

const Page = (props) => {
  const navigate = useNavigate();
  const auth = useSelector((state) => state?.auth);
  const config = makeConfig({ navigate, auth });
  const [leaveTypes, setLeaveTypes] = useState([]);
  const [employees, setEmployees] = useState([]);

  const fetchLeaveTypes = async () => {
    try {
      const queryString = {};

      const response = await axios.get(
        `/leave-types?${qs.stringify(queryString)}`
      );

      if (response?.data) {
        setLeaveTypes(
          response?.data?.data?.map((item) => ({
            value: item?.id,
            label: item?.attributes?.name,
          }))
        );
      } else {
        throw new Error();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchEmployees = async () => {
    try {
      const response = await axios.get(`/employees`);

      if (response?.data) {
        setEmployees(
          response?.data?.data?.map((item) => ({
            value: item?.id,
            label: `${item?.attributes?.first_name} ${item?.attributes?.last_name}`,
          }))
        );
      } else {
        throw new Error();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchLeaveTypes();
    fetchEmployees();
  }, []);

  return (
    <AddForm
      config={config}
      values={{
        employee: employees,
        representative: employees,
        // approved_by: employees,
        leave_type: leaveTypes,
        status: ["Pending", "Rejected", "Approved"]?.map((item) => ({
          value: item,
          label: item,
        })),
      }}
    />
  );
};

export default Page;
