import React, { useState, useEffect } from "react";
import {
  PageHeader,
  Breadcrumb,
  Row,
  Col,
  Form,
  Space,
  Button,
  notification,
} from "antd";
import FormElement from "../FormElement";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Box, Stack } from "@chakra-ui/react";

const AddForm = ({
  loading = false,
  config,
  data = {},
  values = {},
  disabled = false,
  showsHeader = true,
  saveText = "Save",
  showsCancel = true,
  showsTitle = true,
  onSuccess,
}) => {
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);

  const handleFinish = async (values) => {
    setSubmitting(true);
    const response = await config?.actions?.onFinish(values, data);

    if (response?.success) {
      notification["success"]({
        message: config?.title,
        description: response?.message,
      });

      if (onSuccess) {
        onSuccess();
      }

      config?.actions?.onBack();
    } else {
      notification["error"]({
        message: config?.title,
        description: response?.message,
      });
    }

    setSubmitting(false);
  };

  useEffect(() => {
    form.setFieldsValue(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <Helmet>
        <title>{config?.pageTitle}</title>
      </Helmet>

      {showsHeader && (
        <>
          <Breadcrumb style={styles.breadcrumbs}>
            {config?.breadcrumbs?.map((breadcrumb, index) => (
              <Breadcrumb.Item key={index}>
                <Link to={breadcrumb?.path}>{breadcrumb?.title}</Link>
              </Breadcrumb.Item>
            ))}
          </Breadcrumb>

          {showsTitle && (
            <PageHeader
              title={config?.title}
              style={styles.pageHeader}
              onBack={config?.actions?.onBack}
            ></PageHeader>
          )}
        </>
      )}

      <Form
        form={form}
        name={config?.id}
        layout="vertical"
        onFinish={handleFinish}
        initialValues={data}
        style={styles.form}
        scrollToFirstError
        disabled={disabled ? disabled : loading}
      >
        <Box display={{ base: "none", md: "block", lg: "block" }}>
          <Row gutter={24}>
            {config?.components.map((component) => (
              <Col span={component?.span} key={component?.name}>
                <FormElement
                  {...component}
                  values={values}
                  form={form}
                  data={data}
                  disabled={disabled ? disabled : loading}
                />
              </Col>
            ))}
          </Row>
        </Box>

        <Box display={{ base: "block", md: "none", lg: "none" }}>
          {config?.components.map((component) => {
            return (
              <Stack span={component?.span} key={component?.name}>
                <FormElement
                  {...component}
                  values={values}
                  form={form}
                  data={data}
                  disabled={disabled ? disabled : loading}
                />
              </Stack>
            );
          })}
        </Box>

        <Space>
          {showsCancel && (
            <Button onClick={config?.actions?.onBack}>Cancel</Button>
          )}

          <Button type="primary" htmlType="submit" loading={submitting}>
            {saveText}
          </Button>
        </Space>
      </Form>
    </>
  );
};

const styles = {
  pageHeader: {
    padding: 0,
    paddingBottom: 24,
  },
  form: {
    width: "100%",
  },
};

export default AddForm;
