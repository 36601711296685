import React from "react";
import { Route } from "react-router-dom";
import {
  TaskDocumentList,
  TaskDocumentAdd,
  TaskDocumentEdit,
  TaskDocumentReport,
} from "../pages/TaskDocuments";

export const register = (props) => {
  const basePath = "task-documents";

  return (
    <>
      <Route path={basePath} element={<TaskDocumentList />} exact={true} />
      <Route path={`${basePath}/add`} element={<TaskDocumentAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<TaskDocumentEdit />} />
      <Route path={`${basePath}-report`} element={<TaskDocumentReport />} />
    </>
  );
};
