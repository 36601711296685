import React from "react";
import { Route } from "react-router-dom";
import {
  SubContractorQuotationComparisonList,
  SubContractorQuotationComparisonView,
} from "../pages/SubContractorQuotationComparisonLists";

export const register = (props) => {
  const basePath = "sub-contractor-quotations-comparison-lists";

  return (
    <>
      <Route
        path={basePath}
        element={<SubContractorQuotationComparisonList />}
        exact={true}
      />
      <Route
        path={`${basePath}/view/:id`}
        element={<SubContractorQuotationComparisonView />}
      />
    </>
  );
};
