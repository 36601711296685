import { axios } from "../../../../../App";
import { v4 } from "uuid";
import config from "../config";
import { fetchData } from "../../../../../utils/helpers/data.helper";

export const makeConfig = ({ navigate, auth }) => ({
  id: `${config?.slugs?.plural}-add`,
  title: `Add New ${config?.entityNames?.singular}`,
  pageTitle: `Add New ${config?.entityNames?.singular}`,
  breadcrumbs: [
    {
      path: "/quick-projects/dashboard",
      title: "Home",
    },
    {
      path: `/quick-projects/${config?.slugs?.plural}`,
      title: config?.entityNames?.plural,
    },
    {
      path: "",
      title: `Add New ${config?.entityNames?.singular}`,
    },
  ],
  components: [
    {
      span: 12,
      component: "input",
      label: "No",
      name: "no",
      required: true,
      placeholder: "Enter no",
      rules: [
        {
          required: true,
          message: "No is required!",
        },
      ],
    },
    {
      span: 12,
      component: "input",
      label: "Name",
      name: "name",
      required: true,
      placeholder: "Enter name",
      rules: [
        {
          required: true,
          message: "Name is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Project Type",
      name: "project_type",
      placeholder: "Select project type",
      required: true,
      rules: [
        {
          required: true,
          message: "Project type is required!",
        },
      ],
    },
    {
      span: 12,
      component: "number",
      label: "Construction Area per sq.ft",
      addonAfter: "sq.ft",
      name: "construction_area",
      required: true,
      placeholder: "Enter Construction Area",
      rules: [
        {
          required: true,
          message: "Construction Area is required!",
        },
      ],
    },
    {
      span: 24,
      component: "select",
      label: "Project Managers",
      name: "project_managers",
      placeholder: "Select employees",
      required: true,
      rules: [
        {
          required: true,
          message: "Project managers is required!",
        },
      ],
      mode: "multiple",
    },
    {
      span: 24,
      component: "select",
      label: "Team",
      name: "members",
      placeholder: "Select employees",
      required: true,
      rules: [
        {
          required: true,
          message: "Members is required!",
        },
      ],
      mode: "multiple",
    },
    {
      span: 24,
      component: "select",
      label: "Gantt chart view permissions",
      name: "can_view_gantt_chart",
      placeholder: "Selected employees can view the Gantt chart",
      // required: true,
      // rules: [
      //   {
      //     required: true,
      //     message: "Members is required!",
      //   },
      // ],
      mode: "multiple",
    },
    {
      span: 24,
      component: "select",
      label: "Gantt chart update permissions",
      name: "can_update_gantt_chart",
      placeholder: "Selected employees can update the Gantt chart",
      mode: "multiple",
    },
    {
      span: 24,
      component: "select",
      label: "Project view permissions",
      name: "can_view_project",
      placeholder: "Selected employees can view project",
      mode: "multiple",
    },
    {
      span: 24,
      component: "select",
      label: "Project update permissions",
      name: "can_update_project",
      placeholder: "Selected employees can update project",
      // required: true,
      // rules: [
      //   {
      //     required: true,
      //     message: "Members is required!",
      //   },
      // ],
      mode: "multiple",
    },
    {
      span: 24,
      component: "select",
      label: "Partners",
      name: "partners",
      placeholder: "Select partners",
      mode: "multiple",
    },
    // {
    //   span: 24,
    //   component: "select",
    //   label: "Vendors",
    //   name: "vendors",
    //   placeholder: "Select vendors",
    //   mode: "multiple",
    // },
    {
      span: 24,
      component: "select",
      label: "Sub Contractors",
      name: "sub_contractors",
      placeholder: "Select sub contractors",
      mode: "multiple",
    },
    // {
    //   span: 24,
    //   component: "select",
    //   label: "Agencies",
    //   name: "agencies",
    //   placeholder: "Select agencies",
    //   mode: "multiple",
    // },
    {
      span: 24,
      component: "input",
      label: "Address Line 1",
      name: "address_line_1",
      required: true,
      placeholder: "Enter address line 1",
      rules: [
        {
          required: true,
          message: "Address line 1 is required!",
        },
      ],
    },
    {
      span: 24,
      component: "input",
      label: "Address Line 2",
      name: "address_line_2",
      placeholder: "Enter address line 2",
    },
    {
      span: 12,
      component: "select",
      label: "State",
      name: "state",
      placeholder: "Select state",
      required: true,
      rules: [
        {
          required: true,
          message: "State is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "City",
      name: "city",
      placeholder: "Select city",
      required: true,
      rules: [
        {
          required: true,
          message: "City is required!",
        },
      ],
    },
    {
      span: 12,
      component: "input",
      label: "PIN code",
      name: "pin_code",
      placeholder: "Enter PIN code",
      //only 6 digits can be entered
      rules: [
        {
          pattern: /^[0-9]{6}$/,
          message: "PIN code should be 6 digits",
        },
      ],
    },
    {
      span: 12,
      component: "input",
      label: "RERA No",
      name: "rera_no",
      placeholder: "Enter RERA no",
      // required: true,
      // rules: [
      //   {
      //     required: true,
      //     message: "GST no is required!",
      //   },
      // ],
    },
    {
      span: 12,
      component: "select",
      label: "Location",
      name: "location",
      placeholder: "Select location",
      // required: true,
      // rules: [
      //   {
      //     required: true,
      //     message: "Location is required!",
      //   },
      // ],
    },
    // {
    //   span: 12,
    //   component: "input",
    //   label: "Longitude",
    //   name: "longitude",
    //   placeholder: "Enter longitude",
    // },
    // {
    //   span: 12,
    //   component: "input",
    //   label: "Latitude",
    //   name: "latitude",
    //   placeholder: "Enter latitude",
    // },
    {
      span: 12,
      component: "select",
      label: "Company",
      name: "company",
      placeholder: "Select company",
      required: true,
      rules: [
        {
          required: true,
          message: "Company is required!",
        },
      ],
    },
    {
      span: 12,
      component: "date",
      label: "Start Date",
      name: "start_date",
      required: true,
      placeholder: "Select start date",
      rules: [
        {
          required: true,
          message: "Start date is required!",
        },
      ],
    },
    {
      span: 12,
      component: "date",
      label: "End Date",
      name: "end_date",
      required: true,
      placeholder: "Select end date",
      rules: [
        {
          required: true,
          message: "End date is required!",
        },
      ],
    },
    {
      span: 12,
      component: "date",
      label: "Actual Start Date",
      name: "actual_start_date",
      placeholder: "Select actual start date",
    },
    {
      span: 12,
      component: "date",
      label: "Actual End Date",
      name: "actual_end_date",
      placeholder: "Select actual end date",
    },
    {
      span: 12,
      component: "number",
      label: "Estimated Budget",
      name: "estimated_budget",
      placeholder: "Enter estimated budget",
      min: 0,
    },
    {
      span: 12,
      component: "number",
      label: "Sanctioned Budget",
      name: "sanctioned_budget",
      placeholder: "Enter sanctioned budget",
      min: 0,
    },
    {
      span: 12,
      component: "select",
      label: "Status",
      name: "status",
      placeholder: "Select status",
      required: true,
      rules: [
        {
          required: true,
          message: "Status is required!",
        },
      ],
    },
    {
      span: 24,
      component: "editor",
      label: "Description",
      name: "description",
      placeholder: "Enter description",
      rows: 5,
    },
  ],
  actions: {
    onBack: () => navigate(`/quick-projects/${config?.slugs?.plural}`),
    onFinish: async (values) => {
      let users_data;

      try {
        // Fetch user data
        await fetchData({
          slug: "users",
          onSuccess: (data) => {
            users_data = data?.filter((user) => user?.username == auth.user.uid);
          },
        });

        // Post the form data
        const response = await axios.post(`/${config?.slugs?.plural}`, {
          data: {
            ...values,
            uid: v4(),
            actual_start_date: values?.actual_start_date || null,
            actual_end_date: values?.actual_end_date || null,
            // creator: auth?.user?.id,
            creator: users_data?.[0]?.id
          },
        });

        // Handle successful response
        if (response?.data) {
          return {
            success: true,
            message: `Add new ${config?.entityNames?.singular?.toLowerCase()} successfully!`,
          };
        }

        // Handle failed response
        throw new Error();
      } catch (error) {
        // Handle any errors
        return {
          success: false,
          message: `Add new ${config?.entityNames?.singular?.toLowerCase()} failed!`,
        };
      }
    },
  },
});
