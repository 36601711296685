import React from "react";
import { Route } from "react-router-dom";
import ProjectChats from "../pages/ProjectChats";

export const register = (props) => {
  const basePath = "project-chats";

  return (
    <>
      <Route path={basePath} element={<ProjectChats />} exact={true} />
    </>
  );
};
