export default {
  slugs: {
    singular: "material-item",
    plural: "material-items",
  },
  entityNames: {
    singular: "Material Item",
    plural: "Material Items",
  },
};
