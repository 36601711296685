export default {
  slugs: {
    singular: "visitor",
    plural: "visitors",
  },
  entityNames: {
    singular: "Visitor",
    plural: "Visitors",
  },
};
