import config from "../config";
import moment from "moment";

export default {
  id: `${config?.slugs?.plural}-list`,
  pageTitle: `${config?.entityNames?.plural} Report`,
  code: "RFIS",
  breadcrumbs: [
    {
      path: "/quick-projects/dashboard",
      title: "Home",
    },
    {
      path: `/quick-projects/reports`,
      title: "Reports",
    },
    {
      path: "",
      title: `${config?.entityNames?.plural} Report`,
    },
  ],
  columns: [
    {
      title: "Project",
      dataIndex: "project",
      selectable: true,
      references: {
        fieldName: "name",
      },
    },
    {
      title: "Assigned To",
      dataIndex: "employee",
      selectable: true,
    },
    {
      title: "Subject",
      dataIndex: "subject",
      selectable: true,
      sorter: (a, b) => a?.subject?.localeCompare(b?.subject),
      width: 200,
    },
    {
      title: "Due Date",
      dataIndex: "due_date",
      selectable: true,
      sorter: (a, b) => moment(a.due_date).unix() - moment(b.due_date).unix(),
      render: (text) => <span>{moment(text).format("DD-MM-YYYY")}</span>,
    },
    {
      title: "Replied Date",
      dataIndex: "replied_date",
      selectable: true,
      sorter: (a, b) => moment(a.due_date).unix() - moment(b.due_date).unix(),
      render: (text) => <span>{moment(text).format("DD-MM-YYYY")}</span>,
    },
    {
      title: "Reply Person",
      dataIndex: "reply_person",
      selectable: true,
    },
  ],
  ...config,
};
