export default {
  slugs: {
    singular: "agency-group",
    plural: "agency-groups",
  },
  entityNames: {
    singular: "Agency Group",
    plural: "Agency Groups",
  },
};
