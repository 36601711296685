export default {
  slugs: {
    singular: "meeting-comment",
    plural: "meeting-comments",
  },
  entityNames: {
    singular: "Meeting Comment",
    plural: "Meeting Comments",
  },
};
