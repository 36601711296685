import React, { useState, useEffect } from "react";
import {
  Table,
  Space,
  Empty,
  DatePicker,
  Row,
  Col,
  Select,
  Button,
} from "antd";
import moment from "moment";
import { axios } from "../../../../../../App";
import _ from "lodash";
import { fetchData } from "../../../../../../utils/helpers/data.helper";
import qs from "qs";
import { Stack } from "@chakra-ui/react";

const { Option } = Select;

const TeamAvailabilityTab = () => {
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState();
  const [employees, setEmployees] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);

  const getCellColor = (value) => {
    const rate = parseInt(value) / 10;

    if (rate === 0) {
      return "white";
    } else if (rate > 0 && rate < 0.25) {
      return "#F4F3D4";
    } else if (rate > 0.25 && rate < 0.5) {
      return "#F9F960";
    } else if (rate > 0.5 && rate < 0.75) {
      return "#F6D041";
    } else {
      return "#F4A43F";
    }
  };

  const prepareColumns = () => {
    let results = [
      {
        title: "Member",
        width: 200,
        dataIndex: "name",
        key: "name",
        render: (_, { name }) => (
          <Space>
            <Space>
              <span>{name}</span>
            </Space>
          </Space>
        ),
      },
      {
        title: "Free Days",
        width: 100,
        dataIndex: "free_days",
        key: "free_days",
      },
    ];

    for (let i = 0; i <= 90; i++) {
      const day = moment().add(i, "days");

      results.push({
        title: day.format("DD/MM"),
        dataIndex: `day${i}`,
        key: day.format("DD-MM-YYYY"),
        render(text, record) {
          return {
            props: {
              style: {
                background: getCellColor(text),
                textAlign: "center",
              },
            },
            children: <div>{text || 0}</div>,
          };
        },
      });
    }

    return results;
  };

  const prepareRowData = ({ id, name, free_days, counts }) => {
    let result = {
      key: id,
      free_days,
      name,
    };

    for (let i = 0; i <= 90; i++) {
      const key = `day${i}`;

      result[key] =
        counts[moment().add(i, "days")?.startOf("day")?.format("YYYY-MM-DD")] ||
        0;
    }

    return result;
  };

  const fetchRows = async ({
    startTime = null,
    endTime = null,
    members = [],
  }) => {
    try {
      const queryObject = {
        start_time: startTime,
        end_time: endTime,
      };

      const response = await axios.get(
        `/team-availability?${qs.stringify(queryObject)}`
      );

      if (response?.data) {
        const selectedFirstNames = members?.map(member => member.split(' ')[0].trim().toLowerCase());

        // Filtering data based on first names
        const filteredData = response?.data?.data.filter(item => selectedFirstNames.includes(item.first_name.trim().toLowerCase()));

        console.log('filteres', filteredData)

        let newData = (selectedFirstNames?.length > 0 ? filteredData : response?.data?.data)?.map((item) => {
          let freeDays = 0;

          if (!_.isEmpty(item?.counts)) {
            Object.keys(item?.counts)?.forEach((count) => {
              if (count === 0) {
                freeDays += 1;
              }
            });
          }

          return prepareRowData({
            id: item?.id,
            name: `${item?.first_name} ${item?.last_name}`,
            free_days: freeDays,
            counts: item?.counts,
          });
        });

        setData(newData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchEmployees = () => {
    fetchData({
      slug: "employees",
      query: {
        filters: {
          is_deleted: false,
        },
      },
      transformer: (item) => ({
        value: item?.id,
        label: `${item?.first_name} ${item?.last_name}`,
      }),
      onSuccess: (data) => setEmployees(data),
    });
  };

  const onSearch = () => {
    fetchRows({
      startTime: selectedStartDate?.format("YYYY-MM-DD"),
      endTime: selectedEndDate?.format("YYYY-MM-DD"),
      members: selectedMembers,
    });
  };

  const onClear = () => {
    setSelectedMembers([]);
    setSelectedStartDate(null);
    setSelectedEndDate(null);

    fetchRows({
      startTime: moment().format("YYYY-MM-DD"),
      endTime: moment().add(90, "days").format("YYYY-MM-DD"),
      members: [],
    });
  };

  useEffect(() => {
    const newColumns = prepareColumns();
    setColumns(newColumns);

    fetchEmployees();
    fetchRows({
      startTime: moment().format("YYYY-MM-DD"),
      endTime: moment().add(90, "days").format("YYYY-MM-DD"),
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log('selected member', selectedMembers)

  return (
    <div className="team-availability-table" style={styles.container}>
      <Row gutter={16}>
        <Col className="gutter-row" span={24}>
          <Space direction="vertical" size="large" style={{ width: "100%" }}>
            <Stack direction={{ base: "column", md: "row" }}>
              <Col className="gutter-row" span={12}>
                <label>Members</label>
                <Select
                  showSearch
                  mode="multiple"
                  placeholder="Select members"
                  style={{ width: "100%" }}
                  maxTagCount={5}
                  value={selectedMembers}
                  onChange={setSelectedMembers}
                >
                  {employees?.map((employee) => (
                    <Option value={employee?.label}>{employee?.label}</Option>
                  ))}
                </Select>
              </Col>

              <Col>
                <label>Start Date</label>
                <DatePicker
                  placeholder="Select start date"
                  style={{ width: "100%" }}
                  value={selectedStartDate}
                  onChange={setSelectedStartDate}
                />
              </Col>

              <Col>
                <label>End Date</label>
                <DatePicker
                  placeholder="Select end date"
                  style={{ width: "100%" }}
                  value={selectedEndDate}
                  onChange={setSelectedEndDate}
                />
              </Col>
            </Stack>

            <Space
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
              align="center"
            >
              <Space>
                <Button type="primary" onClick={onSearch}>
                  Apply
                </Button>

                <Button onClick={onClear}>Clear</Button>
              </Space>
            </Space>

            {data?.length > 0 ? (
              <Table
                columns={columns}
                dataSource={data}
                scroll={{ x: columns?.length * 60 }}
                size="small"
                pagination={{ pageSize: 8 }}
              />
            ) : (
              <Empty />
            )}
          </Space>
        </Col>
      </Row>
    </div>
  );
};

const styles = {
  container: {
    margin: 0,
    padding: 0,
  },
};

export default TeamAvailabilityTab;
