import React from "react";
import { Route } from "react-router-dom";
import {
  VisitPurposeList,
  VisitPurposeAdd,
  VisitPurposeEdit,
} from "../pages/VisitPurposes";

export const register = (props) => {
  const basePath = "visit-purposes";

  return (
    <>
      <Route path={basePath} element={<VisitPurposeList />} exact={true} />
      <Route path={`${basePath}/add`} element={<VisitPurposeAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<VisitPurposeEdit />} />
    </>
  );
};
