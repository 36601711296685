import { axios } from "../../../../../App";
import { v4 } from "uuid";
import config from "../config";

export const makeConfig = ({ navigate, auth }) => ({
    id: `${config?.slugs?.plural}-add`,
    title: `Add New ${config?.entityNames?.singular}`,
    pageTitle: `Add New ${config?.entityNames?.singular}`,
    breadcrumbs: [
        {
            path: "/quick-stocks/dashboard",
            title: "Home",
        },
        {
            path: `/quick-stocks/${config?.slugs?.plural}`,
            title: config?.entityNames?.plural,
        },
        {
            path: "",
            title: `Add New ${config?.entityNames?.singular}`,
        },
    ],
    components: [
        {
            span: 0,
            component: "select",
            label: "Material Group",
            name: "material_image",
            placeholder: "Select material group",

        },
        {
            span: 12,
            component: "select",
            label: "Material Group",
            name: "material_group",
            placeholder: "Select material group",
            required: true,
            rules: [
                {
                    required: true,
                    message: "Material group is required!",
                },
            ],
        },
        {
            span: 12,
            component: "select",
            label: "Material Item",
            name: "material_item",
            placeholder: "Select material item",
            required: true,
            rules: [
                {
                    required: true,
                    message: "Material item is required!",
                },
            ],
        },
        {
            span: 12,
            component: "input",
            label: "HSN/SAC Code",
            name: "hsn_code",
            placeholder: "HSN/SAC Code",
            required: true,
            rules: [
                {
                    required: true,
                    message: "HSN/SAC code is required!",
                },
            ],
        },
        {
            span: 12,
            component: "number",
            label: "Quantity",
            name: "quantity",
            required: true,
            placeholder: "Enter Quantity",
            rules: [
                {
                    required: true,
                    message: "Quantity is required!",
                },
            ],
            min: 0,
        },
        {
            span: 12,
            component: "number",
            label: "Rate",
            name: "rate",
            required: true,
            placeholder: "Enter Rate",
            rules: [
                {
                    required: true,
                    message: "Rate is required!",
                },
            ],
            min: 0,
        },
        {
            span: 12,
            component: "number",
            label: "Taxable Amount",
            name: "taxable_amount",
            required: true,
            disabled: true,
            placeholder: "Enter Taxable Amount",
            rules: [
                {
                    required: true,
                    message: "Taxable Amount is required!",
                },
            ],
            min: 0,
        },
        {
            span: 0,
            component: "number",
            label: "Tax Amount",
            name: "tax_amount",
            required: true,
            disabled: true,
            placeholder: "Enter Taxable Amount",
            rules: [
                {
                    required: true,
                    message: "Taxable Amount is required!",
                },
            ],
            min: 0,
        },

        {
            span: 0,
            component: "number",
            name: "sgst_amount",
            required: true,
            disabled: true,
            min: 0,
        },
        {
            span: 0,
            component: "number",
            name: "cgst_amount",
            required: true,
            disabled: true,
            min: 0,
        },
        {
            span: 0,
            component: "number",
            name: "igst_amount",
            required: true,
            disabled: true,
            min: 0,
        },
        {
            span: 12,
            component: "number",
            label: "CGST%",
            name: "cgst",
            required: true,
            placeholder: "Enter CGST%",
            // rules: [
            //     {
            //         required: true,
            //         message: "GST% is required!",
            //     },
            // ],
        },
        {
            span: 12,
            component: "number",
            label: "SGST%",
            name: "sgst",
            required: true,
            placeholder: "Enter SGST%",
            // rules: [
            //     {
            //         required: true,
            //         message: "GST% is required!",
            //     },
            // ],
        },
        {
            span: 12,
            component: "number",
            label: "IGST%",
            name: "igst",
            required: true,
            placeholder: "Enter IGST%",
            // rules: [
            //     {
            //         required: true,
            //         message: "GST% is required!",
            //     },
            // ],
        },
        {
            span: 12,
            component: "number",
            label: "Total",
            name: "total",
            required: true,
            disabled: true,
            placeholder: "Enter Total",
            rules: [
                {
                    required: true,
                    message: "Total is required!",
                },
            ],
            min: 0,
        },
        // {
        //     span: 24,
        //     component: "number",
        //     label: "Number",
        //     name: "number",
        //     required: true,
        //     placeholder: "Enter Number",
        //     rules: [
        //         {
        //             required: true,
        //             message: "Number is required!",
        //         },
        //     ],
        //     min: 0,
        // },
        {
            span: 24,
            component: "editor",
            label: "Description",
            name: "description",
            placeholder: "Enter description",
            rows: 5,
        },
    ],
    actions: {
        onBack: () => navigate(`/quick-stocks/${config?.slugs?.plural}`),
        onFinish: async (values) => {
            try {
                const response = await axios.post(`/${config?.slugs?.plural}`, {
                    data: {
                        ...values,
                        uid: v4(),
                        // creator: auth?.user?.id,
                    },
                });

                if (response?.data) {
                    return {
                        success: true,
                        message: `Add new ${config?.entityNames?.singular?.toLowerCase()} successfully!`,
                    };
                }

                throw new Error();
            } catch (error) {
                return {
                    success: false,
                    message: `Add new ${config?.entityNames?.singular?.toLowerCase()} failed!`,
                };
            }
        },
    },
});
