import React from "react";
import { Route } from "react-router-dom";
import { TaskList, TaskAdd, TaskEdit, TaskReport } from "../pages/Tasks";

export const register = (props) => {
  const basePath = "tasks";

  return (
    <>
      <Route path={basePath} element={<TaskList />} exact={true} />
      <Route path={`${basePath}/add`} element={<TaskAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<TaskEdit />} />
      <Route path={`${basePath}-report`} element={<TaskReport />} />
    </>
  );
};
