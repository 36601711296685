import React from "react";
import { Route } from "react-router-dom";
import { CostCodeList, CostCodeAdd, CostCodeEdit } from "../pages/CostCodes";

export const register = (props) => {
  const basePath = "cost-codes";

  return (
    <>
      <Route path={basePath} element={<CostCodeList />} exact={true} />
      <Route path={`${basePath}/add`} element={<CostCodeAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<CostCodeEdit />} />
    </>
  );
};
