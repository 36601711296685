import config from "../config";

export default {
  id: `${config?.slugs?.plural}-list`,
  pageTitle: config?.entityNames?.plural,
  breadcrumbs: [
    {
      path: "/",
      title: "Home",
    },
    {
      path: "",
      title: config?.entityNames?.plural,
    },
  ],
  columns: [
    {
      title: "Qualification No",
      dataIndex: "qualification_no",
      selectable: true,
      sorter: (a, b) => a?.qualification_no?.localeCompare(b?.qualification_no),
    },
    {
      title: "Degree",
      dataIndex: "degree",
      selectable: true,
      sorter: (a, b) => a?.degree?.localeCompare(b?.degree),
    },
    {
      title: "Course",
      dataIndex: "course",
      selectable: true,
      sorter: (a, b) => a?.course?.localeCompare(b?.course),
    },
    {
      title: "University",
      dataIndex: "university",
      selectable: true,
      sorter: (a, b) => a?.university?.localeCompare(b?.university),
    },
  ],
  ...config,
};
