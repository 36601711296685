// eslint-disable-next-line import/no-anonymous-default-export
export default {
  slugs: {
    singular: "vendor-extra-work",
    plural: "vendor-extra-works",
  },
  entityNames: {
    singular: "Vendor Extra Work",
    plural: "Vendor Extra Works",
  },
};
