import { MdArrowBack } from "react-icons/md";
import { BiCategoryAlt } from "react-icons/bi";
import { BsTrash, BsFolder } from "react-icons/bs";
import { RiPieChart2Fill } from "react-icons/ri";

const BASE_SLUG = "/quick-drive";

export const makeNavbar = ({ navigate, user }) => {
  const makeItem = ({ icon, id, label, scopes }) => {
    return {
      id,
      key: `${BASE_SLUG}/${id}`,
      label,
      icon,
      onClick: () => navigate(`${BASE_SLUG}/${id}`),
      scopes,
    };
  };

  let roles = [];

  user?.app_roles?.forEach((appRole) => {
    appRole?.permissions?.forEach((appPermission) => {
      if (!roles?.includes(appPermission)) {
        roles?.push(appPermission);
      }
    });
  });

  roles = roles?.join(",");

  const menuItems = [
    makeItem({
      id: "dashboard",
      label: "Dashboard",
      icon: <RiPieChart2Fill style={styles.icon} />,
    }),
    makeItem({
      id: "my-drive",
      label: "My Drive",
      icon: <BsFolder style={styles.icon} />,
    }),
    // makeItem({
    //   id: "trash",
    //   label: "Trash",
    //   icon: <BsTrash style={styles.icon} />,
    // }),
    {
      key: "back-to-home",
      label: "Return Home",
      icon: <MdArrowBack style={styles.icon} />,
      onClick: () => navigate("/"),
    },
  ];

  let filteredItems = [];

  if (user) {
    menuItems?.forEach((item) => {
      const uid = item?.children ? item?.key : item?.id;

      if (item?.key === "back-to-home") {
        filteredItems?.push(item);
      }

      if (item?.children) {
        const newChildren = item?.children?.filter((item) => {
          const subItemUid = item?.id;

          return roles?.includes(`QUICK-DRIVE.${subItemUid?.toUpperCase()}`);
        });

        if (newChildren?.length > 0) {
          item["children"] = newChildren;
          filteredItems?.push(item);
        }
      } else {
        if (roles?.includes(`QUICK-DRIVE.${uid?.toUpperCase()}`)) {
          filteredItems?.push(item);
        }
      }
    });
  } else {
    menuItems?.forEach((item) => {
      if (item?.children) {
        item?.children?.forEach((child) => {
          filteredItems?.push(child);
        });
      } else {
        filteredItems?.push(item);
      }
    });
  }

  return filteredItems;
};

const styles = {
  icon: {
    fontSize: 16,
    color: "#CF6C57",
  },
};
