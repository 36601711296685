import { axios } from "../../../../../App";
import config from "../config";

export const makeConfig = ({ navigate }) => ({
  id: `${config?.plural}-edit`,
  title: `Edit ${config?.entityNames?.singular}`,
  pageTitle: `Edit ${config?.entityNames?.singular}`,
  breadcrumbs: [
    {
      path: "/quick-stocks/dashboard",
      title: "Home",
    },
    {
      path: `/quick-stocks/${config?.slugs?.plural}`,
      title: config?.entityNames?.plural,
    },
    {
      path: "",
      title: `Edit ${config?.entityNames?.singular}`,
    },
  ],
  components: [
    {
      span: 12,
      component: "input",
      label: "Name",
      name: "name",
      required: true,
      placeholder: "Enter name",
      rules: [
        {
          required: true,
          message: "Name is required!",
        },
      ],
    },
    {
      span: 12,
      component: "input",
      label: "SKU",
      name: "sku",
      required: true,
      placeholder: "Enter SKU",
      rules: [
        {
          required: true,
          message: "SKU is required!",
        },
      ],
    },
    {
      span: 12,
      component: "input",
      label: "HSN Code",
      name: "hsn_code",
      required: true,
      placeholder: "Enter HSN code",
      rules: [
        {
          required: true,
          message: "HSN code is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Material Group",
      name: "material_group",
      placeholder: "Select material group",
      required: true,
      rules: [
        {
          required: true,
          message: "Material group is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Material Brand",
      name: "material_brand",
      placeholder: "Select material brand",
      required: true,
      rules: [
        {
          required: true,
          message: "Material brand is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Material Unit",
      name: "material_unit",
      placeholder: "Select material unit",
      required: true,
      rules: [
        {
          required: true,
          message: "Material unit is required!",
        },
      ],
    },
    {
      span: 12,
      component: 'uploader',
      label: 'Material Image',
      name: 'material_image',
      placeholder: 'Upload image file',
      required: true,
      accept: '.png, .jpg, .jpeg',
      rules: [
        {
          required: true,
          message: 'Image is required!',
        },
      ],
    },
    {
      span: 12,
      component: "number",
      label: "Minimum",
      name: "minimum",
      required: true,
      placeholder: "Enter minimum",
      rules: [
        {
          required: true,
          message: "Minimum is required!",
        },
      ],
      min: 0,
    },
    {
      span: 24,
      component: "editor",
      label: "Description",
      name: "description",
      placeholder: "Enter description",
      rows: 5,
    },
  ],
  actions: {
    onBack: () => navigate(`/quick-stocks/${config?.slugs?.plural}`),
    onFinish: async (values, data) => {
      try {
        const response = await axios.put(
          `/${config?.slugs?.plural}/${data?.id}`,
          {
            data: values,
          }
        );

        if (response?.data) {
          return {
            success: true,
            message: `Edit ${config?.entityNames?.singular?.toLowerCase()} successfully!`,
          };
        }

        throw new Error();
      } catch (error) {
        return {
          success: false,
          message: error?.response?.data?.error?.message,
        };
      }
    },
  },
  ...config,
});
