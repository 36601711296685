import React from "react";
import { Route } from "react-router-dom";
import {
  DepartmentList,
  DepartmentAdd,
  DepartmentEdit,
} from "../pages/Departments";

export const register = (props) => {
  const basePath = "departments";

  return (
    <>
      <Route path={basePath} element={<DepartmentList />} exact={true} />
      <Route path={`${basePath}/add`} element={<DepartmentAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<DepartmentEdit />} />
    </>
  );
};
