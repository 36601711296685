export default {
  slugs: {
    singular: "sub-contractor-quotation",
    plural: "sub-contractor-quotations",
  },
  entityNames: {
    singular: "Sub Contractor Work Order",
    plural: "Sub Contractor Work Orders",
  },
};
