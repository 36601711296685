import config from "../config";

export const makeConfig = ({ navigate }) => ({
  id: `${config?.plural}-list`,
  title: `${config?.entityNames?.plural}`,
  pageTitle: `${config?.entityNames?.plural}`,
  breadcrumbs: [
    {
      path: "/quick-drive",
      title: "Home",
    },
    {
      path: "",
      title: `${config?.entityNames?.plural}`,
    },
  ],
  ...config,
});
