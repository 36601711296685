import config from "../config";
import moment from "moment";
import { Tag } from "antd";

const commify = (x) => {
  return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export default {
  id: `${config?.slugs?.plural}-list`,
  pageTitle: `${config?.entityNames?.plural} Report`,
  code: "MILESTONES",
  breadcrumbs: [
    {
      path: "/quick-projects/dashboard",
      title: "Home",
    },
    {
      path: `/quick-projects/reports`,
      title: "Reports",
    },
    {
      path: "",
      title: `${config?.entityNames?.plural} Report`,
    },
  ],
  columns: [
    {
      title: "Project Name",
      dataIndex: "project",
      selectable: true,
      references: {
        fieldName: "name",
      },
    },
    {
      title: "Name of Milestone",
      dataIndex: "name",
      selectable: true,
      sorter: (a, b) => a?.name?.localeCompare(b?.name),
      width: 250,
    },
    {
      title: "Percentage of Completion",
      dataIndex: "progress",
      selectable: true,
      render: (text) => commify(0),
      sorter: (a, b) => a?.progress - b?.progress,
    },
    {
      title: "Estimated Date",
      dataIndex: "end_date",
      selectable: true,
      sorter: (a, b) =>
        moment(a.end_date).unix() - moment(b.end_date).unix(),
      render: (text) => <span>{moment(text).format("DD-MM-YYYY")}</span>,
    },
    {
      title: "Actual Date",
      dataIndex: "actual_end_date",
      selectable: true,
      sorter: (a, b) =>
        moment(a.actual_end_date).unix() - moment(b.actual_end_date).unix(),
      render: (text) => <span>{moment(text).format("DD-MM-YYYY")}</span>,
    },
    {
      title: "Status",
      dataIndex: "status",
      selectable: true,
      render: (text) => <Tag>{text}</Tag>,
    },
  ],
  ...config,
};
