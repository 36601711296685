import React, { useState, useEffect } from "react";
import { DataTable } from "../../../components";
import { useNavigate } from "react-router-dom";
import { Space, Button, Popconfirm, Tooltip, notification, Input } from "antd";
import { DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import config from "./config";
import { axios } from "../../../../../App";
import _ from "lodash";
import { MAX_PAGINATION_LIMIT } from "../../../../../constants";
import { useSelector } from "react-redux";
import { ReloadOutlined } from "@ant-design/icons";

const Page = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    pageSize: 10,
  });
  const [total, setTotal] = useState(0);
  const [searchValues, setSearchValues] = useState({});
  const [searched, setSearched] = useState(false);
  const { user } = useSelector((state) => state?.auth);

  const fetchData = async (params = null) => {
    try {
      setLoading(true);

      const response = await axios.post(`${config?.slugs?.plural}/list`, {
        recipient_uid: user?.uid,
      });

      if (response?.data) {
        setData(response?.data?.data?.data);
        setTotal(response?.data?.data?.meta?.pagination?.total);
        setPagination({
          ...response?.data?.data?.meta?.pagination,
          pageSize: 10,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const refreshData = () => {
    onSearch();
  };

  const deleteItem = async (id) => {
    try {
      const response = await axios.delete(`${config?.slugs?.plural}/${id}`);

      if (response?.data) {
        notification["success"]({
          message: `Remove ${config?.entityNames?.singular}`,
          description: `Remove ${config?.entityNames?.singular} successfully!`,
        });

        refreshData();
      } else {
        throw new Error();
      }
    } catch (error) {
      notification["error"]({
        message: `Remove ${config?.entityNames?.singular}`,
        description: `Remove ${config?.entityNames?.singular} failed!`,
      });
    }
  };

  const getColumnFilterProps = ({ dataIndex, references = {} }) => {
    if (typeof references?.fieldName === "function") {
      const transformer = references?.fieldName;

      return {
        render: (data) => transformer(data?.data?.attributes) || "N/A",
      };
    } else {
      return {
        render: (data) =>
          data?.data?.attributes?.[references?.fieldName] || "N/A",
      };
    }
  };

  const getColumnProps = (column) => {
    if (column?.references) {
      return getColumnFilterProps(column);
    }

    return column;
  };

  const onSearch = () => {
    setSearched(true);

    fetchData({
      name: {
        $containsi: searchValues?.keyword?.toLowerCase(),
      },
    });
  };

  const onReset = () => {
    setSearchValues({});
    fetchData(null);
    setSearched(false);
  };

  const getResultsMessage = () => {
    if (!_.isEmpty(searchValues) && !loading && searched) {
      return total > 0 ? (
        <span>
          <strong>{total}</strong> matched results
        </span>
      ) : (
        <span>No matched results</span>
      );
    }

    return (
      <span>
        <strong>{total}</strong> total records
      </span>
    );
  };

  useEffect(() => {
    fetchData(filters);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DataTable
      data={data}
      config={{
        ...config,
        columns: config?.columns
          ?.map((column) => ({
            ...column,
            ...getColumnProps(column),
          }))
          ?.concat({
            title: "Actions",
            //fixed: "right",
            width: 100,
            selectable: false,
            render: ({ id }) => {
              return (
                <Space>
                  <Tooltip
                    placement="bottom"
                    title={`View ${config?.entityNames?.singular}`}
                  >
                    <Button
                      icon={<EyeOutlined />}
                      onClick={() =>
                        navigate(`/account/${config?.slugs?.plural}/view/${id}`)
                      }
                    />
                  </Tooltip>

                  {/* <Popconfirm
                    title={`Are you sure to remove this ${config?.entityNames?.singular}?`}
                    okText="Yes"
                    cancelText="No"
                    onConfirm={() => deleteItem(id)}
                  >
                    <Tooltip
                      placement="bottom"
                      title={`Remove ${config?.entityNames?.singular}`}
                    >
                      <Button type="danger" icon={<DeleteOutlined />} />
                    </Tooltip>
                  </Popconfirm> */}
                </Space>
              );
            },
          }),
      }}
      loading={loading}
      pagination={pagination}
      actions={{
        onAdd: () => navigate(`/quick-projects/${config?.slugs?.plural}/add`),
        onRefresh: refreshData,
      }}
    />
  );
};

export default Page;
