export default {
  slugs: {
    singular: "user-type",
    plural: "user-types",
  },
  entityNames: {
    singular: "User Type",
    plural: "User Types",
  },
};
