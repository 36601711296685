import React from "react";
import { Route } from "react-router-dom";
import {
  VendorExtraWorkList,
  VendorExtraWorkAdd,
  VendorExtraWorkReport,
  VendorExtraWorkCreatorEdit,
  VendorExtraWorkApproverEdit,
  VendorExtraWorkView,
} from "../pages/VendorExtraWorks";

export const register = (props) => {
  const basePath = "vendor-extra-works";

  return (
    <>
      <Route path={basePath} element={<VendorExtraWorkList />} exact={true} />
      <Route path={`${basePath}/add`} element={<VendorExtraWorkAdd />} />
      <Route
        path={`${basePath}/creator/edit/:id`}
        element={<VendorExtraWorkCreatorEdit />}
      />
      <Route
        path={`${basePath}/approver/edit/:id`}
        element={<VendorExtraWorkApproverEdit />}
      />
      <Route path={`${basePath}/view/:id`} element={<VendorExtraWorkView />} />
      <Route path={`${basePath}-report`} element={<VendorExtraWorkReport />} />
    </>
  );
};
