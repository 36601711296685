import moment from "moment";
import { Link } from "react-router-dom";
import { FiExternalLink } from "react-icons/fi";
import { Button } from "antd";

export const AttendanceColumns = [
  {
    title: "ID",
    dataIndex: "id",
  },
  {
    title: "Employee",
    dataIndex: "employee",
  },
  {
    title: "Attendance Type",
    dataIndex: "attendance_type",
  },
  {
    title: "Date",
    dataIndex: "date",
    render: (text) => <span>{moment(text).format("DD-MM-YYYY, HH:mm")}</span>,
  },
  {
    title: "In Time",
    dataIndex: "in_time",
    render: (text) => <span>{text?.split(".")?.[0]}</span>,
  },
  {
    title: "Out Time",
    dataIndex: "out_time",
    render: (text) => <span>{text?.split(".")?.[0]}</span>,
  },
  {
    title: "Details",
    dataIndex: "id",
    render: (text) => (
      <Link to={`/quick-teams/attendances`}>
        <Button icon={<FiExternalLink style={{ margin: "0 auto" }} />}></Button>
      </Link>
    ),
  },
];
