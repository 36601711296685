export default {
  slugs: {
    singular: "project",
    plural: "projects",
  },
  entityNames: {
    singular: "Project Cost",
    plural: "Project Costs",
  },
};
