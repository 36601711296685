// eslint-disable-next-line import/no-anonymous-default-export
export default {
  slugs: {
    singular: "agency-credit-note",
    plural: "agency-credit-notes",
  },
  entityNames: {
    singular: "Agency Credit Note",
    plural: "Agency Credit Notes",
  },
};
