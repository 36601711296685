import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import styles from "./styles.module.css";
import { fetchData } from "../../../../../../../../utils/helpers/data.helper";

const DATE_HEADER_CELL_WIDTH = 120;
const DATE_HEADER_CELL_HEIGHT = 86;

const DATA_ROW_HEIGHT = 62.5;
const PROGRESS_BAR_HEIGHT = 30;

const ARROW_TRIANGLE_WIDTH = 10;

const RightPanel = ({
  tasks,
  activeTaskId = -1,
  latestTime,
  miscData,
  expandedIds,
}) => {
  const [dates, setDates] = useState([]);
  const [xByDates, setXByDates] = useState({});
  const [holidays, setholidays] = useState([])
  const [holidayDatesSet, setHolidayDatesSet] = useState(new Set());
  const containerRef = useRef();

  const initDates = () => {
    let results = [];
    let newXByDates = {};
    let index = 0;

    const a = moment().startOf("year");
    const b = moment().endOf("year");

    for (let m = moment(a); m.diff(b, "days") <= 0; m.add(1, "days")) {
      results.push({
        dateString: m.format("DD-MM-YYYY"),
        dayName: m.format("dddd"),
      });

      newXByDates[m.format("DD-MM-YYYY")] = DATE_HEADER_CELL_WIDTH * index;

      index += 1;
    }

    setDates(results);
    setXByDates(newXByDates);
  };


  const renderHeader = () => {
    return (
      <g>
        {dates?.map((dateItem, index) => {
          const isHoliday = holidayDatesSet.has(dateItem.dateString);
          return (
            <g key={dateItem?.dateString}>
              <rect
                x={index * DATE_HEADER_CELL_WIDTH}
                y={0}
                width={DATE_HEADER_CELL_WIDTH}
                height={DATE_HEADER_CELL_HEIGHT}
                stroke="rgba(0, 0, 0, 0.06)"
                style={{
                  fill: isHoliday ? "#d3d3d3" : "#fafafa", // Gray out holidays
                }}
              />
              <text
                x={index * DATE_HEADER_CELL_WIDTH + DATE_HEADER_CELL_WIDTH / 2}
                y={DATE_HEADER_CELL_HEIGHT * 0.4}
                textAnchor="middle"
                fontWeight="bold"
              >
                {dateItem?.dateString}
              </text>
              <text
                x={index * DATE_HEADER_CELL_WIDTH + DATE_HEADER_CELL_WIDTH / 2}
                y={DATE_HEADER_CELL_HEIGHT * 0.7}
                textAnchor="middle"
              >
                {dateItem?.dayName}
              </text>
            </g>
          );
        })}
      </g>
    );
  };


  const renderContainer = (index) => {
    return (
      <g>
        <rect
          x={0}
          //   y={DATE_HEADER_CELL_HEIGHT + 43 * index}
          y={DATE_HEADER_CELL_HEIGHT + DATA_ROW_HEIGHT * index}
          width={dates?.length * DATE_HEADER_CELL_WIDTH}
          //   height={43}
          height={DATA_ROW_HEIGHT}
          stroke="#eee"
          style={{
            fill: "#fff",
          }}
        />
      </g>
    );
  };

  const renderGrid = (index) => {
    return (
      <g>
        {dates?.map((dateItem, itemIndex) => {
          const isHoliday = holidayDatesSet.has(dateItem.dateString);
          return (
            <rect
              key={`${index}-${itemIndex}`}
              x={DATE_HEADER_CELL_WIDTH * itemIndex}
              y={DATE_HEADER_CELL_HEIGHT + DATA_ROW_HEIGHT * index}
              width={DATE_HEADER_CELL_WIDTH}
              height={DATA_ROW_HEIGHT}
              stroke="#eee"
              style={{
                fill: isHoliday ? "#d3d3d3" : "#fff",
              }}
            />
          );
        })}
      </g>
    );
  };


  const renderProgressBar = (index, item) => {
    const actualBarProps = getBarProps(
      miscData?.task_progresses
        ?.find((o) => o?.id === item?.id)
        ?.actual_start_date?.split("-")
        ?.reverse()
        ?.join("-") || item?.start_date,
      miscData?.task_progresses
        ?.find((o) => o?.id === item?.id)
        ?.actual_end_date?.split("-")
        ?.reverse()
        ?.join("-") || item?.end_date
    );
    const progress =
      miscData?.task_progresses?.find((o) => o?.id === item?.id)?.progress || 0;


    return (
      <g className={styles.progressBar}>
        <rect
          // x={DATE_HEADER_CELL_WIDTH * index}
          x={actualBarProps?.x}
          y={
            DATE_HEADER_CELL_HEIGHT +
            DATA_ROW_HEIGHT * index +
            DATA_ROW_HEIGHT * 0.1
          }
          // width={DATE_HEADER_CELL_WIDTH * 1}
          width={actualBarProps?.width}
          height={PROGRESS_BAR_HEIGHT}
          style={{
            fill: "#C1E8FE",
          }}
        />

        {/* Progress Bar Value */}
        <rect
          className={styles.progressBar}
          x={actualBarProps?.x}
          y={
            DATE_HEADER_CELL_HEIGHT +
            DATA_ROW_HEIGHT * index +
            DATA_ROW_HEIGHT * 0.1
          }
          width={actualBarProps?.width * (progress / 100)}
          height={PROGRESS_BAR_HEIGHT}
          style={{
            fill: "#31B6FF",
          }}
        />

        <text
          x={actualBarProps?.x + 12}
          y={
            DATE_HEADER_CELL_HEIGHT +
            DATA_ROW_HEIGHT * index +
            DATA_ROW_HEIGHT * 0.4
          }
          textAnchor="start"
        >
          {progress}%
        </text>
      </g>
    );
  };

  const getBarProps = (startDate, endDate) => {
    const startX = xByDates[moment(startDate).format("DD-MM-YYYY")] || 0;
    const endX =
      xByDates[moment(endDate)?.add(1, "days")?.format("DD-MM-YYYY")] || 0;

    return {
      x: startX,
      width: endX - startX,
    };
  };

  const renderBaseLines = (index, item) => {
    const estimatedBarProps = getBarProps(item?.start_date, item?.end_date);
    const actualBarProps = getBarProps(
      miscData?.task_progresses
        ?.find((o) => o?.id === item?.id)
        ?.actual_start_date?.split("-")
        ?.reverse()
        ?.join("-") || item?.start_date,
      miscData?.task_progresses
        ?.find((o) => o?.id === item?.id)
        ?.actual_end_date?.split("-")
        ?.reverse()
        ?.join("-") || item?.end_date
    );

    return (
      <g>
        <rect
          // x={DATE_HEADER_CELL_WIDTH * index}
          x={estimatedBarProps?.x}
          y={
            DATE_HEADER_CELL_HEIGHT +
            DATA_ROW_HEIGHT * index +
            DATA_ROW_HEIGHT * 0.65
          }
          // width={DATE_HEADER_CELL_WIDTH}
          width={estimatedBarProps?.width}
          height={6}
          style={{
            fill: "#dedede",
          }}
        />

        <rect
          x={actualBarProps?.x}
          y={
            DATE_HEADER_CELL_HEIGHT +
            DATA_ROW_HEIGHT * index +
            DATA_ROW_HEIGHT * 0.8
          }
          width={actualBarProps?.width}
          height={6}
          style={{
            fill: "#6ED331",
          }}
        />
      </g>
    );
  };

  const renderDependencies = (index1, index2) => {
    return (
      <g>
        {/* Top */}
        <line
          x1={DATE_HEADER_CELL_WIDTH * index1 + DATE_HEADER_CELL_WIDTH}
          y1={
            DATE_HEADER_CELL_HEIGHT +
            DATA_ROW_HEIGHT * index1 +
            DATA_ROW_HEIGHT * 0.3
          }
          x2={DATE_HEADER_CELL_WIDTH * index1 + DATE_HEADER_CELL_WIDTH + 24}
          y2={
            DATE_HEADER_CELL_HEIGHT +
            DATA_ROW_HEIGHT * index1 +
            DATA_ROW_HEIGHT * 0.3
          }
          stroke="#000"
        />

        <line
          x1={DATE_HEADER_CELL_WIDTH * index1 + DATE_HEADER_CELL_WIDTH + 24}
          y1={
            DATE_HEADER_CELL_HEIGHT +
            DATA_ROW_HEIGHT * index1 +
            DATA_ROW_HEIGHT * 0.3
          }
          x2={DATE_HEADER_CELL_WIDTH * index1 + DATE_HEADER_CELL_WIDTH + 24}
          y2={
            DATE_HEADER_CELL_HEIGHT +
            DATA_ROW_HEIGHT * index1 +
            DATA_ROW_HEIGHT * 0.8
          }
          stroke="#000"
        />

        {/* Bottom */}
        <line
          x1={DATE_HEADER_CELL_WIDTH * index2}
          y1={
            DATE_HEADER_CELL_HEIGHT +
            DATA_ROW_HEIGHT * index2 +
            DATA_ROW_HEIGHT * 0.3
          }
          x2={DATE_HEADER_CELL_WIDTH * index2 - 24}
          y2={
            DATE_HEADER_CELL_HEIGHT +
            DATA_ROW_HEIGHT * index2 +
            DATA_ROW_HEIGHT * 0.3
          }
          stroke="#000"
        />

        <line
          x1={DATE_HEADER_CELL_WIDTH * index2 - 24}
          y1={
            DATE_HEADER_CELL_HEIGHT +
            DATA_ROW_HEIGHT * index2 +
            DATA_ROW_HEIGHT * 0.3
          }
          x2={DATE_HEADER_CELL_WIDTH * index2 - 24}
          y2={
            DATE_HEADER_CELL_HEIGHT +
            DATA_ROW_HEIGHT * index2 -
            DATA_ROW_HEIGHT * 0.2
          }
          stroke="#000"
        />

        {/* Connection */}
        <line
          x1={DATE_HEADER_CELL_WIDTH * index2 - 24}
          y1={
            DATE_HEADER_CELL_HEIGHT +
            DATA_ROW_HEIGHT * index2 -
            DATA_ROW_HEIGHT * 0.2
          }
          x2={DATE_HEADER_CELL_WIDTH * index1 + DATE_HEADER_CELL_WIDTH + 24}
          y2={
            DATE_HEADER_CELL_HEIGHT +
            DATA_ROW_HEIGHT * index1 +
            DATA_ROW_HEIGHT * 0.8
          }
          stroke="#000"
        />

        {/* Triangle */}
        <polygon
          points={`${DATE_HEADER_CELL_WIDTH * index2 - ARROW_TRIANGLE_WIDTH},${DATE_HEADER_CELL_HEIGHT +
            DATA_ROW_HEIGHT * index2 +
            DATA_ROW_HEIGHT * 0.2
            } ${DATE_HEADER_CELL_WIDTH * index2 - ARROW_TRIANGLE_WIDTH},${DATE_HEADER_CELL_HEIGHT +
            DATA_ROW_HEIGHT * index2 +
            DATA_ROW_HEIGHT * 0.4
            } ${DATE_HEADER_CELL_WIDTH * index2},${DATE_HEADER_CELL_HEIGHT +
            DATA_ROW_HEIGHT * index2 +
            DATA_ROW_HEIGHT * 0.3
            }`}
          class="triangle"
        />
      </g>
    );
  };

  const renderRow = (item, index) => {
    return (
      <g>
        {renderContainer(index)}
        {renderGrid(index)}
        {renderProgressBar(index, item)}
        {renderBaseLines(index, item)}
      </g>
    );
  };

  const filterHolidaysByLocation = (holidays, selectedProject) => {
    const selectedProjectUid = selectedProject?.data?.id;

    return holidays.filter(holiday => {
      const holidayLocations = holiday.attributes.locations.data;

      // If the holiday has no specific location (empty locations array), we include it
      if (holidayLocations.length === 0) {
        return true;
      }

      // If holiday has specific locations, we check if the UID matches the selected project's location UID
      return holidayLocations.some(location => location.id === selectedProjectUid);
    });
  };

  useEffect(() => {
    initDates();
    fetchData(
      {
        slug: "holidays",
        query: {
          populate: "*",
        },
        onSuccess: (data) => {
          setholidays(data);
          const holidayDates = new Set();
          const filteredHolidays = filterHolidaysByLocation(data, tasks?.[0]?.project);

          filteredHolidays.forEach((item) => {
            const dateString = item.attributes.date;
            holidayDates.add(moment(dateString).format("DD-MM-YYYY"));
          });
          setHolidayDatesSet(holidayDates);
        },
      },
      []
    );
  }, []);


  useEffect(() => {
    let task = null;

    if (activeTaskId !== -1) {
      task = tasks?.find((item) => item?.id === activeTaskId);
    }

    // const { x } = getBarProps(
    //   moment(
    //     miscData?.task_progresses?.find((o) => o?.id === task?.id)
    //       ?.actual_start_date,
    //     "DD-MM-YYYY"
    //   ) || task?.start_date,
    //   moment(
    //     miscData?.task_progresses?.find((o) => o?.id === task?.id)
    //       ?.actual_end_date,
    //     "DD-MM-YYYY"
    //   ) || task?.end_date
    // );

    const { x } = getBarProps(task?.start_date, task?.end_date);
    containerRef.current.scrollLeft = x;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTaskId, tasks, latestTime]);

  console.log('right Panel', holidays, tasks)

  return (
    <div className={styles.rightPanel} ref={containerRef}>
      <svg
        width={dates?.length * DATE_HEADER_CELL_WIDTH}
        height="100%"
        xmlns="http://www.w3.org/2000/svg"
      >
        {renderHeader()}

        {tasks
          ?.filter((item) => {
            return (
              item?.milestone_id === "" ||
              expandedIds?.[item?.milestone_id] === true
            );
          })
          ?.map((item, index) => {
            if (item?.milestone_id === "") {
              return renderRow(item, index);
            } else {
              if (expandedIds?.[item?.milestone_id] === true) {
                return renderRow(item, index);
              }
            }
          })}

        {/* {renderDependencies(0, 1)} */}
      </svg>
    </div>
  );
};

export default RightPanel;
