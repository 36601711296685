import React, { useEffect, useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { makeConfig } from "./config";
import { axios } from "../../../../../App";
import qs from "qs";
import moment from "moment";
import { Helmet } from "react-helmet";
import { PageHeader, Breadcrumb, Button, Space, Table } from "antd";

const Page = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const config = makeConfig({ navigate });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const queryObject = {
          filters: {
            released_note: id,
          },
        };

        const response = await axios.get(
          `/released-form-histories?${qs.stringify(queryObject)}`
        );

        if (response?.data) {
          const data = response?.data?.data?.map((item) => ({
            id: item?.id,
            ...item?.attributes,
            createdAt: moment(item?.attributes?.createdAt)?.format(
              "DD-MM-YYYY, HH:mm:ss"
            ),
          }));

          setData(data);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <>
      <Helmet>
        <title>{config?.pageTitle}</title>
      </Helmet>

      <Breadcrumb style={styles.breadcrumbs}>
        {config?.breadcrumbs?.map((breadcrumb, index) => (
          <Breadcrumb.Item key={index}>
            <Link to={breadcrumb?.path}>{breadcrumb?.title}</Link>
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>

      <PageHeader
        title={config?.title}
        style={styles.pageHeader}
        onBack={config?.actions?.onBack}
      ></PageHeader>

      <Table
        style={{ overflow: "auto" }}
        columns={[
          {
            title: "Date Time",
            dataIndex: "createdAt",
            key: "createdAt",
          },
          {
            title: "Quantity",
            dataIndex: "quantity",
            key: "quantity",
          },
          {
            title: "Remarks",
            dataIndex: "remarks",
            key: "remarks",
            render: (text) => {
              if (text !== "") {
                return <div dangerouslySetInnerHTML={{ __html: text }}></div>;
              }

              return "N/A";
            },
          },
        ]}
        rowKey={(record) => record.key}
        dataSource={data}
        loading={loading}
      />

      <Space>
        <Button onClick={config?.actions?.onBack}>Cancel</Button>
      </Space>
    </>
  );
};

const styles = {
  pageHeader: {
    padding: 0,
    paddingBottom: 24,
  },
};

export default Page;
