export default {
  slugs: {
    singular: "petty-cash",
    plural: "petty-cashes",
  },
  entityNames: {
    singular: "Petty Cash",
    plural: "Petty Cashes",
  },
};
