export default {
  slugs: {
    singular: "job-title",
    plural: "job-titles",
  },
  entityNames: {
    singular: "Job Title",
    plural: "Job Titles",
  },
};
