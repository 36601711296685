import config from "../config";
import moment from "moment";

export default {
  id: `${config?.slugs?.plural}-list`,
  pageTitle: config?.entityNames?.plural,
  breadcrumbs: [
    {
      path: "/quick-teams/dashboard",
      title: "Home",
    },
    {
      path: "",
      title: config?.entityNames?.plural,
    },
  ],
  columns: [
    {
      title: "Employee",
      dataIndex: "employee",
      selectable: true,
      filterable: true,
      references: {
        fieldName: (record) => `${record?.first_name} ${record?.last_name}`,
      },
    },
    {
      title: "From Date",
      dataIndex: "from_date",
      selectable: true,
      sorter: (a, b) => moment(a.from_date).unix() - moment(b.from_date).unix(),
      render: (text) => <span>{moment(text).format("DD-MM-YYYY")}</span>,
      width: 250,
    },
    {
      title: "To Date",
      dataIndex: "to_date",
      selectable: true,
      sorter: (a, b) => moment(a.to_date).unix() - moment(b.to_date).unix(),
      render: (text) => <span>{moment(text).format("DD-MM-YYYY")}</span>,
      width: 250,
    },
    {
      title: "Shift",
      dataIndex: "shift",
      selectable: true,
      filterable: true,
      references: {
        fieldName: "name",
      },
    },
    {
      title: "Location",
      dataIndex: "location",
      selectable: true,
      filterable: true,
      references: {
        fieldName: (record) =>`${record?.name}`,
      },
    },
  ],
  ...config,
};
