export const makeConfig = () => ({
  id: `quick-stock-dashboard`,
  title: `Dashboard`,
  pageTitle: `Quick Stock Dashboard`,
  breadcrumbs: [
    {
      path: "/quick-stocks/dashboard",
      title: "Home",
    },
    {
      path: "",
      title: "Dashboard",
    },
  ],
});
