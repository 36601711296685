import React, { useState, useEffect } from "react";
import { DataTable } from "../../../components";
import { useNavigate, useParams } from "react-router-dom";
import { Space, Button, Popconfirm, Tooltip, notification, Input } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import qs from "qs";
import config from "./config";
import { axios } from "../../../../../App";
import _ from "lodash";

const VendorsBoqView = () => {
  const navigate = useNavigate();
  const { id } = useParams()
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [boq_name, setboq_name] = useState('')
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    pageSize: 10,
  });
  const [total, setTotal] = useState(0);
  const [searchValues, setSearchValues] = useState({});

  const fetchData = async (params = null) => {
    try {
      setLoading(true);

      const queryObject = {
        populate: "*",
        sort: ["name:asc"],
      };

      if (!_.isEmpty(params)) {
        queryObject["filters"] = params;
      }

      const response = await axios.get(
        `vendor-boqs/${id}?${qs.stringify(queryObject)}`
      );

      if (response?.data) {
        const boqItems = response?.data?.data?.attributes?.boq_item?.map((item) => ({
          ...item,
          item_name: item?.full_data?.material_item?.label,
          unit: item?.full_data?.material_unit?.label

        }));
        setData(boqItems);
        setFilteredData(boqItems); // Initialize filteredData with all items
        setboq_name(response?.data?.data?.attributes?.name);
      }

    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const refreshData = () => {
    fetchData();
  };

  const getColumnFilterProps = ({ dataIndex, references = {} }) => {
    if (typeof references?.fieldName === "function") {
      const transformer = references?.fieldName;

      return {
        render: (data) => transformer(data?.data?.attributes) || "N/A",
      };
    } else {
      return {
        render: (data) =>
          data?.data?.attributes?.[references?.fieldName] || "N/A",
      };
    }
  };

  const getColumnProps = (column) => {
    if (column?.references) {
      return getColumnFilterProps(column);
    }

    return column;
  };


  const onSearch = () => {
    if (!searchValues.keyword) {
      setFilteredData(data); // If no search keyword, show all data
      return;
    }

    const filtered = data.filter((item) => {
      const keyword = searchValues.keyword.toLowerCase();

      // Check if keyword is present in any of the fields (adjust fields as necessary)
      return (
        item.full_data?.material_item?.label?.toLowerCase().includes(keyword) ||
        item.full_data?.material_group?.attributes?.name?.toLowerCase().includes(keyword) ||
        item.full_data?.material_unit?.attributes?.name?.toLowerCase().includes(keyword) ||
        item.quantity?.toString().includes(keyword) ||
        item.estimated_budget?.toString().includes(keyword) ||
        item.estimated_labour_cost?.toString().includes(keyword) ||
        item.estimated_material_cost?.toString().includes(keyword)
      );
    });

    setFilteredData(filtered);
  };

  const onReset = () => {
    setSearchValues({});
    setFilteredData(data);
  };

  const getResultsMessage = () => {
    if (!_.isEmpty(searchValues)) {
      return total > 0 ? (
        <span>
          <strong>{total}</strong> matched results
        </span>
      ) : (
        <span>No matched results</span>
      );
    }

    return (
      <span>
        <strong>{total}</strong> total records
      </span>
    );
  };

  useEffect(() => {
    fetchData(filters);
  }, []);

  console.log('Boq View dataaa', data)

  return (
    <DataTable
      data={filteredData}
      config={{
        ...config,
        pageTitle: boq_name,
        columns: config?.columns
          ?.map((column) => ({
            ...column,
            ...getColumnProps(column),
          }))
      }}
      loading={loading}
      pagination={false}
      actions={{
        onAdd: () => navigate(`/quick-stocks/${config?.slugs?.plural}/add`),
        onRefresh: refreshData,
      }}
      Toolbar={
        <div className="toolbar">
          <Input
            value={searchValues?.keyword}
            placeholder="Search for keyword..."
            onChange={(e) =>
              setSearchValues((prev) => ({
                ...prev,
                keyword: e.target.value,
              }))
            }
          />

          <div className="toolbar-buttons">
            <p className="toolbar-results">{getResultsMessage()}</p>

            <Space>
              <Button onClick={onReset}>Reset</Button>
              <Button type="primary" onClick={onSearch}>
                Search
              </Button>
            </Space>
          </div>
        </div>
      }
      buttons={[
        <Tooltip placement="top" title="Refresh">
          <Button
            icon={<ReloadOutlined />}
            onClick={refreshData}
            loading={loading}
          />
        </Tooltip>,
      ]}
    />
  );
};

export default VendorsBoqView;
