import React from "react";
import { Route } from "react-router-dom";
import Dashboard from "../pages/Dashboard";

export const register = (props) => {
  const basePath = "dashboard";

  return (
    <>
      <Route path={basePath} element={<Dashboard />} exact={true} />
    </>
  );
};
