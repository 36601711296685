import React, { useState, useEffect } from "react";
import { DataTable } from "../../../components";
import { useLocation, useNavigate } from "react-router-dom";
import { Space, Button, Popconfirm, Tooltip, notification, Input } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import qs from "qs";
import config from "./config";
import { axios } from "../../../../../App";
import _ from "lodash";
import { BsChatSquareText } from "react-icons/bs";
import { useSelector } from "react-redux";
import { MAX_PAGINATION_LIMIT } from "../../../../../constants";

const Page = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    pageSize: 10,
  });
  const [total, setTotal] = useState(0);
  const [searchValues, setSearchValues] = useState({});
  const [searched, setSearched] = useState(false);
  const { user } = useSelector((state) => state?.auth);

  const fetchData = async (params = null) => {
    try {
      setLoading(true);

      const queryObject = {
        populate: "*",
        sort: ["start_date:desc"],
        pagination: {
          limit: MAX_PAGINATION_LIMIT,
        },
      };

      if (!_.isEmpty(params)) {
        queryObject["filters"] = params;
      }

      const response = await axios.get(
        `${config?.slugs?.plural}?${qs.stringify(queryObject)}`
      );

      if (response?.data) {
        setData(
          response?.data?.data?.map((item) => ({
            id: item?.id,
            ...item?.attributes,
          }))
        );

        setTotal(response?.data?.meta?.pagination?.total);

        setPagination({
          ...response?.data?.meta?.pagination,
          pageSize: 10,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const refreshData = () => {
    onSearch();
  };

  const deleteItem = async (id) => {
    try {
      const response = await axios.delete(`${config?.slugs?.plural}/${id}`);

      if (response?.data) {
        notification["success"]({
          message: `Remove ${config?.entityNames?.singular}`,
          description: `Remove ${config?.entityNames?.singular} successfully!`,
        });

        refreshData();
      } else {
        throw new Error();
      }
    } catch (error) {
      notification["error"]({
        message: `Remove ${config?.entityNames?.singular}`,
        description: `Remove ${config?.entityNames?.singular} failed!`,
      });
    }
  };

  const getColumnFilterProps = ({ dataIndex, references = {} }) => {
    if (typeof references?.fieldName === "function") {
      const transformer = references?.fieldName;

      return {
        render: (data) => transformer(data?.data?.attributes) || "N/A",
      };
    } else {
      return {
        render: (data) =>
          data?.data?.attributes?.[references?.fieldName] || "N/A",
      };
    }
  };

  const getColumnProps = (column) => {
    if (column?.references) {
      return getColumnFilterProps(column);
    }

    return column;
  };

  const onSearch = (keyword) => {
    setSearched(true);

    fetchData({
      $and: [
        {
          $or: [
            {
              name: {
                $containsi: searchValues?.keyword?.toLowerCase() ?? keyword.toLowerCase(),
              },
            },
            {
              description: {
                $containsi: searchValues?.keyword?.toLowerCase() ?? keyword.toLowerCase(),
              },
            },
            {
              project: {
                name: {
                  $containsi: searchValues?.keyword?.toLowerCase(),
                },
              },
            },
          ],
        },
        getFiltersForRoles(),
      ],
    });
  };

  const onReset = () => {
    setSearchValues({});
    fetchData(getFiltersForRoles());
    setSearched(false);
  };

  const getResultsMessage = () => {
    if (!_.isEmpty(searchValues) && !loading && searched) {
      return total > 0 ? (
        <span>
          <strong>{total}</strong> matched results
        </span>
      ) : (
        <span>No matched results</span>
      );
    }

    return (
      <span>
        <strong>{total}</strong> total records
      </span>
    );
  };

  const getFiltersForRoles = () => {
    // const userType = user?.user_type?.toLowerCase()?.replace(/\s/i, "_");

    // if (user?.is_admin) {
    //   return {};
    // }

    // return {
    //   project: {
    //     [Pluralize(userType)]: {
    //       uid: user?.uid,
    //     },
    //   },
    // };

    if (user?.is_admin) {
      return {};
    }

    return {
      $or: [
        {
          assignees: {
            uid: user?.uid,
          },
        },
        {
          creator_uid: user?.uid,
        },
      ],
    };
  };

  // useEffect(() => {
  //   fetchData(getFiltersForRoles());
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    if (location?.state?.data?.name) {
      setSearchValues({ keyword: location.state.data.name });

      onSearch(location.state.data.name);

    } else {
      fetchData(getFiltersForRoles());

    }
  }, [location?.state?.data]);

  console.log('task list', data)

  return (
    <DataTable
      data={data}
      config={{
        ...config,
        columns: config?.columns
          ?.map((column) => ({
            ...column,
            ...getColumnProps(column),
          }))
          ?.concat({
            title: "Actions",
            width: 100,
            selectable: false,
            render: ({ id, start_date, status }) => {
              const actualStartDate = new Date(start_date);
              const currentDate = new Date();
              const currentDateOnly = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());

              const showEditButton = actualStartDate >= currentDateOnly;

              const isTaskCompleted = status === "Completed";

              return (
                <Space>
                  {showEditButton && !isTaskCompleted && (
                    <Tooltip
                      placement="bottom"
                      title={`Edit ${config?.entityNames?.singular}`}
                    >
                      <Button
                        icon={<EditOutlined />}
                        onClick={() =>
                          navigate(
                            `/quick-projects/${config?.slugs?.plural}/edit/${id}`
                          )
                        }
                      />
                    </Tooltip>
                  )}

                  <Tooltip
                    placement="bottom"
                    title={`View ${config?.entityNames?.singular} Discussion`}
                  >
                    <Button
                      style={{ paddingLeft: "7px" }}
                      icon={<BsChatSquareText />}
                      onClick={() =>
                        navigate(
                          `/quick-talks/project-chats?conversation=${id}`
                        )
                      }
                    />
                  </Tooltip>

                  <Popconfirm
                    title={`Are you sure to remove this ${config?.entityNames?.singular}?`}
                    okText="Yes"
                    cancelText="No"
                    onConfirm={() => deleteItem(id)}
                  >
                    <Tooltip
                      placement="bottom"
                      title={`Remove ${config?.entityNames?.singular}`}
                    >
                      <Button type="danger" icon={<DeleteOutlined />} />
                    </Tooltip>
                  </Popconfirm>
                </Space>
              );
            },
          }),
      }}
      loading={loading}
      pagination={pagination}
      actions={{
        onAdd: () => navigate(`/quick-projects/${config?.slugs?.plural}/add`),
        onRefresh: refreshData,
      }}
      Toolbar={
        <div className="toolbar">
          <Input
            value={searchValues?.keyword}
            placeholder="Search for keyword..."
            onChange={(e) =>
              setSearchValues((prev) => ({
                ...prev,
                keyword: e.target.value,
              }))
            }
          />

          <div className="toolbar-buttons">
            <p className="toolbar-results">{getResultsMessage()}</p>

            <Space>
              <Button onClick={onReset}>Reset</Button>
              <Button type="primary" onClick={onSearch}>
                Search
              </Button>
            </Space>
          </div>
        </div>
      }
    />
  );
};

export default Page;
