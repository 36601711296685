import React from "react";
import { Route } from "react-router-dom";
import {
  VendorPurchaseOrderList,
  VendorPurchaseOrderReport,
  VendorPurchaseOrderView,
} from "../pages/VendorPurchaseOrders";

export const register = (props) => {
  const basePath = "vendor-purchase-orders";

  return (
    <>
      <Route
        path={basePath}
        element={<VendorPurchaseOrderList />}
        exact={true}
      />
      <Route
        path={`${basePath}/view/:id`}
        element={<VendorPurchaseOrderView />}
      />
      <Route
        path={`${basePath}-report`}
        element={<VendorPurchaseOrderReport />}
      />
    </>
  );
};
