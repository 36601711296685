export default {
  slugs: {
    singular: "work-order-group",
    plural: "work-order-groups",
  },
  entityNames: {
    singular: "Work Order Group",
    plural: "Work Order Groups",
  },
};
