export default {
  slugs: {
    singular: "task-work",
    plural: "task-works",
  },
  entityNames: {
    singular: "Task Work",
    plural: "Task Works",
  },
};
