export default {
  slugs: {
    singular: "vendor-term",
    plural: "vendor-terms",
  },
  entityNames: {
    singular: "Vendor Term & Condition",
    plural: "Vendor Terms & Conditions",
  },
};
