import { axios } from "../../../../../App";
import { v4 } from "uuid";
import config from "../config";

export const makeConfig = ({ navigate, auth }) => ({
  id: `${config?.slugs?.plural}-add`,
  title: `Add New ${config?.entityNames?.singular}`,
  pageTitle: `Add New ${config?.entityNames?.singular}`,
  breadcrumbs: [
    {
      path: "/quick-teams/dashboard",
      title: "Home",
    },
    {
      path: `/quick-teams/${config?.slugs?.plural}`,
      title: config?.entityNames?.plural,
    },
    {
      path: "",
      title: `Add New ${config?.entityNames?.singular}`,
    },
  ],
  components: [
    {
      span: 12,
      component: "date",
      label: "From Date",
      name: "from_date",
      required: true,
      placeholder: "Select from date",
      rules: [
        {
          required: true,
          message: "From date is required!",
        },
      ],
    },
    {
      span: 12,
      component: "date",
      label: "To Date",
      name: "to_date",
      required: true,
      placeholder: "Select to date",
      rules: [
        {
          required: true,
          message: "To date is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Employee",
      name: "employee",
      required: true,
      placeholder: "Select employee",
      rules: [
        {
          required: true,
          message: "Employee is required!",
        },
      ],
    },
    // {
    //   span: 12,
    //   component: "select",
    //   label: "Approver",
    //   name: "approved_by",
    //   required: true,
    //   placeholder: "Select approver",
    //   rules: [
    //     {
    //       required: true,
    //       message: "Approver is required!",
    //     },
    //   ],
    // },
    {
      span: 12,
      component: "select",
      label: "Representative",
      name: "representative",
      required: true,
      placeholder: "Select representative",
      rules: [
        {
          required: true,
          message: "Representative is required!",
        },
      ],
    },
    // {
    //   span: 12,
    //   component: "select",
    //   label: "Status",
    //   name: "status",
    //   required: true,
    //   placeholder: "Select status",
    //   rules: [
    //     {
    //       required: true,
    //       message: "Status is required!",
    //     },
    //   ],
    // },
    {
      span: 12,
      component: "select",
      label: "Leave Type",
      name: "leave_type",
      required: true,
      placeholder: "Select leave type",
      rules: [
        {
          required: true,
          message: "Leave type is required!",
        },
      ],
    },
    {
      span: 12,
      component: "uploader",
      label: "Attachment",
      name: "attachment",
      required: true,
      placeholder: "Upload attachment",
      rules: [
        {
          required: true,
          message: "Attachment is required!",
        },
      ],
    },
    {
      span: 24,
      component: "editor",
      label: "Reason",
      name: "reason",
      placeholder: "Enter reason",
      rows: 5,
    },
    {
      span: 24,
      component: "input",
      type: "hidden",
      name: "attachment",
    },
  ],
  actions: {
    onBack: () => navigate(`/quick-teams/${config?.slugs?.plural}`),
    onFinish: async (values, data) => {
      try {
        const response = await axios.post(`/${config?.slugs?.plural}`, {
          data: {
            ...values,
            uid: v4(),
            // creator: auth?.user?.id,
          },
        });

        if (response?.data) {
          return {
            success: true,
            message: `Add new ${config?.entityNames?.singular?.toLowerCase()} successfully!`,
          };
        }

        throw new Error();
      } catch (error) {
        return {
          success: false,
          message: `Add new ${config?.entityNames?.singular?.toLowerCase()} failed!`,
        };
      }
    },
  },
});
