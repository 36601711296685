const config = {
  slugs: {
    singular: "sub-contractor-term",
    plural: "sub-contractor-terms",
  },
  entityNames: {
    singular: "Sub Contractor Term & Condition",
    plural: "Sub Contractor Terms & Conditions",
  },
};

export default {
  id: `${config?.slugs?.plural}-list`,
  pageTitle: config?.entityNames?.plural,
  breadcrumbs: [
    {
      path: "/quick-stocks/dashboard",
      title: "Home",
    },
    {
      path: "",
      title: config?.entityNames?.plural,
    },
  ],
  columns: [
    {
      title: "Heading",
      dataIndex: "heading",
      selectable: true,
      sorter: (a, b) => a?.heading?.localeCompare(b?.heading),
    },
    // {
    //   title: "Sub Contractor",
    //   dataIndex: "sub_contractor",
    //   selectable: true,
    //   references: {
    //     fieldName: "name",
    //   },
    // },
    {
      title: "Work Group",
      dataIndex: "work_group",
      selectable: true,
      references: {
        fieldName: "name",
      },
    },
    {
      title: "Work Item",
      dataIndex: "work_item",
      selectable: true,
      references: {
        fieldName: "name",
      },
    },
    // {
    //   title: "Work Unit",
    //   dataIndex: "work_unit",
    //   selectable: true,
    //   references: {
    //     fieldName: "name",
    //   },
    // },
  ],
  ...config,
};
