export default {
  slugs: {
    singular: "attendance-mode",
    plural: "attendance-modes",
  },
  entityNames: {
    singular: "Attendance Mode",
    plural: "Attendance Modes",
  },
};
