import React from "react";
import { Route } from "react-router-dom";
import {
  MaterialGroupList,
  MaterialGroupAdd,
  MaterialGroupEdit,
} from "../pages/MaterialGroups";

export const register = (props) => {
  const basePath = "material-groups";

  return (
    <>
      <Route path={basePath} element={<MaterialGroupList />} exact={true} />
      <Route path={`${basePath}/add`} element={<MaterialGroupAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<MaterialGroupEdit />} />
    </>
  );
};
