import config from "../config";
import moment from "moment";

const commify = (x) => {
  return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export default {
  id: `${config?.slugs?.plural}-list`,
  pageTitle: `${config?.entityNames?.plural} Report`,
  code: "AGENCY_PAYMENT_SCHEDULES",
  breadcrumbs: [
    {
      path: "/quick-stocks/dashboard",
      title: "Home",
    },
    {
      path: `/quick-stocks/reports`,
      title: "Reports",
    },
    {
      path: "",
      title: `${config?.entityNames?.plural} Report`,
    },
  ],
  columns: [
    {
      title: "Heading",
      dataIndex: "heading",
      selectable: true,
      sorter: (a, b) => a?.heading?.localeCompare(b?.heading),
      width: 300,
    },
    {
      title: "Agency",
      dataIndex: "agency",
      selectable: true,
      references: {
        fieldName: "name",
      },
    },
    {
      title: "Scheduled Date",
      dataIndex: "scheduled_date",
      selectable: true,
      sorter: (a, b) =>
        moment(a.scheduled_date).unix() - moment(b.scheduled_date).unix(),
      render: (text) => <span>{moment(text).format("DD-MM-YYYY")}</span>,
    },
    // {
    //   title: "Vendor",
    //   dataIndex: "vendor",
    //   selectable: true,
    //   references: {
    //     fieldName: "name",
    //   },
    // },
    {
      title: "Work Group",
      dataIndex: "work_group",
      selectable: true,
      references: {
        fieldName: "name",
      },
    },
    // {
    //   title: "Material Item",
    //   dataIndex: "material_item",
    //   selectable: true,
    //   references: {
    //     fieldName: "name",
    //   },
    // },
    // {
    //   title: "Material Unit",
    //   dataIndex: "material_unit",
    //   selectable: true,
    //   references: {
    //     fieldName: "name",
    //   },
    // },
    {
      title: "Amount",
      dataIndex: "amount",
      selectable: true,
      render: (text) => commify(text || 0),
      sorter: (a, b) => a?.amount - b?.amount,
    },
  ],
  ...config,
};
