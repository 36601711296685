import React, { useState, useEffect } from "react";
import { DataTable } from "../../../components";
import { useNavigate, Link } from "react-router-dom";
import {
  Space,
  Button,
  Popconfirm,
  Tooltip,
  notification,
  Card,
  Progress,
  Tag,
  Breadcrumb,
  PageHeader,
  Empty,
  Spin,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  ReloadOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import qs from "qs";
import config from "./config";
import { axios } from "../../../../../App";
import _ from "lodash";
import { BsChatSquareText, BsBarChartSteps } from "react-icons/bs";
import { useSelector } from "react-redux";
import Pluralize from "pluralize";
import { SimpleGrid } from "@chakra-ui/react";

const Page = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    pageSize: 10,
  });
  const [total, setTotal] = useState(0);
  const [searchValues, setSearchValues] = useState({});
  const [searched, setSearched] = useState(false);
  const [hasData, setHasData] = useState(false);
  const { user } = useSelector((state) => state?.auth);

  const fetchData = async (params = null) => {
    try {
      setLoading(true);

      const queryObject = {
        populate: "*",
        sort: ["name:asc"],
      };

      if (!_.isEmpty(params)) {
        queryObject["filters"] = params;
      }

      const response = await axios.get(
        `${config?.slugs?.plural}?${qs.stringify(queryObject)}`
      );

      if (response?.data) {
        setData(
          response?.data?.data?.map((item) => ({
            id: item?.id,
            ...item?.attributes,
          }))
        );

        setTotal(response?.data?.meta?.pagination?.total);

        setPagination({
          ...response?.data?.meta?.pagination,
          pageSize: 10,
        });

        setHasData(true);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const refreshData = () => {
    onSearch();
  };

  const deleteItem = async (id) => {
    try {
      const response = await axios.delete(`${config?.slugs?.plural}/${id}`);

      if (response?.data) {
        notification["success"]({
          message: `Remove ${config?.entityNames?.singular}`,
          description: `Remove ${config?.entityNames?.singular} successfully!`,
        });

        refreshData();
      } else {
        throw new Error();
      }
    } catch (error) {
      notification["error"]({
        message: `Remove ${config?.entityNames?.singular}`,
        description: `Remove ${config?.entityNames?.singular} failed!`,
      });
    }
  };

  const getColumnFilterProps = ({ dataIndex, references = {} }) => {
    if (typeof references?.fieldName === "function") {
      const transformer = references?.fieldName;

      return {
        render: (data) => transformer(data?.data?.attributes) || "N/A",
      };
    } else {
      return {
        render: (data) =>
          data?.data?.attributes?.[references?.fieldName] || "N/A",
      };
    }
  };

  const getColumnProps = (column) => {
    if (column?.references) {
      return getColumnFilterProps(column);
    }

    return column;
  };

  const onSearch = () => {
    setSearched(true);

    fetchData({
      $and: [
        {
          $or: [
            {
              name: {
                $containsi: searchValues?.keyword?.toLowerCase(),
              },
            },
            {
              pin_code: {
                $containsi: searchValues?.keyword?.toLowerCase(),
              },
            },
            {
              rera_no: {
                $containsi: searchValues?.keyword?.toLowerCase(),
              },
            },
            {
              address_line_1: {
                $containsi: searchValues?.keyword?.toLowerCase(),
              },
            },
            {
              address_line_2: {
                $containsi: searchValues?.keyword?.toLowerCase(),
              },
            },
            {
              description: {
                $containsi: searchValues?.keyword?.toLowerCase(),
              },
            },
          ],
        },
        getFiltersForRoles(),
      ],
    });
  };

  const onReset = () => {
    setSearchValues({});
    fetchData(getFiltersForRoles());
    setSearched(false);
  };

  const getResultsMessage = () => {
    if (!_.isEmpty(searchValues) && !loading && searched) {
      return total > 0 ? (
        <span>
          <strong>{total}</strong> matched results
        </span>
      ) : (
        <span>No matched results</span>
      );
    }

    return (
      <span>
        <strong>{total}</strong> total records
      </span>
    );
  };

  const getFiltersForRoles = () => {
    const userType = user?.user_type?.toLowerCase()?.replace(/\s/i, "_");

    if (user?.is_admin) {
      return {};
    }

    if (userType === "employee") {
      return {
        $or: [
          {
            project_managers: {
              uid: user?.uid,
            },
          },
          {
            members: {
              uid: user?.uid,
            },
          },
        ],
      };
    }

    // TODO: Also okay if requester is also creator

    return {
      [Pluralize(userType)]: {
        uid: user?.uid,
      },
    };
  };

  useEffect(() => {
    fetchData(getFiltersForRoles());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={styles.container}>
      <Breadcrumb style={styles.breadcrumbs}>
        {config?.breadcrumbs?.map((breadcrumb, index) => (
          <Breadcrumb.Item key={index}>
            <Link to={breadcrumb?.path}>{breadcrumb?.title}</Link>
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>

      <PageHeader
        title={config?.pageTitle}
        extra={[
          <Space>
            <Tooltip placement="top" title="Add new item">
              <Button
                icon={<PlusOutlined />}
                onClick={() =>
                  navigate(`/quick-projects/${config?.slugs?.plural}/add`)
                }
              />
            </Tooltip>

            <Tooltip placement="top" title="Refresh">
              <Button
                icon={<ReloadOutlined />}
                onClick={refreshData}
                loading={loading}
              />
            </Tooltip>
          </Space>,
        ]}
        style={styles.pageHeader}
      ></PageHeader>

      {loading && (
        <div style={styles.loaderContainer}>
          <Spin />
        </div>
      )}

      {!loading && !hasData && <Empty />}

      {!loading && hasData && (
        <SimpleGrid columns={[1, 1, 3, 3]} spacing={3} py={3}>
          {data?.map(({ id, name, status, progress }) => (
            <Card
              key={id}
              title={name}
              style={styles.project}
              headStyle={{ display: "none" }}
            >
              <h3>{name}</h3>
              <Tag>{status}</Tag>

              <Progress percent={progress} style={styles.progress} />

              <div style={styles.buttons}>
                <Space>
                  <Tooltip
                    placement="bottom"
                    title={`Edit ${config?.entityNames?.singular}`}
                  >
                    <Button
                      icon={<EditOutlined />}
                      onClick={() =>
                        navigate(
                          `/quick-projects/${config?.slugs?.plural}/edit/${id}`
                        )
                      }
                    />
                  </Tooltip>

                  <Tooltip
                    placement="bottom"
                    title={`View ${config?.entityNames?.singular} Gantt Chart`}
                  >
                    <Button
                      icon={<BsBarChartSteps />}
                      onClick={() =>
                        navigate(`/quick-projects/gantt-chart?projectId=${id}`)
                      }
                    />
                  </Tooltip>

                  <Tooltip
                    placement="bottom"
                    title={`View ${config?.entityNames?.singular} Discussion`}
                  >
                    <Button
                      icon={<BsChatSquareText />}
                      onClick={() =>
                        navigate(
                          `/quick-talks/project-chats?conversation=${id}`
                        )
                      }
                    />
                  </Tooltip>

                  <Popconfirm
                    title={`Are you sure to remove this ${config?.entityNames?.singular}?`}
                    okText="Yes"
                    cancelText="No"
                    onConfirm={() => deleteItem(id)}
                  >
                    <Tooltip
                      placement="bottom"
                      title={`Remove ${config?.entityNames?.singular}`}
                    >
                      <Button type="danger" icon={<DeleteOutlined />} />
                    </Tooltip>
                  </Popconfirm>
                </Space>
              </div>
            </Card>
          ))}
        </SimpleGrid>
      )}
    </div>
  );
};

const styles = {
  container: {},
  main: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gridGap: 24,
    position: "fixed",
  },
  progress: {
    marginTop: 12,
    marginBottom: 0,
  },
  buttons: {
    marginTop: 12,
  },
  pageHeader: {
    padding: 0,
    paddingBottom: 12,
  },
  loaderContainer: {
    textAlign: "center",
    padding: 24,
  },
};

export default Page;
