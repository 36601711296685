export default {
  slugs: {
    singular: "roster",
    plural: "rosters",
  },
  entityNames: {
    singular: "Roster",
    plural: "Rosters",
  },
};
