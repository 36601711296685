export default {
  slugs: {
    singular: "leave-application",
    plural: "leave-applications",
  },
  entityNames: {
    singular: "Leave Application",
    plural: "Leave Applications",
  },
};
