/* eslint-disable default-case */
import { DatePicker, Input, InputNumber, Select, Form, TimePicker } from "antd";
import ReactQuill from "react-quill";
import moment from "moment";
import Uploader from "../Uploader";
import RepeatableComponent from "../RepeatableComponent";

const { Option } = Select;

const renderComponent = (props, value, form) => {
  switch (props?.component) {
    case "input": {
      const { required, ...fieldProps } = props;

      return (
        <Form.Item
          label={props?.label}
          name={props?.name}
          rules={props?.rules}
          initialValue=""
          style={{ display: props?.type === "hidden" ? "none" : "block" }}
        >
          <Input {...fieldProps} autoComplete={`new-${props?.name}`} />
        </Form.Item>
      );
    }

    case "textarea": {
      const { required, ...fieldProps } = props;

      return (
        <Form.Item
          label={props?.label}
          name={props?.name}
          rules={props?.rules}
          initialValue=""
        >
          <Input.TextArea {...fieldProps} autoComplete={`new-${props?.name}`} />
        </Form.Item>
      );
    }

    case "date": {
      const { required, ...fieldProps } = props;

      return (
        <Form.Item
          label={props?.label}
          name={props?.name}
          rules={props?.rules}
          initialValue=""
        >
          <DatePicker
            {...fieldProps}
            style={{ width: "100%" }}
            autoComplete={`new-${props?.name}`}
          />
        </Form.Item>
      );
    }

    case "datetime": {
      const { required, ...fieldProps } = props;

      return (
        <Form.Item
          label={props?.label}
          name={props?.name}
          rules={props?.rules}
          initialValue=""
        >
          <DatePicker
            {...fieldProps}
            showTime={{ defaultValue: moment("00:00:00", "HH:mm:ss") }}
            style={{ width: "100%" }}
            autoComplete={`new-${props?.name}`}
          />
        </Form.Item>
      );
    }

    case "time": {
      const { required, ...fieldProps } = props;

      return (
        <Form.Item
          label={props?.label}
          name={props?.name}
          rules={props?.rules}
          initialValue=""
        >
          <TimePicker
            {...fieldProps}
            style={{ width: "100%" }}
            autoComplete={`new-${props?.name}`}
          />
        </Form.Item>
      );
    }

    case "select": {
      const {
        required,
        placeholder,
        mode,
        referenceLink = "",
        ...fieldProps
      } = props;

      return (
        <Form.Item
          label={
            <div style={styles.label}>
              <span>{props?.label}</span>

              {referenceLink !== "" && (
                <span
                  style={{
                    textDecoration: "underline",
                    color: "#1890ff",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    window.open(referenceLink?.replace(":id", value), "_blank")
                  }
                >
                  Details
                </span>
              )}
            </div>
          }
          name={props?.name}
          rules={props?.rules}
        >
          <Select
            placeholder={placeholder}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.children).toLowerCase().includes(input.toLowerCase())
            }
            mode={mode}
            disabled={props?.disabled}
            onChange={() => props?.onFieldChange(form)}
          >
            {props?.values?.[fieldProps?.name]?.map((option) => (
              <Option value={option?.value}>{option?.label}</Option>
            ))}
          </Select>
        </Form.Item>
      );
    }

    case "gap":
      return <div></div>;

    case "uploader":
      return <Uploader {...props} />;

    case "editor":
      return (
        <Form.Item
          label={props?.label}
          name={props?.name}
          rules={props?.rules}
          initialValue=""
        >
          {props?.disabled ? (
            <div
              dangerouslySetInnerHTML={{
                __html: value || "N/A",
              }}
              className="disabled-textarea"
            ></div>
          ) : (
            <ReactQuill theme="snow" preserveWhitespace={true} {...props} />
          )}
        </Form.Item>
      );

    case "number":
      const { required, ...fieldProps } = props;

      return (
        <Form.Item
          label={props?.label}
          name={props?.name}
          rules={props?.rules}
          initialValue={0}
          onChange={() => props?.onFieldChange(form)}
        >
          <InputNumber style={{ width: "100%", height: 38 }} {...fieldProps} />
        </Form.Item>
      );

    case "repeatable": {
      const { required, ...fieldProps } = props;

      return (
        <Form.Item
          name={props?.name}
          rules={props?.rules}
          initialValue={[]}
          valuePropName="defaultValue"
        >
          <RepeatableComponent
            {...fieldProps}
            form={form}
            required={required}
          />
        </Form.Item>
      );
    }
  }
};

const FormElement = (props) => {
  const value = Form.useWatch(props?.name, props?.form);

  return renderComponent(props, value, props?.form);
};

const styles = {
  label: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
};

export default FormElement;
