import React from "react";
import { Route } from "react-router-dom";
import {
  VendorDebitNoteList,
  VendorDebitNoteAdd,
  VendorDebitNoteCreatorEdit,
  VendorDebitNoteApproverEdit,
  VendorDebitNoteReport,
  VendorDebitNoteView,
} from "../pages/VendorDebitNotes";

export const register = (props) => {
  const basePath = "vendor-debit-notes";

  return (
    <>
      <Route path={basePath} element={<VendorDebitNoteList />} exact={true} />
      <Route path={`${basePath}/add`} element={<VendorDebitNoteAdd />} />
      <Route
        path={`${basePath}/creator/edit/:id`}
        element={<VendorDebitNoteCreatorEdit />}
      />
      <Route
        path={`${basePath}/approver/edit/:id`}
        element={<VendorDebitNoteApproverEdit />}
      />
      <Route path={`${basePath}/view/:id`} element={<VendorDebitNoteView />} />
      <Route path={`${basePath}-report`} element={<VendorDebitNoteReport />} />
    </>
  );
};
