export default {
  slugs: {
    singular: "location-type",
    plural: "location-types",
  },
  entityNames: {
    singular: "Location Type",
    plural: "Location Types",
  },
};
