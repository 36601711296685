import config from "../config";
import moment from "moment";

const commify = (x) => {
  return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export default {
  id: `${config?.slugs?.plural}-list`,
  pageTitle: `${config?.entityNames?.plural} Report`,
  code: "MACHINES",
  breadcrumbs: [
    {
      path: "/quick-stocks/dashboard",
      title: "Home",
    },
    {
      path: `/quick-stocks/reports`,
      title: "Reports",
    },
    {
      path: "",
      title: `${config?.entityNames?.plural} Report`,
    },
  ],
  columns: [
    {
      title: "Date",
      dataIndex: "date",
      selectable: true,
      sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
      render: (text) => <span>{moment(text).format("DD-MM-YYYY")}</span>,
    },
    {
      title: "Machine",
      dataIndex: "machine",
      selectable: true,
      references: {
        fieldName: "name",
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      selectable: true,
      render: (text) => commify(text || 0),
      sorter: (a, b) => a?.amount - b?.amount,
    },
    {
      title: "Hours",
      dataIndex: "hours",
      selectable: true,
      render: (text) => commify(text || 0),
      sorter: (a, b) => a?.hours - b?.hours,
    },
  ],
  ...config,
};
