import React from "react";
import { Column } from "@ant-design/plots";

const Chart = (props) => {
  let { data, xField, yField, seriesField } = props;

  const config = {
    data: data.reverse(),
    isStack: true,
    label: {
      position: "middle",
      layout: [
        {
          type: "interval-adjust-position",
        },
        {
          type: "interval-hide-overlap",
        },
        {
          type: "adjust-color",
        },
      ],
    },
    xField,
    yField,
    seriesField,
  };

  return <Column {...config} />;
};

export default Chart;
