import { axios } from "../../../../../App";
import config from "../config";

export const makeConfig = ({ navigate }) => ({
  id: `${config?.plural}-view`,
  title: `View ${config?.entityNames?.singular} Verification`,
  pageTitle: `View ${config?.entityNames?.singular} Verification`,
  breadcrumbs: [
    {
      path: "/quick-projects/dashboard",
      title: "Home",
    },
    {
      path: `/quick-projects/${config?.slugs?.plural}`,
      title: config?.entityNames?.plural,
    },
    {
      path: "",
      title: `View ${config?.entityNames?.singular} Verification`,
    },
  ],
  components: [
    {
      span: 12,
      component: "select",
      label: "Verified By",
      name: "verified_by",
      placeholder: "Select employee",
      required: true,
      rules: [
        {
          required: true,
          message: "Verified by is required!",
        },
      ],
    },
    {
      span: 12,
      component: "datetime",
      label: "Verified At",
      name: "verified_at",
      required: true,
      placeholder: "Select date & time",
      rules: [
        {
          required: true,
          message: "Verified at is required!",
        },
      ],
    },
    {
      span: 24,
      component: "editor",
      label: "Notes",
      name: "verified_notes",
      placeholder: "Enter notes",
      rows: 5,
    },
  ],
  actions: {
    onBack: () => navigate(`/quick-projects/${config?.slugs?.plural}`),
  },
  ...config,
});
