import config from "../config";
import { Tag } from "antd";

const commify = (x) => {
  return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export default {
  id: `${config?.slugs?.plural}-list`,
  pageTitle: `${config?.entityNames?.plural} Report`,
  code: "VENDOR_QUOTATIONS",
  breadcrumbs: [
    {
      path: "/quick-stocks/dashboard",
      title: "Home",
    },
    {
      path: `/quick-stocks/reports`,
      title: "Reports",
    },
    {
      path: "",
      title: `${config?.entityNames?.plural} Report`,
    },
  ],
  columns: [
    {
      title: "Heading",
      dataIndex: "heading",
      selectable: true,
      sorter: (a, b) => a?.heading?.localeCompare(b?.heading),
      width: 200,
    },
    {
      title: "Project",
      dataIndex: "project",
      selectable: true,
      references: {
        fieldName: "name",
      },
    },
    {
      title: "Vendor",
      dataIndex: "vendor",
      selectable: true,
      references: {
        fieldName: "name",
      },
    },
    // {
    //   title: "Material Group",
    //   dataIndex: "material_group",
    //   selectable: true,
    //   references: {
    //     fieldName: "name",
    //   },
    // },
    // {
    //   title: "Estimated Amount",
    //   dataIndex: "estimated_amount",
    //   selectable: true,
    //   render: (text) => commify(text || 0),
    //   sorter: (a, b) => a?.estimated_amount - b?.estimated_amount,
    // },
    {
      title: "Status",
      dataIndex: "status",
      selectable: true,
      render: (text) => <Tag>{text}</Tag>,
    },
    // {
    //   title: "Urgency Level",
    //   dataIndex: "urgency_level",
    //   selectable: true,
    //   width: 250,
    // },
  ],
  ...config,
};
