import React from "react";
import { Route } from "react-router-dom";
import {
  SubContractorTermList,
  SubContractorTermAdd,
  SubContractorTermEdit,
  SubContractorTermReport,
} from "../pages/SubContractorTerms";

export const register = (props) => {
  const basePath = "sub-contractor-terms";

  return (
    <>
      <Route path={basePath} element={<SubContractorTermList />} exact={true} />
      <Route path={`${basePath}/add`} element={<SubContractorTermAdd />} />
      <Route
        path={`${basePath}/edit/:id`}
        element={<SubContractorTermEdit />}
      />
      <Route
        path={`${basePath}-report`}
        element={<SubContractorTermReport />}
      />
    </>
  );
};
