import {
  MdArrowBack,
  MdPeopleAlt,
  MdPayment,
  MdLocationOn,
  MdCelebration,
  MdOutlineInventory2,
} from "react-icons/md";
import {
  FaHandshake,
  FaRegCalendarAlt,
  FaRegIdCard,
  FaRegBuilding,
  FaRegIdBadge,
} from "react-icons/fa";
import { ImOffice } from "react-icons/im";
import { BsBank2 } from "react-icons/bs";
import { IoDocuments } from "react-icons/io5";
import { RiOrganizationChart, RiUserSettingsLine } from "react-icons/ri";
import { HiOutlineBadgeCheck } from "react-icons/hi";
import { BiCategoryAlt } from "react-icons/bi";
import { TbCertificate } from "react-icons/tb";

const BASE_SLUG = "/quick-setup";

export const makeNavbar = ({ navigate, user }) => {
  const makeItem = ({ icon, id, label, scopes }) => {
    return {
      id,
      key: `${BASE_SLUG}/${id}`,
      label,
      icon,
      onClick: () => navigate(`${BASE_SLUG}/${id}`),
      scopes,
    };
  };

  let roles = [];

  user?.app_roles?.forEach((appRole) => {
    appRole?.permissions?.forEach((appPermission) => {
      if (!roles?.includes(appPermission)) {
        roles?.push(appPermission);
      }
    });
  });

  roles = roles?.join(",");

  const menuItems = [
    {
      key: "organization",
      label: "Organization",
      icon: <FaRegBuilding style={styles.icon} />,
      children: [
        makeItem({
          id: "companies",
          label: "Companies",
          icon: <ImOffice style={styles.icon} />,
        }),
        makeItem({
          id: "partners",
          label: "Partners",
          icon: <FaHandshake style={styles.icon} />,
        }),
        makeItem({
          id: "departments",
          label: "Departments",
          icon: <RiOrganizationChart style={styles.icon} />,
        }),
        makeItem({
          id: "designations",
          label: "Designations",
          icon: <FaRegIdBadge style={styles.icon} />,
        }),
        // makeItem({
        //   id: "firm-types",
        //   label: "Company Types",
        //   icon: <BiCategoryAlt style={styles.icon} />,
        // }),
        makeItem({
          id: "letterheads",
          label: "Letterheads",
          icon: <IoDocuments style={styles.icon} />,
        }),
      ],
    },
    {
      key: "employees",
      label: "Employees",
      icon: <MdPeopleAlt style={styles.icon} />,
      children: [
        makeItem({
          id: "employees",
          label: "Employees",
          icon: <MdPeopleAlt style={styles.icon} />,
        }),
        makeItem({
          id: "employee-documents",
          label: "Employee Documents",
          icon: <IoDocuments style={styles.icon} />,
        }),
        makeItem({
          id: "employee-qualifications",
          label: "Employee Qualifications",
          icon: <TbCertificate style={styles.icon} />,
        }),
        makeItem({
          id: "employee-groups",
          label: "Employee Groups",
          icon: <MdPeopleAlt style={styles.icon} />,
        }),
        // makeItem({
        //   id: "initials",
        //   label: "Initials",
        //   icon: <HiOutlineBadgeCheck style={styles.icon} />,
        // }),
        makeItem({
          id: "job-titles",
          label: "Job Titles",
          icon: <FaRegIdCard style={styles.icon} />,
        }),
      ],
    },
    makeItem({
      id: "documents",
      label: "Documents",
      icon: <IoDocuments style={styles.icon} />,
    }),
    {
      key: "calendar",
      label: "Calendar",
      icon: <FaRegCalendarAlt style={styles.icon} />,
      children: [
        makeItem({
          id: "holidays",
          label: "Holidays",
          icon: <MdCelebration style={styles.icon} />,
        }),
      ],
      scopes: [
        {
          id: "view",
          label: "View",
        },
      ],
    },
    {
      key: "locations",
      label: "Locations",
      icon: <MdLocationOn style={styles.icon} />,
      children: [
        makeItem({
          id: "locations",
          label: "Locations",
          icon: <MdLocationOn style={styles.icon} />,
        }),
        makeItem({
          id: "location-types",
          label: "Location Types",
          icon: <BiCategoryAlt style={styles.icon} />,
        }),
      ],
    },
    {
      key: "payment",
      label: "Payment",
      icon: <MdPayment style={styles.icon} />,
      children: [
        makeItem({
          id: "banks",
          label: "Banks",
          icon: <BsBank2 style={styles.icon} />,
        }),
        makeItem({
          id: "payment-modes",
          label: "Payment Modes",
          icon: <BiCategoryAlt style={styles.icon} />,
        }),
      ],
    },
    // makeItem({
    //   id: "inventory-settings",
    //   label: "Inventory",
    //   icon: <MdOutlineInventory2 style={styles.icon} />,
    // }),
    {
      key: "users",
      label: "Users",
      icon: <MdPeopleAlt style={styles.icon} />,
      children: [
        makeItem({
          id: "app-users",
          label: "Users",
          icon: <MdPeopleAlt style={styles.icon} />,
        }),
        makeItem({
          id: "app-roles",
          label: "Roles",
          icon: <RiUserSettingsLine style={styles.icon} />,
        }),
      ],
    },
    {
      key: "back-to-home",
      label: "Return Home",
      icon: <MdArrowBack style={styles.icon} />,
      onClick: () => navigate("/"),
    },
  ];

  let filteredItems = [];

  if (user) {
    menuItems?.forEach((item) => {
      const uid = item?.children ? item?.key : item?.id;

      if (item?.key === "back-to-home") {
        filteredItems?.push(item);
      }

      if (item?.children) {
        const newChildren = item?.children?.filter((item) => {
          const subItemUid = item?.id;

          return roles?.includes(`QUICK-SETUP.${subItemUid?.toUpperCase()}`);
        });

        if (newChildren?.length > 0) {
          item["children"] = newChildren;
          filteredItems?.push(item);
        }
      } else {
        if (roles?.includes(`QUICK-SETUP.${uid?.toUpperCase()}`)) {
          filteredItems?.push(item);
        }
      }
    });
  } else {
    menuItems?.forEach((item) => {
      if (item?.children) {
        item?.children?.forEach((child) => {
          filteredItems?.push(child);
        });
      } else {
        filteredItems?.push(item);
      }
    });
  }

  return filteredItems;
};

const styles = {
  icon: {
    fontSize: 16,
    color: "#CF6C57",
  },
};
