export default {
  slugs: {
    singular: "visit-purpose",
    plural: "visit-purposes",
  },
  entityNames: {
    singular: "Visit Purpose",
    plural: "Visit Purposes",
  },
};
