import React from "react";
import { Pie } from "@ant-design/plots";

const Chart = ({ angleField, colorField, data }) => {
  const config = {
    appendPadding: 10,
    data,
    angleField,
    colorField,
    radius: 1,
    innerRadius: 0.5,
    label: {
      type: "inner",
      offset: "-50%",
      style: {
        textAlign: "center",
      },
      autoRotate: false,
      content: "{value}",
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
      {
        type: "pie-statistic-active",
      },
    ],
    legend: {
      layout: "horizontal",
      position: "bottom",
    },
    statistic: {
      title: false,
      content: false,
    },
    height: 308,
  };

  return <Pie {...config} />;
};

export default Chart;
