import { Tag } from "antd";

export default {
  ROLE: {
    title: "Allowed Roles",
    entityNames: {
      singular: "Allowed Role",
      plural: "Allowed Roles",
    },
    columns: [
      {
        title: "Role",
        dataIndex: "app_role",
        selectable: true,
        filterable: true,
        references: {
          fieldName: "name",
        },
      },
      {
        title: "File Role",
        dataIndex: "file_role",
        selectable: true,
        render: (text) => <Tag>{text}</Tag>,
      },
    ],
  },
  DEPARTMENT: {
    title: "Allowed Departments",
    entityNames: {
      singular: "Allowed Department",
      plural: "Allowed Departments",
    },
    columns: [
      {
        title: "Department",
        dataIndex: "department",
        selectable: true,
        filterable: true,
        references: {
          fieldName: "name",
        },
      },
      {
        title: "File Role",
        dataIndex: "file_role",
        selectable: true,
        render: (text) => <Tag>{text}</Tag>,
      },
    ],
  },
  EMPLOYEE: {
    title: "Allowed Employees",
    entityNames: {
      singular: "Allowed Employee",
      plural: "Allowed Employees",
    },
    columns: [
      {
        title: "Employee",
        dataIndex: "employee",
        selectable: true,
        filterable: true,
        references: {
          fieldName: (record) => `${record?.first_name} ${record?.last_name}`,
        },
      },
      {
        title: "File Role",
        dataIndex: "file_role",
        selectable: true,
        render: (text) => <Tag>{text}</Tag>,
      },
    ],
  },
};
