import { axios } from "../../../../../App";
import config from "../config";
import moment from "moment";

export const makeConfig = ({ navigate }) => ({
  id: `${config?.plural}-edit`,
  title: `Edit ${config?.entityNames?.singular}`,
  pageTitle: `Edit ${config?.entityNames?.singular}`,
  breadcrumbs: [
    {
      path: "/quick-stocks/dashboard",
      title: "Home",
    },
    {
      path: `/quick-stocks/${config?.slugs?.plural}`,
      title: config?.entityNames?.plural,
    },
    {
      path: "",
      title: `Edit ${config?.entityNames?.singular}`,
    },
  ],
  components: [
    {
      span: 12,
      component: "select",
      label: "Hand Tool",
      name: "hand_tool",
      placeholder: "Select hand tool",
      required: true,
      rules: [
        {
          required: true,
          message: "Hand tool is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Required By",
      name: "required_by",
      placeholder: "Select employee",
      required: true,
      rules: [
        {
          required: true,
          message: "Required by is required!",
        },
      ],
    },
    {
      span: 12,
      component: "time",
      label: "In Time",
      name: "in_time",
      required: true,
      placeholder: "Select time",
      rules: [
        {
          required: true,
          message: "In time is required!",
        },
      ],
    },
    {
      span: 12,
      component: "time",
      label: "Out Time",
      name: "out_time",
      required: true,
      placeholder: "Select time",
      rules: [
        {
          required: true,
          message: "Out time is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Location",
      name: "location",
      placeholder: "Select location",
      required: true,
      rules: [
        {
          required: true,
          message: "Location is required!",
        },
      ],
    },
    {
      span: 24,
      component: "editor",
      label: "Description",
      name: "description",
      placeholder: "Enter description",
      rows: 5,
    },
  ],
  actions: {
    onBack: () => navigate(`/quick-stocks/${config?.slugs?.plural}`),
    onFinish: async (values, data) => {
      try {
        const response = await axios.put(
          `/${config?.slugs?.plural}/${data?.id}`,
          {
            data: {
              ...values,
              in_time: moment(values?.in_time).format("HH:mm:ss.SSS"),
              out_time: moment(values?.out_time).format("HH:mm:ss.SSS"),
            },
          }
        );

        if (response?.data) {
          return {
            success: true,
            message: `Edit ${config?.entityNames?.singular?.toLowerCase()} successfully!`,
          };
        }

        throw new Error();
      } catch (error) {
        return {
          success: false,
          message: error?.response?.data?.error?.message,
        };
      }
    },
  },
  ...config,
});
