import React, { useEffect, useState } from "react";
import { ModalAddForm } from "../../../components";
import { useNavigate } from "react-router-dom";
import { makeConfig } from "./config";
import { fetchData } from "../../../../../utils/helpers/data.helper";

const Page = (props) => {
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    work_item: [],
    all_work_item: [],
  });
  const config = makeConfig({ navigate });
  const contentTypes = [
    {
      slug: "work-groups",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          work_group: data,
        })),
    },
    {
      slug: "work-items",
      query: {
        populate: "*",
      },
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          all_work_item: data,
        })),
    },
    {
      slug: "work-units",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          work_unit: data,
        })),
    },
    {
      slug: "agencies",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          agency: data,
        })),
    },
  ];

  useEffect(() => {
    contentTypes?.forEach((contentType) => {
      fetchData(contentType);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (values?.all_work_item?.length > 0) {
      setValues((prev) => ({
        ...prev,
        work_item: values?.all_work_item
          ?.filter(
            (item) => item?.work_group?.data?.id === props?.data?.work_group
          )
          ?.map((item) => ({
            value: item?.id,
            label: item?.name,
          })),
      }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.all_work_item]);

  return (
    <ModalAddForm
      config={config}
      data={props?.data}
      loading={loading}
      values={values}
      onSubmit={props?.onSubmit}
      onCancel={props?.onCancel}
      onFieldChange={{
        work_group: (antForm) => {
          const workGroupId = antForm.getFieldValue("work_group");

          antForm.setFieldValue("work_item", undefined);

          if (!workGroupId) {
            setValues((prev) => ({
              ...prev,
              work_item: [],
            }));
          } else {
            setValues((prev) => ({
              ...prev,
              work_item: values?.all_work_item
                ?.filter((item) => item?.work_group?.data?.id === workGroupId)
                ?.map((item) => ({
                  value: item?.id,
                  label: item?.name,
                })),
            }));
          }
        },
      }}
    />
  );
};

export default Page;
