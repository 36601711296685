import React from "react";
import { Route } from "react-router-dom";
import {
  RevisedShiftList,
  RevisedShiftAdd,
  RevisedShiftEdit,
  RevisedShiftReport,
} from "../pages/RevisedShifts";

export const register = (props) => {
  const basePath = "revised-shifts";

  return (
    <>
      <Route path={basePath} element={<RevisedShiftList />} exact={true} />
      <Route path={`${basePath}/add`} element={<RevisedShiftAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<RevisedShiftEdit />} />
      <Route path={`${basePath}-report`} element={<RevisedShiftReport />} />
    </>
  );
};
