export default {
  slugs: {
    singular: "employee-document",
    plural: "employee-documents",
  },
  entityNames: {
    singular: "Employee Document",
    plural: "Employee Documents",
  },
};
