import { axios } from "../../../../../App";
import { v4 } from "uuid";
import config from "../config";
import moment from "moment";

export const makeConfig = ({ navigate, auth }) => ({
  id: `${config?.slugs?.plural}-add`,
  title: `Add New ${config?.entityNames?.singular}`,
  pageTitle: `Add New ${config?.entityNames?.singular}`,
  breadcrumbs: [
    {
      path: "/quick-setup",
      title: "Home",
    },
    {
      path: `/quick-setup/${config?.slugs?.plural}`,
      title: config?.entityNames?.plural,
    },
    {
      path: "",
      title: `Add New ${config?.entityNames?.singular}`,
    },
  ],
  components: [
    {
      span: 8,
      component: "input",
      label: "First Name",
      name: "first_name",
      required: true,
      placeholder: "Enter first name",
      rules: [
        {
          required: true,
          message: "First name is required!",
        },
      ],
    },
    {
      span: 8,
      component: "input",
      label: "Middle Name",
      name: "middle_name",
      placeholder: "Enter first name",
    },
    {
      span: 8,
      component: "input",
      label: "Last Name",
      name: "last_name",
      required: true,
      placeholder: "Enter last name",
      rules: [
        {
          required: true,
          message: "Last name is required!",
        },
      ],
    },
    {
      span: 8,
      component: "select",
      label: "Initial",
      name: "initial",
      placeholder: "Select initial",
      required: true,
      rules: [
        {
          required: true,
          message: "Initial is required!",
        },
      ],
    },
    {
      span: 8,
      component: "select",
      label: "Gender",
      name: "gender",
      placeholder: "Select gender",
      required: true,
      rules: [
        {
          required: true,
          message: "Gender is required!",
        },
      ],
    },
    {
      span: 8,
      component: "select",
      label: "Job Title",
      name: "job_title",
      placeholder: "Select job title",
      required: true,
      rules: [
        {
          required: true,
          message: "Job title is required!",
        },
      ],
    },
    {
      span: 12,
      component: "input",
      label: "No",
      name: "no",
      required: true,
      placeholder: "Enter no",
      rules: [
        {
          required: true,
          message: "No is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Department",
      name: "department",
      placeholder: "Select department",
      required: true,
      rules: [
        {
          required: true,
          message: "Department is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Designation",
      name: "designation",
      placeholder: "Select designation",
      required: true,
      rules: [
        {
          required: true,
          message: "Designation is required!",
        },
      ],
    },
    {
      span: 12,
      component: "date",
      label: "Birthdate",
      name: "birthdate",
      required: true,
      placeholder: "Select birthdate",
      rules: [
        {
          required: true,
          message: "Birthdate is required!",
        },
      ],
    },
    {
      span: 12,
      component: "uploader",
      label: "Photo",
      name: "photo",
      placeholder: "Upload photo",
    },
    {
      span: 8,
      component: "date",
      label: "Joining Date",
      name: "joining_date",
      required: true,
      placeholder: "Select joining date",
      rules: [
        {
          required: true,
          message: "Joining date is required!",
        },
      ],
    },
    {
      span: 8,
      component: "date",
      label: "Anniversary Date",
      name: "anniversary_date",
      // required: true,
      placeholder: "Select anniversary date",
      // rules: [
      //   {
      //     required: true,
      //     message: "Anniversary date is required!",
      //   },
      // ],
    },
    {
      span: 8,
      component: "date",
      label: "Resignation Date",
      name: "resignation_date",
      // required: true,
      placeholder: "Select resignation date",
      // rules: [
      //   {
      //     required: true,
      //     message: "Resignation date is required!",
      //   },
      // ],
    },
    {
      span: 12,
      component: "input",
      label: "PIN code",
      name: "pin_code",
      // required: true,
      placeholder: "Enter PIN code",
      rules: [
        {
          pattern: /^[0-9]{6}$/,
          message: "PIN code should be 6 digits",
        },
      ],
      // rules: [
      //   {
      //     required: true,
      //     message: "PIN code is required!",
      //   },
      // ],
    },
    {
      span: 12,
      component: "input",
      label: "STD code",
      name: "std_code",
      // required: true,
      placeholder: "Enter STD code",
      // rules: [
      //   {
      //     required: true,
      //     message: "STD code is required!",
      //   },
      // ],
    },
    {
      span: 24,
      component: "input",
      label: "Address Line 1",
      name: "address_line_1",
      required: true,
      placeholder: "Enter address line 1",
      rules: [
        {
          required: true,
          message: "Address line 1 is required!",
        },
      ],
    },
    {
      span: 24,
      component: "input",
      label: "Address Line 2",
      name: "address_line_2",
      placeholder: "Enter address line 2",
    },
    {
      span: 12,
      component: "select",
      label: "State",
      name: "state",
      placeholder: "Select state",
      required: true,
      rules: [
        {
          required: true,
          message: "State is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "City",
      name: "city",
      placeholder: "Select city",
      required: true,
      rules: [
        {
          required: true,
          message: "City is required!",
        },
      ],
    },
    {
      span: 12,
      component: "input",
      label: "Phone Number 1",
      name: "phone_number_1",
      // required: true,
      placeholder: "Enter phone number 1",
      // rules: [
      //   {
      //     required: true,
      //     message: "Phone number 1 is required!",
      //   },
      // ],
    },
    {
      span: 12,
      component: "input",
      label: "Phone Number 2",
      name: "phone_number_2",
      // required: true,
      placeholder: "Enter phone number 2",
      // rules: [
      //   {
      //     required: true,
      //     message: "Phone number 2 is required!",
      //   },
      // ],
    },
    {
      span: 12,
      component: "input",
      label: "Mobile Number 1",
      name: "mobile_number_1",
      required: true,
      placeholder: "Enter mobile number 1",
      rules: [
        {
          required: true,
          message: "Mobile number 1 is required!",
        },
      ],
    },
    {
      span: 12,
      component: "input",
      label: "Mobile Number 2",
      name: "mobile_number_2",
      // required: true,
      placeholder: "Enter mobile number 2",
      // rules: [
      //   {
      //     required: true,
      //     message: "Mobile number 2 is required!",
      //   },
      // ],
    },
    {
      span: 12,
      component: "input",
      label: "Email 1",
      name: "email_1",
      required: true,
      placeholder: "Enter email 1",
      rules: [
        {
          required: true,
          message: "Email 1 is required!",
        },
      ],
    },
    {
      span: 12,
      component: "input",
      label: "Email 2",
      name: "email_2",
      // required: true,
      placeholder: "Enter email 2",
      // rules: [
      //   {
      //     required: true,
      //     message: "Email 2 is required!",
      //   },
      // ],
    },
    {
      span: 8,
      component: "select",
      label: "Attendance Mode",
      name: "attendance_mode",
      placeholder: "Select attendance mode",
      required: true,
      rules: [
        {
          required: true,
          message: "Attendance mode is required!",
        },
      ],
    },
    {
      span: 8,
      component: "time",
      label: "In Time",
      name: "in_time",
      required: true,
      placeholder: "Select time",
      rules: [
        {
          required: true,
          message: "In time is required!",
        },
      ],
    },
    {
      span: 8,
      component: "time",
      label: "Out Time",
      name: "out_time",
      required: true,
      placeholder: "Select time",
      rules: [
        {
          required: true,
          message: "Out time is required!",
        },
      ],
    },
    {
      span: 24,
      component: "select",
      label: "Working Days",
      name: "working_days",
      placeholder: "Select working days",
      required: true,
      rules: [
        {
          required: true,
          message: "Working days is required!",
        },
      ],
      mode: "multiple",
    },
    {
      span: 24,
      component: "title",
      label: "Earnings",
      name: "earnings",
    },
    {
      span: 6,
      component: "number",
      label: "Basic Salary",
      name: "basic_salary",
      required: true,
      placeholder: "Enter basic salary",
      rules: [
        {
          required: true,
          message: "Basic salary is required!",
        },
      ],
      min: 0,
    },
    {
      span: 6,
      component: "number",
      label: "HRA",
      name: "hra",
      required: true,
      placeholder: "Enter HRA",
      rules: [
        {
          required: true,
          message: "HRA is required!",
        },
      ],
      min: 0,
    },
    {
      span: 6,
      component: "number",
      label: "Bonus",
      name: "bonus",
      required: true,
      placeholder: "Enter bonus",
      rules: [
        {
          required: true,
          message: "Bonus is required!",
        },
      ],
      min: 0,
    },
    {
      span: 6,
      component: "number",
      label: "Conveyance",
      name: "conveyance",
      required: true,
      placeholder: "Enter conveyance",
      rules: [
        {
          required: true,
          message: "Conveyance is required!",
        },
      ],
      min: 0,
    },
    {
      span: 6,
      component: "number",
      label: "Reimbursement",
      name: "reimbursement",
      required: true,
      placeholder: "Enter reimbursement",
      rules: [
        {
          required: true,
          message: "Reimbursement is required!",
        },
      ],
      min: 0,
    },
    {
      span: 6,
      component: "number",
      label: "Other Allowances",
      name: "other_allowances",
      required: true,
      placeholder: "Enter other allowances",
      rules: [
        {
          required: true,
          message: "Other allowances is required!",
        },
      ],
      min: 0,
    },
    {
      span: 24,
      component: "title",
      label: "Deductions",
      name: "deductions",
    },
    {
      span: 6,
      component: "number",
      label: "TDS",
      name: "tds",
      required: true,
      placeholder: "Enter TDS",
      rules: [
        {
          required: true,
          message: "TDS is required!",
        },
      ],
      min: 0,
    },
    {
      span: 6,
      component: "number",
      label: "ESI",
      name: "esi",
      required: true,
      placeholder: "Enter ESI",
      rules: [
        {
          required: true,
          message: "ESI is required!",
        },
      ],
      min: 0,
    },
    {
      span: 6,
      component: "number",
      label: "PF",
      name: "pf",
      required: true,
      placeholder: "Enter PF",
      rules: [
        {
          required: true,
          message: "PF is required!",
        },
      ],
      min: 0,
    },
    {
      span: 6,
      component: "number",
      label: "Advanced Loan",
      name: "advanced_loan",
      required: true,
      placeholder: "Enter advanced loan",
      rules: [
        {
          required: true,
          message: "Advanced loan is required!",
        },
      ],
      min: 0,
    },
    {
      span: 6,
      component: "number",
      label: "Other Deductions",
      name: "other_deductions",
      required: true,
      placeholder: "Enter other deductions",
      rules: [
        {
          required: true,
          message: "Other deductions is required!",
        },
      ],
      min: 0,
    },
    {
      span: 24,
      component: "input",
      type: "hidden",
      name: "photo",
    },
    {
      span: 24,
      component: "editor",
      label: "Description",
      name: "description",
      placeholder: "Enter description",
      rows: 5,
    },
  ],
  actions: {
    onBack: () => navigate(`/quick-setup/${config?.slugs?.plural}`),
    onFinish: async (values) => {
      try {
        const response = await axios.post(`/${config?.slugs?.plural}`, {
          data: {
            ...values,
            uid: v4(),
            // creator: auth?.user?.id,
            photo: values?.photo || null,
            birthdate:
              values?.birthdate !== ""
                ? values?.birthdate?.format("YYYY-MM-DD")
                : null,
            joining_date:
              values?.joining_date !== ""
                ? values?.joining_date?.format("YYYY-MM-DD")
                : null,
            anniversary_date:
              values?.anniversary_date !== ""
                ? values?.anniversary_date?.format("YYYY-MM-DD")
                : null,
            resignation_date:
              values?.resignation_date !== ""
                ? values?.resignation_date?.format("YYYY-MM-DD")
                : null,
            in_time: moment(values?.in_time)?.format("HH:mm:ss"),
            out_time: moment(values?.out_time)?.format("HH:mm:ss"),
          },
        });

        if (response?.data) {
          return {
            success: true,
            message: `Add new ${config?.entityNames?.singular?.toLowerCase()} successfully!`,
          };
        }

        throw new Error();
      } catch (error) {
        return {
          success: false,
          message: `Add new ${config?.entityNames?.singular?.toLowerCase()} failed!`,
        };
      }
    },
  },
});
