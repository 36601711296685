export default {
  slugs: {
    singular: "employee",
    plural: "employees",
  },
  entityNames: {
    singular: "Employee",
    plural: "Employees",
  },
};
