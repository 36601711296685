import { axios } from "../../../../../App";
import { v4 } from "uuid";
import config from "../config";
import qs from "qs";

export const makeConfig = ({ navigate, auth }) => ({
  id: `${config?.slugs?.plural}-add`,
  title: `Add New ${config?.entityNames?.singular}`,
  pageTitle: `Add New ${config?.entityNames?.singular}`,
  breadcrumbs: [
    {
      path: "/quick-stocks/dashboard",
      title: "Home",
    },
    {
      path: `/quick-stocks/${config?.slugs?.plural}`,
      title: config?.entityNames?.plural,
    },
    {
      path: "",
      title: `Add New ${config?.entityNames?.singular}`,
    },
  ],
  components: [
    // {
    //   span: 12,
    //   component: "select",
    //   label: "Vendor",
    //   name: "vendor",
    //   placeholder: "Select vendor",
    //   required: true,
    //   rules: [
    //     {
    //       required: true,
    //       message: "Vendor is required!",
    //     },
    //   ],
    // },
    {
      span: 12,
      component: "select",
      label: "Project",
      name: "project",
      placeholder: "Select project",
      required: true,
      rules: [
        {
          required: true,
          message: "Project is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Material Item",
      name: "material_item",
      placeholder: "Select material item",
      required: true,
      rules: [
        {
          required: true,
          message: "Material item is required!",
        },
      ],
    },
     // {
    //   span: 12,
    //   component: "select",
    //   label: "Material Unit",
    //   name: "material_unit",
    //   placeholder: "Select material unit",
    //   required: true,
    //   rules: [
    //     {
    //       required: true,
    //       message: "Material unit is required!",
    //     },
    //   ],
    // },
    // {
    //   span: 12,
    //   component: "select",
    //   label: "Location",
    //   name: "location",
    //   placeholder: "Select location",
    //   required: true,
    //   rules: [
    //     {
    //       required: true,
    //       message: "Location is required!",
    //     },
    //   ],
    // },
    {
      span: 12,
      component: "number",
      label: "Quantity",
      name: "quantity",
      required: true,
      placeholder: "Enter quantity",
      rules: [
        {
          required: true,
          message: "Quantity is required!",
        },
      ],
      min: 0,
    },
    // {
    //   span: 12,
    //   component: "number",
    //   label: "Physically Counted Quantity",
    //   name: "physically_counted_quantity",
    //   required: true,
    //   placeholder: "Enter physically counted quantity",
    //   rules: [
    //     {
    //       required: true,
    //       message: "Physically counted quantity is required!",
    //     },
    //   ],
    //   min: 0,
    // },
    // {
    //   span: 24,
    //   component: "editor",
    //   label: "Description",
    //   name: "description",
    //   placeholder: "Enter description",
    //   rows: 5,
    // },
    {
      span: 24,
      component: "editor",
      label: "Remarks",
      name: "remarks",
      placeholder: "Enter remarks",
      rows: 5,
    },
  ],
  actions: {
    onBack: () => navigate(`/quick-stocks/${config?.slugs?.plural}`),
    onFinish: async (values) => {
      try {
        const queryObject = {
          filters: {
            material_item: values?.material_item,
            material_unit: values?.material_unit,
          },
        };

        const existingResp = await axios.get(
          `/${config?.slugs?.plural}?${qs.stringify(queryObject)}`
        );

        if (existingResp?.status === 200) {
          const total = existingResp?.data?.meta?.pagination?.total;

          if (total > 0) {
            return {
              success: false,
              message: `There is already an existing item with a similar task material.`,
            };
          } else {
            const response = await axios.post(`/${config?.slugs?.plural}`, {
              data: {
                ...values,
                status: "Pending",
                uid: v4(),
                source: "MANUAL",
                // creator: auth?.user?.id,
              },
            });

            if (response?.data) {
              return {
                success: true,
                message: `Add new ${config?.entityNames?.singular?.toLowerCase()} successfully!`,
              };
            }

            throw new Error();
          }
        } else {
          throw new Error();
        }
      } catch (error) {
        return {
          success: false,
          message: `Add new ${config?.entityNames?.singular?.toLowerCase()} failed!`,
        };
      }
    },
  },
});
