import { axios } from "../../../../../App";
import { v4 } from "uuid";
import config from "../config";

export const makeConfig = ({ navigate, auth }) => ({
  id: `${config?.slugs?.plural}-add`,
  title: `Add New ${config?.entityNames?.singular}`,
  pageTitle: `Add New ${config?.entityNames?.singular}`,
  breadcrumbs: [
    {
      path: "/quick-stocks/dashboard",
      title: "Home",
    },
    {
      path: `/quick-stocks/${config?.slugs?.plural}`,
      title: config?.entityNames?.plural,
    },
    {
      path: "",
      title: `Add New ${config?.entityNames?.singular}`,
    },
  ],
  components: [
    {
      span: 12,
      component: "date",
      label: "Date",
      name: "date",
      placeholder: "Select date",
    },
    {
      span: 12,
      component: "select",
      label: "Location",
      name: "location",
      placeholder: "Select location",
      required: true,
      rules: [
        {
          required: true,
          message: "Location is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Machinery",
      name: "machine",
      placeholder: "Select machine",
      required: true,
      rules: [
        {
          required: true,
          message: "Machine is required!",
        },
      ],
    },
    {
      span: 12,
      component: "number",
      label: "Amount",
      name: "amount",
      placeholder: "Enter amount",
      min: 0,
    },
    {
      span: 12,
      component: "number",
      label: "Estimate hours of use",
      name: "estimate_hours_of_use",
      placeholder: "Enter estimate hours",
      min: 0,
    },
    {
      span: 12,
      component: "select",
      label: "Operated By",
      name: "runned_by",
      placeholder: "Select operated by",
      required: true,
      rules: [
        {
          required: true,
          message: "Operated is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Purchased By",
      name: "purchased_by",
      placeholder: "Select purchased by",
      required: true,
      rules: [
        {
          required: true,
          message: "Machine is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Projects",
      name: "project",
      placeholder: "Select Project",
      required: true,
      rules: [
        {
          required: true,
          message: "Project is required!",
        },
      ],
    },
    // {
    //   span: 12,
    //   component: "uploader",
    //   label: "Upload Bill Photo",
    //   name: "attachment",
    //   required: true,
    //   placeholder: "Upload attachment",
    //   rules: [
    //     {
    //       required: true,
    //       message: "Attachment is required!",
    //     },
    //   ],
    // },
    // {
    //   span: 24,
    //   component: "editor",
    //   label: "Description",
    //   name: "description",
    //   placeholder: "Enter description",
    //   rows: 5,
    // },
    {
      span: 24,
      component: "editor",
      label: "Remarks",
      name: "remarks",
      placeholder: "Enter remarks",
      rows: 5,
    },
  ],
  actions: {
    onBack: () => navigate(`/quick-stocks/${config?.slugs?.plural}`),
    onFinish: async (values) => {
      try {
        const response = await axios.post(`/${config?.slugs?.plural}`, {
          data: {
            ...values,
            uid: v4(),
            // creator: auth?.user?.id,
          },
        });

        if (response?.data) {
          return {
            success: true,
            message: `Add new ${config?.entityNames?.singular?.toLowerCase()} successfully!`,
          };
        }

        throw new Error();
      } catch (error) {
        return {
          success: false,
          message: `Add new ${config?.entityNames?.singular?.toLowerCase()} failed!`,
        };
      }
    },
  },
});
