import { NOTIFICATION_UNREAD_COUNT } from "../actions/types";

const initialState = {
  unread_count: 0,
};

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case NOTIFICATION_UNREAD_COUNT:
      return {
        ...state,
        unread_count: action?.payload,
      };

    default:
      return state;
  }
};

export default notificationReducer;
