import { axios } from "../../../../../App";
import { v4 } from "uuid";
import config from "../config";

export const makeConfig = ({ navigate, auth }) => ({
  id: `${config?.slugs?.plural}-add`,
  title: `Add New ${config?.entityNames?.singular}`,
  pageTitle: `Add New ${config?.entityNames?.singular}`,
  breadcrumbs: [
    {
      path: "/quick-stocks/dashboard",
      title: "Home",
    },
    {
      path: `/quick-stocks/${config?.slugs?.plural}`,
      title: config?.entityNames?.plural,
    },
    {
      path: "",
      title: `Add New ${config?.entityNames?.singular}`,
    },
  ],
  components: [
    {
      span: 24,
      component: "input",
      label: "Heading",
      name: "heading",
      required: true,
      placeholder: "Enter heading",
      rules: [
        {
          required: true,
          message: "Heading is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Vendor",
      name: "vendor",
      placeholder: "Select vendor",
      required: true,
      rules: [
        {
          required: true,
          message: "Vendor is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Project",
      name: "project",
      placeholder: "Select project",
      required: true,
      rules: [
        {
          required: true,
          message: "Project is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Delivery Address",
      name: "delivery_address",
      placeholder: "Select delivery address",
      required: true,
      rules: [
        {
          required: true,
          message: "Delivery address is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Material Group",
      name: "material_group",
      placeholder: "Select material group",
      required: true,
      rules: [
        {
          required: true,
          message: "Material group is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Material Requisition",
      name: "vendor_material_requisition",
      placeholder: "Select material requisition",
      required: true,
      rules: [
        {
          required: true,
          message: "Material requisition is required!",
        },
      ],
    },
    {
      span: 12,
      component: "number",
      label: "Budget",
      name: "budget",
      required: true,
      placeholder: "Enter budget",
      rules: [
        {
          required: true,
          message: "Budget is required!",
        },
      ],
      min: 0,
    },
    {
      span: 12,
      component: "number",
      label: "Estimated Amount",
      name: "estimated_amount",
      required: true,
      placeholder: "Enter estimated amount",
      rules: [
        {
          required: true,
          message: "Estimated amount is required!",
        },
      ],
      min: 0,
    },
    {
      span: 12,
      component: "select",
      label: "Terms & Conditions",
      name: "vendor_term",
      placeholder: "Select terms & conditions",
      required: true,
      rules: [
        {
          required: true,
          message: "Terms & conditions is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Payment Schedule",
      name: "vendor_payment_schedule",
      placeholder: "Select payment schedule",
      required: true,
      rules: [
        {
          required: true,
          message: "Payment schedule is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Urgency Level",
      name: "urgency_level",
      placeholder: "Select urgency level",
      required: true,
      rules: [
        {
          required: true,
          message: "Urgency level is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Approved By",
      name: "approved_by",
      placeholder: "Select employee",
      required: true,
      rules: [
        {
          required: true,
          message: "Approved by is required!",
        },
      ],
    },
    {
      span: 24,
      component: "editor",
      label: "Remarks",
      name: "remarks",
      placeholder: "Enter remarks",
      rows: 5,
    },
    {
      span: 24,
      component: "editor",
      label: "Approval Remarks",
      name: "approval_remarks",
      placeholder: "Enter approval remarks",
      rows: 5,
    },
  ],
  actions: {
    onBack: () => navigate(`/quick-stocks/${config?.slugs?.plural}`),
    onFinish: async (values) => {
      try {
        const response = await axios.post(`/${config?.slugs?.plural}`, {
          data: {
            ...values,
            uid: v4(),
            // creator: auth?.user?.id,
          },
        });

        if (response?.data) {
          return {
            success: true,
            message: `Add new ${config?.entityNames?.singular?.toLowerCase()} successfully!`,
          };
        }

        throw new Error();
      } catch (error) {
        return {
          success: false,
          message: `Add new ${config?.entityNames?.singular?.toLowerCase()} failed!`,
        };
      }
    },
  },
});
