import { SettingOutlined } from "@ant-design/icons";
import { Dropdown, Menu, Button, Checkbox, Tooltip } from "antd";
import React, { useState, useEffect, useRef } from "react";

const ColumnSelector = ({ columns, value, onSelect }) => {
  const [showMenu, setShowMenu] = useState(false);
  const ref = useRef();

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowMenu(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return (
    <Dropdown
      overlay={
        <div ref={ref}>
          <Menu
            items={columns?.map((column, index) => ({
              key: index,
              label: (
                <Checkbox
                  checked={value?.indexOf(column?.dataIndex) > -1}
                  onChange={() => onSelect(column?.dataIndex)}
                >
                  {column?.title}
                </Checkbox>
              ),
            }))}
          />
        </div>
      }
      trigger={["click"]}
      visible={showMenu}
    >
      <Tooltip placement="top" title="Select columns">
        <Button icon={<SettingOutlined />} onClick={toggleMenu} />
      </Tooltip>
    </Dropdown>
  );
};

export default ColumnSelector;
