import config from "../config";

export default {
  id: `${config?.slugs?.plural}-list`,
  pageTitle: `${config?.entityNames?.plural} Report`,
  code: "TASK_MANPOWERS",
  breadcrumbs: [
    {
      path: "/quick-projects/dashboard",
      title: "Home",
    },
    {
      path: `/quick-projects/reports`,
      title: "Reports",
    },
    {
      path: "",
      title: `${config?.entityNames?.plural} Report`,
    },
  ],
  columns: [
    {
      title: "Task",
      dataIndex: "task",
      selectable: true,
      references: {
        fieldName: "name",
      },
      width: 200,
    },
    {
      title: "Labour",
      dataIndex: "labour",
      selectable: true,
      sorter: (a, b) => a?.labour?.localeCompare(b?.labour),
    },
    {
      title: "Manpower Type",
      dataIndex: "manpower_type",
      selectable: true,
      references: {
        fieldName: "name",
      },
    },
    {
      title: "Estimated Budget",
      dataIndex: "task",
      selectable: true,
      references: {
        fieldName: "estimated_budget",
      },
    },
    {
      title: "Sanctioned Budget",
      dataIndex: "task",
      selectable: true,
      references: {
        fieldName: "sanctioned_budget",
      },
    },
  ],
  ...config,
};
