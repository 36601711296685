import React from "react";
import { Route } from "react-router-dom";
import {
  VendorMaterialReceivableList,
  VendorMaterialReceivableAdd,
  VendorMaterialReceivableEdit,
  VendorMaterialReceivableView,
  VendorMaterialReceivableReceive,
  VendorMaterialReceivableReport,
} from "../pages/VendorMaterialReceivables";
import ReplaceMaterialReport from "../pages/VendorMaterialReceivables/ReplaceMaterial";
import ReturnMaterialReport from "../pages/VendorMaterialReceivables/RetunMaterial";

export const register = (props) => {
  const basePath = "vendor-material-receivables";

  return (
    <>
      <Route
        path={basePath}
        element={<VendorMaterialReceivableList />}
        exact={true}
      />
      <Route
        path={`${basePath}/add`}
        element={<VendorMaterialReceivableAdd />}
      />
      <Route
        path={`${basePath}/edit/:id`}
        element={<VendorMaterialReceivableEdit />}
      />
      <Route
        path={`${basePath}/view/:id`}
        element={<VendorMaterialReceivableView />}
      />
      <Route
        path={`${basePath}/receive/:id`}
        element={<VendorMaterialReceivableReceive />}
      />
      <Route
        path={`${basePath}-report`}
        element={<VendorMaterialReceivableReport />}
      />
      <Route
        path={`replace-material-report`}
        element={<ReplaceMaterialReport />}
      />
      <Route
        path={`return-material-report`}
        element={<ReturnMaterialReport />}
      />
    </>
  );
};
