/* eslint-disable import/no-anonymous-default-export */
import config from "../config";
import moment from "moment";

export default {
  id: `${config?.slugs?.plural}-list`,
  pageTitle: config?.entityNames?.plural,
  breadcrumbs: [
    {
      path: "/quick-teams/dashboard",
      title: "Home",
    },
    {
      path: "",
      title: config?.entityNames?.plural,
    },
  ],
  columns: [
    {
      title: "First Name",
      dataIndex: "first_name",
      selectable: true,
      width: 200,
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      selectable: true,
      width: 200,
    },
    {
      title: "Meeting With",
      dataIndex: "meeting_with",
      selectable: true,
      filterable: true,
      references: {
        fieldName: (record) => `${record?.first_name} ${record?.last_name}`,
      },
      width: 250,
    },
    // {
    //   title: "Meeting Purpose",
    //   dataIndex: "meeting_purpose",
    //   selectable: true,
    //   references: {
    //     fieldName: "meeting_purpose",
    //   },
    //   width: 200,
    // },
    {
      title: "Date",
      dataIndex: "date",
      selectable: true,
      sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
      render: (text) => <span>{moment(text).format("DD-MM-YYYY")}</span>,
      width: 200,
    },
    // {
    //   title: "Email",
    //   dataIndex: "email_1",
    //   selectable: true,
    //   sorter: (a, b) => a?.email_1?.localeCompare(b?.email_1),
    // },
    // {
    //   title: "Phone Number",
    //   dataIndex: "phone_number_1",
    //   selectable: true,
    //   sorter: (a, b) => a?.phone_number_1?.localeCompare(b?.phone_number_1),
    // },
  ],
  ...config,
};
