import React from "react";
import { Route } from "react-router-dom";
import {
  SubContractorPaymentScheduleList,
  SubContractorPaymentScheduleAdd,
  SubContractorPaymentScheduleEdit,
  SubContractorPaymentScheduleReport,
} from "../pages/SubContractorPaymentSchedules";

export const register = (props) => {
  const basePath = "sub-contractor-payment-schedules";

  return (
    <>
      <Route
        path={basePath}
        element={<SubContractorPaymentScheduleList />}
        exact={true}
      />
      <Route
        path={`${basePath}/add`}
        element={<SubContractorPaymentScheduleAdd />}
      />
      <Route
        path={`${basePath}/edit/:id`}
        element={<SubContractorPaymentScheduleEdit />}
      />
      <Route
        path={`${basePath}-report`}
        element={<SubContractorPaymentScheduleReport />}
      />
    </>
  );
};
