import React from "react";
import { Route } from "react-router-dom";
import {
  DesignationList,
  DesignationAdd,
  DesignationEdit,
} from "../pages/Designations";

export const register = (props) => {
  const basePath = "designations";

  return (
    <>
      <Route path={basePath} element={<DesignationList />} exact={true} />
      <Route path={`${basePath}/add`} element={<DesignationAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<DesignationEdit />} />
    </>
  );
};
