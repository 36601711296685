export default {
  slugs: {
    singular: "attendance",
    plural: "attendances",
  },
  entityNames: {
    singular: "Attendance",
    plural: "Attendances",
  },
};
