import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { makeConfig } from "./config";
import { useEffect, useState } from "react";
import { v4 } from "uuid";
import { fetchData } from "../../../../../utils/helpers/data.helper";
import { AddForm } from "../../../components";
import { axios } from "../../../../../App";
import qs from "qs";

const AddQuotation = (props) => {
  const navigate = useNavigate();
  const auth = useSelector((state) => state?.auth);
  const config = makeConfig({ navigate, auth });
  const [values, setValues] = useState({});
  const [item, setitem] = useState([]);
  const [client, setclient] = useState([]);
  const [poAddress, setpoAddress] = useState([]);
  const [paymentSchedule, setpaymentSchedule] = useState([]);
  const [term, setterm] = useState([]);
  const [shippings, setShippings] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null)
  const [vendorTerm, setVendorTerm] = useState([])

  const [formData, setFormData] = useState(null)

  const contentTypes = [
    {
      slug: "material-groups",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          material_group: data,
        })),
    },
    {
      slug: "material-items",
      query: {
        populate: "*"
      },
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
        material_image: item?.material_image
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          material_item: data,
        })),
    },
    {
      slug: "material-units",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          material_unit: data,
        })),
    },
    {
      slug: "vendors",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          vendor: data,
          approved_by: data,
        })),
    },

    {
      slug: "companies",
      transformer: (item) => ({
        ...item,
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          po_from: data,
        })),
    },
    {
      slug: "banks",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          bank: data,
        })),
    },
    {
      slug: "vendor-terms",
      query: {
        populate: "*"
      },
      transformer: (item) => ({
        ...item,
      }),
      onSuccess: (data) => {
        const transformedDataArray = data?.map((item) => ({
          id: item.uid,
          heading: item.heading,
          material_group: item.material_group.data ? item.material_group.data.attributes.name : null,
          material_item: item.material_item.data ? item.material_item.data.attributes.name : null,
          description: item.description,
          material_image: null,  // Assuming no image in the first data
          raw_data: {
            heading: item.heading,
            material_group: item.material_group.data ? item.material_group.data.id : null,
            material_item: item.material_item.data ? item.material_item.data.id : null,
            description: item.description,
          }
        }));

        setVendorTerm([...transformedDataArray]);
      }

    },
  ];

  const transformPaymentSchedule = (rawData) => {
    return {
      ...rawData,
      material_item: values?.material_item?.find(
        (item) => item?.value === rawData?.material_item
      )?.label,
      material_image: values?.material_item?.find(
        (item) => item?.value === rawData?.material_item
      )?.material_image,
      raw_data: rawData,
    };
  };

  const onAddInvoice = (data) => {
    const newData = {
      id: v4(),
      ...data,
      ...transformPaymentSchedule(data),
    };

    setitem((prev) => prev?.concat(newData));

    const itemTerm = vendorTerm?.find((term) => term?.raw_data?.material_item === data?.material_item)

    if (itemTerm) {
      setterm(prev => ([...prev, itemTerm]))
    }
  };

  const onAddClient = (data) => {
    if (client?.length > 0) {
      alert('Only one client can be added.')
      return;
    }
    const newData = {
      id: v4(),
      ...data,
      ...transformPaymentSchedule(data),
    };

    setclient((prev) => prev?.concat(newData));
  };

  const onEditClient = (id, data) => {
    let newitem = client?.map((record) => {
      if (record?.id === id) {
        record = {
          ...data,
          ...transformTerm(data),
        };
      }

      return record;
    });

    setclient(newitem);
  };

  const onDeleteClient = (id) => {
    setclient((prev) => prev?.filter((item) => item?.id !== id));
  };

  const onAddAddress = (data) => {
    const newData = {
      id: v4(),
      ...data,
      ...transformPaymentSchedule(data),
    };

    setpoAddress((prev) => prev?.concat(newData));
  };

  const onDeleteAddress = (id) => {
    setpoAddress((prev) => prev?.filter((item) => item?.id !== id));
  };


  const transformTerm = (rawData) => {
    return {
      ...rawData,
      material_group: values?.material_group?.find(
        (item) => item?.value === rawData?.material_group
      )?.label,
      material_item: values?.material_item?.find(
        (item) => item?.value === rawData?.material_item
      )?.label,
      material_unit: values?.material_unit?.find(
        (item) => item?.value === rawData?.material_unit
      )?.label,
      raw_data: rawData,
    };
  };

  const onAddTerm = (data) => {
    const newData = {
      id: v4(),
      ...data,
      ...transformTerm(data),
    };

    setterm((prev) => prev?.concat(newData));
  };

  const onEditTerm = (id, data) => {
    let newitem = term?.map((record) => {
      if (record?.id === id) {
        record = {
          ...data,
          ...transformTerm(data),
        };
      }

      return record;
    });

    setterm(newitem);
  };


  const onDeleteTerm = (id) => {
    setterm((prev) => prev?.filter((item) => item?.id !== id));
  };

  const onEditInvoice = (id, data) => {
    let newitem = item?.map((record) => {
      if (record?.id === id) {
        record = {
          ...data,
          ...transformPaymentSchedule(data),
        };
      }

      return record;
    });

    setitem(newitem);
  };

  const onDeleteInvoice = (id) => {
    setitem((prev) => prev?.filter((item) => item?.id !== id));
  };

  const onAddShipping = (data) => {
    if (shippings?.length > 0) {
      alert('Only one Shipping Address can be added.')
      return;
    }
    const newData = {
      id: v4(),
      ...data,
      ...transformPaymentSchedule(data),
    };

    setShippings((prev) => prev?.concat(newData));
  };

  const onEditShipping = (id, data) => {
    let newitem = shippings?.map((record) => {
      if (record?.id === id) {
        record = {
          ...data,
          ...transformPaymentSchedule(data),
        };
      }

      return record;
    });

    setShippings(newitem);
  };

  const onDeleteShipping = (id) => {
    setShippings((prev) => prev?.filter((item) => item?.id !== id));
  };

  useEffect(() => {
    contentTypes?.forEach((contentType) => {
      fetchData(contentType);

      setValues((prev) => ({
        ...prev,
        invoice_type: [
          {
            value: 'Performa Invoice',
            label: 'Performa Invoice'
          },
          {
            value: 'Tax Invoice',
            label: 'Tax Invoice'
          }
        ]
      }))
    });
  }, []);

  const fetchVendorSchedulesData = async (params = null) => {
    try {

      const queryObject = {
        populate: "*",
        // sort: ["heading:asc"],
      };

      // if (!_.isEmpty(params)) {
      //   queryObject["filters"] = params;
      // }

      const response = await axios.get(
        `vendor-payment-schedules?${qs.stringify(queryObject)}`
      );

      if (response?.data) {
        // Create a set of material_group IDs from materialItems
        const materialGroupIds = new Set(item?.map(item => item.raw_data.material_group));

        // Filter the data based on material_group IDs
        const filtered_data = response?.data?.data?.filter(
          (s) => materialGroupIds?.has(s?.attributes?.material_group?.data?.id)
        );

        // console.log('Material IDS', materialGroupIds, response?.data?.data, filtered_data)

        if (filtered_data) {
          // Extract all payment schedules from the filtered data
          const payments = filtered_data.flatMap(payment => {
            const selected_item = item?.find(item => item.material_group === payment.attributes.material_group?.data?.id);
            return payment?.attributes?.payment_schedule?.map(schedule => ({
              ...schedule,
              material_group: payment.attributes.material_group,
              amount: selected_item?.total * Number(schedule?.amount_percentage) / 100
            }));
          });

          // Do something with the payments, e.g., set state
          setpaymentSchedule(payments);
        }


      }

    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchVendorSchedulesData();

  }, [item])

  useEffect(() => {
    if (formData?.po_from) {
      const filtereCompany = values?.po_from.find(po => po.value === formData.po_from)

      setSelectedCompany(filtereCompany);
    }
  }, [formData?.po_from])

  console.log('quotation', item, values, paymentSchedule);

  return (
    <>
      <AddForm
        config={config}
        values={values}
        setFormData={setFormData}
        modals={{
          item: {
            data: item,
            methods: {
              add: onAddInvoice,
              delete: onDeleteInvoice,
              edit: onEditInvoice,
            },
          },
          client: {
            data: client,
            methods: {
              add: onAddClient,
              delete: onDeleteClient,
              edit: onEditClient,
            },
          },
          shipped_to: {
            data: shippings,
            methods: {
              add: onAddShipping,
              delete: onDeleteShipping,
              edit: onEditShipping,
            },
          },
          po_address: {
            data: poAddress,
            methods: {
              add: onAddAddress,
              delete: onDeleteAddress,
              // edit: onEditInvoice,
            },
          },
          payment_schedule: {
            data: paymentSchedule,
            methods: {
              // add: onAddPaymentSchedule,
              // edit: onEditPaymentSchedule,
              // delete: onDeletePaymentSchedule,
              // addExisting: onAddExistingPaymentSchedule,
            },
          },
          term: {
            data: term,
            methods: {
              add: onAddTerm,
              edit: onEditTerm,
              delete: onDeleteTerm,
              // addExisting: onAddExistingTerm,
            },
          },
        }}
        data={{
          item: item,
          client: client,
          shipped_to: shippings,
          po_address: poAddress,
          payment_schedule: paymentSchedule,
          term: term,
        }}
      />
    </>
  );
};

export default AddQuotation;