import { MdArrowBack } from "react-icons/md";
import { BiCategoryAlt } from "react-icons/bi";
import { TbReportAnalytics, TbReportMoney } from "react-icons/tb";
import {
  FaRegCalendarTimes,
  FaRegAddressCard,
  FaRegCalendarCheck,
} from "react-icons/fa";
import {
  RiContactsBookLine,
  RiRefund2Line,
  RiPieChart2Fill,
  RiUserLocationLine,
} from "react-icons/ri";
import { CgTimelapse } from "react-icons/cg";
import { GiReceiveMoney } from "react-icons/gi";
import { BsCashCoin } from "react-icons/bs";
import { IoTicketOutline } from "react-icons/io5";

const BASE_SLUG = "/quick-teams";

export const makeNavbar = ({ navigate, user }) => {
  const makeItem = ({ icon, id, label, scopes }) => {
    return {
      id,
      key: `${BASE_SLUG}/${id}`,
      label,
      icon,
      onClick: () => navigate(`${BASE_SLUG}/${id}`),
      scopes,
    };
  };

  let roles = [];

  user?.app_roles?.forEach((appRole) => {
    appRole?.permissions?.forEach((appPermission) => {
      if (!roles?.includes(appPermission)) {
        roles?.push(appPermission);
      }
    });
  });

  roles = roles?.join(",");

  const menuItems = [
    makeItem({
      id: "dashboard",
      label: "Dashboard",
      icon: <RiPieChart2Fill style={styles.icon} />,
      scopes: [
        {
          id: "view",
          label: "View",
        },
      ],
    }),
    {
      key: "attendance",
      label: "Attendance",
      icon: <FaRegCalendarCheck style={styles.icon} />,
      children: [
        makeItem({
          id: "attendances",
          label: "Attendances",
          icon: <FaRegCalendarCheck style={styles.icon} />,
        }),
        makeItem({
          id: "location-check-ins",
          label: "Location Check-ins",
          icon: <RiUserLocationLine style={styles.icon} />,
        }),
        makeItem({
          id: "leave-applications",
          label: "Leave Applications",
          icon: <FaRegCalendarTimes style={styles.icon} />,
        }),
        makeItem({
          id: "leave-types",
          label: "Leave Application Types",
          icon: <BiCategoryAlt style={styles.icon} />,
        }),
        makeItem({
          id: "attendance-types",
          label: "Attendance Types",
          icon: <BiCategoryAlt style={styles.icon} />,
        }),
        makeItem({
          id: "attendance-modes",
          label: "Attendance Modes",
          icon: <BiCategoryAlt style={styles.icon} />,
        }),
      ],
    },
    {
      key: "payment",
      label: "Payment",
      icon: <TbReportMoney style={styles.icon} />,
      children: [
        makeItem({
          id: "payrolls",
          label: "Payrolls",
          icon: <TbReportMoney style={styles.icon} />,
        }),
        makeItem({
          id: "petty-cashes",
          label: "Petty Cashes",
          icon: <BsCashCoin style={styles.icon} />,
        }),
        makeItem({
          id: "reimburses",
          label: "Reimburses",
          icon: <RiRefund2Line style={styles.icon} />,
        }),
        makeItem({
          id: "advanced-loans",
          label: "Advanced Loans",
          icon: <GiReceiveMoney style={styles.icon} />,
        }),
      ],
    },
    {
      key: "shifts",
      label: "Shifts",
      icon: <CgTimelapse style={styles.icon} />,
      children: [
        makeItem({
          id: "shifts",
          label: "Shifts",
          icon: <CgTimelapse style={styles.icon} />,
        }),
        makeItem({
          id: "rosters",
          label: "Rosters",
          icon: <CgTimelapse style={styles.icon} />,
        }),
        makeItem({
          id: "revised-shifts",
          label: "Revised Shifts",
          icon: <CgTimelapse style={styles.icon} />,
        }),
      ],
    },
    {
      key: "contacts",
      label: "Contacts",
      icon: <RiContactsBookLine style={styles.icon} />,
      children: [
        makeItem({
          id: "contacts",
          label: "Contacts",
          icon: <RiContactsBookLine style={styles.icon} />,
        }),
        makeItem({
          id: "contact-groups",
          label: "Contact Groups",
          icon: <BiCategoryAlt style={styles.icon} />,
        }),
      ],
    },
    {
      key: "visitors",
      label: "Visitors",
      icon: <FaRegAddressCard style={styles.icon} />,
      children: [
        makeItem({
          id: "visitors",
          label: "Visitors",
          icon: <FaRegAddressCard style={styles.icon} />,
        }),
        makeItem({
          id: "visit-purposes",
          label: "Visit Purposes",
          icon: <BiCategoryAlt style={styles.icon} />,
        }),
      ],
    },
    makeItem({
      id: "tickets",
      label: "Tickets",
      icon: <IoTicketOutline style={styles.icon} />,
    }),
    makeItem({
      id: "reports",
      label: "Reports",
      icon: <TbReportAnalytics style={styles.icon} />,
      scopes: [
        {
          id: "view",
          label: "View",
        },
      ],
    }),
    {
      key: "back-to-home",
      label: "Return Home",
      icon: <MdArrowBack style={styles.icon} />,
      onClick: () => navigate("/"),
    },
  ];

  let filteredItems = [];

  if (user) {
    menuItems?.forEach((item) => {
      const uid = item?.children ? item?.key : item?.id;

      if (item?.key === "back-to-home") {
        filteredItems?.push(item);
      }

      if (item?.children) {
        const newChildren = item?.children?.filter((item) => {
          const subItemUid = item?.id;

          return roles?.includes(`QUICK-TEAMS.${subItemUid?.toUpperCase()}`);
        });

        if (newChildren?.length > 0) {
          item["children"] = newChildren;
          filteredItems?.push(item);
        }
      } else {
        if (roles?.includes(`QUICK-TEAMS.${uid?.toUpperCase()}`)) {
          filteredItems?.push(item);
        }
      }
    });
  } else {
    menuItems?.forEach((item) => {
      if (item?.children) {
        item?.children?.forEach((child) => {
          filteredItems?.push(child);
        });
      } else {
        filteredItems?.push(item);
      }
    });
  }

  return filteredItems;
};

const styles = {
  icon: {
    fontSize: 16,
    color: "#CF6C57",
  },
};
