import React from "react";
import { Route } from "react-router-dom";
import {
  ReminderList,
  ReminderAdd,
  ReminderEdit,
  ReminderReport,
  ReminderView,
} from "../pages/Reminders";

export const register = (props) => {
  const basePath = "reminders";

  return (
    <>
      <Route path={basePath} element={<ReminderList />} exact={true} />
      <Route path={`${basePath}/add`} element={<ReminderAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<ReminderEdit />} />
      <Route path={`${basePath}/view/:id`} element={<ReminderView />} />
      <Route path={`${basePath}-report`} element={<ReminderReport />} />
    </>
  );
};
