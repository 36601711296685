export default {
  slugs: {
    singular: "event",
    plural: "events",
  },
  entityNames: {
    singular: "Event",
    plural: "Events",
  },
};
