import React from "react";
import { Route } from "react-router-dom";
import {
  MachineList,
  MachineAdd,
  MachineEdit,
  MachineReport,
} from "../pages/Machines";

export const register = (props) => {
  const basePath = "machines";

  return (
    <>
      <Route path={basePath} element={<MachineList />} exact={true} />
      <Route path={`${basePath}/add`} element={<MachineAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<MachineEdit />} />
      <Route path={`${basePath}-report`} element={<MachineReport />} />
    </>
  );
};
