import config from "../config";

const commify = (x) => {
  return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export default {
  id: `${config?.slugs?.plural}-list`,
  pageTitle: config?.entityNames?.plural,
  breadcrumbs: [
    {
      path: "/quick-stocks/dashboard",
      title: "Home",
    },
    {
      path: "",
      title: config?.entityNames?.plural,
    },
  ],
  columns: [
    {
      title: "Project",
      dataIndex: "project",
      selectable: true,
      references: {
        fieldName: "name",
      },
    },
    {
      title: "Material Item",
      dataIndex: "material_item",
      selectable: true,
      filterable: true,
      references: {
        fieldName: "name",
      },
    },
    // {
    //   title: "Material Unit",
    //   dataIndex: "material_unit",
    //   selectable: true,
    //   filterable: true,
    //   references: {
    //     fieldName: "name",
    //   },
    // },
    {
      title: "Quantity",
      dataIndex: "quantity",
      selectable: true,
      render: (text) => commify(text || 0),
      sorter: (a, b) => a?.quantity - b?.quantity,
    },
    // {
    //   title: "Physically Counted Quantity",
    //   dataIndex: "physically_counted_quantity",
    //   selectable: true,
    //   render: (text) => commify(text || 0),
    //   sorter: (a, b) =>
    //     a?.physically_counted_quantity - b?.physically_counted_quantity,
    // },
  ],
  ...config,
};
