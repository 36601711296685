import React, { useEffect, useState } from "react";
import { AddForm } from "../../../components";
import { useNavigate, useParams } from "react-router-dom";
import { makeConfig } from "./config";
import { axios } from "../../../../../App";
import qs from "qs";
import { makeNavbar as makeProjectsNav } from "../../../../projects/nav";
import { makeNavbar as makeTeamsNav } from "../../../../teams/nav";
import { makeNavbar as makeTalksNav } from "../../../../talks/nav";
import { makeNavbar as makeStocksNav } from "../../../../stocks/nav";
import { makeNavbar as makeSetupNav } from "../../../../setup/nav";
import { makeNavbar as makeDriveNav } from "../../../../drive/nav";
import PermissionsTable from "../../../components/PermissionsTable";

const Page = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const config = makeConfig({ navigate });
  const [values, setValues] = useState({
    user_type: [
      "General",
      "Employee",
      "Partner",
      "Agency",
      "Vendor",
      "Sub-contractor",
    ]?.map((item) => ({
      value: item,
      label: item,
    })),
  });
  const [permissions, setPermissions] = useState([]);
  const rawItems = {
    projects: {
      id: "quick-projects",
      name: "Quick Projects",
      items: makeProjectsNav({ navigate }),
    },
    teams: {
      id: "quick-teams",
      name: "Quick Teams",
      items: makeTeamsNav({ navigate }),
    },
    talks: {
      id: "quick-talks",
      name: "Quick Talks",
      items: makeTalksNav({ navigate }),
    },
    stocks: {
      id: "quick-stocks",
      name: "Quick Stocks",
      items: makeStocksNav({ navigate }),
    },
    drive: {
      id: "quick-drive",
      name: "Quick Drive",
      items: makeDriveNav({ navigate }),
    },
    setup: {
      id: "quick-setup",
      name: "Quick Setup",
      items: makeSetupNav({ navigate }),
    },
  };

  const onChangePermissions = (code, _) => {
    if (permissions?.indexOf(code) > -1) {
      setPermissions(permissions?.filter((item) => item !== code));
    } else {
      setPermissions(permissions?.concat(code));
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const queryObject = {
          populate: "*",
        };

        const response = await axios.get(
          `/${config?.slugs?.plural}/${id}?${qs.stringify(queryObject)}`
        );

        if (response?.data) {
          setPermissions(response?.data?.data?.attributes?.permissions);

          setData({
            ...response?.data?.data?.attributes,
            id: response?.data?.data?.id,
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <AddForm
      config={config}
      data={{
        ...data,
        permissions,
      }}
      loading={loading}
      values={values}
      extra={
        <PermissionsTable
          rawItems={rawItems}
          permissions={permissions}
          onChange={onChangePermissions}
        />
      }
    />
  );
};

export default Page;
