import React from "react";
import { Route } from "react-router-dom";
import { JobTitleList, JobTitleAdd, JobTitleEdit } from "../pages/JobTitles";

export const register = (props) => {
  const basePath = "job-titles";

  return (
    <>
      <Route path={basePath} element={<JobTitleList />} exact={true} />
      <Route path={`${basePath}/add`} element={<JobTitleAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<JobTitleEdit />} />
    </>
  );
};
