import { axios } from "../../../../../App";
import config from "../config";
import qs from "qs";

export const makeConfig = ({ navigate }) => ({
  id: `${config?.plural}-edit`,
  title: `Edit ${config?.entityNames?.singular}`,
  pageTitle: `Edit ${config?.entityNames?.singular}`,
  breadcrumbs: [
    {
      path: "/quick-stocks/dashboard",
      title: "Home",
    },
    {
      path: `/quick-stocks/${config?.slugs?.plural}`,
      title: config?.entityNames?.plural,
    },
    {
      path: "",
      title: `Edit ${config?.entityNames?.singular}`,
    },
  ],
  components: [
    // {
    //   span: 12,
    //   component: "select",
    //   label: "Vendor",
    //   name: "vendor",
    //   placeholder: "Select vendor",
    //   required: true,
    //   rules: [
    //     {
    //       required: true,
    //       message: "Vendor is required!",
    //     },
    //   ],
    //   disabled: true,
    // },
    {
      span: 12,
      component: "select",
      label: "Project",
      name: "project",
      placeholder: "Select project",
      required: true,
      rules: [
        {
          required: true,
          message: "Project is required!",
        },
      ],
      disabled: true,
    },
    {
      span: 12,
      component: "select",
      label: "Material Item",
      name: "material_item",
      placeholder: "Select material item",
      required: true,
      rules: [
        {
          required: true,
          message: "Material item is required!",
        },
      ],
      disabled: true,
    },
    // {
    //   span: 12,
    //   component: "select",
    //   label: "Material Unit",
    //   name: "material_unit",
    //   placeholder: "Select material unit",
    //   required: true,
    //   rules: [
    //     {
    //       required: true,
    //       message: "Material unit is required!",
    //     },
    //   ],
    //   disabled: true,
    // },
    // {
    //   span: 12,
    //   component: "select",
    //   label: "Location",
    //   name: "location",
    //   placeholder: "Select location",
    //   required: true,
    //   rules: [
    //     {
    //       required: true,
    //       message: "Location is required!",
    //     },
    //   ],
    //   disabled: true,
    // },
    {
      span: 12,
      component: "number",
      label: "Quantity",
      name: "quantity",
      required: true,
      placeholder: "Enter quantity",
      rules: [
        {
          required: true,
          message: "Quantity is required!",
        },
      ],
      min: 0,
    },
    // {
    //   span: 12,
    //   component: "number",
    //   label: "Physically Counted Quantity",
    //   name: "physically_counted_quantity",
    //   required: true,
    //   placeholder: "Enter physically counted quantity",
    //   rules: [
    //     {
    //       required: true,
    //       message: "Physically counted quantity is required!",
    //     },
    //   ],
    //   min: 0,
    // },
    // {
    //   span: 24,
    //   component: "editor",
    //   label: "Description",
    //   name: "description",
    //   placeholder: "Enter description",
    //   rows: 5,
    // },
    {
      span: 24,
      component: "editor",
      label: "Remarks",
      name: "remarks",
      placeholder: "Enter remarks",
      rows: 5,
    },
  ],
  actions: {
    onBack: () => navigate(`/quick-stocks/${config?.slugs?.plural}`),
    onFinish: async (values, data) => {
      try {
        const updateEntry = async () => {
          const response = await axios.put(
            `/${config?.slugs?.plural}/${data?.id}`,
            {
              data: values,
            }
          );

          if (response?.data) {
            return {
              success: true,
              message: `Edit ${config?.entityNames?.singular?.toLowerCase()} successfully!`,
            };
          }

          throw new Error();
        };

        const queryObject = {
          filters: {
            material_item: values?.material_item,
            material_unit: values?.material_unit,
          },
        };

        const existingResp = await axios.get(
          `/${config?.slugs?.plural}?${qs.stringify(queryObject)}`
        );

        if (existingResp?.status === 200) {
          const total = existingResp?.data?.meta?.pagination?.total;

          if (total > 0) {
            const entryId = existingResp?.data?.data?.[0]?.id;

            if (entryId === data?.id) {
              return updateEntry();
            } else {
              return {
                success: false,
                message: `There is already an existing item with a similar task material.`,
              };
            }
          } else {
            return updateEntry();
          }
        } else {
          throw new Error();
        }
      } catch (error) {
        return {
          success: false,
          message: error?.response?.data?.error?.message,
        };
      }
    },
  },
  ...config,
});
