import config from "../config";

const commify = (x) => {
  return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  id: `${config?.slugs?.plural}-list`,
  pageTitle: `${config?.entityNames?.plural} Report`,
  code: "SUB_CONTRACTOR_DEBIT_NOTES",
  breadcrumbs: [
    {
      path: "/quick-stocks/dashboard",
      title: "Home",
    },
    {
      path: `/quick-stocks/reports`,
      title: "Reports",
    },
    {
      path: "",
      title: `${config?.entityNames?.plural} Report`,
    },
  ],
  columns: [
    {
      title: "Heading",
      dataIndex: "heading",
      selectable: true,
      sorter: (a, b) => a?.heading?.localeCompare(b?.heading),
      width: 200,
    },
    {
      title: "Project",
      dataIndex: "project",
      selectable: true,
      references: {
        fieldName: "name",
      },
    },
    {
      title: "Sub Contractor",
      dataIndex: "sub_contractor",
      selectable: true,
      references: {
        fieldName: "name",
      },
    },
    {
      title: "Grand Total",
      dataIndex: "grand_total",
      selectable: true,
      render: (text) => commify(text || 0),
      sorter: (a, b) => a?.grand_total - b?.grand_total,
    },
  ],
  ...config,
};
