import React from "react";
import { Route } from "react-router-dom";
import {
  HandToolLogList,
  HandToolLogAdd,
  HandToolLogEdit,
  HandToolLogReport,
} from "../pages/HandToolLogs";

export const register = (props) => {
  const basePath = "hand-tool-logs";

  return (
    <>
      <Route path={basePath} element={<HandToolLogList />} exact={true} />
      <Route path={`${basePath}/add`} element={<HandToolLogAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<HandToolLogEdit />} />
      <Route path={`${basePath}-report`} element={<HandToolLogReport />} />
    </>
  );
};
