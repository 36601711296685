import React from "react";
import SharedLayout from "../../components/SharedLayout";
import {makeNavbar} from "./nav";
import { Outlet } from "react-router-dom";

const AccountManagement = () => {
  return (
    <SharedLayout menuItem={{ title: "", path: "/account/profile" }}  makeNavbar = {makeNavbar}>
      <Outlet />
    </SharedLayout>
  );
};

export default AccountManagement;
