import { axios } from "../../../../../App";
import config from "../config";

export const makeConfig = ({ navigate }) => ({
  id: `${config?.plural}-view`,
  title: `View ${config?.entityNames?.singular}`,
  pageTitle: `View ${config?.entityNames?.singular}`,
  breadcrumbs: [
    {
      path: "/quick-stocks/dashboard",
      title: "Home",
    },
    {
      path: `/quick-stocks/${config?.slugs?.plural}`,
      title: config?.entityNames?.plural,
    },
    {
      path: "",
      title: `Edit ${config?.entityNames?.singular}`,
    },
  ],
  components: [
    // {
    //   span: 12,
    //   component: "select",
    //   label: "Vendor",
    //   name: "vendor",
    //   placeholder: "Select vendor",
    //   required: true,
    //   rules: [
    //     {
    //       required: true,
    //       message: "Vendor is required!",
    //     },
    //   ],
    // },
    {
      span: 12,
      component: "select",
      label: "Project",
      name: "project",
      placeholder: "Select project",
      required: true,
      rules: [
        {
          required: true,
          message: "Project is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Material Item",
      name: "material_item",
      placeholder: "Select material item",
      required: true,
      rules: [
        {
          required: true,
          message: "Material item is required!",
        },
      ],
      disabled: true,
    },
     // {
    //   span: 12,
    //   component: "select",
    //   label: "Material Unit",
    //   name: "material_unit",
    //   placeholder: "Select material unit",
    //   required: true,
    //   rules: [
    //     {
    //       required: true,
    //       message: "Material unit is required!",
    //     },
    //   ],
    // },
    // {
    //   span: 12,
    //   component: "select",
    //   label: "Location",
    //   name: "location",
    //   placeholder: "Select location",
    //   required: true,
    //   rules: [
    //     {
    //       required: true,
    //       message: "Location is required!",
    //     },
    //   ],
    // },
    {
      span: 12,
      component: "number",
      label: "Quantity",
      name: "quantity",
      required: true,
      placeholder: "Enter quantity",
      rules: [
        {
          required: true,
          message: "Quantity is required!",
        },
      ],
      min: 0,
    },
    // {
    //   span: 12,
    //   component: "number",
    //   label: "Physically Counted Quantity",
    //   name: "physically_counted_quantity",
    //   required: true,
    //   placeholder: "Enter physically counted quantity",
    //   rules: [
    //     {
    //       required: true,
    //       message: "Physically counted quantity is required!",
    //     },
    //   ],
    //   min: 0,
    // },
    // {
    //   span: 24,
    //   component: "editor",
    //   label: "Description",
    //   name: "description",
    //   placeholder: "Enter description",
    //   rows: 5,
    // },
    {
      span: 24,
      component: "editor",
      label: "Remarks",
      name: "remarks",
      placeholder: "Enter remarks",
      rows: 5,
      disabled: true,
    },
  ],
  actions: {
    onBack: () => navigate(`/quick-stocks/${config?.slugs?.plural}`),
    onFinish: async (values, data) => {
      try {
        const response = await axios.put(
          `/${config?.slugs?.plural}/${data?.id}`,
          {
            data: values,
          }
        );

        if (response?.data) {
          return {
            success: true,
            message: `Edit ${config?.entityNames?.singular?.toLowerCase()} successfully!`,
          };
        }

        throw new Error();
      } catch (error) {
        return {
          success: false,
          message: error?.response?.data?.error?.message,
        };
      }
    },
  },
  ...config,
});
