export default {
  slugs: {
    singular: "material-group",
    plural: "material-groups",
  },
  entityNames: {
    singular: "Material Group",
    plural: "Material Groups",
  },
};
