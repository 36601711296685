import React from "react";
import { Route } from "react-router-dom";
import {
  TicketList,
  TicketAdd,
  TicketView,
  TicketReport,
} from "../pages/Tickets";

export const register = (props) => {
  const basePath = "tickets";

  return (
    <>
      <Route path={basePath} element={<TicketList />} exact={true} />
      <Route path={`${basePath}/add`} element={<TicketAdd />} />
      <Route path={`${basePath}/view/:id`} element={<TicketView />} />
      <Route path={`${basePath}-report`} element={<TicketReport />} />
    </>
  );
};
