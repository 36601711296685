import React from "react";
import { Route } from "react-router-dom";
import {
  AgencyWorkOrderList,
  AgencyWorkOrderView,
  AgencyWorkOrderReport,
} from "../pages/AgencyWorkOrders";

export const register = (props) => {
  const basePath = "agency-work-orders";

  return (
    <>
      <Route path={basePath} element={<AgencyWorkOrderList />} exact={true} />
      <Route path={`${basePath}/view/:id`} element={<AgencyWorkOrderView />} />
      <Route path={`${basePath}-report`} element={<AgencyWorkOrderReport />} />
    </>
  );
};
