import moment from "moment";
import { Link } from "react-router-dom";
import { FiExternalLink } from "react-icons/fi";
import { Button } from "antd";

export const MeetingColumns = [
  {
    title: "ID",
    dataIndex: "id",
  },
  {
    title: "Name",
    dataIndex: "name",
  },
  {
    title: "Date/ Time",
    dataIndex: "started_at",
    render: (text) => <span>{moment(text).format("DD-MM-YYYY, HH:mm")}</span>,
  },
  {
    title: "Agenda",
    dataIndex: "agenda",
  },
  {
    title: "Details",
    dataIndex: "id",
    render: (text) => (
      <Link to={`/quick-talks/meetings/view/${text}`}>
        <Button icon={<FiExternalLink style={{ margin: "0 auto" }} />}></Button>
      </Link>
    ),
  },
];

export const EventColumns = [
  {
    title: "ID",
    dataIndex: "id",
  },
  {
    title: "Name",
    dataIndex: "name",
  },
  {
    title: "Date/ Time",
    dataIndex: "time",
    render: (text) => <span>{moment(text).format("DD-MM-YYYY, HH:mm")}</span>,
  },
  {
    title: "Message",
    dataIndex: "description",
  },
  {
    title: "Details",
    dataIndex: "id",
    render: (text) => (
      <Link to={`/quick-talks/events/view/${text}`}>
        <Button icon={<FiExternalLink style={{ margin: "0 auto" }} />}></Button>
      </Link>
    ),
  },
];

export const ReminderColumns = [
  {
    title: "ID",
    dataIndex: "id",
  },
  {
    title: "Name",
    dataIndex: "name",
  },
  {
    title: "Date",
    dataIndex: "date",
    render: (text) => <span>{moment(text).format("DD-MM-YYYY")}</span>,
  },
  {
    title: "Time",
    dataIndex: "time",
    render: (text) => <span>{text?.split(".")?.[0]}</span>,
  },
  {
    title: "Message",
    dataIndex: "description",
  },
  {
    title: "Details",
    dataIndex: "id",
    render: (text) => (
      <Link to={`/quick-talks/reminders/view/${text}`}>
        <Button icon={<FiExternalLink style={{ margin: "0 auto" }} />}></Button>
      </Link>
    ),
  },
];

export const ToDoColumns = [
  {
    title: "ID",
    dataIndex: "id",
  },
  {
    title: "Heading",
    dataIndex: "name",
  },
  {
    title: "Description",
    dataIndex: "description",
  },
  {
    title: "Details",
    dataIndex: "id",
    render: (text) => (
      <Link to={`/quick-talks/to-dos/view/${text}`}>
        <Button icon={<FiExternalLink style={{ margin: "0 auto" }} />}></Button>
      </Link>
    ),
  },
];

export const NewEmailsColumns = [
  {
    title: "ID",
    dataIndex: "id",
  },
  {
    title: "Subject",
    dataIndex: "subject",
  },
  {
    title: "Sender",
    dataIndex: "sender",
    render: (text) => {
      if (text?.data) {
        return `${text?.data?.attributes?.first_name} ${text?.data?.attributes.last_name}`;
      }

      return "N/A";
    },
  },
  {
    title: "Details",
    dataIndex: "id",
    render: (text) => (
      <Link to={`/quick-talks/app-emails/view/${text}`}>
        <Button icon={<FiExternalLink style={{ margin: "0 auto" }} />}></Button>
      </Link>
    ),
  },
];

export const UnOpenedChatsColumns = [
  {
    title: "ID",
    dataIndex: "id",
  },
  {
    title: "Content",
    dataIndex: "content",
  },
  // {
  //   title: "Sender",
  //   dataIndex: "sender",
  //   render: (text) => {
  //     if (text?.data) {
  //       return `${text?.data?.attributes?.first_name} ${text?.data?.attributes.last_name}`;
  //     }

  //     return "N/A";
  //   },
  // },
  {
    title: "Details",
    dataIndex: "id",
    render: (_, { conversation }) => {
      if (conversation?.data) {
        return (
          <Link
            to={`/quick-talks/project-chats?conversationId=${conversation?.data?.id}`}
          >
            <Button
              icon={<FiExternalLink style={{ margin: "0 auto" }} />}
            ></Button>
          </Link>
        );
      }
    },
  },
];
