/* eslint-disable import/no-anonymous-default-export */
import config from "../config";
import moment from "moment";
import { Tag } from "antd";

function commify(number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default {
  id: `${config?.slugs?.plural}-list`,
  pageTitle: `${config?.entityNames?.plural} Report`,
  code: "PROJECTS",
  breadcrumbs: [
    {
      path: "/quick-projects/dashboard",
      title: "Home",
    },
    {
      path: `/quick-projects/reports`,
      title: "Reports",
    },
    {
      path: "",
      title: `${config?.entityNames?.plural} Report`,
    },
  ],
  columns: [
    {
      title: "No",
      dataIndex: "no",
      selectable: true,
      sorter: (a, b) => a?.no?.localeCompare(b?.no),
      width: 150,
    },
    {
      title: "Name",
      dataIndex: "name",
      selectable: true,
      sorter: (a, b) => a?.name?.localeCompare(b?.name),
      width: 250,
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      selectable: true,
      sorter: (a, b) =>
        moment(a.start_date).unix() - moment(b.start_date).unix(),
      render: (text) => <span>{moment(text).format("DD-MM-YYYY")}</span>,
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      selectable: true,
      sorter: (a, b) => moment(a.end_date).unix() - moment(b.end_date).unix(),
      render: (text) => <span>{moment(text).format("DD-MM-YYYY")}</span>,
    },
    {
      title: "Project Type",
      dataIndex: "project_type",
      selectable: true,
      references: {
        fieldName: "name",
      },
    },
    {
      title: "Estimated Budget",
      dataIndex: "estimated_budget",
      selectable: true,
      render: (text) => commify(text || 0),
      sorter: (a, b) => a?.estimated_budget - b?.estimated_budget,
    },
    {
      title: "Status",
      dataIndex: "status",
      selectable: true,
      render: (text) => <Tag>{text}</Tag>,
    },
  ],
  ...config,
};
