import React from "react";
import { Modal } from "antd";
import { axios } from "../../../../App";

const DeleteModal = ({
  visible,
  onClose,
  onFinish,
  currentFileItemId = null,
  data = {},
}) => {
  const onDelete = async (values) => {
    try {
      const response = await axios.put(`/file-items/${currentFileItemId}`, {
        data: {
          is_deleted: true,
        },
      });

      if (response?.data) {
        onFinish();
        onClose();
      }

      throw new Error();
    } catch (error) {}
  };

  return (
    <Modal
      title={`Delete ${data?.item_type}`}
      visible={visible}
      onOk={onDelete}
      onCancel={onClose}
      destroyOnClose
    >
      <p>
        Are you sure to delete the {data?.item_type?.toLowerCase()}{" "}
        <strong>{data?.name}</strong>?
      </p>
    </Modal>
  );
};

export default DeleteModal;
