import React from "react";
import { Route } from "react-router-dom";
import {
  MachineLogList,
  MachineLogAdd,
  MachineLogEdit,
  MachineLogReport,
} from "../pages/MachineLogs";

export const register = (props) => {
  const basePath = "machine-logs";

  return (
    <>
      <Route path={basePath} element={<MachineLogList />} exact={true} />
      <Route path={`${basePath}/add`} element={<MachineLogAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<MachineLogEdit />} />
      <Route path={`${basePath}-report`} element={<MachineLogReport />} />
    </>
  );
};
