export default {
  slugs: {
    singular: "vendor",
    plural: "vendors",
  },
  entityNames: {
    singular: "Vendor",
    plural: "Vendors",
  },
};
