import React, { useState, useEffect } from "react";
import { Layout, Button, Form, Input, notification } from "antd";
import { Box, Image, SimpleGrid } from "@chakra-ui/react";
import logo from "../../assets/images/logo.png";
import axios from "../../utils/axios";
import { useNavigate } from "react-router-dom";
import { loginUser } from "../../redux/actions/authActions";
import { setClientUid } from "../../redux/actions/clientUidActions";
import { useDispatch, useSelector } from "react-redux";
import bgAuth from "../../assets/images/bg-auth.png";
import _ from "lodash";
import { v4 } from "uuid";

const OPTIONS = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0,
};

const Page = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [userLocation, setUserLocation] = useState({});
  const [polygons, setPolygons] = useState([]);
  const { loggedIn } = useSelector((state) => state?.auth);

  const isInside = (point, vs) => {
    // https://stackoverflow.com/questions/22521982/check-if-point-is-inside-a-polygon

    let x = point[0];
    let y = point[1];
    let inside = false;

    for (let i = 0, j = vs.length - 1; i < vs.length; j = i++) {
      let xi = vs[i][0],
        yi = vs[i][1];
      let xj = vs[j][0],
        yj = vs[j][1];

      let intersect =
        yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;

      if (intersect) inside = !inside;
    }

    return inside;
  };

  const findLocation = (point) => {
    let vs = [];

    polygons.forEach((polygon) => {
      polygon?.coordinates?.forEach((coordinate) => {
        vs.push([coordinate?.latitude, coordinate?.longitude]);
      });
    });

    for (let i = 0; i < vs.length; i++) {
      if (isInside(point, vs)) {
        return {
          id: polygons[i]?.id,
        };
      }
    }

    return null;
  };

  const fetchLocations = async () => {
    try {
      const resp = await axios.get("/locations");

      if (resp?.data) {
        setPolygons(
          resp?.data?.data?.map((item) => ({
            id: item?.id,
            coordinates: item?.attributes?.coordinates,
          }))
        );
      }
    } catch {}
  };

  const onFinish = async (values) => {
    try {
      setIsLoading(true);

      const clientUid = v4();

      const submitData = {
        username: values?.email,
        password: values?.password,
        client_uid: clientUid,
      };

      if (!_.isEmpty(userLocation)) {
        submitData["location"] = {
          ...userLocation,
          uid: v4(),
        };
      }

      const { data } = await axios.post(`/app-auth/login`, submitData);

      if (data?.success) {
        dispatch(setClientUid(clientUid));

        dispatch(
          loginUser({
            ...data?.data?.user,
            token: data?.data?.jwt,
          })
        );

        notification["success"]({
          message: "Login",
          description: "Login successfully!",
        });

        navigate("/");
      } else {
        notification["error"]({
          message: "Login",
          description: data?.message,
        });
      }
    } catch (error) {
      notification["error"]({
        message: "Login",
        description: "Login unsuccessfully!",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onFinishFailed = (errorInfo) => {};

  const success = (pos) => {
    const { latitude, longitude } = pos.coords;
    const location = findLocation([latitude, longitude]);

    let result = {
      latitude,
      longitude,
    };

    if (location) {
      result["location"] = location?.id;
    }

    setUserLocation(result);
  };

  const errors = (err) => {
    console.warn(`ERROR(${err.code}): ${err.message}`);
  };

  useEffect(() => {
    if (navigator?.geolocation) {
      navigator?.permissions
        ?.query({ name: "geolocation" })
        .then(function (result) {
          if (result.state === "granted") {
            //If granted then you can directly call your function here
            navigator.geolocation.getCurrentPosition(success);
          } else if (result.state === "prompt") {
            navigator.geolocation.getCurrentPosition(success, errors, OPTIONS);
          } else if (result.state === "denied") {
            //If denied then you have to show instructions to enable location
          }

          result.onchange = function () {
            console.log(result.state);
          };
        });
    } else {
      alert("Sorry Not available!");
    }

    if (loggedIn) {
      navigate("/");
    }

    fetchLocations();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout
      style={{
        padding: "64px 20px 64px 20px",
        background: "url(images/background.jpeg)",
        backgroundSize: "100vw 100vh",
        minHeight: "100vh",
      }}
    >
      <Box
        bg="#fff"
        mb={{ base: "5", lg: "10" }}
        mx={{ base: "none", md: "10%" }}
        borderRadius={20}
        boxShadow="md"
      >
        <SimpleGrid columns={[1, 1, 1, 2]} spacingX="10px">
          <Box width="100%">
            <img src={bgAuth} style={styles.image} />
            {/* <Image src={bgAuth} style={styles.image} /> */}
          </Box>

          <Box justifyContent="center" width="100%" p={10}>
            <Image
              src={logo}
              alt="Logo"
              style={{ maxHeight: 60, marginBottom: 24 }}
            />
            <Form
              name="basic"
              h="max-content"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              layout="vertical"
              paddingX="0"
              disabled={isLoading}
            >
              <Form.Item
                label="Mobile/ Email/ Username"
                name="email"
                rules={[{ required: true, message: "Email is required!" }]}
              >
                <Input placeholder="Enter mobile/ email/ username" />
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
                rules={[{ required: true, message: "Password is required!" }]}
              >
                <Input.Password placeholder="Enter password" />
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  loading={isLoading}
                >
                  Sign in
                </Button>
              </Form.Item>
            </Form>
          </Box>
        </SimpleGrid>
      </Box>
    </Layout>
  );
};

const styles = {
  imageContainer: {
    maxHeight: "100vh",
    maxWidth: "100%",
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    borderRadius: 20,
    overflow: "auto",
  },
};

export default Page;
