import config from "../config";

export default {
  id: `${config?.slugs?.plural}-list`,
  pageTitle: config?.entityNames?.plural,
  breadcrumbs: [
    {
      path: "/quick-stocks/dashboard",
      title: "Home",
    },
    {
      path: "",
      title: config?.entityNames?.plural,
    },
  ],
  columns: [
    {
      title: "Heading",
      dataIndex: "heading",
      selectable: true,
      sorter: (a, b) => a?.heading?.localeCompare(b?.heading),
    },
    // {
    //   title: "Vendor",
    //   dataIndex: "vendor",
    //   selectable: true,
    //   references: {
    //     fieldName: "name",
    //   },
    // },
    {
      title: "Material Group",
      dataIndex: "material_group",
      selectable: true,
      references: {
        fieldName: "name",
      },
    },
    {
      title: "Material Item",
      dataIndex: "material_item",
      selectable: true,
      references: {
        fieldName: "name",
      },
    },
    // {
    //   title: "Material Unit",
    //   dataIndex: "material_unit",
    //   selectable: true,
    //   references: {
    //     fieldName: "name",
    //   },
    // },
  ],
  ...config,
};
