import React from "react";
import { Route } from "react-router-dom";
import {
  ContactList,
  ContactAdd,
  ContactEdit,
  ContactReport,
} from "../pages/Contacts";

export const register = (props) => {
  const basePath = "contacts";

  return (
    <>
      <Route path={basePath} element={<ContactList />} exact={true} />
      <Route path={`${basePath}/add`} element={<ContactAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<ContactEdit />} />
      <Route path={`${basePath}-report`} element={<ContactReport />} />
    </>
  );
};
