import React from "react";
import { Route } from "react-router-dom";
import {
  AgencyDebitNoteList,
  AgencyDebitNoteAdd,
  AgencyDebitNoteCreatorEdit,
  AgencyDebitNoteApproverEdit,
  AgencyDebitNoteReport,
  AgencyDebitNoteView,
} from "../pages/AgencyDebitNotes";

export const register = (props) => {
  const basePath = "agency-debit-notes";

  return (
    <>
      <Route path={basePath} element={<AgencyDebitNoteList />} exact={true} />
      <Route path={`${basePath}/add`} element={<AgencyDebitNoteAdd />} />
      <Route
        path={`${basePath}/creator/edit/:id`}
        element={<AgencyDebitNoteCreatorEdit />}
      />
      <Route
        path={`${basePath}/approver/edit/:id`}
        element={<AgencyDebitNoteApproverEdit />}
      />
      <Route path={`${basePath}/view/:id`} element={<AgencyDebitNoteView />} />
      <Route path={`${basePath}-report`} element={<AgencyDebitNoteReport />} />
    </>
  );
};
