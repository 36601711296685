import { axios } from "../../../../../App";
import { v4 } from "uuid";
import config from "../config";

export const makeConfig = ({ navigate, auth }) => ({
  id: `${config?.slugs?.plural}-add`,
  title: `Add New ${config?.entityNames?.singular}`,
  pageTitle: `Add New ${config?.entityNames?.singular}`,
  breadcrumbs: [
    {
      path: "/quick-stocks/dashboard",
      title: "Home",
    },
    {
      path: `/quick-stocks/${config?.slugs?.plural}`,
      title: config?.entityNames?.plural,
    },
    {
      path: "",
      title: `Add New ${config?.entityNames?.singular}`,
    },
  ],
  components: [
    {
      span: 12,
      component: "select",
      label: "Machinery",
      name: "machine",
      placeholder: "Select machinery",
      required: true,
      rules: [
        {
          required: true,
          message: "Machine is required!",
        },
      ],
    },
    {
      span: 12,
      component: "input",
      label: "Label",
      name: "label",
      required: true,
      placeholder: "Enter label",
      rules: [
        {
          required: true,
          message: "Label is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Manufacturer",
      name: "manufacturer",
      placeholder: "Select manufacturer",
      required: true,
      rules: [
        {
          required: true,
          message: "Manufacturer is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Contact",
      name: "contact",
      placeholder: "Select contact",
      required: true,
      rules: [
        {
          required: true,
          message: "Contact is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Allotted To",
      name: "allotted_to",
      placeholder: "Select employee",
      required: true,
      rules: [
        {
          required: true,
          message: "Allotted to is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Approved By",
      name: "performed_by",
      placeholder: "Select employee",
      required: true,
      rules: [
        {
          required: true,
          message: "Performed by is required!",
        },
      ],
    },
    {
      span: 12,
      component: "date",
      label: "Scheduled Date",
      name: "date",
      required: true,
      placeholder: "Select date",
      rules: [
        {
          required: true,
          message: "Date is required!",
        },
      ],
    },
    {
      span: 12,
      component: "date",
      label: "Purchased Date",
      name: "purchased_date",
      required: true,
      placeholder: "Select purchased date",
      rules: [
        {
          required: true,
          message: "Purchased date is required!",
        },
      ],
    },
    {
      span: 12,
      component: "date",
      label: "Last Service Date",
      name: "last_service_date",
      required: true,
      placeholder: "Select last service date",
      rules: [
        {
          required: true,
          message: "Last service date is required!",
        },
      ],
    },
    {
      span: 12,
      component: "date",
      label: "Validation Date",
      name: "validation_date",
      required: true,
      placeholder: "Select validation date",
      rules: [
        {
          required: true,
          message: "Validation date is required!",
        },
      ],
    },
    {
      span: 12,
      component: "date",
      label: "Next maintainence scheduled Date",
      name: "due_date",
      required: true,
      placeholder: "Select due date",
      rules: [
        {
          required: true,
          message: "Next maintainence date is required!",
        },
      ],
    },
    {
      span: 24,
      component: "editor",
      label: "Remarks",
      name: "remarks",
      placeholder: "Enter remarks",
      rows: 5,
    },
    {
      span: 24,
      component: "editor",
      label: "Description",
      name: "description",
      placeholder: "Enter description",
      rows: 5,
    },
  ],
  actions: {
    onBack: () => navigate(`/quick-stocks/${config?.slugs?.plural}`),
    onFinish: async (values) => {
      try {
        const response = await axios.post(`/${config?.slugs?.plural}`, {
          data: {
            ...values,
            uid: v4(),
            // creator: auth?.user?.id,
          },
        });

        if (response?.data) {
          return {
            success: true,
            message: `Add new ${config?.entityNames?.singular?.toLowerCase()} successfully!`,
          };
        }

        throw new Error();
      } catch (error) {
        return {
          success: false,
          message: `Add new ${config?.entityNames?.singular?.toLowerCase()} failed!`,
        };
      }
    },
  },
});
