import React from "react";
import { Route } from "react-router-dom";
import {
  MaterialUnitList,
  MaterialUnitAdd,
  MaterialUnitEdit,
} from "../pages/MaterialUnits";

export const register = (props) => {
  const basePath = "material-units";

  return (
    <>
      <Route path={basePath} element={<MaterialUnitList />} exact={true} />
      <Route path={`${basePath}/add`} element={<MaterialUnitAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<MaterialUnitEdit />} />
    </>
  );
};
