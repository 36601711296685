import config from "../config";

export default {
  id: `${config?.slugs?.plural}-list`,
  pageTitle: config?.entityNames?.plural,
  breadcrumbs: [
    {
      path: "/quick-projects/dashboard",
      title: "Home",
    },
    {
      path: "",
      title: config?.entityNames?.plural,
    },
  ],
  columns: [
    // {
    //   title: "Employee",
    //   dataIndex: "employee",
    //   selectable: true,
    //   filterable: true,
    //   references: {
    //     fieldName: (record) => `${record?.first_name} ${record?.last_name}`,
    //   },
    // },
    {
      title: "Labour",
      dataIndex: "labour",
      selectable: true,
      sorter: (a, b) => a?.labour?.localeCompare(b?.labour),
    },
    {
      title: "Manpower Type",
      dataIndex: "manpower_type",
      selectable: true,
      references: {
        fieldName: "name",
      },
    },
    {
      title: "Project Location",
      dataIndex: "project_location",
      selectable: true,
      filterable: true,
      references: {
        fieldName: (record) =>
          `${record?.name} (${record?.location_type?.data?.attributes?.name})`,
      },
    },
  ],
  ...config,
};
