import React from "react";
import { Route } from "react-router-dom";
import { RoleList, RoleAdd, RoleEdit } from "../pages/Roles";

export const register = (props) => {
  const basePath = "app-roles";

  return (
    <>
      <Route path={basePath} element={<RoleList />} exact={true} />
      <Route path={`${basePath}/add`} element={<RoleAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<RoleEdit />} />
    </>
  );
};
