import React from "react";
import { Route } from "react-router-dom";
import {
  SubContractorDebitNoteList,
  SubContractorDebitNoteAdd,
  SubContractorDebitNoteCreatorEdit,
  SubContractorDebitNoteApproverEdit,
  SubContractorDebitNoteReport,
  SubContractorDebitNoteView,
} from "../pages/SubContractorDebitNotes";

export const register = (props) => {
  const basePath = "sub-contractor-debit-notes";

  return (
    <>
      <Route
        path={basePath}
        element={<SubContractorDebitNoteList />}
        exact={true}
      />
      <Route path={`${basePath}/add`} element={<SubContractorDebitNoteAdd />} />
      <Route
        path={`${basePath}/creator/edit/:id`}
        element={<SubContractorDebitNoteCreatorEdit />}
      />
      <Route
        path={`${basePath}/approver/edit/:id`}
        element={<SubContractorDebitNoteApproverEdit />}
      />
      <Route
        path={`${basePath}/view/:id`}
        element={<SubContractorDebitNoteView />}
      />
      <Route
        path={`${basePath}-report`}
        element={<SubContractorDebitNoteReport />}
      />
    </>
  );
};
