import React, { useState, useEffect } from "react";
import { Col, Row, Card, Breadcrumb } from "antd";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Image, Text, SimpleGrid, Center, Flex, Box } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import LanguageSelector from "../../components/LanguageSelector";

const MODULES = [
  {
    id: "quick-projects",
    name: "Quick Projects",
    image: "/images/modules/quick-projects.png",
  },
  {
    id: "quick-teams",
    name: "Quick Teams",
    image: "/images/modules/quick-teams.png",
  },
  {
    id: "quick-talks",
    name: "Quick Talks",
    image: "/images/modules/quick-talks.png",
  },
  {
    id: "quick-stocks",
    name: "Quick Stock",
    image: "/images/modules/quick-stocks.png",
  },
  {
    id: "quick-drive",
    name: "Quick Drive",
    image: "/images/modules/quick-drive.png",
  },
  {
    id: "quick-setup",
    name: "Quick Setup",
    image: "/images/modules/quick-setup.png",
  },
];

const Page = (props) => {
  const { t } = useTranslation();
  const { user } = useSelector((state) => state?.auth);
  const [roles, setRoles] = useState("");

  useEffect(() => {
    let newRoles = [];

    user?.app_roles?.forEach((appRole) => {
      appRole?.permissions?.forEach((appPermission) => {
        if (!newRoles?.includes(appPermission)) {
          newRoles?.push(appPermission);
        }
      });
    });

    setRoles(newRoles?.join(","));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Flex justifyContent="space-between" alignItems="center">
        <Box>
          <Breadcrumb style={styles.breadcrumbs}>
            <Breadcrumb.Item>
              <Link to="/">{t("Home")}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{t("Features")}</Breadcrumb.Item>
          </Breadcrumb>
        </Box>
        <Box>
          {/* <LanguageSelector /> */}
        </Box>
      </Flex>

      <SimpleGrid columns={[1, 3, 3, 6]} spacing={3}>
        {MODULES.map((item) => {
          if (roles?.includes(item?.id?.toUpperCase())) {
            return (
              <Col key={item?.id} className="module-card-column">
                <Link to={`/${item?.id}`}>
                  <Card bodyStyle={styles.card} className="module-card">
                    <Center>
                      <Image
                        src={item?.image}
                        alignSelf="center"
                        alt=""
                        w={20}
                        py={3}
                      />
                    </Center>
                    <Center>
                      <Text fontSize="16px" fontWeight="semi-bold">
                        {t(item?.name)}
                      </Text>
                    </Center>
                  </Card>
                </Link>
              </Col>
            );
          }
        })}
      </SimpleGrid>
    </div>
  );
};

const styles = {
  breadcrumbs: {
    marginBottom: 24,
  },
  card: {
    alignItems: "center",
  },
};

export default Page;
