import React, { useState, useRef, useEffect } from "react";
import {
  Empty,
  Button,
  Space,
  notification,
  Modal,
  Tooltip,
  Spin,
  Input,
} from "antd";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  CopyOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { axios } from "../../../../../../App";
import qs from "qs";
import EditTask from "../../../Tasks/Edit";
import AddTask from "../../../Tasks/Add";
import moment from "moment";
import { useSelector } from "react-redux";
import QuickSoGantt from "../QuickSoGantt";
import { fetchData } from "../../../../../../utils/helpers/data.helper";
import { MdDownload, MdUpload } from "react-icons/md";
import CsvImporter from "../CsvImporter";
import { Stack } from "@chakra-ui/react";
import csvDownload from "json-to-csv-export";

// TaskMaterial
import ListTaskMaterial from "../../../TaskMaterials/List";
import AddTaskMaterial from "../../../TaskMaterials/Add";
import EditTaskMaterial from "../../../TaskMaterials/CreatorEdit";
import ViewTaskMaterial from "../../../TaskMaterials/View";

// TaskManpower
import ListTaskManpower from "../../../TaskManpowers/List";
import AddTaskManpower from "../../../TaskManpowers/Add";
import EditTaskManpower from "../../../TaskManpowers/Edit";

// TaskMachine
import ListTaskMachine from "../../../TaskMachines/List";
import AddTaskMachine from "../../../TaskMachines/Add";
import EditTaskMachine from "../../../TaskMachines/Edit";

// TaskHandTool
import ListTaskHandTool from "../../../TaskHandTools/List";
import AddTaskHandTool from "../../../TaskHandTools/Add";
import EditTaskHandTool from "../../../TaskHandTools/Edit";

// TaskDocument
import ListTaskDocument from "../../../TaskDocuments/List";
import AddTaskDocument from "../../../TaskDocuments/Add";
import EditTaskDocument from "../../../TaskDocuments/Edit";

// TaskComment
import ListTaskComment from "../../../TaskComments/List";
import AddTaskComment from "../../../TaskComments/Add";
import EditTaskComment from "../../../TaskComments/Edit";

import ContentModal from "./ContentModal";

const App = (props) => {
  const { user } = useSelector((state) => state?.auth);
  const [showAdd, setShowAdd] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [showToolbar, setShowToolbar] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [loading, setLoading] = useState(true);
  const [allTasks, setAllTasks] = useState([]);
  const [hasData, setHasData] = useState(false);
  const [activeTask, setActiveTask] = useState({});
  const [miscData, setMiscData] = useState({});
  const [searchValues, setSearchValues] = useState({});
  const [searched, setSearched] = useState(false);
  const [total, setTotal] = useState(0);
  const [activeTaskId, setActiveTaskId] = useState(-1);
  const [importData, setImportData] = useState([]);
  const [importing, setImporting] = useState(false);
  const [expandedIds, setExpandedIds] = useState({});

  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showTaskMaterials, setShowTaskMaterials] = useState(false);
  const [showTaskManpowers, setShowTaskManpowers] = useState(false);
  const [showTaskMachines, setShowTaskMachines] = useState(false);
  const [showTaskHandTools, setShowTaskHandTools] = useState(false);
  const [showTaskDocuments, setShowTaskDocuments] = useState(false);
  const [showTaskComments, setShowTaskComments] = useState(false);
  const [showImport, setShowImport] = useState(false);

  const [project_data, setproject_data] = useState(null)

  const ref = useRef();
  const contentTypes = [
    {
      slug: "task-materials",
      query: {
        populate: "*",
      },
      transformer: (item) => ({
        ...item?.attributes,
        id: item?.id,
      }),
      onSuccess: (data) =>
        setMiscData((prev) => ({
          ...prev,
          task_materials: data,
        })),
    },
    {
      slug: "task-manpowers",
      query: {
        populate: "*",
      },
      transformer: (item) => ({
        ...item?.attributes,
        id: item?.id,
      }),
      onSuccess: (data) =>
        setMiscData((prev) => ({
          ...prev,
          task_manpowers: data,
        })),
    },
    {
      slug: "task-machines",
      query: {
        populate: "*",
      },
      transformer: (item) => ({
        ...item?.attributes,
        id: item?.id,
      }),
      onSuccess: (data) =>
        setMiscData((prev) => ({
          ...prev,
          task_machines: data,
        })),
    },
    {
      slug: "task-hand-tools",
      query: {
        populate: "*",
      },
      transformer: (item) => ({
        ...item?.attributes,
        id: item?.id,
      }),
      onSuccess: (data) =>
        setMiscData((prev) => ({
          ...prev,
          task_hand_tools: data,
        })),
    },
    {
      slug: "task-documents",
      query: {
        populate: "*",
      },
      transformer: (item) => ({
        ...item?.attributes,
        id: item?.id,
      }),
      onSuccess: (data) =>
        setMiscData((prev) => ({
          ...prev,
          task_documents: data,
        })),
    },
  ];

  const getFiltersForRoles = (type) => {
    if (user?.is_admin) {
      return {};
    }

    if (type === "project") {
      return {
        $or: [
          {
            project_managers: {
              uid: user?.uid,
            },
          },
          {
            members: {
              uid: user?.uid,
            },
          },
        ],
      };
    }

    return {
      assignees: {
        uid: user?.uid,
      },
    };
  };

  const fetchTasks = async (filterParams = null) => {
    try {
      setLoading(true);

      const queryObject = {
        populate: "*",
        filters: getFiltersForRoles("task"),
        pagination: {
          limit: 999,
        },
      };

      if (filterParams) {
        queryObject["filters"] = {
          $and: [
            {
              project: props?.projectId,
            },
            getFiltersForRoles("task"),
            filterParams,
          ],
        };
      }

      const response = await axios.get(`/tasks?${qs.stringify(queryObject)}`);

      if (response?.data) {
        let newTasks = [];
        let items = {};
        let newExpandedIds = {};

        response?.data?.data?.forEach((item) => {
          if (item?.attributes?.type === "Milestone") {
            items[item?.id] = {
              ...item?.attributes,
              startDateTimestamp: moment(
                item?.attributes?.start_date
              )?.valueOf(),
              id: item?.id,
              children: [],
              milestone_id: "",
            };

            newExpandedIds[item?.id] = true;
          }
        });

        Object.keys(items).forEach((milestoneId) => {
          items[milestoneId] = {
            ...items?.[milestoneId],
            children: response?.data?.data
              ?.filter(
                (item) =>
                  item?.attributes?.type === "Task" &&
                  Number(item?.attributes?.milestone?.data?.id) ===
                  Number(milestoneId)
              )
              ?.map((item) => ({
                ...item?.attributes,
                id: item?.id,
                milestone_id: milestoneId,
              })),
          };
        });

        Object.keys(items)?.forEach((key) => {
          newTasks?.push(items?.[key]);

          if (items?.[key]?.children?.length > 0) {
            items?.[key]?.children?.forEach((child) => {
              newTasks?.push(child);
            });
          }
        });

        // setTasks(newTasks?.sort((a, b) => a?.id - b?.id));
        setTasks(newTasks);
        setExpandedIds(newExpandedIds);
        // END TODO

        setAllTasks(response.data?.data);
        setHasData(true);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchProject = async () => {
    try {
      const resp = await axios.get(`/projects/${props?.projectId}?populate=*`);

      if (resp?.data) {
        setproject_data(resp.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const toggleAdd = () => {
    setShowAdd((prev) => !prev);
  };

  const toggleEdit = () => {
    setShowEdit((prev) => !prev);
  };

  const toggleDelete = () => {
    setShowDelete((prev) => !prev);
  };

  const toggleTaskMaterials = () => setShowTaskMaterials((prev) => !prev);

  const toggleTaskManpowers = () => setShowTaskManpowers((prev) => !prev);

  const toggleTaskMachines = () => setShowTaskMachines((prev) => !prev);

  const toggleTaskHandTools = () => setShowTaskHandTools((prev) => !prev);

  const toggleTaskDocuments = () => setShowTaskDocuments((prev) => !prev);

  const toggleTaskComments = () => setShowTaskComments((prev) => !prev);

  const toggleImport = () => setShowImport((prev) => !prev);

  const toggleExpandedId = (id) => {
    setExpandedIds((prev) => ({
      ...prev,
      [id]: !expandedIds?.[id],
    }));
  };

  const onDuplicateTask = async () => {
    try {
      let dataObject = {
        ...activeTask?.attributes,
        type: "Task",
        name: `${activeTask?.attributes.name} Copy`,
        project: activeTask?.attributes?.project?.data?.id,
        start_date: moment(activeTask?.attributes?.start_date)?.add(1, "days"),
        end_date: moment(activeTask?.attributes?.end_date)?.add(1, "days"),
        machines: activeTask?.attributes?.machines?.data?.map(
          (item) => item?.id
        ),
        hand_tools: activeTask?.attributes?.hand_tools?.data?.map(
          (item) => item?.id
        ),
        dependencies: activeTask?.attributes?.dependencies?.data?.map(
          (item) => item?.id
        ),
        assignees: activeTask?.attributes?.assignees?.data?.map(
          (item) => item?.id
        ),
      };

      Object.keys(dataObject)?.forEach((key) => {
        if (
          !dataObject?.[key] ||
          (Array.isArray(dataObject?.[key]) &&
            dataObject?.[key]?.length === 0) ||
          dataObject?.[key]?.data === null
        ) {
          delete dataObject[key];
        }
      });

      const response = await axios.post(`/tasks`, {
        data: dataObject,
      });

      if (response?.data) {
        notification["success"]({
          message: `Duplicate Task`,
          description: `Duplicate task successfully!`,
        });

        setActiveTask({});
        onRefresh();
      } else {
        throw new Error();
      }
    } catch (error) {
      notification["error"]({
        message: `Duplicate Task`,
        description: `Duplicate task failed!`,
      });
    }
  };

  const onRemoveTask = (item) => {
    Modal.confirm({
      title: `Remove Task`,
      content: (
        <span>
          Are you sure to remove the task <strong>{item?.name}</strong>?
        </span>
      ),
      async onOk() {
        try {
          const response = await axios.delete(`tasks/${item?.id}`);

          if (response?.data) {
            notification["success"]({
              message: `Remove Task`,
              description: `Remove the task successfully!`,
            });

            onRefresh();
          }
        } catch (error) {
          notification["error"]({
            message: `Remove Task`,
            description: `Failed to remove the task!`,
          });
        }
      },
    });
  };

  const onFinishEdit = () => {
    setShowEdit(false);
    onRefresh();
  };

  const handleEdit = () => {
    setShowEdit(true);
  };

  const onRefresh = () => {
    fetchMiscData();

    fetchTasks({
      project: props?.projectId,
    });
  };

  const onFinishAdd = () => {
    setShowAdd(false);
    onRefresh();
  };

  const onReset = () => {
    setActiveTaskId(-1);
    setSearchValues({});
    onRefresh();
  };

  const onSearch = () => {
    const searchObject = {
      project: props?.projectId,
    };

    if (searchValues?.keyword !== "") {
      searchObject["name"] = {
        $contains: searchValues?.keyword,
      };
    }

    setActiveTaskId(-1);
    fetchTasks(searchObject);
  };

  const onMenuClick = (key, item) => {
    // eslint-disable-next-line default-case
    switch (key) {
      case "edit":
        setActiveTask(item);
        toggleEdit();
        break;

      case "materials":
        toggleTaskMaterials();
        break;

      case "manpowers":
        toggleTaskManpowers();
        break;

      case "machines":
        toggleTaskMachines();
        break;

      case "hand_tools":
        toggleTaskHandTools();
        break;

      case "documents":
        toggleTaskDocuments();
        break;

      case "comments":
        toggleTaskComments();
        break;

      case "remove":
        onRemoveTask(item);
        break;
    }
  };

  const fetchMiscData = () => {
    contentTypes?.forEach((contentType) => {
      fetchData(contentType);
    });
  };

  const fetchTaskProgresses = async () => {
    try {
      const resp = await axios.get(`/gantt/projects/${props?.projectId}`);

      if (resp?.data) {
        setMiscData((prev) => ({
          ...prev,
          task_progresses: resp?.data?.data?.tasks,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onImportData = async () => {
    try {
      setImporting(true);

      const resp = await axios.post(`/data-importer/gantt-tasks`, {
        data: {
          project_id: +props?.projectId,
          data: importData,
        },
      });

      if (resp?.data?.success) {
        notification["success"]({
          message: `Import Tasks`,
          description: `Import tasks successfully!`,
        });
      } else {
        throw new Error();
      }
    } catch (error) {
      console.log(error);

      notification["error"]({
        message: `Import Tasks`,
        description: `Import tasks failed!`,
      });
    } finally {
      setImporting(false);
      toggleImport();
      onRefresh();
    }
  };

  const onExportData = async () => {
    try {
      const resp = await axios.get(`/data-exporter/gantt/${props?.projectId}`);

      if (resp?.data?.success) {
        const dataToConvert = {
          data: resp?.data?.data,
          filename: "gantt-chart-data",
          delimiter: ",",
          headers: Object.keys(resp?.data?.data?.[0]),
        };

        csvDownload(dataToConvert);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (props?.projectId) {
      fetchMiscData();
      fetchTaskProgresses();

      fetchTasks({
        project: props?.projectId,
      });

      fetchProject()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.projectId]);

  const ganttChartPermission = (record) => {


    console.log(record)
    const canUpdateGanttChart = record?.can_update_gantt_chart?.data?.map(
      (item) => item?.attributes?.uid
    );

    const projectManagerUids = record?.project_managers?.data?.map(
      (item) => item?.attributes?.uid
    );
    const memberUids = record?.members?.data?.map(
      (item) => item?.attributes?.uid
    );

    if (user?.is_admin) {
      return true;
    }

    if (user?.uid === record?.creator?.data?.attributes?.uid) {
      return true;
    }

    if (canUpdateGanttChart?.includes(user?.uid)) {
      return true;
    }

    if (projectManagerUids?.includes(user?.uid)) {
      return true;
    }

    if (memberUids?.includes(user?.uid)) {
      return true;
    }

    return false;

  }

  const gantt_update_permissions = ganttChartPermission(project_data?.data?.attributes)

  console.log('gjnofsiuhgofshg', project_data, gantt_update_permissions, user)

  return (
    <div>
      <Stack direction={{ base: "column", md: "row" }} spacing={3}>
        <div style={styles.leftToolbar}>
          <Input
            value={searchValues?.keyword}
            placeholder="Search for keyword..."
            onChange={(e) =>
              setSearchValues((prev) => ({
                ...prev,
                keyword: e.target.value,
              }))
            }
          />

          {/* <DatePicker
            onChange={(value) =>
              setSearchValues((prev) => ({
                ...prev,
                start_date: value,
              }))
            }
            value={searchValues?.start_date}
            placeholder="Select start date"
            style={styles.datePicker}
          /> */}

          {/* <DatePicker
            onChange={(value) =>
              setSearchValues((prev) => ({
                ...prev,
                end_date: value,
              }))
            }
            value={searchValues?.endDate}
            placeholder="Select end date"
            style={styles.datePicker}
          /> */}
        </div>

        {/* <div className="toolbar-buttons">
            <p className="toolbar-results">{getResultsMessage()}</p>

            <Space>
              <Button>Reset</Button>
              <Button type="primary">Search</Button>
            </Space>
          </div> */}
        <div style={styles.div}>
          <Space>
            {gantt_update_permissions && (
              <Tooltip placement="top" title="Add new item">
                <Button icon={<PlusOutlined />} onClick={toggleAdd} />
              </Tooltip>
            )}

            <Tooltip placement="top" title="Import Data">
              <Button
                icon={<MdUpload style={{ margin: "0 auto" }} />}
                onClick={toggleImport}
              />
            </Tooltip>

            <Tooltip placement="top" title="Export Data">
              <Button
                icon={<MdDownload style={{ margin: "0 auto" }} />}
                onClick={onExportData}
              />
            </Tooltip>

            <Tooltip placement="top" title="Refresh">
              <Button
                icon={<ReloadOutlined />}
                onClick={onRefresh}
                loading={loading}
              />
            </Tooltip>

            <Tooltip placement="top" title="Reset">
              <Button onClick={onReset}>Reset</Button>
            </Tooltip>

            <Tooltip placement="top" title="Search">
              <Button type="primary" onClick={onSearch}>
                Search
              </Button>
            </Tooltip>
          </Space>
        </div>
      </Stack>
      {loading && (
        <div style={styles.loaderContainer}>
          <Spin />
        </div>
      )}
      {!loading && !hasData && <Empty />}
      {!loading && hasData && (
        <QuickSoGantt
          tasks={tasks}
          miscData={miscData}
          onMenuClick={onMenuClick}
          activeTaskId={activeTaskId}
          setActiveTaskId={setActiveTaskId}
          toggleExpandedId={toggleExpandedId}
          expandedIds={expandedIds}
          gantt_update_permissions={gantt_update_permissions}
        />
      )}
      <Modal
        title="Add New Task"
        visible={showAdd}
        onOk={onFinishAdd}
        onCancel={toggleAdd}
        width={1000}
        footer={null}
        destroyOnClose={true}
      >
        <AddTask
          {...props}
          mode="embed"
          onEmbedCancel={toggleAdd}
          onEmbedFinish={onFinishAdd}
          data={{
            project: +props?.projectId,
          }}
          disabledItems={["project"]}
        />
      </Modal>

      <ContentModal
        visible={showTaskMaterials}
        initialPage="list"
        pages={{
          list: ListTaskMaterial,
          add: AddTaskMaterial,
          edit: EditTaskMaterial,
          view: ViewTaskMaterial,
        }}
        title={{
          list: "Task Materials",
          add: "Add Task Material",
          edit: "Edit Task Material",
          view: "View Task Material",
        }}
        actions={({ setPageId, setQuery }) => {
          return {
            list: {
              onAdd: () => setPageId("add"),
              onEdit: (item) => {
                setPageId("edit");
                setQuery({
                  entryId: item?.id,
                });
              },
              onView: (item) => {
                setPageId("view");
                setQuery({
                  entryId: item?.id,
                });
              },
            },
            add: {
              onCancel: () => setPageId("list"),
            },
            edit: {
              onCancel: () => setPageId("list"),
            },
            view: {
              onCancel: () => setPageId("list"),
            },
          };
        }}
        data={{
          add: {
            task: activeTaskId,
          },
          edit: {
            task: activeTaskId,
          },
        }}
        disabledItems={{
          add: ["task"],
          edit: ["task"],
        }}
        queries={{
          list: {
            taskId: activeTaskId,
          },
          view: {
            taskId: activeTaskId,
          },
          edit: {
            taskId: activeTaskId,
          },
        }}
        onCancel={() => {
          toggleTaskMaterials();
          onRefresh();
        }}
      />

      <ContentModal
        visible={showTaskManpowers}
        initialPage="list"
        pages={{
          list: ListTaskManpower,
          add: AddTaskManpower,
          edit: EditTaskManpower,
          // view: ViewTaskManpower,
        }}
        title={{
          list: "Task Manpowers",
          add: "Add Task Manpower",
          edit: "Edit Task Manpower",
          // view: "View Task Manpower",
        }}
        actions={({ setPageId, setQuery }) => {
          return {
            list: {
              onAdd: () => setPageId("add"),
              onEdit: (item) => {
                setPageId("edit");
                setQuery({
                  entryId: item?.id,
                });
              },
              // onView: () => setPageId("view"),
            },
            add: {
              onCancel: () => setPageId("list"),
            },
            edit: {
              onCancel: () => setPageId("list"),
            },
            // view: {
            //   onCancel: () => setPageId("list"),
            // },
          };
        }}
        data={{
          add: {
            task: activeTaskId,
          },
          edit: {
            task: activeTaskId,
          },
        }}
        disabledItems={{
          add: ["task"],
          edit: ["task"],
        }}
        queries={{
          list: {
            taskId: activeTaskId,
          },
          // view: {
          //   taskId: activeTaskId,
          // },
          edit: {
            taskId: activeTaskId,
          },
        }}
        onCancel={() => {
          toggleTaskManpowers();
          onRefresh();
        }}
      />

      <ContentModal
        visible={showTaskMachines}
        initialPage="list"
        pages={{
          list: ListTaskMachine,
          add: AddTaskMachine,
          edit: EditTaskMachine,
          // view: ViewTaskMachine,
        }}
        title={{
          list: "Task Machines",
          add: "Add Task Machine",
          edit: "Edit Task Machine",
          // view: "View Task Machine",
        }}
        actions={({ setPageId, setQuery }) => {
          return {
            list: {
              onAdd: () => setPageId("add"),
              onEdit: (item) => {
                setPageId("edit");
                setQuery({
                  entryId: item?.id,
                });
              },
              // onView: () => setPageId("view"),
            },
            add: {
              onCancel: () => setPageId("list"),
            },
            edit: {
              onCancel: () => setPageId("list"),
            },
            // view: {
            //   onCancel: () => setPageId("list"),
            // },
          };
        }}
        data={{
          add: {
            task: activeTaskId,
          },
          edit: {
            task: activeTaskId,
          },
        }}
        disabledItems={{
          add: ["task"],
          edit: ["task"],
        }}
        queries={{
          list: {
            taskId: activeTaskId,
          },
          // view: {
          //   taskId: activeTaskId,
          // },
          edit: {
            taskId: activeTaskId,
          },
        }}
        onCancel={() => {
          toggleTaskMachines();
          onRefresh();
        }}
      />

      <ContentModal
        visible={showTaskHandTools}
        initialPage="list"
        pages={{
          list: ListTaskHandTool,
          add: AddTaskHandTool,
          edit: EditTaskHandTool,
          // view: ViewTaskHandTool,
        }}
        title={{
          list: "Task Hand Tools",
          add: "Add Task Hand Tool",
          edit: "Edit Task Hand Tool",
          // view: "View Task Hand Tool",
        }}
        actions={({ setPageId, setQuery }) => {
          return {
            list: {
              onAdd: () => setPageId("add"),
              onEdit: (item) => {
                setPageId("edit");
                setQuery({
                  entryId: item?.id,
                });
              },
              // onView: () => setPageId("view"),
            },
            add: {
              onCancel: () => setPageId("list"),
            },
            edit: {
              onCancel: () => setPageId("list"),
            },
            // view: {
            //   onCancel: () => setPageId("list"),
            // },
          };
        }}
        data={{
          add: {
            task: activeTaskId,
          },
          edit: {
            task: activeTaskId,
          },
        }}
        disabledItems={{
          add: ["task"],
          edit: ["task"],
        }}
        queries={{
          list: {
            taskId: activeTaskId,
          },
          // view: {
          //   taskId: activeTaskId,
          // },
          edit: {
            taskId: activeTaskId,
          },
        }}
        onCancel={() => {
          toggleTaskHandTools();
          onRefresh();
        }}
      />

      <ContentModal
        visible={showTaskDocuments}
        initialPage="list"
        pages={{
          list: ListTaskDocument,
          add: AddTaskDocument,
          edit: EditTaskDocument,
          // view: ViewTaskDocument,
        }}
        title={{
          list: "Task Documents",
          add: "Add Task Document",
          edit: "Edit Task Document",
          // view: "View Task Document",
        }}
        actions={({ setPageId, setQuery }) => {
          return {
            list: {
              onAdd: () => setPageId("add"),
              onEdit: (item) => {
                setPageId("edit");
                setQuery({
                  entryId: item?.id,
                });
              },
              // onView: () => setPageId("view"),
            },
            add: {
              onCancel: () => setPageId("list"),
            },
            edit: {
              onCancel: () => setPageId("list"),
            },
            // view: {
            //   onCancel: () => setPageId("list"),
            // },
          };
        }}
        data={{
          add: {
            task: activeTaskId,
          },
          edit: {
            task: activeTaskId,
          },
        }}
        disabledItems={{
          add: ["task"],
          edit: ["task"],
        }}
        queries={{
          list: {
            taskId: activeTaskId,
          },
          // view: {
          //   taskId: activeTaskId,
          // },
          edit: {
            taskId: activeTaskId,
          },
        }}
        onCancel={() => {
          toggleTaskDocuments();
          onRefresh();
        }}
      />

      <ContentModal
        visible={showTaskComments}
        initialPage="list"
        pages={{
          list: ListTaskComment,
          add: AddTaskComment,
          edit: EditTaskComment,
          // view: ViewTaskComment,
        }}
        title={{
          list: "Task Comments",
          add: "Add Task Comment",
          edit: "Edit Task Comment",
          // view: "View Task Comment",
        }}
        actions={({ setPageId, setQuery }) => {
          return {
            list: {
              onAdd: () => setPageId("add"),
              onEdit: (item) => {
                setPageId("edit");
                setQuery({
                  entryId: item?.id,
                });
              },
              // onView: () => setPageId("view"),
            },
            add: {
              onCancel: () => setPageId("list"),
            },
            edit: {
              onCancel: () => setPageId("list"),
            },
            // view: {
            //   onCancel: () => setPageId("list"),
            // },
          };
        }}
        data={{
          add: {
            task: activeTaskId,
          },
          edit: {
            task: activeTaskId,
          },
        }}
        disabledItems={{
          add: ["task"],
          edit: ["task"],
        }}
        queries={{
          list: {
            taskId: activeTaskId,
          },
          // view: {
          //   taskId: activeTaskId,
          // },
          edit: {
            taskId: activeTaskId,
          },
        }}
        onCancel={() => {
          toggleTaskComments();
          onRefresh();
        }}
      />

      <Modal
        title="Edit Task"
        visible={showEdit}
        onOk={onFinishEdit}
        onCancel={toggleEdit}
        width={1000}
        footer={null}
        destroyOnClose={true}
      >
        <EditTask
          {...props}
          mode="embed"
          embedId={activeTask?.id}
          onEmbedCancel={toggleEdit}
          onEmbedFinish={onFinishEdit}
          disabledItems={["project"]}
        />
      </Modal>

      <Modal
        title="Delete Task"
        visible={showDelete}
        onOk={onRemoveTask}
        onCancel={toggleDelete}
        destroyOnClose={true}
      >
        <p>
          Are you sure to delete the task{" "}
          <strong>{activeTask?.attributes?.name}</strong>?
        </p>
      </Modal>

      <Modal
        title="Import Data"
        visible={showImport}
        // onOk={onFinishEdit}
        onCancel={toggleImport}
        // width={1000}
        footer={[
          <Button onClick={toggleImport}>Cancel</Button>,
          <Button type="primary" onClick={onImportData}>
            Import
          </Button>,
        ]}
        destroyOnClose={true}
      >
        {importing && (
          <div style={styles.loaderContainer}>
            <Spin />
          </div>
        )}

        {!importing && (
          <CsvImporter projectId={+props?.projectId} onChange={setImportData} />
        )}
      </Modal>
    </div>
  );
};

const styles = {
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 24,
  },
  loaderContainer: {
    padding: 24,
    textAlign: "center",
  },
  div: {
    marginBottom: 20,
  },
  leftToolbar: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridGap: 14,
    width: "100%",
    marginRight: 12,
  },
  datePicker: {
    width: "100%",
  },
};

export default App;
