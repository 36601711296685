export default {
  slugs: {
    singular: "task-hand-tool",
    plural: "task-hand-tools",
  },
  entityNames: {
    singular: "Task Hand Tool",
    plural: "Task Hand Tools",
  },
};
