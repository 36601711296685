export default {
  slugs: {
    singular: "gst-type",
    plural: "gst-types",
  },
  entityNames: {
    singular: "GST Type",
    plural: "GST Types",
  },
};
