export default {
  slugs: {
    singular: "work-unit",
    plural: "work-units",
  },
  entityNames: {
    singular: "Work Unit",
    plural: "Work Units",
  },
};
