import React from "react";
import { Route } from "react-router-dom";
import {
  RosterList,
  RosterAdd,
  RosterEdit,
  RosterReport,
} from "../pages/Rosters";

export const register = (props) => {
  const basePath = "rosters";

  return (
    <>
      <Route path={basePath} element={<RosterList />} exact={true} />
      <Route path={`${basePath}/add`} element={<RosterAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<RosterEdit />} />
      <Route path={`${basePath}-report`} element={<RosterReport />} />
    </>
  );
};
