export default {
  slugs: {
    singular: "project",
    plural: "projects",
  },
  entityNames: {
    singular: "Project",
    plural: "Projects",
  },
};
