import React, { useEffect, useRef, useState } from "react";
import { ReloadOutlined, PlusOutlined, DownloadOutlined } from "@ant-design/icons";
import { Table, Space, Button, PageHeader, Breadcrumb, Tooltip, Select, Modal } from "antd";
import ColumnSelector from "../ColumnSelector";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import _ from "lodash";
import html2canvas from "html2canvas";
import { axios } from "../../../../App";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { ImFilePdf } from "react-icons/im";

const { Option } = Select;


const DataTable = ({
  data,
  config,
  loading,
  pagination,
  actions,
  buttons = {},
  onBack = null,
  Toolbar = null,
  projects_data = null,
  projects_column = null,
  showsBreadcrumbs = true,
}) => {
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [letterheadFileName, setLetterheadFileName] = useState("");
  const [showsModal, setShowsModal] = useState(false);
  const [letterheads, setLetterheads] = useState([]);
  const tableRef = useRef(null);

  const selectColumn = (dataIndex) => {
    if (selectedColumns?.indexOf(dataIndex) > -1) {
      setSelectedColumns((prev) => prev?.filter((item) => item !== dataIndex));
    } else {
      setSelectedColumns((prev) => prev?.concat(dataIndex));
    }
  };

  const fetchLetterheads = async () => {
    try {
      const resp = await axios.get(`/letterheads?populate=*`);

      if (resp?.data?.data?.length > 0) {
        const results = resp?.data?.data?.map((record, index) => {
          let fileName =
            record?.attributes?.image_file?.data?.attributes?.url?.split(
              "/"
            )[2] || "";

          if (index === 0) {
            setLetterheadFileName(fileName);
          }

          return {
            id: record?.id,
            name: record?.attributes?.name,
            file_name: fileName,
          };
        });

        setLetterheads(results);
      }
    } catch (error) { }
  };

  const downloadPDF = async () => {
    const doc = new jsPDF();
    // doc.setFontSize(18);
    // doc.text(config?.entityNames?.plural?.toUpperCase(), 14, 40);
    // doc.setFontSize(11);
    // doc.text(`List of all ${config?.entityNames?.plural?.toLowerCase()}`, 14, 46);
    // doc.setTextColor(100);

    // Capture the bar graph as an image
    let barImgData = null;
    let pieImgData = null;
    let pieImgData2 = null;
    const barGraph = document.getElementById('barGraph');
    const pieChart = document.getElementById('pieChart');
    const pieChart2 = document.getElementById('pieChart2');

    if (barGraph) {
      const canvas = await html2canvas(barGraph);
      barImgData = canvas.toDataURL('image/png');
    }

    if (pieChart) {
      const canvas = await html2canvas(pieChart);
      pieImgData = canvas.toDataURL('image/png');
    }

    if (pieChart2) {
      const canvas = await html2canvas(pieChart2);
      pieImgData2 = canvas.toDataURL('image/png');
    }

    // Fetch header image
    const headerResp = await axios.get(`/utils/image-to-base64?file_name=${letterheadFileName}`);

    let finalY = 60; // Starting y-coordinate after the header and title

    // Filter out the column with the title "Actions"
    const filteredColumns = config?.columns?.filter((column) => column?.title !== 'Actions');

    autoTable(doc, {
      head: [filteredColumns?.map((column) => column?.title)],
      body: data?.map((record) => {
        let results = [];

        filteredColumns?.forEach((column) => {
          let value = record?.[column?.dataIndex];

          if (column?.references) {
            if (typeof column?.references?.fieldName === 'function') {
              const transformer = column?.references?.fieldName;
              value = transformer(record) || 'N/A';
            } else {
              value = record?.[column?.dataIndex]?.data?.attributes?.[column?.references?.fieldName] || 'N/A';
            }
          }

          results.push(value);
        });

        return results;
      }),
      didDrawPage: function (data) {
        if (headerResp?.data?.success) {
          doc.addImage(
            headerResp?.data?.data,
            'JPEG',
            0,
            0,
            doc.internal.pageSize.width,
            30
          );
        }
        finalY = data.cursor.y + 20; // Adjust finalY based on where autoTable ends
      },
      margin: {
        top: 40,
      },
      // styles: {
      //   fillColor: [169, 169, 169], // Gray background color for the table headings
      // },
      headStyles: {
        fillColor: [169, 169, 169], // Gray background color for the table headings
      },
    });

    // Function to draw image without border in the center of the page
    const drawImageWithoutBorder = (doc, imgData, x, y, maxWidth, title) => {
      const imgProps = doc.getImageProperties(imgData);
      const imgWidth = imgProps.width;
      const imgHeight = imgProps.height;
      const aspectRatio = imgWidth / imgHeight;

      const finalWidth = maxWidth;
      const finalHeight = finalWidth / aspectRatio;

      // Draw image
      doc.addImage(imgData, 'PNG', x, y, finalWidth, finalHeight);

      return finalHeight; // Return the total height used by the image
    };

    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const margin = 20; // Margin from the sides

    // Calculate the available width for images
    const availableWidth = pageWidth - margin * 2;

    // Ensure there's enough space between autoTable content and charts
    finalY = Math.max(finalY, 60);

    // Draw bar graph image
    if (barImgData) {
      finalY += drawImageWithoutBorder(doc, barImgData, margin, finalY, availableWidth, '');
    }

    // Add some space between the charts
    finalY += 20;


    // Draw pie chart image without border
    if (pieImgData) {
      doc.setFontSize(12);
      doc.text(config?.pie_title, margin, finalY);
      finalY += 5; // Adjust finalY to add space between the title and the table

      finalY += drawImageWithoutBorder(doc, pieImgData, margin, finalY, availableWidth, '');
    }

    if (pieImgData2) {
      doc.setFontSize(12);
      doc.text(config?.pie_title, margin, finalY);
      finalY += 5; // Adjust finalY to add space between the title and the table

      finalY += drawImageWithoutBorder(doc, pieImgData2, margin, finalY, availableWidth, '');
    }

    // Add projects table if there is project data
    if (projects_data && projects_data.length > 0) {
      finalY += 20; // Add some space before the new table

      // Draw the project title
      doc.setFontSize(12);
      doc.text(config?.project_title, margin, finalY);
      finalY += 10; // Adjust finalY to add space between the title and the table

      autoTable(doc, {
        head: [projects_column?.map((column) => column?.title)],
        body: projects_data?.map((record) => {
          let results = [];

          projects_column?.forEach((column) => {
            let value = record?.[column?.dataIndex];

            if (column?.references) {
              if (typeof column?.references?.fieldName === 'function') {
                const transformer = column?.references?.fieldName;
                value = transformer(record) || 'N/A';
              } else {
                value = record?.[column?.dataIndex]?.data?.attributes?.[column?.references?.fieldName] || 'N/A';
              }
            }

            results.push(value);
          });

          return results;
        }),
        startY: finalY,
        margin: {
          top: 10,
        },
        // styles: {
        //   fillColor: [169, 169, 169], // Gray background color for the table headings
        // },
        headStyles: {
          fillColor: [169, 169, 169], // Gray background color for the table headings
        },
      });
    }

    doc.save(`${config?.slugs?.plural}.pdf`);
  };

  const onSubmit = () => {
    setShowsModal(false);
    downloadPDF();
  };

  const renderButtons = () => {
    if (!_.isEmpty(buttons)) {
      return buttons;
    } else {
      return [
        <Tooltip placement="top" title="Add new item">
          <Button icon={<PlusOutlined />} onClick={actions?.onAdd} />
        </Tooltip>,
        <Tooltip placement="top" title="Refresh">
          <Button
            icon={<ReloadOutlined />}
            onClick={actions?.onRefresh}
            loading={loading}
          />
        </Tooltip>,
      ];
    }
  };

  useEffect(() => {
    fetchLetterheads();

    setSelectedColumns(
      config?.columns
        ?.filter((column) => column?.selectable)
        ?.map((column) => column?.dataIndex)
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>{config?.pageTitle}</title>
      </Helmet>

      {showsBreadcrumbs && (
        <Breadcrumb style={styles.breadcrumbs}>
          {config?.breadcrumbs?.map((breadcrumb, index) => (
            <Breadcrumb.Item key={index}>
              <Link to={breadcrumb?.path}>{breadcrumb?.title}</Link>
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>
      )}

      <PageHeader
        title={config?.pageTitle}
        extra={[
          <Space>
            {renderButtons()}

            <Tooltip placement="top" title="Download PDF">
              <Button
                icon={<ImFilePdf style={{ margin: "0 auto" }} />}
                onClick={() => setShowsModal(true)}
              />
            </Tooltip>

            <DownloadTableExcel
              filename="report"
              sheet="report"
              currentTableRef={tableRef.current}
            >
              <Tooltip placement="top" title="Download Excel">
                <Button icon={<DownloadOutlined />}>
                </Button>
              </Tooltip>
            </DownloadTableExcel>

            <ColumnSelector
              value={selectedColumns}
              columns={config?.columns?.filter((column) => column?.selectable)}
              onSelect={selectColumn}
            />
          </Space>,
        ]}
        onBack={onBack}
        style={styles.pageHeader}
      ></PageHeader>

      {Toolbar}

      {selectedColumns?.length > 0 && (
        <Table
          style={{ overflow: "auto" }}
          columns={config?.columns?.filter(
            (column) =>
              column?.title === "Actions" ||
              selectedColumns?.indexOf(column?.dataIndex) > -1
          )}
          rowKey={(record) => record.id}
          dataSource={data}
          pagination={pagination}
          loading={loading}
          // onChange={handleTableChange}
        />
      )}

<Modal
        title="Print PDF"
        visible={showsModal}
        onOk={onSubmit}
        onCancel={() => setShowsModal(false)}
        destroyOnClose
      // footer={null} // Removing the footer of the modal
      >
        <label style={{ ...styles.label, marginTop: 0 }}>Letterhead</label>
        <Select
          value={letterheadFileName}
          onChange={setLetterheadFileName}
          style={styles.select}
        >
          {letterheads?.map((letterhead, index) => (
            <Option key={index} value={letterhead?.file_name}>
              {letterhead?.name}
            </Option>
          ))}
        </Select>

        <label style={styles.label}>Preview</label>
        <img
          src={`${process.env.REACT_APP_BASE_API_URL}/uploads/${letterheadFileName}`}
          style={styles.previewedImage}
          alt="Preview"
        />

        {/* {pdfContent(letterheadFileName)} */}
      </Modal>
    </>
  );
};


const styles = {
  toolbar: {
    marginBottom: 24,
  },
  rightToolbar: {
    textAlign: "right",
  },
  pageHeader: {
    padding: 0,
    paddingBottom: 24,
  },
  label: {
    display: "block",
    marginBottom: 3,
    marginTop: 6,
  },
  previewedImage: {
    width: "100%",
  },
  select: {
    width: "100%",
  },
};

export default DataTable;
