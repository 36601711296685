export default {
  slugs: {
    singular: "letterhead",
    plural: "letterheads",
  },
  entityNames: {
    singular: "Letterhead",
    plural: "Letterheads",
  },
};
