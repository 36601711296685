import axiosInstance from "./index";
import store from "../../redux";
import { logOutUser } from "../../redux/actions/authActions";

const setupAxios = (store) => {
  axiosInstance.interceptors.request.use(
    (config) => {
      const token = store.getState().auth?.user?.token;

      if (token) {
        config.headers["Authorization"] = "Bearer " + token;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      if (err?.response?.status === 401) {
        store?.dispatch(logOutUser());
      }

      return Promise.reject(err);
    }
  );

  return axiosInstance;
};

export default setupAxios;
