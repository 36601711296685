import React, { useEffect, useState } from "react";
import { AddForm } from "../../../components";
import { useNavigate, useParams } from "react-router-dom";
import { makeConfig } from "./config";
import { axios } from "../../../../../App";
import { fetchData } from "../../../../../utils/helpers/data.helper";
import qs from "qs";
import moment from "moment";
import { isEmpty } from "lodash";

const Page = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    gender: ["Male", "Female"]?.map((item) => ({
      value: item,
      label: item,
    })),
  });
  const config = makeConfig({ navigate });
  const contentTypes = [
    {
      slug: "initials",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          initial: data,
        })),
    },
    {
      slug: "states",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          state: data,
        })),
    },
    {
      slug: "cities",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          city: data,
        })),
    },
    {
      slug: "departments",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          department: data,
        })),
    },
    {
      slug: "job-titles",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          job_title: data,
        })),
    },
    {
      slug: "designations",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          designation: data,
        })),
    },
    {
      slug: "attendance-modes",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          attendance_mode: data,
        })),
    },
    {
      slug: "working-days",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          working_days: data,
        })),
    },
  ];

  useEffect(() => {
    contentTypes?.forEach((contentType) => {
      fetchData(contentType);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const queryObject = {
          populate: "*",
        };

        const response = await axios.get(
          `/${config?.slugs?.plural}/${id}?${qs.stringify(queryObject)}`
        );

        if (response?.data) {
          let newData = {
            ...response?.data?.data?.attributes,
            id: response?.data?.data?.id,
            city: response?.data?.data?.attributes?.city?.data?.id,
            state: response?.data?.data?.attributes?.state?.data?.id,
            initial: response?.data?.data?.attributes?.initial?.data?.id,
            job_title: response?.data?.data?.attributes?.job_title?.data?.id,
            designation:
              response?.data?.data?.attributes?.designation?.data?.id,
            department: response?.data?.data?.attributes?.department?.data?.id,
            birthdate: response?.data?.data?.attributes?.birthdate
              ? moment(response?.data?.data?.attributes?.birthdate)
              : null,
            joining_date: response?.data?.data?.attributes?.joining_date
              ? moment(response?.data?.data?.attributes?.joining_date)
              : null,
            anniversary_date: response?.data?.data?.attributes?.anniversary_date
              ? moment(response?.data?.data?.attributes?.anniversary_date)
              : null,
            resignation_date: response?.data?.data?.attributes?.resignation_date
              ? moment(response?.data?.data?.attributes?.resignation_date)
              : null,
            in_time: response?.data?.data?.attributes?.in_time
              ? moment(response?.data?.data?.attributes?.in_time, "HH:mm:ss")
              : null,
            out_time: response?.data?.data?.attributes?.out_time
              ? moment(response?.data?.data?.attributes?.out_time, "HH:mm:ss")
              : null,
            attendance_mode:
              response?.data?.data?.attributes?.attendance_mode?.data?.id,
            working_days:
              response?.data?.data?.attributes?.working_days?.data?.map(
                (item) => item?.id
              ),
          };

          if (isEmpty(newData?.photo) || isEmpty(newData?.photo?.data)) {
            newData["photo"] = null;
          }

          setData(newData);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <AddForm config={config} data={data} loading={loading} values={values} />
  );
};

export default Page;
