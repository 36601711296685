import { axios } from "../../../../../App";
import config from "../config";
import md5 from "md5";

export const makeConfig = ({ navigate }) => ({
  id: `${config?.plural}-change-password`,
  title: `Change Password`,
  pageTitle: `Change Password`,
  breadcrumbs: [
    {
      path: "/quick-setup",
      title: "Home",
    },
    {
      path: `/quick-setup/${config?.slugs?.plural}`,
      title: config?.entityNames?.plural,
    },
    {
      path: "",
      title: `Change Password`,
    },
  ],
  components: [
    {
      span: 8,
      component: "input",
      label: "Current Password",
      name: "current_password",
      required: true,
      placeholder: "Enter current password",
      rules: [
        {
          required: true,
          message: "Current password is required!",
        },
      ],
      type: "password",
    },
    {
      span: 8,
      component: "input",
      label: "New Password",
      name: "new_password",
      required: true,
      placeholder: "Enter new password",
      rules: [
        {
          required: true,
          message: "New password is required!",
        },
      ],
      type: "password",
    },
    {
      span: 8,
      component: "input",
      label: "Confirmation Password",
      name: "confirmation_password",
      required: true,
      placeholder: "Enter confirmation password",
      rules: [
        {
          required: true,
          message: "Confirmation password is required!",
        },
      ],
      type: "password",
    },
  ],
  actions: {
    onBack: () => navigate(`/quick-setup/${config?.slugs?.plural}`),
    onFinish: async (values, data) => {
      try {
        if (md5(values?.current_password) !== data?.password) {
          return {
            success: false,
            message: "Current password does not match!",
          };
        }

        if (md5(values?.new_password) === data?.password) {
          return {
            success: false,
            message: "New password must be different from current password!",
          };
        }

        if (values?.confirmation_password !== values?.new_password) {
          return {
            success: false,
            message: "Confirmation password does not match!",
          };
        }

        const response = await axios.put(
          `/${config?.slugs?.plural}/${data?.id}`,
          {
            data: {
              password: md5(values?.new_password),
            },
          }
        );

        if (response?.data) {
          return {
            success: true,
            message: `Change password successfully!`,
          };
        }

        throw new Error();
      } catch (error) {
        return {
          success: false,
          message: error?.response?.data?.error?.message,
        };
      }
    },
  },
  ...config,
});
