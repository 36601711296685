export const UserTypes = [
  {
    id: 1,
    name: "Employee",
    image:
      "https://img.icons8.com/external-inipagistudio-mixed-inipagistudio/344/external-employee-professional-mentorship-inipagistudio-mixed-inipagistudio.png",
  },
  {
    id: 2,
    name: "Partner",
    image:
      "https://img.icons8.com/external-inipagistudio-mixed-inipagistudio/344/external-business-partnership-leadership-coaching-inipagistudio-mixed-inipagistudio.png",
  },
  {
    id: 3,
    name: "Agency",
    image:
      "https://img.icons8.com/external-inipagistudio-mixed-inipagistudio/344/external-office-moving-house-inipagistudio-mixed-inipagistudio.png",
  },
  {
    id: 4,
    name: "Sub-contractor",
    image:
      "https://img.icons8.com/external-inipagistudio-mixed-inipagistudio/344/external-office-moving-house-inipagistudio-mixed-inipagistudio.png",
  },
  {
    id: 5,
    name: "Vendor",
    image:
      "https://img.icons8.com/external-inipagistudio-mixed-inipagistudio/344/external-office-moving-house-inipagistudio-mixed-inipagistudio.png",
  },
];
