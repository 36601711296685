import React from "react";
import { Route } from "react-router-dom";
import {
  ReminderTemplateList,
  ReminderTemplateAdd,
  ReminderTemplateEdit,
} from "../pages/ReminderTemplates";

export const register = (props) => {
  const basePath = "reminder-templates";

  return (
    <>
      <Route path={basePath} element={<ReminderTemplateList />} exact={true} />
      <Route path={`${basePath}/add`} element={<ReminderTemplateAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<ReminderTemplateEdit />} />
    </>
  );
};
