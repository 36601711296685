import { CLIENT_UID_SET, CLIENT_UID_UNSET } from "../actions/types";

const initialState = {
  clientUid: "",
};

const clientUidReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLIENT_UID_SET:
      return {
        clientUid: action?.uid,
      };

    case CLIENT_UID_UNSET:
      return {
        clientUid: "",
      };

    default:
      return state;
  }
};

export default clientUidReducer;
