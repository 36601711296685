import React, { useState, useEffect } from "react";
import DataTable from "./DataTable";
import { fetchData } from "../../../../../utils/helpers/data.helper";
import {
  MeetingColumns,
  EventColumns,
  ReminderColumns,
  ToDoColumns,
  NewEmailsColumns,
  UnOpenedChatsColumns,
} from "./columns";
import { Stack, SimpleGrid, Box } from "@chakra-ui/react";
import { Col } from "antd";
import NumberCard from "./NumberCard";
import { axios } from "../../../../../App";
import { CHART_SLUGS } from "../constants";
import ChartGenerator from "../../../../../components/ChartGenerator";

const CARDS = [
  {
    title: "Total Meetings",
    dataIndex: "total_meetings",
  },
  {
    title: "Total Events",
    dataIndex: "total_events",
  },
  {
    title: "Total Reminders",
    dataIndex: "total_reminders",
  },
  {
    title: "Total To-dos",
    dataIndex: "total_to_dos",
  },
  {
    title: "Total Unread Mails",
    dataIndex: "total_unread_mails",
  },
  {
    title: "Total Chat Groups",
    dataIndex: "total_chat_groups",
  },
];

const Main = (props) => {
  const [meetings, setMeetings] = useState([]);
  const [events, setEvents] = useState([]);
  const [reminders, setReminders] = useState([]);
  const [toDos, setToDos] = useState([]);
  const [newMails, setNewMails] = useState([]);
  const [unOpenedChats, setUnOpenedChats] = useState([]);
  const [stats, setStats] = useState({});
  const [data, setData] = useState({});

  const contentTypes = [
    {
      slug: "meetings",
      query: {
        sort: ["started_at:desc"],
        pagination: {
          page: 1,
          pageSize: 10,
        },
      },
      onSuccess: (data) => setMeetings(data),
    },
    {
      slug: "events",
      query: {
        sort: ["time:desc"],
        pagination: {
          page: 1,
          pageSize: 10,
        },
      },
      onSuccess: (data) => setEvents(data),
    },
    {
      slug: "reminders",
      query: {
        sort: ["date:desc"],
        pagination: {
          page: 1,
          pageSize: 10,
        },
      },
      onSuccess: (data) => setReminders(data),
    },
    {
      slug: "to-dos",
      query: {
        sort: ["time:desc"],
        pagination: {
          page: 1,
          pageSize: 10,
        },
      },
      onSuccess: (data) => setToDos(data),
    },
    {
      slug: "app-emails",
      query: {
        filters: {
          is_read: false,
        },
        populate: {
          sender: {
            fields: ["first_name,last_name"],
          },
        },
        sort: ["createdAt:desc"],
        pagination: {
          page: 1,
          pageSize: 10,
        },
      },
      onSuccess: (data) => setNewMails(data),
    },
    {
      slug: "chat-messages",
      query: {
        // filters: { is_read: false },
        populate: {
          sender: {
            fields: ["first_name,last_name"],
          },
          conversation: true,
        },
        sort: ["sent_at:desc"],
        pagination: {
          page: 1,
          pageSize: 10,
        },
      },
      onSuccess: (data) => setUnOpenedChats(data),
    },
  ];

  const fetchStats = async () => {
    try {
      const response = await axios.get(`/quick-talks-dashboard/statistics`);

      if (response?.data) {
        const newStats = response?.data?.data;

        setStats(newStats);
      }

      throw new Error();
    } catch (error) {
      console.log(error);
    }
  };

  const fetchChartData = async () => {
    let requests = [];
    let newData = {};
    let slug = "";

    for (let slug of Object.values(CHART_SLUGS)) {
      requests.push(axios.get(`/quick-talks-dashboard/${slug}`));
    }

    const response = await Promise.all(requests);

    for (let i = 0; i < Object.values(CHART_SLUGS)?.length; i++) {
      slug = Object.values(CHART_SLUGS)?.[i];

      newData[slug] = response[i]?.data;
    }

    setData(newData);
  };

  useEffect(() => {
    fetchStats();
    fetchChartData();

    contentTypes?.forEach((contentType) => {
      fetchData(contentType);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <SimpleGrid
        spacing={3}
        columns={{ base: 2, md: 3, lg: 4 }}
        style={{ marginBottom: 12 }}
      >
        {CARDS?.map((card, index) => (
          <Col>
            <NumberCard
              key={index}
              title={card?.title}
              value={stats?.[card?.dataIndex] || 0}
            />
          </Col>
        ))}
      </SimpleGrid>

      <Stack direction={{ base: "column", md: "row", lg: "row" }}>
        {data?.[CHART_SLUGS?.MAIL_STATUSES] && (
          <Box w={{ base: "auto", md: `${50}%` }}>
            <Col>
              <ChartGenerator
                type="pie"
                data={data?.[CHART_SLUGS?.MAIL_STATUSES]}
                schema={{
                  title: "Mail Statuses",
                  angleField: "value",
                  colorField: "status",
                }}
              />
            </Col>
          </Box>
        )}

        {data?.[CHART_SLUGS?.CHAT_STATUSES] && (
          <Box w={{ base: "auto", md: `${50}%` }}>
            <Col>
              <ChartGenerator
                type="pie"
                data={data?.[CHART_SLUGS?.CHAT_STATUSES]}
                schema={{
                  title: "Ticket Statuses",
                  angleField: "value",
                  colorField: "status",
                }}
              />
            </Col>
          </Box>
        )}
      </Stack>

      <Stack direction={{ base: "column", md: "row", lg: "row" }}>
        {data?.[CHART_SLUGS?.MEETINGS] && (
          <Box w={{ base: "auto", md: "100%" }}>
            <Col>
              <ChartGenerator
                type="columns"
                data={data?.[CHART_SLUGS?.MEETINGS]}
                schema={{
                  title: "Total Meetings",
                  xField: "date",
                  yField: "value",
                }}
              />
            </Col>
          </Box>
        )}
      </Stack>

      <Stack direction={{ base: "column", md: "row", lg: "row" }}>
        {data?.[CHART_SLUGS?.EVENTS] && (
          <Box w={{ base: "auto", md: "100%" }}>
            <Col>
              <ChartGenerator
                type="columns"
                data={data?.[CHART_SLUGS?.EVENTS]}
                schema={{
                  title: "Total Events",
                  xField: "date",
                  yField: "value",
                }}
              />
            </Col>
          </Box>
        )}
      </Stack>

      <Stack direction={{ base: "column", md: "row", lg: "row" }}>
        {data?.[CHART_SLUGS?.REMINDERS] && (
          <Box w={{ base: "auto", md: "100%" }}>
            <Col>
              <ChartGenerator
                type="columns"
                data={data?.[CHART_SLUGS?.REMINDERS]}
                schema={{
                  title: "Total Reminders",
                  xField: "date",
                  yField: "value",
                }}
              />
            </Col>
          </Box>
        )}
      </Stack>

      <Stack direction={{ base: "column", md: "row", lg: "row" }}>
        {data?.[CHART_SLUGS?.TO_DOS] && (
          <Box w={{ base: "auto", md: "100%" }}>
            <Col>
              <ChartGenerator
                type="columns"
                data={data?.[CHART_SLUGS?.TO_DOS]}
                schema={{
                  title: "Total To Dos",
                  xField: "date",
                  yField: "value",
                }}
              />
            </Col>
          </Box>
        )}
      </Stack>

      <SimpleGrid columns={[1, 1, 2, 2]} spacing={10}>
        <DataTable title="Meetings" columns={MeetingColumns} data={meetings} />
        <DataTable title="Events" columns={EventColumns} data={events} />
        <DataTable
          title="Reminders"
          columns={ReminderColumns}
          data={reminders}
        />
        <DataTable title="To-dos" columns={ToDoColumns} data={toDos} />
        {/* <DataTable
          title="Unread Mails"
          columns={NewEmailsColumns}
          data={newMails}
        />
        <DataTable
          title="Chats"
          columns={UnOpenedChatsColumns}
          data={unOpenedChats}
        /> */}
      </SimpleGrid>
    </>
  );
};

export default Main;
