import React from "react";
import { Route } from "react-router-dom";
import {
  AdvancedLoanList,
  AdvancedLoanAdd,
  AdvancedLoanEdit,
  AdvancedLoanReport,
} from "../pages/AdvancedLoans";

export const register = (props) => {
  const basePath = "advanced-loans";

  return (
    <>
      <Route path={basePath} element={<AdvancedLoanList />} exact={true} />
      <Route path={`${basePath}/add`} element={<AdvancedLoanAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<AdvancedLoanEdit />} />
      <Route path={`${basePath}-report`} element={<AdvancedLoanReport />} />
    </>
  );
};
