import React from "react";
import { Route } from "react-router-dom";
import {
  SubContractorCreditNoteList,
  SubContractorCreditNoteAdd,
  SubContractorCreditNoteReport,
  SubContractorCreditNoteCreatorEdit,
  SubContractorCreditNoteApproverEdit,
  SubContractorCreditNoteView,
} from "../pages/SubContractorCreditNotes";

export const register = (props) => {
  const basePath = "sub-contractor-credit-notes";

  return (
    <>
      <Route
        path={basePath}
        element={<SubContractorCreditNoteList />}
        exact={true}
      />
      <Route
        path={`${basePath}/add`}
        element={<SubContractorCreditNoteAdd />}
      />
      <Route
        path={`${basePath}/creator/edit/:id`}
        element={<SubContractorCreditNoteCreatorEdit />}
      />
      <Route
        path={`${basePath}/approver/edit/:id`}
        element={<SubContractorCreditNoteApproverEdit />}
      />
      <Route
        path={`${basePath}/view/:id`}
        element={<SubContractorCreditNoteView />}
      />
      <Route
        path={`${basePath}-report`}
        element={<SubContractorCreditNoteReport />}
      />
    </>
  );
};
