export default {
  slugs: {
    singular: "revised-shift",
    plural: "revised-shifts",
  },
  entityNames: {
    singular: "Revised Shift",
    plural: "Revised Shifts",
  },
};
