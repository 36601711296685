import { axios } from "../../../../../App";
import { v4 } from "uuid";
import config from "../config";

export const makeConfig = ({ navigate, auth }) => ({
  id: `${config?.slugs?.plural}-add`,
  title: `Add New ${config?.entityNames?.singular}`,
  pageTitle: `Add New ${config?.entityNames?.singular}`,
  breadcrumbs: [
    {
      path: "/quick-projects/dashboard",
      title: "Home",
    },
    {
      path: `/quick-projects/${config?.slugs?.plural}`,
      title: config?.entityNames?.plural,
    },
    {
      path: "",
      title: `Add New ${config?.entityNames?.singular}`,
    },
  ],
  components: [
    {
      span: 24,
      component: "select",
      label: "Project",
      name: "project",
      placeholder: "Select project",
      required: true,
      rules: [
        {
          required: true,
          message: "Project is required!",
        },
      ],
    },
    // {
    //   span: 12,
    //   component: "select",
    //   label: "Material Group",
    //   name: "material_group",
    //   placeholder: "Select material group",
    //   required: true,
    //   rules: [
    //     {
    //       required: true,
    //       message: "Material group is required!",
    //     },
    //   ],
    // },
    {
      span: 12,
      component: "select",
      label: "Material Item",
      name: "material_item",
      placeholder: "Select material item",
      required: true,
      rules: [
        {
          required: true,
          message: "Material item is required!",
        },
      ],
    },
    // {
    //   span: 12,
    //   component: "select",
    //   label: "Material Unit",
    //   name: "material_unit",
    //   placeholder: "Select material unit",
    //   required: true,
    //   rules: [
    //     {
    //       required: true,
    //       message: "Material unit is required!",
    //     },
    //   ],
    // },
    {
      span: 12,
      component: "number",
      label: "Amount",
      name: "amount",
      required: true,
      placeholder: "Enter amount",
      rules: [
        {
          required: true,
          message: "Amount is required!",
        },
      ],
      min: 0,
    },
    // {
    //   span: 12,
    //   component: "select",
    //   label: "Status",
    //   name: "status",
    //   placeholder: "Select status",
    //   required: true,
    //   rules: [
    //     {
    //       required: true,
    //       message: "Status is required!",
    //     },
    //   ],
    // },
    {
      span: 24,
      component: "editor",
      label: "Description",
      name: "description",
      placeholder: "Enter description",
      rows: 5,
    },
  ],
  actions: {
    onBack: () => navigate(`/quick-projects/${config?.slugs?.plural}`),
    onFinish: async (values) => {
      try {
        const checkResponse = await axios.get(
          `/stock-ledgers/${values?.material_item}`
        );

        if (checkResponse?.data) {
          const stockAmount = checkResponse?.data?.data?.attributes?.quantity;
          if (values?.amount > stockAmount) {
            return {
              success: false,
              message: `There are not enough items in stock (Items left: ${stockAmount})`,
            };
          } else {
            const response = await axios.post(`/${config?.slugs?.plural}`, {
              data: {
                ...values,
                uid: v4(),
                // creator: auth?.user?.id,
              },
            });

            if (response?.data) {
              await axios.put(`/stock-ledgers/${values?.material_item}`, {
                data: {
                  quantity: stockAmount - values?.amount,
                },
              });

              return {
                success: true,
                message: `Add new ${config?.entityNames?.singular?.toLowerCase()} successfully!`,
              };
            } else {
              throw new Error();
            }
          }
        } else {
          throw new Error();
        }
      } catch (error) {
        console.log(error);
        return {
          success: false,
          message: `Add new ${config?.entityNames?.singular?.toLowerCase()} failed!`,
        };
      }
    },
  },
});
