export default {
  slugs: {
    singular: "app-user",
    plural: "app-users",
  },
  entityNames: {
    singular: "User",
    plural: "Users",
  },
};
