import { axios } from "../../../../../App";
import { v4 } from "uuid";
import config from "../config";
import moment from "moment";

export const makeConfig = ({ navigate, auth }) => ({
  id: `${config?.slugs?.plural}-add`,
  title: `Add New ${config?.entityNames?.singular}`,
  pageTitle: `Add New ${config?.entityNames?.singular}`,
  breadcrumbs: [
    {
      path: "/quick-talks/dashboard",
      title: "Home",
    },
    {
      path: `/quick-talks/${config?.slugs?.plural}`,
      title: config?.entityNames?.plural,
    },
    {
      path: "",
      title: `Add New ${config?.entityNames?.singular}`,
    },
  ],
  components: [
    {
      span: 24,
      component: "input",
      label: "Name",
      name: "name",
      required: true,
      placeholder: "Enter name",
      rules: [
        {
          required: true,
          message: "Name is required!",
        },
      ],
    },
    {
      span: 12,
      component: "date",
      label: "Date",
      name: "date",
      required: true,
      placeholder: "Select date",
      rules: [
        {
          required: true,
          message: "Date is required!",
        },
      ],
    },
    {
      span: 12,
      component: "time",
      label: "Time",
      name: "time",
      required: true,
      placeholder: "Select time",
      rules: [
        {
          required: true,
          message: "Time is required!",
        },
      ],
    },
    {
      span: 24,
      component: "select",
      label: "Employees",
      name: "employees",
      placeholder: "Select employees",
      required: true,
      rules: [
        {
          required: true,
          message: "Employees is required!",
        },
      ],
      mode: "multiple",
    },
    // {
    //   span: 12,
    //   component: "select",
    //   label: "Frequency",
    //   name: "frequency",
    //   placeholder: "Select frequency",
    //   required: true,
    //   rules: [
    //     {
    //       required: true,
    //       message: "Frequency is required!",
    //     },
    //   ],
    // },
    {
      span: 24,
      component: "editor",
      label: "Description",
      name: "description",
      placeholder: "Enter description",
      rows: 5,
    },
  ],
  actions: {
    onBack: () => navigate(`/quick-talks/${config?.slugs?.plural}`),
    onFinish: async (values) => {
      try {
        const response = await axios.post(`/${config?.slugs?.plural}`, {
          data: {
            ...values,
            uid: v4(),
            // creator: auth?.user?.id,
            time: moment(values?.time).format("HH:mm:ss.SSS"),
          },
        });

        if (response?.data) {
          return {
            success: true,
            message: `Add new ${config?.entityNames?.singular?.toLowerCase()} successfully!`,
          };
        }

        throw new Error();
      } catch (error) {
        return {
          success: false,
          message: `Add new ${config?.entityNames?.singular?.toLowerCase()} failed!`,
        };
      }
    },
  },
});
