export default {
  slugs: {
    singular: "shift",
    plural: "shifts",
  },
  entityNames: {
    singular: "Shift",
    plural: "Shifts",
  },
};
