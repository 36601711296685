export default {
  slugs: {
    singular: "rfi",
    plural: "rfis",
  },
  entityNames: {
    singular: "RFI",
    plural: "RFIs",
  },
};
