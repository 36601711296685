import config from "../config";
import moment from "moment";

const commify = (x) => {
  return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export default {
  id: `${config?.slugs?.plural}-list`,
  pageTitle: config?.entityNames?.plural,
  breadcrumbs: [
    {
      path: "/quick-stocks/dashboard",
      title: "Home",
    },
    {
      path: "",
      title: config?.entityNames?.plural,
    },
  ],
  columns: [
    {
      title: "Hand Tool",
      dataIndex: "hand_tool",
      selectable: true,
      references: {
        fieldName: "name",
      },
    },
    {
      title: "In Time",
      dataIndex: "in_time",
      selectable: true,
      sorter: (a, b) =>
        moment(a.in_time, "HH:mm:ss.SSS").unix() -
        moment(b.in_time, "HH:mm:ss.SSS").unix(),
      render: (text) => <span>{text?.split(".")?.[0]}</span>,
      width: 250,
    },
    {
      title: "Out Time",
      dataIndex: "out_time",
      selectable: true,
      sorter: (a, b) =>
        moment(a.out_time, "HH:mm:ss.SSS").unix() -
        moment(b.out_time, "HH:mm:ss.SSS").unix(),
      render: (text) => <span>{text?.split(".")?.[0]}</span>,
      width: 250,
    },
    {
      title: "Required By",
      dataIndex: "required_by",
      selectable: true,
      filterable: true,
      references: {
        fieldName: (record) => `${record?.first_name} ${record?.last_name}`,
      },
    },
  ],
  ...config,
};
