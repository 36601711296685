import React, { useState, useEffect } from "react";
import NumberCard from "./NumberCard";
import { Col } from "antd";
import ChartGenerator from "../../../../../components/ChartGenerator";
import {
  CompensationDistData,
  CompensationDistByLocationData,
  EmployeesPerformanceData,
} from "./mock";
import { AttendanceColumns } from "./columns";
import { Stack, SimpleGrid, Box } from "@chakra-ui/react";
import DataTable from "./DataTable";
import { axios } from "../../../../../App";
import { CHART_SLUGS } from "../constants";
import qs from "qs";

const CARDS = [
  {
    title: "Total Salary",
    dataIndex: "total_salary",
  },
  {
    title: "Total Bonus",
    dataIndex: "total_bonus",
  },
  {
    title: "Total Employees",
    dataIndex: "total_employees",
  },
  {
    title: "Average Base Salary",
    dataIndex: "average_base_salary",
    format: (value) => Math.round(value * 100) / 100, // Rounds to 2 decimal places
  },
];

const Page = (props) => {
  const [attendances, setAttendances] = useState([]);
  const [data, setData] = useState({});

  const fetchAttendances = async () => {
    try {
      const queryObject = {
        populate: "*",
        sort: ["date:desc"],
      };

      const response = await axios.get(
        `/attendances?${qs.stringify(queryObject)}`
      );

      if (response?.data) {
        setAttendances(
          response?.data?.data?.map((item) => ({
            id: item?.id,
            ...item?.attributes,
            employee: `${
              item?.attributes?.employee?.data?.attributes?.first_name || ""
            } ${item?.employee?.data?.attributes?.last_name || ""}`,
            attendance_type:
              item?.attributes?.attendance_type?.data?.attributes?.name,
          }))
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchChartData = async () => {
    let requests = [];
    let newData = {};
    let slug = "";

    for (let slug of Object.values(CHART_SLUGS)) {
      requests.push(axios.get(`/quick-teams-dashboard/${slug}`));
    }

    const response = await Promise.all(requests);

    for (let i = 0; i < Object.values(CHART_SLUGS)?.length; i++) {
      slug = Object.values(CHART_SLUGS)?.[i];

      newData[slug] = response[i]?.data;
    }

    setData(newData);
  };

  useEffect(() => {
    fetchAttendances();
    fetchChartData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={styles.container}>
      <div style={styles.cards}>
        <SimpleGrid
          spacing={3}
          columns={{ base: 2, md: 3, lg: 4 }}
          style={{ marginBottom: 12 }}
        >
          {CARDS?.map((card, index) => (
            <Col>
              <NumberCard
                key={index}
                title={card?.title}
                value={card?.format ? card.format(data?.[CHART_SLUGS?.STATISTICS]?.data?.[card?.dataIndex]) : data?.[CHART_SLUGS?.STATISTICS]?.data?.[card?.dataIndex]}
              />
            </Col>
          ))}
        </SimpleGrid>

        <Stack direction={{ base: "column", md: "row", lg: "row" }}>
          {data?.[CHART_SLUGS?.EMPLOYEES_PER_GENDER] && (
            <Box w={{ base: "auto", md: `${100 / 3}%` }}>
              <Col>
                <ChartGenerator
                  type="donus"
                  data={data?.[CHART_SLUGS?.EMPLOYEES_PER_GENDER]}
                  schema={{
                    title: "Employee Genders",
                    angleField: "value",
                    colorField: "type",
                  }}
                />
              </Col>
            </Box>
          )}

          {data?.[CHART_SLUGS?.EMPLOYEES_PER_LOCATION] && (
            <Box w={{ base: "auto", md: `${100 / 3}%` }}>
              <Col>
                <ChartGenerator
                  type="pie"
                  data={data?.[CHART_SLUGS?.EMPLOYEES_PER_LOCATION]}
                  schema={{
                    title: "Employee Per Location",
                    angleField: "employees",
                    colorField: "location",
                  }}
                />
              </Col>
            </Box>
          )}

          {data?.[CHART_SLUGS?.EMPLOYEES_PER_DEPARTMENT] && (
            <Box w={{ base: "auto", md: `${100 / 3}%` }}>
              <Col>
                <ChartGenerator
                  type="pie"
                  data={data?.[CHART_SLUGS?.EMPLOYEES_PER_DEPARTMENT]}
                  schema={{
                    title: "Employee Per Department",
                    angleField: "employees",
                    colorField: "department",
                  }}
                />
              </Col>
            </Box>
          )}
        </Stack>

        <Stack direction={{ base: "column", md: "row", lg: "row" }}>
          {data?.[CHART_SLUGS?.EMPLOYEES_PER_PROJECT] && (
            <Box w={{ base: "auto", md: `50%` }}>
              <Col>
                <ChartGenerator
                  type="pie"
                  data={data?.[CHART_SLUGS?.EMPLOYEES_PER_PROJECT]}
                  schema={{
                    title: "Employee Per Project",
                    angleField: "employees",
                    colorField: "project",
                  }}
                />
              </Col>
            </Box>
          )}

          {data?.[CHART_SLUGS?.TICKET_STATUSES] && (
            <Box w={{ base: "auto", md: `50%` }}>
              <Col>
                <ChartGenerator
                  type="pie"
                  data={data?.[CHART_SLUGS?.TICKET_STATUSES]}
                  schema={{
                    title: "Ticket Statuses",
                    angleField: "value",
                    colorField: "type",
                  }}
                />
              </Col>
            </Box>
          )}

          {/* <Box w={{ base: "auto", md: `${100 / 3}%` }}>
            <Col>
              <ChartGenerator
                type="pie"
                data={EmployeesPerformanceData}
                schema={{
                  title: "Employees Performance",
                }}
              />
            </Col>
          </Box> */}
        </Stack>

        <Stack direction={{ base: "column", md: "row", lg: "row" }}>
          {data?.[CHART_SLUGS?.EMPLOYEES] && (
            <Box w={{ base: "auto", md: "100%" }}>
              <Col>
                <ChartGenerator
                  type="stacked-column"
                  data={data?.[CHART_SLUGS?.EMPLOYEES]}
                  schema={{
                    title: "Total Employees",
                    xField: "date",
                    yField: "value",
                    seriesField: "gender",
                  }}
                />
              </Col>
            </Box>
          )}
        </Stack>

        {/* <Stack direction={{ base: "column", md: "row", lg: "row" }}>
          <Box w={{ base: "auto", md: "100%" }}>
            <Col>
              <ChartGenerator
                type="stacked-bar"
                data={CompensationDistData}
                schema={{
                  title: "Compensation Distribution by Department",
                }}
              />
            </Col>
          </Box>
        </Stack> */}

        {/* <Stack direction={{ base: "column", md: "row", lg: "row" }}>
          <Box w={{ base: "auto", md: "100%" }}>
            <Col>
              <ChartGenerator
                type="bar-plot"
                data={CompensationDistByLocationData}
                schema={{
                  title: "Compensation Distribution by Location",
                }}
              />
            </Col>
          </Box>
        </Stack> */}

        <Stack direction={{ base: "column", md: "row", lg: "row" }}>
          <Col span={24} style={{ marginBottom: 16 }}>
            <DataTable
              title="Attendance"
              columns={AttendanceColumns}
              data={attendances}
            />
          </Col>
        </Stack>
      </div>
    </div>
  );
};

const styles = {};

export default Page;
