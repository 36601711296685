import React from "react";
import { Route } from "react-router-dom";
import {
  CreatedInvoiceList,
  CreatedInvoiceAdd,
  CreatedInvoiceEdit,
  CreatedInvoiceReport,
} from "../pages/CreatedInvoices";
import InvoiceReport from "../pages/CreatedInvoices/Report";

export const register = (props) => {
  const basePath = "created-invoices";

  return (
    <>
      <Route path={basePath} element={<CreatedInvoiceList />} exact={true} />
      <Route path={`${basePath}/add`} element={<CreatedInvoiceAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<CreatedInvoiceEdit />} />
      <Route path={`${basePath}-report`} element={<InvoiceReport />} />
    </>
  );
};
