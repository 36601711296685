export default {
  slugs: {
    singular: "hand-tool",
    plural: "hand-tools",
  },
  entityNames: {
    singular: "Hand Tool",
    plural: "Hand Tools",
  },
};
