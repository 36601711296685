import { AUTH_LOGIN, AUTH_LOG_OUT } from "../actions/types";

const initialState = {
  user: {},
  loggedIn: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_LOGIN:
      return {
        ...state,
        user: action?.payload,
        loggedIn: true,
      };

    case AUTH_LOG_OUT:
      return {
        user: {},
        loggedIn: false,
      };

    default:
      return state;
  }
};

export default authReducer;
