export default {
  slugs: {
    singular: "leave-type",
    plural: "leave-types",
  },
  entityNames: {
    singular: "Leave Application Type",
    plural: "Leave Application Types",
  },
};
