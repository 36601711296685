import { MdArrowBack, MdLockOutline } from "react-icons/md";
import { CgProfile } from "react-icons/cg";
import { IoDocuments, IoNotificationsOutline } from "react-icons/io5";
import { TbCertificate } from "react-icons/tb";

const BASE_SLUG = "/account";

export const makeNavbar = ({ navigate, user }) => {
  const makeItem = ({ icon, id, label, scopes }) => {
    return {
      id,
      key: `${BASE_SLUG}/${id}`,
      label,
      icon,
      onClick: () => navigate(`${BASE_SLUG}/${id}`),
      scopes,
    };
  };

  let menuItems = [
    makeItem({
      id: "profile",
      label: "Profile",
      icon: <CgProfile style={styles.icon} />,
    }),
    makeItem({
      id: "notifications",
      label: "Notifications",
      icon: <IoNotificationsOutline style={styles.icon} />,
    }),
    makeItem({
      id: "change-password",
      label: "Change Password",
      icon: <MdLockOutline style={styles.icon} />,
    }),
  ];

  // if (user?.user_type === "Employee") {
  //   menuItems.push(
  //     makeItem({
  //       id: "employee-documents",
  //       label: "Documents",
  //       icon: <IoDocuments style={styles.icon} />,
  //     })
  //   );

  //   menuItems.push(
  //     makeItem({
  //       id: "employee-qualifications",
  //       label: "Qualifications",
  //       icon: <TbCertificate style={styles.icon} />,
  //     })
  //   );
  // }

  menuItems.push({
    key: "back-to-home",
    label: "Return Home",
    icon: <MdArrowBack style={styles.icon} />,
    onClick: () => navigate("/"),
  });

  return menuItems;
};

const styles = {
  icon: {
    fontSize: 16,
    color: "#CF6C57",
  },
};
