export default {
  slugs: {
    singular: "task",
    plural: "tasks",
  },
  entityNames: {
    singular: "Milestone",
    plural: "Milestones",
  },
};
