import React from "react";
import { Route } from "react-router-dom";
import {
  VendorGroupList,
  VendorGroupAdd,
  VendorGroupEdit,
} from "../pages/VendorGroups";

export const register = (props) => {
  const basePath = "vendor-groups";

  return (
    <>
      <Route path={basePath} element={<VendorGroupList />} exact={true} />
      <Route path={`${basePath}/add`} element={<VendorGroupAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<VendorGroupEdit />} />
    </>
  );
};
