import { axios } from "../../../../../App";
import config from "../config";

export const makeConfig = ({ navigate }) => ({
  id: `${config?.plural}-edit`,
  title: `Edit ${config?.entityNames?.singular}`,
  pageTitle: `Edit ${config?.entityNames?.singular}`,
  breadcrumbs: [
    {
      path: "/quick-setup",
      title: "Home",
    },
    {
      path: `/quick-setup/${config?.slugs?.plural}`,
      title: config?.entityNames?.plural,
    },
    {
      path: "",
      title: `Edit ${config?.entityNames?.singular}`,
    },
  ],
  components: [
    {
      span: 12,
      component: "input",
      label: "Username",
      name: "username",
      required: true,
      placeholder: "Enter username",
      rules: [
        {
          required: true,
          message: "Username is required!",
        },
        {
          min: 3,
          message: "Username must have at least 3 characters!",
        },
        {
          pattern: new RegExp("^[A-Za-z0-9_]*$"),
          message: [
            "Only lowercase/ uppercase letters, digits and underscores are allowed!",
          ],
        },
      ],
    },
    // {
    //   span: 12,
    //   component: "input",
    //   label: "Password",
    //   name: "password",
    //   required: true,
    //   placeholder: "Enter password",
    //   rules: [
    //     {
    //       required: true,
    //       message: "Password is required!",
    //     },
    //     {
    //       min: 6,
    //       message: "Password must have at least 6 characters!",
    //     },
    //   ],
    //   type: "password",
    // },
    {
      span: 12,
      component: "select",
      label: "Roles",
      name: "app_roles",
      placeholder: "Select roles",
      required: true,
      rules: [
        {
          required: true,
          message: "Roles is required!",
        },
      ],
      mode: "multiple",
    },
    {
      span: 12,
      component: "select",
      label: "Status",
      name: "status",
      placeholder: "Select status",
      required: true,
      rules: [
        {
          required: true,
          message: "Status is required!",
        },
      ],
    },
    {
      span: 12,
      component: "input",
      label: "Email 1",
      name: "email_1",
      required: true,
      placeholder: "Enter email 1",
      rules: [
        {
          required: true,
          message: "Email 1 is required!",
        },
      ],
    },
    // {
    //   span: 12,
    //   component: "input",
    //   label: "Email 2",
    //   name: "email_2",
    //   placeholder: "Enter email 2",
    // },
  ],
  actions: {
    onBack: () => navigate(`/quick-setup/${config?.slugs?.plural}`),
    onFinish: async (values, data) => {
      try {
        const response = await axios.put(
          `/${config?.slugs?.plural}/${data?.id}`,
          {
            data: values,
          }
        );

        if (response?.data) {
          return {
            success: true,
            message: `Edit ${config?.entityNames?.singular?.toLowerCase()} successfully!`,
          };
        }

        throw new Error();
      } catch (error) {
        return {
          success: false,
          message: error?.response?.data?.error?.message,
        };
      }
    },
  },
  ...config,
});
