export default {
  slugs: {
    singular: "reminder-template",
    plural: "reminder-templates",
  },
  entityNames: {
    singular: "Reminder Template",
    plural: "Reminder Templates",
  },
};
