import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { makeConfig } from "./config";
import { fetchData } from "../../../../../utils/helpers/data.helper";
import { Helmet } from "react-helmet";
import {
  Empty,
  PageHeader,
  Breadcrumb,
  Row,
  Col,
  Progress,
  Divider,
} from "antd";
import { Link } from "react-router-dom";
import moment from "moment";
import { Stack } from "@chakra-ui/react";

const DATE_TIME_FORMAT = "YYYY-MM-DDTHH:mm:ss[Z]";

const Page = (props) => {
  const navigate = useNavigate();
  const config = makeConfig({ navigate });
  const [data, setData] = useState({});
  const [monthDays, setMonthDays] = useState(moment().daysInMonth());
  const [holidays, setHolidays] = useState([]);
  const [leaveApplications, setLeaveApplications] = useState([]);
  const { id } = useParams();

  const fetchChartData = () => {
    const contentTypes = [
      {
        slug: "holidays",
        query: {
          filters: {
            date: {
              $gte: moment().startOf("month").format(DATE_TIME_FORMAT),
              $lte: moment().endOf("month").format(DATE_TIME_FORMAT),
            },
          },
        },
        onSuccess: (data) => {
          setHolidays(data);
        },
      },
      {
        slug: "leave-applications",
        query: {
          populate: "*",
          filters: {
            employee: id,
            status: "Approved",
            from_date: {
              $gte: moment().startOf("month").format(DATE_TIME_FORMAT),
              $lte: moment().endOf("month").format(DATE_TIME_FORMAT),
            },
            to_date: {
              $gte: moment().startOf("month").format(DATE_TIME_FORMAT),
              $lte: moment().endOf("month").format(DATE_TIME_FORMAT),
            },
          },
        },
        onSuccess: (data) => {
          setLeaveApplications(data);
        },
      },
    ];

    contentTypes.forEach((contentType) => fetchData(contentType));
  };

  const fetchEmployee = (employeeId) => {
    fetchData({
      slug: "employees",
      query: {
        populate: "*",
        filters: {
          id: employeeId,
        },
      },
      onSuccess: (data) => setData(data?.[0]?.attributes),
    });
  };

  const calcTotalEarnings = () => {
    let basicSalary = data?.basic_salary || 0;
    let hra = data?.hra || 0;
    let bonus = data?.bonus || 0;
    let conveyance = data?.conveyance || 0;
    let otherAllowances = data?.other_allowances || 0;

    return basicSalary + hra + bonus + conveyance + otherAllowances;
  };

  const calcTotalDeductions = () => {
    let tds = data?.tds || 0;
    let esi = data?.esi || 0;
    let advanced_loan = data?.advanced_loan || 0;
    let other_deductions = data?.other_deductions || 0;
    let pf = data?.pf || 0;

    return tds + esi + advanced_loan + other_deductions + pf;
  };

  const calcPaidDays = () => {
    let count = 0;

    leaveApplications?.forEach((leave) => {
      if (leave?.leave_type?.data?.attributes?.is_paid) {
        count += 1;
      }
    });

    return count;
  };

  const calcUnpaidDays = () => {
    let count = 0;

    leaveApplications?.forEach((leave) => {
      if (leave?.leave_type?.data?.attributes?.is_paid === false) {
        count += 1;
      }
    });

    return count;
  };

  useEffect(() => {
    fetchEmployee(id);
    fetchChartData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <div style={styles.pageContainer}>
      <Helmet>
        <title>{config?.pageTitle}</title>
      </Helmet>

      <Breadcrumb style={styles.breadcrumbs}>
        {config?.breadcrumbs?.map((breadcrumb, index) => (
          <Breadcrumb.Item key={index}>
            <Link to={breadcrumb?.path}>{breadcrumb?.title}</Link>
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>

      <PageHeader
        title={config?.title}
        style={styles.pageHeader}
        onBack={config?.actions?.onBack}
      ></PageHeader>

      {!data && <Empty />}

      {data && (
        <>
          <Row>
            <Col span={12} style={{ textAlign: "left" }}>
              <h3>
                {data?.first_name?.toUpperCase()}{" "}
                {data?.last_name?.toUpperCase()}
              </h3>
              <p style={styles.paragraph}>
                {data?.job_title?.data?.attributes?.name || "N/A"}
              </p>
            </Col>

            <Col span={12} style={{ textAlign: "right" }}>
              <h3>INVOICE ID: PRINV-{id}</h3>
              <p style={styles.paragraph}>
                SALARY MONTH: {moment().format("MMMM, YYYY")?.toUpperCase()}
              </p>
            </Col>
          </Row>

          <Divider />

          <div style={styles.cards}>
            <div style={styles.chartCard}>
              <Progress
                type="circle"
                percent={Math.round(
                  ((monthDays - holidays?.length) / monthDays) * 100
                )}
                status="success"
                format={(percent) => `${percent}%`}
              />
              <h2 style={styles.chartCardValue}>
                {monthDays - holidays?.length}
              </h2>
              <p style={styles.chartCardDesc}>Total Days</p>
            </div>

            <div style={styles.chartCard}>
              <Progress
                type="circle"
                percent={Math.round(
                  ((data?.working_days?.data?.length * 4) /
                    (monthDays - holidays?.length)) *
                    100
                )}
                status="success"
                format={(percent) => `${percent}%`}
              />
              <h2 style={styles.chartCardValue}>
                {data?.working_days?.data?.length * 4}
              </h2>
              <p style={styles.chartCardDesc}>Working</p>
            </div>

            <div style={styles.chartCard}>
              <Progress
                type="circle"
                percent={Math.round((holidays?.length / monthDays) * 100)}
                status="success"
                format={(percent) => `${percent}%`}
              />
              <h2 style={styles.chartCardValue}>{holidays?.length || 0}</h2>
              <p style={styles.chartCardDesc}>Holidays</p>
            </div>

            <div style={styles.chartCard}>
              <Progress
                type="circle"
                percent={Math.round(
                  (calcPaidDays() / (monthDays - holidays?.length)) * 100
                )}
                status="success"
                format={(percent) => `${percent}%`}
              />
              <h2 style={styles.chartCardValue}>{calcPaidDays()}</h2>
              <p style={styles.chartCardDesc}>Pay Leave</p>
            </div>

            <div style={styles.chartCard}>
              <Progress
                type="circle"
                percent={Math.round(
                  (calcUnpaidDays() / (monthDays - holidays?.length)) * 100
                )}
                status="success"
                format={(percent) => `${percent}%`}
              />
              <h2 style={styles.chartCardValue}>{calcUnpaidDays()}</h2>
              <p style={styles.chartCardDesc}>Without Pay</p>
            </div>
          </div>

            <Stack direction={{base:"column", md: "row", lg: "row"}} spacing={3}>
             <div style={{width:"100%"}}> 
              <table className="payroll-table earnings-table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Earnings</th>
                    <th>Total</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td>1</td>
                    <td>Basic Salary</td>
                    <td>{data?.basic_salary || 0}</td>
                  </tr>

                  <tr>
                    <td>2</td>
                    <td>House Rent Allowance (H.R.A.)</td>
                    <td>{data?.hra || 0}</td>
                  </tr>

                  <tr>
                    <td>3</td>
                    <td>Bonus</td>
                    <td>{data?.bonus || 0}</td>
                  </tr>

                  <tr>
                    <td>4</td>
                    <td>Conveyance</td>
                    <td>{data?.conveyance || 0}</td>
                  </tr>

                  <tr>
                    <td>5</td>
                    <td>Other Allowances</td>
                    <td>{data?.other_allowances || 0}</td>
                  </tr>

                  <tr>
                    <td></td>
                    <td>
                      <strong>Total Earnings</strong>
                    </td>
                    <td>
                      <strong>{calcTotalEarnings()}</strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>  

            <div style={{width:"100%"}}>      
              <table className="payroll-table deductions-table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Deductions</th>
                    <th>Total</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td>1</td>
                    <td>Tax Deducted at Source (T.D.S.)</td>
                    <td>{data?.tds || 0}</td>
                  </tr>

                  <tr>
                    <td>2</td>
                    <td>ESI</td>
                    <td>{data?.esi || 0}</td>
                  </tr>

                  <tr>
                    <td>3</td>
                    <td>Provident Fund</td>
                    <td>{data?.pf || 0}</td>
                  </tr>

                  <tr>
                    <td>4</td>
                    <td>C/Bank Loan</td>
                    <td>{data?.advanced_loan || 0}</td>
                  </tr>

                  <tr>
                    <td>5</td>
                    <td>Other Deductions</td>
                    <td>{data?.other_deductions || 0}</td>
                  </tr>

                  <tr>
                    <td></td>
                    <td>
                      <strong>Total Deductions</strong>
                    </td>
                    <td>
                      <strong>{calcTotalDeductions()}</strong>
                    </td>
                  </tr>
                </tbody>
              </table>
              </div> 
            </Stack>  

          <Divider />

          <Row>
            <Col span={12} style={{ textAlign: "left" }}>
              <h3>NOTES</h3>
              <div style={styles.paragraph} dangerouslySetInnerHTML={{ __html: data?.description || "N/A" }} />
            </Col>

            <Col span={12} style={{ textAlign: "right" }}>
              <h3>Total Earnings: {calcTotalEarnings()}</h3>
              <h3>Total Deductions: {calcTotalDeductions()}</h3>
              <h3>NET Salary: {calcTotalEarnings() - calcTotalDeductions()}</h3>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

const styles = {
  pageContainer: {},
  container: {
    display: "grid",
    gridTemplateColumns: "2fr 4fr",
    gridColumnGap: 24,
  },
  vendor: {
    background: "rgb(246, 246, 249)",
    padding: "12px 16px",
    border: "1px solid #eee",
    marginBottom: 12,
    borderRadius: 4,
    cursor: "pointer",
  },
  details: {
    margin: 0,
    padding: 0,
  },
  pageHeader: {
    padding: 0,
    paddingBottom: 24,
  },
  cards: {
    display: "grid",
    gridTemplateColumns: "repeat(5, 1fr)",
    gridGap: 24,
    overflow: "auto",
    margin: "0 auto",
    marginTop: 12,
    marginBottom: 36,
  },
  chartCard: {
    textAlign: "center",
  },
  chartCardValue: {
    margin: 0,
    padding: 0,
    marginTop: 12,
  },
  chartCardDesc: {
    margin: 0,
    padding: 0,
  },
  paragraph: {
    margin: 0,
    padding: 0,
  },
};

export default Page;
