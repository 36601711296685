export default {
  slugs: {
    singular: "created-invoice",
    plural: "created-invoices",
  },
  entityNames: {
    singular: "Created Invoice",
    plural: "Created Invoices",
  },
};
