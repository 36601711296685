export default {
  slugs: {
    singular: "employee-group",
    plural: "employee-groups",
  },
  entityNames: {
    singular: "Employee Group",
    plural: "Employee Groups",
  },
};
