import React from "react";
import { Route } from "react-router-dom";
import {
  AgencyRequirementFormList,
  AgencyRequirementFormAdd,
  AgencyRequirementFormEdit,
  AgencyRequirementFormReport,
  AgencyRequirementFormQuotationLinkList,
} from "../pages/AgencyRequirementForms";

export const register = (props) => {
  const basePath = "agency-requirement-forms";

  return (
    <>
      <Route
        path={basePath}
        element={<AgencyRequirementFormList />}
        exact={true}
      />
      <Route path={`${basePath}/add`} element={<AgencyRequirementFormAdd />} />
      <Route
        path={`${basePath}/edit/:id`}
        element={<AgencyRequirementFormEdit />}
      />
      <Route
        path={`${basePath}/quotations/:id`}
        element={<AgencyRequirementFormQuotationLinkList />}
      />
      <Route
        path={`${basePath}-report`}
        element={<AgencyRequirementFormReport />}
      />
    </>
  );
};
