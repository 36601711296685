import React, { useState, useEffect } from "react";
import { ReportDataTable } from "../../../components";
import { useNavigate } from "react-router-dom";
import { Space, Button, Row, Col, Input, Tooltip, DatePicker, Select, Table } from "antd";
import qs from "qs";
import config from "./config";
import { axios } from "../../../../../App";
import _ from "lodash";
import { ReloadOutlined, DownloadOutlined } from "@ant-design/icons";
import { gql, useQuery } from "@apollo/client";
import COSTREPORTS from "./query";
import dayjs from "dayjs";
import './index.css'

const DATE_TIME_FORMAT = "YYYY-MM-DDTHH:mm:ss[Z]";

const CashFlowReport = () => {
    const navigate = useNavigate();
    const [searchValues, setSearchValues] = useState({});
    const [total, settotal] = useState(0)
    const [searched, setSearched] = useState(false);

    const { loading, error, data: all_data } = useQuery(COSTREPORTS, {
        variables: {
            startDate: searchValues.start_date ? dayjs(searchValues.start_date).format('YYYY-MM-DD') : dayjs(new Date()).format('YYYY-MM-DD'),
            endDate: searchValues.end_date ? dayjs(searchValues.end_date).format('YYYY-MM-DD') : dayjs(new Date()).format('YYYY-MM-DD'),
        },
    });

    const refreshData = () => {
        onSearch();
    };

    const onSearch = () => {
        setSearched(true);
    };

    const onReset = () => {
        setSearchValues({});
        setSearched(false);
    };

    const getResultsMessage = () => {
        if (!_.isEmpty(searchValues) && !loading && searched) {
            return total > 0 ? (
                <span>
                    <strong>{total}</strong> matched results
                </span>
            ) : (
                <span>No matched results</span>
            );
        }

        return (
            <span>
                <strong>{total}</strong> total records
            </span>
        );
    };

    const getColumnFilterProps = ({ dataIndex, references = {} }) => {
        if (typeof references?.fieldName === "function") {
            const transformer = references?.fieldName;

            return {
                render: (data) => transformer(data?.data?.attributes) || "N/A",
            };
        } else {
            return {
                render: (data) =>
                    data?.data?.attributes?.[references?.fieldName] || "N/A",
            };
        }
    };

    const getColumnProps = (column) => {
        if (column?.references) {
            return getColumnFilterProps(column);
        }

        return column;
    };

    const renderHeaderButtons = () => {
        let buttons = [
            <Tooltip key="refresh" placement="top" title="Refresh">
                <Button
                    icon={<ReloadOutlined />}
                    onClick={refreshData}
                    loading={loading}
                />
            </Tooltip>,
        ];

        return buttons;
    };

    const projects_columns = [
        {
            title: "Project Name",
            dataIndex: "name",
            selectable: true,
        },
        {
            title: "Estimated Budget",
            dataIndex: "estimated_budget",
            selectable: true,
        },
        {
            title: 'Sanctioned Budget',
            dataIndex: 'sanctioned_budget',
            key: 'sanctioned_budget',
        },
    ];

    const tasks_columns = [
        {
            title: "Task Name",
            dataIndex: "name",
            selectable: true,
        },
        {
            title: "Estimated Cost",
            dataIndex: "estimated_cost",
            selectable: true,
        },
        {
            title: 'Actual Cost',
            dataIndex: 'actual_cost',
            key: 'actual_cost',
        },
    ];

    const employee_columns = [
        {
            title: "Employee Name",
            dataIndex: "name",
            selectable: true,
        },
        {
            title: "Employee Salary",
            dataIndex: "salary",
            selectable: true,
        },
    ];

    const machine_columns = [
        {
            title: "Machine Name",
            dataIndex: "name",
            selectable: true,
        },
        {
            title: "Price",
            dataIndex: "value",
            selectable: true,
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
        },
    ];

    const sub_columns = [
        {
            title: "Sub Contractor",
            dataIndex: "sub_contractor",
            selectable: true,
        },
        {
            title: "Heading",
            dataIndex: "heading",
            selectable: true,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
        },
    ];

    const payment_colmns = [
        {
            title: "Name",
            dataIndex: "name",
            selectable: true,
        },
        {
            title: "Scheduled Date",
            dataIndex: "scheduled_date",
            selectable: true,
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
        },
    ];


    // Define the rowClassName function
    const rowClassName = (record) => {
        return record.name === 'Grand Total' ? 'highlight-total-row' : '';
    };


    const project_cost_data = all_data?.projects?.data?.flatMap((report) => ({
        name: report.attributes.name,
        estimated_budget: report.attributes.estimated_budget,
        sanctioned_budget: report.attributes.sanctioned_budget,
    }));

    const renderProjectCosts = () => {
        if (all_data?.projects?.data?.length > 0) {

            const totalEstimatedBudget = project_cost_data?.reduce((acc, report) => acc + (report.estimated_budget || 0), 0);
            const totalSanctionedBudget = project_cost_data?.reduce((acc, report) => acc + (report.sanctioned_budget || 0), 0);

            const grandTotalRow = {
                name: 'Grand Total',
                estimated_budget: totalEstimatedBudget,
                sanctioned_budget: totalSanctionedBudget,
            };

            const dataWithTotal = [...project_cost_data, grandTotalRow];

            return (
                <>
                    <style>
                        {`
                        .highlight-total-row {
                            background-color: #f0f0f0 !important; /* Change this to your desired highlight color */
                            font-weight: bold;
                        }
                        `}
                    </style>

                    <h2 style={{ fontSize: '20px' }}>Projects Expenses</h2>
                    <div id="taskTable" style={{ display: 'none' }}>
                        <Table columns={projects_columns} dataSource={project_cost_data} pagination={false} />
                    </div>

                    <Table
                        columns={projects_columns}
                        dataSource={dataWithTotal}
                        pagination={false}
                        rowClassName={rowClassName}
                        style={{ pageBreakInside: 'avoid' }}
                    />
                </>
            );
        } else {
            return null;
        }
    };






    const task_cost_data = all_data?.tasks?.data?.flatMap((report) => ({
        name: report.attributes.name,
        estimated_cost: report.attributes.estimated_budget,
        actual_cost: report.attributes.sanctioned_budget,
    }));

    const renderTaskCosts = () => {
        if (all_data?.tasks?.data?.length > 0) {

            const totalEstimatedCost = task_cost_data?.reduce((acc, report) => acc + (report.estimated_cost || 0), 0);
            const totalActualCost = task_cost_data?.reduce((acc, report) => acc + (report.actual_cost || 0), 0);

            const grandTotalRow = {
                name: 'Grand Total',
                estimated_cost: totalEstimatedCost,
                actual_cost: totalActualCost,
            };

            const dataWithTotal = [...task_cost_data, grandTotalRow];

            return (
                <>
                    <style>
                        {`
                        .highlight-total-row {
                            background-color: #f0f0f0 !important; /* Change this to your desired highlight color */
                            font-weight: bold;
                        }
                        `}
                    </style>

                    <h2 style={{ fontSize: '20px' }}>Tasks Expenses</h2>
                    <div id="taskTable" style={{ display: 'none' }}>
                        <Table columns={tasks_columns} dataSource={task_cost_data} pagination={false} />
                    </div>

                    <Table
                        columns={tasks_columns}
                        dataSource={dataWithTotal}
                        pagination={false}
                        rowClassName={rowClassName}
                        style={{ pageBreakInside: 'avoid' }}
                    />
                </>
            );
        } else {
            return null;
        }
    };





    const salary_cost_data = all_data?.employees?.data?.flatMap((report) => ({
        name: `${report.attributes.first_name} ${report.attributes.last_name}`,
        salary: report.attributes.basic_salary,
    }));

    const renderSalaryCosts = () => {
        if (all_data?.employees?.data?.length > 0) {

            const totalSalary = salary_cost_data?.reduce((acc, report) => acc + (report.salary || 0), 0);

            const grandTotalRow = {
                name: 'Grand Total',
                salary: totalSalary,
            };

            const dataWithTotal = [...salary_cost_data, grandTotalRow];

            return (
                <>
                    <style>
                        {`
                        .highlight-total-row {
                            background-color: #f0f0f0 !important; /* Change this to your desired highlight color */
                            font-weight: bold;
                        }
                        `}
                    </style>

                    <h2 style={{ fontSize: '20px' }}>Employee Expenses</h2>
                    <div id="taskTable" style={{ display: 'none' }}>
                        <Table columns={employee_columns} dataSource={salary_cost_data} pagination={false} />
                    </div>

                    <Table
                        columns={employee_columns}
                        dataSource={dataWithTotal}
                        pagination={false}
                        rowClassName={rowClassName}
                        style={{ pageBreakInside: 'avoid' }}
                    />
                </>
            );
        } else {
            return null;
        }
    };





    const machine_cost_data = all_data?.taskMachines?.data?.flatMap((report) => ({
        name: report.attributes.machine.data.attributes.name,
        value: report.attributes.machine.data.attributes.initial_value,
        quantity: report.attributes.quantity,
    }));

    const renderMachineCosts = () => {
        if (all_data?.employees?.data?.length > 0) {

            const totalCost = machine_cost_data?.reduce((acc, report) => acc + (report.value || 0), 0);
            const totalQuantity = machine_cost_data?.reduce((acc, report) => acc + (report.quantity || 0), 0);


            const grandTotalRow = {
                name: 'Grand Total',
                value: totalCost,
                quantity: totalQuantity,
            };

            const dataWithTotal = [...machine_cost_data, grandTotalRow];

            return (
                <>
                    <style>
                        {`
                        .highlight-total-row {
                            background-color: #f0f0f0 !important; /* Change this to your desired highlight color */
                            font-weight: bold;
                        }
                        `}
                    </style>

                    <h2 style={{ fontSize: '20px' }}>Machinery Expenses</h2>
                    <div id="taskTable" style={{ display: 'none' }}>
                        <Table columns={machine_columns} dataSource={machine_cost_data} pagination={false} />
                    </div>

                    <Table
                        columns={machine_columns}
                        dataSource={dataWithTotal}
                        pagination={false}
                        rowClassName={rowClassName}
                        style={{ pageBreakInside: 'avoid' }}
                    />
                </>
            );
        } else {
            return null;
        }
    };





    const sub_debit_cost_data = all_data?.subContractorDebitNotes?.data?.flatMap((report) => ({
        sub_contractor: report.attributes.sub_contractor.data.attributes.name,
        heading: report.attributes.heading,
        status: report.attributes.status,
        total: report.attributes.grand_total,
    }));

    const renderSubDebitCosts = () => {
        if (all_data?.employees?.data?.length > 0) {

            const totalSalary = sub_debit_cost_data?.reduce((acc, report) => acc + (report.total || 0), 0);

            const grandTotalRow = {
                sub_contractor: 'Grand Total',
                total: totalSalary,
            };

            const dataWithTotal = [...sub_debit_cost_data, grandTotalRow];

            return (
                <>
                    <style>
                        {`
                        .highlight-total-row {
                            background-color: #f0f0f0 !important; /* Change this to your desired highlight color */
                            font-weight: bold;
                        }
                        `}
                    </style>
                    <h2 style={{ fontSize: '20px' }}>Sub-Contractor Debits</h2>
                    <div id="taskTable" style={{ display: 'none' }}>
                        <Table columns={sub_columns} dataSource={sub_debit_cost_data} pagination={false} />
                    </div>

                    <Table
                        id="taskTable"
                        columns={sub_columns}
                        dataSource={dataWithTotal}
                        pagination={false}
                        rowClassName={rowClassName}
                        style={{ pageBreakInside: 'avoid' }}
                    />
                </>
            );
        } else {
            return null;
        }
    };



    const sub_payments = all_data?.subContractorPaymentSchedules?.data?.flatMap((report) => ({
        name: report.attributes.sub_contractor.data.attributes.name,
        scheduled_date: report.attributes.scheduled_date,
        amount: report.attributes.amount,
    }));

    const renderSubPayment = () => {
        if (all_data?.subContractorPaymentSchedules?.data?.length > 0) {

            const totalSalary = sub_payments?.reduce((acc, report) => acc + (report.amount || 0), 0);

            const grandTotalRow = {
                name: 'Grand Total',
                amount: totalSalary,
            };

            const dataWithTotal = [...sub_payments, grandTotalRow];

            return (
                <>
                    <style>
                        {`
                        .highlight-total-row {
                            background-color: #f0f0f0 !important; /* Change this to your desired highlight color */
                            font-weight: bold;
                        }
                        `}
                    </style>
                    <h2 style={{ fontSize: '20px' }}>Sub-Contractor Payment Schedules</h2>
                    <div id="taskTable" style={{ display: 'none' }}>
                        <Table columns={payment_colmns} dataSource={sub_payments} pagination={false} />
                    </div>

                    <Table
                        id="taskTable"
                        columns={payment_colmns}
                        dataSource={dataWithTotal}
                        pagination={false}
                        rowClassName={rowClassName}
                        style={{ pageBreakInside: 'avoid' }}
                    />
                </>
            );
        } else {
            return null;
        }
    };



    const vendor_payments = all_data?.vendorPaymentSchedules
        ?.data?.flatMap((report) => ({
            name: report.attributes?.vendor?.data?.attributes?.name || "N/A",
            scheduled_date: report.attributes.scheduled_date,
            amount: report.attributes.amount,
        }));

    const renderVendorPayment = () => {
        if (all_data?.vendorPaymentSchedules
            ?.data?.length > 0) {

            const totalSalary = vendor_payments?.reduce((acc, report) => acc + (report.amount || 0), 0);

            const grandTotalRow = {
                name: 'Grand Total',
                amount: totalSalary,
            };

            const dataWithTotal = [...vendor_payments, grandTotalRow];

            return (
                <>
                    <style>
                        {`
                        .highlight-total-row {
                            background-color: #f0f0f0 !important; /* Change this to your desired highlight color */
                            font-weight: bold;
                        }
                        `}
                    </style>
                    <h2 style={{ fontSize: '20px' }}>Vendor Payment Schedules</h2>
                    <div id="taskTable" style={{ display: 'none' }}>
                        <Table columns={payment_colmns} dataSource={vendor_payments} pagination={false} />
                    </div>

                    <Table
                        id="taskTable"
                        columns={payment_colmns}
                        dataSource={dataWithTotal}
                        pagination={false}
                        rowClassName={rowClassName}
                        style={{ pageBreakInside: 'avoid' }} // Apply the rowClassName function
                    />
                </>
            );
        } else {
            return null;
        }
    };



    const agency_payments = all_data?.agencyPaymentSchedules
        ?.data?.flatMap((report) => ({
            name: report.attributes?.agency?.data?.attributes?.name || "N/A",
            scheduled_date: report.attributes.scheduled_date,
            amount: report.attributes.amount,
        }));

    const renderAgencyPayment = () => {
        if (all_data?.agencyPaymentSchedules
            ?.data?.length > 0) {

            const totalSalary = agency_payments?.reduce((acc, report) => acc + (report.amount || 0), 0);

            const grandTotalRow = {
                name: 'Grand Total',
                scheduled_date: '',
                amount: totalSalary,
            };

            const dataWithTotal = [...agency_payments, grandTotalRow];

            return (
                <>
                    <style>
                        {`
                        .highlight-total-row {
                            background-color: #f0f0f0 !important; /* Change this to your desired highlight color */
                            font-weight: bold;
                        }
                        `}
                    </style>
                    <h2 style={{ fontSize: '20px' }}>Agency Payment Schedules</h2>
                    <div id="taskTable" style={{ display: 'none' }}>
                        <Table columns={payment_colmns} dataSource={agency_payments} pagination={false} />
                    </div>

                    <div style={styles.tableContainer}>
                        <Table
                            id="taskTable"
                            columns={payment_colmns}
                            dataSource={dataWithTotal}
                            pagination={false}
                            rowClassName={rowClassName}
                            style={{ pageBreakInside: 'avoid' }} // Apply the rowClassName function
                        />
                    </div>
                </>
            );
        } else {
            return null;
        }
    };

    return (
        <>
            <ReportDataTable
                data={null}
                title={"Cash Flow Report"}
                config={{
                    ...config,
                    pageTitle: "Cash Flow Report"
                }}
                taskData={[project_cost_data, task_cost_data, salary_cost_data, machine_cost_data, agency_payments, sub_payments, vendor_payments]}
                columns={[projects_columns, tasks_columns, employee_columns, machine_columns, payment_colmns, payment_colmns, payment_colmns]}
                use_handlePrint={true}
                Toolbar={
                    <div className="toolbar">
                        <Row gutter={16}>

                            <Col span={7}>
                                <DatePicker
                                    onChange={(value) =>
                                        setSearchValues((prev) => ({
                                            ...prev,
                                            start_date: value,
                                        }))
                                    }
                                    value={searchValues?.start_date}
                                    placeholder="Select start date"
                                    style={styles.datePicker}
                                />
                            </Col>

                            <Col span={7}>
                                <DatePicker
                                    onChange={(value) =>
                                        setSearchValues((prev) => ({
                                            ...prev,
                                            end_date: value,
                                        }))
                                    }
                                    value={searchValues?.endDate}
                                    placeholder="Select end date"
                                    style={styles.datePicker}
                                />
                            </Col>
                        </Row>

                        <div className="toolbar-buttons">
                            <p className="toolbar-results">{getResultsMessage()}</p>

                            <Space>
                                <Button onClick={onReset}>Reset</Button>
                                <Button type="primary" onClick={onSearch}>
                                    Search
                                </Button>
                            </Space>
                        </div>
                    </div>
                }
                buttons={renderHeaderButtons()}
                onBack={() => navigate(`/quick-projects/reports`)}
            />

            <div style={{ display: 'flex', flexDirection: 'column', gap: '40px' }} id="barGraph">

                {all_data?.projects?.data?.length > 0 && renderProjectCosts()}


                {all_data?.tasks?.data?.length > 0 && renderTaskCosts()}

                {all_data?.employees?.data?.length > 0 && renderSalaryCosts()}

                {/* {all_data?.subContractorDebitNotes?.data?.length > 0 && renderSubDebitCosts()} */}

                {all_data?.taskMachines?.data?.length > 0 && renderMachineCosts()}

                {all_data?.agencyPaymentSchedules?.data?.length > 0 && renderAgencyPayment()}

                {all_data?.subContractorPaymentSchedules?.data?.length > 0 && renderSubPayment()}

                {all_data?.vendorPaymentSchedules?.data?.length > 0 && renderVendorPayment()}
            </div>


        </>
    );
};


const styles = {
    datePicker: {
        width: "100%",
    },
    highlightRow: {
        backgroundColor: '#f0f0f0', // Change this to your desired highlight color
        fontWeight: 'bold',
    }
};

export default CashFlowReport;
