import React from "react";
import { Route } from "react-router-dom";
import {
  TaskCommentList,
  TaskCommentAdd,
  TaskCommentEdit,
  TaskCommentReport,
} from "../pages/TaskComments";

export const register = (props) => {
  const basePath = "task-comments";

  return (
    <>
      <Route path={basePath} element={<TaskCommentList />} exact={true} />
      <Route path={`${basePath}/add`} element={<TaskCommentAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<TaskCommentEdit />} />
      <Route path={`${basePath}-report`} element={<TaskCommentReport />} />
    </>
  );
};
