import React from "react";
import { Route } from "react-router-dom";
import {
  MaterialBrandList,
  MaterialBrandAdd,
  MaterialBrandEdit,
} from "../pages/MaterialBrands";

export const register = (props) => {
  const basePath = "material-brands";

  return (
    <>
      <Route path={basePath} element={<MaterialBrandList />} exact={true} />
      <Route path={`${basePath}/add`} element={<MaterialBrandAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<MaterialBrandEdit />} />
    </>
  );
};
