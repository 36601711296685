export default {
  slugs: {
    singular: "meeting-notes",
    plural: "meeting-notes",
  },
  entityNames: {
    singular: "Meeting Note",
    plural: "Meeting Notes",
  },
};
