export const makeConfig = () => ({
  id: `project-chats`,
  title: `Project Chats`,
  pageTitle: `Project Chats`,
  breadcrumbs: [
    {
      path: "/quick-talks/dashboard",
      title: "Home",
    },
    {
      path: "",
      title: "Project Chats",
    },
  ],
  conversationType: "project",
});
