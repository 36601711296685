import React from "react";
import { Route } from "react-router-dom";
import {
  AgencyTermList,
  AgencyTermAdd,
  AgencyTermEdit,
  AgencyTermReport,
  AgencyTermView,
} from "../pages/AgencyTerms";

export const register = (props) => {
  const basePath = "agency-terms";

  return (
    <>
      <Route path={basePath} element={<AgencyTermList />} exact={true} />
      <Route path={`${basePath}/add`} element={<AgencyTermAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<AgencyTermEdit />} />
      <Route path={`${basePath}/view/:id`} element={<AgencyTermView />} />
      <Route path={`${basePath}-report`} element={<AgencyTermReport />} />
    </>
  );
};
