import React, { useState, useEffect } from "react";
import { DataTable } from "../../../components";
import { useNavigate, Link } from "react-router-dom";
import {
  Space,
  Button,
  Popconfirm,
  Tooltip,
  notification,
  Input,
  Empty,
  Card,
  Progress,
  Tag,
  PageHeader,
  Breadcrumb,
  Spin,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  ReloadOutlined,
  PlusOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import qs from "qs";
import config from "./config";
import { axios } from "../../../../../App";
import _ from "lodash";
import { BsChatSquareText, BsBarChartSteps } from "react-icons/bs";
import { useSelector } from "react-redux";
import Pluralize from "pluralize";
import { IoGridOutline } from "react-icons/io5";
import { FaList } from "react-icons/fa";
import ColumnSelector from "../../../components/ColumnSelector";
import { MAX_PAGINATION_LIMIT } from "../../../../../constants";
// import ReportDocumentContainer from "../../../components/ReportDocument";

const Page = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    pageSize: 10,
  });
  const [total, setTotal] = useState(0);
  const [searchValues, setSearchValues] = useState({});
  const [searched, setSearched] = useState(false);
  const { user } = useSelector((state) => state?.auth);
  const [hasData, setHasData] = useState(false);
  const [mode, setMode] = useState("grid");
  const [selectedColumns, setSelectedColumns] = useState([]);

  const selectColumn = (dataIndex) => {
    if (selectedColumns?.indexOf(dataIndex) > -1) {
      setSelectedColumns((prev) => prev?.filter((item) => item !== dataIndex));
    } else {
      setSelectedColumns((prev) => prev?.concat(dataIndex));
    }
  };

  const setListMode = () => setMode("list");

  const setGridMode = () => setMode("grid");

  const fetchData = async (params = null) => {
    try {
      setLoading(true);

      const queryObject = {
        populate: "*",
        sort: ["name:asc"],
        pagination: {
          limit: MAX_PAGINATION_LIMIT,
        },
      };

      if (!_.isEmpty(params)) {
        queryObject["filters"] = params;
      }

      const response = await axios.get(
        `${config?.slugs?.plural}?${qs.stringify(queryObject)}`
      );

      if (response?.data) {
        setData(
          response?.data?.data?.map((item) => ({
            id: item?.id,
            ...item?.attributes,
          }))
        );

        setTotal(response?.data?.meta?.pagination?.total);

        setHasData(true);
        setPagination({
          ...response?.data?.meta?.pagination,
          pageSize: 10,
        });
      }
    } catch (error) {
      setHasData(false);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const refreshData = () => {
    onSearch();
  };

  const deleteItem = async (id) => {
    try {
      const response = await axios.delete(`${config?.slugs?.plural}/${id}`);

      if (response?.data) {
        notification["success"]({
          message: `Remove ${config?.entityNames?.singular}`,
          description: `Remove ${config?.entityNames?.singular} successfully!`,
        });

        refreshData();
      } else {
        throw new Error();
      }
    } catch (error) {
      notification["error"]({
        message: `Remove ${config?.entityNames?.singular}`,
        description: `Remove ${config?.entityNames?.singular} failed!`,
      });
    }
  };

  const getColumnFilterProps = ({ dataIndex, references = {} }) => {
    if (typeof references?.fieldName === "function") {
      const transformer = references?.fieldName;

      return {
        render: (data) => transformer(data?.data?.attributes) || "N/A",
      };
    } else {
      return {
        render: (data) =>
          data?.data?.attributes?.[references?.fieldName] || "N/A",
      };
    }
  };

  const getColumnProps = (column) => {
    if (column?.references) {
      return getColumnFilterProps(column);
    }

    return column;
  };

  const onSearch = () => {
    setSearched(true);

    fetchData({
      $and: [
        {
          $or: [
            {
              name: {
                $containsi: searchValues?.keyword?.toLowerCase(),
              },
            },
            {
              pin_code: {
                $containsi: searchValues?.keyword?.toLowerCase(),
              },
            },
            {
              rera_no: {
                $containsi: searchValues?.keyword?.toLowerCase(),
              },
            },
            {
              address_line_1: {
                $containsi: searchValues?.keyword?.toLowerCase(),
              },
            },
            {
              address_line_2: {
                $containsi: searchValues?.keyword?.toLowerCase(),
              },
            },
            {
              status: {
                $containsi: searchValues?.keyword?.toLowerCase(),
              },
            },
            {
              description: {
                $containsi: searchValues?.keyword?.toLowerCase(),
              },
            },
          ],
        },
        // getFiltersForRoles(),
      ],
    });
  };

  const onReset = () => {
    setSearchValues({});
    // fetchData(getFiltersForRoles());
    fetchData();
    setSearched(false);
  };

  const getResultsMessage = () => {
    if (!_.isEmpty(searchValues) && !loading && searched) {
      return total > 0 ? (
        <span>
          <strong>{total}</strong> matched results
        </span>
      ) : (
        <span>No matched results</span>
      );
    }

    return (
      <span>
        <strong>{total}</strong> total records
      </span>
    );
  };

  const getFiltersForRoles = () => {
    const userType = user?.user_type?.toLowerCase()?.replace(/\s/i, "_");

    if (user?.is_admin) {
      return {};
    }

    if (userType === "employee") {
      return {
        $or: [
          {
            project_managers: {
              uid: user?.uid,
            },
          },
          {
            members: {
              uid: user?.uid,
            },
          },
        ],
      };
    }

    // TODO: Also okay if requester is also creator

    return {
      [Pluralize(userType)]: {
        uid: user?.uid,
      },
    };
  };

  const downloadReport = (record) => {
    try {
      console.log("Download Report", record);
    } catch (error) {
      console.log(error);
    }
  };

  const doesHavePermission = (record) => {

    const projectManagerUids = record?.project_managers?.data && record?.project_managers?.data?.map(
      (item) => item?.attributes?.uid
    );
    const memberUids = record?.members?.data && record?.members?.data?.map(
      (item) => item?.attributes?.uid
    );

    const canUpdateProjectUids = record?.can_update_project?.data?.length > 0 && record?.can_update_project?.data?.map(
      (item) => item?.attributes?.uid
    );

    console.log("uid", user?.uid, record);

    if (user?.is_admin) {
      return true;
    }

    if (user?.uid === record?.creator?.data?.attributes?.uid) {
      return true;
    }

    if (projectManagerUids?.length > 0 && projectManagerUids?.includes(user?.uid)) {
      return true;
    }

    if (memberUids?.length > 0 && memberUids?.includes(user?.uid)) {
      return true;
    }

    if (canUpdateProjectUids?.length > 0 && canUpdateProjectUids?.includes(user?.uid)) {
      return true;
    }

    return false;
  };

  const ganttChartPermission = (record) => {

    const canViewGanttChart = record?.can_view_gantt_chart?.data?.length > 0 && record?.can_view_gantt_chart?.data?.map(
      (item) => item?.attributes?.uid
    );

    const projectManagerUids = record?.project_managers?.data?.length > 0 && record?.project_managers?.data?.map(
      (item) => item?.attributes?.uid
    );
    const memberUids = record?.members?.data?.map(
      (item) => item?.attributes?.uid
    );

    if (user?.is_admin) {
      return true;
    }

    if (user?.uid === record?.creator?.data?.attributes?.uid) {
      return true;
    }

    if (canViewGanttChart?.length > 0 && canViewGanttChart?.includes(user?.uid)) {
      return true;
    }

    if (projectManagerUids?.length > 0 && projectManagerUids?.includes(user?.uid)) {
      return true;
    }

    if (memberUids?.length > 0 && memberUids?.includes(user?.uid)) {
      return true;
    }

    return false;

  }

  useEffect(() => {
    setSelectedColumns(
      config?.columns
        ?.filter((column) => column?.selectable)
        ?.map((column) => column?.dataIndex)
    );

    // fetchData(getFiltersForRoles());
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  

  return (
    <>
      <Breadcrumb style={styles.breadcrumbs}>
        {config?.breadcrumbs?.map((breadcrumb, index) => (
          <Breadcrumb.Item key={index}>
            <Link to={breadcrumb?.path}>{breadcrumb?.title}</Link>
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>

      <PageHeader
        title={config?.pageTitle}
        extra={[
          <Space>
            <Tooltip placement="top" title="Add new item">
              <Button
                icon={<PlusOutlined />}
                onClick={() =>
                  navigate(`/quick-projects/${config?.slugs?.plural}/add`)
                }
              />
            </Tooltip>

            <Tooltip placement="top" title="Refresh">
              <Button
                icon={<ReloadOutlined />}
                onClick={refreshData}
                loading={loading}
              />
            </Tooltip>

            <Tooltip placement="top" title="Show as List">
              <Button
                icon={<FaList style={{ margin: "0 auto" }} />}
                onClick={setListMode}
                type={mode === "list" ? "primary" : ""}
              />
            </Tooltip>

            <Tooltip placement="top" title="Show as Grid">
              <Button
                icon={<IoGridOutline style={{ margin: "0 auto" }} />}
                onClick={setGridMode}
                type={mode === "grid" ? "primary" : ""}
              />
            </Tooltip>

            <ColumnSelector
              value={selectedColumns}
              columns={config?.columns?.filter((column) => column?.selectable)}
              onSelect={selectColumn}
            />
          </Space>,
        ]}
        style={styles.pageHeader}
      ></PageHeader>

      <div className="toolbar">
        <Input
          value={searchValues?.keyword}
          placeholder="Search for keyword..."
          onChange={(e) =>
            setSearchValues((prev) => ({
              ...prev,
              keyword: e.target.value,
            }))
          }
        />

        <div className="toolbar-buttons">
          <p className="toolbar-results">{getResultsMessage()}</p>

          <Space>
            <Button onClick={onReset}>Reset</Button>
            <Button type="primary" onClick={onSearch}>
              Search
            </Button>
          </Space>
        </div>
      </div>

      {loading && (
        <div style={styles.loaderContainer}>
          <Spin />
        </div>
      )}

      {!loading && hasData && mode === "grid" && (
        <div className="projects-grid" style={styles.main}>
          {data?.map((record) => {
            const hasPermission = doesHavePermission(record);
            const can_view_gantt = ganttChartPermission(record);

            const actualStartDate = new Date(record?.start_date);
            const currentDate = new Date();
            const showEditButton = actualStartDate >= currentDate;

            // can_view_project.data is there then add canViewUids
            
            const canViewUids = record?.can_view_project?.data?.length > 0 && record?.can_view_project?.data?.map(
              (item) => item?.attributes?.uid
            );

            const can_view_project = canViewUids?.length > 0 ? canViewUids?.includes(user?.uid) : false;

            // console.log('can_view_project', hasPermission, can_view_project, actualStartDate, canViewUids, record)

            return (
              <Card
                key={record?.id}
                title={record?.name}
                style={styles.project}
                headStyle={{ display: "none" }}
              >
                <h3>{record?.name}</h3>
                <Tag>{record?.status}</Tag>

                <Progress percent={record?.progress} style={styles.progress} />

                <div style={styles.buttons}>
                  {hasPermission && (
                    <>
                      <Space>
                        { showEditButton && (
                          <Tooltip
                            placement="bottom"
                            title={`Edit ${config?.entityNames?.singular}`}
                          >
                            <Button
                              icon={<EditOutlined />}
                              onClick={() =>
                                navigate(
                                  `/quick-projects/${config?.slugs?.plural}/edit/${record?.id}`
                                )
                              }
                            />
                          </Tooltip>
                        )}

                        {(can_view_gantt && hasPermission) && (
                          <Tooltip
                            placement="bottom"
                            title={`View ${config?.entityNames?.singular} Gantt Chart`}
                          >
                            <Button
                              icon={
                                <BsBarChartSteps style={{ margin: "0 auto" }} />
                              }
                              onClick={() =>
                                navigate(
                                  `/quick-projects/gantt-chart?projectId=${record?.id}`
                                )
                              }
                            />
                          </Tooltip>
                        )}

                        <Tooltip
                          placement="bottom"
                          title={`View ${config?.entityNames?.singular} Discussion`}
                        >
                          <Button
                            icon={
                              <BsChatSquareText style={{ margin: "0 auto" }} />
                            }
                            onClick={() =>
                              navigate(
                                `/quick-talks/project-chats?conversation=${record?.id}`
                              )
                            }
                          />
                        </Tooltip>

                        {/* <Tooltip placement="bottom" title={`Download Report`}>
                          <ReportDocumentContainer projectId={record?.id} />
                        </Tooltip> */}

                        <Popconfirm
                          title={`Are you sure to remove this ${config?.entityNames?.singular}?`}
                          okText="Yes"
                          cancelText="No"
                          onConfirm={() => deleteItem(record?.id)}
                        >
                          <Tooltip
                            placement="bottom"
                            title={`Remove ${config?.entityNames?.singular}`}
                          >
                            <Button type="danger" icon={<DeleteOutlined />} />
                          </Tooltip>
                        </Popconfirm>
                      </Space>
                    </>
                  )}

                  {!hasPermission && can_view_project && (
                  <Space>
                      <Tooltip
                        placement="bottom"
                        title={`View ${config?.entityNames?.singular}`}
                      >
                        <Button
                          icon={<EyeOutlined />}
                          onClick={() =>
                            navigate(
                              `/quick-projects/${config?.slugs?.plural}/view/${record?.id}`
                            )
                          }
                        />
                      </Tooltip>

                      {(can_view_gantt) && (
                        <Tooltip
                          placement="bottom"
                          title={`View ${config?.entityNames?.singular} Gantt Chart`}
                        >
                          <Button
                            icon={<BsBarChartSteps style={{ margin: "0 auto" }} />}
                            onClick={() =>
                              navigate(`/quick-projects/gantt-chart?projectId=${record?.id}`)
                            }
                          />
                        </Tooltip>
                      )}
                    </Space>
                  )}
                </div>
              </Card>
            );
          })}
        </div>
      )}

      {!loading && hasData && mode === "list" && (
        <DataTable
          data={data}
          config={{
            ...config,
            columns: config?.columns
              ?.filter(
                (column) =>
                  column?.title === "Actions" ||
                  selectedColumns?.indexOf(column?.dataIndex) > -1
              )
              ?.map((column) => ({
                ...column,
                ...getColumnProps(column),
              }))
              ?.concat({
                title: "Actions",
                width: 100,
                selectable: false,
                render: (record) => {

                  const hasPermission = doesHavePermission(record);
                  const can_view_gantt = ganttChartPermission(record);

                  const actualStartDate = new Date(record?.start_date);
                  const currentDate = new Date();
                  const showEditButton = actualStartDate >= currentDate;

                  const canViewUids = record?.can_view_project?.data?.length > 0 && record?.can_view_project?.data?.map(
                    (item) => item?.attributes?.uid
                  );
      
                  const can_view_project = canViewUids?.length > 0 ? canViewUids?.includes(user?.uid) : false;

                  return (
                    <Space>
                      {hasPermission && (
                        <>
                          {showEditButton && (
                            <Tooltip
                              placement="bottom"
                              title={`Edit ${config?.entityNames?.singular}`}
                            >
                              <Button
                                icon={<EditOutlined />}
                                onClick={() =>
                                  navigate(
                                    `/quick-projects/${config?.slugs?.plural}/edit/${record?.id}`
                                  )
                                }
                              />
                            </Tooltip>
                          )}

                          {(can_view_gantt && hasPermission) && (
                            <Tooltip
                              placement="bottom"
                              title={`View ${config?.entityNames?.singular} Gantt Chart`}
                            >
                              <Button
                                icon={<BsBarChartSteps style={{ margin: "0 auto" }} />}
                                onClick={() =>
                                  navigate(`/quick-projects/gantt-chart?projectId=${record?.id}`)
                                }
                              />
                            </Tooltip>
                          )}

                          <Tooltip
                            placement="bottom"
                            title={`View ${config?.entityNames?.singular} Discussion`}
                          >
                            <Button
                              icon={
                                <BsChatSquareText
                                  style={{ margin: "0 auto" }}
                                />
                              }
                              onClick={() =>
                                navigate(
                                  `/quick-talks/project-chats?conversation=${record?.id}`
                                )
                              }
                            />
                          </Tooltip>

                          {/* <Tooltip placement="bottom" title={`Download Report`}>
                        <ReportDocumentContainer projectId={record?.id} />
                      </Tooltip> */}

                          <Popconfirm
                            title={`Are you sure to remove this ${config?.entityNames?.singular}?`}
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => deleteItem(record?.id)}
                          >
                            <Tooltip
                              placement="bottom"
                              title={`Remove ${config?.entityNames?.singular}`}
                            >
                              <Button type="danger" icon={<DeleteOutlined />} />
                            </Tooltip>
                          </Popconfirm>
                        </>
                      )}

                      {!hasPermission && can_view_project && (
                        <>
                          <Tooltip
                            placement="bottom"
                            title={`View ${config?.entityNames?.singular}`}
                          >
                            <Button
                              icon={<EyeOutlined />}
                              onClick={() =>
                                navigate(
                                  `/quick-projects/${config?.slugs?.plural}/view/${record?.id}`
                                )
                              }
                            />
                          </Tooltip>

                          {(can_view_gantt) && (
                            <Tooltip
                              placement="bottom"
                              title={`View ${config?.entityNames?.singular} Gantt Chart`}
                            >
                              <Button
                                icon={<BsBarChartSteps style={{ margin: "0 auto" }} />}
                                onClick={() =>
                                  navigate(`/quick-projects/gantt-chart?projectId=${record?.id}`)
                                }
                              />
                            </Tooltip>
                          )}
                        </>
                      )}
                    </Space>
                  );
                },
              }),
          }}
          loading={loading}
          pagination={pagination}
          actions={{
            onAdd: () =>
              navigate(`/quick-projects/${config?.slugs?.plural}/add`),
            onRefresh: refreshData,
          }}
          // Toolbar={
          //   <div className="toolbar">
          //     <Input
          //       value={searchValues?.keyword}
          //       placeholder="Search for keyword..."
          //       onChange={(e) =>
          //         setSearchValues((prev) => ({
          //           ...prev,
          //           keyword: e.target.value,
          //         }))
          //       }
          //     />

          //     <div className="toolbar-buttons">
          //       <p className="toolbar-results">{getResultsMessage()}</p>

          //       <Space>
          //         <Button onClick={onReset}>Reset</Button>
          //         <Button type="primary" onClick={onSearch}>
          //           Search
          //         </Button>
          //       </Space>
          //     </div>
          //   </div>
          // }
          showsTopBar={false}
          showsBreadcrumbs={false}
        />
      )}

      {!loading && !hasData && <Empty />}
    </>
  );
};

const styles = {
  main: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gridGap: 24,
  },
  progress: {
    marginTop: 12,
    marginBottom: 0,
  },
  buttons: {
    marginTop: 12,
  },
  pageHeader: {
    padding: 0,
    paddingBottom: 12,
  },
  loaderContainer: {
    padding: 24,
    textAlign: "center",
  },
};

export default Page;
