import React, { useState, useEffect } from "react";
import { AddForm } from "../../../components";
import { useNavigate, useParams } from "react-router-dom";
import { makeConfig } from "./config";
import { useSelector } from "react-redux";
import { fetchData } from "../../../../../utils/helpers/data.helper";
import { isEmpty, find } from "lodash";
import moment from "moment";
import qs from "qs";
import { axios } from "../../../../../App";

const Page = (props) => {
  const navigate = useNavigate();
  const auth = useSelector((state) => state?.auth);
  const { id } = useParams();
  const config = makeConfig({ navigate, auth });
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    status: ["Pending", "Rejected", "Approved"]?.map((item) => ({
      value: item,
      label: item,
    })),
    all_task_material: [],
    all_task: [],
  });
  const contentTypes = [
    {
      slug: "employees",
      transformer: (item) => ({
        value: item?.id,
        label: `${item?.first_name} ${item?.last_name}`,
        uid: item?.uid,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          employee: data,
        //  approved_by: data,
        })),
    },
     {
      slug: "employees/approvers",
      transformer: (item) => ({
        value: item?.id,
        label: `${item?.first_name} ${item?.last_name}`,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
         approved_by: data,
        })),
    },
    {
      slug: "projects",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          project: data,
        })),
    },
    {
      slug: "tasks",
      query: {
        populate: "*",
      },
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          all_task: data,
        })),
    },
    {
      slug: "task-materials",
      query: {
        populate: "*",
      },
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          all_task_material: data,
        })),
    },
  ];

  const fetchProjectMaterials = (taskId) => {
    fetchData({
      slug: "task-materials",
      query: {
        filters: {
          task: taskId,
          status: "Approved",
        },
        populate: "*",
      },
      onSuccess: (data) => {
        const taskMaterialIds = data?.map(
          (record) => record?.material_item?.data?.id
        );

        setValues((prev) => ({
          ...prev,
          task_material: values?.all_task_material
            ?.filter((item) => taskMaterialIds?.includes(item?.id))
            ?.map((item) => ({
              value: item?.id,
              label:
                item?.material_item?.data?.attributes?.name +
                " x " +
                item?.quantity +
                " (" +
                item?.material_unit?.data?.attributes?.name +
                ")",
            })),
        }));
      },
    });
  };

  const fetchStockLedgers = ({
    antForm,
    materialItemId,
    materialUnitId,
    requiredQuantity,
  }) => {
    fetchData({
      slug: "stock-ledgers",
      query: {
        filters: {
          material_item: materialItemId,
          material_unit: materialUnitId,
        },
        populate: "*",
      },
      onSuccess: (data) => {
        if (!isEmpty(data?.[0])) {
          const inStockQuantity = data?.[0]?.quantity || 0;

          antForm.setFieldValue("in_stock_quantity", inStockQuantity);
          antForm.setFieldValue("stock_ledger", data?.[0]?.id);

          let releasedQuantity = 0;

          if (requiredQuantity <= inStockQuantity) {
            releasedQuantity = requiredQuantity;
          } else {
            releasedQuantity = inStockQuantity;
          }

          antForm.setFieldValue("released_quantity", releasedQuantity);
        }
      },
    });
  };

  useEffect(() => {
    contentTypes?.forEach((contentType) => {
      fetchData(contentType);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const queryObject = {
          populate: "*",
        };

        const response = await axios.get(
          `/${config?.slugs?.plural}/${id}?${qs.stringify(queryObject)}`
        );

        if (response?.data) {
          const projectId = Number(response?.data?.data?.attributes?.project);
          const taskId = Number(response?.data?.data?.attributes?.task);

          if (projectId) {
            setValues((prev) => ({
              ...prev,
              task: values?.all_task
                ?.filter(
                  (item) => item?.project?.data?.id === Number(projectId)
                )
                ?.map((item) => ({
                  value: item?.id,
                  label: item?.name,
                })),
            }));
          }

          if (taskId) {
            setValues((prev) => ({
              ...prev,
              task_material: values?.all_task_material
                ?.filter((item) => item?.task?.data?.id === Number(taskId))
                ?.map((item) => ({
                  value: item?.id,
                  label: item?.material_item?.data?.attributes?.name,
                })),
            }));
          }

          const newData = {
            ...response?.data?.data?.attributes,
            id: response?.data?.data?.id,
            date: moment(response?.data?.data?.attributes?.createdAt)?.format(
              "DD-MM-YYYY"
            ),
            released_quantity: 0,
          };

          setData(newData);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, values?.all_task, values?.all_task_material]);

  return (
    <AddForm
      config={config}
      values={values}
      // data={{
      //   status: "Pending",
      //   employee: find(values?.employee, {
      //     uid: auth?.user?.uid,
      //   })?.value,
      // }}
      data={data}
      onFieldChange={{
        project: (antForm) => {
          const projectId = antForm.getFieldValue("project");

          if (projectId) {
            antForm.setFieldValue("task", undefined);
            antForm.setFieldValue("task_material", undefined);
            antForm.setFieldValue("required_quantity", 0);
            antForm.setFieldValue("in_stock_quantity", 0);
            antForm.setFieldValue("released_quantity", 0);

            setValues((prev) => ({
              ...prev,
              task_material: [],
              required_quantity: 0,
              task: values?.all_task
                ?.filter(
                  (item) => item?.project?.data?.id === Number(projectId)
                )
                ?.map((item) => ({
                  value: item?.id,
                  label: item?.name,
                })),
            }));
          }
        },
        task: (antForm) => {
          const taskId = antForm.getFieldValue("task");

          if (taskId) {
            antForm.setFieldValue("task_material", undefined);
            antForm.setFieldValue("required_quantity", 0);
            antForm.setFieldValue("in_stock_quantity", 0);
            antForm.setFieldValue("released_quantity", 0);

            setValues((prev) => ({
              ...prev,
              required_quantity: 0,
              task_material: values?.all_task_material
                ?.filter((item) => item?.task?.data?.id === Number(taskId))
                ?.map((item) => ({
                  value: item?.id,
                  label: item?.material_item?.data?.attributes?.name,
                })),
            }));
          }
        },
        task_material: (antForm) => {
          const taskMaterialId = antForm.getFieldValue("task_material");

          if (taskMaterialId) {
            antForm.setFieldValue("required_quantity", 0);
            antForm.setFieldValue("in_stock_quantity", 0);
            antForm.setFieldValue("released_quantity", 0);

            const taskMaterial = values?.all_task_material?.find(
              (item) => Number(item?.id) === Number(taskMaterialId)
            );

            antForm.setFieldValue("required_quantity", taskMaterial?.quantity);

            fetchStockLedgers({
              antForm: antForm,
              materialItemId: taskMaterial?.material_item?.data?.id,
              materialUnitId: taskMaterial?.material_unit?.data?.id,
              requiredQuantity: taskMaterial?.quantity,
            });
          }
        },
      }}
    />
  );
};

export default Page;
