import React, { useState, useEffect } from "react";
import { DataTable } from "../../../components";
import { useNavigate } from "react-router-dom";
import { Space, Button, Tooltip, notification, Input, Col } from "antd";
import { EyeOutlined, ReloadOutlined } from "@ant-design/icons";
import qs from "qs";
import config from "./config";
import { axios } from "../../../../../App";
import _ from "lodash";
import { Box, Stack } from "@chakra-ui/react";
import FeaturedReports from '../../Dashboard/components/FeaturedReports'


const Page = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    pageSize: 10,
  });
  const [total, setTotal] = useState(0);
  const [searchValues, setSearchValues] = useState({});

  const fetchData = async (params = null) => {
    try {
      setLoading(true);

      const queryObject = {
        populate: "*",
        sort: ["name:asc"],
        filters: {
          module: "quick-stock",
        },
      };

      if (!_.isEmpty(params)) {
        queryObject["filters"] = params;
      }

      const response = await axios.get(
        `report-queries?${qs.stringify(queryObject)}`
      );

      if (response?.data) {
        setData(
          response?.data?.data?.map((item) => ({
            id: item?.id,
            ...item?.attributes,
          }))
        );

        setTotal(response?.data?.meta?.pagination?.total);

        setPagination({
          ...response?.data?.meta?.pagination,
          pageSize: 10,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const refreshData = () => {
    onSearch();
  };

  const getColumnFilterProps = ({ dataIndex, references = {} }) => {
    if (typeof references?.fieldName === "function") {
      const transformer = references?.fieldName;

      return {
        render: (data) => transformer(data?.data?.attributes) || "N/A",
      };
    } else {
      return {
        render: (data) =>
          data?.data?.attributes?.[references?.fieldName] || "N/A",
      };
    }
  };

  const getColumnProps = (column) => {
    if (column?.references) {
      return getColumnFilterProps(column);
    }

    return column;
  };

  const onSearch = () => {
    fetchData({
      $or: [
        {
          name: {
            $containsi: searchValues?.keyword?.toLowerCase(),
          },
        },
      ],
    });
  };

  const onReset = () => {
    setSearchValues({});
    fetchData(null);
  };

  const getResultsMessage = () => {
    if (!_.isEmpty(searchValues)) {
      return total > 0 ? (
        <span>
          <strong>{total}</strong> matched results
        </span>
      ) : (
        <span>No matched results</span>
      );
    }

    return (
      <span>
        <strong>{total}</strong> total records
      </span>
    );
  };

  useEffect(() => {
    fetchData(filters);
  }, []);

  console.log('fsdf')

  return (
    // <DataTable
    //   data={data}
    //   config={{
    //     ...config,
    //     columns: config?.columns
    //       ?.map((column) => ({
    //         ...column,
    //         ...getColumnProps(column),
    //       }))
    //       ?.concat({
    //         title: "Actions",
    //         fixed: "right",
    //         width: 100,
    //         selectable: false,
    //         render: ({ id, code }) => {
    //           return (
    //             <Space>
    //               <Tooltip
    //                 placement="bottom"
    //                 title={`View ${config?.entityNames?.singular}`}
    //               >
    //                 <Button
    //                   icon={<EyeOutlined />}
    //                   onClick={() =>
    //                     navigate(
    //                       `/quick-stocks/${code
    //                         ?.toLowerCase()
    //                         ?.replace(/_/gi, "-")}-report`
    //                     )
    //                   }
    //                 />
    //               </Tooltip>
    //             </Space>
    //           );
    //         },
    //       }),
    //   }}
    //   loading={loading}
    //   pagination={pagination}
    //   actions={{
    //     onAdd: () => navigate(`/quick-stocks/${config?.slugs?.plural}/add`),
    //     onRefresh: refreshData,
    //   }}
    //   Toolbar={
    //     <div className="toolbar">
    //       <Input
    //         value={searchValues?.keyword}
    //         placeholder="Search for keyword..."
    //         onChange={(e) =>
    //           setSearchValues((prev) => ({
    //             ...prev,
    //             keyword: e.target.value,
    //           }))
    //         }
    //       />

    //       <div className="toolbar-buttons">
    //         <p className="toolbar-results">{getResultsMessage()}</p>

    //         <Space>
    //           <Button onClick={onReset}>Reset</Button>
    //           <Button type="primary" onClick={onSearch}>
    //             Search
    //           </Button>
    //         </Space>
    //       </div>
    //     </div>
    //   }
    //   buttons={[
    //     <Tooltip placement="top" title="Refresh">
    //       <Button
    //         icon={<ReloadOutlined />}
    //         onClick={refreshData}
    //         loading={loading}
    //       />
    //     </Tooltip>,
    //   ]}
    // />
    <>

      <Col span={24}>
        <Stack direction={{ base: "column", md: "row", lg: "row" }}>
          <Box w={{ base: "auto", md: "100%" }}>
            <Col>
              <FeaturedReports />
            </Col>
          </Box>
        </Stack>
      </Col>
    </>
  );
};

export default Page;
