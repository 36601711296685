import React from "react";
import { Route } from "react-router-dom";
import { GstTypeList, GstTypeAdd, GstTypeEdit } from "../pages/GstTypes";

export const register = (props) => {
  const basePath = "gst-types";

  return (
    <>
      <Route path={basePath} element={<GstTypeList />} exact={true} />
      <Route path={`${basePath}/add`} element={<GstTypeAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<GstTypeEdit />} />
    </>
  );
};
