import config from "../config";
import moment from "moment"

const commify = (x) => {
  return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  id: `${config?.slugs?.plural}-list`,
  pageTitle: `Sub Contractor Cost Report`,
  code: "SUB_CONTRACTOR_EXTRA_WORKS",
  breadcrumbs: [
    {
      path: "/quick-stocks/dashboard",
      title: "Home",
    },
    {
      path: `/quick-stocks/reports`,
      title: "Reports",
    },
    {
      path: "",
      title: `Sub Contractor Cost Report`,
    },
  ],
  columns: [
    {
      title: "Name",
      dataIndex: "name",
      selectable: true,
      sorter: (a, b) => a?.name?.localeCompare(b?.name),
      width: 300,
    },
    {
      title: "Project",
      dataIndex: "workOrders",
      selectable: true,
      // render: (text) => console.log('textttttt', text)
      render: (text) => (<span>{text?.[0]?.project?.data?.attributes?.name}</span>)
    },

    {
      title: "Work Order Date",
      dataIndex: "workOrders",
      selectable: true,
      render: (text) => (moment(text?.work_order?.data?.attributes?.createdAt).format("DD-MM-YYYY"))
    },
    {
      title: "Grand Total",
      dataIndex: "workOrdersTotal",
      selectable: true,
      // render: (text) => commify(text?.grand_total || 0),
      // sorter: (a, b) => a?.grand_total - b?.grand_total,
    },
    {
      title: "Paid",
      dataIndex: "paymentScheduleTotal",
      selectable: true,
    },
  ],
  ...config,
};
