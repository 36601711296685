import React from "react";
import { Route } from "react-router-dom";
import {
  PartnerList,
  PartnerAdd,
  PartnerEdit,
  // PartnerReport,
} from "../pages/Partners";

export const register = (props) => {
  const basePath = "partners";

  return (
    <>
      <Route path={basePath} element={<PartnerList />} exact={true} />
      <Route path={`${basePath}/add`} element={<PartnerAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<PartnerEdit />} />
      {/* <Route path={`${basePath}-report`} element={<PartnerReport />} /> */}
    </>
  );
};
