import React, { useEffect } from "react";
import { Button, Dropdown, Space } from "antd";
import { BsCaretDownFill, BsCaretUpFill, BsChatDots } from "react-icons/bs";
import { HiMenu } from "react-icons/hi";
import moment from "moment";
import { IoDocuments } from "react-icons/io5";
import { FaLayerGroup, FaTools } from "react-icons/fa";
import { FiSettings } from "react-icons/fi";
import { MdPeopleAlt } from "react-icons/md";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import styles from "./styles.module.css";

const LeftPanel = ({
  tasks,
  miscData,
  onMenuClick,
  setActiveTaskId,
  setLatestTime,
  toggleExpandedId,
  expandedIds,
  gantt_update_permissions
}) => {
  const onFocusTask = (e, id) => {
    e.stopPropagation();
    setLatestTime(moment().valueOf());
    setActiveTaskId(id);
  };

  const calcDuration = (startDate, endDate) => {
    return Math.max(
      moment(endDate)
        ?.endOf("day")
        ?.diff(moment(startDate)?.startOf("day"), "days") + 1,
      0
    );
  };

  const renderRow = (item) => {
    return (
      <tr
        key={item?.id}
        onClick={(e) => onFocusTask(e, item?.id)}
        className={item?.milestone_id === "" ? styles.milestoneRow : ""}
      >
        <td
          style={{
            width: 60,
          }}
        >
          <div className={styles.buttons}>
            <Space>
              {item?.milestone_id === "" && (
                <Button
                  icon={
                    expandedIds?.[item?.id] === true ? (
                      <AiOutlineMinus style={{ margin: "0 auto" }} />
                    ) : (
                      <AiOutlinePlus style={{ margin: "0 auto" }} />
                    )
                  }
                  onClick={() => toggleExpandedId(item?.id)}
                />
              )}

              {gantt_update_permissions && (
                <Dropdown
                  menu={{
                    items: [
                      {
                        label: "Edit",
                        key: "edit",
                        icon: <EditOutlined />,
                      },
                      {
                        label: "Materials",
                        key: "materials",
                        icon: <FaLayerGroup />,
                      },
                      {
                        label: "Manpowers",
                        key: "manpowers",
                        icon: <MdPeopleAlt />,
                      },
                      {
                        label: "Machines",
                        key: "machines",
                        icon: <FiSettings />,
                      },
                      {
                        label: "Hand Tools",
                        key: "hand_tools",
                        icon: <FaTools />,
                      },
                      {
                        label: "Documents",
                        key: "documents",
                        icon: <IoDocuments />,
                      },
                      {
                        label: "Comments",
                        key: "comments",
                        icon: <BsChatDots />,
                      },
                      {
                        label: "Remove",
                        key: "remove",
                        icon: <DeleteOutlined />,
                      },
                    ],
                    onClick: (values) => onMenuClick(values?.key, item),
                  }}
                >
                  <Button icon={<HiMenu style={{ margin: "0 auto" }} />} />
                </Dropdown>
              )}
            </Space>
          </div>
        </td>
        <td>
          <span
            className={styles.itemId}
            style={{
              marginLeft: item?.milestone_id !== "" ? 16 : 0,
            }}
          >
            {item?.milestone_id !== "" && (
              <span className={styles.childItemId}>○</span>
            )}

            <span className={styles.itemIdText}>{item?.no}</span>
          </span>
        </td>
        <td>{item?.name}</td>
        <td style={{ textAlign: "center" }}>{item?.type}</td>
        <td>{moment(item?.start_date)?.format("DD-MM-YYYY")}</td>
        <td>{moment(item?.end_date)?.format("DD-MM-YYYY")}</td>
        <td>
          {item?.actual_start_date &&
            moment(item?.actual_start_date)?.format("DD-MM-YYYY")}
        </td>
        <td>
          {item?.actual_end_date &&
            moment(item?.actual_end_date)?.format("DD-MM-YYYY")}
        </td>
        <td style={{ textAlign: "center" }}>
          {Math.max(
            moment(item?.end_date)
              ?.endOf("day")
              ?.diff(moment(item?.start_date)?.startOf("day"), "days") + 1,
            0
          )}
          d
        </td>
        <td style={{ textAlign: "center" }}>
          {!isNaN(
            calcDuration(item?.actual_start_date, item?.actual_end_date)
          ) &&
            `${calcDuration(item?.actual_start_date, item?.actual_end_date)}d`}
        </td>
        <td style={{ textAlign: "center" }}>
          {miscData?.task_progresses?.find((o) => o?.id === item?.id)
            ?.progress || 0}
          %
        </td>
        <td style={{ minWidth: 320 }}>
          {item?.dependencies?.data
            ?.map((item) => item?.attributes?.name)
            ?.join(", ")}
        </td>
        <td style={{ minWidth: 320 }}>
          {item?.assignees?.data
            ?.map(
              (item) =>
                `${item?.attributes?.first_name}
                    ${item?.attributes?.last_name}`
            )
            ?.join(", ")}
        </td>
        <td style={{ textAlign: "center" }}>{item?.estimated_budget || 0}</td>
        <td style={{ minWidth: 320 }}>
          {miscData?.task_materials
            ?.filter((o) => o?.task?.data?.id === item?.id)
            ?.map((o) => o?.material_item?.data?.attributes?.name)
            ?.join(", ")}
        </td>
        <td style={{ minWidth: 320 }}>
          {miscData?.task_manpowers
            ?.filter((o) => o?.task?.data?.id === item?.id)
            ?.map(
              (o) => `${o?.labour} ${o?.manpower_type?.data?.attributes?.name}`
            )
            ?.join(", ")}
        </td>

        <td style={{ minWidth: 320 }}>
          {miscData?.task_machines
            ?.filter((o) => o?.task?.data?.id === parseInt(item?.id))
            ?.map((o) => o?.machine?.data?.attributes?.name)
            ?.join(", ")}
        </td>

        <td style={{ minWidth: 320 }}>
          {miscData?.task_hand_tools
            ?.filter((o) => o?.task?.data?.id === item?.id)
            ?.map((o) => o?.hand_tool?.data?.attributes?.name)
            ?.join(", ")}
        </td>

        <td style={{ minWidth: 320 }}>
          {miscData?.task_documents
            ?.filter((o) => o?.task?.data?.id === item?.id)
            ?.map((o) => o?.name)
            ?.join(", ")}
        </td>
      </tr>
    );
  };

  useEffect(() => {
    setLatestTime(moment().valueOf());
    setActiveTaskId(tasks?.[0]?.id);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log('gantttt left panel', tasks, miscData)

  return (
    <div className={styles.leftPanel}>
      <table className={styles.leftPanelTable}>
        <thead>
          <tr>
            <th rowSpan={2} style={{ width: 60 }}>
              Actions
            </th>
            <th rowSpan={2} style={{ minWidth: 120 }}>
              ID
            </th>
            <th rowSpan={2} style={{ minWidth: 240 }}>
              Name
            </th>
            <th rowSpan={2} style={{ minWidth: 120 }}>
              Type
            </th>
            <th colSpan={2} style={{ minWidth: 220 }}>
              Estimated
            </th>
            <th colSpan={2} style={{ minWidth: 220 }}>
              Actual
            </th>
            <th colSpan={2}>Duration</th>
            <th rowSpan={2}>Completion Percentage</th>
            <th rowSpan={2}>Dependencies</th>
            <th rowSpan={2}>Assignees</th>
            <th rowSpan={2}>Estimated Budget</th>
            <th rowSpan={2}>Materials</th>
            <th rowSpan={2}>Manpowers</th>
            <th rowSpan={2}>Machines</th>
            <th rowSpan={2}>Hand Tools</th>
            <th rowSpan={2}>Documents</th>
          </tr>

          <tr>
            <th>Start</th>
            <th>End</th>
            <th>Start</th>
            <th>End</th>
            <th>Estimated</th>
            <th>Actual</th>
          </tr>
        </thead>

        <tbody>
          {tasks?.map((item) => {
            if (item?.milestone_id === "") {
              return renderRow(item);
            } else {
              if (expandedIds?.[item?.milestone_id] === true) {
                return renderRow(item);
              }
            }
          })}
        </tbody>
      </table>
    </div>
  );
};

export default LeftPanel;
