export default {
  slugs: {
    singular: "payment-mode",
    plural: "payment-modes",
  },
  entityNames: {
    singular: "Payment Mode",
    plural: "Payment Modes",
  },
};
