import { Modal, Form, Input } from "antd";
import React from "react";
import { axios } from "../../../../App";

const AddFolderModal = ({
  visible,
  onClose,
  onFinish,
  currentFileItemId = null,
  moduleName,
}) => {
  const [form] = Form.useForm();

  const onSubmit = async (values) => {
    try {
      const requestData = {
        name: values?.name,
        // module: moduleName,
        item_type: "Folder",
        is_deleted: false,
      };

      if (currentFileItemId) {
        requestData["parent"] = currentFileItemId;
      }

      const response = await axios.post(`/file-items`, {
        data: requestData,
      });

      if (response?.data) {
        form.resetFields();

        onFinish();
        onClose();
      }

      throw new Error();
    } catch (error) {}
  };

  return (
    <Modal
      title="Add New Folder"
      visible={visible}
      onOk={form.submit}
      onCancel={onClose}
      destroyOnClose
    >
      <Form
        form={form}
        name="addFolderForm"
        onFinish={onSubmit}
        autoComplete="off"
        layout="vertical"
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: "Name is required!" }]}
        >
          <Input placeholder="Enter name" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddFolderModal;
