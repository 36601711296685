export default {
  slugs: {
    singular: "report",
    plural: "reports",
  },
  entityNames: {
    singular: "Report",
    plural: "Reports",
  },
};
