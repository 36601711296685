import React from "react";
import { Route } from "react-router-dom";
import {
  WorkOrderGroupList,
  WorkOrderGroupAdd,
  WorkOrderGroupEdit,
} from "../pages/WorkOrderGroups";

export const register = (props) => {
  const basePath = "work-order-groups";

  return (
    <>
      <Route path={basePath} element={<WorkOrderGroupList />} exact={true} />
      <Route path={`${basePath}/add`} element={<WorkOrderGroupAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<WorkOrderGroupEdit />} />
    </>
  );
};
