import React from "react";
import { Gauge } from "@ant-design/plots";

const Chart = ({ data }) => {
  const config = {
    percent: 0,
    range: {
      ticks: [0, 1 / 3, 2 / 3, 1],
      color: ["#F4664A", "#FAAD14", "#30BF78"],
    },
    indicator: {
      pointer: {
        style: {
          stroke: "#D0D0D0",
        },
      },
      pin: {
        style: {
          stroke: "#D0D0D0",
        },
      },
    },
    height: 308,
    ...data,
  };

  return <Gauge {...config} />;
};

export default Chart;
