import config from "../config";
import { Tag } from "antd";
import moment from "moment";

const DATE_TIME_FORMAT = "YYYY-MM-DDTHH:mm:ss[Z]";

const commify = (x) => {
  return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export default {
  id: `${config?.slugs?.plural}-list`,
  pageTitle: config?.entityNames?.plural,
  breadcrumbs: [
    {
      path: "/quick-stocks/dashboard",
      title: "Home",
    },
    {
      path: "",
      title: config?.entityNames?.plural,
    },
  ],
  columns: [
    {
      title: "Task",
      dataIndex: "task",
      selectable: true,
      references: {
        fieldName: "name",
      },
    },
    {
      title: "Material Group",
      dataIndex: "material_group",
      selectable: true,
      references: {
        fieldName: "name",
      },
    },
    {
      title: "Material Item",
      dataIndex: "material_item",
      selectable: true,
      references: {
        fieldName: "name",
      },
    },
    // {
    //   title: "Material Unit",
    //   dataIndex: "material_unit",
    //   selectable: true,
    //   references: {
    //     fieldName: "name",
    //   },
    // },
    {
      title: "Quantity",
      dataIndex: "quantity",
      selectable: true,
      render: (text) => commify(text || 0),
      sorter: (a, b) => a?.quantity - b?.quantity,
    },
    {
      title: "Available Quantity",
      dataIndex: "available_quantity",
      selectable: true,
      render: (text) => commify(text || 0),
      sorter: (a, b) => a?.available_quantity - b?.available_quantity,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      selectable: true,
      render: (text) => commify(text || 0),
      sorter: (a, b) => a?.amount - b?.amount,
    },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   selectable: true,
    //   render: (text) => <Tag>{text}</Tag>,
    // },
    {
      title: "Required Date",
      dataIndex: "createdAt",
      selectable: true,
      sorter: (a, b) =>
        moment(a.createdAt, DATE_TIME_FORMAT).unix() -
        moment(b.createdAt, DATE_TIME_FORMAT).unix(),
      render: (text) => (
        <span>
          {moment(text, DATE_TIME_FORMAT).format("DD-MM-YYYY, HH:mm:ss")}
        </span>
      ),
      width: 250,
    },
  ],
  ...config,
};
