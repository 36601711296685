import config from "../config";

export default {
    id: `${config?.slugs?.plural}-list`,
    pageTitle: `${config?.entityNames?.plural} Report`,
    code: "CALENDAR REPORT",
    breadcrumbs: [
        {
            path: "/quick-stocks/dashboard",
            title: "Home",
        },
        {
            path: "",
            title: config?.entityNames?.plural,
        },
    ],
    columns: [
        {
            title: "Id",
            dataIndex: "id",
            selectable: true,
        },
        {
            title: "Name",
            dataIndex: "name",
            selectable: true,
        },
        {
            title: "Description",
            dataIndex: "description",
            selectable: true,
        },
        {
            title: "Date",
            dataIndex: "date",
            selectable: true,
        },
    ],
    ...config,
};
