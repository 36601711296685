import React from "react";
import { Routes, Route } from "react-router-dom";
import AuthRoute from "../components/AuthRoute";
import NotFound from "../pages/NotFound";
import {
  VendorQuotations,
  AgencyQuotations,
  SubContractorQuotations,
} from "../pages/PrintableForms";

import * as HomeRoutes from "./home.routes";
import * as AuthRoutes from "./auth.routes";
import * as QuickProjectsRoutes from "../modules/projects/routes";
import * as QuickTalksRoutes from "../modules/talks/routes";
import * as QuickTeamsRoutes from "../modules/teams/routes";
import * as QuickStockRoutes from "../modules/stocks/routes";
import * as QuickSetupRoutes from "../modules/setup/routes";
import * as QuickDriveRoutes from "../modules/drive/routes";
import * as AccountManagement from "../modules/account/routes";

const AppRoutes = () => {
  return (
    <Routes>
      {AuthRoutes.register()}

      <Route
        path="/public/agency-quotations/:requirementFormUid/:agencyUid"
        element={<AgencyQuotations />}
      />

      <Route
        path="/public/sub-contractor-quotations/:requirementFormUid/:subContractorUid"
        element={<SubContractorQuotations />}
      />

      <Route
        path="/public/vendor-quotations/:materialRequisitionUid/:vendorUid"
        element={<VendorQuotations />}
      />

      <Route path="/" element={<AuthRoute />}>
        {HomeRoutes.register()}
        {QuickProjectsRoutes.register()}
        {QuickTeamsRoutes.register()}
        {QuickTalksRoutes.register()}
        {QuickStockRoutes.register()}
        {QuickSetupRoutes.register()}
        {QuickDriveRoutes.register()}
        {AccountManagement.register()}
      </Route>

      <Route path="*" element={<NotFound returnPath="/login" />} />
    </Routes>
  );
};

export default AppRoutes;
