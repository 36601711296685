export default {
    slugs: {
        singular: "agencies-boq",
        plural: "agencies-boq",
    },
    entityNames: {
        singular: "Agency Boq",
        plural: "Agencies Boq",
    },
};
