export default {
  slugs: {
    singular: "daily-report",
    plural: "daily-reports",
  },
  entityNames: {
    singular: "Daily Report",
    plural: "Daily Reports",
  },
};
