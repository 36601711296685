const NumberCard = ({ title, value = "--" }) => {
  return (
    <div style={styles.container}>
      <p style={styles.title}>{title}</p>
      <p style={styles.value}>{value}</p>
    </div>
  );
};

const styles = {
  container: {
    textAlign: "center",
    marginBottom: 12,
    padding: 16,
    boxShadow: "0 0 20px 0 rgb(29 31 89 / 10%)",
    borderRadius: 6,
  },
  title: {
    color: "gray",
    fontSize: 15,
    margin: 0,
  },
  value: {
    fontSize: 24,
    fontWeight: "bold",
    margin: 0,
  },
};

export default NumberCard;
