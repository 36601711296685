// eslint-disable-next-line import/no-anonymous-default-export
export default {
  container: {
    width: "100%",
  },
  filters: {
    background: "white",
    marginBottom: 16,
    padding: 24,
    boxShadow: "0 0 20px 0 rgb(29 31 89 / 10%)",
    borderRadius: 12,
    overflow: "hidden",
  },
  dataTable: {
    marginBottom: 16,
    overflow: "auto"
  },
};
