export const APP_MODULES = [
  {
    id: "quick-projects",
    name: "Quick Projects",
  },
  {
    id: "quick-teams",
    name: "Quick Teams",
  },
  {
    id: "quick-talks",
    name: "Quick Talks",
  },
  {
    id: "quick-stocks",
    name: "Quick Stocks",
  },
  {
    id: "quick-drive",
    name: "Quick Drive",
  },
  {
    id: "quick-setup",
    name: "Quick Setup",
  },
];
