import React from "react";
import SharedLayout from "../../components/SharedLayout";
import {makeNavbar} from "./nav";
import { Outlet } from "react-router-dom";

const QuickProjects = () => {
  return (
    <SharedLayout menuItem={{ title: "Quick Projects", path: "/quick-projects" }} makeNavbar = {makeNavbar}>
      <Outlet />
    </SharedLayout>
  );
};

export default QuickProjects;
