import config from "../config";
import { Tag } from "antd";

const commify = (x) => {
  return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export default {
  id: `${config?.slugs?.plural}-list`,
  pageTitle: config?.entityNames?.plural,
  breadcrumbs: [
    {
      path: "/quick-projects/dashboard",
      title: "Home",
    },
    {
      path: "",
      title: config?.entityNames?.plural,
    },
  ],
  columns: [
    {
      title: "Project",
      dataIndex: "project",
      selectable: true,
      references: {
        fieldName: "name",
      },
    },
    // {
    //   title: "Material Group",
    //   dataIndex: "material_group",
    //   selectable: true,
    //   references: {
    //     fieldName: "name",
    //   },
    // },
    {
      title: "Material Item",
      dataIndex: "material_item",
      selectable: true,
      references: {
        fieldName: "name",
      },
    },
    // {
    //   title: "Material Unit",
    //   dataIndex: "material_unit",
    //   selectable: true,
    //   references: {
    //     fieldName: "name",
    //   },
    // },
    {
      title: "Amount",
      dataIndex: "amount",
      selectable: true,
      render: (text) => commify(text || 0),
      sorter: (a, b) => a?.amount - b?.amount,
    },
    {
      title: "Status",
      dataIndex: "status",
      selectable: true,
      render: (text) => <Tag>{text}</Tag>,
    },
  ],
  ...config,
};
