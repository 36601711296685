import React, { useEffect, useState } from "react";
import { AddForm } from "../../../components";
import { useNavigate, useParams } from "react-router-dom";
import { makeConfig } from "./config";
import { axios } from "../../../../../App";
import { fetchData } from "../../../../../utils/helpers/data.helper";
import qs from "qs";
import moment from "moment";
import { v4 } from "uuid";

const Page = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({});
  const [estimatedItems, setEstimatedItems] = useState([]);
  const [actualItems, setActualItems] = useState([]);
  const [issues, setIssues] = useState([]);
  const config = makeConfig({ navigate });
  const contentTypes = [
    {
      slug: "employees",
      transformer: (item) => ({
        value: item?.id,
        label: `${item?.first_name} ${item?.last_name}`,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          employee: data,
        })),
    },
  ];

  // ESTIMATED ITEMS

  const onAddEstimatedItem = (data) => {
    const newData = {
      id: v4(),
      ...data,
      raw_data: data,
    };

    setEstimatedItems((prev) => prev?.concat(newData));
  };

  const onEditEstimatedItem = (id, data) => {
    let newItems = estimatedItems?.map((record) => {
      if (record?.id === id) {
        record = {
          ...data,
          raw_data: data,
        };
      }

      return record;
    });

    setEstimatedItems(newItems);
  };

  const onDeleteEstimatedItem = (id) => {
    setEstimatedItems((prev) => prev?.filter((item) => item?.id !== id));
  };

  // ACTUAL ITEMS

  const transformActualItem = (rawData) => {
    return {
      ...rawData,
      task: values?.task?.find((item) => item?.value === rawData?.task)?.label,
      raw_data: rawData,
    };
  };

  const onAddActualItem = (data) => {
    const newData = {
      id: v4(),
      ...data,
      ...transformActualItem(data),
    };

    setActualItems((prev) => prev?.concat(newData));
  };

  const onEditActualItem = (id, data) => {
    let newItems = actualItems?.map((record) => {
      if (record?.id === id) {
        record = {
          ...data,
          ...transformActualItem(data),
        };
      }

      return record;
    });

    setActualItems(newItems);
  };

  const onDeleteActualItem = (id) => {
    setActualItems((prev) => prev?.filter((item) => item?.id !== id));
  };

  // ISSUES

  const onAddIssue = (data) => {
    const newData = {
      id: v4(),
      ...data,
      raw_data: data,
    };

    setIssues((prev) => prev?.concat(newData));
  };

  const onEditIssue = (id, data) => {
    let newItems = issues?.map((record) => {
      if (record?.id === id) {
        record = {
          ...data,
          raw_data: data,
        };
      }

      return record;
    });

    setIssues(newItems);
  };

  const onDeleteIssue = (id) => {
    setIssues((prev) => prev?.filter((item) => item?.id !== id));
  };

  useEffect(() => {
    contentTypes?.forEach((contentType) => {
      fetchData(contentType);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const queryObject = {
          populate: "*",
        };

        const response = await axios.get(
          `/${config?.slugs?.plural}/${id}?${qs.stringify(queryObject)}`
        );

        if (response?.data) {
          setData({
            ...response?.data?.data?.attributes,
            id: response?.data?.data?.id,
            task: response?.data?.data?.attributes?.task?.data?.id,
            date: moment(response?.data?.data?.attributes?.date),
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <AddForm
      config={config}
      data={{
        ...data,
        estimated_items: estimatedItems,
        actual_items: actualItems,
        issues,
      }}
      loading={loading}
      values={values}
      modals={{
        estimated_items: {
          data: estimatedItems,
          methods: {
            add: onAddEstimatedItem,
            edit: onEditEstimatedItem,
            delete: onDeleteEstimatedItem,
          },
        },
        actual_items: {
          data: actualItems,
          methods: {
            add: onAddActualItem,
            edit: onEditActualItem,
            delete: onDeleteActualItem,
          },
        },
        issues: {
          data: issues,
          methods: {
            add: onAddIssue,
            edit: onEditIssue,
            delete: onDeleteIssue,
          },
        },
      }}
    />
  );
};

export default Page;
