import { axios } from "../../../../../App";
import { v4 } from "uuid";
import config from "../config";
import AddClient from "../AddClient";
import EditClient from "../EditClient";
import AddInvoice from "../AddInvoice";
import EditInvoice from "../EditInvoice";
import { STRAPI_URL } from "../../../../../constants";
import AddShipping from "../AddShipping";
import EditShipping from "../EditShipping";
import AddTerms from "../AddTerm";
import EditTerms from "../EditTerm";

export const makeConfig = ({ navigate, auth }) => ({
  id: `${config?.slugs?.plural}-add`,
  title: `Add New ${config?.entityNames?.singular}`,
  pageTitle: `Add New ${config?.entityNames?.singular}`,
  breadcrumbs: [
    {
      path: "/quick-stocks/dashboard",
      title: "Home",
    },
    {
      path: `/quick-stocks/${config?.slugs?.plural}`,
      title: config?.entityNames?.plural,
    },
    {
      path: "",
      title: `Add New ${config?.entityNames?.singular}`,
    },
  ],
  components: [
    {
      span: 12,
      component: "date",
      label: "Invoice date",
      name: "invoice_date",
      placeholder: "Invoice Date",
      required: true,
      rules: [
        {
          required: true,
          message: "Invoice date is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Invoice Type",
      name: "invoice_type",
      placeholder: "Select Invoice Type",
      required: true,
      rules: [
        {
          required: true,
          message: "Invoice type is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Invoice from",
      name: "invoice_from",
      placeholder: "Name",
      required: true,
      rules: [
        {
          required: true,
          message: "Name is required!",
        },
      ],
    },

    {
      span: 0,
      component: "input",
      label: "Invoice Number",
      name: "invoice_number",
      placeholder: "Invoice Number",
      // required: true,
      // rules: [
      //   {
      //     required: true,
      //     message: "Invoice number is required!",
      //   },
      // ],
    },

    //Po Number Details
    {
      span: 12,
      component: "input",
      label: "PO Number",
      name: "po_number",
      placeholder: "Po Number",
      // required: true,
      // rules: [
      //   {
      //     required: true,
      //     message: "Po number is required!",
      //   },
      //   {
      //     pattern: /^[0-9]+$/,
      //     message: "PO must be a number!",
      //   },
      // ],
    },
    {
      span: 12,
      component: "date",
      label: "PO date",
      name: "po_date",
      placeholder: "Po Date",
      // required: true,
      // rules: [
      //   {
      //     required: true,
      //     message: "Po date is required!",
      //   },
      // ],
    },

    //Po Number Details
    {
      span: 12,
      component: "input",
      label: "OC Number",
      name: "oc_number",
      placeholder: "OC Number",
      required: true,
      rules: [
        {
          required: true,
          message: "OC number is required!",
        },
      ],
    },
    {
      span: 12,
      component: "date",
      label: "OC date",
      name: "oc_date",
      placeholder: "OC Date",
      required: true,
      rules: [
        {
          required: true,
          message: "OC date is required!",
        },
      ],
    },

    // Invoice To Fields
    {
      span: 24,
      component: "repeatable",
      label: "Invoice To",
      name: "invoice_to",
      required: true,
      rules: [
        {
          required: true,
          message: "Invoices is required!",
        },
      ],
      list: [
        {
          name: "Client Name",
          dataIndex: "client_name",
        },
        {
          name: "GSTIN Number",
          dataIndex: "gstin_number",
        },
        {
          name: "Invoice Date",
          dataIndex: "po_date",
        },
        {
          name: "Purchase order Address",
          dataIndex: "po_address",
        },
        {
          name: "Place of Supply",
          dataIndex: "place_of_supply",
        },
      ],
      modalComponents: {
        add: (params) => {
          return <AddClient {...params} />;
        },
        edit: (params) => {
          return <EditClient {...params} />;
        },
      },
    },

    // Shipped To details
    {
      span: 24,
      component: "repeatable",
      label: "Shipped to",
      name: "shipped_to",
      placeholder: "Name",
      required: true,
      rules: [
        {
          required: true,
          message: "Sipping Detail is required!",
        },
      ],
      list: [
        {
          name: "Name",
          dataIndex: "name",
        },
        {
          name: "GSTIN Number",
          dataIndex: "gstin_number",
        },
        {
          name: "Purchase order Address",
          dataIndex: "address",
        },
      ],
      modalComponents: {
        add: (params) => {
          return <AddShipping {...params} />;
        },
        edit: (params) => {
          return <EditShipping {...params} />;
        },
      },
    },

    // All Invoices Detail Box
    {
      span: 24,
      component: "repeatable",
      label: "Invoices",
      name: "invoices",
      required: true,
      rules: [
        {
          required: true,
          message: "Invoices is required!",
        },
      ],
      list: [
        {
          name: "Material Item",
          dataIndex: "material_item",
        },
        {
          name: "Material Image",
          dataIndex: "material_image",
          render: (text) => (
            <img
              src={`${STRAPI_URL}${text?.data?.attributes?.url}`}
              alt="N/A"
              style={{ width: "50px", height: "50px", objectFit: "contain" }}
            />
          ),
        },
        {
          name: "Description",
          dataIndex: "description",
          render: (text) => (
            <p dangerouslySetInnerHTML={{ __html: text || "N/A" }} />
          ),
        },
        {
          name: "HSN/SAC Code",
          dataIndex: "hsn_code",
        },
        {
          name: "Quantity",
          dataIndex: "quantity",
        },
        {
          name: "Rate",
          dataIndex: "rate",
        },
        {
          name: "Taxable Amount",
          dataIndex: "taxable_amount",
        },
        {
          name: "GST",
          dataIndex: "gst_percentage",
        },
        {
          name: "Total",
          dataIndex: "total",
        },
      ],
      modalComponents: {
        add: (params) => {
          return <AddInvoice {...params} />;
        },
        edit: (params) => {
          return <EditInvoice {...params} />;
        },
      },
    },

    //Payment Schedules

    {
      span: 24,
      component: "repeatable",
      label: "Payment Schedules",
      name: "payment_schedule",
      hide_actions: true,

      list: [
        {
          name: "Name",
          dataIndex: "name",
        },
        {
          name: "Material Group",
          dataIndex: "material_group",
          render: (text) => <p>{text?.data?.attributes.name}</p>,
        },
        {
          name: "Description",
          dataIndex: "description",
          render: (text) => (
            <p dangerouslySetInnerHTML={{ __html: text || "N/A" }} />
          ),
        },
        {
          name: "Amount (%)",
          dataIndex: "amount_percentage",
        },
        {
          name: "Amount (₹)",
          dataIndex: "amount",
        },
      ],
      modalComponents: {
        // add: (params) => {
        //     return
        // },
        edit: (params) => {
          return;
        },
        // list: (params) => {
        //   return <AddExistingPaymentSchedule {...params} />;
        // },
      },
    },

    {
      span: 24,
      component: "repeatable",
      label: "Terms & Conditions",
      name: "payment_term",
      // required: true,
      // rules: [
      //     {
      //         required: true,
      //         message: "Terms & Conditions is required!",
      //     },
      // ],
      list: [
        {
          name: "Heading",
          dataIndex: "heading",
        },
        {
          name: "Material Group",
          dataIndex: "material_group",
        },
        {
          name: "Material Item",
          dataIndex: "material_item",
        },
      ],
      modalComponents: {
        add: (params) => {
          return <AddTerms {...params} />;
        },
        edit: (params) => {
          return <EditTerms {...params} />;
        },
      },
    },

    // Bank Details
    {
      span: 12,
      component: "select",
      label: "Bank Account",
      name: "bank",
      placeholder: "Select Bank Account",
      // required: true,
      // rules: [
      //   {
      //     required: true,
      //     message: "Bank Account is required!",
      //   },
      // ],
    },

    {
      span: 24,
      component: "editor",
      label: "Description",
      name: "description",
      placeholder: "Enter description",
      rows: 5,
    },
    // {
    //   span: 24,
    //   component: "input",
    //   type: "hidden",
    //   name: "attachment",
    // },
  ],
  actions: {
    onBack: () => navigate(`/quick-stocks/${config?.slugs?.plural}`),
    onFinish: async (values) => {
      console.log("fndsgbvfsdigvb", values);
      try {
        const response = await axios.post(`/${config?.slugs?.plural}`, {
          data: {
            ...values,
            uid: v4(),
            // creator: auth?.user?.id,
          },
        });

        if (response?.data) {
          return {
            success: true,
            message: `Add new ${config?.entityNames?.singular?.toLowerCase()} successfully!`,
          };
        }

        throw new Error();
      } catch (error) {
        return {
          success: false,
          message: `Add new ${config?.entityNames?.singular?.toLowerCase()} failed!`,
        };
      }
    },
  },
});

// Older Fieldsets
