export default {
  slugs: {
    singular: "project-material",
    plural: "project-materials",
  },
  entityNames: {
    singular: "Project Material",
    plural: "Project Materials",
  },
};
