import config from "../config";

export default {
  id: `${config?.slugs?.plural}-list`,
  pageTitle: config?.entityNames?.plural,
  breadcrumbs: [
    {
      path: "/quick-teams/dashboard",
      title: "Home",
    },
    {
      path: "",
      title: config?.entityNames?.plural,
    },
  ],
  columns: [
    {
      title: "Name",
      dataIndex: "name",
      selectable: true,
      sorter: (a, b) => a?.name?.localeCompare(b?.name),
    },
    // {
    //   title: "Description",
    //   dataIndex: "description",
    //   selectable: true,
    //   sorter: (a, b) => a?.description?.localeCompare(b?.description),
    //   render: (text) =>
    //     text !== "" ? (
    //       <span
    //         className="html-cell"
    //         dangerouslySetInnerHTML={{ __html: text }}
    //       ></span>
    //     ) : (
    //       "N/A"
    //     ),
    // },
  ],
  ...config,
};
