import { axios } from "../../../../../App";
import config from "../config";

export const makeConfig = ({ navigate }) => ({
  id: `${config?.plural}-edit`,
  title: `Edit ${config?.entityNames?.singular}`,
  pageTitle: `Edit ${config?.entityNames?.singular}`,
  breadcrumbs: [
    {
      path: "/quick-setup",
      title: "Home",
    },
    {
      path: `/quick-setup/${config?.slugs?.plural}`,
      title: config?.entityNames?.plural,
    },
    {
      path: "",
      title: `Edit ${config?.entityNames?.singular}`,
    },
  ],
  components: [
    {
      span: 12,
      component: "select",
      label: "Document",
      name: "document",
      placeholder: "Select document",
      required: true,
      rules: [
        {
          required: true,
          message: "Document no is required!",
        },
      ],
    },
    {
      span: 12,
      component: "input",
      label: "Document No",
      name: "document_no",
      required: true,
      placeholder: "Enter document no",
      rules: [
        {
          required: true,
          message: "Document no is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Employee",
      name: "employee",
      placeholder: "Select employee",
      required: true,
      rules: [
        {
          required: true,
          message: "Employee is required!",
        },
      ],
    },
    {
      span: 12,
      component: "uploader",
      label: "Front Side",
      name: "front_side",
      placeholder: "Upload front side",
    },
    {
      span: 12,
      component: "uploader",
      label: "Back Side",
      name: "back_side",
      placeholder: "Upload back side",
    },
    {
      span: 24,
      component: "editor",
      label: "Description",
      name: "description",
      placeholder: "Enter description",
      rows: 5,
    },
    {
      span: 24,
      component: "input",
      type: "hidden",
      name: "front_side",
    },
    {
      span: 24,
      component: "input",
      type: "hidden",
      name: "back_side",
    },
  ],
  actions: {
    onBack: () => navigate(`/quick-setup/${config?.slugs?.plural}`),
    onFinish: async (values, data) => {
      try {
        let dataToSend = {
          ...values,
          front_side: values?.front_side !== "" ? values?.front_side : null,
          back_side: values?.back_side !== "" ? values?.back_side : null,
        };

        Object.keys(dataToSend).forEach((key) => {
          if (dataToSend?.[key] === "") {
            delete dataToSend[key];
          }

          if (dataToSend?.[key]?.data?.id) {
            dataToSend[key] = dataToSend?.[key]?.data?.id;
          }
        });

        const response = await axios.put(
          `/${config?.slugs?.plural}/${data?.id}`,
          {
            data: dataToSend,
          }
        );

        if (response?.data) {
          return {
            success: true,
            message: `Edit ${config?.entityNames?.singular?.toLowerCase()} successfully!`,
          };
        }

        throw new Error();
      } catch (error) {
        return {
          success: false,
          message: error?.response?.data?.error?.message,
        };
      }
    },
  },
  ...config,
});
