import React from "react";
import { Route } from "react-router-dom";
import {
  TaskManpowerList,
  TaskManpowerAdd,
  TaskManpowerEdit,
  TaskManpowerReport,
} from "../pages/TaskManpowers";

export const register = (props) => {
  const basePath = "task-manpowers";

  return (
    <>
      <Route path={basePath} element={<TaskManpowerList />} exact={true} />
      <Route path={`${basePath}/add`} element={<TaskManpowerAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<TaskManpowerEdit />} />
      <Route path={`${basePath}-report`} element={<TaskManpowerReport />} />
    </>
  );
};
