import React from "react";
import { Route } from "react-router-dom";
import {
  ConsumableList,
  ConsumableAdd,
  ConsumableEdit,
  ConsumableReport,
} from "../pages/Consumables";

export const register = (props) => {
  const basePath = "consumables";

  return (
    <>
      <Route path={basePath} element={<ConsumableList />} exact={true} />
      <Route path={`${basePath}/add`} element={<ConsumableAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<ConsumableEdit />} />
      <Route path={`${basePath}-report`} element={<ConsumableReport />} />
    </>
  );
};
