import React from "react";

const Card = ({ name, value }) => {
  return (
    <div style={styles.card}>
      <p style={styles.cardValue}>{value}</p>
      <h3 style={styles.cardName}>{name}</h3>
    </div>
  );
};

const Numbers = ({ data = [] }) => {
  return (
    <div style={styles.container}>
      {data?.length > 0 &&
        data?.map((item) => <Card name={item?.name} value={item?.value} />)}
    </div>
  );
};

const styles = {
  container: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: 16,
    minHeight: 308,
  },
  card: {
    padding: "3px 0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  cardName: {
    margin: 0,
    padding: 0,
    fontSize: 13,
    color: "gray",
  },
  cardValue: {
    fontWeight: "bold",
    fontSize: 24,
    margin: 0,
    padding: 0,
  },
};

export default Numbers;
