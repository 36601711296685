import React, { useState, useEffect } from "react";
import {
  PageHeader,
  Breadcrumb,
  Row,
  Col,
  Form,
  Space,
  Button,
  notification,
} from "antd";
import FormElement from "../FormElement";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import _ from "lodash";
import { Box, Stack } from "@chakra-ui/react";

const AddForm = ({
  loading = false,
  config,
  data = {},
  values = {},
  disabled = false,
  extra = null,
  modals = {},
  visibility = {},
  showsCancel = true,
  onStateChange = () => { },

}) => {
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);
  const [formInternalData, setFormInternalData] = useState({});

  const handleFinish = async (values) => {
    setSubmitting(true);
    const response = await config?.actions?.onFinish(values, data);

    if (response?.success) {
      notification["success"]({
        message: config?.title,
        description: response?.message,
      });

      if (config?.actions?.onBack) {
        config?.actions?.onBack();
      }
    } else {
      notification["error"]({
        message: config?.title,
        description: response?.message,
      });
    }

    setSubmitting(false);
  };

  useEffect(() => {
    if (!_.isEmpty(data)) {
      form.setFieldsValue(data);
      setFormInternalData(data);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (formInternalData?.state) {
      const selected_state = values?.state?.filter(state => state.value == formInternalData?.state);
      if (selected_state?.length > 0) {
        onStateChange(selected_state?.[0]);
      }
    }
  }, [formInternalData.state]);

  console.log('formmmm', formInternalData)

  return (
    <>
      <Helmet>
        <title>{config?.pageTitle}</title>
      </Helmet>

      <Breadcrumb style={styles.breadcrumbs}>
        {config?.breadcrumbs?.map((breadcrumb, index) => (
          <Breadcrumb.Item key={index}>
            <Link to={breadcrumb?.path}>{breadcrumb?.title}</Link>
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>

      <PageHeader
        title={config?.title}
        style={styles.pageHeader}
        onBack={config?.actions?.onBack}
      ></PageHeader>

      <Form
        form={form}
        name={config?.id}
        layout="vertical"
        onFinish={handleFinish}
        initialValues={data}
        style={styles.form}
        scrollToFirstError
        disabled={disabled ? disabled : loading}
        onValuesChange={(_, allValues) => {
          setFormInternalData(allValues);
        }}
      >
        <Box display={{ base: "none", md: "block", lg: "block" }}>
          <Row gutter={24}>
            {config?.components.map((component) => {
              return (
                <Col span={component?.span} key={component?.name}>
                  <FormElement
                    {...component}
                    values={values}
                    form={form}
                    modals={modals}
                    data={data}
                    visibility={visibility}
                    formInternalData={formInternalData}
                    disabled={disabled ? disabled : loading}
                  />
                </Col>
              );
            })}
          </Row>
        </Box>

        <Box display={{ base: "block", md: "none", lg: "none" }}>
          {config?.components.map((component) => {
            return (
              <Stack span={component?.span} key={component?.name}>
                <FormElement
                  {...component}
                  values={values}
                  form={form}
                  modals={modals}
                  data={data}
                  visibility={visibility}
                  formInternalData={formInternalData}
                  disabled={disabled ? disabled : loading}
                />
              </Stack>
            );
          })}
        </Box>

        {extra}

        <Space>
          {showsCancel && (
            <Button onClick={config?.actions?.onBack}>Cancel</Button>
          )}

          <Button type="primary" htmlType="submit" loading={submitting}>
            Save
          </Button>
        </Space>
      </Form>
    </>
  );
};

const styles = {
  pageHeader: {
    padding: 0,
    paddingBottom: 24,
  },
  form: {
    width: "100%",
  },
};

export default AddForm;
