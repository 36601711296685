import React from "react";
import { Route } from "react-router-dom";
import { TdsTypeList, TdsTypeAdd, TdsTypeEdit } from "../pages/TdsTypes";

export const register = (props) => {
  const basePath = "tds-types";

  return (
    <>
      <Route path={basePath} element={<TdsTypeList />} exact={true} />
      <Route path={`${basePath}/add`} element={<TdsTypeAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<TdsTypeEdit />} />
    </>
  );
};
