import { axios } from "../../../../../App";
import { v4 } from "uuid";
import config from "../config";

export const makeConfig = ({ navigate, auth }) => ({
  id: `${config?.slugs?.plural}-add`,
  title: `Add New ${config?.entityNames?.singular}`,
  pageTitle: `Add New ${config?.entityNames?.singular}`,
  breadcrumbs: [
    {
      path: "/quick-setup",
      title: "Home",
    },
    {
      path: `/quick-setup/${config?.slugs?.plural}`,
      title: config?.entityNames?.plural,
    },
    {
      path: "",
      title: `Add New ${config?.entityNames?.singular}`,
    },
  ],
  components: [
    {
      span: 12,
      component: "input",
      label: "Name",
      name: "name",
      required: true,
      placeholder: "Enter name",
      rules: [
        {
          required: true,
          message: "Name is required!",
        },
      ],
    },
    {
      span: 12,
      component: 'uploader',
      label: 'Image File',
      name: 'image_file',
      placeholder: 'Upload image file',
      required: true,
      accept: 'image/*',
      rules: [
        {
          required: true,
          message: 'Image file is required!',
        },
      ],
    },
    {
      span: 24,
      component: "editor",
      label: "Description",
      name: "description",
      placeholder: "Enter description",
      rows: 5,
    },
    {
      span: 24,
      component: "input",
      type: "hidden",
      name: "image_file",
    },
  ],
  actions: {
    onBack: () => navigate(`/quick-setup/${config?.slugs?.plural}`),
    onFinish: async (values) => {
      try {
        const response = await axios.post(`/${config?.slugs?.plural}`, {
          data: {
            ...values,
            uid: v4(),
            imge_file: values?.image_file !== "" ? values?.image_file : null,
            // creator: auth?.user?.id,
          },
        });

        if (response?.data) {
          return {
            success: true,
            message: `Add new ${config?.entityNames?.singular?.toLowerCase()} successfully!`,
          };
        }

        throw new Error();
      } catch (error) {
        return {
          success: false,
          message: `Add new ${config?.entityNames?.singular?.toLowerCase()} failed!`,
        };
      }
    },
  },
});
