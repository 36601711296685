export default {
  slugs: {
    singular: "employee-qualification",
    plural: "employee-qualifications",
  },
  entityNames: {
    singular: "Employee Qualification",
    plural: "Employee Qualifications",
  },
};
