import React from "react";
import { Route } from "react-router-dom";
import { PayrollList, PayrollView } from "../pages/Payrolls";
import PayrollReport from "../pages/Payrolls/Report";

export const register = (props) => {
  const basePath = "payrolls";

  return (
    <>
      <Route path={basePath} element={<PayrollList />} exact={true} />
      <Route path={`${basePath}/view/:id`} element={<PayrollView />} />
      <Route path={`${basePath}-report`} element={<PayrollReport />} />

    </>
  );
};
