import React, { useEffect, useState } from "react";
import { AddForm } from "../../../components";
import { useNavigate, useParams } from "react-router-dom";
import { makeConfig } from "./config";
import { axios } from "../../../../../App";
import { fetchData } from "../../../../../utils/helpers/data.helper";
import qs from "qs";
import { isEmpty } from "lodash";

const Page = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({});
  const config = makeConfig({ navigate });
  const contentTypes = [
    {
      slug: "vendor-groups",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          vendor_group: data,
        })),
    },
    {
      slug: "firm-types",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          firm_type: data,
        })),
    },
    {
      slug: "states",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
        code: item?.code,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          state: data,
        })),
    },
    {
      slug: "cities",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          city: data,
        })),
    },
    {
      slug: "banks",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          bank: data,
        })),
    },
    {
      slug: "gst-types",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          gst_type: data,
        })),
    },
    {
      slug: "tds-types",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          tds_type: data,
        })),
    },
  ];

  useEffect(() => {
    contentTypes?.forEach((contentType) => {
      fetchData(contentType);
    });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const queryObject = {
          populate: "*",
        };

        const response = await axios.get(
          `/${config?.slugs?.plural}/${id}?${qs.stringify(queryObject)}`
        );

        if (response?.data) {
          let newData = {
            ...response?.data?.data?.attributes,
            id: response?.data?.data?.id,
            city: response?.data?.data?.attributes?.city?.data?.id,
            state: response?.data?.data?.attributes?.state?.data?.id,
            bank: response?.data?.data?.attributes?.bank?.data?.id,
            // account_no: response?.data?.data?.attributes?.account_no?.data?.id,
            vendor_group:
              response?.data?.data?.attributes?.vendor_group?.data?.id,
            firm_type: response?.data?.data?.attributes?.firm_type?.data?.id,
            gst_type: response?.data?.data?.attributes?.gst_type?.data?.id,
            tds_type: response?.data?.data?.attributes?.tds_type?.data?.id,
          };

          if (
            isEmpty(newData?.registration_document) ||
            isEmpty(newData?.registration_document?.data)
          ) {
            newData["registration_document"] = null;
          }

          if (
            isEmpty(newData?.gsin_document) ||
            isEmpty(newData?.gsin_document?.data)
          ) {
            newData["gsin_document"] = null;
          }

          if (
            isEmpty(newData?.pan_document) ||
            isEmpty(newData?.pan_document?.data)
          ) {
            newData["pan_document"] = null;
          }

          if (
            isEmpty(newData?.ifsc_document) ||
            isEmpty(newData?.ifsc_document?.data)
          ) {
            newData["ifsc_document"] = null;
          }

          if (
            isEmpty(newData?.micr_document) ||
            isEmpty(newData?.micr_document?.data)
          ) {
            newData["micr_document"] = null;
          }

          if (isEmpty(newData?.logo) || isEmpty(newData?.logo?.data)) {
            newData["logo"] = null;
          }

          if (isEmpty(newData?.header) || isEmpty(newData?.header?.data)) {
            newData["header"] = null;
          }

          setData(newData);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const fetchCities = (stateCode) => {
    const query = stateCode?.code
      ? { filters: { code: stateCode?.code } }
      : {};
      
    fetchData({
      slug: "cities",
      query: query,
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          city: data,
        })),
    });
  };

  const handleStateChange = (stateCode) => {
    fetchCities(stateCode);
  };

  return (
    <AddForm config={config} data={data} loading={loading} values={values} onStateChange={handleStateChange} />
  );
};

export default Page;
