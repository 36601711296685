import { axios } from "../../../../../App";
import { v4 } from "uuid";
import config from "../config";

export const makeConfig = ({ navigate, auth }) => ({
  id: `${config?.slugs?.plural}-add`,
  title: `Add New ${config?.entityNames?.singular}`,
  pageTitle: `Add New ${config?.entityNames?.singular}`,
  breadcrumbs: [
    {
      path: "/quick-setup",
      title: "Home",
    },
    {
      path: `/quick-setup/${config?.slugs?.plural}`,
      title: config?.entityNames?.plural,
    },
    {
      path: "",
      title: `Add New ${config?.entityNames?.singular}`,
    },
  ],
  components: [
    {
      span: 12,
      component: "input",
      label: "Username",
      name: "username",
      required: true,
      placeholder: "Enter username",
      rules: [
        {
          required: true,
          message: "Username is required!",
        },
        {
          min: 3,
          message: "Username must have at least 3 characters!",
        },
        {
          pattern: new RegExp("^[A-Za-z0-9_]*$"),
          message: [
            "Only lowercase/ uppercase letters, digits and underscores are allowed!",
          ],
        },
      ],
    },
    {
      span: 12,
      component: "input",
      label: "Password",
      name: "password",
      required: true,
      placeholder: "Enter password",
      rules: [
        {
          required: true,
          message: "Password is required!",
        },
        {
          min: 6,
          message: "Password must have at least 6 characters!",
        },
      ],
      type: "password",
    },
    {
      span: 12,
      component: "select",
      label: "Roles",
      name: "app_roles",
      placeholder: "Select roles",
      required: true,
      rules: [
        {
          required: true,
          message: "Roles is required!",
        },
      ],
      mode: "multiple",
    },
    {
      span: 12,
      component: "input",
      label: "Email 1",
      name: "email_1",
      required: true,
      placeholder: "Enter email 1",
      rules: [
        {
          required: true,
          message: "Email 1 is required!",
        },
      ],
    },
    {
      span: 12,
      component: "input",
      label: "Email 2",
      name: "email_2",
      placeholder: "Enter email 2",
    },
  ],
  actions: {
    onBack: () => navigate(`/quick-setup/${config?.slugs?.plural}`),
    onFinish: async (values, data) => {
      try {
        const uid = v4();

        const response = await axios.post(`/${config?.slugs?.plural}`, {
          data: {
            ...values,
            user_type: data?.userType,
            uid,
          },
        });

        if (response) {
          const regResponse = await axios.post(`/auth/local/register`, {
            username: uid,
            email: `${uid}@quickso.in`,
            password: values?.password,
            full_name: uid,
            uid,
          });

          if (data?.userType?.toLowerCase() === "partner") {
            await axios.post(`/partners`, {
              data: {
                uid,
                name: values?.username,
                contact_person: values?.username,
                email_1: values?.email_1,
                email_2: values?.email_2,
              },
            });
          }

          if (data?.userType?.toLowerCase() === "employee") {
            await axios.post(`/employees`, {
              data: {
                uid,
                first_name: values?.username,
                middle_name: '',
                last_name: '',
                email_1: values?.email_1,
                email_2: values?.email_2,
              },
            });
          }

          if (data?.userType?.toLowerCase() === "agency") {
            await axios.post(`/agencies`, {
              data: {
                uid,
                name: values?.username,
                email_1: values?.email_1,
                email_2: values?.email_2,
              },
            });
          }

          if (data?.userType?.toLowerCase() === "sub-contractor") {
            await axios.post(`/sub-contractors`, {
              data: {
                uid,
                name: values?.username,
                email_1: values?.email_1,
                email_2: values?.email_2,
              },
            });
          }

          if (data?.userType?.toLowerCase() === "vendor") {
            await axios.post(`/vendors`, {
              data: {
                uid,
                name: values?.username,
                email_1: values?.email_1,
                email_2: values?.email_2,
              },
            });
          }

          if (regResponse?.data && response?.data) {
            return {
              success: true,
              message: `Add new ${config?.entityNames?.singular?.toLowerCase()} successfully!`,
            };
          }
        }

        throw new Error();
      } catch (error) {
        return {
          success: false,
          message: `Add new ${config?.entityNames?.singular?.toLowerCase()} failed!`,
        };
      }
    },
  },
});
