import { axios } from "../../../../../App";
import config from "../config";
import { notification } from "antd";

const MIN_QUANTITY = 1;
const MIN_AMOUNT = 1;

export const makeConfig = ({ navigate }) => ({
  id: `${config?.plural}-edit`,
  title: `Edit ${config?.entityNames?.singular}`,
  pageTitle: `Edit ${config?.entityNames?.singular}`,
  breadcrumbs: [
    {
      path: "/quick-projects/dashboard",
      title: "Home",
    },
    {
      path: `/quick-projects/${config?.slugs?.plural}`,
      title: config?.entityNames?.plural,
    },
    {
      path: "",
      title: `Edit ${config?.entityNames?.singular}`,
    },
  ],
  components: [
    {
      span: 12,
      component: "select",
      label: "Material Group",
      name: "material_group",
      placeholder: "Select material group",
      required: true,
      rules: [
        {
          required: true,
          message: "Material group is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Material Item",
      name: "material_item",
      placeholder: "Select material item",
      required: true,
      rules: [
        {
          required: true,
          message: "Material item is required!",
        },
      ],
    },
     // {
    //   span: 12,
    //   component: "select",
    //   label: "Material Unit",
    //   name: "material_unit",
    //   placeholder: "Select material unit",
    //   required: true,
    //   rules: [
    //     {
    //       required: true,
    //       message: "Material unit is required!",
    //     },
    //   ],
    // },
    {
      span: 12,
      component: "number",
      label: "Quantity",
      name: "quantity",
      required: true,
      placeholder: "Enter quantity",
      rules: [
        {
          required: true,
          message: "Quantity is required!",
        },
        {
          validator(_, value) {
            if (!value || value < MIN_QUANTITY) {
              return Promise.reject(
                `Quantity must be greater than ${MIN_QUANTITY}`
              );
            }

            return Promise.resolve();
          },
        },
      ],
      min: 0,
    },
    // {
    //   span: 12,
    //   component: "number",
    //   label: "Amount",
    //   name: "amount",
    //   required: true,
    //   placeholder: "Enter amount",
    //   rules: [
    //     {
    //       required: true,
    //       message: "Amount is required!",
    //     },
    //     {
    //       validator(_, value) {
    //         if (!value || value < MIN_AMOUNT) {
    //           return Promise.reject(
    //             `Amount must be greater than ${MIN_AMOUNT}`
    //           );
    //         }

    //         return Promise.resolve();
    //       },
    //     },
    //   ],
    //   min: 0,
    // },
  ],
  actions: {
    onBack: () => navigate(`/quick-projects/${config?.slugs?.plural}`),
    onFinish: async (values, data) => {
      try {
        const response = await axios.put(
          `/${config?.slugs?.plural}/${data?.id}`,
          {
            data: values,
          }
        );

        if (response?.data) {
          if (values?.status === "Rejected") {
            const checkResponse = await axios.get(
              `/stock-ledgers/${values?.material_item}`
            );

            if (checkResponse?.data) {
              const stockAmount =
                checkResponse?.data?.data?.attributes?.quantity;

              const returnResponse = await axios.put(
                `/stock-ledgers/${values?.material_item}`,
                {
                  data: {
                    quantity: stockAmount + values?.amount,
                  },
                }
              );

              if (returnResponse?.data) {
                notification["success"]({
                  message: `Edit ${config?.entityNames?.singular}`,
                  description: `Return items to stock successfully!`,
                });
              }
            }
          }

          return {
            success: true,
            message: `Edit ${config?.entityNames?.singular?.toLowerCase()} successfully!`,
          };
        }
      } catch (error) {
        return {
          success: false,
          message: error?.response?.data?.error?.message,
        };
      }
    },
  },
  ...config,
});