export const makeConfig = () => ({
  id: `employee-chats`,
  title: `Employee Chats`,
  pageTitle: `Employee Chats`,
  breadcrumbs: [
    {
      path: "/quick-talks/dashboard",
      title: "Home",
    },
    {
      path: "",
      title: "Employee Chats",
    },
  ],
  conversationType: "employee",
});
