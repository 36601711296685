import React, { useRef, useEffect, useState } from "react";
import { PageHeader, Breadcrumb, Tooltip, Button, Space, DatePicker, Col, Input, Row, Tabs, Progress, Table } from "antd";
import config from "./config";
import { useNavigate, Link } from "react-router-dom";
import { Box, border } from "@chakra-ui/react";
import pluralize from "pluralize";
import { ReloadOutlined, DownloadOutlined } from "@ant-design/icons";
import _ from "lodash";
import qs from "qs";
import moment from "moment";
import { useSelector } from "react-redux";
import { MAX_PAGINATION_LIMIT, STRAPI_URL } from "../../../../../constants";
import { ReportDataTable } from "../../../components";
import { Bar, Column } from "@ant-design/plots";
import { axios } from "../../../../../App";
import TeamAvailabilityTab from "../../GanttChart/components/TeamAvailabilityTab";
import TimelineTab from "../../GanttChart/components/TimelineTab";

const { TabPane } = Tabs;

const DATE_TIME_FORMAT = "YYYY-MM-DDTHH:mm:ss[Z]";

const Page = (props) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    pageSize: 10,
  });
  const [total, setTotal] = useState(0);
  const [searchValues, setSearchValues] = useState({});
  const [searched, setSearched] = useState(false);
  const { user } = useSelector((state) => state?.auth);
  const [chartData, setchartData] = useState([]);
  const [project_tasks_data, setProject_tasks_data] = useState(null);
  const [projectId, setprojectId] = useState(0)
  const [dailyReports, setDailyReports] = useState([]);
  const [taskMaterials, settaskMaterials] = useState([]);
  const [task_images, setTask_images] = useState([]);

  const fetchData = async (params = null) => {
    try {
      setLoading(true);

      const queryObject = {
        populate: "*",
        sort: ["name:asc"],
        pagination: {
          limit: MAX_PAGINATION_LIMIT,
        },
      };

      if (!_.isEmpty(params)) {
        queryObject["filters"] = params;
      }

      const response = await axios.get(
        `${config?.slugs?.plural}?${qs.stringify(queryObject)}`
      );

      if (response?.data) {
        setData(
          response?.data?.data?.map((item) => ({
            id: item?.id,
            ...item?.attributes,
          }))
        );

        setchartData(
          response?.data?.data.flatMap(item => [
            {
              name: item.attributes.name,
              type: 'Estimated Budget',
              value: item.attributes.estimated_budget,
            },
            {
              name: item.attributes.name,
              type: 'Sanctioned Budget',
              value: item.attributes.sanctioned_budget,
            }
          ])
        );


        setTotal(response?.data?.meta?.pagination?.total);

        setPagination({
          ...response?.data?.meta?.pagination,
          pageSize: 10,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const refreshData = () => {
    onSearch();
  };

  const downloadReport = () => {
    try {
      const data = {
        code: config?.code,
        params: {
          start_date: searchValues?.start_date
            ? searchValues?.start_date?.format("YYYY-MM-DD")
            : moment().subtract(1000, "years").format("YYYY-MM-DD"),
          end_date: searchValues?.end_date
            ? searchValues?.end_date?.format("YYYY-MM-DD")
            : moment().add(1000, "years").format("YYYY-MM-DD"),
        },
      };

      const link = document.createElement("a");
      link.href = `${process.env.REACT_APP_BASE_API_URL
        }/reporter?${qs.stringify(data)}`;

      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.log(error);
    }
  };

  const getColumnFilterProps = ({ dataIndex, references = {} }) => {
    if (typeof references?.fieldName === "function") {
      const transformer = references?.fieldName;

      return {
        render: (data) => transformer(data?.data?.attributes) || "N/A",
      };
    } else {
      return {
        render: (data) =>
          data?.data?.attributes?.[references?.fieldName] || "N/A",
      };
    }
  };

  const getColumnProps = (column) => {
    if (column?.references) {
      return getColumnFilterProps(column);
    }

    return column;
  };

  const onSearch = () => {
    setSearched(true);

    fetchData({
      $and: [
        {
          $or: [
            {
              name: {
                $containsi: searchValues?.keyword?.toLowerCase(),
              },
            },
            {
              pin_code: {
                $containsi: searchValues?.keyword?.toLowerCase(),
              },
            },
            {
              rera_no: {
                $containsi: searchValues?.keyword?.toLowerCase(),
              },
            },
            {
              address_line_1: {
                $containsi: searchValues?.keyword?.toLowerCase(),
              },
            },
            {
              address_line_2: {
                $containsi: searchValues?.keyword?.toLowerCase(),
              },
            },
            {
              description: {
                $containsi: searchValues?.keyword?.toLowerCase(),
              },
            },
          ],
        },
        {
          start_date: {
            $gte: searchValues.start_date.format("YYYY-MM-DD"),
          },
        },
        {
          end_date: {
            $lte: searchValues.end_date.format("YYYY-MM-DD"),
          },
        },

        getFiltersForRoles(),
      ],
    });
  };

  const onReset = () => {
    setSearchValues({});
    fetchData(getFiltersForRoles());
    setSearched(false);
  };

  const getResultsMessage = () => {
    if (!_.isEmpty(searchValues) && !loading && searched) {
      return total > 0 ? (
        <span>
          <strong>{total}</strong> matched results
        </span>
      ) : (
        <span>No matched results</span>
      );
    }

    return (
      <span>
        <strong>{total}</strong> total records
      </span>
    );
  };

  const renderHeaderButtons = () => {
    let buttons = [
      <Tooltip placement="top" title="Refresh">
        <Button
          icon={<ReloadOutlined />}
          onClick={refreshData}
          loading={loading}
        />
      </Tooltip>,
    ];

    // if (data?.length > 0) {
    //   buttons.push(
    //     <Tooltip placement="top" title="Download Report">
    //       <Button icon={<DownloadOutlined />} onClick={downloadReport} />
    //     </Tooltip>
    //   );
    // }

    return buttons;
  };

  const getFiltersForRoles = () => {
    const userType = user?.user_type?.toLowerCase()?.replace(/\s/i, "_");

    if (user?.is_admin) {
      return {};
    }

    if (userType === "employee") {
      return {
        $or: [
          {
            project_managers: {
              uid: user?.uid,
            },
          },
          {
            members: {
              uid: user?.uid,
            },
          },
        ],
      };
    }

    return {
      [pluralize(userType)]: {
        uid: user?.uid,
      },
    };
  };

  const handleRowClick = async (data) => {
    setprojectId(data);

    const taskObject = {
      populate: "*",
      pagination: {
        limit: MAX_PAGINATION_LIMIT,
      },
      filters: {
        project: {
          id: data?.id,
        },
        start_date: {
          $gte: searchValues?.start_date && searchValues.start_date.format("YYYY-MM-DD"),
        },
        end_date: {
          $lte: searchValues?.end_date && searchValues.end_date.format("YYYY-MM-DD"),
        },
      },
    };

    const queryString = qs.stringify(taskObject, { encodeValuesOnly: true });

    try {
      const taskRes = await axios.get(`/tasks?${queryString}`);

      if (taskRes?.data?.data) {
        const transformedChartData = taskRes.data.data.flatMap(item => [
          {
            name: item.attributes.name,
            type: 'Estimated Budget',
            value: item.attributes.estimated_budget,
          },
          {
            name: item.attributes.name,
            type: 'Sanctioned Budget',
            value: item.attributes.sanctioned_budget,
          }
        ]);

        setProject_tasks_data(taskRes.data.data);
        setchartData(transformedChartData);

        // Fetch daily reports for each task
        const dailyReports = await fetchDailyReports();
        const taskMaterials = await fetchTaskMaterials();

        const taskImages = dailyReports?.data?.data?.filter((data) => {
          if (data?.attributes?.attached_photo?.data) {
            return data?.attributes?.attached_photo?.data;

          }
        })

        setDailyReports(dailyReports);
        settaskMaterials(taskMaterials);
        setTask_images(taskImages)
      }
    } catch (error) {
      console.error("Error fetching tasks:", error);
    }
  };

  const fetchDailyReports = async () => {
    try {
      const reportObject = {
        populate: "*",
        filters: {
          createdAt: {
            $gte: searchValues?.start_date && searchValues.start_date.format("YYYY-MM-DD"),
          },
        },
      };
      const queryString = qs.stringify(reportObject, { encodeValuesOnly: true });
      const dailyReports = await axios.get(`/daily-reports?${queryString}`);

      return dailyReports;
    } catch (error) {
      console.error("Error fetching daily reports:", error);
      return [];
    }
  };

  const fetchTaskMaterials = async (tasks) => {
    try {

      const reportObject = {
        populate: "*",
        filters: {
          createdAt: {
            $gte: searchValues?.start_date && searchValues.start_date.format("YYYY-MM-DD"),
          },
        },
      };

      const queryString = qs.stringify(reportObject, { encodeValuesOnly: true });
      const reportRes = await axios.get(`/task-materials?${queryString}`);

      return reportRes;

    } catch (error) {
      console.error("Error fetching daily reports:", error);
      return [];
    }
  };

  useEffect(() => {
    fetchData(getFiltersForRoles());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const barConfig = {
    data: chartData,
    isStack: true,
    xField: 'name',
    yField: 'value',
    seriesField: 'type',
    label: {
      position: 'middle',
      layout: [
        { type: 'interval-adjust-position' },
        { type: 'interval-hide-overlap' },
        { type: 'adjust-color' },
      ],
    },
    yAxis: {
      label: {
        formatter: v => v.toLocaleString(),
      },
    },
    tooltip: {
      formatter: datum => ({ name: datum.type, value: datum.value.toLocaleString() }),
    },
    legend: {
      position: 'top-left',
    },
  };

  const columns = [
    {
      title: "Task No",
      dataIndex: "task_no",
      selectable: true,
      sorter: (a, b) => a?.no?.localeCompare(b?.no),
      width: 150,
    },
    {
      title: 'Task Name',
      dataIndex: 'name',
      key: 'task_name',
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      selectable: true,
      sorter: (a, b) =>
        moment(a.start_date).unix() - moment(b.start_date).unix(),
      render: (text) => <span>{moment(text).format("DD-MM-YYYY")}</span>,
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      selectable: true,
      sorter: (a, b) =>
        moment(a.end_date).unix() - moment(b.end_date).unix(),
      render: (text) => <span>{moment(text).format("DD-MM-YYYY")}</span>,
    },
    {
      title: 'Progress',
      dataIndex: 'progress',
      key: 'progress',
      render: progress => `${progress}%`
    },
    {
      title: 'Workers',
      dataIndex: 'workers',
      key: 'workers',
    }
  ];

  const tableData = project_tasks_data?.map(dep => ({
    key: dep.id,
    task_no: dep.attributes.no,
    name: dep.attributes.name,
    start_date: dep.attributes.start_date,
    end_date: dep.attributes.end_date,
    progress: dep.attributes.progress ?? 0,
    workers: dep.attributes.assignees ? dep.attributes.assignees.data.length : 0 // Assuming workers are stored here
  }));

  const mitigation_columns = [
    {
      title: "Employee",
      dataIndex: "employee",
      selectable: true,
      width: 150,
    },
    {
      title: "Mitigations",
      dataIndex: "mitigation",
      selectable: true,
      width: 150,
    },
    {
      title: 'Issue',
      dataIndex: 'issue',
      key: 'issue',
    },
    {
      title: "Risks",
      dataIndex: "risk",
      key: 'risk'
    },
    {
      title: "Notes",
      dataIndex: "notes",
      key: 'notes'
    },

  ];

  const mitigation_data = dailyReports?.data?.data?.flatMap((report) =>
    report.attributes.issues.map((issue) => ({
      employee: report?.attributes?.employee?.data?.attributes?.first_name,
      reportId: report.id,
      issueId: issue.id,
      risk: issue.risk,
      issue: issue.issue,
      notes: issue.notes,
      mitigation: issue.mitigation,
    }))
  );

  const material_columns = [
    {
      title: "Task",
      dataIndex: "task",
      selectable: true,
      width: 150,
    },
    {
      title: "Material Name",
      dataIndex: "material",
      selectable: true,
      width: 150,
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: 'amount'
    },

  ];

  const material_data = taskMaterials?.data?.data?.flatMap((report) => ({
    task: report?.attributes?.task?.data?.attributes?.name,
    material: report?.attributes?.material_item?.data?.attributes?.name,
    quantity: report?.attributes?.quantity,
    amount: report?.attributes?.amount
  }));

  console.log('project report', task_images, project_tasks_data, dailyReports);

  return (
    <Box>

      <ReportDataTable
        data={data}
        taskData={[tableData, mitigation_data, material_data]}
        columns={[columns, mitigation_columns, material_columns]}
        task_images={task_images}
        handleRowClick={handleRowClick}
        config={{
          ...config,
          columns: config?.columns?.map((column) => ({
            ...column,
            ...getColumnProps(column),
          })),
        }}
        loading={loading}
        pagination={pagination}
        actions={{
          onAdd: () => navigate(`/quick-projects/${config?.slugs?.plural}/add`),
          onRefresh: refreshData,
        }}
        Toolbar={
          <div className="toolbar">
            <Row gutter={16}>
              <Col span={12}>
                <Input
                  value={searchValues?.keyword}
                  placeholder="Search for keyword..."
                  onChange={(e) =>
                    setSearchValues((prev) => ({
                      ...prev,
                      keyword: e.target.value,
                    }))
                  }
                />
              </Col>

              <Col span={3}>
                <DatePicker
                  onChange={(value) =>
                    setSearchValues((prev) => ({
                      ...prev,
                      start_date: value,
                    }))
                  }
                  value={searchValues?.start_date}
                  placeholder="Select start date"
                  style={styles.datePicker}
                />
              </Col>

              <Col span={4}>
                <DatePicker
                  onChange={(value) =>
                    setSearchValues((prev) => ({
                      ...prev,
                      end_date: value,
                    }))
                  }
                  value={searchValues?.endDate}
                  placeholder="Select end date"
                  style={styles.datePicker}
                />
              </Col>
            </Row>

            <div className="toolbar-buttons">
              <p className="toolbar-results">{getResultsMessage()}</p>

              <Space>
                <Button onClick={onReset}>Reset</Button>
                <Button type="primary" onClick={onSearch}>
                  Search
                </Button>
              </Space>
            </div>
          </div>
        }
        buttons={renderHeaderButtons()}
        onBack={() => navigate(`/quick-projects/reports`)}
      />

      <div id="taskTable" style={{ display: 'none' }}>
        <Table columns={columns} dataSource={tableData} pagination={{
          pageSize: 1000,
        }} />
      </div>

      <div id="taskTable" style={{ display: 'none' }}>
        <Table columns={mitigation_columns} dataSource={mitigation_data} pagination={{
          pageSize: 1000,
        }} />
      </div>

      <div id="taskTable" style={{ display: 'none' }}>
        <Table columns={material_columns} dataSource={material_data} pagination={{
          pageSize: 1000,
        }} />
      </div>

      <Table columns={columns} dataSource={tableData} />

      <Table columns={mitigation_columns} dataSource={mitigation_data} />

      <Table columns={material_columns} dataSource={material_data} />

      <div style={styles.productImage}>
        {task_images?.map((task) => (
          <img src={`${STRAPI_URL}${task?.attributes?.attached_photo?.data?.attributes?.url}`} alt="fvdsfg" style={styles.img} />
        ))}
      </div>


      <h2>{projectId?.name}</h2>

      <div id="barGraph">
        <Column {...barConfig} />
      </div>

      {/* {projectId && (
        <>
          <div>
            <Tabs>
              <TabPane tab="Timeline" key="1">
                <TimelineTab projectId={projectId?.id} />
              </TabPane>
            </Tabs>
          </div>

        </>
      )} */}
    </Box>
  );
};

const styles = {
  breadcrumbs: {},
  pageHeader: {
    padding: 0,
    paddingBottom: 12,
  },

  productImage: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    gap: '20px',
    margin: '40px 0'
  },

  img: {
    border: '1px solid black',
    padding: '10px',
    borderRadius: '5px'
  }
};

export default Page;
