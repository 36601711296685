import React from "react";
import SharedLayout from "../../components/SharedLayout";
import {makeNavbar} from "./nav";
import { Outlet } from "react-router-dom";

const QuickStock = () => {
  return (
    <SharedLayout menuItem={{ title: "Quick Stock", path: "/quick-stocks" }} makeNavbar = {makeNavbar}>
      <Outlet />
    </SharedLayout>
  );
};

export default QuickStock;
