export default {
  slugs: {
    singular: "material-brand",
    plural: "material-brands",
  },
  entityNames: {
    singular: "Material Brand",
    plural: "Material Brands",
  },
};
