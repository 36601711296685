import { axios } from "../../../../../App";
import config from "../config";

export const makeConfig = ({ navigate }) => ({
  id: `${config?.plural}-edit`,
  title: `Edit ${config?.entityNames?.singular}`,
  pageTitle: `Edit ${config?.entityNames?.singular}`,
  breadcrumbs: [
    {
      path: "/quick-stocks/dashboard",
      title: "Home",
    },
    {
      path: `/quick-stocks/${config?.slugs?.plural}`,
      title: config?.entityNames?.plural,
    },
    {
      path: "",
      title: `Edit ${config?.entityNames?.singular}`,
    },
  ],
  components: [
    {
      span: 24,
      component: "input",
      label: "Heading",
      name: "heading",
      required: true,
      placeholder: "Enter heading",
      rules: [
        {
          required: true,
          message: "Heading is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Vendor",
      name: "vendor",
      placeholder: "Select vendor",
      required: true,
      rules: [
        {
          required: true,
          message: "Vendor is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Material Group",
      name: "material_group",
      placeholder: "Select material group",
      required: true,
      rules: [
        {
          required: true,
          message: "Material group is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Material Item",
      name: "material_item",
      placeholder: "Select material item",
    },
    // {
    //   span: 12,
    //   component: "select",
    //   label: "Material Unit",
    //   name: "material_unit",
    //   placeholder: "Select material unit",
    // },
    {
      span: 12,
      component: "select",
      label: "Approved By",
      name: "approved_by",
      placeholder: "Select employee",
      required: true,
      rules: [
        {
          required: true,
          message: "Approved by is required!",
        },
      ],
    },
    {
      span: 24,
      component: "editor",
      label: "Description",
      name: "description",
      placeholder: "Enter description",
      rows: 5,
      required: true,
      rules: [
        {
          required: true,
          message: "Description is required!",
        },
      ],
    },
    {
      span: 24,
      component: "editor",
      label: "Approval Remarks",
      name: "approval_remarks",
      placeholder: "Enter approval remarks",
      rows: 5,
    },
  ],
  actions: {
    onBack: () => navigate(`/quick-stocks/${config?.slugs?.plural}`),
    onFinish: async (values, data) => {
      try {
        const response = await axios.put(
          `/${config?.slugs?.plural}/${data?.id}`,
          {
            data: values,
          }
        );

        if (response?.data) {
          return {
            success: true,
            message: `Edit ${config?.entityNames?.singular?.toLowerCase()} successfully!`,
          };
        }

        throw new Error();
      } catch (error) {
        return {
          success: false,
          message: error?.response?.data?.error?.message,
        };
      }
    },
  },
  ...config,
});
