import { axios } from "../../../../../App";
import { v4 } from "uuid";
import config from "../config";

export const makeConfig = ({ navigate, auth }) => ({
  id: `${config?.slugs?.plural}-add`,
  title: `Add New ${config?.entityNames?.singular}`,
  pageTitle: `Add New ${config?.entityNames?.singular}`,
  breadcrumbs: [
    {
      path: "/quick-setup",
      title: "Home",
    },
    {
      path: `/quick-setup/${config?.slugs?.plural}`,
      title: config?.entityNames?.plural,
    },
    {
      path: "",
      title: `Add New ${config?.entityNames?.singular}`,
    },
  ],
  components: [
    {
      span: 8,
      component: "input",
      label: "Name",
      name: "name",
      required: true,
      placeholder: "Enter name",
      rules: [
        {
          required: true,
          message: "Name is required!",
        },
      ],
    },
    {
      span: 8,
      component: "select",
      label: "Initial",
      name: "initial",
      placeholder: "Select initial",
      required: true,
      rules: [
        {
          required: true,
          message: "Initial is required!",
        },
      ],
    },
    {
      span: 8,
      component: "select",
      label: "Company",
      name: "company",
      placeholder: "Select company",
      required: true,
      rules: [
        {
          required: true,
          message: "Company is required!",
        },
      ],
    },
    {
      span: 8,
      component: "input",
      label: "First Name",
      name: "first_name",
      required: true,
      placeholder: "Enter first name",
      rules: [
        {
          required: true,
          message: "First name is required!",
        },
      ],
    },
    {
      span: 8,
      component: "input",
      label: "Middle Name",
      name: "middle_name",
      placeholder: "Enter first name",
    },
    {
      span: 8,
      component: "input",
      label: "Last Name",
      name: "last_name",
      required: true,
      placeholder: "Enter last name",
      rules: [
        {
          required: true,
          message: "Last name is required!",
        },
      ],
    },
    {
      span: 24,
      component: "input",
      label: "Address Line 1",
      name: "address_line_1",
      required: true,
      placeholder: "Enter address line 1",
      rules: [
        {
          required: true,
          message: "Address line 1 is required!",
        },
      ],
    },
    {
      span: 24,
      component: "input",
      label: "Address Line 2",
      name: "address_line_2",
      placeholder: "Enter address line 2",
    },
    {
      span: 12,
      component: "select",
      label: "State",
      name: "state",
      placeholder: "Select state",
      required: true,
      rules: [
        {
          required: true,
          message: "State is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "City",
      name: "city",
      placeholder: "Select city",
      required: true,
      rules: [
        {
          required: true,
          message: "City is required!",
        },
      ],
    },
    {
      span: 12,
      component: "input",
      label: "Phone Number 1",
      name: "phone_number_1",
      placeholder: "Enter phone number 1",
    },
    {
      span: 12,
      component: "input",
      label: "Phone Number 2",
      name: "phone_number_2",
      placeholder: "Enter phone number 2",
    },
    {
      span: 12,
      component: "input",
      label: "Mobile Number 1",
      name: "mobile_number_1",
      required: true,
      placeholder: "Enter mobile number 1",
      rules: [
        {
          required: true,
          message: "Mobile number 1 is required!",
        },
      ],
    },
    {
      span: 12,
      component: "input",
      label: "Mobile Number 2",
      name: "mobile_number_2",
      placeholder: "Enter mobile number 2",
    },
    {
      span: 12,
      component: "input",
      label: "Email 1",
      name: "email_1",
      required: true,
      placeholder: "Enter email 1",
      rules: [
        {
          required: true,
          message: "Email 1 is required!",
        },
      ],
    },
    {
      span: 12,
      component: "input",
      label: "Email 2",
      name: "email_2",
      placeholder: "Enter email 2",
    },
    {
      span: 12,
      component: "input",
      label: "PIN code",
      name: "pin_code",
      placeholder: "Enter PIN code",
      rules: [
        {
          required: true,
          message: "PIN code is required!",
        },
        {
          pattern: /^[0-9]{6}$/,
          message: "PIN code should be 6 digits",
        },
      ],
    },
    {
      span: 12,
      component: "input",
      label: "Registration No",
      name: "registration_no",
      placeholder: "Enter registration no",
    },
    {
      span: 12,
      component: "uploader",
      label: "Registration Document",
      name: "registration_document",
      placeholder: "Upload registration document",
    },
    {
      span: 24,
      component: "input",
      type: "hidden",
      name: "registration_document",
    },
  ],
  actions: {
    onBack: () => navigate(`/quick-setup/${config?.slugs?.plural}`),
    onFinish: async (values) => {
      try {
        const response = await axios.post(`/${config?.slugs?.plural}`, {
          data: {
            ...values,
            uid: v4(),
            // creator: auth?.user?.id,
            registration_document: values?.registration_document || null,
            pan_document: values?.pan_document || null,
            gsin_document: values?.gsin_document || null,
            logo: values?.logo || null,
            header: values?.header || null,
          },
        });

        if (response?.data) {
          return {
            success: true,
            message: `Add new ${config?.entityNames?.singular?.toLowerCase()} successfully!`,
          };
        }

        throw new Error();
      } catch (error) {
        return {
          success: false,
          message: `Add new ${config?.entityNames?.singular?.toLowerCase()} failed!`,
        };
      }
    },
  },
});
