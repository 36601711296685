import React, { useState, useEffect } from "react";
import {
  PageHeader,
  Breadcrumb,
  Row,
  Col,
  Form,
  Space,
  Button,
  notification,
} from "antd";
import FormElement from "../FormElement";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import _ from "lodash";
import { Box, Stack } from "@chakra-ui/react";
import moment from "moment";

const AddForm = ({
  loading = false,
  config,
  data = {},
  values = {},
  disabled = false,
  showExtraButton = false,
  extraButtonTitle,
  extraButtonHandler = () => { },
  mode = "normal",
  onEmbedFinish = () => { },
  onEmbedCancel = () => { },
  modals = {},
  actions = {},
  disabledItems = [],
  visibility = {},
  onFieldChange = () => { },
  onStateChange = () => { },

}) => {
  const [form] = Form.useForm();
  const [allValues, setAllValues] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [formInternalData, setFormInternalData] = useState({});

  // Helper function to check if a date is a holiday
  const filterHolidaysByLocation = (holidays, selectedProject) => {
    const selectedProjectUid = selectedProject?.location?.data?.attributes?.uid;

    return holidays.filter(holiday => {
      const holidayLocations = holiday.attributes.locations.data;

      // If the holiday has no specific location (empty locations array), we include it
      if (holidayLocations.length === 0) {
        return true;
      }

      // If holiday has specific locations, we check if the UID matches the selected project's location UID
      return holidayLocations.some(location => location.attributes.uid === selectedProjectUid);
    });
  };

  const handleFinish = async (value) => {

    if (values?.holidays) {
      const startDate = value.start_date;
      const endDate = value.end_date;
      const selected_projects = values?.project?.find(project => project.value === value?.project)

      const filteredHolidays = filterHolidaysByLocation(values?.holidays, selected_projects);

      const isHoliday = (date) => {
        return filteredHolidays.some(holiday => moment(holiday.date).isSame(date, 'day'));
      };


      // Check if the start date or end date is a holiday
      if (isHoliday(startDate)) {
        notification['error']({
          message: 'Validation Error',
          description: `The start date ${moment(startDate).format('DD MMMM')} falls on a holiday.`,
        });
        return; // Prevent submission if start date is a holiday
      }

      if (isHoliday(endDate)) {
        notification['error']({
          message: 'Validation Error',
          description: `The end date ${moment(endDate).format('DD MMMM')} falls on a holiday.`,
        });
        return; // Prevent submission if end date is a holiday
      }
    }

    setSubmitting(true);
    const response = await config?.actions?.onFinish(value, data);

    if (response) {
      if (response?.success) {
        notification["success"]({
          message: config?.title,
          description: response?.message,
        });

        if (mode === "normal") {
          config?.actions?.onBack();
        } else {
          onEmbedFinish();
          actions?.onCancel();
        }
      } else {
        notification["error"]({
          message: config?.title,
          description: response?.message,
        });
      }

      setSubmitting(false);
    }
  };

  const handleExtra = async () => {
    const response = await extraButtonHandler(allValues, data);

    if (response?.success) {
      notification["success"]({
        message: config?.title,
        description: response?.message,
      });

      config?.actions?.onBack();
    } else {
      notification["error"]({
        message: config?.title,
        description: response?.message,
      });
    }
  };

  const handleValuesChange = (_, inputValues) => {
    setAllValues((prev) => ({
      ...prev,
      ...inputValues,
    }));
  };

  const handleCancel = () => {
    if (mode === "normal") {
      config?.actions?.onBack();
    } else {
      onEmbedCancel(); // TODO: Remove this!
      actions?.onCancel();
    }
  };

  useEffect(() => {
    if (!_.isEmpty(data)) {
      form.setFieldsValue(data);
      setFormInternalData(data);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (formInternalData?.state) {
      const selected_state = values?.state?.filter(state => state.value == formInternalData?.state);
      if (selected_state?.length > 0) {
        onStateChange(selected_state?.[0]);
      }
    }
  }, [formInternalData.state]);

  return (
    <>
      {mode === "normal" && (
        <>
          <Helmet>
            <title>{config?.pageTitle}</title>
          </Helmet>

          <Breadcrumb style={styles.breadcrumbs}>
            {config?.breadcrumbs?.map((breadcrumb, index) => (
              <Breadcrumb.Item key={index}>
                <Link to={breadcrumb?.path}>{breadcrumb?.title}</Link>
              </Breadcrumb.Item>
            ))}
          </Breadcrumb>

          <PageHeader
            title={config?.title}
            style={styles.pageHeader}
            onBack={config?.actions?.onBack}
          ></PageHeader>
        </>
      )}

      <Form
        form={form}
        name={config?.id}
        layout="vertical"
        onFinish={handleFinish}
        initialValues={data}
        style={styles.form}
        scrollToFirstError
        disabled={disabled ? disabled : loading}
        // onValuesChange={handleValuesChange}
        onValuesChange={(_, allValues) => {
          setFormInternalData(allValues);
        }}
      >
        <Box display={{ base: "none", md: "block", lg: "block" }}>
          <Row gutter={24}>
            {config?.components.map((component) => (
              <Col span={component?.span} key={component?.name}>
                <FormElement
                  {...component}
                  values={values}
                  form={form}
                  data={data}
                  modals={modals}
                  disabled={
                    component?.disabled ||
                    disabledItems?.includes(component?.name) ||
                    loading
                  }
                  visibility={visibility}
                  formInternalData={formInternalData}
                  onFieldChange={onFieldChange?.[component?.name]}
                />
              </Col>
            ))}
          </Row>
        </Box>

        <Box display={{ base: "block", md: "none", lg: "none" }}>
          {config?.components.map((component) => (
            <Col span={component?.span} key={component?.name}>
              <FormElement
                {...component}
                values={values}
                form={form}
                data={data}
                modals={modals}
                disabled={
                  component?.disabled ||
                  disabledItems?.includes(component?.name) ||
                  loading
                }
                visibility={visibility}
                formInternalData={formInternalData}
                onFieldChange={onFieldChange?.[component?.name]}
              />
            </Col>
          ))}
        </Box>

        <Space style={styles.buttons}>
          <Space>
            <Button onClick={handleCancel}>Cancel</Button>

            <Button type="primary" htmlType="submit" loading={submitting}>
              Save
            </Button>
          </Space>

          <Space>
            {showExtraButton && (
              <Button htmlType="button" onClick={handleExtra}>
                {extraButtonTitle}
              </Button>
            )}
          </Space>
        </Space>
      </Form>
    </>
  );
};

const styles = {
  pageHeader: {
    padding: 0,
    paddingBottom: 24,
  },
  form: {
    width: "100%",
  },
  buttons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
};

export default AddForm;
