import React, { useEffect, useState } from "react";
import MainForm from "./components/MainForm";
import { useNavigate, useParams } from "react-router-dom";
import { makeConfig } from "./config";
import { axios } from "../../../App";
import qs from "qs";

const Page = (props) => {
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const config = makeConfig({ navigate });
  const { materialRequisitionUid, vendorUid } = useParams();
  const [materialItems, setMaterialItems] = useState([]);
  const [paymentSchedules, setPaymentSchedules] = useState([]);
  const [terms, setTerms] = useState([]);
  const [quotation, setQuotation] = useState({});

  const fetchQuotation = async () => {
    try {
      const queryObject = {
        populate: "*",
        filters: {
          vendor_material_requisition: {
            uid: materialRequisitionUid,
          },
          vendor: {
            uid: vendorUid,
          },
        },
      };

      const response = await axios.get(
        `/vendor-quotations?${qs.stringify(queryObject)}`
      );

      if (response?.data?.data?.length > 0) {
        setQuotation(response?.data?.data?.[0]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchMaterialRequisition = async () => {
    try {
      setLoading(true);

      const queryObject = {
        populate: "*",
        filters: {
          uid: materialRequisitionUid,
        },
      };

      const response = await axios.get(
        `/vendor-material-requisitions?${qs.stringify(queryObject)}`
      );

      if (response?.data) {
        setMaterialItems(
          response?.data?.data?.[0]?.attributes?.material_items || []
        );

        setPaymentSchedules(
          response?.data?.data?.[0]?.attributes?.payment_schedules || []
        );

        setTerms(response?.data?.data?.[0]?.attributes?.terms || []);

        setData({
          ...response?.data?.data?.[0]?.attributes,
          id: response?.data?.data?.[0]?.id,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (materialRequisitionUid && vendorUid) {
      fetchMaterialRequisition();
      fetchQuotation();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [materialRequisitionUid, vendorUid]);

  return (
    <MainForm
      config={config}
      data={{
        ...data,
        materialItems,
        paymentSchedules,
        terms,
        quotation,
      }}
      loading={loading}
      materialRequisitionUid={materialRequisitionUid}
      vendorUid={vendorUid}
    />
  );
};

export default Page;
