import React from "react";
import { Route } from "react-router-dom";
import {
  VendorCreditNoteList,
  VendorCreditNoteAdd,
  VendorCreditNoteReport,
  VendorCreditNoteCreatorEdit,
  VendorCreditNoteApproverEdit,
  VendorCreditNoteView,
} from "../pages/VendorCreditNotes";

export const register = (props) => {
  const basePath = "vendor-credit-notes";

  return (
    <>
      <Route path={basePath} element={<VendorCreditNoteList />} exact={true} />
      <Route path={`${basePath}/add`} element={<VendorCreditNoteAdd />} />
      <Route
        path={`${basePath}/creator/edit/:id`}
        element={<VendorCreditNoteCreatorEdit />}
      />
      <Route
        path={`${basePath}/approver/edit/:id`}
        element={<VendorCreditNoteApproverEdit />}
      />
      <Route path={`${basePath}/view/:id`} element={<VendorCreditNoteView />} />
      <Route path={`${basePath}-report`} element={<VendorCreditNoteReport />} />
    </>
  );
};
