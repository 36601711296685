export default {
  container: {
    minHeight: "100vh",
  },
  logoContainer: {
    // textAlign: "center",
    padding: "9px 20px",
  },
  logo: {
    maxHeight: 42,
  },
  header: {
    background: "white",
    padding: "0 12px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #eee",
  },
  headerTitle: {
    margin: 5,
    padding: 0,
    color: "#cf6c58",
    flex: 1,
    // paddingLeft: 16,
    fontSize: 16,
    fontWeight: "bold",
  },
  sider: {
    background: "#fff",
    minHeight: "100vh",
    position: 'sticky',
    top: 0,
  },
  avatar: {
    backgroundColor: "#f56a00",
    cursor: "pointer",
  },
  menu: {
    height: "100%",
    overflowY: "auto",
  },
  contentContainer: {
    margin: "0 16px",
    marginTop: 18,
  },
  breadcrumbs: {
    margin: "16px 0",
  },
  main: {
    padding: 24,
    minHeight: 360,
  },
  menuToggler: {
    cursor: "pointer",
  },
  avatarContainer: {
    position: "relative",
  },
  onlineDot: {
    height: 10,
    width: 10,
    background: "#52c41a",
    borderRadius: "100%",
    display: "inline-block",
    position: "absolute",
    bottom: -10,
    right: 0,
  },
  menuDivider: {
    background: "#eee",
    height: 32,
  },
  profile: {
    cursor: "pointer",
  },
  icon: {
    fontSize: 24,
    cursor: "pointer",
  },
  notificationIcon: {
    position: "relative",
  },
  notificationCount: {
    position: "absolute",
    top: "-12px",
    right: "-12px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "24px",
    height: "24px",
    borderRadius: "50%",
    backgroundColor: "red",
    color: "white",
    fontSize: "10px",
    cursor: "pointer",
    userSelect: "none",
    fontWeight: "bold",
    // padding: 13,
  },
  menuDivider: {
    background: "#eee",
    height: 32,
  },
  headerTitleLink: {
    textDecoration: "none",
    color: "#cf6c58",
  },
  listItem: {
    cursor: "pointer",
  },
};
