export default {
  slugs: {
    singular: "document",
    plural: "documents",
  },
  entityNames: {
    singular: "Document",
    plural: "Documents",
  },
};
