import React from "react";
import { Pie } from "@ant-design/plots";

const Chart = ({ data = [], angleField, colorField }) => {
  const config = {
    appendPadding: 10,
    data,
    angleField,
    colorField,
    radius: 0.9,
    label: {
      type: "inner",
      offset: "-30%",
      content: (data) => {
        const percentage = data?.percent * 100;
        return `${percentage?.toFixed(2)}%`;
      },
      style: {
        fontSize: 14,
        textAlign: "center",
      },
    },
    interactions: [
      {
        type: "element-active",
      },
    ],
    // legend: false,
    legend: {
      layout: "horizontal",
      position: "bottom",
    },
    height: 308,
    ...data,
  };

  return <Pie {...config} />;
};

export default Chart;
