export default {
  slugs: {
    singular: "manufacturer",
    plural: "manufacturers",
  },
  entityNames: {
    singular: "Manufacturer",
    plural: "Manufacturers",
  },
};
