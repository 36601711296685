export default {
  slugs: {
    singular: "designation",
    plural: "designations",
  },
  entityNames: {
    singular: "Designation",
    plural: "Designations",
  },
};
