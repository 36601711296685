import React from "react";
import { Route, Navigate } from "react-router-dom";
import NotFound from "../../../pages/NotFound";
import QuickTeams from "../index";
import HomePage from "../pages";

import * as AttendanceTypes from "./attendance-types.route";
import * as ContactGroups from "./contact-groups.route";
import * as VisitPurposes from "./visit-purposes.route";
import * as AttendanceModes from "./attendance-modes.route";
import * as Shifts from "./shifts.route";
import * as Dashboard from "./dashboard.route";
import * as AdvancedLoans from "./advanced-loans.route";
import * as PettyCashes from "./petty-cashes.route";
import * as RevisedShifts from "./revised-shifts.route";
import * as Rosters from "./rosters.route";
import * as Reimburses from "./reimburses.route";
import * as LeaveApplications from "./leave-applications.route";
import * as Attendances from "./attendances.route";
import * as Contacts from "./contacts.route";
import * as Visitors from "./visitors.route";
import * as Reports from "./reports.route";
import * as Tickets from "./tickets.route";
import * as Payrolls from "./payrolls.route";
import * as LocationCheckIns from "./location-check-ins.route";
import * as LeaveTypes from "./leave-types.route";

export const register = (props) => {
  const basePath = "/quick-teams";

  return (
    <Route path={basePath} element={<QuickTeams />}>
      {AttendanceTypes.register()}
      {ContactGroups.register()}
      {VisitPurposes.register()}
      {AttendanceModes.register()}
      {Shifts.register()}
      {Dashboard.register()}
      {AdvancedLoans.register()}
      {PettyCashes.register()}
      {RevisedShifts.register()}
      {Rosters.register()}
      {Reimburses.register()}
      {LeaveApplications.register()}
      {Attendances.register()}
      {Contacts.register()}
      {Visitors.register()}
      {Reports.register()}
      {Tickets.register()}
      {Payrolls.register()}
      {LocationCheckIns.register()}
      {LeaveTypes.register()}

      <Route
        path={basePath}
        element={<Navigate to={`${basePath}/dashboard`} replace />}
        // element={<HomePage />}
      />
      <Route path="*" element={<NotFound returnPath={basePath} />} />
    </Route>
  );
};
