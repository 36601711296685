import React, { useState } from "react";
import { AddForm } from "../../../components";
import { useNavigate } from "react-router-dom";
import { makeConfig } from "./config";
import { useSelector } from "react-redux";
import { makeNavbar as makeProjectsNav } from "../../../../projects/nav";
import { makeNavbar as makeTeamsNav } from "../../../../teams/nav";
import { makeNavbar as makeTalksNav } from "../../../../talks/nav";
import { makeNavbar as makeStocksNav } from "../../../../stocks/nav";
import { makeNavbar as makeSetupNav } from "../../../../setup/nav";
import { makeNavbar as makeDriveNav } from "../../../../drive/nav";
import PermissionsTable from "../../../components/PermissionsTable";

const Page = (props) => {
  const navigate = useNavigate();
  const auth = useSelector((state) => state?.auth);
  const config = makeConfig({ navigate, auth });
  const [values, setValues] = useState({
    user_type: [
      "General",
      "Employee",
      "Partner",
      "Agency",
      "Vendor",
      "Sub-contractor",
    ]?.map((item) => ({
      value: item,
      label: item,
    })),
  });
  const [permissions, setPermissions] = useState([]);
  const rawItems = {
    projects: {
      id: "quick-projects",
      name: "Quick Projects",
      items: makeProjectsNav({ navigate }),
    },
    teams: {
      id: "quick-teams",
      name: "Quick Teams",
      items: makeTeamsNav({ navigate }),
    },
    talks: {
      id: "quick-talks",
      name: "Quick Talks",
      items: makeTalksNav({ navigate }),
    },
    stocks: {
      id: "quick-stocks",
      name: "Quick Stocks",
      items: makeStocksNav({ navigate }),
    },
    drive: {
      id: "quick-drive",
      name: "Quick Drive",
      items: makeDriveNav({ navigate }),
    },
    setup: {
      id: "quick-setup",
      name: "Quick Setup",
      items: makeSetupNav({ navigate }),
    },
  };

  const onChangePermissions = (code, _) => {
    if (permissions?.indexOf(code) > -1) {
      setPermissions(permissions?.filter((item) => item !== code));
    } else {
      setPermissions(permissions?.concat(code));
    }
  };

  return (
    <div>
      <AddForm
        config={config}
        values={values}
        data={{ permissions }}
        extra={
          <PermissionsTable
            rawItems={rawItems}
            permissions={permissions}
            onChange={onChangePermissions}
          />
        }
      />
    </div>
  );
};

export default Page;
