export default {
  slugs: {
    singular: "sub-contractor-group",
    plural: "sub-contractor-groups",
  },
  entityNames: {
    singular: "Sub Contractor Group",
    plural: "Sub Contractor Groups",
  },
};
