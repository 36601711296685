import React from "react";
import { Route } from "react-router-dom";
import { EmailList, EmailAdd, EmailView, EmailEdit} from "../pages/Emails";

export const register = (props) => {
  const basePath = "app-emails";

  return (
    <>
      <Route path={basePath} element={<EmailList />} exact={true} />
      <Route path={`${basePath}/add`} element={<EmailAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<EmailEdit />} />
      <Route path={`${basePath}/view/:id`} element={<EmailView />} />
    </>
  );
};
