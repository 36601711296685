export default {
  slugs: {
    singular: "vendor-material-requisition",
    plural: "vendor-material-requisitions",
  },
  entityNames: {
    singular: "Vendor Material Requisition",
    plural: "Vendor Material Requisitions",
  },
};
