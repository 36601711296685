import React from "react";
import { Route } from "react-router-dom";
import {
  ShiftList,
  ShiftAdd,
  ShiftEdit,
} from "../pages/Shifts";

export const register = (props) => {
  const basePath = "shifts";

  return (
    <>
      <Route path={basePath} element={<ShiftList />} exact={true} />
      <Route path={`${basePath}/add`} element={<ShiftAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<ShiftEdit />} />
    </>
  );
};
