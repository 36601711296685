export default {
  slugs: {
    singular: "company",
    plural: "companies",
  },
  entityNames: {
    singular: "Company",
    plural: "Companies",
  },
};
