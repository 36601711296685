import React, { useEffect, useState } from "react";
import { AddForm } from "../../../components";
import { useNavigate, useParams } from "react-router-dom";
import { makeConfig } from "./config";
import { axios } from "../../../../../App";
import { fetchData } from "../../../../../utils/helpers/data.helper";
import qs from "qs";
import moment from "moment";
import { isEmpty } from "lodash";

const Page = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const config = makeConfig({ navigate });
  const [values, setValues] = useState({});
  const contentTypes = [
    {
      slug: "employees",
      transformer: (item) => ({
        value: item?.id,
        label: `${item?.first_name} ${item?.last_name}`,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          employee: data,
        })),
    },
  ];

  useEffect(() => {
    contentTypes?.forEach((contentType) => {
      fetchData(contentType);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const queryObject = {
          populate: "*",
        };

        const response = await axios.get(
          `/${config?.slugs?.plural}/${id}?${qs.stringify(queryObject)}`
        );

        if (response?.data) {
          let newData = {
            ...response?.data?.data?.attributes,
            id: response?.data?.data?.id,
            employee: response?.data?.data?.attributes?.employee?.data?.id,
            date: moment(response?.data?.data?.attributes?.date),
          };

          if (
            isEmpty(newData?.attachment) ||
            isEmpty(newData?.attachment?.data)
          ) {
            newData["attachment"] = null;
          }

          setData(newData);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <AddForm config={config} data={data} loading={loading} values={values} />
  );
};

export default Page;
