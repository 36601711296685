import React from "react";
import { Table } from "antd";

const DataTable = ({ data, columns, title }) => {
  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <p style={styles.headerTitle}>{title}</p>
      </div>

      <div style={styles.body}>
        <Table
          style={{ overflow: "auto" }}
          columns={columns}
          dataSource={data}
          pagination={false}
        />
      </div>
    </div>
  );
};

const styles = {
  container: {
    boxShadow: "0 0 20px 0 rgb(29 31 89 / 10%)",
    borderRadius: 10,
    overflow: "hidden",
  },
  header: {
    padding: "10px 15px",
    borderBottom: "1px solid #cf6c58",
    background: "#cf6c58",
  },
  headerTitle: {
    fontSize: "1.1rem",
    margin: "0rem",
    fontWeight: "500",
    color: "white",
  },
  body: {
    padding: 16,
    background: "white",
  },
};

export default DataTable;
