import config from "../config";
import moment from "moment";

export default {
  id: `${config?.slugs?.plural}-list`,
  pageTitle: `${config?.entityNames?.plural} Report`,
  code: "MACHINE_LOGS",
  breadcrumbs: [
    {
      path: "/quick-stocks/dashboard",
      title: "Home",
    },
    {
      path: `/quick-stocks/reports`,
      title: "Reports",
    },
    {
      path: "",
      title: `${config?.entityNames?.plural} Report`,
    },
  ],
  columns: [
    {
      title: "Machine",
      dataIndex: "machine",
      selectable: true,
      references: {
        fieldName: "name",
      },
    },
    {
      title: "Date",
      dataIndex: "date",
      selectable: true,
      sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
      render: (text) => <span>{moment(text).format("DD-MM-YYYY")}</span>,
      width: 250,
    },
    {
      title: "Location",
      dataIndex: "location",
      selectable: true,
      references: {
        fieldName: "name",
      },
    },
    {
      title: "Allotted To",
      dataIndex: "allotted_to",
      selectable: true,
      filterable: true,
      references: {
        fieldName: (record) => `${record?.first_name} ${record?.last_name}`,
      },
    },
    {
      title: "Performed By",
      dataIndex: "performed_by",
      selectable: true,
      filterable: true,
      references: {
        fieldName: (record) => `${record?.first_name} ${record?.last_name}`,
      },
    },
  ],
  ...config,
};
