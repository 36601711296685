export default {
  slugs: {
    singular: "tool-type",
    plural: "tool-types",
  },
  entityNames: {
    singular: "Machinery Type",
    plural: "Machinery Type",
  },
};
