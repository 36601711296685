export default {
  slugs: {
    singular: "meeting-type",
    plural: "meeting-types",
  },
  entityNames: {
    singular: "Meeting Type",
    plural: "Meeting Types",
  },
};
