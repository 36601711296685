import React, { useEffect, useState } from "react";
import { AddForm } from "../../../components";
import { useNavigate, useParams } from "react-router-dom";
import { makeConfig } from "./config";
import { axios } from "../../../../../App";
import { fetchData } from "../../../../../utils/helpers/data.helper";
import qs from "qs";

const Page = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    urgency_level: ["Low", "Medium", "High"]?.map((item) => ({
      value: item,
      label: item,
    })),
  });
  const config = makeConfig({ navigate });
  const contentTypes = [
    {
      slug: "work-groups",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          work_group: data,
        })),
    },
    {
      slug: "agency-requirement-forms",
      transformer: (item) => ({
        value: item?.id,
        label: item?.heading,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          agency_requirement_form: data,
        })),
    },
    {
      slug: "agencies",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          agency: data,
        })),
    },
    {
      slug: "projects",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          project: data,
        })),
    },
    {
      slug: "agency-terms",
      transformer: (item) => ({
        value: item?.id,
        label: item?.heading,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          agency_term: data,
        })),
    },
    {
      slug: "agency-payment-schedules",
      transformer: (item) => ({
        value: item?.id,
        label: item?.heading,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          agency_payment_schedule: data,
        })),
    },
    {
      slug: "locations",
      query: {
        populate: "*",
      },
      transformer: (item) => ({
        value: item?.id,
        label: `${item?.name} (${item?.location_type?.data?.attributes?.name})`,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          delivery_address: data,
        })),
    },
    {
      slug: "employees",
      transformer: (item) => ({
        value: item?.id,
        label: `${item?.first_name} ${item?.last_name}`,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
        //  approved_by: data,
        })),
    },
     {
      slug: "employees/approvers",
      transformer: (item) => ({
        value: item?.id,
        label: `${item?.first_name} ${item?.last_name}`,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
         approved_by: data,
        })),
    },
  ];

  useEffect(() => {
    contentTypes?.forEach((contentType) => {
      fetchData(contentType);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const queryObject = {
          populate: "*",
        };

        const response = await axios.get(
          `/${config?.slugs?.plural}/${id}?${qs.stringify(queryObject)}`
        );

        if (response?.data) {
          setData({
            ...response?.data?.data?.attributes,
            id: response?.data?.data?.id,
            work_group: response?.data?.data?.attributes?.work_group?.data?.id,
            agency: response?.data?.data?.attributes?.agency?.data?.id,
            project: response?.data?.data?.attributes?.project?.data?.id,
            agency_term:
              response?.data?.data?.attributes?.agency_term?.data?.id,
            delivery_address:
              response?.data?.data?.attributes?.delivery_address?.data?.id,
            agency_requirement_form:
              response?.data?.data?.attributes?.agency_requirement_form?.data
                ?.id,
            agency_payment_schedule:
              response?.data?.data?.attributes?.agency_payment_schedule?.data
                ?.id,
            approved_by:
              response?.data?.data?.attributes?.approved_by?.data?.id,
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  return (
    <AddForm config={config} data={data} loading={loading} values={values} />
  );
};

export default Page;
