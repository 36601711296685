

import React, { useRef, useEffect, useState } from "react";
import { PageHeader, Breadcrumb, Tooltip, Button, Col, DatePicker, Input, Row, Space, Table } from "antd";
import config from "./config";
import { useNavigate, Link } from "react-router-dom";
import { Box } from "@chakra-ui/react";
import pluralize from "pluralize";
import { ReloadOutlined, DownloadOutlined } from "@ant-design/icons";
import _ from "lodash";
import qs from "qs";
import moment from "moment";
import { useSelector } from "react-redux";
import { MAX_PAGINATION_LIMIT } from "../../../../../constants";
import { axios } from "../../../../../App";
import { ReportDataTable } from "../../../components";
import { Column, Pie } from "@ant-design/plots";

const DATE_TIME_FORMAT = "YYYY-MM-DDTHH:mm:ss[Z]";

const Page = (props) => {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [consumables, setConsumables] = useState([])
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    pageSize: 10,
  });
  const [total, setTotal] = useState(0);
  const [searchValues, setSearchValues] = useState({});
  const [searched, setSearched] = useState(false);
  const { user } = useSelector((state) => state?.auth);
  const [chartData, setchartData] = useState([])

  const onBack = () => navigate(`/quick-projects/reports`);

  const fetchData = async (params = null) => {
    try {
      setLoading(true);

      const queryObject = {
        populate: "*",
        sort: ["createdAt:desc"],
        pagination: {
          limit: MAX_PAGINATION_LIMIT,
        },
      };

      if (!_.isEmpty(params)) {
        queryObject["filters"] = params;
      }

      const response = await axios.get(
        `${config?.slugs?.plural}?${qs.stringify(queryObject)}`
      );


      if (response?.data) {

        setData(
          response?.data?.data?.map((item) => ({
            id: item?.id,
            ...item?.attributes,
          }))
        );

        setTotal(response?.data?.meta?.pagination?.total);

        setPagination({
          ...response?.data?.meta?.pagination,
          pageSize: 10,
        });
      }

    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchTaskMachinesData = async (params = null) => {
    try {
      setLoading(true);

      const queryObject = {
        populate: "*",
        pagination: {
          limit: 9999,
        },
      };

      if (!_.isEmpty(params)) {
        queryObject["filters"] = params;
      }

      const response = await axios.get(
        `task-machines?${qs.stringify(queryObject)}`
      );


      if (response?.data) {

        const aggregatedData = response?.data?.data?.reduce((acc, item) => {

          const machineName = item?.attributes?.machine?.data?.attributes?.name;
          const quantity = item?.attributes?.quantity;

          if (acc[machineName]) {
            acc[machineName] += quantity;
          } else {
            acc[machineName] = quantity;
          }

          return acc;
        }, {});

        // Formatting data for the pie chart
        const chartData = Object.keys(aggregatedData).map(machineName => ({
          type: machineName,
          value: aggregatedData[machineName],
        }));

        setchartData(chartData)
      }

    } catch (error) {
      console.log(error);
    }
  };

  const fetchConsumablesData = async (params = null) => {
    try {
      setLoading(true);

      const queryObject = {
        populate: "*",
        pagination: {
          limit: 9999,
        },
      };

      if (!_.isEmpty(params)) {
        queryObject["filters"] = params;
      }

      const response = await axios.get(
        `consumables?${qs.stringify(queryObject)}`
      );


      if (response?.data) {

        setConsumables(response.data.data);
      }

    } catch (error) {
      console.log(error);
    }
  };

  const refreshData = () => {
    onSearch();
    fetchTaskMachinesData();
  };

  const downloadReport = () => {
    try {
      const data = {
        code: config?.code,
        params: {
          start_date: searchValues?.start_date
            ? searchValues?.start_date?.format("YYYY-MM-DD")
            : moment().subtract(1000, "years").format("YYYY-MM-DD"),
          end_date: searchValues?.end_date
            ? searchValues?.end_date?.format("YYYY-MM-DD")
            : moment().add(1000, "years").format("YYYY-MM-DD"),
        },
      };

      const link = document.createElement("a");
      link.href = `${process.env.REACT_APP_BASE_API_URL
        }/reporter?${qs.stringify(data)}`;

      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.log(error);
    }
  };

  const getColumnFilterProps = ({ dataIndex, references = {} }) => {
    if (typeof references?.fieldName === "function") {
      const transformer = references?.fieldName;

      return {
        render: (data) => transformer(data?.data?.attributes) || "N/A",
      };
    } else {
      return {
        render: (data) =>
          data?.data?.attributes?.[references?.fieldName] || "N/A",
      };
    }
  };

  const getColumnProps = (column) => {
    if (column?.references) {
      return getColumnFilterProps(column);
    }

    return column;
  };

  const onSearch = () => {
    setSearched(true);

    fetchData({
      $and: [
        {
          $or: [
            {
              name: {
                $containsi: searchValues?.keyword?.toLowerCase(),
              },
            },
            {
              model: {
                $containsi: searchValues?.keyword?.toLowerCase(),
              },
            },
            {
              condition: {
                $containsi: searchValues?.keyword?.toLowerCase(),
              },
            },
            {
              location: {
                name: {
                  $containsi: searchValues?.keyword?.toLowerCase(),
                },
              }
            },

            {
              tool_type: {
                name: {
                  $containsi: searchValues?.keyword?.toLowerCase(),
                },
              }
            },
            {
              description: {
                $containsi: searchValues?.keyword?.toLowerCase(),
              },
            },
            {
              createdAt: {
                $gte: moment(searchValues?.start_date).format(DATE_TIME_FORMAT),
                $lte: moment(searchValues?.end_date).format(DATE_TIME_FORMAT),
              },
            },
          ],
        },

        getFiltersForRoles(),
      ],
    });
  };

  const onReset = () => {
    setSearchValues({});
    fetchData(getFiltersForRoles());
    setSearched(false);
  };

  const getResultsMessage = () => {
    if (!_.isEmpty(searchValues) && !loading && searched) {
      return total > 0 ? (
        <span>
          <strong>{total}</strong> matched results
        </span>
      ) : (
        <span>No matched results</span>
      );
    }

    return (
      <span>
        <strong>{total}</strong> total records
      </span>
    );
  };

  const renderHeaderButtons = () => {
    let buttons = [
      <Tooltip placement="top" title="Refresh">
        <Button
          icon={<ReloadOutlined />}
          onClick={refreshData}
          loading={loading}
        />
      </Tooltip>,
    ];

    return buttons;
  };

  const getFiltersForRoles = () => {
    const userType = user?.user_type?.toLowerCase()?.replace(/\s/i, "_");

    if (user?.is_admin) {
      return {};
    }

    if (userType === "employee") {
      return {
        $or: [
          {
            project_managers: {
              uid: user?.uid,
            },
          },
          {
            members: {
              uid: user?.uid,
            },
          },
        ],
      };
    }

    return {
      [pluralize(userType)]: {
        uid: user?.uid,
      },
    };
  };

  useEffect(() => {
    fetchData(getFiltersForRoles());
    fetchTaskMachinesData()
    fetchConsumablesData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pieConfig = {
    appendPadding: 10,
    data: chartData,
    angleField: 'value',
    colorField: 'type',
    radius: 1,
    label: {
      type: 'inner',
      offset: '-30%',
      content: '{value}',
      style: {
        fontSize: 14,
        textAlign: 'center',
      },
    },
    interactions: [{ type: 'element-active' }],
    color: ['#CF6C58', '#2E943E', '#2E4494', '#000000', '#4BC0C0'], // Custom colors
  };

  const projects_data = consumables?.map((s, index) => (
    {
      key: index,
      project_id: s.attributes.project?.data?.id ?? null,
      project_name: s.attributes.project?.data?.attributes?.name ?? "N/A",
      name: s.attributes.machine.data.attributes.name,
      date_of_use: s.attributes.date,
      consumable_name: s.attributes.runned_by.data.attributes.first_name,
      consumable_cost: s.attributes.amount,
    }
  ));

  // Calculate the total cost
  const totalCost = projects_data?.reduce((acc, item) => acc + item.consumable_cost, 0);

  // Add a total cost row
  const totalCostRow = {
    key: 'total',
    project_name: '',
    name: '',
    date_of_use: '',
    consumable_name: '',
    consumable_cost: `Total Cost - ${totalCost.toLocaleString()}`
  };

  const project_column = [
    {
      title: 'Project Name',
      dataIndex: 'project_name',
      key: 'project_name',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Date of Use',
      dataIndex: 'date_of_use',
      key: 'date_of_use',
    },
    {
      title: 'Consumable Name',
      dataIndex: 'consumable_name',
      key: 'consumable_name',
    },
    {
      title: 'Consumable Cost',
      dataIndex: 'consumable_cost',
      key: 'consumable_cost',
      render: (text, record) => {
        return record.key === 'total' ? <strong>{text}</strong> : text;
      }
    }
  ];

  const dataSourceWithTotal = [...projects_data, totalCostRow];

  const aggregatedData = projects_data.reduce((acc, item) => {
    const existingProject = acc.find(project => project.project_name === item.project_name);
    if (existingProject) {
      existingProject.consumable_cost += item.consumable_cost;
    } else {
      acc.push({
        project_name: item.project_name,
        consumable_cost: item.consumable_cost,
      });
    }
    return acc;
  }, []);

  const vehicle_data = data?.map(item => ({
    vehicle: item.name,
    location: item.location.data.attributes.name,
  }));

  const vehicleChartData = vehicle_data.reduce((acc, { vehicle, location }) => {
    const existingLocation = acc.find(item => item.location === location);
    if (existingLocation) {
      existingLocation[vehicle] = (existingLocation[vehicle] || 0) + 1;
    } else {
      acc.push({ location, [vehicle]: 1 });
    }
    return acc;
  }, []);

  const formattedData = [];
  vehicleChartData.forEach(item => {
    Object.keys(item).forEach(key => {
      if (key !== "location") {
        formattedData.push({
          location: item.location,
          vehicle: key,
          count: item[key],
        });
      }
    });
  });

  const ColumnChart = () => {
    const config = {
      data: aggregatedData,
      xField: 'project_name',
      yField: 'consumable_cost',
      label: {
        position: 'middle',
        style: {
          fill: '#FFFFFF',
          opacity: 0.6,
        },
      },
      xAxis: {
        label: {
          autoHide: true,
          autoRotate: false,
        },
      },
      meta: {
        project_name: { alias: 'Project Name' },
        consumable_cost: { alias: 'Consumable Cost' },
      },
      color: ['#CF6C58', '#2E943E', '#2E4494', '#000000', '#4BC0C0'], // Custom colors
    };
    return <Column {...config} />;
  };

  const VehicleColumnChart = () => {
    const config = {
      data: formattedData,
      xField: 'vehicle',
      yField: 'count',
      seriesField: 'location',
      label: {
        position: 'middle',
        style: {
          fill: '#FFFFFF',
          opacity: 0.6,
        },
      },
      xAxis: {
        label: {
          autoHide: true,
          autoRotate: false,
        },
      },
      meta: {
        vehicle: { alias: 'Vehicle' },
        location: { alias: 'Location' },
      },
      color: ['#CF6C58', '#2E943E', '#2E4494', '#000000', '#4BC0C0'], // Custom colors
    };
    return <Column {...config} />;
  };


  console.log('task machines', data, formattedData);

  return (
    <Box>
      {/* <div id="barGraph">
        <Bar {...barConfig} />
      </div> */}

      <ReportDataTable
        data={data}
        projects_data={dataSourceWithTotal}
        projects_column={project_column}
        use_handlePrint={true}
        config={{
          ...config,
          project_title: 'Machinery in Use',
          pie_title: '',
          columns: config?.columns?.map((column) => ({
            ...column,
            ...getColumnProps(column),
          })),
        }}
        loading={loading}
        pagination={pagination}
        actions={{
          onAdd: () => navigate(`/quick-projects/${config?.slugs?.plural}/add`),
          onRefresh: refreshData,
        }}
        Toolbar={
          <div className="toolbar">
            <Row gutter={16}>
              <Col span={10}>
                <Input
                  value={searchValues?.keyword}
                  placeholder="Search for keyword..."
                  onChange={(e) =>
                    setSearchValues((prev) => ({
                      ...prev,
                      keyword: e.target.value,
                    }))
                  }
                />
              </Col>

              <Col span={7}>
                <DatePicker
                  onChange={(value) =>
                    setSearchValues((prev) => ({
                      ...prev,
                      start_date: value,
                    }))
                  }
                  value={searchValues?.start_date}
                  placeholder="Select start date"
                  style={styles.datePicker}
                />
              </Col>

              <Col span={7}>
                <DatePicker
                  onChange={(value) =>
                    setSearchValues((prev) => ({
                      ...prev,
                      end_date: value,
                    }))
                  }
                  value={searchValues?.endDate}
                  placeholder="Select end date"
                  style={styles.datePicker}
                />
              </Col>
            </Row>

            <div className="toolbar-buttons">
              <p className="toolbar-results">{getResultsMessage()}</p>

              <Space>
                <Button onClick={onReset}>Reset</Button>
                <Button type="primary" onClick={onSearch}>
                  Search
                </Button>
              </Space>
            </div>
          </div>
        }
        buttons={renderHeaderButtons()}
        onBack={() => navigate(`/quick-projects/reports`)}
      />

      <div style={{ margin: '5rem 0', fontSize: '20px' }} id="pieChart">
        {/* <p>Agency and Agency groups</p> */}
        <div style={{ border: '1px solid #E9E9E9', width: '60%', borderRadius: '10px', margin: '20px 0', padding: '10px', fontSize: '40px' }}>
          <Pie {...pieConfig} />
        </div>
      </div>

      <div style={{ fontSize: '20px' }}>
        <p style={{ paddingBottom: '20px' }}>Machinery in Use</p>
        <Table
          dataSource={dataSourceWithTotal}
          columns={project_column}
          pagination={false}

        />
      </div>

      <div id="pieChart2" style={{ pageBreakInside: 'avoid' }}>
        <div style={{ margin: '5rem 0', fontSize: '20px' }}>
          <p>Consumable costs for each project</p>
          <div style={{ border: '1px solid #E9E9E9', width: '60%', borderRadius: '10px', margin: '20px 0', padding: '10px', fontSize: '40px' }}>
            <ColumnChart />
          </div>
        </div>

        <div style={{ margin: '5rem 0', fontSize: '20px' }} >
          <p> Count of each type of vehicle used in different projects</p>
          <div style={{ border: '1px solid #E9E9E9', width: '60%', borderRadius: '10px', margin: '20px 0', padding: '10px', fontSize: '40px' }}>
            <VehicleColumnChart />
          </div>
        </div>
      </div>
    </Box>
  );
};

const styles = {
  breadcrumbs: {},
  pageHeader: {
    padding: 0,
    paddingBottom: 12,
  },
  datePicker: {
    width: "100%",
  },
};

export default Page;

// import React, { useState, useEffect } from "react";
// import { DataTable } from "../../../components";
// import { useNavigate } from "react-router-dom";
// import { Space, Button, Row, Col, Input, Tooltip, DatePicker } from "antd";
// import qs from "qs";
// import config from "./config";
// import { axios } from "../../../../../App";
// import _ from "lodash";
// import { ReloadOutlined, DownloadOutlined } from "@ant-design/icons";
// import moment from "moment";

// const DATE_TIME_FORMAT = "YYYY-MM-DDTHH:mm:ss[Z]";

// const Page = () => {
//   const navigate = useNavigate();
//   const [data, setData] = useState([]);
//   const [filters, setFilters] = useState({});
//   const [loading, setLoading] = useState(false);
//   const [pagination, setPagination] = useState({
//     pageSize: 10,
//   });
//   const [total, setTotal] = useState(0);
//   const [searchValues, setSearchValues] = useState({});

//   const fetchData = async (params = null) => {
//     try {
//       setLoading(true);

//       const queryObject = {
//         populate: "*",
//         sort: ["name:asc"],
//       };

//       if (!_.isEmpty(params)) {
//         queryObject["filters"] = params;
//       }

//       const response = await axios.get(
//         `${config?.slugs?.plural}?${qs.stringify(queryObject)}`
//       );

//       if (response?.data) {
//         setData(
//           response?.data?.data?.map((item) => ({
//             id: item?.id,
//             ...item?.attributes,
//           }))
//         );

//         setTotal(response?.data?.meta?.pagination?.total);

//         setPagination({
//           ...response?.data?.meta?.pagination,
//           pageSize: 10,
//         });
//       }
//     } catch (error) {
//       console.log(error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const refreshData = () => {
//     onSearch();
//   };

//   const downloadReport = () => {
//     try {
//       const data = {
//         code: config?.code,
//         params: {
//           start_date: searchValues?.start_date
//             ? searchValues?.start_date?.format("YYYY-MM-DD")
//             : moment().subtract(1000, "years").format("YYYY-MM-DD"),
//           end_date: searchValues?.end_date
//             ? searchValues?.end_date?.format("YYYY-MM-DD")
//             : moment().add(1000, "years").format("YYYY-MM-DD"),
//         },
//       };

//       const link = document.createElement("a");
//       link.href = `${
//         process.env.REACT_APP_BASE_API_URL
//       }/reporter?${qs.stringify(data)}`;

//       document.body.appendChild(link);
//       link.click();
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   const getColumnFilterProps = ({ dataIndex, references = {} }) => {
//     if (typeof references?.fieldName === "function") {
//       const transformer = references?.fieldName;

//       return {
//         render: (data) => transformer(data?.data?.attributes) || "N/A",
//       };
//     } else {
//       return {
//         render: (data) =>
//           data?.data?.attributes?.[references?.fieldName] || "N/A",
//       };
//     }
//   };

//   const getColumnProps = (column) => {
//     if (column?.references) {
//       return getColumnFilterProps(column);
//     }

//     return column;
//   };

//   const onSearch = () => {
//     fetchData({
//       $or: [
//         {
//           name: {
//             $containsi: searchValues?.keyword?.toLowerCase(),
//           },
//         },
//         {
//           model: {
//             $containsi: searchValues?.keyword?.toLowerCase(),
//           },
//         },
//         {
//           condition: {
//             $containsi: searchValues?.keyword?.toLowerCase(),
//           },
//         },
//         {
//           description: {
//             $containsi: searchValues?.keyword?.toLowerCase(),
//           },
//         },
//         {
//           createdAt: {
//             $gte: moment(searchValues?.start_date).format(DATE_TIME_FORMAT),
//             $lte: moment(searchValues?.end_date).format(DATE_TIME_FORMAT),
//           },
//         },
//       ],
//     });
//   };

//   const onReset = () => {
//     setSearchValues({});
//     fetchData(null);
//   };

//   const getResultsMessage = () => {
//     if (!_.isEmpty(searchValues)) {
//       return total > 0 ? (
//         <span>
//           <strong>{total}</strong> matched results
//         </span>
//       ) : (
//         <span>No matched results</span>
//       );
//     }

//     return (
//       <span>
//         <strong>{total}</strong> total records
//       </span>
//     );
//   };

//   useEffect(() => {
//     fetchData(filters);
//   }, []);

//   return (
//     <DataTable
//       data={data}
//       config={{
//         ...config,
//         columns: config?.columns?.map((column) => ({
//           ...column,
//           ...getColumnProps(column),
//         })),
//       }}
//       loading={loading}
//       pagination={pagination}
//       actions={{
//         onAdd: () => navigate(`/quick-stocks/${config?.slugs?.plural}/add`),
//         onRefresh: refreshData,
//       }}
//       Toolbar={
//         <div className="toolbar">
//           <Row gutter={16}>
//             <Col span={10}>
//               <Input
//                 value={searchValues?.keyword}
//                 placeholder="Search for keyword..."
//                 onChange={(e) =>
//                   setSearchValues((prev) => ({
//                     ...prev,
//                     keyword: e.target.value,
//                   }))
//                 }
//               />
//             </Col>

//             <Col span={7}>
//               <DatePicker
//                 onChange={(value) =>
//                   setSearchValues((prev) => ({
//                     ...prev,
//                     start_date: value,
//                   }))
//                 }
//                 value={searchValues?.start_date}
//                 placeholder="Select start date"
//                 style={styles.datePicker}
//               />
//             </Col>

//             <Col span={7}>
//               <DatePicker
//                 onChange={(value) =>
//                   setSearchValues((prev) => ({
//                     ...prev,
//                     end_date: value,
//                   }))
//                 }
//                 value={searchValues?.endDate}
//                 placeholder="Select end date"
//                 style={styles.datePicker}
//               />
//             </Col>
//           </Row>

//           <div className="toolbar-buttons">
//             <p className="toolbar-results">{getResultsMessage()}</p>

//             <Space>
//               <Button onClick={onReset}>Reset</Button>
//               <Button type="primary" onClick={onSearch}>
//                 Search
//               </Button>
//             </Space>
//           </div>
//         </div>
//       }
//       buttons={[
//         <Tooltip placement="top" title="Refresh">
//           <Button
//             icon={<ReloadOutlined />}
//             onClick={refreshData}
//             loading={loading}
//           />
//         </Tooltip>,
//         <Tooltip placement="top" title="Download Report">
//           <Button icon={<DownloadOutlined />} onClick={downloadReport} />
//         </Tooltip>,
//       ]}
//       onBack={() => navigate(`/quick-stocks/reports`)}
//     />
//   );
// };

// const styles = {
//   datePicker: {
//     width: "100%",
//   },
// };

// export default Page;
