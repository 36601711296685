import React from "react";
import GroupedColumns from "./components/GroupedColumns";
import Speedometer from "./components/Speedometer";
import Numbers from "./components/Numbers";
import Pie from "./components/Pie";
import PieGroup from "./components/PieGroup";
import Donus from "./components/Donus";
import DonusGroup from "./components/DonusGroup";
import Line from "./components/Line";
import Columns from "./components/Columns";
import StackedBar from "./components/StackedBar";
import BarPlot from "./components/BarPlot";
import StackedColumn from "./components/StackedColumn";

const ChartGenerator = ({ type, schema, data }) => {
  const renderChart = () => {
    let propsData = {
      ...schema,
      ...data,
    };

    switch (type) {
      case "grouped-columns":
        return <GroupedColumns {...propsData} />;

      case "columns":
        return <Columns {...propsData} />;

      case "speedometer":
        return <Speedometer {...propsData} />;

      case "numbers":
        return <Numbers {...propsData} />;

      case "pie":
        return <Pie {...propsData} />;

      case "pie-group":
        return <PieGroup {...propsData} />;

      case "donus":
        return <Donus {...propsData} />;

      case "donus-group": {
        propsData = [];

        for (let i = 0; i < schema?.childrenSchema?.length; i++) {
          propsData?.push({
            ...schema?.childrenSchema?.[i],
            ...data?.data?.[i],
          });
        }

        return <DonusGroup title={schema?.title} data={propsData} />;
      }

      case "line":
        return <Line {...propsData} />;

      case "stacked-bar":
        return <StackedBar {...propsData} />;

      case "bar-plot":
        return <BarPlot {...propsData} />;

      case "stacked-column":
        return <StackedColumn {...propsData} />;

      default:
        return null;
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <p style={styles.headerTitle}>{schema?.title}</p>
      </div>

      <div style={styles.body}>{renderChart()}</div>
    </div>
  );
};

const styles = {
  container: {
    marginBottom: 16,
    boxShadow: "0 0 20px 0 rgb(29 31 89 / 10%)",
    borderRadius: 10,
    overflow: "hidden",
  },
  header: {
    padding: "10px 15px",
    borderBottom: "1px solid #cf6c58",
    background: "#cf6c58",
  },
  headerTitle: {
    fontSize: "1.1rem",
    margin: 0,
    fontWeight: 500,
    color: "white",
  },
  body: {
    padding: 16,
    background: "white",
  },
};

export default ChartGenerator;
