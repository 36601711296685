import config from "../config";

export default {
  id: `${config?.slugs?.plural}-list`,
  pageTitle: config?.entityNames?.plural,
  breadcrumbs: [
    {
      path: "/quick-teams/dashboard",
      title: "Home",
    },
    {
      path: "",
      title: config?.entityNames?.plural,
    },
  ],
  columns: [
    {
      title: "Contact Group",
      dataIndex: "contact_group",
      selectable: true,
      references: {
        fieldName: "name",
      },
      width: 200,
    },
    {
      title: "First Name",
      dataIndex: "first_name",
      selectable: true,
      sorter: (a, b) => a?.first_name?.localeCompare(b?.first_name),
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      selectable: true,
      sorter: (a, b) => a?.last_name?.localeCompare(b?.last_name),
    },
    {
      title: "Email",
      dataIndex: "email_1",
      selectable: true,
      sorter: (a, b) => a?.email_1?.localeCompare(b?.email_1),
    },
    {
      title: "Phone Number",
      dataIndex: "phone_number_1",
      selectable: true,
      sorter: (a, b) => a?.phone_number_1?.localeCompare(b?.phone_number_1),
    },
  ],
  ...config,
};
