// eslint-disable-next-line import/no-anonymous-default-export
export default {
  slugs: {
    singular: "agency-extra-work",
    plural: "agency-extra-works",
  },
  entityNames: {
    singular: "Agency Extra Work",
    plural: "Agency Extra Works",
  },
};
