import React, { useState, useEffect } from "react";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { makeNavbar as makeProjectsNav } from "../../modules/projects/nav";
import { makeNavbar as makeTeamsNav } from "../../modules/teams/nav";
import { makeNavbar as makeTalksNav } from "../../modules/talks/nav";
import { makeNavbar as makeStocksNav } from "../../modules/stocks/nav";
import { makeNavbar as makeSetupNav } from "../../modules/setup/nav";
import { makeNavbar as makeDriveNav } from "../../modules/drive/nav";
import { useTranslation } from 'react-i18next';

const FeatureSearchBox = ({ navigate }) => {
  const rawItems = {
    projects: {
      id: "quick-projects",
      name: "Quick Projects",
      items: makeProjectsNav({ navigate }),
    },
    teams: {
      id: "quick-teams",
      name: "Quick Teams",
      items: makeTeamsNav({ navigate }),
    },
    talks: {
      id: "quick-talks",
      name: "Quick Talks",
      items: makeTalksNav({ navigate }),
    },
    stocks: {
      id: "quick-stocks",
      name: "Quick Stocks",
      items: makeStocksNav({ navigate }),
    },
    drive: {
      id: "quick-drive",
      name: "Quick Drive",
      items: makeDriveNav({ navigate }),
    },
    setup: {
      id: "quick-setup",
      name: "Quick Setup",
      items: makeSetupNav({ navigate }),
    },
  };
  const [searchItems, setSearchItems] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    let items = [
      {
        id: "/faqs",
        name: "FAQs",
      },
    ];

    Object.keys(rawItems)?.forEach((key) => {
      items?.push({
        id: rawItems?.[key]?.id,
        name: rawItems?.[key]?.name,
      });

      if (rawItems?.[key]?.items?.length > 0) {
        rawItems?.[key]?.items?.forEach((item) => {
          if (item?.key !== "back-to-home") {
            if (item?.children?.length > 0) {
              item?.children?.forEach((subItem) => {
                items?.push({
                  id: subItem?.key,
                  name: `${rawItems?.[key]?.name} - ${subItem?.label}`,
                });
              });
            } else {
              items?.push({
                id: item?.key,
                name: `${rawItems?.[key]?.name} - ${item?.label}`,
              });
            }
          }
        });
      }
    });

    setSearchItems(items);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="autocomplete-search-box">
      <ReactSearchAutocomplete
        items={searchItems}
        onSelect={(item) => navigate(item?.id)}
        autoFocus
        maxResults={5}
        placeholder={t("Search for QuickSo feature")}
      />
    </div>
  );
};

export default FeatureSearchBox;
