export default {
  slugs: {
    singular: "task-report",
    plural: "task-reports",
  },
  entityNames: {
    singular: "Daily Report",
    plural: "Daily Reports",
  },
};
