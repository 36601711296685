import React from "react";
import { Route } from "react-router-dom";
import {
  TaskMachineList,
  TaskMachineAdd,
  TaskMachineEdit,
  TaskMachineReport,
} from "../pages/TaskMachines";

export const register = (props) => {
  const basePath = "task-machines";

  return (
    <>
      <Route path={basePath} element={<TaskMachineList />} exact={true} />
      <Route path={`${basePath}/add`} element={<TaskMachineAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<TaskMachineEdit />} />
      <Route path={`${basePath}-report`} element={<TaskMachineReport />} />
    </>
  );
};
