import React from "react";
import { Route } from "react-router-dom";
import {
  TaskHandToolList,
  TaskHandToolAdd,
  TaskHandToolEdit,
  TaskHandToolReport,
} from "../pages/TaskHandTools";

export const register = (props) => {
  const basePath = "task-hand-tools";

  return (
    <>
      <Route path={basePath} element={<TaskHandToolList />} exact={true} />
      <Route path={`${basePath}/add`} element={<TaskHandToolAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<TaskHandToolEdit />} />
      <Route path={`${basePath}-report`} element={<TaskHandToolReport />} />
    </>
  );
};
