import config from "../config";
import moment from "moment";

export default {
  id: `${config?.slugs?.plural}-list`,
  pageTitle: config?.entityNames?.plural,
  breadcrumbs: [
    {
      path: "/quick-setup",
      title: "Home",
    },
    {
      path: "",
      title: config?.entityNames?.plural,
    },
  ],
  columns: [
    {
      title: "Date",
      dataIndex: "date",
      selectable: true,
      sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
      render: (text) => <span>{moment(text).format("DD-MM-YYYY")}</span>,
      width: 250,
    },
    {
      title: "Reason of Holiday",
      dataIndex: "name",
      selectable: true,
      sorter: (a, b) => a?.name?.localeCompare(b?.name),
    },
    // {
    //   title: "Location",
    //   dataIndex: "location",
    //   selectable: true,
    //   filterable: true,
    //   references: {
    //     fieldName: (record) =>
    //       `${record?.name} (${record?.location_type?.data?.attributes?.name})`,
    //   },
    // },
    // {
    //   title: "Description",
    //   dataIndex: "description",
    //   selectable: true,
    //   sorter: (a, b) => a?.description?.localeCompare(b?.description),
    //   render: (text) =>
    //     text !== "" ? (
    //       <span
    //         className="html-cell"
    //         dangerouslySetInnerHTML={{ __html: text }}
    //       ></span>
    //     ) : (
    //       "N/A"
    //     ),
    // },
  ],
  ...config,
};
