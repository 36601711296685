import config from "../config";

export default {
  id: `${config?.slugs?.plural}-list`,
  pageTitle: config?.entityNames?.plural,
  breadcrumbs: [
    {
      path: "/quick-projects/dashboard",
      title: "Home",
    },
    {
      path: "",
      title: config?.entityNames?.plural,
    },
  ],
  columns: [
    {
      title: "Task",
      dataIndex: "task",
      selectable: true,
      references: {
        fieldName: "name",
      },
    },
    {
      title: "Labour",
      dataIndex: "labour",
      selectable: true,
      sorter: (a, b) => a?.labour?.localeCompare(b?.labour),
    },
    {
      title: "Manpower Type",
      dataIndex: "manpower_type",
      selectable: true,
      references: {
        fieldName: "name",
      },
    },
  ],
  ...config,
};
