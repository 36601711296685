import React, { useState, useEffect } from "react";
import NumberCard from "./NumberCard";
import { Col } from "antd";
import ChartGenerator from "../../../../../components/ChartGenerator";
import { Stack, SimpleGrid, Box } from "@chakra-ui/react";
import { axios } from "../../../../../App";
import { CHART_SLUGS } from "../constants";

const KB2GB = (value = 0) => {
  const result = value / (1024 * 1024);

  if (result % 1 !== 0) {
    return result?.toFixed(3);
  }

  return result;
};

const CARDS = [
  {
    title: "Total Files",
    dataIndex: "total_files",
    transformer: (value) => value || 0,
  },
  {
    title: "Total Folders",
    dataIndex: "total_folders",
    transformer: (value) => value || 0,
  },
  {
    title: "Site Used Space (GB)",
    dataIndex: "used_space",
    transformer: (value) => (!isNaN(value) ? KB2GB(+value) : 0),
  },
  {
    title: "Monthly Used Space (GB)",
    dataIndex: "monthly_used_space",
    transformer: (value) => (!isNaN(value) ? KB2GB(+value) : 0),
  },
  {
    title: "Monthly Unused Space (GB)",
    dataIndex: "monthly_unused_space",
    transformer: (value) => (!isNaN(value) ? KB2GB(+value) : 0),
  },
];

const Page = (props) => {
  const [data, setData] = useState({});

  const fetchChartData = async () => {
    let requests = [];
    let newData = {};
    let slug = "";

    for (let slug of Object.values(CHART_SLUGS)) {
      requests.push(axios.get(`/quick-drive-dashboard/${slug}`));
    }

    const response = await Promise.all(requests);

    for (let i = 0; i < Object.values(CHART_SLUGS)?.length; i++) {
      slug = Object.values(CHART_SLUGS)?.[i];

      newData[slug] = response[i]?.data;
    }

    setData(newData);
  };

  useEffect(() => {
    fetchChartData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={styles.container}>
      <div style={styles.cards}>
        <SimpleGrid
          spacing={3}
          columns={{ base: 2, md: 3, lg: 4 }}
          style={{ marginBottom: 12 }}
        >
          {CARDS?.map((card, index) => (
            <Col>
              <NumberCard
                key={index}
                title={card?.title}
                value={card?.transformer(
                  data?.[CHART_SLUGS?.STATISTICS]?.data?.[card?.dataIndex]
                )}
              />
            </Col>
          ))}
        </SimpleGrid>

        <Stack direction={{ base: "column", md: "row", lg: "row" }}>
          {data?.[CHART_SLUGS?.MONTHLY_STORAGE_AMOUNT_STATUS] && (
            <Box w={{ base: "auto", md: "100%" }}>
              <Col>
                <ChartGenerator
                  type="pie"
                  data={{
                    data: data?.[
                      CHART_SLUGS?.MONTHLY_STORAGE_AMOUNT_STATUS
                    ]?.data?.map((item) => ({
                      value: +KB2GB(item?.value),
                      status: item?.status + " (GB)",
                    })),
                  }}
                  schema={{
                    title: "Monthly Storage Amount Status",
                    angleField: "value",
                    colorField: "status",
                  }}
                />
              </Col>
            </Box>
          )}
        </Stack>
      </div>
    </div>
  );
};

const styles = {};

export default Page;
