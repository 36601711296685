// eslint-disable-next-line import/no-anonymous-default-export
export default {
  slugs: {
    singular: "sub-contractor-extra-work",
    plural: "sub-contractor-extra-works",
  },
  entityNames: {
    singular: "Sub Contractor Extra Work",
    plural: "Sub Contractor Extra Works",
  },
};
