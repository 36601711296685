import { axios } from "../../../../../App";
import config from "../config";
import AddEstimatedItem from "../AddEstimatedItem";
import EditEstimatedItem from "../EditEstimatedItem";
import AddIssue from "../AddIssue";
import EditIssue from "../EditIssue";
import AddActualItem from "../AddActualItem";
import EditActualItem from "../EditActualItem";

export const makeConfig = ({ navigate }) => ({
  id: `${config?.plural}-edit`,
  title: `Edit ${config?.entityNames?.singular}`,
  pageTitle: `Edit ${config?.entityNames?.singular}`,
  breadcrumbs: [
    {
      path: "/quick-projects/dashboard",
      title: "Home",
    },
    {
      path: `/quick-projects/${config?.slugs?.plural}`,
      title: config?.entityNames?.plural,
    },
    {
      path: "",
      title: `Edit ${config?.entityNames?.singular}`,
    },
  ],
  components: [
    {
      span: 12,
      component: "select",
      label: "Employee",
      name: "employee",
      placeholder: "Select employee",
      required: true,
      rules: [
        {
          required: true,
          message: "Employee is required!",
        },
      ],
    },
    {
      span: 12,
      component: "date",
      label: "Date",
      name: "date",
      required: true,
      placeholder: "Select date",
      rules: [
        {
          required: true,
          message: "Date is required!",
        },
      ],
    },
    {
      span: 24,
      component: "editor",
      label: "Details",
      name: "details",
      placeholder: "Enter details",
      rows: 5,
    },
    {
      span: 24,
      component: "repeatable",
      label: "Estimated",
      name: "estimated_items",
      list: [
        {
          name: "Task/ Milestone",
          dataIndex: "task",
        },
        {
          name: "Target Date",
          dataIndex: "target_date",
        },
        {
          name: "Details",
          dataIndex: "details",
        },
      ],
      modalComponents: {
        add: (params) => {
          return <AddEstimatedItem {...params} />;
        },
        edit: (params) => {
          return <EditEstimatedItem {...params} />;
        },
      },
    },
    {
      span: 24,
      component: "repeatable",
      label: "Actual",
      name: "actual_items",
      list: [
        {
          name: "Task/ Milestone",
          dataIndex: "task",
        },
        {
          name: "Percentage of Completion",
          dataIndex: "task",
        },
        {
          name: "Target Date",
          dataIndex: "target_date",
        },
        {
          name: "Finished Date",
          dataIndex: "finished_date",
        },
        {
          name: "Completed",
          dataIndex: "completed",
        },
        {
          name: "Details",
          dataIndex: "details",
        },
      ],
      modalComponents: {
        add: (params) => {
          return <AddActualItem {...params} />;
        },
        edit: (params) => {
          return <EditActualItem {...params} />;
        },
      },
    },
    {
      span: 24,
      component: "repeatable",
      label: "Project Risks, Issues, and Mitigation Plans",
      name: "issues",
      list: [
        {
          name: "Project Issue",
          dataIndex: "project_issue",
        },
        {
          name: "Risk",
          dataIndex: "risk",
        },
        {
          name: "Mitigation",
          dataIndex: "mitigation",
        },
        {
          name: "Notes",
          dataIndex: "notes",
        },
      ],
      modalComponents: {
        add: (params) => {
          return <AddIssue {...params} />;
        },
        edit: (params) => {
          return <EditIssue {...params} />;
        },
      },
    },
  ],
  actions: {
    onBack: () => navigate(`/quick-projects/${config?.slugs?.plural}`),
    onFinish: async (values, data) => {
      try {
        const response = await axios.put(
          `/${config?.slugs?.plural}/${data?.id}`,
          {
            data: {
              ...values,
              date: values?.date?.add(1, "days"),
            },
          }
        );

        if (response?.data) {
          return {
            success: true,
            message: `Edit ${config?.entityNames?.singular?.toLowerCase()} successfully!`,
          };
        }

        throw new Error();
      } catch (error) {
        return {
          success: false,
          message: error?.response?.data?.error?.message,
        };
      }
    },
  },
  ...config,
});
