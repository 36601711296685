import React from "react";
import { Route } from "react-router-dom";
import Dashboard from "../pages/Dashboard";
import DashboardReport from "../pages/Dashboard/Report";

export const register = (props) => {
  const basePath = "dashboard";

  return (
    <>
      <Route path={basePath} element={<Dashboard />} exact={true} />
      <Route
        path={`${basePath}-report`}
        element={<DashboardReport />}
      />
    </>
  );
};
