export default {
  slugs: {
    singular: "agency-requirement-form",
    plural: "agency-requirement-forms",
  },
  entityNames: {
    singular: "Agency Requirement Form",
    plural: "Agency Requirement Forms",
  },
};
