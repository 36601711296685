export default {
    slugs: {
        singular: "vendor-boq",
        plural: "vendors-boq",
    },
    entityNames: {
        singular: "Vendor Boq",
        plural: "Vendors Boq",
    },
};
