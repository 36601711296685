import React, { useState, useEffect } from "react";
import {
  PageHeader,
  Breadcrumb,
  Row,
  Col,
  Form,
  Space,
  Button,
  notification,
} from "antd";
import FormElement from "../FormElement";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Box, Stack } from "@chakra-ui/react";

const AddForm = ({
  loading = false,
  config,
  data = {},
  values = {},
  disabled = false,
  modals = {},
  onFieldChange = {},
  onStateChange = () => { },
  setFormData = () => { },
  children = null
}) => {
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);
  const [formInternalData, setFormInternalData] = useState({});
  const [selectedCompany, setSelectedCompany] = useState(null);

  useEffect(() => {
    if (formInternalData?.po_from) {
      const filteredCompany = values?.po_from.find(po => po.value === formInternalData.po_from);
      setSelectedCompany(filteredCompany);
    }
  }, [formInternalData?.po_from]);

  useEffect(() => {
    if (selectedCompany) {
      form.setFieldsValue({
        company_address: selectedCompany.address_line_1 || "", // Update company_address field
        company_gstin: selectedCompany.gsin_no || "", // Update company_gstin field
        company_email: selectedCompany.email_1 || "", // Update company_email field
      });
    }
  }, [selectedCompany, form]);

  const handleFinish = async (values) => {
    setSubmitting(true);
    const response = await config?.actions?.onFinish(values, data);

    if (response) {
      if (response?.success) {
        notification["success"]({
          message: config?.title,
          description: response?.message,
        });
        config?.actions?.onBack();
      } else {
        notification["error"]({
          message: config?.title,
          description: response?.message,
        });
      }
    }

    setSubmitting(false);
  };

  useEffect(() => {
    form.setFieldsValue(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (formInternalData?.state) {
      const selected_state = values?.state?.filter(state => state.value == formInternalData?.state);
      if (selected_state?.length > 0) {
        onStateChange(selected_state?.[0]);
      }
    }
  }, [formInternalData.state]);

  useEffect(() => {
    setFormData(formInternalData);
  }, [formInternalData]);

  console.log('selectedddd', selectedCompany)

  return (
    <>
      <Helmet>
        <title>{config?.pageTitle}</title>
      </Helmet>

      <Breadcrumb style={styles.breadcrumbs}>
        {config?.breadcrumbs?.map((breadcrumb, index) => (
          <Breadcrumb.Item key={index}>
            <Link to={breadcrumb?.path}>{breadcrumb?.title}</Link>
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>

      <PageHeader
        title={<span style={styles.pageTitle}>{config?.title}</span>}
        style={styles.pageHeader}
        onBack={config?.actions?.onBack}
      ></PageHeader>

      <Form
        form={form}
        name={config?.id}
        layout="vertical"
        onFinish={handleFinish}
        initialValues={data}
        style={styles.form}
        scrollToFirstError
        disabled={disabled ? disabled : loading}
        onValuesChange={(_, allValues) => {
          setFormInternalData(allValues);
        }}
      >
        <Box display={{ base: "none", md: "block", lg: "block" }}>
          <Row gutter={24}>
            {config?.components.map((component) => (
              <Col span={component?.span} key={component?.name}>
                <FormElement
                  {...component}
                  values={values}
                  form={form}
                  modals={modals}
                  data={data}
                  onFieldChange={onFieldChange?.[component?.name]}
                />
              </Col>
            ))}
          </Row>

          {children}

        </Box>

        {config?.components?.length > 0 && (
          <Box display={{ base: "block", md: "none", lg: "none" }}>
            {config?.components.map((component) => {
              return (
                <Stack span={component?.span} key={component?.name}>
                  <FormElement
                    {...component}
                    values={values}
                    form={form}
                    modals={modals}
                    data={data}
                  />
                </Stack>
              );
            })}
          </Box>
        )}


        <Space>
          <Button onClick={config?.actions?.onBack}>Cancel</Button>

          <Button type="primary" htmlType="submit" loading={submitting}>
            Save
          </Button>
        </Space>
      </Form>
    </>
  );
};

const styles = {
  pageHeader: {
    padding: 0,
    paddingBottom: 24,
  },
  pageTitle: {
    whiteSpace: "normal",
    overflow: "hidden",
  },
  form: {
    width: "100%",
  },
};

export default AddForm;
