import React from "react";
import { Route } from "react-router-dom";
import {
  AttendanceModeList,
  AttendanceModeAdd,
  AttendanceModeEdit,
} from "../pages/AttendanceModes";

export const register = (props) => {
  const basePath = "attendance-modes";

  return (
    <>
      <Route path={basePath} element={<AttendanceModeList />} exact={true} />
      <Route path={`${basePath}/add`} element={<AttendanceModeAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<AttendanceModeEdit />} />
    </>
  );
};
