export default {
  slugs: {
    singular: "consumable",
    plural: "consumables",
  },
  entityNames: {
    singular: "Consumable",
    plural: "Consumables",
  },
};
