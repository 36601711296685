import React from "react";
import { Route } from "react-router-dom";
import {
  ProjectResourceList,
  ProjectResourceAdd,
  ProjectResourceEdit,
  ProjectResourceReport,
} from "../pages/ProjectResources";

export const register = (props) => {
  const basePath = "project-resources";

  return (
    <>
      <Route path={basePath} element={<ProjectResourceList />} exact={true} />
      <Route path={`${basePath}/add`} element={<ProjectResourceAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<ProjectResourceEdit />} />
      <Route path={`${basePath}-report`} element={<ProjectResourceReport />} />
    </>
  );
};
