import { axios } from "../../../../../App";
import { v4 } from "uuid";
import config from "../config";

export const makeConfig = ({ navigate, auth }) => ({
  id: `${config?.slugs?.plural}-add`,
  title: `${config?.entityNames?.singular} - Require Materials`,
  pageTitle: `Add New ${config?.entityNames?.singular} - Require Materials`,
  breadcrumbs: [
    {
      path: "/quick-stocks/dashboard",
      title: "Home",
    },
    {
      path: `/quick-stocks/${config?.slugs?.plural}`,
      title: config?.entityNames?.plural,
    },
    {
      path: "",
      title: `${config?.entityNames?.singular} - Require Materials`,
    },
  ],
  components: [
    {
      span: 12,
      component: "input",
      label: "Date",
      name: "date",
      placeholder: "",
      disabled: true,
    },
    {
      span: 12,
      component: "select",
      label: "Project",
      name: "project",
      placeholder: "Select project",
      required: true,
      rules: [
        {
          required: true,
          message: "Project is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Task",
      name: "task",
      placeholder: "Select task",
      required: true,
      rules: [
        {
          required: true,
          message: "Task is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Task Material",
      name: "task_material",
      placeholder: "Select task material",
      required: true,
      rules: [
        {
          required: true,
          message: "Task material is required!",
        },
      ],
    },
    {
      span: 12,
      component: "number",
      label: "Requirable/ In Stock Quantity",
      name: "in_stock_quantity",
      min: 0,
      disabled: true,
    },
    {
      span: 12,
      component: "number",
      label: "Required Quantity",
      name: "required_quantity",
      min: 0,
      // min: 1,
      // rules: [
      //   {
      //     min: 1,
      //     message: "Required quantity must be greater than 0!",
      //   },
      // ],
    },
    {
      span: 24,
      component: "input",
      type: "hidden",
      name: "stock_ledger",
    },
    {
      span: 24,
      component: "editor",
      label: "Remarks",
      name: "remarks",
      placeholder: "Enter remarks",
      rows: 5,
    },
  ],
  actions: {
    onBack: () => navigate(`/quick-stocks/${config?.slugs?.plural}`),
    onFinish: async (values) => {
      try {
        const stockLedgersResp = await axios.get(
          `/stock-ledgers/${values?.stock_ledger}`
        );

        if (stockLedgersResp.status === 200) {
          const data = stockLedgersResp?.data?.data?.attributes;

          if (data?.quantity === 0) {
            return {
              success: false,
              message: `There is no items left for this material item inside stock ledger!`,
            };
          }

          if (data?.quantity !== values?.in_stock_quantity) {
            return {
              success: false,
              message: `There is a change in stock ledger quantity. Please check again!`,
            };
          } else {
            const response = await axios.post(`/${config?.slugs?.plural}`, {
              data: {
                ...values,
                uid: v4(),
                // creator: auth?.user?.id,
              },
            });

            if (response?.data) {
              return {
                success: true,
                message: `Add new ${config?.entityNames?.singular?.toLowerCase()} successfully!`,
              };
            }

            throw new Error();
          }
        }
      } catch (error) {
        return {
          success: false,
          message: `Add new ${config?.entityNames?.singular?.toLowerCase()} failed!`,
        };
      }
    },
  },
});
