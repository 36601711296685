export default {
  slugs: {
    singular: "agency-term",
    plural: "agency-terms",
  },
  entityNames: {
    singular: "Agency Term & Condition",
    plural: "Agency Terms & Conditions",
  },
};
