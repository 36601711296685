import React, { useEffect, useState } from "react";
import { AddForm } from "../../../components";
import { useNavigate, useParams } from "react-router-dom";
import { makeConfig } from "./config";
import { axios } from "../../../../../App";
import { fetchData } from "../../../../../utils/helpers/data.helper";
import qs from "qs";

const Page = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    status: ["Pending", "Approved"]?.map((item) => ({
      value: item,
      label: item,
    })),
    project: [],
    work_order: [],
    all_project: [],
    all_work_order: [],
  });
  const config = makeConfig({ navigate });
  const contentTypes = [
    {
      slug: "agencies",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          agency: data,
        })),
    },
    {
      slug: "employees",
      transformer: (item) => ({
        value: item?.id,
        label: `${item?.first_name} ${item?.last_name}`,
        uid: item?.uid,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          employee: data,
        //  approved_by: data,
        })),
    },
     {
      slug: "employees/approvers",
      transformer: (item) => ({
        value: item?.id,
        label: `${item?.first_name} ${item?.last_name}`,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
         approved_by: data,
        })),
    },
    {
      slug: "projects",
      query: {
        populate: "*",
      },
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
        agencies: item?.agencies?.data?.map((item) => ({
          others: item,
          value: item?.id,
          label: item?.attributes?.name,
        })),
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          all_project: data,
        })),
    },
    {
      slug: "agency-quotations",
      query: {
        filters: {
          status: "Approved",
        },
        populate: "*",
      },
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          all_work_order: data,
        })),
    },
  ];

  useEffect(() => {
    contentTypes?.forEach((contentType) => {
      fetchData(contentType);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const queryObject = {
          populate: "*",
        };

        const response = await axios.get(
          `/${config?.slugs?.plural}/${id}?${qs.stringify(queryObject)}`
        );

        if (response?.data) {
          const agencyId = response?.data?.data?.attributes?.agency?.data?.id;
          const projectId = response?.data?.data?.attributes?.project?.data?.id;

          // Fill data into project select box
          if (agencyId) {
            let filteredProjects = [];
            let agencyIds = [];

            if (values?.all_project?.length > 0) {
              values?.all_project?.forEach((project) => {
                agencyIds = project?.agencies?.map((item) =>
                  Number(item?.value)
                );

                if (agencyIds?.some((id) => Number(id) === Number(agencyId))) {
                  filteredProjects.push(project);
                }
              });
            }

            setValues((prev) => ({
              ...prev,
              project: filteredProjects,
            }));
          } else {
            setValues((prev) => ({
              ...prev,
              project: [],
              work_order: [],
            }));
          }

          // Fill data into work order select box
          if (projectId) {
            setValues((prev) => ({
              ...prev,
              work_order: values?.all_work_order
                ?.filter(
                  (item) =>
                    item?.project?.data?.id === Number(projectId) &&
                    item?.agency?.data?.id === Number(agencyId)
                )
                ?.map((item) => ({
                  value: item?.id,
                  label: item?.heading,
                })),
            }));
          }

          let newData = {
            ...response?.data?.data?.attributes,
            id: response?.data?.data?.id,
            employee: response?.data?.data?.attributes?.employee?.data?.id,
            approved_by:
              response?.data?.data?.attributes?.approved_by?.data?.id,
            work_order: response?.data?.data?.attributes?.work_order?.data?.id,
            //project: projectId,
            project: response?.data?.data?.attributes?.project?.data?.id,
            agency: agencyId,
          };

          if (!response?.data?.data?.attributes?.attachment?.data) {
            newData["attachment"] = null;
          }

          setData(newData);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    if (values?.all_project?.length > 0 && values?.all_work_order?.length > 0) {
      fetchData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, values?.all_project, values?.all_work_order]);

  return (
    <AddForm
      config={config}
      data={data}
      loading={loading}
      values={values}
      onFieldChange={{
        // Reference: https://4x.ant.design/components/form/#FormInstance

        agency: (antForm) => {
          const agencyId = antForm.getFieldValue("agency");

          if (agencyId) {
            let filteredProjects = [];
            let agencyIds = [];

            if (values?.all_project?.length > 0) {
              values?.all_project?.forEach((project) => {
                agencyIds = project?.agencies?.map((item) =>
                  Number(item?.value)
                );

                if (agencyIds?.some((id) => Number(id) === Number(agencyId))) {
                  filteredProjects.push(project);
                }
              });
            }

            antForm.setFieldValue("project", undefined);
            antForm.setFieldValue("work_order", undefined);

            setValues((prev) => ({
              ...prev,
              project: filteredProjects,
            }));
          } else {
            setValues((prev) => ({
              ...prev,
              project: [],
              work_order: [],
            }));
          }
        },
        project: (antForm) => {
          const projectId = antForm.getFieldValue("project");
          const agencyId = antForm.getFieldValue("agency");

          if (projectId) {
            antForm.setFieldValue("work_order", undefined);

            setValues((prev) => ({
              ...prev,
              work_order: values?.all_work_order
                ?.filter(
                  (item) =>
                    item?.project?.data?.id === Number(projectId) &&
                    item?.agency?.data?.id === Number(agencyId)
                )
                ?.map((item) => ({
                  value: item?.id,
                  label: item?.heading,
                })),
            }));
          }
        },
      }}
    />
  );
};

export default Page;
