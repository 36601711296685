import React, { useState, useEffect } from "react";
import { AddForm } from "../../../components";
import { Link, useNavigate, useParams } from "react-router-dom";
import { makeConfig as makeViewConfig } from "./view-config";
import { makeConfig as makeAddConfig } from "./add-config";
import { useSelector } from "react-redux";
import { fetchData } from "../../../../../utils/helpers/data.helper";
import { axios } from "../../../../../App";
import qs from "qs";
import {
  Tag,
  PageHeader,
  Breadcrumb,
  Button,
  Space,
  notification,
  Empty,
  Spin,
} from "antd";
import moment from "moment";
import { GrDownload } from "react-icons/gr";
import { saveAs } from "file-saver";

const Page = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const auth = useSelector((state) => state?.auth);
  const viewConfig = makeViewConfig({ navigate, auth });
  const addConfig = makeAddConfig({ navigate, auth });
  const [data, setData] = useState({});
  const [loadingTicket, setLoadingTicket] = useState(false);
  const [hasTicket, setHasTicket] = useState(false);
  const [values, setValues] = useState({});
  const [responses, setResponses] = useState([]);
  const [showResponseBox, setShowResponseBox] = useState(false);
  const [loadingResponses, setLoadingResponses] = useState(false);
  const [hasResponses, setHasResponses] = useState(false);
  const contentTypes = [
    {
      slug: "employees",
      transformer: (item) => ({
        value: item?.id,
        label: `${item?.first_name} ${item?.last_name}`,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          sender: data,
          recipients: data,
        })),
    },
  ];

  const onDownloadAttachment = (data) => {
    const url = `${process.env.REACT_APP_BASE_API_URL}${data?.url}?updated_at=${data?.updatedAt}`;

    saveAs(url, data?.name);
  };

  const onMarkAsClosed = async () => {
    try {
      const response = await axios.put(`/${viewConfig?.slugs?.plural}/${id}`, {
        data: {
          status: "Closed",
        },
      });

      if (response?.data) {
        notification["success"]({
          message: "Mark As Closed",
          description: "Mark the ticket as closed successfully!",
        });

        navigate(`/quick-teams/${viewConfig?.slugs?.plural}`);
      }
    } catch (error) {
      console.log(error);

      notification["error"]({
        message: "Mark As Closed",
        description: "Mark the ticket as closed unsuccessfully!",
      });
    }
  };

  const getPriorityColor = (priority) => {
    switch (priority) {
      case "High":
        return "#f50";

      case "Medium":
        return "#faad14";

      default:
        return "default";
    }
  };

  const fetchResponses = async (id) => {
    try {
      setLoadingResponses(true);

      const queryObject = {
        populate: "*",
        filters: {
          ticket: id,
        },
      };

      const response = await axios.get(
        `/ticket-responses?${qs.stringify(queryObject)}`
      );

      if (response?.data) {
        setResponses(
          response?.data?.data?.map((item) => ({
            ...item?.attributes,
            id: item?.id,
          }))
        );
        setHasResponses(true);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingResponses(false);
    }
  };

  const onDoneResponse = () => {
    fetchResponses(id);
    setShowResponseBox(false);
  };

  const toggleResponseBox = () => {
    setShowResponseBox((prev) => !prev);
  };

  useEffect(() => {
    contentTypes?.forEach((contentType) => {
      fetchData(contentType);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingTicket(true);

        const queryObject = {
          populate: "*",
        };

        const response = await axios.get(
          `/${viewConfig?.slugs?.plural}/${id}?${qs.stringify(queryObject)}`
        );

        if (response?.data) {
          setData({
            ...response?.data?.data?.attributes,
            id: response?.data?.data?.id,
          });
          setHasTicket(true);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoadingTicket(false);
      }
    };

    fetchData();
    fetchResponses(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <div>
      <Breadcrumb style={styles.breadcrumbs}>
        {viewConfig?.breadcrumbs?.map((breadcrumb, index) => (
          <Breadcrumb.Item key={index}>
            <Link to={breadcrumb?.path}>{breadcrumb?.title}</Link>
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>

      <PageHeader
        title={viewConfig?.title}
        style={styles.pageHeader}
        onBack={viewConfig?.actions?.onBack}
      ></PageHeader>

      {loadingTicket && (
        <div style={styles.loadingContainer}>
          <Spin />
        </div>
      )}

      {!loadingTicket && !hasTicket && <Empty />}

      {!loadingTicket && hasTicket && (
        <div style={styles.ticketBox}>
          <img
            src="https://img.icons8.com/external-inipagistudio-mixed-inipagistudio/344/external-question-business-mentoring-inipagistudio-mixed-inipagistudio.png"
            alt=""
            style={styles.ticketIcon}
          />

          <div style={styles.ticketDetails}>
            <div style={styles.ticketHeader}>
              <span style={styles.ticketNumber}>TICKET #{data?.id}</span>

              <span style={{ color: "#888" }}>
                <strong>From:</strong>{" "}
                {data?.sender?.data?.attributes?.first_name}{" "}
                {data?.sender?.data?.attributes?.last_name} •{" "}
                {moment(data?.createdAt).format("DD-MM-YYYY, HH:mm")}
              </span>
            </div>

            <h3 style={styles.ticketTitle}>{data?.subject}</h3>
            <p>
              <strong>To:</strong>{" "}
              <>
                {data?.recipients?.data
                  ?.map(
                    (item) =>
                      `${item?.attributes?.first_name} ${item?.attributes?.last_name}`
                  )
                  ?.join(", ")}
              </>
              <div>
                <Tag
                  color={getPriorityColor(data?.priority)}
                  style={{
                    textTransform: "uppercase",
                    marginTop: 10,
                    fontWeight: "bold",
                  }}
                >
                  {data?.priority}
                </Tag>{" "}
              </div>
            </p>
            <p
              dangerouslySetInnerHTML={{ __html: data?.message }}
              className="ticket-message"
            ></p>

            {data?.attachment?.data && (
              <div style={styles.attachments}>
                <span>ATTACHMENT</span>

                <span style={styles.attachment}>
                  <img
                    src="https://img.icons8.com/external-inipagistudio-mixed-inipagistudio/344/external-document-computer-and-internet-inipagistudio-mixed-inipagistudio.png"
                    alt=""
                    style={styles.attachmentIcon}
                  />

                  <span style={styles.attachmentText}>
                    <span style={styles.attachmentName}>
                      {data?.attachment?.data?.attributes?.name}
                    </span>
                    <span style={styles.attachmentSize}>
                      {data?.attachment?.data?.attributes?.size} KB
                    </span>
                  </span>

                  <Button
                    style={styles.button}
                    icon={<GrDownload />}
                    onClick={() =>
                      onDownloadAttachment(data?.attachment?.data?.attributes)
                    }
                  />
                </span>
              </div>
            )}

            {data?.status !== "Closed" && (
              <Space>
                <Button onClick={toggleResponseBox} type="primary">
                  Leave response
                </Button>
                <Button onClick={onMarkAsClosed}>Mark as closed</Button>
              </Space>
            )}
          </div>
        </div>
      )}

      <h2>
        Responses{" "}
        <span>{responses?.length > 0 ? `(${responses?.length})` : ``}</span>
      </h2>

      {responses.length === 0 && <Empty />}

      {data?.status !== "Closed" && showResponseBox && (
        <div style={styles.addFormContainer}>
          <h2 style={styles.addFormTitle}>Your Response</h2>
          <AddForm
            config={{
              ...addConfig,
              actions: {
                ...addConfig.actions,
                onBack: toggleResponseBox,
              },
            }}
            values={values}
            // showsCancel={false}
            saveText="Submit"
            data={{
              ticketId: id,
            }}
            onSuccess={onDoneResponse}
            showsTitle={false}
          />
        </div>
      )}

      {loadingResponses && (
        <div style={styles.loadingContainer}>
          <Spin />
        </div>
      )}

      {!loadingResponses && !hasResponses && !showResponseBox && <Empty />}

      {!loadingResponses &&
        hasResponses &&
        responses?.map((response) => (
          <div style={styles.ticketBoxResponse}>
            <img
              src="https://img.icons8.com/external-inipagistudio-mixed-inipagistudio/344/external-chat-distant-friend-inipagistudio-mixed-inipagistudio-2.png"
              alt=""
              style={{ ...styles.ticketIcon, marginTop: 8 }}
            />

            <div style={styles.ticketDetails}>
              <div style={styles.ticketHeader}>
                <span style={styles.ticketUsername}>
                  {response?.sender?.data?.attributes?.first_name}{" "}
                  {response?.sender?.data?.attributes?.last_name}
                </span>
                <span style={{ color: "#888" }}>
                  {moment(response?.createdAt).format("DD-MM-YYYY, HH:mm")}
                </span>
              </div>

              <p
                dangerouslySetInnerHTML={{
                  __html: response?.content,
                }}
                className="ticket-message"
              ></p>

              {response?.attachment && (
                <div style={styles.attachments}>
                  <span>ATTACHMENT</span>

                  <span style={styles.attachment}>
                    <img
                      src="https://img.icons8.com/external-inipagistudio-mixed-inipagistudio/344/external-document-computer-and-internet-inipagistudio-mixed-inipagistudio.png"
                      alt=""
                      style={styles.attachmentIcon}
                    />

                    <span style={styles.attachmentText}>
                      <span style={styles.attachmentName}>
                        {response?.attachment?.data?.attributes?.name}
                      </span>
                      <span style={styles.attachmentSize}>
                        {response?.attachment?.data?.attributes?.size} KB
                      </span>
                    </span>

                    <Button
                      style={styles.button}
                      icon={<GrDownload />}
                      onClick={() => onDownloadAttachment(response?.attachment?.data?.attributes)}
                    />
                  </span>
                </div>
              )}
            </div>
          </div>
        ))}
    </div>
  );
};

const styles = {
  ticketBox: {
    marginBottom: 24,
    border: "2px solid #eff2f3",
    padding: 24,
    display: "flex",
    borderRadius: 12,
    paddingBottom: 32,
  },
  ticketIcon: {
    width: 42,
    height: 42,
    marginRight: 24,
  },
  ticketDetails: {
    flex: 1,
  },
  responseBox: {
    padding: 24,
    background: "#eff2f3",
  },
  label: {
    fontWeight: "bold",
  },
  value: {},

  ticketNumber: {
    textTransform: "uppercase",
    fontWeight: "bold",
    color: "#3E3EF4",
  },
  ticketTitle: {
    fontSize: 24,
    margin: 0,
    padding: 0,
    marginBottom: 0,
  },
  ticketBoxResponse: {
    border: "2px solid #eff2f3",
    padding: 24,
    display: "flex",
    marginLeft: 92,
    borderRadius: 12,
    marginTop: 16,
  },
  ticketUsername: {
    margin: 0,
    padding: 0,
    fontSize: 15,
    fontWeight: "bold",
  },
  ticketHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  attachments: {
    marginBottom: 24,
    marginTop: 16,
  },
  attachment: {
    padding: 12,
    borderRadius: 6,
    marginTop: 6,
    maxWidth: 320,
    display: "flex",
    alignItems: "center",
    border: "2px solid #eff2f3",
    background: "#eff2f3",
  },
  attachmentText: {
    marginLeft: 12,
    flex: 1,
  },
  attachmentName: {
    fontWeight: "bold",
    display: "block",
  },
  attachmentSize: {
    color: "#888",
    display: "block",
    fontSize: 12,
  },
  attachmentIcon: {
    width: 42,
    height: 42,
  },
  pageHeader: {
    padding: 0,
    paddingBottom: 24,
  },
  addFormContainer: {
    marginBottom: 24,
    border: "2px solid #eff2f3",
    padding: 24,
    borderRadius: 12,
    background: "#eff2f3",
  },
  addFormTitle: {
    marginBottom: 6,
  },
  loadingContainer: {
    textAlign: "center",
    padding: 24,
  },
};

export default Page;
