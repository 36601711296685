import React, { useState, useEffect } from "react";
import { AddForm } from "../../../components";
import { useNavigate } from "react-router-dom";
import { makeConfig } from "./config";
import { useSelector } from "react-redux";
import { fetchData } from "../../../../../utils/helpers/data.helper";

const Page = (props) => {
  const navigate = useNavigate();
  const auth = useSelector((state) => state?.auth);
  const config = makeConfig({ navigate, auth });
  const [values, setValues] = useState({
    work_hour: [{
      label: 'Full Day',
      value: 8
    }, {
      label: 'Half Day',
      value: 4
    }]?.map((item) => ({
      value: item?.value,
      label: item?.label,
    })),
  });
  const contentTypes = [
    {
      slug: "tasks",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          task: data,
        })),
    },
    {
      slug: "manpower-types",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          manpower_type: data,
        })),
    },
  ];

  useEffect(() => {
    contentTypes?.forEach((contentType) => {
      fetchData(contentType);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AddForm
      config={config}
      values={values}
      mode={props?.mode}
      data={props?.data}
      actions={props?.actions}
      disabledItems={props?.disabledItems}
    />
  );
};

export default Page;
