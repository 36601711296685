export default {
  slugs: {
    singular: "attendance-type",
    plural: "attendance-types",
  },
  entityNames: {
    singular: "Attendance Type",
    plural: "Attendance Types",
  },
};
