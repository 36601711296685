export default {
  slugs: {
    singular: "vendor-debit-note",
    plural: "vendor-debit-notes",
  },
  entityNames: {
    singular: "Vendor Debit Note",
    plural: "Vendor Debit Notes",
  },
};
