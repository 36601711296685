import React from "react";
import { Route } from "react-router-dom";
import {
  PaymentModeList,
  PaymentModeAdd,
  PaymentModeEdit,
} from "../pages/PaymentModes";

export const register = (props) => {
  const basePath = "payment-modes";

  return (
    <>
      <Route path={basePath} element={<PaymentModeList />} exact={true} />
      <Route path={`${basePath}/add`} element={<PaymentModeAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<PaymentModeEdit />} />
    </>
  );
};
