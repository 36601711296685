import config from "../config";

const commify = (x) => {
  return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export default {
  id: `${config?.slugs?.plural}-list`,
  pageTitle: config?.entityNames?.plural,
  breadcrumbs: [
    {
      path: "/quick-setup",
      title: "Home",
    },
    {
      path: "",
      title: config?.entityNames?.plural,
    },
  ],
  columns: [
    {
      title: "Name",
      dataIndex: "name",
      selectable: true,
      sorter: (a, b) => a?.name?.localeCompare(b?.name),
      width: 250,
    },
    {
      title: "Contact Person",
      dataIndex: "contact_person",
      selectable: true,
      sorter: (a, b) => a?.contact_person?.localeCompare(b?.contact_person),
      width: 250,
    },
    {
      title: "Company Type",
      dataIndex: "firm_type",
      selectable: true,
      references: {
        fieldName: "name",
      },
    },
    {
      title: "City",
      dataIndex: "city",
      selectable: true,
      references: {
        fieldName: "name",
      },
    },
    {
      title: "State",
      dataIndex: "state",
      selectable: true,
      references: {
        fieldName: "name",
      },
    },
  ],
  ...config,
};
