export default {
  slugs: {
    singular: "ticket",
    plural: "tickets",
  },
  entityNames: {
    singular: "Ticket",
    plural: "Tickets",
  },
};
