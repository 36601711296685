import { axios } from "../../../../../App";
import { v4 } from "uuid";
import config from "../config";

export const makeConfig = ({ navigate, auth }) => ({
  id: `${config?.slugs?.plural}-add`,
  title: `Add New ${config?.entityNames?.singular}`,
  pageTitle: `Add New ${config?.entityNames?.singular}`,
  breadcrumbs: [
    {
      path: "/quick-stocks/dashboard",
      title: "Home",
    },
    {
      path: `/quick-stocks/${config?.slugs?.plural}`,
      title: config?.entityNames?.plural,
    },
    {
      path: "",
      title: `Add New ${config?.entityNames?.singular}`,
    },
  ],
  components: [
    {
      span: 12,
      component: "select",
      label: "Vendor Group",
      name: "vendor_group",
      placeholder: "Select vendor group",
      required: true,
      rules: [
        {
          required: true,
          message: "Vendor group is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Company Type",
      name: "firm_type",
      placeholder: "Select firm type",
      required: true,
      rules: [
        {
          required: true,
          message: "Company type is required!",
        },
      ],
    },
    {
      span: 12,
      component: "uploader",
      label: "Logo",
      name: "logo",
      placeholder: "Upload logo",
    },
    {
      span: 12,
      component: "uploader",
      label: "Header",
      name: "header",
      placeholder: "Upload header",
    },
    {
      span: 12,
      component: "input",
      label: "Name",
      name: "name",
      required: true,
      placeholder: "Enter name",
      rules: [
        {
          required: true,
          message: "Name is required!",
        },
      ],
    },
    {
      span: 12,
      component: "input",
      label: "Contact Person",
      name: "contact_person",
      required: true,
      placeholder: "Enter contact person",
      rules: [
        {
          required: true,
          message: "Company name is required!",
        },
      ],
    },
    {
      span: 12,
      component: "input",
      label: "Website",
      name: "website",
      placeholder: "Enter website",
    },
    {
      span: 12,
      component: "select",
      label: "Bank",
      name: "bank",
      placeholder: "Select bank",
    },
    {
      span: 12,
      component: "input",
      label: "Branch",
      name: "branch",
      placeholder: "Enter branch",
    },
    {
      span: 12,
      component: "input",
      label: "Account Number",
      name: "account_no",
      placeholder: "Enter account number",
    },
    {
      span: 24,
      component: "input",
      label: "Address Line 1",
      name: "address_line_1",
      required: true,
      placeholder: "Enter address line 1",
      rules: [
        {
          required: true,
          message: "Address line 1 is required!",
        },
      ],
    },
    {
      span: 24,
      component: "input",
      label: "Address Line 2",
      name: "address_line_2",
      placeholder: "Enter address line 2",
    },
    {
      span: 12,
      component: "select",
      label: "State",
      name: "state",
      placeholder: "Select state",
      required: true,
      rules: [
        {
          required: true,
          message: "State is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "City",
      name: "city",
      placeholder: "Select city",
      required: true,
      rules: [
        {
          required: true,
          message: "City is required!",
        },
      ],
    },
    {
      span: 12,
      component: "input",
      label: "PIN code",
      name: "pin_code",
      placeholder: "Enter PIN code",
      rules: [
        {
          pattern: /^[0-9]{6}$/,
          message: "PIN code should be 6 digits",
        },
      ],
    },
    {
      span: 12,
      component: "input",
      label: "STD code",
      name: "std_code",
      placeholder: "Enter STD code",
    },
    {
      span: 12,
      component: "input",
      label: "Phone Number 1",
      name: "phone_number_1",
      placeholder: "Enter phone number 1",
    },
    {
      span: 12,
      component: "input",
      label: "Phone Number 2",
      name: "phone_number_2",
      placeholder: "Enter phone number 2",
    },
    {
      span: 12,
      component: "input",
      label: "Mobile Number 1",
      name: "mobile_number_1",
      required: true,
      placeholder: "Enter mobile number 1",
      rules: [
        {
          required: true,
          message: "Mobile number 1 is required!",
        },
      ],
    },
    {
      span: 12,
      component: "input",
      label: "Mobile Number 2",
      name: "mobile_number_2",
      placeholder: "Enter mobile number 2",
    },
    {
      span: 12,
      component: "input",
      label: "Email 1",
      name: "email_1",
      required: true,
      placeholder: "Enter email 1",
      rules: [
        {
          required: true,
          message: "Email 1 is required!",
        },
      ],
    },
    {
      span: 12,
      component: "input",
      label: "Email 2",
      name: "email_2",
      placeholder: "Enter email 2",
    },
    {
      span: 12,
      component: "input",
      label: "Registration No",
      name: "registration_no",
      placeholder: "Enter registration no",
    },
    {
      span: 12,
      component: "uploader",
      label: "Registration Document",
      name: "registration_document",
      placeholder: "Upload registration document",
    },
    {
      span: 12,
      component: "input",
      label: "PAN No",
      name: "pan_no",
      placeholder: "Enter PAN no",
    },
    {
      span: 12,
      component: "uploader",
      label: "PAN Document",
      name: "pan_document",
      placeholder: "Upload PAN document",
    },
    {
      span: 12,
      component: "input",
      label: "GSIN No",
      name: "gsin_no",
      placeholder: "Enter GSIN no",
    },
    {
      span: 12,
      component: "uploader",
      label: "GSIN Document",
      name: "gsin_document",
      placeholder: "Upload GSIN document",
    },
    {
      span: 12,
      component: "input",
      label: "IFSC Code",
      name: "ifsc_code",
      placeholder: "Enter IFSC code",
    },
    {
      span: 12,
      component: "uploader",
      label: "IFSC Document",
      name: "ifsc_document",
      placeholder: "Upload IFSC document",
    },
    {
      span: 12,
      component: "input",
      label: "MICR Code",
      name: "micr_code",
      placeholder: "Enter MICR code",
    },
    {
      span: 12,
      component: "uploader",
      label: "MICR Document",
      name: "micr_document",
      placeholder: "Upload MICR document",
    },
    {
      span: 12,
      component: "select",
      label: "TDS Type",
      name: "tds_type",
      placeholder: "Select TDS type",
      // required: true,
      // rules: [
      //   {
      //     required: true,
      //     message: "TDS type is required!",
      //   },
      // ],
    },
    {
      span: 12,
      component: "select",
      label: "GST Type",
      name: "gst_type",
      placeholder: "Select GST type",
      // required: true,
      // rules: [
      //   {
      //     required: true,
      //     message: "GST type is required!",
      //   },
      // ],
    },
    {
      span: 12,
      component: "input",
      label: "Work Types",
      name: "work_type",
      placeholder: "Enter work types",
    },
    {
      span: 24,
      component: "input",
      type: "hidden",
      name: "logo",
    },
    {
      span: 24,
      component: "input",
      type: "hidden",
      name: "header",
    },
    {
      span: 24,
      component: "input",
      type: "hidden",
      name: "registration_document",
    },
    {
      span: 24,
      component: "input",
      type: "hidden",
      name: "pan_document",
    },
    {
      span: 24,
      component: "input",
      type: "hidden",
      name: "gsin_document",
    },
    {
      span: 24,
      component: "input",
      type: "hidden",
      name: "ifsc_document",
    },
    {
      span: 24,
      component: "input",
      type: "hidden",
      name: "micr_document",
    },
    {
      span: 24,
      component: "editor",
      label: "Remarks",
      name: "remarks",
      placeholder: "Enter remarks",
      rows: 5,
    },
  ],
  actions: {
    onBack: () => navigate(`/quick-stocks/${config?.slugs?.plural}`),
    onFinish: async (values) => {
      try {
        const response = await axios.post(`/${config?.slugs?.plural}`, {
          data: {
            ...values,
            uid: v4(),
            // creator: auth?.user?.id,
            registration_document: values?.registration_document || null,
            pan_document: values?.pan_document || null,
            gsin_document: values?.gsin_document || null,
            ifsc_document: values?.ifsc_document || null,
            micr_document: values?.micr_document || null,
            logo: values?.logo || null,
            header: values?.header || null,
          },
        });

        if (response?.data) {
          return {
            success: true,
            message: `Add new ${config?.entityNames?.singular?.toLowerCase()} successfully!`,
          };
        }

        throw new Error();
      } catch (error) {
        return {
          success: false,
          message: `Add new ${config?.entityNames?.singular?.toLowerCase()} failed!`,
        };
      }
    },
  },
});
