import React from "react";
import { Route } from "react-router-dom";
import {
  ProjectList,
  ProjectAdd,
  ProjectEdit,
  ProjectGrid,
  ProjectReport,
  ProjectView,
} from "../pages/Projects";

export const register = (props) => {
  const basePath = "projects";

  return (
    <>
      <Route path={basePath} element={<ProjectList />} exact={true} />
      <Route path={`${basePath}/add`} element={<ProjectAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<ProjectEdit />} />
      <Route path={`${basePath}/view/:id`} element={<ProjectView />} />
      <Route path={`${basePath}-grid`} element={<ProjectGrid />} />
      <Route path={`${basePath}-report`} element={<ProjectReport />} />
    </>
  );
};
