import React from "react";
import { Route } from "react-router-dom";
import { ProjectCostReport } from "../pages/ProjectCosts";
import CashFlowReport from "../pages/ProjectCosts/Report/CashFlowReport";

export const register = (props) => {
  const basePath = "project-costs";

  return (
    <>
      <Route path={`${basePath}-report`} element={<ProjectCostReport />} />
      <Route path={`${basePath}-flow-report`} element={<CashFlowReport />} />

    </>
  );
};
