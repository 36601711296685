import React from "react";
import { Route } from "react-router-dom";
import { WorkItemList, WorkItemAdd, WorkItemEdit } from "../pages/WorkItems";

export const register = (props) => {
  const basePath = "work-items";

  return (
    <>
      <Route path={basePath} element={<WorkItemList />} exact={true} />
      <Route path={`${basePath}/add`} element={<WorkItemAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<WorkItemEdit />} />
    </>
  );
};
