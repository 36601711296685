import React, { useState, useEffect } from "react";
import { AddForm } from "../../../components";
import { useNavigate } from "react-router-dom";
import { makeConfig } from "./config";
import { useSelector } from "react-redux";
import { fetchData } from "../../../../../utils/helpers/data.helper";

const Page = (props) => {
  const navigate = useNavigate();
  const auth = useSelector((state) => state?.auth);
  const config = makeConfig({ navigate, auth });
  const [values, setValues] = useState({
    status: ["Draft", "Official"]?.map((item) => ({
      value: item,
      label: item,
    })),
  });

  const contentTypes = [
    {
      slug: "employees",
      transformer: (item) => ({
        value: item?.id,
        label: `${item?.first_name} ${item?.last_name}`,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          sender: data,
          recipients: data,
          cc_recipients: data,
          bcc_recipients: data,
        })),
    },
  ];

  useEffect(() => {
    contentTypes?.forEach((contentType) => {
      fetchData(contentType);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AddForm
      config={config}
      values={values}
      showExtraButton={true}
      extraButtonTitle="Save as Draft"
      extraButtonHandler={config?.actions?.onSaveDraft}
      submitButtonText="Send"
    />
  );
};

export default Page;
