import React, { useEffect, useState } from "react";
import { Breadcrumb, PageHeader, Tabs } from "antd";
import TimelineTab from "./components/TimelineTab";
import TeamAvailabilityTab from "./components/TeamAvailabilityTab";
import { makeConfig } from "./config";
import { Link, useLocation, useNavigate } from "react-router-dom";
import qs from "qs";

const { TabPane } = Tabs;

const GanttChart = (props) => {
  const navigate = useNavigate();
  const config = makeConfig({ navigate });
  const location = useLocation();
  const [projectId, setProjectId] = useState(0);

  useEffect(() => {
    const queryString = location?.search?.substring(1);

    if (queryString !== "") {
      const queryObject = qs.parse(queryString);

      if (queryObject?.projectId) {
        setProjectId(queryObject?.projectId);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.search]);

  return (
    <div style={styles.container}>
      <Breadcrumb style={styles.breadcrumbs}>
        {config?.breadcrumbs?.map((breadcrumb, index) => (
          <Breadcrumb.Item key={index}>
            <Link to={breadcrumb?.path}>{breadcrumb?.title}</Link>
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>

      <PageHeader
        title={config?.title}
        style={styles.pageHeader}
        onBack={config?.actions?.onBack}
        // extra={[
        //   <Space>
        //     <Tooltip placement="top" title="Add new item">
        //       <Button icon={<PlusOutlined />} onClick={toggleAdd} />
        //     </Tooltip>

        //     <Tooltip placement="top" title="Refresh">
        //       <Button icon={<ReloadOutlined />} onClick={onRefresh} />
        //     </Tooltip>
        //   </Space>,
        // ]}
      ></PageHeader>

      <Tabs>
        <TabPane tab="Timeline" key="1">
          <TimelineTab projectId={projectId} />
        </TabPane>

        <TabPane tab="Team Availability" key="2">
          <TeamAvailabilityTab projectId={projectId} />
        </TabPane>
      </Tabs>
    </div>
  );
};

const styles = {
  container: {},
  pageHeader: {
    padding: 0,
  },
};

export default GanttChart;
