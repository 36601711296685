import { Link } from "react-router-dom";
import { FiExternalLink } from "react-icons/fi";
import { Tag, Button, Badge } from "antd";
import moment from "moment";

export const CHART_SLUGS = {
  COST_PERFORMANCE: "cost-performance",
  PROJECT_PERFORMANCE: "project-performance",
  DISCUSSIONS: "discussions",
  PERFORMANCE_REVIEWS: "performance-reviews",
  PROJECTS_HEALTH: "projects-health",
  PHASE_COMPLETION: "phase-completion",
  TIMELINE_AND_RESOURCES: "timeline-and-resources",
};

export const CHART_SCHEMAS = {
  COST_PERFORMANCE: {
    title: "Cost Performance",
    angleField: "value",
    colorField: "type",
  },
  PROJECT_PERFORMANCE: {
    title: "Performance",
  },
  DISCUSSIONS: {
    title: "Discussions",
  },
  PERFORMANCE_REVIEWS: {
    title: "Performance Review",
    angleField: "value",
    colorField: "type",
  },
  PROJECTS_HEALTH: {
    title: "Project Health",
    xField: "project",
    yField: "value",
    seriesField: "category",
  },
  PHASE_COMPLETION: {
    title: "Phase Completion",
    childrenSchema: [
      {
        title: "Milestone Statuses",
        angleField: "value",
        colorField: "type",
      },
      {
        title: "Task Statuses",
        angleField: "value",
        colorField: "type",
      },
    ],
  },
  TIMELINE_AND_RESOURCES: {
    title: "Time Line and Resource Capacity",
    xField: "project_name",
    yField: "value",
    seriesField: "name",
  },
};

export const DATE_RANGES = [
  {
    value: "all_time",
    label: "All Time",
  },
  {
    value: "today",
    label: "Today",
  },
  {
    value: "week",
    label: "This Week",
  },
  {
    value: "month",
    label: "This Month",
  },
  {
    value: "quarter",
    label: "This Quarter",
  },
  {
    value: "year",
    label: "This Year",
  },
];

export const PROJECT_COLUMNS = [
  {
    title: "ID",
    dataIndex: "id",
  },
  {
    title: "Name",
    dataIndex: "name",
  },
  {
    title: "Start Date",
    dataIndex: "start_date",
    render: (text) => <span>{moment(text).format("DD-MM-YYYY, HH:mm")}</span>,
  },
  {
    title: "End Date",
    dataIndex: "end_date",
    render: (text) => <span>{moment(text).format("DD-MM-YYYY, HH:mm")}</span>,
  },
  {
    title: "Project Type",
    dataIndex: "id",
    render: (_, { project_type }) => {
      return <span>{project_type?.data?.attributes?.name}</span>;
    },
  },
  {
    title: "Status",
    dataIndex: "status",
    selectable: true,
    render: (text) => <Tag>{text}</Tag>,
  },
  {
    title: "Details",
    dataIndex: "id",
    render: (text) => (
      <Link to={`/quick-projects/projects/edit/${text}`}>
        <Button icon={<FiExternalLink style={{ margin: "0 auto" }} />}></Button>
      </Link>
    ),
  },
];

export const PROJECT_HEALTH_COLUMNS = [
  {
    title: "ID",
    dataIndex: "id",
  },
  {
    title: "Project",
    dataIndex: "name",
  },
  {
    title: "Delay",
    dataIndex: "delay",
  },
  {
    title: "Cost Escalation",
    dataIndex: "cost_escalation",
  },
  {
    title: "Status",
    dataIndex: "status",
    render: (text) => {
      const renderByStatus = (status) => {
        // eslint-disable-next-line default-case
        switch (status) {
          case "urgent":
            return <Badge color="#ff4d4f" text="Urgent" />;

          case "deviation":
            return <Badge color="#fadb14" text="Deviation" />;

          case "smooth":
            return <Badge color="#52c41a" text="Smooth" />;
        }
      };

      return renderByStatus(text);
    },
  },
  {
    title: "Details",
    dataIndex: "id",
    render: (text) => (
      <Link to={`/quick-projects/projects/edit/${text}`}>
        <Button icon={<FiExternalLink style={{ margin: "0 auto" }} />}></Button>
      </Link>
    ),
  },
];

export const REPORTS = [
  {
    id: "dashboard-report",
    name: "Dashboard Report",
  },
  {
    id: "daily-reports-report",
    name: "Daily Report",
  },
  {
    id: "tasks-report",
    name: "Tasks Report",
  },
  {
    id: "rfis-report",
    name: "RFIs Report",
  },
  {
    id: "milestones-report",
    name: "Milestones Report",
  },
  {
    id: "construction-cost-report",
    name: "Construction Costs Report",
  },
  {
    id: "timeline-variance-report",
    name: "Time line Variance Report",
  },
  {
    id: "projects-report",
    name: "Projects Report",
  },
  {
    id: "project-costs-report",
    name: "Costs Report",
  },
  {
    id: "task-materials-report",
    name: "Materials Report",
  },
  {
    id: 'task-manpowers-report',
    name: 'Manpower Logs Report',
  },
  {
    id: 'project-costs-flow-report',
    name: 'Cash Flow Report',
  }
];
