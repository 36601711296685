import config from "../config";
import moment from "moment";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  id: `${config?.slugs?.plural}-list`,
  pageTitle: `${config?.entityNames?.plural} Report`,
  code: "VENDOR_MATERIAL_RECEIVABLES",
  breadcrumbs: [
    {
      path: "/quick-stocks/dashboard",
      title: "Home",
    },
    {
      path: `/quick-stocks/reports`,
      title: "Reports",
    },
    {
      path: "",
      title: `${config?.entityNames?.plural} Report`,
    },
  ],
  columns: [
    // {
    //   title: "Material Item",
    //   dataIndex: "material_item",
    //   selectable: true,
    //   references: {
    //     fieldName: "name",
    //   },
    // },
    // {
    //   title: "Project",
    //   dataIndex: "project",
    //   selectable: true,
    //   references: {
    //     fieldName: "name",
    //   },
    // },
    // // {
    // //   title: "Material Unit",
    // //   dataIndex: "material_unit",
    // //   selectable: true,
    // //   references: {
    // //     fieldName: "name",
    // //   },
    // // },
    // // {
    // //   title: "Total Quantity",
    // //   dataIndex: "total_quantity",
    // //   selectable: true,
    // // },
    // // {
    // //   title: "Received Quantity",
    // //   dataIndex: "received_quantity",
    // //   selectable: true,
    // // },
    // // {
    // //   title: "Remaining Quantity",
    // //   dataIndex: "remaining_quantity",
    // //   selectable: true,
    // // },
    // // {
    // //   title: "Order Date",
    // //   dataIndex: "order_date",
    // //   selectable: true,
    // //   sorter: (a, b) =>
    // //     moment(a.order_date).unix() - moment(b.order_date).unix(),
    // //   render: (text) => <span>{moment(text).format("DD-MM-YYYY")}</span>,
    // //   width: 250,
    // // },
    // {
    //   title: "Materials Received Date",
    //   dataIndex: "received_date",
    //   selectable: true,
    //   sorter: (a, b) =>
    //     moment(a.received_date).unix() - moment(b.received_date).unix(),
    //   render: (text) => <span>{moment(text).format("DD-MM-YYYY")}</span>,
    //   width: 250,
    // },
    // {
    //   title: "Vendor name",
    //   dataIndex: "vendor",
    //   selectable: true,
    //   references: {
    //     fieldName: "name",
    //   },
    // },
    // {
    //   title: "Cost",
    //   dataIndex: "purchase_order",
    //   selectable: true,
    //   references: {
    //     fieldName: "grand_total_amount",
    //   },
    // },
  ],
  ...config,
};
