import React from "react";
import { Route } from "react-router-dom";
import {
  ProjectTypeList,
  ProjectTypeAdd,
  ProjectTypeEdit,
} from "../pages/ProjectTypes";

export const register = (props) => {
  const basePath = "project-types";

  return (
    <>
      <Route path={basePath} element={<ProjectTypeList />} exact={true} />
      <Route path={`${basePath}/add`} element={<ProjectTypeAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<ProjectTypeEdit />} />
    </>
  );
};
