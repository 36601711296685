import React, { useState, useEffect } from "react";
import { DataTable } from "../../../components";
import { useNavigate } from "react-router-dom";
import { Space, Button, Row, Col, Input, Tooltip, DatePicker } from "antd";
import qs from "qs";
import config from "./config";
import { axios } from "../../../../../App";
import _ from "lodash";
import { ReloadOutlined, DownloadOutlined } from "@ant-design/icons";
import moment from "moment";
import { ReportDataTable } from "../../../../projects/components";

const DATE_TIME_FORMAT = "YYYY-MM-DDTHH:mm:ss[Z]";

const PayrollReport = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    pageSize: 10,
  });
  const [total, setTotal] = useState(0);
  const [searchValues, setSearchValues] = useState({});

  const calcTotalEarnings = (data) => {
    let basicSalary = data?.basic_salary || 0;
    let hra = data?.hra || 0;
    let bonus = data?.bonus || 0;
    let conveyance = data?.conveyance || 0;
    let otherAllowances = data?.other_allowances || 0;

    return basicSalary + hra + bonus + conveyance + otherAllowances;
  };

  const calcTotalDeductions = (data) => {
    let tds = data?.tds || 0;
    let esi = data?.esi || 0;
    let advanced_loan = data?.advanced_loan || 0;
    let other_deductions = data?.other_deductions || 0;
    let pf = data?.pf || 0;

    return tds + esi + advanced_loan + other_deductions + pf;
  };

  const fetchData = async (params = null) => {
    try {
      setLoading(true);

      const queryObject = {
        populate: "*",
        sort: ["first_name:asc"],
        filters: { is_deleted: false }
      };

      if (!_.isEmpty(params)) {
        queryObject["filters"] = params;
      }

      const response = await axios.get(
        `${config?.slugs?.plural}?${qs.stringify(queryObject)}`
      );

      if (response?.data) {
        console.log('resposneee', response?.data)
        setData(
          response?.data?.data?.map((item) => ({
            id: item?.id,
            name: `${item?.attributes?.first_name} ${item?.attributes?.last_name}`,
            total_salary: calcTotalEarnings(item?.attributes),
            salary_payable: calcTotalEarnings(item?.attributes) - calcTotalDeductions(item?.attributes),
            ...item?.attributes,
          }))
        );

        setTotal(response?.data?.meta?.pagination?.total);

        setPagination({
          ...response?.data?.meta?.pagination,
          pageSize: 10,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const refreshData = () => {
    onSearch();
  };


  const getColumnFilterProps = ({ dataIndex, references = {} }) => {
    if (typeof references?.fieldName === "function") {
      const transformer = references?.fieldName;

      return {
        render: (data) => transformer(data?.data?.attributes) || "N/A",
      };
    } else {
      return {
        render: (data) =>
          data?.data?.attributes?.[references?.fieldName] || "N/A",
      };
    }
  };

  const getColumnProps = (column) => {
    if (column?.references) {
      return getColumnFilterProps(column);
    }

    return column;
  };

  const onSearch = () => {
    let filters = [
      {
        $or: [
          {
            first_name: {
              $containsi: searchValues?.keyword?.toLowerCase(),
            },
          }
        ],
      },

    ];

    if (searchValues?.start_date) {
      filters.push({
        createdAt: {
          $gte: searchValues.start_date.format("YYYY-MM-DD"),
        },
      });
    }

    if (searchValues?.end_date) {
      filters.push({
        createdAt: {
          $lte: searchValues.end_date.format("YYYY-MM-DD"),
        },
      });
    }

    fetchData({
      $and: filters,
    });
  };


  const onReset = () => {
    setSearchValues({});
    fetchData(null);
  };

  const getResultsMessage = () => {
    if (!_.isEmpty(searchValues)) {
      return total > 0 ? (
        <span>
          <strong>{total}</strong> matched results
        </span>
      ) : (
        <span>No matched results</span>
      );
    }

    return (
      <span>
        <strong>{total}</strong> total records
      </span>
    );
  };

  useEffect(() => {
    fetchData(filters);
  }, []);

  console.log('meeting data', data)

  return (
    <ReportDataTable
      data={data}
      config={{
        ...config,
        columns: config?.columns?.map((column) => ({
          ...column,
          ...getColumnProps(column),
        })),
      }}
      loading={loading}
      pagination={pagination}
      actions={{
        onAdd: () => navigate(`/quick-talks/${config?.slugs?.plural}/add`),
        onRefresh: refreshData,
      }}
      Toolbar={
        <div className="toolbar">
          <Row gutter={16}>
            <Col span={10}>
              <Input
                value={searchValues?.keyword}
                placeholder="Search for keyword..."
                onChange={(e) =>
                  setSearchValues((prev) => ({
                    ...prev,
                    keyword: e.target.value,
                  }))
                }
              />
            </Col>
            <Col span={6}>
              <DatePicker
                onChange={(value) =>
                  setSearchValues((prev) => ({
                    ...prev,
                    start_date: value,
                  }))
                }
                value={searchValues?.start_date}
                placeholder="Select start date"
                style={styles.datePicker}
              />
            </Col>

            <Col span={6}>
              <DatePicker
                onChange={(value) =>
                  setSearchValues((prev) => ({
                    ...prev,
                    end_date: value,
                  }))
                }
                value={searchValues?.endDate}
                placeholder="Select end date"
                style={styles.datePicker}
              />
            </Col>
          </Row>

          <div className="toolbar-buttons">
            <p className="toolbar-results">{getResultsMessage()}</p>

            <Space>
              <Button onClick={onReset}>Reset</Button>
              <Button type="primary" onClick={onSearch}>
                Search
              </Button>
            </Space>
          </div>
        </div>
      }
      buttons={[
        <Tooltip placement="top" title="Refresh">
          <Button
            icon={<ReloadOutlined />}
            onClick={refreshData}
            loading={loading}
          />
        </Tooltip>,
      ]}
      onBack={() => navigate(`/quick-talks/reports`)}
    />
  );
};

const styles = {
  datePicker: {
    width: "100%",
  },
};

export default PayrollReport;
