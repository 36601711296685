import { axios } from "../../../../../App";
import { v4 } from "uuid";
import config from "../config";

export const makeConfig = ({ navigate, auth }) => ({
  id: `${config?.slugs?.plural}-edit`,
  title: `Edit New ${config?.entityNames?.singular}`,
  pageTitle: `Edit New ${config?.entityNames?.singular}`,
  breadcrumbs: [
    {
      path: "/quick-stocks/dashboard",
      title: "Home",
    },
    {
      path: `/quick-stocks/${config?.slugs?.plural}`,
      title: config?.entityNames?.plural,
    },
    {
      path: "",
      title: `Edit New ${config?.entityNames?.singular}`,
    },
  ],
  components: [
    {
      span: 12,
      component: "input",
      label: "Client Name",
      name: "client_name",
      placeholder: "Client Name",
      required: true,
      rules: [
        {
          required: true,
          message: "HSN/SAC code is required!",
        },
      ],
    },
    {
      span: 12,
      component: "input",
      label: "GSTIN Number",
      name: "gstin_number",
      placeholder: "GSTIN Number",
      required: true,
      rules: [
        {
          required: true,
          message: "GSTIN code is required!",
        },
      ],
    },
    {
      span: 12,
      component: "date",
      label: "PO Date",
      name: "po_date",
      placeholder: "PO Date",
      // required: true,
      // rules: [
      //     {
      //         required: true,
      //         message: "PO Date is required!",
      //     },
      // ],
    },
    {
      span: 24,
      component: "input",
      label: "PO Address",
      name: "po_address",
      placeholder: "PO Address",
      required: true,
      //   rules: [
      //     {
      //       required: true,
      //       message: "PO Address is required!",
      //     },
      //   ],
    },
    {
      span: 24,
      component: "input",
      label: "Place of Supply",
      name: "place_of_supply",
      placeholder: "Place of Supply",
      required: true,
      rules: [
        {
          required: true,
          message: "Place of Supply is required!",
        },
      ],
    },
  ],
  actions: {
    onBack: () => navigate(`/quick-stocks/${config?.slugs?.plural}`),
    onFinish: async (values) => {
      try {
        const response = await axios.put(`/${config?.slugs?.plural}`, {
          data: {
            ...values,
            uid: v4(),
            // creator: auth?.user?.id,
          },
        });

        if (response?.data) {
          return {
            success: true,
            message: `Edit new ${config?.entityNames?.singular?.toLowerCase()} successfully!`,
          };
        }

        throw new Error();
      } catch (error) {
        return {
          success: false,
          message: `Edit new ${config?.entityNames?.singular?.toLowerCase()} failed!`,
        };
      }
    },
  },
});
