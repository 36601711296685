import React, { useState, useEffect } from "react";
import { ModalAddForm } from "../../../components";
import { useNavigate } from "react-router-dom";
import { makeConfig } from "./config";
import { useSelector } from "react-redux";
import { fetchData } from "../../../../../utils/helpers/data.helper";
import { Button, Col, Form, Row, Space } from "antd";
import FormElement from "../../../components/FormElement";
import { BASE_API_URL, BASE_APP_URL, STRAPI_URL } from "../../../../../constants";

const AddInvoice = (props) => {
  const navigate = useNavigate();
  const auth = useSelector((state) => state?.auth);
  const config = makeConfig({ navigate, auth });
  const [values, setValues] = useState({});
  const [selectedMaterialItem, setSelectedMaterialItem] = useState(null)
  const [materialImage, setmaterialImage] = useState(null)
  const contentTypes = [
    {
      slug: "gst-types",
      transformer: (item) => ({
        value: item?.rate,
        label: `${item?.name} (${item?.rate}%)`,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          gst: data,
        })),
    },
    {
      slug: "material-groups",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          material_group: data,
        })),
    },
    {
      slug: "material-items",
      query: {
        populate: "*",
      },
      // transformer: (item) => ({
      //   value: item?.id,
      //   label: item?.name,
      // }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          all_material_item: data,
        })),
    },
  ];

  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);

  const handleFinish = (values) => {
    setSubmitting(true);
    props?.onSubmit(values);
    setSubmitting(false);
  };

  // Function to calculate total based on quantity, rate, and gst
  const calculateTotal = (quantity, rate, gst) => {
    const gstMultiplier = 1 + gst / 100;
    return quantity * rate * gstMultiplier;
  };



  const handleValuesChange = (changedValues, allValues) => {
    const { quantity, rate, gst } = allValues;

    // Check if all necessary fields are available
    if (quantity && rate && gst) {
      const total = calculateTotal(quantity, rate, gst);
      form.setFieldsValue({ total });
    }
  };

  useEffect(() => {
    contentTypes?.forEach((contentType) => {
      fetchData(contentType);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const image = values?.material_item?.find((mat) => mat.value === selectedMaterialItem)

    if (image) {
      setmaterialImage(image?.material_image?.data?.attributes)
    }

  }, [selectedMaterialItem])


  return (
    <>
      {materialImage && (
        <div style={{ marginBottom: '20px' }}>
          <img src={`${STRAPI_URL}${materialImage?.url}`} alt="" style={{ width: '80px', height: '80px', objectFit: 'contain' }} />
        </div>
      )}

      <ModalAddForm
        config={config}
        values={values}
        onSubmit={props?.onSubmit}
        onCancel={props?.onCancel}
        onFieldChange={{
          material_group: (antForm) => {
            const materialGroupId = antForm.getFieldValue("material_group");

            antForm.setFieldValue("material_item", undefined);

            if (!materialGroupId) {
              setValues((prev) => ({
                ...prev,
                material_item: [],
              }));
            } else {
              setValues((prev) => ({
                ...prev,
                material_item: values?.all_material_item
                  ?.filter(
                    (item) => item?.material_group?.data?.id === materialGroupId
                  )
                  ?.map((item) => ({
                    value: item?.id,
                    label: item?.name,
                    ...item
                  })),
              }));
            }
          },

          material_item: (antForm) => {
            const materialItemId = antForm.getFieldValue("material_item");

            setSelectedMaterialItem(materialItemId)
          }
        }}
      />
    </>
    // <Form
    //   form={form}
    //   name={config?.id}
    //   layout="vertical"
    //   onFinish={handleFinish}
    //   onValuesChange={handleValuesChange}

    //   style={styles.form}
    //   scrollToFirstError
    // // disabled={disabled ? disabled : loading}
    // >
    //   <Row gutter={24}>
    //     {config?.components.map((component) => (
    //       <Col span={component?.span} key={component?.name}>
    //         <FormElement
    //           {...component}
    //           values={values}
    //           form={form}


    //         />
    //       </Col>
    //     ))}
    //   </Row>

    //   <Space style={styles.footer}>
    //     <Button onClick={props?.onCancel}>Cancel</Button>

    //     <Button type="primary" htmlType="submit" loading={submitting}>
    //       Save
    //     </Button>
    //   </Space>
    // </Form>
  );
};

const styles = {
  pageHeader: {
    padding: 0,
    paddingBottom: 24,
  },
  form: {
    width: "100%",
  },
  footer: {
    marginTop: 12,
  },
};

export default AddInvoice;
