export default {
  slugs: {
    singular: "meeting",
    plural: "meetings",
  },
  entityNames: {
    singular: "Meeting",
    plural: "Meetings",
  },
};
