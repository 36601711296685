import React from "react";
import { Column } from "@ant-design/plots";

const Chart = ({ data = [], xField, yField, seriesField }) => {
  const config = {
    data,
    isGroup: true,
    xField,
    yField,
    seriesField,
    label: {
      position: "middle",
      layout: [
        {
          type: "interval-adjust-position",
        },
        {
          type: "interval-hide-overlap",
        },
        {
          type: "adjust-color",
        },
      ],
    },
    legend: {
      layout: "horizontal",
      position: "bottom",
    },
    ...data,
  };

  return <Column {...config} />;
};

export default Chart;
