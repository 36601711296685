import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./redux";
import { BrowserRouter } from "react-router-dom";
import { Main } from "./Main";
import setupInterceptors from "./utils/axios/interceptors";

const App = (props) => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <Main />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
};

export const axios = setupInterceptors(store);

export default App;
