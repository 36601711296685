import config from "../config";

const commify = (x) => {
  return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  id: `${config?.slugs?.plural}-list`,
  pageTitle: `${config?.entityNames?.plural} Report`,
  code: "STOCK_LEDGERS",
  breadcrumbs: [
    {
      path: "/quick-stocks/dashboard",
      title: "Home",
    },
    {
      path: `/quick-stocks/reports`,
      title: "Reports",
    },
    {
      path: "",
      title: `${config?.entityNames?.plural} Report`,
    },
  ],
  columns: [
    {
      title: "Project",
      dataIndex: "project",
      selectable: true,
      references: {
        fieldName: "name",
      },
    },
    {
      title: "Material Item",
      dataIndex: "material_item",
      selectable: true,
      filterable: true,
      references: {
        fieldName: "name",
      },
    },
    // {
    //   title: "Material Unit",
    //   dataIndex: "material_unit",
    //   selectable: true,
    //   filterable: true,
    //   references: {
    //     fieldName: "name",
    //   },
    // },
    {
      title: "Quantity",
      dataIndex: "quantity",
      selectable: true,
      render: (text) => commify(text || 0),
      sorter: (a, b) => a?.quantity - b?.quantity,
    },
    {
      title: "Quantity Released",
      dataIndex: "released_quantity",
      selectable: true,
    },

    {
      title: "Released Date",
      dataIndex: "released_date",
      selectable: true,
    },

    {
      title: "Task Details",
      dataIndex: "task_material",
      selectable: true,
      filterable: true,
      references: {
        fieldName: "name",
      },
    },
  ],
  ...config,
};
