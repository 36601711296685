export default {
  slugs: {
    singular: "hand-tool-log",
    plural: "hand-tool-logs",
  },
  entityNames: {
    singular: "Hand Tool Log",
    plural: "Hand Tool Logs",
  },
};
