export default {
  slugs: {
    singular: "task-comment",
    plural: "task-comments",
  },
  entityNames: {
    singular: "Task Comment",
    plural: "Task Comments",
  },
};
