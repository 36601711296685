import config from "../config";

export default {
  id: `${config?.slugs?.plural}-list`,
  pageTitle: config?.entityNames?.plural,
  breadcrumbs: [
    {
      path: "/quick-stocks/dashboard",
      title: "Home",
    },
    {
      path: "",
      title: config?.entityNames?.plural,
    },
  ],
  columns: [
    {
      title: "Item",
      dataIndex: "item_name",
      selectable: true,
    },
    {
      title: "Unit",
      dataIndex: "unit",
      selectable: true,
    },
    {
      title: "Qty",
      dataIndex: "quantity",
      selectable: true,
    },
    {
      title: "Estimated Labout Cost",
      dataIndex: "estimated_labour_cost",
      selectable: true,
    },
    {
      title: "Estimated Material Cost",
      dataIndex: "estimated_material_cost",
      selectable: true,
    },
    {
      title: "Estimated Budget",
      dataIndex: "estimated_budget",
      selectable: true,
    },
  ],
  ...config,
};
