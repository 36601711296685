import React, { useEffect, useState } from "react";
import { AddForm } from "../../../components";
import { useNavigate, useParams } from "react-router-dom";
import { makeConfig } from "./config";
import { axios } from "../../../../../App";
import qs from "qs";
import { fetchData } from "../../../../../utils/helpers/data.helper";
import { v4 } from "uuid";

const Page = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const config = makeConfig({ navigate });
  const [values, setValues] = useState({});
  const [coordinates, setCoordinates] = useState([]);
  const contentTypes = [
    {
      slug: "location-types",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          location_type: data,
        })),
    },
    {
      slug: "states",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          state: data,
        })),
    },
    {
      slug: "cities",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          city: data,
        })),
    },
  ];

  // CO-ORDINATES

  const onAddCoordinate = (data) => {
    const newData = {
      id: v4(),
      ...data,
    };

    setCoordinates((prev) => prev?.concat(newData));
  };

  const onEditCoordinate = (id, data) => {
    let newItems = coordinates?.map((record) => {
      if (record?.id === id) {
        record = {
          ...data,
        };
      }

      return record;
    });

    setCoordinates(newItems);
  };

  const onDeleteCoordinate = (id) => {
    setCoordinates((prev) => prev?.filter((item) => item?.id !== id));
  };

  useEffect(() => {
    contentTypes?.forEach((contentType) => {
      fetchData(contentType);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const queryObject = {
          populate: "*",
        };

        const response = await axios.get(
          `/${config?.slugs?.plural}/${id}?${qs.stringify(queryObject)}`
        );

        if (response?.data) {
          setCoordinates(response?.data?.data?.attributes?.coordinates || []);

          setData({
            ...response?.data?.data?.attributes,
            id: response?.data?.data?.id,
            location_type:
              response?.data?.data?.attributes?.location_type?.data?.id,
            state: response?.data?.data?.attributes?.state?.data?.id,
            city: response?.data?.data?.attributes?.city?.data?.id,
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <AddForm
      config={config}
      data={{
        ...data,
        coordinates,
      }}
      loading={loading}
      values={values}
      modals={{
        coordinates: {
          data: coordinates,
          methods: {
            add: onAddCoordinate,
            edit: onEditCoordinate,
            delete: onDeleteCoordinate,
          },
        },
      }}
    />
  );
};

export default Page;
