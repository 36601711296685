/* eslint-disable import/no-anonymous-default-export */
import config from "../config";
import moment from "moment";
import { Tag } from "antd";

export default {
  id: `${config?.slugs?.plural}-list`,
  pageTitle: config?.entityNames?.plural,
  breadcrumbs: [
    {
      path: "/quick-projects/dashboard",
      title: "Home",
    },
    {
      path: "",
      title: config?.entityNames?.plural,
    },
  ],
  columns: [
    {
      title: "No",
      dataIndex: "no",
      selectable: true,
      sorter: (a, b) => a?.no?.localeCompare(b?.no),
      width: 150,
    },
    {
      title: "Name",
      dataIndex: "name",
      selectable: true,
      sorter: (a, b) => a?.name?.localeCompare(b?.name),
      width: 150,
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      selectable: true,
      sorter: (a, b) =>
        moment(a.start_date).unix() - moment(b.start_date).unix(),
      render: (text) => <span>{moment(text).format("DD-MM-YYYY")}</span>,
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      selectable: true,
      sorter: (a, b) => moment(a.end_date).unix() - moment(b.end_date).unix(),
      render: (text) => <span>{moment(text).format("DD-MM-YYYY")}</span>,
    },
    {
      title: "Status",
      dataIndex: "status",
      selectable: true,
      render: (text) => <Tag>{text}</Tag>,
    },
  ],
  ...config,
};
