/* eslint-disable import/no-anonymous-default-export */
import config from "../config";
import moment from "moment";
import { Tag } from "antd";

const getPriorityColor = (priority) => {
  switch (priority) {
    case "High":
      return "#f50";

    case "Medium":
      return "#faad14";

    default:
      return "default";
  }
};

export default {
  id: `${config?.slugs?.plural}-list`,
  pageTitle: `${config?.entityNames?.plural} Report`,
  code: "REVISED_SHIFTS",
  breadcrumbs: [
    {
      path: "/quick-teams/dashboard",
      title: "Home",
    },
    {
      path: `/quick-teams/reports`,
      title: "Reports",
    },
    {
      path: "",
      title: `${config?.entityNames?.plural} Report`,
    },
  ],
  columns: [
    {
      title: "Subject",
      dataIndex: "subject",
      selectable: true,
    },
    {
      title: "Sender",
      dataIndex: "sender",
      selectable: true,
      filterable: true,
      references: {
        fieldName: (record) => `${record?.first_name} ${record?.last_name}`,
      },
    },
    {
      title: "Date",
      dataIndex: "date",
      selectable: true,
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      render: (text) => <span>{moment(text).format("DD-MM-YYYY")}</span>,
      width: 250,
    },
    {
      title: "Priority",
      dataIndex: "priority",
      selectable: true,
      render: (text) => (
        <Tag color={getPriorityColor(text)} style={styles.priority}>
          {text}
        </Tag>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      selectable: true,
      render: (text) => <Tag>{text}</Tag>,
    },
  ],
  ...config,
};

const styles = {
  priority: {
    fontWeight: "bold",
    textTransform: "uppercase",
  },
};
