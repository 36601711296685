import React from "react";
import { Route, Navigate } from "react-router-dom";
import NotFound from "../../../pages/NotFound";
import QuickSetup from "../index";
import HomePage from "../pages";

import * as PaymentModes from "./payment-modes.route";
import * as Banks from "./banks.route";
import * as LocationTypes from "./location-types.route";
import * as Departments from "./departments.route";
import * as EmployeeGroups from "./employee-groups.route";
import * as Documents from "./documents.route";
import * as Designations from "./designations.route";
import * as Locations from "./locations.route";
import * as FirmTypes from "./firm-types.route";
import * as Initials from "./initials.route";
import * as Holidays from "./holidays.route";
import * as JobTitles from "./job-titles.route";
import * as Partners from "./partners.route";
import * as Companies from "./companies.route";
import * as Employees from "./employees.route";
import * as Users from "./users.route";
import * as UserTypes from "./user-types.route";
import * as Roles from "./roles.route";
import * as EmployeeDocuments from "./employee-documents.route";
import * as EmployeeQualifications from "./employee-qualifications.route";
import * as Letterheads from "./letterheads.route";
// import * as InventorySettings from "./inventory-settings.route";

export const register = (props) => {
  const basePath = "/quick-setup";

  return (
    <Route path={basePath} element={<QuickSetup />}>
      {PaymentModes.register()}
      {Banks.register()}
      {LocationTypes.register()}
      {Departments.register()}
      {EmployeeGroups.register()}
      {Documents.register()}
      {Designations.register()}
      {Locations.register()}
      {FirmTypes.register()}
      {Initials.register()}
      {Holidays.register()}
      {JobTitles.register()}
      {Partners.register()}
      {Companies.register()}
      {Employees.register()}
      {Users.register()}
      {UserTypes.register()}
      {Roles.register()}
      {EmployeeDocuments.register()}
      {EmployeeQualifications.register()}
      {Letterheads.register()}
      {/* {InventorySettings.register()} */}

      <Route
        path={basePath}
        // element={<Navigate to={`${basePath}/companies`} replace />}
        element={<HomePage />}
      />
      <Route path="*" element={<NotFound returnPath={basePath} />} />
    </Route>
  );
};
