export default {
  slugs: {
    singular: "location-check-in",
    plural: "location-check-ins",
  },
  entityNames: {
    singular: "Location Check-in",
    plural: "Location Check-in",
  },
};
