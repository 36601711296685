export default {
  slugs: {
    singular: "notification",
    plural: "notifications",
  },
  entityNames: {
    singular: "Notification",
    plural: "Notifications",
  },
};
