export default {
  slugs: {
    singular: "advanced-loan",
    plural: "advanced-loans",
  },
  entityNames: {
    singular: "Advanced Loan",
    plural: "Advanced Loans",
  },
};
