export default {
  slugs: {
    singular: "project-resource",
    plural: "project-resources",
  },
  entityNames: {
    singular: "Project Resource",
    plural: "Project Resources",
  },
};
