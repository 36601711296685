import React from "react";
import { Route } from "react-router-dom";
import {
  AgencyCreditNoteList,
  AgencyCreditNoteAdd,
  AgencyCreditNoteReport,
  AgencyCreditNoteCreatorEdit,
  AgencyCreditNoteApproverEdit,
  AgencyCreditNoteView,
} from "../pages/AgencyCreditNotes";

export const register = (props) => {
  const basePath = "agency-credit-notes";

  return (
    <>
      <Route path={basePath} element={<AgencyCreditNoteList />} exact={true} />
      <Route path={`${basePath}/add`} element={<AgencyCreditNoteAdd />} />
      <Route
        path={`${basePath}/creator/edit/:id`}
        element={<AgencyCreditNoteCreatorEdit />}
      />
      <Route
        path={`${basePath}/approver/edit/:id`}
        element={<AgencyCreditNoteApproverEdit />}
      />
      <Route path={`${basePath}/view/:id`} element={<AgencyCreditNoteView />} />
      <Route path={`${basePath}-report`} element={<AgencyCreditNoteReport />} />
    </>
  );
};
