import React from "react";
import { Route } from "react-router-dom";
import {
  TaskWorkList,
  TaskWorkAdd,
  TaskWorkCreatorEdit,
  TaskWorkApproverEdit,
  TaskWorkView,
  TaskWorkReport,
} from "../pages/TaskWorks";

export const register = (props) => {
  const basePath = "task-works";

  return (
    <>
      <Route path={basePath} element={<TaskWorkList />} exact={true} />
      <Route path={`${basePath}/add`} element={<TaskWorkAdd />} />
      <Route
        path={`${basePath}/creator/edit/:id`}
        element={<TaskWorkCreatorEdit />}
      />
      <Route
        path={`${basePath}/approver/edit/:id`}
        element={<TaskWorkApproverEdit />}
      />
      <Route path={`${basePath}/view/:id`} element={<TaskWorkView />} />
      <Route path={`${basePath}-report`} element={<TaskWorkReport />} />
    </>
  );
};
