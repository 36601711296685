import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import moment from "moment";
import {
  Empty,
  Input,
  Row,
  Col,
  Button,
  PageHeader,
  Breadcrumb,
  Spin,
  Select,
  Modal,
} from "antd";
import { axios } from "../../../../../App";
import { Link, useNavigate, useParams } from "react-router-dom";
import { makeConfig } from "./config";
import qs from "qs";

const { TextArea } = Input;
const { Option } = Select;

const Page = ({
  inputId = null,
  showsCancel = true,
  showsHeader = true,
  mode = "normal",
}) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const config = makeConfig({ navigate });
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [workItemsRemarks, setWorkItemsRemarks] = useState("");
  const [termsRemarks, setTermsRemarks] = useState("");
  const [paymentSchedulesRemarks, setPaymentSchedulesRemarks] = useState("");
  const [letterheadFileName, setLetterheadFileName] = useState(null);
  const [showsModal, setShowsModal] = useState(false);
  const [letterheads, setLetterheads] = useState([]);

  const componentRef = useRef();


  const fetchData = async (quotationId) => {
    try {
      setLoading(true);

      const queryObject = {
        populate: "*",
      };

      const response = await axios.get(
        `/sub-contractor-quotations/${quotationId}?${qs.stringify(queryObject)}`
      );

      if (response?.data) {
        const dataObject = response?.data?.data?.attributes;

        setData(dataObject);
        setWorkItemsRemarks(dataObject?.work_items_remarks);
        setTermsRemarks(dataObject?.terms_remarks);
        setPaymentSchedulesRemarks(dataObject?.payment_schedules_remarks);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchLetterheads = async () => {
    try {
      const resp = await axios.get(`/letterheads?populate=*`);

      if (resp?.data?.data?.length > 0) {
        const results = resp?.data?.data?.map((record, index) => {
          let fileName =
            record?.attributes?.image_file?.data?.attributes?.url?.split(
              "/"
            )[2] || "";

          return {
            id: record?.id,
            name: record?.attributes?.name,
            file_name: fileName,
          };
        });

        setLetterheads(results);
      }
    } catch (error) { }
  };


  const getDeliveryAddress = (addressObject) => {
    return `${addressObject?.name} (${addressObject?.address_line_1}, ${addressObject?.address_line_2})`;
  };

  useEffect(() => {
    if (mode === "normal") {
      fetchData(id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (mode !== "normal" && inputId) {
      fetchData(inputId);
    }
    fetchLetterheads()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputId]);

  const generatePdf = () => {
    setTimeout(() => {
      var element = componentRef?.current?.innerHTML;

      var opt = {
        margin: [0.03, 0, 0.3, 0],
        filename: "invoices_" + ".pdf",
        image: { type: "jpeg", quality: 0.5 },
        pagebreak: { avoid: "tr", mode: "css", before: "#nextpage1" },
        html2canvas: {
          scale: 5,
          useCORS: true,
        },
        jsPDF: { unit: "in", format: "a3", orientation: "portrait" },
        compressImage: false,
      };

      import("html2pdf.js").then((html2pdf) => {
        /* html2pdf().set(opt).from(element).save(); */
        html2pdf.default().set(opt).from(element).save();
      });
    }, 10);
  }

  const onSubmit = () => {
    setShowsModal(false);
    generatePdf();

  };

  if (loading) {
    return (
      <div style={styles.container}>
        <div style={styles.loadingContainer}>
          <Spin />
        </div>
      </div>
    );
  }

  console.log('vndsfjklvbsdf', data)

  return (
    <div style={styles.container}>
      <Helmet>
        <title>Sub Contractor Quotation</title>
      </Helmet>

      {showsHeader && (
        <>
          <Breadcrumb style={styles.breadcrumbs}>
            {config?.breadcrumbs?.map((breadcrumb, index) => (
              <Breadcrumb.Item key={index}>
                <Link to={breadcrumb?.path}>{breadcrumb?.title}</Link>
              </Breadcrumb.Item>
            ))}
          </Breadcrumb>

          <PageHeader
            title={config?.title}
            style={styles.pageHeader}
            onBack={config?.actions?.onBack}
          ></PageHeader>
        </>
      )}

      <div style={styles.formContainer} ref={componentRef}>
        <div className="public-form-header">
          <table
            cellPadding={0}
            cellSpacing={0}
            style={{
              width: "100%",
              border: "0px solid #ddd",
              marginBottom: "10px",
            }}
          >
            <tr style={{ borderBottom: "1px solid #eee" }}>
              <td
                className="text-center"
                style={{
                  background:
                    "url(/images/form-header-left.jpeg) no-repeat top left",
                  backgroundSize: "80%",
                  width: "30%",
                }}
              >
                <img
                  title="QuickSO"
                  src="/images/form-header-logo.png"
                  alt="QuickSO"
                  style={{
                    height: "75px",
                    width: "auto",
                    objectFit: "contain",
                    margin: "0 auto",
                  }}
                />
              </td>

              <td
                style={{
                  background:
                    "url(/images/form-header-right.jpeg) no-repeat top left",
                  backgroundSize: "100%",
                }}
              >
                <table
                  cellPadding={0}
                  cellSpacing={0}
                  style={{
                    width: "100%",
                    border: "0px solid #ddd",
                    marginBottom: "0px",
                  }}
                >
                  <tbody>
                    <tr>
                      <td
                        colSpan={2}
                        style={{ borderBottom: "2px dashed #ddd" }}
                      >
                        <h1
                          className="text-left"
                          style={{
                            color: "#ffffff",
                            fontSize: "30px",
                            padding: "20px 20px",
                            textTransform: "uppercase",
                            margin: 0,
                          }}
                        >
                          SUB CONTRACTOR QUOTATION
                        </h1>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <h2
                          className="text-left"
                          style={{
                            color: "#ffffff",
                            fontSize: "16px",
                            padding: "10px 20px",
                            fontWeight: 300,
                          }}
                        >
                          Serial Number
                          <br />
                          <b>
                            PAQ-
                            {
                              data?.sc_requirement_form?.data?.attributes?.uid?.split(
                                "-"
                              )?.[0]
                            }
                            -
                            {
                              data?.sub_contractor?.data?.attributes?.uid?.split(
                                "-"
                              )?.[0]
                            }
                          </b>
                        </h2>
                      </td>

                      <td>
                        <h2
                          className="text-left"
                          style={{
                            color: "#ffffff",
                            fontSize: "16px",
                            padding: "10px 20px",
                            fontWeight: 300,
                          }}
                        >
                          Date
                          <br />
                          <b>
                            {moment(data?.createdAt).format(
                              "DD-MM-YYYY, HH:mm"
                            )}
                          </b>
                        </h2>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </table>
        </div>

        <div className="public-form-body">
          <div className="public-form-section">
            <h2 className="public-form-section-title">General Information</h2>

            <table className="public-form-general-info">
              <tr>
                <td>Sub Contractor</td>
                <td>{data?.sub_contractor?.data?.attributes?.name}</td>
              </tr>

              <tr>
                <td>Project</td>
                <td>{data?.project?.data?.attributes?.name}</td>
              </tr>

              <tr>
                <td>Delivery Address</td>
                <td>
                  {getDeliveryAddress(
                    data?.delivery_address?.data?.attributes
                  ) || "N/A"}
                </td>
              </tr>

              <tr>
                <td>Requirement Form</td>
                <td>{data?.heading}</td>
              </tr>

              <tr>
                <td>Urgency Level</td>
                <td>{data?.urgency_level}</td>
              </tr>

              <tr>
                <td>Expected Delivery Date</td>
                <td>
                  {data?.expected_delivery_date
                    ? moment(data?.expected_delivery_date)?.format("DD-MM-YYYY")
                    : "N/A"}
                </td>
              </tr>

              <tr>
                <td>Delivery Date</td>
                <td>
                  {data?.delivery_date
                    ? moment(data?.delivery_date)?.format("DD-MM-YYYY")
                    : "N/A"}
                </td>
              </tr>

              <tr>
                <td>Work Specifications</td>
                <td>
                  <span
                    dangerouslySetInnerHTML={{
                      __html:
                        data?.sc_requirement_form?.data?.attributes?.remarks,
                    }}
                  />
                </td>
              </tr>
            </table>
          </div>

          {data?.work_items?.length > 0 && (
            <div className="public-form-section">
              <h2 className="public-form-section-title">Work Items</h2>

              <table className="public-form-table">
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Work Group</th>
                    <th>Work Item</th>
                    <th>Quantity</th>
                    <th style={{ width: "10%" }}>Labour Cost(₹)</th>
                    <th style={{ width: "10%" }}>Material Cost(₹)</th>
                    <th style={{ width: "10%" }}>Amount(₹)</th>
                    <th style={{ width: "10%" }}>CGST (%)</th>
                    <th style={{ width: "10%" }}>SGST (%)</th>
                    <th style={{ width: "10%" }}>IGST (%)</th>
                    <th style={{ width: "10%" }}>Taxable Amount(₹)</th>
                    <th style={{ width: "10%" }}>Total(₹)</th>
                  </tr>
                </thead>

                <tbody>
                  {data?.work_items?.length > 0 &&
                    data?.work_items?.map((workItem, index) => {
                      const taxableAmount =
                        workItem?.quantity * data?.work_item_notes?.[index]?.amount || 0;
                      const cqst = (data?.work_item_notes?.[index]?.cqst || 0) / 100;
                      const sqst = (data?.work_item_notes?.[index]?.sqst || 0) / 100;
                      const igst = (data?.work_item_notes?.[index]?.igst || 0) / 100;


                      // Calculate the total with taxes
                      const totalWithTax =
                        taxableAmount +
                        taxableAmount * cqst +
                        taxableAmount * sqst +
                        taxableAmount * igst

                      return (
                        <tr key={workItem?.id}>
                          <td className="text-center text-bold">{index + 1}</td>
                          <td>{workItem?.work_group || "N/A"}</td>
                          <td>{workItem?.work_item || "N/A"}</td>
                          {/* <td>{workItem?.work_unit || "N/A"}</td> */}
                          <td>{workItem?.quantity || 0}</td>
                          <td style={{ fontWeight: "bold" }}>
                            {data?.work_item_notes?.[index]?.estimated_labour_cost || 0}
                          </td>
                          <td style={{ fontWeight: "bold" }}>
                            {data?.work_item_notes?.[index]?.estimated_material_cost || 0}
                          </td>
                          <td style={{ fontWeight: "bold" }}>
                            {data?.work_item_notes?.[index]?.amount || 0}
                          </td>
                          <td style={{ fontWeight: "bold" }}>
                            {data?.work_item_notes?.[index]?.cqst || 0}
                          </td>
                          <td style={{ fontWeight: "bold" }}>
                            {data?.work_item_notes?.[index]?.sqst || 0}
                          </td>
                          <td style={{ fontWeight: "bold" }}>
                            {data?.work_item_notes?.[index]?.igst || 0}
                          </td>
                          <td>{taxableAmount}</td>
                          <td style={{ fontWeight: "bold" }}>
                            {totalWithTax}
                          </td>
                        </tr>
                      )
                    })}

                  <tr>
                    <td
                      colSpan={11}
                      style={{
                        background: "#fbfbfb",
                        textAlign: "right",
                        fontWeight: "bold",
                      }}
                    >
                      Grand Total Amount (₹)
                    </td>

                    <td style={{ background: "#fbfbfb", fontWeight: "bold" }}>
                      {data?.grand_total_amount || 0}
                    </td>
                  </tr>

                  {(!data?.work_items || data?.work_items?.length === 0) && (
                    <tr>
                      <td colSpan={8} style={{ padding: 24 }}>
                        <Empty />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>

              <label style={styles.label}>Remarks</label>
              <TextArea
                placeholder="N/A"
                value={workItemsRemarks}
                style={{ marginBottom: 24 }}
                disabled={true}
              />
            </div>
          )}

          {data?.terms?.length > 0 && (
            <div className="public-form-section">
              <h2 className="public-form-section-title">Terms & Conditions</h2>

              <table className="public-form-table">
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Heading</th>
                    <th>Work Group</th>
                    <th>Work Item</th>
                    {/* <th>Work Unit</th> */}
                    {/* <th style={{ textAlign: "left", width: "30%" }}>
                      Description
                    </th> */}
                  </tr>
                </thead>

                <tbody>
                  {data?.terms?.length > 0 &&
                    data?.terms?.map((term, index) => (
                      <tr key={term?.id}>
                        <td className="text-center text-bold">{index + 1}</td>
                        <td>{term?.heading || "N/A"}</td>
                        <td>{term?.work_group || "N/A"}</td>
                        <td>{term?.work_item || "N/A"}</td>
                        {/* <td>{term?.work_unit || "N/A"}</td> */}
                        {/* <td style={{ textAlign: "left" }}>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: term.description || "N/A",
                            }}
                          ></span>
                        </td> */}
                      </tr>
                    ))}

                  {(!data?.terms || data?.terms?.length === 0) && (
                    <tr>
                      <td colSpan={6} style={{ padding: 24 }}>
                        <Empty />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>

              <label style={styles.label}>Remarks</label>
              <TextArea
                placeholder="N/A"
                value={termsRemarks}
                disabled={true}
                style={{ marginBottom: 24 }}
              />
            </div>
          )}

          {data?.payment_schedules?.length > 0 && (
            <div className="public-form-section">
              <h2 className="public-form-section-title">Payment Schedules</h2>

              <table className="public-form-table">
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Date</th>
                    <th style={{ width: "50%", textAlign: "left" }}>
                      Amount (₹)
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {data?.payment_schedules?.length > 0 &&
                    data?.payment_schedules?.map((paymentSchedule, index) => (
                      <tr key={paymentSchedule?.id}>
                        <td className="text-center text-bold">{index + 1}</td>
                        <td>{paymentSchedule?.scheduled_date}</td>
                        <td style={{ textAlign: "left" }}>
                          {paymentSchedule?.amount}
                        </td>
                      </tr>
                    ))}

                  {(!data?.payment_schedules ||
                    data?.payment_schedules?.length === 0) && (
                      <tr>
                        <td colSpan={6} style={{ padding: 24 }}>
                          <Empty />
                        </td>
                      </tr>
                    )}
                </tbody>
              </table>

              <label style={styles.label}>Remarks</label>
              <TextArea
                placeholder="N/A"
                value={paymentSchedulesRemarks}
                disabled={true}
              />
            </div>
          )}

          <div className="public-form-section" style={{ marginTop: 16 }}>
            <h2 className="public-form-section-title">Approval</h2>

            <label style={styles.label}>Remarks</label>
            <TextArea
              placeholder="N/A"
              value={data?.approval_remarks}
              disabled
            />

            <label style={{ ...styles.label, marginTop: 12 }}>
              Verified By
            </label>
            <Input
              value={
                data?.approved_by?.data
                  ? `${data?.approved_by?.data?.attributes?.first_name} ${data?.approved_by?.data?.attributes?.last_name}`
                  : "N/A"
              }
              disabled
            />
          </div>

          <div
            className="public-form-section no-print"  // Add a specific class to target with CSS
            style={{ paddingTop: 24, display: 'flex', alignItems: 'center', gap: '10px' }}
          >
            {showsCancel && (
              <div>
                <Button onClick={config?.actions?.onBack}>Cancel</Button>
              </div>
            )}
          </div>

          <div className="public-form-section">
            <table style={{ width: "100%" }}>
              <tfoot>
                <tr>
                  <td colSpan={2}>
                    <div
                      style={{
                        background:
                          "url(/images/form-line.png) no-repeat center center",
                        backgroundAttachment: "cover",
                        backgroundSize: "100%",
                        height: 42,
                        marginTop: 24,
                        marginBottom: 10,
                      }}
                    />
                  </td>
                </tr>

                <tr className="text-center" style={{ fontSize: "14px" }}>
                  <td className="text-left" style={{ padding: "5px 0px" }}>
                    <img
                      title="QuickSO"
                      src="/images/form-logo.png"
                      alt="QuickSO"
                      style={{
                        height: "50px",
                        width: "auto",
                      }}
                    />
                  </td>
                  <td
                    className="text-right"
                    style={{ padding: "5px 0px", fontSize: "16px" }}
                  >
                    Copyright © 2022, All Rights Reserved by QuickSO
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>

      <Button type="primary" onClick={() => setShowsModal(true)} style={{ marginTop: 24 }}>
        Download as PDF
      </Button>

      <Modal
        title="Print PDF"
        visible={showsModal}
        onOk={onSubmit}
        onCancel={() => setShowsModal(false)}
        destroyOnClose
      >
        <label style={{ ...styles.label, marginTop: 0 }}>Letterhead</label>
        <Select
          value={letterheadFileName}
          onChange={setLetterheadFileName}
          style={styles.select}
        >
          {letterheads?.map((letterhead, index) => (
            <Option key={index} value={letterhead?.file_name}>
              {letterhead?.name}
            </Option>
          ))}
        </Select>

        <label style={styles.label}>Preview</label>
        <img
          src={`${process.env.REACT_APP_BASE_API_URL}/uploads/${letterheadFileName}`}
          style={styles.previewedImage}
          alt="Preview"
        />
      </Modal>
    </div>
  );
};

const styles = {
  container: {
    background: "white",
  },
  datePicker: {
    width: "100%",
  },
  pageHeader: {
    padding: 0,
    paddingBottom: 24,
  },
  loadingContainer: {
    textAlign: "center",
    padding: 24,
  },
  label: {
    fontWeight: "bold",
    display: "block",
    marginBottom: 6,
  },
  formContainer: {
    border: "2px solid #eee",
  },
};

export default Page;
