import React from "react";
import { Route } from "react-router-dom";
import { BankList, BankAdd, BankEdit } from "../pages/Banks";

export const register = (props) => {
  const basePath = "banks";

  return (
    <>
      <Route path={basePath} element={<BankList />} exact={true} />
      <Route path={`${basePath}/add`} element={<BankAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<BankEdit />} />
    </>
  );
};
