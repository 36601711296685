export default {
  slugs: {
    singular: "vendor-material-receivable",
    plural: "vendor-material-receivables",
  },
  entityNames: {
    singular: "Vendor Material Receivable",
    plural: "Vendor Material Receivables",
  },
};
