import { axios } from "../../../../../App";
import { v4 } from "uuid";
import config from "../config";
import { Modal, notification } from "antd";

const MIN_QUANTITY = 1;
const MIN_AMOUNT = 1;

export const makeConfig = ({ navigate, auth }) => ({
  id: `${config?.slugs?.plural}-add`,
  title: `Add New ${config?.entityNames?.singular}`,
  pageTitle: `Add New ${config?.entityNames?.singular}`,
  breadcrumbs: [
    {
      path: "/quick-projects/dashboard",
      title: "Home",
    },
    {
      path: `/quick-projects/${config?.slugs?.plural}`,
      title: config?.entityNames?.plural,
    },
    {
      path: "",
      title: `Add New ${config?.entityNames?.singular}`,
    },
  ],
  components: [
    {
      span: 12,
      component: "select",
      label: "Work Group",
      name: "work_group",
      placeholder: "Select work group",
      required: true,
      rules: [
        {
          required: true,
          message: "Work group is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Work Item",
      name: "work_item",
      placeholder: "Select work item",
      required: true,
      rules: [
        {
          required: true,
          message: "Work item is required!",
        },
      ],
    },
    // {
    //   span: 12,
    //   component: "select",
    //   label: "Work Unit",
    //   name: "work_unit",
    //   placeholder: "Select work unit",
    //   required: true,
    //   rules: [
    //     {
    //       required: true,
    //       message: "Work unit is required!",
    //     },
    //   ],
    // },
    {
      span: 12,
      component: "number",
      label: "Quantity",
      name: "quantity",
      required: true,
      placeholder: "Enter quantity",
      rules: [
        {
          required: true,
          message: "Quantity is required!",
        },
        {
          validator(_, value) {
            if (!value || value < MIN_QUANTITY) {
              return Promise.reject(
                `Quantity must be greater than ${MIN_QUANTITY}`
              );
            }

            return Promise.resolve();
          },
        },
      ],
    },
    // {
    //   span: 12,
    //   component: "number",
    //   label: "Amount",
    //   name: "amount",
    //   required: true,
    //   placeholder: "Enter amount",
    //   rules: [
    //     {
    //       required: true,
    //       message: "Amount is required!",
    //     },
    //     {
    //       validator(_, value) {
    //         if (!value || value < MIN_AMOUNT) {
    //           return Promise.reject(
    //             `Amount must be greater than ${MIN_AMOUNT}`
    //           );
    //         }

    //         return Promise.resolve();
    //       },
    //     },
    //   ],
    // },
  ],
  actions: {
    onBack: () => navigate(`/quick-projects/${config?.slugs?.plural}`),
    onFinish: async (values) => {
      try {
        // const checkResponse = await axios.get(
        //   `/stock-ledgers/${values?.material_item}`
        // );

        // const onMakeRequest = async () => {
        //   try {
        //     const response = await axios.post(`/${config?.slugs?.plural}`, {
        //       data: {
        //         ...values,
        //         status: "Requested",
        //         uid: v4(),
        //         // creator: auth?.user?.id,
        //       },
        //     });

        //     if (response?.data) {
        //       notification["success"]({
        //         message: "Task Material Request",
        //         description: "Your request have been created!",
        //       });
        //     } else {
        //       throw new Error();
        //     }
        //   } catch (error) {
        //     notification["error"]({
        //       message: "Task Material Request",
        //       description: "Failed to create request for this material!",
        //     });
        //   }
        // };

        // if (checkResponse?.data) {
        //   const stockAmount = checkResponse?.data?.data?.attributes?.quantity;

        //   if (values?.amount > stockAmount) {
        //     Modal.confirm({
        //       title: `Add New ${config?.entityNames?.plural}`,
        //       content: `There are not enough items in stock (Items left: ${stockAmount}). Do you want to make a request for it?`,
        //       onOk() {
        //         onMakeRequest();
        //         navigate(`/quick-projects/${config?.slugs?.plural}`);
        //       },
        //       onCancel() {},
        //     });
        //   } else {
        //     const response = await axios.post(`/${config?.slugs?.plural}`, {
        //       data: {
        //         ...values,
        //         uid: v4(),
        //         // creator: auth?.user?.id,
        //       },
        //     });

        //     if (response?.data) {
        //       await axios.put(`/stock-ledgers/${values?.material_item}`, {
        //         data: {
        //           quantity: stockAmount - values?.amount,
        //         },
        //       });

        //       return {
        //         success: true,
        //         message: `Add new ${config?.entityNames?.singular?.toLowerCase()} successfully!`,
        //       };
        //     } else {
        //       throw new Error();
        //     }
        //   }
        // } else {
        //   throw new Error();
        // }

        const response = await axios.post(`/${config?.slugs?.plural}`, {
          data: {
            ...values,
            uid: v4(),
            // creator: auth?.user?.id,
          },
        });

        if (response?.data) {
          return {
            success: true,
            message: `Add new ${config?.entityNames?.singular?.toLowerCase()} successfully!`,
          };
        }

        throw new Error();
      } catch (error) {
        return {
          success: false,
          message: `Add new ${config?.entityNames?.singular?.toLowerCase()} failed!`,
        };
      }
    },
  },
});
