import moment from "moment";

const DATE_TIME_FORMAT = "YYYY-MM-DDTHH:mm:ss[Z]";

const commify = (x) => {
  return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const config = {
  slugs: {
    singular: "task-work",
    plural: "task-works",
  },
  entityNames: {
    singular: "Task Work",
    plural: "Task Works",
  },
};

export default {
  id: `${config?.slugs?.plural}-list`,
  pageTitle: config?.entityNames?.plural,
  breadcrumbs: [
    {
      path: "/quick-stocks/dashboard",
      title: "Home",
    },
    {
      path: "",
      title: config?.entityNames?.plural,
    },
  ],
  columns: [
    {
      title: "Task",
      dataIndex: "task",
      selectable: true,
      references: {
        fieldName: "name",
      },
    },
    // {
    //   title: "Material Group",
    //   dataIndex: "material_group",
    //   selectable: true,
    //   references: {
    //     fieldName: "name",
    //   },
    // },
    {
      title: "Work Item",
      dataIndex: "work_item",
      selectable: true,
      references: {
        fieldName: "name",
      },
    },
    // {
    //   title: "Work Unit",
    //   dataIndex: "work_unit",
    //   selectable: true,
    //   references: {
    //     fieldName: "name",
    //   },
    // },
    {
      title: "Quantity",
      dataIndex: "quantity",
      selectable: true,
      render: (text) => commify(text || 0),
      sorter: (a, b) => a?.quantity - b?.quantity,
    },
    // {
    //   title: "Amount",
    //   dataIndex: "amount",
    //   selectable: true,
    //   render: (text) => commify(text || 0),
    //   sorter: (a, b) => a?.amount - b?.amount,
    // },
    {
      title: "Required Date",
      dataIndex: "createdAt",
      selectable: true,
      sorter: (a, b) =>
        moment(a.createdAt, DATE_TIME_FORMAT).unix() -
        moment(b.createdAt, DATE_TIME_FORMAT).unix(),
      render: (text) => (
        <span>
          {moment(text, DATE_TIME_FORMAT).format("DD-MM-YYYY, HH:mm:ss")}
        </span>
      ),
      width: 250,
    },
  ],
  ...config,
};
