import React from "react";
import { Route } from "react-router-dom";
import { MilestoneReport } from "../pages/Milestones";

export const register = (props) => {
  const basePath = "milestones";

  return (
    <>
      <Route path={`${basePath}-report`} element={<MilestoneReport />} />
    </>
  );
};
