import React, { useEffect } from "react";
import { Modal, Form, Input } from "antd";
import { axios } from "../../../../App";

const RenameModal = ({
  visible,
  onClose,
  onFinish,
  currentFileItemId = null,
  data = {},
}) => {
  const [form] = Form.useForm();

  const onSubmit = async (values) => {
    try {
      const response = await axios.put(`/file-items/${currentFileItemId}`, {
        data: {
          name: values?.name,
        },
      });

      if (response?.data) {
        form.resetFields();

        onFinish();
        onClose();
      }

      throw new Error();
    } catch (error) {}
  };

  useEffect(() => {
    form.setFieldsValue({
      name: data?.name,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Modal
      title={`Rename ${data?.item_type}`}
      visible={visible}
      onOk={form.submit}
      onCancel={onClose}
      destroyOnClose
    >
      <Form
        form={form}
        name="renameForm"
        onFinish={onSubmit}
        autoComplete="off"
        layout="vertical"
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: "Name is required!" }]}
        >
          <Input placeholder="Enter name" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default RenameModal;
