import React from "react";
import { Route } from "react-router-dom";
import {
  MeetingCommentList,
  MeetingCommentAdd,
  MeetingCommentEdit,
} from "../pages/MeetingComments";

export const register = (props) => {
  const basePath = "meeting-comments";

  return (
    <>
      <Route path={basePath} element={<MeetingCommentList />} exact={true} />
      <Route path={`${basePath}/add`} element={<MeetingCommentAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<MeetingCommentEdit />} />
    </>
  );
};
