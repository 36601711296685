import React from "react";
import { Route } from "react-router-dom";
import {
  TaskMaterialList,
  TaskMaterialAdd,
  TaskMaterialCreatorEdit,
  TaskMaterialApproverEdit,
  TaskMaterialView,
  TaskMaterialReport,
} from "../pages/TaskMaterials";

export const register = (props) => {
  const basePath = "task-materials";

  return (
    <>
      <Route path={basePath} element={<TaskMaterialList />} exact={true} />
      <Route path={`${basePath}/add`} element={<TaskMaterialAdd />} />
      <Route
        path={`${basePath}/creator/edit/:id`}
        element={<TaskMaterialCreatorEdit />}
      />
      <Route
        path={`${basePath}/approver/edit/:id`}
        element={<TaskMaterialApproverEdit />}
      />
      <Route path={`${basePath}/view/:id`} element={<TaskMaterialView />} />
      <Route path={`${basePath}-report`} element={<TaskMaterialReport />} />
    </>
  );
};
