import React, { useState, useEffect } from "react";
import {
  BellOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from "@ant-design/icons";
import {
  Layout,
  Divider,
  Menu,
  Space,
  Tooltip,
  Avatar,
  List,
  Drawer as AntDrawer,
  Badge,
} from "antd";
import { useNavigate, Link, useLocation } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import { useDispatch, useSelector } from "react-redux";
import { logOutUser } from "../../redux/actions/authActions";
import { BiCalendarStar } from "react-icons/bi";
import { BsChatDots } from "react-icons/bs";
import FeatureSearchBox from "../FeatureSeachBox";
import styles from "./styles";
import { IoNotificationsOutline } from "react-icons/io5";
import {
  Box,
  HStack,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Icon,
  Image,
} from "@chakra-ui/react";
import { axios } from "../../App";
import { updateUnreadCount } from "../../redux/actions/notificationActions";

const { Header, Content, Sider } = Layout;

const SharedLayout = ({ children, title, menuItem, makeNavbar }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  const { user } = useSelector((state) => state?.auth);
  const items = makeNavbar({ navigate, user });
  const [collapsed, setCollapsed] = useState(false);
  const [showAccount, setShowAccount] = useState(false);
  const { unread_count } = useSelector((state) => state?.notification);

  const menuItems = [
    {
      title: "Profile",
      onClick: () => {
        navigate("/account/profile");
        toggleAccount();
      },
    },
    {
      title: "Change Password",
      onClick: () => {
        navigate("/account/change-password");
        toggleAccount();
      },
    },
    {
      title: "Notifications",
      onClick: () => {
        showNotifications();
        toggleAccount();
      },
    },
    {
      title: "Log out",
      onClick: () => {
        dispatch(logOutUser());
      },
    },
  ];

  const toggleAccount = () => {
    setShowAccount(!showAccount);
  };

  const showNotifications = () => {
    navigate("/account/notifications");
  };

  const fetchUnreadNotifications = async () => {
    try {
      const response = await axios.post(`/notifications/count/unread`, {
        recipient_uid: user?.uid,
      });

      if (response?.data) {
        dispatch(updateUnreadCount(response?.data?.data?.count));
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchUnreadNotifications();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout style={styles.container}>
      <HStack display={{ base: "none", md: "none", lg: "block" }}>
        <Sider
          collapsible
          collapsed={collapsed}
          width={300}
          style={styles.sider}
          trigger={null}
        >
          {!collapsed && (
            <div style={styles.logoContainer}>
              <Link to="/">
                <img src={logo} alt="Logo" style={styles.logo} />
              </Link>
            </div>
          )}

          <Menu
            mode="inline"
            style={{
              ...styles.menu,
              background: collapsed ? "#001528" : "white",
              color: collapsed ? "white" : "#000000d9",
            }}
            items={items}
            defaultSelectedKeys={["/"]}
            selectedKeys={[location.pathname]}
          />
        </Sider>
      </HStack>

      <Layout className="site-layout">
        <Header style={styles.header}>
          <Box display={{ base: "none", md: "none", lg: "block" }}>
            <Tooltip
              placement="bottom"
              title={`${!collapsed ? "Collapse" : "Expand"} sidebar`}
            >
              <span
                onClick={() => setCollapsed(!collapsed)}
                style={styles.menuToggler}
              >
                {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              </span>
            </Tooltip>
          </Box>

          <Box display={{ base: "block", md: "block", lg: "none" }}>
            <Icon as={MenuUnfoldOutlined} ref={btnRef} onClick={onOpen}></Icon>
          </Box>
          <Drawer
            isOpen={isOpen}
            placement="left"
            onClose={onClose}
            finalFocusRef={btnRef}
          >
            <DrawerOverlay />
            <DrawerContent>
              <DrawerCloseButton />
              <Image src={logo} alt="Logo" w={180} p="5px" />
              <Menu
                mode="inline"
                style={{
                  ...styles.menu,
                  background: collapsed ? "#001528" : "white",
                  color: collapsed ? "white" : "#000000d9",
                }}
                items={items}
                defaultSelectedKeys={["/"]}
                selectedKeys={[location.pathname]}
              />
            </DrawerContent>
          </Drawer>

          <h2 style={styles.headerTitle}>
            <Link to={menuItem.path} style={styles.headerTitleLink}>
              {menuItem.title}
            </Link>
          </h2>

          <Space size="middle">
            <FeatureSearchBox navigate={navigate} />

            <Tooltip placement="bottom" title="Project Chats">
              <BsChatDots
                style={styles.icon}
                onClick={() => navigate("/quick-talks/project-chats")}
              />
            </Tooltip>

            <Tooltip placement="bottom" title="Calendar">
              <BiCalendarStar
                style={styles.icon}
                onClick={() => navigate("/quick-talks/calendar")}
              />
            </Tooltip>

            <Tooltip
              placement="bottom"
              title="Notifications"
              onClick={showNotifications}
            >
              <div style={styles.notificationIcon}>
                <IoNotificationsOutline style={styles.icon} />

                {unread_count > 0 && (
                  <>
                    {unread_count >= 100 ? (
                      <span style={styles.notificationCount}>99+</span>
                    ) : (
                      <span style={styles.notificationCount}>
                        {unread_count}
                      </span>
                    )}
                  </>
                )}
              </div>
            </Tooltip>

            <Divider type="vertical" style={styles.menuDivider} />

            <Space style={styles.profile} onClick={toggleAccount}>
              <span style={styles.avatarContainer}>
                <Avatar
                  src={user?.avatar || "/images/no-avatar.png"}
                  style={styles.avatar}
                ></Avatar>

                <span style={styles.onlineDot}></span>
              </span>

              <span>{user?.full_name}</span>
            </Space>
          </Space>
        </Header>

        <Content style={styles.contentContainer}>
          <div className="site-layout-background" style={styles.main}>
            {children}
          </div>
        </Content>

        <AntDrawer
          title="Account"
          placement="right"
          onClose={toggleAccount}
          visible={showAccount}
        >
          <List
            itemLayout="horizontal"
            dataSource={menuItems}
            renderItem={(item) => (
              <List.Item style={styles.listItem} onClick={item?.onClick}>
                <List.Item.Meta title={<span>{item.title}</span>} />
              </List.Item>
            )}
          />
        </AntDrawer>
      </Layout>
    </Layout>
  );
};

export default SharedLayout;
