import React, { useState, useEffect } from "react";
import { AddForm } from "../../../components";
import { useNavigate } from "react-router-dom";
import { makeConfig } from "./config";
import { useSelector } from "react-redux";
import { fetchData } from "../../../../../utils/helpers/data.helper";
import { find } from "lodash";

const Page = (props) => {
  const navigate = useNavigate();
  const auth = useSelector((state) => state?.auth);
  const config = makeConfig({ navigate, auth });
  const [values, setValues] = useState({
    status: ["Pending", "Approved", "Rejected"]?.map((item) => ({
      value: item,
      label: item,
    })),
    work_item: [],
    all_work_item: [],
  });
  const contentTypes = [
    {
      slug: "work-groups",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          work_group: data,
        })),
    },
    {
      slug: "work-items",
      query: {
        populate: "*",
      },
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          all_work_item: data,
        })),
    },
    {
      slug: "work-units",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          work_unit: data,
        })),
    },
    {
      slug: "tasks",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          task: data,
        })),
    },
    {
      slug: "employees",
      transformer: (item) => ({
        value: item?.id,
        label: `${item?.first_name} ${item?.last_name}`,
        uid: item?.uid,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          employee: data,
         // approved_by: data,
        })),
    },
    {
      slug: "app-users",
      transformer: (item) => {
        if (item?.attributes?.user_type === "Employee") {
          return {
            value: item?.id,
            label: item?.attributes?.username,
          };
        }
      },
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          approved_by: data,
        })),
    }
  ];

  useEffect(() => {
    contentTypes?.forEach((contentType) => {
      fetchData(contentType);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AddForm
      config={config}
      values={values}
      mode={props?.mode}
      data={{
        ...props?.data,
        status: "Pending",
        employee: find(values?.employee, {
          uid: auth?.user?.uid,
        })?.value,
      }}
      actions={props?.actions}
      disabledItems={props?.disabledItems}
      onFieldChange={{
        work_group: (antForm) => {
          const workGroupId = antForm.getFieldValue("work_group");

          antForm.setFieldValue("work_item", undefined);

          if (!workGroupId) {
            setValues((prev) => ({
              ...prev,
              work_item: [],
            }));
          } else {
            setValues((prev) => ({
              ...prev,
              work_item: values?.all_work_item
                ?.filter((item) => item?.work_group?.data?.id === workGroupId)
                ?.map((item) => ({
                  value: item?.id,
                  label: item?.name,
                })),
            }));
          }
        },
      }}
    />
  );
};

export default Page;
