import React, { useEffect, useState } from "react";
import MainForm from "./components/MainForm";
import { useNavigate, useParams } from "react-router-dom";
import { makeConfig } from "./config";
import { axios } from "../../../App";
import qs from "qs";

const Page = (props) => {
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const config = makeConfig({ navigate });
  const { requirementFormUid, agencyUid } = useParams();
  const [workItems, setWorkItems] = useState([]);
  const [paymentSchedules, setPaymentSchedules] = useState([]);
  const [terms, setTerms] = useState([]);
  const [quotation, setQuotation] = useState({});

  const fetchQuotation = async () => {
    try {
      const queryObject = {
        populate: "*",
        filters: {
          agency_requirement_form: {
            uid: requirementFormUid,
          },
          agency: {
            uid: agencyUid,
          },
        },
      };

      const response = await axios.get(
        `/agency-quotations?${qs.stringify(queryObject)}`
      );

      if (response?.data?.data?.length > 0) {
        setQuotation(response?.data?.data?.[0]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchRequirementForm = async () => {
    try {
      setLoading(true);

      const queryObject = {
        populate: "*",
        filters: {
          uid: requirementFormUid,
        },
      };

      const response = await axios.get(
        `/agency-requirement-forms?${qs.stringify(queryObject)}`
      );

      if (response?.data) {
        setWorkItems(response?.data?.data?.[0]?.attributes?.work_items || []);

        setPaymentSchedules(
          response?.data?.data?.[0]?.attributes?.payment_schedules || []
        );

        setTerms(response?.data?.data?.[0]?.attributes?.terms || []);

        setData({
          ...response?.data?.data?.[0]?.attributes,
          id: response?.data?.data?.[0]?.id,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (requirementFormUid && agencyUid) {
      fetchRequirementForm();
      fetchQuotation();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requirementFormUid, agencyUid]);

  return (
    <MainForm
      config={config}
      data={{
        ...data,
        workItems,
        paymentSchedules,
        terms,
        quotation,
      }}
      loading={loading}
      requirementFormUid={requirementFormUid}
      agencyUid={agencyUid}
    />
  );
};

export default Page;
