import React, { useEffect, useState } from "react";
import { ModalAddForm } from "../../../components";
import { useNavigate } from "react-router-dom";
import { makeConfig } from "./config";
import { fetchData } from "../../../../../utils/helpers/data.helper";
import { v4 } from "uuid";
import { Button, Col, Collapse, Input, Row, Space, notification } from "antd";
import ReactQuill from "react-quill";

const { Panel } = Collapse;

const Page = (props) => {
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    work_item: [],
    all_work_item: [],
  });
  const config = makeConfig({ navigate });
  const [terms, setTerms] = useState([]);
  const [tableTerms, settableTerms] = useState([]);


  const contentTypes = [
    {
      slug: "work-groups",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          work_group: data,
        })),
    },
    {
      slug: "work-items",
      query: {
        populate: "*",
      },
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          all_work_item: data,
        })),
    },
    {
      slug: "work-units",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          work_unit: data,
        })),
    },
    {
      slug: "sub-contractors",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          sub_contractor: data,
        })),
    },
  ];

  const onAddTerms = () => {
    setTerms((prev) =>
      prev?.concat({
        id: v4(),
        term_name: '',
        term_details: ''
      })
    );
  };

  // State to hold filtered material items for each boq item
  const onEditTerms = (id, key, value) => {
    setTerms((prev) =>
      prev.map((item) => {
        if (item.id === id) {
          const updatedItem = { ...item, [key]: value };
          return updatedItem;
        }
        return item;
      })
    );
  };

  const onRemoveTerms = (id) => {
    setTerms((prev) => prev?.filter((item) => item?.id !== id));

    notification["success"]({
      message: `Remove Term`,
      description: `Removed Term successfully!`,
    });
  };

  useEffect(() => {
    contentTypes?.forEach((contentType) => {
      fetchData(contentType);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (values?.all_work_item?.length > 0) {
      setValues((prev) => ({
        ...prev,
        work_item: values?.all_work_item
          ?.filter(
            (item) => item?.work_group?.data?.id === props?.data?.work_group
          )
          ?.map((item) => ({
            value: item?.id,
            label: item?.name,
          })),
      }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.all_work_item]);

  useEffect(() => {
    if (props?.data?.term_and_condition) {
      setTerms(props?.data?.term_and_condition?.terms)
      settableTerms(props?.data?.term_and_condition?.table_terms)
    }
  }, [props?.data])

  console.log('edit terms', props)

  return (
    <ModalAddForm
      config={config}
      data={{ ...props?.data, term_and_condition: { terms, tableTerms } }}
      loading={loading}
      values={values}
      onSubmit={props?.onSubmit}
      onCancel={props?.onCancel}
      addTerms={true}
      onFieldChange={{
        work_group: (antForm) => {
          const workGroupId = antForm.getFieldValue("work_group");

          antForm.setFieldValue("work_item", undefined);

          if (!workGroupId) {
            setValues((prev) => ({
              ...prev,
              work_item: [],
            }));
          } else {
            setValues((prev) => ({
              ...prev,
              work_item: values?.all_work_item
                ?.filter((item) => item?.work_group?.data?.id === workGroupId)
                ?.map((item) => ({
                  value: item?.id,
                  label: item?.name,
                })),
            }));
          }
        },
      }}
      children={
        <div style={{ margin: '0 12px', width: '100%' }}>
          {terms?.map((data, index) => (
            <div key={index} style={{ marginBottom: '20px' }}>
              <Collapse>
                <Panel header={`${data?.term_name ?? `Terms & Condition ${index + 1}`}`} key="uniqueKey">
                  <div style={{ border: '1px solid #e8e8e8', padding: '20px', marginBottom: '10px' }}>
                    <Row gutter={24}>

                      {/* term_name Input */}
                      <Col span={24}>
                        <Input
                          type="text"
                          placeholder="Enter Term Name"
                          value={data?.term_name}
                          onChange={(e) =>
                            onEditTerms(data?.id, "term_name", e.target.value)
                          }
                          style={{ width: "100%" }}
                        />
                      </Col>
                    </Row>

                    <Row gutter={24} style={{ marginTop: '10px' }}>
                      {/* Estimated Labour Cost */}
                      <Col span={24}>

                        <ReactQuill
                          placeholder="Enter Term Description"
                          value={data?.term_details}
                          onChange={(value) =>
                            onEditTerms(data?.id, "term_details", value) // Pass `value` directly
                          }
                          style={{ width: "100%" }}
                        />

                      </Col>
                    </Row>
                  </div>


                  {/* Delete Button */}
                  <Space>
                    <Button type="danger" onClick={() => onRemoveTerms(data?.id)}>
                      Delete
                    </Button>
                  </Space>
                </Panel>
              </Collapse>
            </div>
          ))}

          <Space style={{ width: '100%', marginBottom: '20px' }}>
            <Space style={{ width: '100%' }}>
              <Button type="secondary" onClick={() => onAddTerms()} >
                Add Terms & Conditions
              </Button>
            </Space>
          </Space>
        </div>
      }
    />
  );
};

export default Page;
