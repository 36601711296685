import React from "react";
import { Route } from "react-router-dom";
import {
  EmployeeList,
  EmployeeAdd,
  EmployeeEdit,
  // EmployeeReport,
} from "../pages/Employees";

export const register = (props) => {
  const basePath = "employees";

  return (
    <>
      <Route path={basePath} element={<EmployeeList />} exact={true} />
      <Route path={`${basePath}/add`} element={<EmployeeAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<EmployeeEdit />} />
      {/* <Route path={`${basePath}-report`} element={<EmployeeReport />} /> */}
    </>
  );
};
