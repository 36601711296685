import React, { useState, useEffect } from "react";
import { PageHeader, Row, Col, Form, Space, Button, notification } from "antd";
import FormElement from "../../../FormElement";
import { Box, Stack } from "@chakra-ui/react";
import { axios } from "../../../../../../App";
import { v4 } from "uuid";
import config from "./config";

const PermissionAdd = ({
  loading = false,
  data = {},
  values = {},
  saveText = "Save",
  onBack,
  configId,
  contentTypeId,
}) => {
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);

  const handleFinish = async (values) => {
    setSubmitting(true);

    const response = await axios.post(`${contentTypeId}`, {
      data: {
        ...values,
        uid: v4(),
      },
    });

    if (response?.data?.data) {
      notification["success"]({
        message: config?.title,
        description: `Add new ${config?.[
          configId
        ]?.entityNames?.singular?.toLowerCase()} successfully!`,
      });

      onBack();
    } else {
      notification["error"]({
        message: config?.[configId]?.title,
        description: `Add new ${config?.[
          configId
        ]?.entityNames?.singular?.toLowerCase()} failed!`,
      });
    }

    setSubmitting(false);
  };

  useEffect(() => {
    form.setFieldsValue(data);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentTypeId, configId, data]);

  return (
    <>
      <PageHeader
        title={config?.[configId]?.title}
        style={styles.pageHeader}
        onBack={onBack}
      ></PageHeader>

      <Form
        form={form}
        name={configId}
        layout="vertical"
        onFinish={handleFinish}
        initialValues={data}
        style={styles.form}
        scrollToFirstError
        disabled={loading}
      >
        <Box display={{ base: "none", md: "block", lg: "block" }}>
          <Row gutter={24}>
            {config?.[configId]?.components.map((component) => (
              <Col span={component?.span} key={component?.name}>
                <FormElement
                  {...component}
                  values={values}
                  form={form}
                  data={data}
                />
              </Col>
            ))}
          </Row>
        </Box>

        <Box display={{ base: "block", md: "none", lg: "none" }}>
          {config?.[configId]?.components.map((component) => {
            return (
              <Stack span={component?.span} key={component?.name}>
                <FormElement
                  {...component}
                  values={values}
                  form={form}
                  data={data}
                />
              </Stack>
            );
          })}
        </Box>

        <Space>
          <Button onClick={onBack}>Cancel</Button>

          <Button type="primary" htmlType="submit" loading={submitting}>
            {saveText}
          </Button>
        </Space>
      </Form>
    </>
  );
};

const styles = {
  pageHeader: {
    padding: 0,
    paddingBottom: 24,
  },
  form: {
    width: "100%",
  },
};

export default PermissionAdd;
