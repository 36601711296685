export default {
  slugs: {
    singular: "project-type",
    plural: "project-types",
  },
  entityNames: {
    singular: "Project Types",
    plural: "Project Types",
  },
};
