export const CHART_SLUGS = {
  STATISTICS: "statistics",
  EMPLOYEES_PER_GENDER: "employees-per-gender",
  EMPLOYEES_PER_LOCATION: "employees-per-location",
  EMPLOYEES_PER_DEPARTMENT: "employees-per-department",
  TICKET_STATUSES: "ticket-statuses",
  EMPLOYEES_PER_PROJECT: "employees-per-project",
  EMPLOYEES: "employees",
};

export const REPORTS = [
  {
    id: "payrolls-report",
    name: "Payrolls Report",
  },
];
