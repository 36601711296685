import { axios } from "../../../../../App";
import config from "../config";

export const makeConfig = ({ navigate }) => ({
  id: `${config?.plural}-edit`,
  title: `Edit ${config?.entityNames?.singular}`,
  pageTitle: `Edit ${config?.entityNames?.singular}`,
  breadcrumbs: [
    {
      path: "/quick-teams/dashboard",
      title: "Home",
    },
    {
      path: `/quick-teams/${config?.slugs?.plural}`,
      title: config?.entityNames?.plural,
    },
    {
      path: "",
      title: `Edit ${config?.entityNames?.singular}`,
    },
  ],
  components: [
    {
      span: 12,
      component: "date",
      label: "From Date",
      name: "from_date",
      required: true,
      placeholder: "Select from date",
      rules: [
        {
          required: true,
          message: "From date is required!",
        },
      ],
    },
    {
      span: 12,
      component: "date",
      label: "To Date",
      name: "to_date",
      required: true,
      placeholder: "Select to date",
      rules: [
        {
          required: true,
          message: "To date is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Employee",
      name: "employee",
      required: true,
      placeholder: "Select employee",
      rules: [
        {
          required: true,
          message: "Employee is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Shift",
      name: "shift",
      required: true,
      placeholder: "Select shift",
      rules: [
        {
          required: true,
          message: "Shift is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Location",
      name: "location",
      required: true,
      placeholder: "Select location",
      rules: [
        {
          required: true,
          message: "Location is required!",
        },
      ],
    },
    {
      span: 24,
      component: "editor",
      label: "Description",
      name: "description",
      placeholder: "Enter description",
      rows: 5,
    },
  ],
  actions: {
    onBack: () => navigate(`/quick-teams/${config?.slugs?.plural}`),
    onFinish: async (values, data) => {
      try {
        const response = await axios.put(
          `/${config?.slugs?.plural}/${data?.id}`,
          {
            data: {
              ...values,
              from_date: values?.from_date?.add(1, "days"),
              to_date: values?.to_date?.add(1, "days"),
            },
          }
        );

        if (response?.data) {
          return {
            success: true,
            message: `Edit ${config?.entityNames?.singular?.toLowerCase()} successfully!`,
          };
        }

        throw new Error();
      } catch (error) {
        return {
          success: false,
          message: error?.response?.data?.error?.message,
        };
      }
    },
  },
  ...config,
});
