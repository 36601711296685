import React from "react";
import { Route } from "react-router-dom";
import {
  SubContractorWorkOrderList,
  SubContractorWorkOrderView,
  SubContractorWorkOrderReport,
} from "../pages/SubContractorWorkOrders";

export const register = (props) => {
  const basePath = "sub-contractor-work-orders";

  return (
    <>
      <Route
        path={basePath}
        element={<SubContractorWorkOrderList />}
        exact={true}
      />
      <Route
        path={`${basePath}/view/:id`}
        element={<SubContractorWorkOrderView />}
      />
      <Route
        path={`${basePath}-report`}
        element={<SubContractorWorkOrderReport />}
      />
    </>
  );
};
