import React from "react";
import { Route } from "react-router-dom";
import {
  VendorMaterialRequisitionList,
  VendorMaterialRequisitionAdd,
  VendorMaterialRequisitionEdit,
  VendorMaterialRequisitionReport,
  VendorMaterialRequisitionQuotationLinkList,
} from "../pages/VendorMaterialRequisitions";

export const register = (props) => {
  const basePath = "vendor-material-requisitions";

  return (
    <>
      <Route
        path={basePath}
        element={<VendorMaterialRequisitionList />}
        exact={true}
      />
      <Route
        path={`${basePath}/add`}
        element={<VendorMaterialRequisitionAdd />}
      />
      <Route
        path={`${basePath}/edit/:id`}
        element={<VendorMaterialRequisitionEdit />}
      />
      <Route
        path={`${basePath}/quotations/:id`}
        element={<VendorMaterialRequisitionQuotationLinkList />}
      />
      <Route
        path={`${basePath}-report`}
        element={<VendorMaterialRequisitionReport />}
      />
    </>
  );
};
