import React from "react";
import { Route } from "react-router-dom";
import {
  SubContractorQuotationList,
  SubContractorQuotationAdd,
  SubContractorQuotationEdit,
  SubContractorQuotationView,
  SubContractorQuotationReport,
} from "../pages/SubContractorQuotations";

export const register = (props) => {
  const basePath = "sub-contractor-quotations";

  return (
    <>
      <Route
        path={basePath}
        element={<SubContractorQuotationList />}
        exact={true}
      />
      <Route path={`${basePath}/add`} element={<SubContractorQuotationAdd />} />
      <Route
        path={`${basePath}/edit/:id`}
        element={<SubContractorQuotationEdit />}
      />
      <Route
        path={`${basePath}/view/:id`}
        element={<SubContractorQuotationView />}
      />
      <Route
        path={`${basePath}-report`}
        element={<SubContractorQuotationReport />}
      />
    </>
  );
};
