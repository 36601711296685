export default {
  slugs: {
    singular: "manpower",
    plural: "manpowers",
  },
  entityNames: {
    singular: "Manpower",
    plural: "Manpowers",
  },
};
