import React from "react";
import { AddForm } from "../../../components";
import { makeConfig } from "./config";
import { useSelector, useDispatch } from "react-redux";
import { logOutUser } from "../../../../../redux/actions/authActions";

const Page = (props) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state?.auth);
  const config = makeConfig({ auth, dispatch });

  const logOut = () => {
    dispatch(logOutUser());
  };

  return <AddForm config={config} showsCancel={false} onSuccess={logOut} />;
};

export default Page;
